/**
 * These "events" will be Redux States.
 * Every button press on the GUI will result in the change of a state variable
 * i.e. Observable events
 */
const InputEvents = {
  /** Event "Flow" Buttons */
  FT_ROUTINE: 'FT_ROUTINE',
  SHOT_FT_MADE: 'SHOT_FT_MADE',
  SHOT_FT_MISS: 'SHOT_FT_MISS',
  SHOT_2PT_MADE: 'SHOT_2PT_MADE',
  SHOT_2PT_MISS: 'SHOT_2PT_MISS',
  SHOT_3PT_MADE: 'SHOT_3PT_MADE',
  SHOT_3PT_MISS: 'SHOT_3PT_MISS',
  END_PLAY: 'END_PLAY',
  TURNOVER: 'TURNOVER',
  STEAL: 'STEAL',
  PERSONAL_FOUL: 'PERSONAL_FOUL',
  TECHNICAL_FOUL: 'TECHNICAL_FOUL',
  VIOLATION: 'VIOLATION',
  DEFLECT: 'DEFLECT',
  /** Event "Player" Buttons  */
  PLAYER: 'PLAYER',
  /** Event "Conversation" Buttons  */
  BLOCK: 'BLOCK',
  LOCATION: 'DECISION_LOCATION',
  ASSIST: 'ASSIST',
  REBOUND_OFFENSIVE: 'REBOUND_OFFENSIVE',
  REBOUND_DEFENSIVE: 'REBOUND_DEFENSIVE',
  /** Event "Conversation" Violation Buttons  */
  VIOLATION_TRAVELING: 'VIOLATION_TRAVELING',
  VIOLATION_CARRYING: 'VIOLATION_CARRYING',
  VIOLATION_DOUBLE_DRIBBLE: 'VIOLATION_DOUBLE_DRIBBLE',
  VIOLATION_BACKCOURT: 'VIOLATION_BACKCOURT',
  VIOLATION_OFF_GOALTENDING: 'VIOLATION_OFF_GOALTENDING',
  VIOLATION_DEF_GOALTENDING: 'VIOLATION_DEF_GOALTENDING',
  VIOLATION_3_SECONDS: 'VIOLATION_3_SECONDS',
  VIOLATION_5_SECONDS: 'VIOLATION_5_SECONDS',
  VIOLATION_10_SECONDS: 'VIOLATION_10_SECONDS',
  /** Are these right */
  VIOLATION_FREE_THROW: 'VIOLATION_FREE_THROW',

  /** OTHER */
  UPDATE_DISPLAY_TYPE: 'UPDATE_DISPLAY_TYPE',
  CHANGE_POSSESION: 'UPDATE_DISPLAY_TYPE',
  TIMEOUT: 'TIMEOUT',
  // EDIT: 'EDIT',
  BACK: 'BACK',
  CANCEL: 'CANCEL',
};

const breadcrumbMap = {
  [InputEvents.SHOT_2PT_MADE]: '2pt Made',
  [InputEvents.SHOT_2PT_MISS]: '2pt Miss',
  [InputEvents.SHOT_3PT_MADE]: '3pt Made',
  [InputEvents.SHOT_3PT_MISS]: '3pt Miss',
  [InputEvents.FT_ROUTINE]: 'Free Throws',
  [InputEvents.SHOT_FT_MADE]: 'FT Made',
  [InputEvents.SHOT_FT_MISS]: 'FT Miss',
  [InputEvents.TURNOVER]: 'Turnover',
  [InputEvents.BLOCK]: 'Block',
  [InputEvents.STEAL]: 'Steal',
  [InputEvents.PERSONAL_FOUL]: 'P Foul',
  [InputEvents.TECHNICAL_FOUL]: 'T Foul',
  [InputEvents.VIOLATION]: 'Violation',
  [InputEvents.DEFLECT]: 'Deflect',
  [InputEvents.PLAYER]: (state) => {return `#${state.player.playerJerseyNumber}`},
  [InputEvents.PLAYER_OFFENSE]: null,
  [InputEvents.PLAYER_DEFENSE]: null,
  [InputEvents.LOCATION]: (state) => {return `Location (${state.location.area})`},
  [InputEvents.NO_ASSIST]: 'No Assist',
  [InputEvents.END_PLAY]: 'End',
  [InputEvents.ASSIST]: 'Assist',
  [InputEvents.REBOUND_OFFENSIVE]: 'OFF Rebound',
  [InputEvents.REBOUND_DEFENSIVE]: 'DEF Rebound',
  [InputEvents.VIOLATION_TRAVELING]: 'Travel',
  [InputEvents.VIOLATION_CARRYING]: 'Carry',
  [InputEvents.VIOLATION_DOUBLE_DRIBBLE]: 'Double Dribble',
  [InputEvents.VIOLATION_BACKCOURT]: 'Backcourt',
  [InputEvents.VIOLATION_OFF_GOALTENDING]: 'OFF Goaltending',
  [InputEvents.VIOLATION_DEF_GOALTENDING]: 'DEF Goaltending',
  [InputEvents.VIOLATION_3_SECONDS]: '3 Sec',
  [InputEvents.VIOLATION_5_SECONDS]: '5 Sec',
  [InputEvents.VIOLATION_10_SECONDS]: '10 Sec',
  [InputEvents.VIOLATION_FREE_THROW]: 'FT Violation',
  [InputEvents.UPDATE_DISPLAY_TYPE]: null,
  [InputEvents.CHANGE_POSSESION]: null,
  [InputEvents.TIMEOUT]: null,
  [InputEvents.BACK]: null,
  [InputEvents.CANCEL]: null,
};

export function getBreadcrumbForEvent(state) {
  if (breadcrumbMap.hasOwnProperty(state.value)) {
    let breadcrumb = breadcrumbMap[state.value];
    if (typeof breadcrumb === 'function') {
      return breadcrumb(state);
    } else {
      return breadcrumb;
    }
  } else {
    return null;
  }
}

export default InputEvents;
