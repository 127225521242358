import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Reset from '../../../assets/Reset@2x.png';

const GamePeriodControl = ({
  game,
  gamePeriodValue,
  gamePeriodOptions,
  resetIconImgStatus,
  restoreOriginalShotLocationsStates,
  selectShotLocationsForPeriods
}) => {
  return (
    <Row style={{ width: '100%', height: '20%', margin: 0, }}>
      <Col xs={8} className="p-0" style={{ width: '67.6%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
        <Form.Select
          style={{ width: '92%', height: 45, }}
          onChange={event => selectShotLocationsForPeriods(event.currentTarget.value, game?.awayTeamId, game?.homeTeamId)}
          value={gamePeriodValue}
        >
          {gamePeriodOptions.map(gamePeriod => (
            <option value={gamePeriod.period}>{gamePeriod.periodItem}</option>
          ))}
        </Form.Select>
      </Col>
      <Col xs={4} className="p-0" style={{ width: '32.4%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', borderTopRightRadius: 10, overflow: 'hidden', }}>
        <div style={{ width: '100%', height: '100%', borderLeft: '2px solid #D5D5D5', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EFEFEF', cursor: 'pointer', }} onClick={restoreOriginalShotLocationsStates}>
          <div style={{ width: '40%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', }}>
            <img
              src={Reset}
              width="30px"
              height="30px"
              alt=""
              style={{ 
                objectFit: 'contain', 
                opacity: resetIconImgStatus ? 1 : 0.7, 
                mixBlendMode: resetIconImgStatus ? 'normal' : 'luminosity', 
              }}
            />
          </div>
          <div style={{ width: '60%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 5, font: `normal normal ${ resetIconImgStatus ?  700 : 'normal' } 18px/40px Open Sans`, color: '#707070', }}>
            Reset
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default GamePeriodControl;