import styled from 'styled-components';

export const ViewStats = styled.div`
  width: 100%;
  height: auto;
  padding-top: ${props => props.paddingTop ?? '10px'};
  padding-bottom: ${props => props.paddingBottom ?? '10px'};
  display: flex;
  flex-dirrction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 600 13px/17px 'Segoe UI';
  color: #26A9E1;
`;

export const ViewStatsItem = styled.div`
  width: auto;
  height: auto;
  cursor: pointer;
`;
