import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ThreeDots } from 'react-bootstrap-icons';

const styleDropdown ={
  paddingLeft: '2rem',
  paddingRight: '1rem',
  paddingTop: '0.5rem',
  paddingBottom: '2rem',
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const EllipsisMenuToggle = React.forwardRef(({ key, children, onClick }, ref) => (
  <a 
    id={key}
    key={key}
    href=""
    ref={ref}
    data-toggle="dropdown"
    onClick={(e) => {
      e.overflowMenuHandled = true
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    <ThreeDots size="24px" color="#AFAFAF" />
  </a>
));

const OptionsOverflowMenu = ({ key, menuItems = [] }) => {
  return (
    <Dropdown className={'position-absolute top-0 end-0'} style={styleDropdown}>
      <Dropdown.Toggle key={key} as={EllipsisMenuToggle} />
      <Dropdown.Menu style={{ minWidth: '30px' }}>
        {menuItems.map((value, index) => {
          return (
            <Dropdown.Item key={index} eventKey={index} onClick={value.onClick} disabled={value.disabled}>
              {value.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default OptionsOverflowMenu;
