import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const StatsDataRowItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StatDataItem = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: ${props => props.fontWeight};
`;

const StatItemTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #EBEBEB;
`;

const StatsDataContainer = ({
  keyText = '',
  dataItems = [],
  dataItemStyle = {}, 
  children
}) => {
  const dataDisplayRef = useRef();
  const [dataWidth, setDataWidth] = useState();

  useEffect(() => {
    const dataDisplayWidth = dataDisplayRef.current?.clientWidth;
    const dataItemWidth = Math.ceil(dataDisplayWidth / 12);
    const percentageDataItemWidth = ( dataItemWidth / dataDisplayWidth ) * 100;
    setDataWidth(`${percentageDataItemWidth}%`);
  }, []);

  const getDataItemFontWeight = (keyText, isLastItemIndex) => (
    (keyText == 'court' || keyText == 'bench') 
      ? 'bold' 
      : isLastItemIndex 
         ? 'bold' 
         : '600'
  );

  return (
    <Row style={{ width: '100%', height: 50, margin: 0, borderBottom: '1px solid #EBEBEB', borderLeft: '1px solid #EBEBEB', borderRight: '1px solid #EBEBEB', }}>
      <Col xs={4} className="p-0">
        <StatItemTitle>
          {children}
        </StatItemTitle>
      </Col>
      <Col xs={8} className="p-0">
        <StatsDataRowItem 
          ref={dataDisplayRef}
          style={dataItemStyle}
        >
          {(dataItems.length === 1 && dataItems[0].data === "DNP-COACH'S DECISION")
            ? "DNP-COACH'S DECISION"
            : (
                dataItems.map(({ data }, index, arrayItems) => (
                  <StatDataItem
                    key={`${keyText}_${++index}`}
                    width={dataWidth}
                    fontWeight={getDataItemFontWeight(keyText, (index == (arrayItems.length - 1)))}
                  >
                    {data}
                  </StatDataItem>
                ))
              )
          }
        </StatsDataRowItem>
      </Col>
    </Row>
  );
};

export default StatsDataContainer;