import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { colors } from '../../../styles';

const MainTeamStatsTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font: normal normal 600 25px/33px 'Segoe UI';
  color: ${colors.BLACK[100]};
  border-top: ${props => `${props.borderTop}px solid ${props.borderColor}`};
  border-left: ${props => `${props.borderLeft}px solid ${props.borderColor}`};
`;

const TeamImageDisplay = styled.div`
  width: 50%;
  height: 100%;
  border-top: ${props => `1px solid ${props.borderColor}`};
  border-left: ${props => `${props.leftBorderWidth}px solid ${props.borderColor}`};
  border-right: ${props => `${props.rightBorderWidth}px solid ${props.borderColor}`};
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TeamImage = ({
  imgSrc, 
  leftBorderWidth, 
  rightBorderWidth,
  borderColor, 
  imgSize, 
  borderWidth, 
}) => {
  return (
    <TeamImageDisplay
      leftBorderWidth={leftBorderWidth}
      rightBorderWidth={rightBorderWidth}
      borderColor={borderColor}
    >
      <ImageContainer>
        <img
          src={imgSrc}
          width={imgSize}
          height={imgSize}
          style={{ objectFit: 'contain', border: `${borderWidth}px solid #D5D5D5`, borderRadius: 5, }}
        />
      </ImageContainer>
    </TeamImageDisplay>
  );
};

const StatsHeader = ({
  homeTeamImage, 
  awayTeamImage, 
  imageHeaderSize,
  headerHeight,
  colSize1,
  colSize2,
  mainBorderTop = 0, 
  mainMorderLeft = 0,
  borderColor = '#D4D4D4',
  borderWidth = 0,
  children,
}) => {
  return (
    <Row style={{ width: '100%', height: headerHeight, margin: 0, }}>
      <Col xs={colSize1} className='p-0'>
        <MainTeamStatsTitle
          borderTop={mainBorderTop}
          borderLeft={mainMorderLeft}
          borderColor={borderColor}
        >
          {children}
        </MainTeamStatsTitle>
      </Col>
      <Col xs={colSize2} className='p-0' style={{ display: 'flex', flexDirection: 'row', }}>
        <TeamImage
          imgSrc={homeTeamImage}
          imgSize={imageHeaderSize}
          leftBorderWidth={1}
          rightBorderWidth={1}
          borderColor={borderColor}
          borderWidth={borderWidth}
        />
        <TeamImage
          imgSrc={awayTeamImage}
          imgSize={imageHeaderSize}
          leftBorderWidth={0}
          rightBorderWidth={1}
          borderColor={borderColor}
          borderWidth={borderWidth}
        />
      </Col>
    </Row>
  );
};

export default StatsHeader;