import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FastStatsLogo from '../../assets/fastStats-logo@2x.png';
import Dropdown from '../../components/Dropdowns/UserDropDown';
import UserStatus from './UserStatus';
import { colors } from '../../styles/colors';
import Block from '../../assets/block.png';
import AlertComponent, { ALERT_VARIANTS } from '../../components/alerts/Alert';
import EndGameModal from '../../components/Modals/EndGameModal';
const log = require('../../logger')('ClockHeader');

const Header = ({
  users,
  handleOpenEndGameModal,
  handleCloseEndGameModal,
  endGameModalOpen,
  endGame,
  hasGameEnded,
  disableGameFinishedButton,
  notificationText,
  notificationTimeout = 3000,
  ...props
}) => {
  const [notificationTexts, setNotificationTexts] = useState(null);

  const [notificationsMuted, setNotificationsMuted] = useState(false);
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const previousUsers = usePrevious(users);

  const compareUsers = useCallback(() => {
    let updatedUser = null;
    for (let i = 0; i < previousUsers.length; i++) {
      const currentUser = previousUsers[i];
      const newUser = users[i];
      if (currentUser?.[1]?.status !== newUser[1]?.status) {
        updatedUser = {
          fn: newUser?.[1]?.fn,
          ln: newUser?.[1]?.ln,
          isOnline: newUser?.[1]?.status === 'online',
          role: newUser?.[1]?.role,
        };
      }
    }
    updatedUser &&
      setNotificationTexts({
        text: `${updatedUser.fn} ${updatedUser.ln} is ${
          updatedUser.isOnline ? 'online' : 'offline'
        }`,
        variant: updatedUser.isOnline
          ? ALERT_VARIANTS.GREEN
          : ALERT_VARIANTS.RED,
      });

  }, [previousUsers, users]);

  useEffect(() => {
    if (previousUsers && previousUsers.length > 0) {
      compareUsers();
    }
  }, [compareUsers, previousUsers, users]);

  useEffect(() => {
    window.addEventListener('offline', function (e) {
      log.info('Device Offline');
      setNotificationTexts({
        text:
          'No internet connection detected. Please connect to the internet to use the app',
        variant: ALERT_VARIANTS.RED,
      });
    });

    window.addEventListener('online', function (e) {
      log.info('Device Online');
    });
  }, []);

  useEffect(() => {
    setNotificationTexts(notificationText);
  }, [notificationText]);

  return (
    <>
      <EndGameModal
        modalOpen={endGameModalOpen}
        handleCloseModal={handleCloseEndGameModal}
        endGame={endGame}
        disableGameFinishedButton={disableGameFinishedButton}
      />
      <StyledHeader {...props}>
        <img src={FastStatsLogo} style={{ height: 18 }} alt="logo" />
        {!notificationsMuted && notificationTexts ? (
          <AlertComponent
            variant={notificationTexts?.variant}
            notificationText={notificationTexts?.text}
            width="70%"
            height="36px"
            users={users}
            timeout={notificationTimeout}
            closeButton={true}
          />
        ) : (
          <UserStatus
            users={users}
            style={hasGameEnded ? { opacity: 0.3 } : {}}
          />
        )}
        <StyledRightHeader>
          <StyledDropDownDiv>
            <NotificationsDiv
              onClick={() => setNotificationsMuted(!notificationsMuted)}
            >
              {notificationsMuted && <BlockImage src={Block} />}
              <NotificationsIcon
                style={{ fontSize: 30, color: 'white' }}
                src={NotificationsIcon}
              />
            </NotificationsDiv>
            <Dropdown
              handleOpenEndGameModal={handleOpenEndGameModal}
              hasGameEnded={hasGameEnded}
            />
          </StyledDropDownDiv>
        </StyledRightHeader>
      </StyledHeader>
    </>
  );
};

const StyledHeader = styled.div`
  padding: 0 2.5vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledDropDownDiv = styled.div`
  padding-left: 1vw;
  display: flex;
  align-items: center;
`;

const StyledRightHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const NotificationsDiv = styled.div`
  border-right: 1px solid ${colors.GRAY[500]};
  margin-right: 0.5rem;
  padding-right: 1rem;
  position: relative;
`;

const BlockImage = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
`;

export default Header;
