import { API, graphqlOperation } from 'aws-amplify';
import {
  listSports as listSportsQuery,
  getSport as getSportQuery,
} from '../graphql/queries';
import {
  createSport as createSportMutation,
  updateSport as updateSportMutation,
  deleteSport as deleteSportMutation,
} from '../graphql/mutations';
const log = require('../logger')('api');

export const listSports = async (variables = null) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listSportsQuery, variables)
    );
    return response.data.listSports;
  } catch (err) {
    log.error('Error fetching sports...', err);
  }
};

export const getSport = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getSportQuery, { id: id })
    );
    return response.data.getSport;
  } catch (err) {
    log.error('Error fetching sport...', err);
  }
};

export const createSport = async (name, description) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createSportMutation, {
        input: { name: name, description: description },
      })
    );
    return response.data.createSport;
  } catch (err) {
    log.error('Error creating sport...', err);
  }
};

export const updateSport = async (name, description, id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateSportMutation, {
        input: { name: name, description: description, id: id },
      })
    );
    return response.data.updateSport;
  } catch (err) {
    log.error('Error updating sport...', err);
  }
};

// TODO: Remove
export const deleteSport = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteSportMutation, { input: { id: id } })
    );
    return response.data.deleteSport;
  } catch (err) {
    log.error('Error deleting sport...', err);
  }
};
