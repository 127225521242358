import React, { useState, useEffect, } from 'react';
import Spinner from '../../Spinner';
import HomeTeamShotsMadeIndicator from '../../../assets/home_team_shots_made_indicator@2x.png';
import HomeTeamShotsMissedIndicator from '../../../assets/home_team_shots_missed_indicator@2x.png';
import AwayTeamShotsMadeIndicator from '../../../assets/away_team_shots_made_indicator@2x.png';
import AwayTeamShotsMissedIndicator from '../../../assets/away_team_shots_missed_indicator@2x.png';
import BasketballCourt from '../../../assets/BasketballCourt3.png';

const X_COORDINATE_SCALE = 50;
const Y_COORDINATE_SCALE = 105;

const BasketballCourtShotLocation = ({
  basketballCourtHeight,
  awayTeamShotMadeLocations,
  homeTeamShotMadeLocations,
  awayTeamShotMissedLocations,
  homeTeamShotMissedLocations,
}) => {
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [quadrantSize, setQuadrantSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const { width, height } = quadrantSize;
    setSpinnerStatus(width === 0 && height === 0);
  }, [quadrantSize])

  /**
   * This method displays a shot made or missed image location positioned on a quadrant of the basket ball court.
   * @param {image} shotPositionIndicator 
   * @param {object} stylePosition 
   * @returns 
   */
  const getQudarantShotPositions = (shotPositionIndicator, stylePosition) => {
    return <img 
              src={shotPositionIndicator} 
              width="10px" 
              height="10px" 
              alt=""
              style={{ zIndex: 10, position: 'absolute', ...stylePosition }}
            />
  };

  /**
   * This method calculates the x- and y- position for a shot location image on a specific quadrant of the basketball court.
   * @param {int} xcor 
   * @param {int} ycor 
   * @param {int} quadrantHeight 
   * @param {int} quadrantWidth 
   * @returns 
   */
  const getXYBasketCourtPosition = (xcor, ycor, quadrantHeight, quadrantWidth) => {
    const xPosition = ( xcor * quadrantHeight ) / X_COORDINATE_SCALE;
    const yPosition = ( ycor * quadrantWidth ) / Y_COORDINATE_SCALE;
    return { xPosition, yPosition };
  };

  /****************************************** THE LEFT-HALF OF THE BASKETBALL COURT IS THE SHOTS-TAKEN AREA FOR THE HOME TEAM **************************************/

  /**
   * This method displays shots-made images for the Home Team in the top-left quadrant of the basketball court.
   * @param {array} homeShotMadeLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showHomeTeamShotsMadePositionsInTopLeftQuadrant = (homeShotMadeLocations, quadrantSize) => {
    const shotsInQuadrant = homeShotMadeLocations?.filter(({ shotLocation }) => shotLocation.x > 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(shotLocation.x, shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(HomeTeamShotsMadeIndicator, { bottom: xPosition, left: yPosition + 5 })
    }));
  };

  /**
   * This method displays shots-missed images for the Home Team in the top-left quadrant of the basketball court.
   * @param {array} homeShotMissedLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showHomeTeamShotsMissedPositionsInTopLeftQuadrant = (homeShotMissedLocations, quadrantSize) => {
    const shotsInQuadrant = homeShotMissedLocations?.filter(({ shotLocation }) => shotLocation.x > 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(shotLocation.x, shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(HomeTeamShotsMissedIndicator, { bottom: xPosition, left: yPosition + 5 })
    }));
  };

  /**
   * This method displays shots-made images for the Home Team in the bottom-left quadrant of the basketball court.
   * @param {array} homeShotMadeLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showHomeTeamShotsMadePositionsInBottomLeftQuadrant = (homeShotMadeLocations, quadrantSize) => {
    const shotsInQuadrant = homeShotMadeLocations?.filter(({ shotLocation }) => shotLocation.x < 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(Math.abs(shotLocation.x), shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(HomeTeamShotsMadeIndicator, { top: xPosition, left: yPosition + 5 })
    }))
  };

  /**
   * This method displays shots-missed images for the Home Team in the bottom-left quadrant of the basketball court.
   * @param {array} homeShotMissedLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showHomeTeamShotsMissedPositionsInBottomLeftQuadrant = (homeShotMissedLocations, quadrantSize) => {
    const shotsInQuadrant = homeShotMissedLocations?.filter(({ shotLocation }) => shotLocation.x < 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(Math.abs(shotLocation.x), shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(HomeTeamShotsMissedIndicator, { top: xPosition, left: yPosition + 5 })
    }))
  };
  /*****************************************************************************************************************************************************************/

  /****************************************** THE RIGHT-HALF OF THE BASKETBALL COURT IS THE SHOTS-TAKEN AREA FOR THE AWAY TEAM *************************************/

  /**
   * This method displays shots-made images for the Away Team in the top-right quadrant of the basketball court.
   * @param {array} awayShotMadeLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showAwayTeamShotsMadePositionsInTopRightQuadrant = (awayShotMadeLocations, quadrantSize) => {
    const shotsInQuadrant = awayShotMadeLocations?.filter(({ shotLocation }) => shotLocation.x > 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(shotLocation.x, shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(AwayTeamShotsMadeIndicator, { bottom: xPosition, right: yPosition + 5 })
    }));
  };

  /**
   * This method displays shots-missed images for the Away Team in the top-right quadrant of the basketball court.
   * @param {array} awayShotMissedLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showAwayTeamShotsMissedPositionsInTopRightQuadrant = (awayShotMissedLocations, quadrantSize) => {
    const shotsInQuadrant = awayShotMissedLocations?.filter(({ shotLocation }) => shotLocation.x > 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(shotLocation.x, shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(AwayTeamShotsMissedIndicator, { bottom: xPosition, right: yPosition + 5 })
    }));
  };

  /**
   * This method displays shots-made images for the Away Team in the bottom-right quadrant of the basketball court.
   * @param {array} awayShotMadeLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showAwayTeamShotsMadePositionsInBottomRightQuadrant = (awayShotMadeLocations, quadrantSize) => {
    const shotsInQuadrant = awayShotMadeLocations?.filter(({ shotLocation }) => shotLocation.x < 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(Math.abs(shotLocation.x), shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(AwayTeamShotsMadeIndicator, { top: xPosition, right: yPosition + 5 })
    }));
  };

  /**
   * This method displays shots-missed images for the Away Team in the bottom-right quadrant of the basketball court.
   * @param {array} awayShotMissedLocations 
   * @param {object} quadrantSize 
   * @returns 
   */
  const showAwayTeamShotsMissedPositionsInBottomRightQuadrant = (awayShotMissedLocations, quadrantSize) => {
    const shotsInQuadrant = awayShotMissedLocations?.filter(({ shotLocation }) => shotLocation.x < 0);
    return (shotsInQuadrant?.map(({ shotLocation }) => {
      const { xPosition, yPosition } = getXYBasketCourtPosition(Math.abs(shotLocation.x), shotLocation.y, quadrantSize.height, quadrantSize.width);
      return getQudarantShotPositions(AwayTeamShotsMissedIndicator, { top: xPosition, right: yPosition + 5 })
    }));
  };
  /*****************************************************************************************************************************************************************/

  const onLoadHandler = event => {
    new ResizeObserver(resizeEntry => {
      const divWidth  = resizeEntry[0].target.clientWidth;
      const divHeight = resizeEntry[0].target.clientHeight;
      const quadrantWidth = divWidth / 2;
      const quadrantHeight = (divHeight / 2) - 15;
      setQuadrantSize({ width: quadrantWidth, height: quadrantHeight });
    }).observe(event.currentTarget);
  };

  return (
    <div style={{ width: '100%', paddingLeft: 5, paddingRight: 5, paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
      <div onLoad={onLoadHandler} style={{ width: 'fit-content', position: 'relative', }}>
        <img
          src={BasketballCourt}
          width="auto"
          height={`${basketballCourtHeight}px`}
          style={{ objectFit: "contain" }}
        />

        {spinnerStatus && (
          <Spinner 
            position={"absolute"}
          />
        )}

        {/********* BASKET BALL COURT TOP-LEFT QUADRANT ********/}
        <div style={{ width: '50%', height: '50%', position: 'absolute', top: 0, left: 0, bottom: '50%', }}>
          {showHomeTeamShotsMadePositionsInTopLeftQuadrant(homeTeamShotMadeLocations, quadrantSize)}
          {showHomeTeamShotsMissedPositionsInTopLeftQuadrant(homeTeamShotMissedLocations, quadrantSize)}
        </div>

        {/********* BASKET BALL COURT BOTTOM-LEFT QUADRANT ********/}
        <div style={{ width: '50%', height: '50%', position: 'absolute', top: '50%', left: 0, bottom: 0, }}>
          {showHomeTeamShotsMadePositionsInBottomLeftQuadrant(homeTeamShotMadeLocations, quadrantSize)}
          {showHomeTeamShotsMissedPositionsInBottomLeftQuadrant(homeTeamShotMissedLocations, quadrantSize)}
        </div>

        {/********* BASKET BALL COURT TOP-RIGHT QUADRANT *********/}
        <div style={{ width: '50%', height: '50%', position: 'absolute', top: 0, right: 0, bottom: '50%', }}>
          {showAwayTeamShotsMadePositionsInTopRightQuadrant(awayTeamShotMadeLocations, quadrantSize)}
          {showAwayTeamShotsMissedPositionsInTopRightQuadrant(awayTeamShotMissedLocations, quadrantSize)}
        </div>

        {/********* BASKET BALL COURT BOTTOM-RIGHT QUADRANT *********/}
        <div style={{ width: '50%', height: '50%', position: 'absolute', top: '50%', right: 0, bottom: 0, }}>
          {showAwayTeamShotsMadePositionsInBottomRightQuadrant(awayTeamShotMadeLocations, quadrantSize)}
          {showAwayTeamShotsMissedPositionsInBottomRightQuadrant(awayTeamShotMissedLocations, quadrantSize)}
        </div>
      </div>
    </div>
  );
};

export default BasketballCourtShotLocation;