import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const homeCalendarSlice = createSlice({
  name: 'homeCalendar',
  initialState: {
    collapsed: false,
    /** Initial start date is the start of the previous month */
    startDate: moment().subtract(1, 'months').startOf('month').toISOString(),
    /** Initial end date is the end of the next month */
    endDate: moment().add(1, 'months').endOf('month').toISOString(),
  },
  reducers: {
    updateHomeCalendarCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    updateStartDate: (state, action) => {
      if (action.payload) state.startDate = action.payload;
    },
    updateEndDate: (state, action) => {
      if (action.payload) state.endDate = action.payload;
    },
    updateDateRange: (state, action) => {
      if (action.payload.startDate) state.startDate = action.payload.startDate;
      if (action.payload.endDate) state.endDate = action.payload.endDate;
    },
    resetDateRange: (state, action) => {
      /** Initial start date is the start of the previous month */
      state.startDate = moment().subtract(1, 'months').startOf('month').toISOString();
      /** Initial end date is the end of the next month */
      state.endDate = moment().add(1, 'months').endOf('month').toISOString();
    },
  },
});

export const {
  updateHomeCalendarCollapsed,
  updateStartDate,
  updateEndDate,
  updateDateRange,
  resetDateRange,
} = homeCalendarSlice.actions;

export const selectHomeCalendarCollapsed = (state) => state.homeCalendar.collapsed;
export const selectStartDate = (state) => state.homeCalendar.startDate;
export const selectEndDate = (state) => state.homeCalendar.endDate;
export const selectDateRange = (state) => ({
  startDate: state.homeCalendar.startDate,
  endDate: state.homeCalendar.endDate,
});

export default homeCalendarSlice.reducer;