import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ButtonElement from '../../../../components/Buttons/ButtonElement';
import logger from '../../../../logger';
import { buttonThemes, StyledFlexRow as Row } from '../../../../styles';
import ClockManagerEvents from '../../../ClockManager/ClockManagerEvents';
import GameEvents, { getEditForm } from '../../controller/gameEvents';
const log = logger('PlayFeed EditTab', 'info');

export const EditTab = ({ play, exitEditMode, gameData, lineupData }) => {
  const queryClient = useQueryClient();
  const {
    homeTeamId,
    homeTeamName,
    awayTeamId,
    awayTeamName,
    leagueNumPeriods,
    leagueTimePerPeriodInMins,
    leagueOvertimeDurationInMins,
  } = gameData;

  const parsedUser = useSelector(
    (state) => state?.user?.value && JSON.parse(state?.user?.value)
  );
  const statcollFirstName = parsedUser?.attributes?.name;
  const statcollLastName = parsedUser?.attributes?.family_name;
  const statcollEmail = parsedUser?.attributes?.email;
  const statcollSub = parsedUser?.attributes?.sub;

  useEffect(() => {
    log.debug('Editing play: ', play);
  }, [play]);

  const { event, eventType } = play;
  const editForm = getEditForm(event);
  const FieldsetComponent = editForm.FieldsetComponent;

  /* TODO confirm OT check works correctly */
  const periodDuration =
    event.gamePeriodNumber > leagueNumPeriods
      ? leagueOvertimeDurationInMins
      : leagueTimePerPeriodInMins;

  const commonProps = {
    periodDuration,
    homeTeamId,
    homeTeamName,
    awayTeamId,
    awayTeamName,
    play,
    event,
  };
  let fieldsetComponentProps = { ...commonProps };

  const lookupGameLineupPlayer = (gameLineupPlayerId) =>
    play.teamId === homeTeamId
      ? lineupData.homeTeamLineupMap.get(gameLineupPlayerId)
      : lineupData.awayTeamLineupMap.get(gameLineupPlayerId);

  let isClockManager = false;

  switch (eventType) {
    case ClockManagerEvents.JUMPBALL:
    case ClockManagerEvents.TIMEOUT:
      isClockManager = true;
      fieldsetComponentProps = {
        ...commonProps,
        allowedEventTypes: [
          ClockManagerEvents.JUMPBALL,
          ClockManagerEvents.TIMEOUT,
        ],
      };
      break;
    case ClockManagerEvents.SUBSTITUTION:
      isClockManager = true;
      fieldsetComponentProps = {
        ...commonProps,
        eventTeamId: play.teamId,
        eventCourtPlayer: lookupGameLineupPlayer(play.event.gameLineupPlayerId),
        eventBenchPlayer: lookupGameLineupPlayer(
          play.subPlay.event.gameLineupPlayerId
        ),
        homeTeamOnCourtPlayers: lineupData.homeTeamOnCourtPlayers,
        homeTeamOnBenchPlayers: lineupData.homeTeamOnBenchPlayers,
        awayTeamOnCourtPlayers: lineupData.awayTeamOnCourtPlayers,
        awayTeamOnBenchPlayers: lineupData.awayTeamOnBenchPlayers,
      };
      break;
    case GameEvents.TWO_POINT_MADE:
    case GameEvents.TWO_POINT_MISS:
    case GameEvents.THREE_POINT_MADE:
    case GameEvents.THREE_POINT_MISS:
      fieldsetComponentProps = {
        ...commonProps,
        allowedEventTypes: [
          GameEvents.TWO_POINT_MADE,
          GameEvents.TWO_POINT_MISS,
          GameEvents.THREE_POINT_MADE,
          GameEvents.THREE_POINT_MISS,
        ],
        courtPlayers:
          play.teamId === homeTeamId
            ? lineupData.homeTeamOnCourtPlayers
            : lineupData.awayTeamOnCourtPlayers,
      };
      break;
    default:
      /* Edit not supported, go back to Play-by-Play */
      log.warn(`Edit not supported for event type ${eventType}`);
      exitEditMode();
  }

  return (
    <Formik
      validateOnBlur
      validationSchema={editForm.getValidationSchema(fieldsetComponentProps)}
      initialValues={editForm.getInitialValues(play)}
      onSubmit={(values) => {
        editForm.handleSubmit({
          play,
          values: {
            ...values,
            /* Add statcoll user fields to form values */
            statcollFirstName,
            statcollLastName,
            statcollSub,
            statcollEmail,
          },
          gameData,
          lineupData,
          queryClient,
        });
        exitEditMode();
      }}
    >
      {({ handleSubmit }) => (
        <EditForm noValidate onSubmit={handleSubmit}>
          <Row style={isClockManager ? {} : { flex: 1 }}>
            <Col sm={12}>
              <FieldsetComponent {...fieldsetComponentProps} />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <FormButton
                type="submit"
                label="Save Changes"
                theme={buttonThemes.GREEN}
              />
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <FormButton
                type="button"
                onClick={exitEditMode}
                label="Cancel"
                theme={buttonThemes.BLACK}
              />
            </Col>
          </Row>
        </EditForm>
      )}
    </Formik>
  );
};

const FormButton = styled(ButtonElement)`
  &&& {
    font: normal normal 600 15px/20px Open Sans;
    border-radius: 5px;
    width: calc(100% - 0.5rem);
    margin: 0;
    height: 43px;
    min-width: unset !important;
  }
`;

const EditForm = styled(Form)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  justify-content: center;
  height: 100%;
`;
