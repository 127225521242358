import styled from 'styled-components';
import { colors, gradients } from '../../../styles/colorsStatsCollector';

const StyledButton = styled(({ children, ...props }) => (
  <button {...props}>
    <span>{children}</span>
  </button>
))`
  height: calc(40px + 1vh);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  border: ${({ selected }) =>
    selected ? 'none' : `2px solid ${colors.BLUE[100]}`};
  border-radius: 8px;
  font: normal normal 500 14px/19px Open Sans;
  color: ${({ selected, theme: { lightMode } }) =>
    selected
      ? colors.WHITE[100]
      : lightMode
      ? colors.BLACK[100]
      : colors.GRAY[500]};
  background: ${({ selected, theme: { lightMode } }) =>
    selected
      ? gradients.BLUE
      : lightMode
      ? gradients.LIGHT_GRAY
      : gradients.BLACK};

  &:disabled {
    opacity: 0.5;
    border-color: ${colors.GRAY[1700]};
  }
`;

export default StyledButton;
