import React, { useCallback, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { colors, gradients } from '../../styles/colorsStatsCollector';
import ClockManagerEvents from '../../pages/ClockManager/ClockManagerEvents';
import logger from '../../logger';

const log = logger('EndGameModal', 'info');

const passwordValidationSchema = Yup.object({
  password: Yup.string().required(),
});

const StyledText = styled.div`
  color: #b7b7b7;
  font: normal normal normal 20px/24px Open Sans;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
  justify-items: center;
  margin-top: 35px;
`;

const Button = styled.button`
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font: normal normal 500 16px/24px Inter;
  color: ${colors.GRAY[500]};

  &:disabled {
    opacity: 0.4;
  }
`;

const StyledEndGameButton = styled(Button)`
  width: 200px; 
  background: ${gradients.DARKER_BLUE};
  box-shadow: 0px 3px 6px #000000;
  color: white;
`;

const StyledCancelGameButton = styled(Button)`
  width: 200px;
  background: ${gradients.RED};
  box-shadow: 0px 3px 6px #000000;
  color: white;
`;

const StyledCancelButton = styled(Button)`
  color: #b4b4b4;
  border: 1px solid #7b7b7b;
  grid-column-start: span ${({ span = 1 }) => span};
  background: none;
`;

const EndGameReasonScreen = forwardRef(
  (
    { handleCloseModal, setEndGameReason, disableGameFinishedButton, ...props },
    ref
  ) => {
    return (
      <div {...props} ref={ref}>
        <StyledText>Select a reason to end the game</StyledText>
        <ButtonContainer>
          <StyledEndGameButton
            onClick={() => setEndGameReason(ClockManagerEvents.GAME_ENDED)}
            disabled={disableGameFinishedButton}
          >
            Game Finished
          </StyledEndGameButton>
          <StyledCancelGameButton
            onClick={() => setEndGameReason(ClockManagerEvents.GAME_CANCELED)}
          >
            Game Cancellation
          </StyledCancelGameButton>
          <StyledCancelButton onClick={handleCloseModal} span={2}>
            Cancel
          </StyledCancelButton>
        </ButtonContainer>
      </div>
    );
  }
);

const EndGameConfirmationScreen = forwardRef(
  ({ handleCloseModal, endGameReason, onSubmit, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    return (
      <div {...props} ref={ref}>
        <StyledText>
          Are you sure you want to{' '}
          {endGameReason === ClockManagerEvents.GAME_ENDED ? (
            <span style={{ color: colors.BLUE[400] }}>end</span>
          ) : (
            <span style={{ color: colors.RED[400] }}>cancel</span>
          )}{' '}
          the game?
        </StyledText>

        <Formik
          validationSchema={passwordValidationSchema}
          onSubmit={async (values) => {
            const wasAuthSuccessful = await onSubmit(values.password);
            if (wasAuthSuccessful) {
              setInvalidPassword(false);
              handleCloseModal();
            } else {
              setInvalidPassword(true);
            }
          }}
          initialValues={{
            password: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 17,
                }}
              >
                <Form.Label
                  style={{
                    font: 'normal normal normal 16px/24px Inter',
                    letterSpacing: '0px',
                    color: '#a8a8a8',
                  }}
                >
                  Enter Password to continue
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'input' : 'password'}
                    name="password"
                    // style={{ borderRight: 'none' }}
                    value={values.password}
                    autoComplete="current-password"
                    isValid={
                      touched.password && !errors.password && !invalidPassword
                    }
                    isInvalid={
                      touched.password && (errors.password || invalidPassword)
                    }
                    onChange={handleChange}
                  />
                  <InputGroup.Text
                    style={{
                      background: 'white',
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <ButtonContainer>
                <StyledEndGameButton
                  style={{ width: 132 }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Yes
                </StyledEndGameButton>
                <StyledCancelButton
                  style={{ width: 132 }}
                  onClick={handleCloseModal}
                >
                  No
                </StyledCancelButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

const EndGameModal = ({
  modalOpen,
  handleCloseModal,
  endGame,
  disableGameFinishedButton,
}) => {
  const classes = useStyles();
  const [endGameReason, setEndGameReason] = useState();

  const username = useSelector(
    (state) => JSON.parse(state.user?.value)?.username
  );

  const onClose = useCallback(() => {
    setEndGameReason();
    handleCloseModal();
  }, [handleCloseModal]);

  const endGameOnAuthSuccess = useCallback(
    async (password) => {
      log.debug('Password entered: ', password);
      try {
        await Auth.signIn(username, password);

        /* Auth successful, create end/cancel game event */
        await endGame(endGameReason);

        return true;
      } catch (error) {
        log.warn('Sign-in failed', error);
        return false;
      }
    },
    [endGame, endGameReason, username]
  );

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={modalOpen}
      onClose={onClose}
    >
      {!endGameReason ? (
        <EndGameReasonScreen
          className={classes.paper}
          handleCloseModal={onClose}
          setEndGameReason={setEndGameReason}
          disableGameFinishedButton={disableGameFinishedButton}
        />
      ) : (
        <EndGameConfirmationScreen
          className={classes.paper}
          handleCloseModal={onClose}
          endGameReason={endGameReason}
          onSubmit={endGameOnAuthSuccess}
        />
      )}
    </Modal>
  );
};

export default EndGameModal;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#1C1C1C',
    border: '1px solid #707070',
    borderRadius: 10,
    width: '50%',
    height: 'auto',
    boxShadow: theme.shadows[5],
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
