import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Panel } from './components/Panel';
import EventActionButtons from './components/EventActionButtons';
import EventActions from './components/EventActions';
import { TEAM_PLAYERS_DEF } from './PropTypeDefs.type';

/**
 * In the ScoreKeeper UI, this is the large panel in the center/right.
 */
export const EventActionPanel = ({
  homeTeamLogo,
  awayTeamLogo,
  homeTeamPlayers,
  awayTeamPlayers,
  possessionTeam,
  gameData,
  lineupData,
  ...props
}) => {
  return (
    <Container {...props}>
      <EventActions
        homeTeamLogo={homeTeamLogo}
        awayTeamLogo={awayTeamLogo}
        homeTeamPlayers={homeTeamPlayers}
        awayTeamPlayers={awayTeamPlayers}
        possessionTeam={possessionTeam}
        gameData={gameData}
        lineupData={lineupData}
        style={{
          flex: 1,
          width:'57%'
        }}
      />
      <EventActionButtons
        style={{
          width: '43%',
          maxWidth: 355,
        }}
      />
    </Container>
  );
};

EventActionPanel.propTypes = {
  homeTeamLogo: PropTypes.any,
  awayTeamLogo: PropTypes.any,
  homeTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF)),
  awayTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF)),
};

const Container = styled(Panel)`
  // width: 810px;
  // height: 660px;

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 24px 20px;
`;

export default EventActionPanel;
