import React, { useLayoutEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import GameLeadersStats from './GameLeaders';
import TeamPercentageStats from './TeamPercentages';
import MainStats from './MainStats';
import { getGamePercentageStatsData } from './teamStatsUtility';

const STATS_FIELDS = [
  'Assists',
  'Blocks',
  'Steal',
  'Defensive Rebound',
  'Offensive Rebound',
  'Total Rebounds',
  'Field Goal Att',
  'Field Goal Made',
  'Free Throw',
  'Free Throw Att',
  'Personal Foul',
  'Technical Foul',
  'Turnover',
  '2PT Att',
  '2PT Made',
  '3PT Att',
  '3PT Made',
  'Total Points',
  'Timeouts',
];

const AWAY_TEAM_STATS = [
  'away_assist',
  'away_blocks',
  'away_steal',
  'away_def_rebound',
  'away_off_rebound',
  'away_total_rebounds',
  'away_fg_att',
  'away_fg_made',
  'away_free_throw',
  'away_free_throw_atts',
  'away_personal_foul',
  'away_tech_foul',  
  'away_turnover',
  'away_two_point_att',
  'away_two_point_made',
  'away_three_point_att',
  'away_three_point_made',
  'away_total_points',
  'away_timeouts',
];

const HOME_TEAM_STATS = [
  'home_assist',
  'home_blocks',
  'home_steal',
  'home_def_rebound',
  'home_off_rebound',
  'home_total_rebounds',
  'home_fg_att',
  'home_fg_made',
  'home_free_throw',
  'home_free_throw_atts',
  'home_personal_foul',
  'home_tech_foul',
  'home_turnover',
  'home_two_point_att',
  'home_two_point_made',
  'home_three_point_att',
  'home_three_point_made',
  'home_total_points',
  'home_timeouts',
];

const teamStatsStyles = {
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const TeamStatsData = ({
  awayTeamImage, 
  homeTeamImage, 
  teamStatsData, 
  percentageStatsData, 
  setStatsTab, 
  awayTeamPlayersGameStats, 
  homeTeamPlayersGameStats, 
}) => {
  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, }} className='d-flex justify-content-between'>
      <Col xs={3} className='p-0' style={{ ...teamStatsStyles, width: '33%', }}>
        <GameLeadersStats
          setStatsTab={setStatsTab}
          awayTeamImage={awayTeamImage}
          homeTeamImage={homeTeamImage}
          awayTeamPlayersGameStats={awayTeamPlayersGameStats}
          homeTeamPlayersGameStats={homeTeamPlayersGameStats}
        />
        <TeamPercentageStats
          awayTeamImage={awayTeamImage}
          homeTeamImage={homeTeamImage}
          percentageStatsData={percentageStatsData}
        />
      </Col>
      <Col xs={9} className='p-0' style={{ ...teamStatsStyles, width: '66%' }}>
        <MainStats
          awayTeamImage={awayTeamImage}
          homeTeamImage={homeTeamImage}
          teamStatsData={teamStatsData}
        />
      </Col>
    </Row>
  );
};

const TeamStats = ({
  awayTeamImage, 
  homeTeamImage, 
  gameStatsData, 
  setStatsTab, 
  awayTeamPlayersGameStats, 
  homeTeamPlayersGameStats, 
  scrollListRef,
}) => {
  useLayoutEffect(() => {
    scrollListRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
    
  const getGameTeamStatsData = statsData => {
    /* Select Stats Data For Each Team */
    const getTeamStats = (teamStatsDataFields, statsData) => {
      return teamStatsDataFields.map(statDataField => {
        const statData = {};
        statData[statDataField] = statsData[statDataField];
        return statData;
      });
    };

    /* Away Team Stats Data */
    const awayStatsData = getTeamStats(AWAY_TEAM_STATS, statsData);

    /* Home Team Stats Data */
    const homeStatsData = getTeamStats(HOME_TEAM_STATS, statsData);

    /* Game Teams Stat Record Data */
    const teamsStatsData = STATS_FIELDS.map((statField, index) => {
      const awayStatData = awayStatsData[index];
      const homeStatData = homeStatsData[index];
      return { statField, ...awayStatData, ...homeStatData };
    });

    return teamsStatsData;
  };

  return (
    <TeamStatsData
      awayTeamImage={awayTeamImage}
      homeTeamImage={homeTeamImage}
      teamStatsData={getGameTeamStatsData(gameStatsData)}
      percentageStatsData={getGamePercentageStatsData(gameStatsData)}
      setStatsTab={setStatsTab}
      awayTeamPlayersGameStats={awayTeamPlayersGameStats}
      homeTeamPlayersGameStats={homeTeamPlayersGameStats}
    />
  );
};

export default TeamStats;