import { GamesTabs } from '../utils/gameUtil';

export const GameTabsSliceObj = {
  initialState: {
    activeTab: GamesTabs.NONE,
    /** Used when an game object is selected on one tab, and changing tabs is disabled */
    canChangeTabs: true,
  },
  reducers: {
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateCanChangeTabs: (state, action) => {
      state.canChangeTabs = action.payload;
    },
  },
};
