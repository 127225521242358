import styled, { ThemeContext } from 'styled-components';
import JerseyDark from '../../assets/svg/JerseyDark.svg';
import JerseyLight from '../../assets/svg/JerseyLight.svg';
import { ReactComponent as JerseyHighlight } from '../../assets/svg/JerseyHighlight.svg';
import { useContext } from 'react';
import { colors } from '../../styles/colorsStatsCollector';
import { shortenFullName } from '../../utils/gameLineupUtils';
import { ALERT_VARIANTS } from '../../components/alerts/Alert';

const StyledPlayerJerseyDiv = styled.div`
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
  position: relative;
  width: 100%;
  height: 100%;
  border: ${({ selected }) => (selected ? '4px solid' : 'none')};
  border-color: ${(props) => props.borderColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 14px 0px 0px 0px;

  &:before {
    display: ${({ newIndicator }) => (newIndicator ? 'block' : 'none')};
    content: 'New';
    text-align: center;
    font: normal normal normal 7px/7px Inter;
    letter-spacing: 0px;
    color: #ffffff;
    background: #438efd;
    position: absolute;
    top: -15px;
    padding: 2px;
  }
`;

const PlayerJerseyContainer = styled.div`
  position: relative;
  width: 61px;
  height: 72px;
`;

const StyledPlayerJersey = styled.img`
  width: 55px;
  height: 67px;
  margin-top: 2px;
`;

const StyledJerseyHighlight = styled(JerseyHighlight)`
  display: ${({ $isSelected }) => ($isSelected ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  width: 61px;
  height: 72px;

  path {
    stroke: ${({ $highlightColor }) => $highlightColor};
  }
`;

const StyledPlayerJerseyNumber = styled.div`
  position: absolute;
  font: normal 20px/28px 'Race Sport';
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.WHITE[100] : colors.BLACK[100]};
  text-align: center;
  width: 100%;
  top: 40%;
`;

const StyledPlayerName = styled.div`
  font: 10px/18px Open Sans;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  letter-spacing: 0.2px;
  width: 100%;
  min-width: 3.2vw;
`;

const PlayerTile = ({
  player,
  disabled,
  onCourt,
  isSelected,
  isHighlighted,
  highlightColor,
  substitutionHandler,
}) => {
  const lightMode = useContext(ThemeContext).lightMode;

  return (
    <StyledPlayerJerseyDiv
      disabled={disabled}
      onClick={() =>
        !disabled &&
        substitutionHandler(player)
      }
      newIndicator={isHighlighted && highlightColor === ALERT_VARIANTS.BLUE}
    >
      <PlayerJerseyContainer>
        <StyledPlayerJersey src={lightMode ? JerseyDark : JerseyLight} alt="" />

        <StyledJerseyHighlight
          src={JerseyHighlight}
          alt=""
          $isSelected={isSelected || isHighlighted}
          $highlightColor={highlightColor}
        />
        <StyledPlayerJerseyNumber isOnCourt={onCourt}>
          {player.playerJerseyNumber}
        </StyledPlayerJerseyNumber>
      </PlayerJerseyContainer>

      <StyledPlayerName isSelected={isSelected || isHighlighted}>
        {player?.player?.firstName &&
          player?.player?.lastName &&
          shortenFullName(
            player?.player?.firstName,
            player?.player?.lastName,
            14
          )}
      </StyledPlayerName>
    </StyledPlayerJerseyDiv>
  );
};

export default PlayerTile;
