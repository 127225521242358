import React from 'react';
import styled from 'styled-components';
import { colors, typeScale } from '../../styles';

// using div instead of footer because in Layout.jsx the component is wrapped in footer
const StyledFooter = styled.div`
  background-color: ${colors.GRAY[400]};
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10px 5px;
`;

const StyledText = styled.div`
  color: ${colors.WHITE[100]};
  font-size: ${typeScale.SMALL.fontSize};
  line-height: 2;
  font-family: Inter;
  color: #d9d9d9;
`;

// const StyledTextEmail = styled.a`
//   color: ${colors.WHITE[100]};
//   font-size: ${typeScale.SMALL.fontSize};
//   line-height: ${typeScale.SMALL.lineHeight};
//   text-decoration: none;
// `;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledText>© 2021 FASTstats All Rights Reserved</StyledText>
      {/* <StyledTextEmail href='mailto:support@faststats.live'>
        support@faststats.live
      </StyledTextEmail> */}
    </StyledFooter>
  );
};

export default Footer;
