const UserValidation = {
  firstName: {
    isRequired: {
      value: true,
      message: 'Please enter First Name',
    },
  },
  lastName: {
    isRequired: {
      value: true,
      message: 'Please enter Last Name',
    },
  },
  email: {
    isRequired: {
      value: true,
      message: 'Please enter email',
    },
    regex: {
      value: /^[a-zA-Z0-9._+]+\@[a-zA-Z]+\.[a-zA-Z]{2,3}$/g, 
      message: 'Email is invalid',
    },
  },
  role: {
    isRequired: {
      value: true,
      message: 'Please select Assign Role(s)',
    },
  },
  acceptedTerms: {
    isRequired: {
      value: true,
      message: 'Select the checkbox after reading the statement.',
    },
  },
};

export default UserValidation;