export { detectMobileMedia as isMobile } from '../styles/media';

let scrollTimer = null;

export const addScrollListClass = (
  scrollListElement,
  scrollListClass = 'list-items-scroll'
) => {
  scrollListElement?.classList.add(scrollListClass);
};

export const removeScrollListClass = (scrollListElement, scrollListClass) => {
  scrollListElement?.classList.remove(scrollListClass);
};

export const onListScrollHandler = (
  isMobile,
  scrollListElement,
  scrollListClass = 'ipad-page-list-Items'
) => {
  if (isMobile) {
    if (scrollTimer != null) {
      clearTimeout(scrollTimer);
    }

    if (scrollListElement?.classList.contains(scrollListClass) === false) {
      addScrollListClass(scrollListElement, scrollListClass);
    }

    scrollTimer = setTimeout(() => {
      removeScrollListClass(scrollListElement, scrollListClass);
    }, 200);
  }
};
