import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndOfResultsIndicator from './EndOfResultsIndicator';
import { infiniteScrollContainerStyle } from '../styles/infiniteScroll.js';
import Spinner from './Spinner';

const CustomSpinner = () => (
  <div
    style={{
      width: '100%',
      margin: '25px 0 50px',
      display: 'flex',
    }}
  >
    <Spinner style={{ margin: 'auto' }} />
  </div>
);

const InfiniteListScroll = ({
  displayedData = [],
  unavailableDataStmt = '',
  displayedDataFilter = () => {},
  fetchNextPage,
  hasNextPage,
}) => {
  return (
    <InfiniteScroll
      dataLength={displayedData?.length}
      next={() => fetchNextPage()}
      hasMore={hasNextPage}
      loader={<CustomSpinner />}
      scrollableTarget={'infiniteScrollContainer'}
      style={infiniteScrollContainerStyle}
      scrollThreshold={'350px'}
      endMessage={<EndOfResultsIndicator enabled={displayedData?.length > 0} />}
    >
      {displayedData.length > 0 ? (
        displayedDataFilter()
      ) : (
        <EmptyDataList unavailableDataStmt={unavailableDataStmt} />
      )}
    </InfiniteScroll>
  );
};

const EmptyDataList = ({ unavailableDataStmt }) => (
  <div style={unavailableStmtStyles}>{unavailableDataStmt}</div>
);

const unavailableStmtStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default InfiniteListScroll;
