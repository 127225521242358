import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { getGameByDateTime } from '../../api/gamesService';
import logger from '../../logger';
import { textEllipsisStyle } from '../../styles';
import { LIST_PAGINATION_LIMIT } from '../../utils/constantsUtils';
import { generateValidateCallback } from '../../utils/formikToolkit';
import {
  seasonFormatDisplay,
  seasonFormatDisplayCondensed
} from '../../utils/seasonsUtil';
import { truncateText } from '../../utils/stringUtil';
import validation from '../../validation-config/game';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import InputSelectDropdown from '../Dropdowns/InputSelectDropdown';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import Spinner from '../Spinner';
import ModalComponent from './ModalComponent';
const log = logger('AddEditGameModal', 'info');

const LeagueInputSelectDropdown = ({
  isNewGame,
  leaguesList,
  seasonList,
  setLeagueSelectedSeasonList,
  formik,
  setIsBackButtonClicked,
}) => {
  const { leagueId } = formik.values;

  /* When league ID is set, filter for seasons matching the chosen league ID. */
  useEffect(() => {
    if (leagueId?.length > 0) {
      const selectedLeagueSeasonsList = seasonList.filter(
        (season) => season?.leagueId === leagueId
      );
      setLeagueSelectedSeasonList(selectedLeagueSeasonsList);
    } else {
      setLeagueSelectedSeasonList([]);
    }
  }, [leagueId]);

  return (
    <InputSelectDropdown
      label="League"
      name="leagueId"
      required={true}
      options={leaguesList}
      disabled={!isNewGame}
      labelKey={(option) => `${option.name} (${option.abbreviation})`}
      onFocus={() => setIsBackButtonClicked(false)}
    />
  );
};

const SeasonInputSelectDropdown = ({
  isNewGame,
  leagueSelectedSeasonList,
  teamList,
  seasonSelected,
  setSeasonSelected,
  setSeasonLeagueSelectedTeamList,
  setIsBackButtonClicked,
  formik,
}) => {
  let typeahead = useRef();
  const { leagueId, seasonId } = formik.values;

  /**
   * When season ID is selected, set seasonSelected with season object.
   * Also, filter team list for the selected league.
   */
  useEffect(() => {
    if (seasonId?.length > 0) {
      const selectedSeason = leagueSelectedSeasonList?.find(
        (seasonItem) => seasonItem?.id === seasonId
      );
      setSeasonSelected(selectedSeason);

      const selectedTeams = teamList?.filter(
        (teamItem) => teamItem?.leagueId === selectedSeason?.leagueId
      );
      setSeasonLeagueSelectedTeamList(selectedTeams);
    } else {
      setSeasonLeagueSelectedTeamList([]);
      setIsBackButtonClicked(false);
    }
  }, [seasonId]);

  /* Reset season field if league ID changes. */
  useEffect(() => {
    if (isNewGame) {
      if (!leagueId || seasonSelected?.leagueId !== leagueId) {
        typeahead.clear();
        formik.setFieldValue('seasonId', '');
        formik.setFieldError('seasonId', '');
        formik.setFieldTouched('seasonId', false, false);
      }
    }
  }, [leagueId]);

  const getLeagueSeasonLabelFormat = ({ seasonType, startDate, endDate }) => {
    const seasonFormat = seasonFormatDisplay({
      seasonType,
      startDate,
      endDate,
    });
    return `${seasonFormat}`;
  };

  return (
    <InputSelectDropdown
      label="Season"
      name="seasonId"
      required={true}
      options={leagueSelectedSeasonList}
      disabled={!isNewGame || leagueSelectedSeasonList.length === 0}
      labelKey={(option) => option && getLeagueSeasonLabelFormat(option)}
      onFocus={() => setIsBackButtonClicked(false)}
      setRef={(ref) => (typeahead = ref)}
    />
  );
};

const TeamInputSelectDropdown = ({
  isNewGame,
  label,
  name,
  seasonLeagueSelectedTeamList,
  fieldErrorMessage,
  isBackButtonClicked,
  formik,
}) => {
  let typeahead = useRef();
  const [customErrorMsg, setCustomErrorMsg] = useState(null);
  // const teamId = formik.values[name];
  const { seasonId } = formik.values;

  /* Check if there are available teams for the selected seasonId */
  useEffect(() => {
    if (!isBackButtonClicked) {
      if (seasonId?.length > 0 && seasonLeagueSelectedTeamList?.length === 0) {
        setCustomErrorMsg(fieldErrorMessage);
        formik.setFieldTouched(name, true, false);
      } else {
        formik.setFieldError(name, '');
        formik.setFieldTouched(name, false, false);
        setCustomErrorMsg(null);
      }
    }
  }, [seasonLeagueSelectedTeamList]);

  /* Clear team field if season ID changes. */
  useEffect(() => {
    if (isNewGame) {
      if (!isBackButtonClicked || !seasonId) {
        formik.setFieldValue(name, '');
        typeahead.clear();
        formik.setFieldError(name, '');
        formik.setFieldTouched(name, false, false);
      }
    }
  }, [seasonId]);

  return (
    <InputSelectDropdown
      label={label}
      name={name}
      required={true}
      options={seasonLeagueSelectedTeamList}
      disabled={!isNewGame || seasonLeagueSelectedTeamList.length === 0}
      labelKey={(option) => option && truncateText(option.name, 36)}
      customErrorMsg={customErrorMsg}
      setRef={(ref) => (typeahead = ref)}
    />
  );
};

const VenueSelectDropdown = ({
  // isNewGame,
  venueList,
  formik,
}) => {
  // let typeahead = useRef();

  return (
    <InputSelectDropdown
      label="Venue"
      name="venueId"
      required={true}
      options={venueList}
      disabled={
        !(formik.values.homeTeamId && formik.values.awayTeamId) ||
        formik.values.homeTeamId === formik.values.awayTeamId
      }
      labelKey={(option) => option && `${option.name}`}
      // setRef={(ref) => (typeahead = ref)}
    />
  );
};

const GameDateTimeSelectDropdown = ({ 
  isNewGame, 
  seasonSelected, 
  formik, 
}) => {
  const { gameDateTime } = formik.values;

  useEffect(() => {
    if (!gameDateTime) {
      formik.setFieldValue('acceptedTerms', false);
    }
  }, [gameDateTime]);

  return (
    <InputFieldComponent
      id="gameDateTime"
      name="gameDateTime"
      label="Date & Time"
      type="datetime-local"
      min={moment(seasonSelected?.startDate).valueOf() > moment().startOf('d').valueOf() ? seasonSelected?.startDate : moment().format("YYYY-MM-DDThh:mm")}
      max={seasonSelected?.endDate ? `${seasonSelected.endDate}T23:59` : ''}
      placeholder="Test"
      required={true}
      disabled={!formik.values.venueId}
    />
  );
};

const GameContentForm = ({
  isNewGame,
  leaguesList,
  seasonList,
  venueList,
  teamList,
  seasonSelected,
  setSeasonSelected,
  isBackButtonClicked,
  setIsBackButtonClicked,
  formik,
}) => {
  const [
    seasonLeagueSelectedTeamList,
    setSeasonLeagueSelectedTeamList,
  ] = useState(teamList);
  const [leagueSelectedSeasonList, setLeagueSelectedSeasonList] = useState(
    seasonList
  );

  return (
    <>
      <Row className="mb-3">
        <Col xs={12}>
          <LeagueInputSelectDropdown
            isNewGame={isNewGame}
            leaguesList={leaguesList}
            seasonList={seasonList}
            setLeagueSelectedSeasonList={setLeagueSelectedSeasonList}
            setIsBackButtonClicked={setIsBackButtonClicked}
            formik={formik}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <SeasonInputSelectDropdown
            isNewGame={isNewGame}
            leagueSelectedSeasonList={leagueSelectedSeasonList}
            teamList={teamList}
            setSeasonLeagueSelectedTeamList={setSeasonLeagueSelectedTeamList}
            seasonSelected={seasonSelected}
            setSeasonSelected={setSeasonSelected}
            setIsBackButtonClicked={setIsBackButtonClicked}
            formik={formik}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={6}>
          <TeamInputSelectDropdown
            isNewGame={isNewGame}
            label="Home Team"
            name="homeTeamId"
            fieldErrorMessage="Home Teams unavailable for Season/League"
            seasonLeagueSelectedTeamList={seasonLeagueSelectedTeamList}
            isBackButtonClicked={isBackButtonClicked}
            formik={formik}
          />
        </Col>

        <Col xs={6}>
          <TeamInputSelectDropdown
            isNewGame={isNewGame}
            label="Away Team"
            name="awayTeamId"
            fieldErrorMessage="Away Teams unavailable for Season/League"
            seasonLeagueSelectedTeamList={seasonLeagueSelectedTeamList}
            isBackButtonClicked={isBackButtonClicked}
            formik={formik}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <VenueSelectDropdown
            // isNewGame={isNewGame}
            venueList={venueList}
            formik={formik}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <GameDateTimeSelectDropdown
            isNewGame={isNewGame}
            seasonSelected={seasonSelected}
            formik={formik}
          />
        </Col>
      </Row>

      {isNewGame && (
        <Row className="mb-4">
          <Col xs={12}>
            <InputCheckboxLabel
              id="acceptedTerms"
              label="Please check the information you have entered as all or some of the fields will not be editable once the Game has been added."
              name="acceptedTerms"
              disabled={!formik.values.gameDateTime}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const GameContentInfo = ({ seasonList, venueList, teamList, formik }) => {
  const {
    gameDateTime,
    homeTeamId,
    awayTeamId,
    seasonId,
    venueId,
  } = formik.values;

  const seasonItem = seasonList.find(({ id }) => seasonId === id);
  const { startDate, endDate, seasonType, league } = seasonItem;

  const homeTeam = teamList.find(({ id }) => homeTeamId === id);
  const awayTeam = teamList.find(({ id }) => awayTeamId === id);
  const venueItem = venueList.find(({ id }) => venueId === id);
  const gameDate = moment(gameDateTime).format('MM/DD/YYYY');
  const gameTime = moment(gameDateTime).format('h:mm A');

  const infoTitleStyle = {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#707070',
    padding: 0,
    marginBottom: 10,
  };

  const dataTitleStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#616161',
  };

  const dataValueStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
    color: '#707070',
    width: '65%',
  };

  const GameInfo = [
    {
      title: 'Date',
      value: gameDate,
    },
    {
      title: 'Time',
      value: gameTime,
    },
    {
      title: 'Season Type',
      value: seasonFormatDisplay({ seasonType, startDate, endDate }),
    },
    {
      title: 'Home Team',
      value: homeTeam.name,
    },
    {
      title: 'Away Team',
      value: awayTeam.name,
    },
    {
      title: 'Venue',
      value: venueItem.name,
    },
  ];

  const LeagueInfo = [
    {
      title: 'Name',
      value: league?.name,
    },
    {
      title: 'Abbreviation',
      value: league?.abbreviation,
    },
    {
      title: 'Team Size',
      value: league?.teamSize,
    },
    {
      title: 'Players On Court',
      value: league?.numPlayersOnCourt,
    },
    {
      title: 'Players On Bench',
      value: league?.numPlayersOnBench,
    },
    {
      title: 'Periods',
      value: league?.numPeriods,
    },
    {
      title: 'Time Per Period',
      value: league?.timePerPeriodInMins,
    },
  ];

  const titleInfoRow = (title) => {
    return (
      <Col xs={12} style={infoTitleStyle}>
        {title}:
      </Col>
    );
  };

  const dataInfoRow = (title, value) => {
    return (
      <tr key={`${title}_${value}`}>
        <td style={dataTitleStyle}>{title}</td>
        <td style={dataValueStyle}>
          <div style={{ ...textEllipsisStyle, maxWidth: '95%' }}>{value}</div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Row className="d-flex justify-content-center mx-0">
        {titleInfoRow('Game Info')}
        <Table striped bordered hover size="sm" className="pb-3">
          <tbody>
            {GameInfo.map(({ title, value }) => dataInfoRow(title, value))}
          </tbody>
        </Table>

        {titleInfoRow('League Info')}
        <Table striped bordered hover size="sm">
          <tbody>
            {LeagueInfo.map(({ title, value }) => dataInfoRow(title, value))}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

const AddEditGameModal = ({
  isNewGame,
  gameData,
  modalOpen,
  setModalOpen,
  teamList,
  seasonList,
  venueList,
  leaguesList,
  onSubmit,
  onClose,
  error,
  isLoading,
  setError,
}) => {
  const [hasNextBtn, setHasNextBtn] = useState(true);
  const [hasBackBtn, setHasBackBtn] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [seasonSelected, setSeasonSelected] = useState(undefined);

  const gameId = gameData?.id;

  /** Check for duplicate game */
  const duplicateCheck = useCallback(
    async (values, context) => {
      const {
        gameDateTime,
        homeTeamId,
        awayTeamId,
        seasonId,
        venueId,
      } = values;

      /* If any of these fields are missing, there can't be a duplicate in the DB. */
      if (!gameDateTime || !homeTeamId || !awayTeamId || !seasonId || !venueId) return true;

      const homeTeam = teamList.find(({ id }) => homeTeamId === id);
      const awayTeam = teamList.find(({ id }) => awayTeamId === id);
      const season = seasonList.find(({ id }) => seasonId === id);

      const queryVars = {
        gameDateTime: {
          between: [
            moment(gameDateTime).startOf('day').toISOString(),
            moment(gameDateTime).endOf('day').toISOString(),
          ],
        },
        filter: {
          homeTeamId: { eq: homeTeamId },
          awayTeamId: { eq: awayTeamId },
          seasonId: { eq: seasonId },
          leagueId: { eq: season.leagueId },
          venueId: { eq: venueId },
        },
        limit: LIST_PAGINATION_LIMIT.XLARGE,
      };

      /* If this is an existing game, ignore the current game id when checking for duplicates */
      if (!isNewGame && gameId) {
        queryVars.filter.id = { ne: gameId };
      }

      let duplicateDataResponse = await getGameByDateTime(queryVars);

      if (duplicateDataResponse.errors) {
        /** Error checking for duplicate */
        return context.createError({
          message: `Duplicate check failed. ${duplicateDataResponse.errors[0].message}.`,
        });
      }

      let items = duplicateDataResponse.items;
      while (items.length === 0 && duplicateDataResponse.nextToken) {
        duplicateDataResponse = await getGameByDateTime({
          ...queryVars,
          nextToken: duplicateDataResponse.nextToken,
        });
        items = [...items, ...duplicateDataResponse.items];
      }

      if (items.length >= 1) {
        const { startDate, endDate, seasonType, league } = season;
        const { name, abbreviation } = league;
        const seasonDisplay = seasonFormatDisplayCondensed({
          seasonType,
          startDate,
          endDate,
        });
        /** Duplicate Exists */
        return context.createError({
          message: `The ${homeTeam.name} vs ${awayTeam.name} game for ${seasonDisplay} in ${name} (${abbreviation}) league already exists.`,
        });
      } 
            
      return true;
    },
    [teamList, seasonList, isNewGame, gameId]
  );

  const gameValidationSchema = Yup.object({
    gameDateTime: Yup.date()
      .test(
        'gameDateSelectionValidation', 
        "Cannot select a previous date prior to today's date", 
        (value) => moment(value).valueOf() > moment().startOf('day').valueOf()
      )
      .when(['seasonId'], (seasonId, schema) => {
        return seasonSelected?.startDate
          ? schema
              .max(
                `${seasonSelected.endDate}T23:59`,
                `Date is not within the selected Season (${new Date(
                  seasonSelected.startDate
                ).toLocaleDateString()} to ${new Date(
                  seasonSelected.endDate
                ).toLocaleDateString()})`
              )
              .min(
                `${seasonSelected.startDate}T00:00`,
                `Date is not within the selected Season (${new Date(
                  seasonSelected.startDate
                ).toLocaleDateString()} to ${new Date(
                  seasonSelected.endDate
                ).toLocaleDateString()})`
              )
          : schema;
      })
      .when('null', {
        is: () => validation.gameDateTime.isRequired.value,
        then: Yup.date().required(validation.gameDateTime.isRequired.message),
      }),
    homeTeamId: Yup.string().when('null', {
      is: () => validation.homeTeamId.isRequired.value,
      then: Yup.string().required(validation.homeTeamId.isRequired.message),
    }),
    awayTeamId: Yup.string()
      .when('null', {
        is: () => validation.awayTeamId.isRequired.value,
        then: Yup.string().required(validation.awayTeamId.isRequired.message),
      })
      .when('homeTeamId', {
        is: (homeTeamId) => homeTeamId != null && homeTeamId?.length != 0,
        then: Yup.string().notOneOf(
          [Yup.ref('homeTeamId')],
          validation.awayTeamId.awayTeamDiff.message
        ),
      }),
    leagueId: Yup.string().when('null', {
      is: () => validation.leagueId.isRequired.value,
      then: Yup.string().required(validation.leagueId.isRequired.message),
    }),
    seasonId: Yup.string().when('null', {
      is: () => validation.seasonId.isRequired.value,
      then: Yup.string().required(validation.seasonId.isRequired.message),
    }),
    venueId: Yup.string().when('null', {
      is: () => validation.venueId.isRequired.value,
      then: Yup.string().required(validation.venueId.isRequired.message),
    }),
    acceptedTerms: Yup.boolean().when('null', {
      is: () => validation.acceptedTerms.isRequired.value && isNewGame,
      then: (schema) =>
        schema
          .required(validation.acceptedTerms.isRequired.message)
          .oneOf([true], validation.acceptedTerms.isRequired.message),
      }),
  });

  const gameValidationSchemaAsync = gameValidationSchema.test(
    'duplicate-check',
    'A duplicate game was found.',
    duplicateCheck
  );

  const getInitialValues = () => {
    const emptyValues = {
      gameDateTime: '',
      homeTeamId: '',
      awayTeamId: '',
      leagueId: '',
      seasonId: '',
      venueId: '',
      acceptedTerms: false,
    };

    if (!isNewGame) {
      gameData.acceptedTerms = true;
    }

    return isNewGame ? emptyValues : gameData;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}

      /** This uses the schema without the async duplicate check, which isn't required for initial values. */
      /** @deprecated isInitialValid has been deprecated in Formik 2.x */
      isInitialValid={gameValidationSchema.isValidSync(getInitialValues())}

      validate={generateValidateCallback(
        gameValidationSchemaAsync,
        (msg) =>
          setError(`Unable to ${isNewGame ? 'create' : 'edit'} game. ${msg}`),
        () => setError()
      )}
      onReset={(values, formik) => {
        setHasNextBtn(true);
        setHasBackBtn(false);
      }}
      onSubmit={async (values, formik) => {
        try {
          await onSubmit(values);
          onClose();
          formik.resetForm();
        } catch (err) {
          log.error(err);
        }
      }}
    >
      {(formik) => (
        <ModalComponent
          headerTitle={isNewGame ? 'Add Game' : 'Edit Game'}
          buttonTitle={isNewGame ? 'Add Game' : 'Save Changes'}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={formik.handleSubmit}
          modalWidth={750}
          handleClose={() => {
            formik.resetForm();
            onClose();
          }}
          buttonDimmed={!formik.isValid || error}
          buttonDisabled={!isNewGame && !formik.dirty}
          // isFooterVisible={isNewGame}
          showNextButton={hasNextBtn}
          showBackButton={hasBackBtn}
          nextHandler={(e) => {
            e.preventDefault();
            if (formik.isValid && !error) {
              setHasNextBtn(false);
              setHasBackBtn(true);
            }
          }}
          backHandler={() => {
            setHasBackBtn(false);
            setHasNextBtn(true);
            setIsBackButtonClicked(true);
          }}
        >
          {isLoading && <Spinner />}

          {/* Alert Column */}
          <Col sm={12} className="px-1">
            {/* Edit Warning */}
            {!isNewGame && (
              <Alert variant={'warning'}>
                <i>
                  If you need to edit any of the disabled fields please contact
                  your Fast Stats administrator.
                </i>
              </Alert>
            )}
            {/* Error Message */}
            {error && <Alert variant={'danger'}>{error}</Alert>}
          </Col>

          {hasNextBtn ? (
            <GameContentForm
              isNewGame={isNewGame}
              leaguesList={leaguesList}
              seasonList={seasonList}
              venueList={venueList}
              teamList={teamList}
              seasonSelected={seasonSelected}
              setSeasonSelected={setSeasonSelected}
              isBackButtonClicked={isBackButtonClicked}
              setIsBackButtonClicked={setIsBackButtonClicked}
              formik={formik}
            />
          ) : (
            <GameContentInfo
              seasonList={seasonList}
              venueList={venueList}
              teamList={teamList}
              formik={formik}
            />
          )}
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditGameModal;
