import React, { useState, useEffect, useRef, } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import StatsTabs from '../components/tabs/StatsTabs';
import ScrollListItemsContainer from '../components/ScrollListItemsContainer';
import PlayByPlayStats from '../components/stats/playByPlay/PlayByPlayStats';
import TeamStats from '../components/stats/team/TeamStats';
import PlayerStats from '../components/stats/player/PlayerStats';
import GameStats from '../components/stats/game/GameStats';
import GameStatus from '../components/GameStatus';
import { getTeam } from '../api/teamsService';
import { 
  getGame, 
  getGameTeamStats, 
} from '../api/gamesService';
import { useGetGameLineupQuery } from '../api/gamesQueries';
import { getTeamImageProfile } from '../api/teamsService';
import { textEllipsisStyle, colors } from '../styles';
import { GAME_STATUS } from '../utils/constantsUtils';
import { isMobile, onListScrollHandler } from '../utils/scrollUtil';
import useGameTeamPlayersStats from '../utils/useGameTeamPlayersStats';
import useGamePeriods from '../utils/useGamePeriods';
import useGameShotLocation from '../utils/useGameShotLocation';
import LeftArrowChevron from '../assets/leftArrowChevron@2x.png';
import RightArrowChevron from '../assets/rightArrowChevron@2x.png';
import WinnerTrophy from '../assets/trophy@2x.png';
import Spinner from '../components/Spinner';

/************************************* BEGIN STYLES *****************************************/
const gameScoreContainerStyle = { 
  backgroundColor: '#ffffff', 
  padding: 5, 
  transition: 'height 0.25s linear',
};

const gameScoreContentStyle = { 
  height: '100%', 
  width: '100%', 
  margin: 0, 
  position: 'relative',
};

const teamNameTextContainerStyle = { 
  width: '60%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'column', 
};

const teamScoreDisplayContainerStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
};

const teamLogoOrScoreTextContentStyle = { 
  width: '20%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const teamQuaterScoreContainerStyle = { 
  width: '97%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
};

const tabDisplayContainerStyles = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
};

const statsDisplaySectionStyles = { 
  width: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  marginTop: 7, 
};

const gameCancelledStyles = { 
  width: '80%', 
  height: 450, 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  borderRadius: 10, 
  border: '1px solid #D5D5D5', 
  backgroundColor: colors.WHITE[100], 
  font: 'normal normal normal 14px/45px "Segoe UI"', 
  color: '#0A0A0A', 
};
/*************************************** END STYLES *****************************************/

const BodyStyled = styled.div`
  height: 100%;
  width: auto;
  padding-top: 0;
  padding-left: 10px;
  padding-right: 10px;
`;

const BackButton = styled.div`
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  font: normal normal bold 16px/24px Inter;
  color: #26a9e1;
  cursor: pointer;
  position: absolute;
  top: 12%;
  left: 30px;
`;


const GameTeamName = styled.div`
  width: 100%; 
  height: 50%;
  display: flex; 
  flexDirection: row;
  justify-content: ${props => props.justifyContent};
  align-items: flex-start;
  font: normal normal 18px/${props => props.lineHeight} 'Segoe UI Bold';
  text-align: ${props => props.textAlign};
  color: ${props => props.isLive ? colors.BLACK[100] : (props.didTeamLoose ? '#A2A2A2' : colors.BLACK[100])};
`;

const GameTeamType = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flexDirection: row;
  font: normal normal 600 12px/14px 'Segoe UI';
  color: #444444;
  align-items: flex-start;
  justify-content: ${props => props.justifyContent};
  text-align: ${props => props.textAlign};
`;

const GameTeamImage = styled.div`
  width: 85%;
  height: 85%;
  display: flex;
  justifyContent: center;
  alignItems: center;
`;

const GameTeamScore = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 30px/48px 'Segoe UI Bold';
  textAlign: center;
  color: ${props => props.isLive ? colors.BLACK[100] : (props.didTeamLoose ? '#A2A2A2' : colors.BLACK[100])};
`;

const TeamWinLoss = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.isCollapsed ? '25%' : 'auto'};
  align-items: ${props => props.alignItems};
`;

const TeamWinLossContainer = styled.div`
  width: 80px; 
  height: 100%; 
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  align-items: ${props => props.alignItems}; 
  padding-right: ${props => props.paddingRight || 0}px;
  padding-left: ${props => props.paddingLeft || 0}px;
`;

const Separator = styled.div`
  width: 2px;
  height: ${props => props.height};
  border-left: 2px solid #DCDCDC;
`;

const GameStatusContainer = styled.div`
  width: auto; 
  height: 100%; 
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
  align-items: center;
`;

const IconDisplayContainer = styled.div`
  width: auto; 
  height:auto; 
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  align-items: flex-end;
`;

const STATS_TABS = {
  GAME_STATS: 'gameStats',
  PLAY_BY_PLAY_STATS: 'playByPlayStats',
  TEAM_STATS: 'team_stats',
  PLAYER_STATS: 'player_stats',
};

const IconDisplay = ({
  iconSrc,
  isCollapsed,
}) => {
  return (
    <img
      src={iconSrc}
      style={{ objectFit: 'contain' }}
      width={isCollapsed ? "20px" : "25px"}
      height={isCollapsed ? "20px" : "25px"}
    />
  );
};

const TeamTotalScoreDisplay = ({
  teamName,
  teamType,
  teamScore,
  teamlogo,
  didTeamLoose,
  flexDir,
  justifyContent,
  textAlignment, 
  teamTextJustify, 
  isLive,
  isCollapsed,
}) => {
  return (
    <div style={{ ...teamScoreDisplayContainerStyle, flexDirection: flexDir, justifyContent: justifyContent, }}>
      <div style={teamNameTextContainerStyle}>
        <GameTeamName
          isLive={isLive}
          didTeamLoose={didTeamLoose}
          textAlign={textAlignment}
          justifyContent={teamTextJustify}
          lineHeight={isCollapsed ? '18px' : '63px'}
          style={{ ...textEllipsisStyle }}
        >
          {teamName}
        </GameTeamName>
        <GameTeamType
          textAlign={textAlignment}
          justifyContent={teamTextJustify}
        >
          {teamType}          
        </GameTeamType>
      </div>
      <div style={{ ...teamLogoOrScoreTextContentStyle, width: '15%', }}>
        <GameTeamImage>
          <img
            style={{ objectFit: 'contain', opacity: isLive ? 1 : (didTeamLoose ? 0.7 : 1), mixBlendMode: isLive ? 'normal' : (didTeamLoose ? 'luminosity' : 'normal')  }}
            width="100%"
            height="100%"
            src={teamlogo}
          />
        </GameTeamImage>
      </div>
      <GameTeamScore
        isLive={isLive}
        didTeamLoose={didTeamLoose}
      >
        {teamScore}
      </GameTeamScore>
    </div>
  );
};

const TeamQuaterScoresDisplay = ({
  didHomeTeamWin,
  didAwayTeamWin,
  isCollapsed,
  isLive,
  isGameDrawn,
  isGameCancelled,
}) => {
  return (
    <div style={teamQuaterScoreContainerStyle}>
      <TeamWinLossContainer 
        alignItems={'flex-end'} 
        paddingRight={5}
      >
        <TeamWinLoss
          isCollapsed={isCollapsed}
          alignItems={'flex-end'}
        >
          {(didHomeTeamWin && !isLive) && 
            <IconDisplayContainer>
              <IconDisplay
                iconSrc={LeftArrowChevron}
                isCollapsed={isCollapsed}
              />
              <IconDisplay
                iconSrc={WinnerTrophy}
                isCollapsed={isCollapsed}
              />
            </IconDisplayContainer>
          }
        </TeamWinLoss>
      </TeamWinLossContainer>
      <GameStatusContainer>
        {(isGameDrawn || isGameCancelled)
          ? <GameStatus 
              isGameDrawn={isGameDrawn} 
              isGameCancelled={isGameCancelled} 
            />
          : <Separator 
              height={isCollapsed ? '60%' : '100%'} 
            />
        }
      </GameStatusContainer>
      <TeamWinLossContainer 
        alignItems={'flex-start'} 
        paddingleft={5}
      >
        <TeamWinLoss
          isCollapsed={isCollapsed}
          alignItems={'flex-start'}
        >
          {(didAwayTeamWin && !isLive) && 
            <IconDisplayContainer>
              <IconDisplay
                iconSrc={WinnerTrophy}
                isCollapsed={isCollapsed}
              />
              <IconDisplay
                iconSrc={RightArrowChevron}
                isCollapsed={isCollapsed}
              />
            </IconDisplayContainer>
          }
        </TeamWinLoss>
      </TeamWinLossContainer>
    </div>
  );
};

const GameScoresDisplay = ({
  game,
  history,
  isCollapsed,
  homeTeamTotalScore,
  awayTeamTotalScore,
}) => {
  const getTeamName = team => {
    const teamNameItems = team?.split(' ');
    const teamNameItemsSize = teamNameItems?.length;
    return teamNameItems[ teamNameItemsSize - 1 ];
  };

  return (
    <Row>
      <Col xs={12} style={{ ...gameScoreContainerStyle, height: isCollapsed ? 50 : 100, }}>
        <Row style={gameScoreContentStyle} className="d-flex justify-content-center">
          {game && 
            <BackButton onClick={() => history.goBack()}>
              {'< Back'}
            </BackButton>
          }
          <Col xs={4} className='p-0' style={{ height: '100%', }}>
            {game && 
              <TeamTotalScoreDisplay
                teamName={game?.homeTeamName}
                teamType='Home Team'
                teamScore={homeTeamTotalScore}
                teamlogo={game?.homeTeamImage}
                isLive={(game?.gameStatus === GAME_STATUS.LIVE)}
                didTeamLoose={(homeTeamTotalScore < awayTeamTotalScore)}
                flexDir='row'
                justifyContent='flex-end'
                textAlignment='right'
                teamTextJustify='flex-end'
                isCollapsed={isCollapsed}
              />
            }
          </Col>
          <div className='p-0 d-flex justify-content-center' style={{ height: '100%', transition: 'width 0.25s linear', width: isCollapsed ? 150 : 'auto', }}>
            {game &&
              <TeamQuaterScoresDisplay
                homeTeamName={getTeamName(game?.homeTeamName)}
                awayTeamName={getTeamName(game?.awayTeamName)}
                didHomeTeamWin={homeTeamTotalScore > awayTeamTotalScore}
                didAwayTeamWin={awayTeamTotalScore > homeTeamTotalScore}
                isCollapsed={isCollapsed}
                isLive={(game?.gameStatus === GAME_STATUS.LIVE)}
                isGameDrawn={((game?.gameStatus != GAME_STATUS.CANCELED) && (game?.gameStatus != GAME_STATUS.LIVE)) && (awayTeamTotalScore == homeTeamTotalScore) && (awayTeamTotalScore !== 0 && homeTeamTotalScore !== 0)}
                isGameCancelled={(game?.gameStatus == GAME_STATUS.CANCELED)}
              />
            }
          </div>
          <Col xs={4} className='p-0' style={{ height: '100%', }}>
            {game && 
              <TeamTotalScoreDisplay
                teamName={game?.awayTeamName}
                teamType='Away Team'
                teamScore={awayTeamTotalScore}
                teamlogo={game?.awayTeamImage}
                isLive={(game?.gameStatus === GAME_STATUS.LIVE)}
                didTeamLoose={(awayTeamTotalScore < homeTeamTotalScore)}
                flexDir='row-reverse'
                justifyContent='flex-end'
                textAlignment='left'
                teamTextJustify='flex-start'
                isCollapsed={isCollapsed}
              />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const TabsDisplay = ({
  selectedTab,
  setSelectedTab = () => {},
}) => {
  return (
    <div style={tabDisplayContainerStyles}>
      <div style={{ width: '97.5%', height: 'auto', }}>
        <Row>
          <Col xs={12} className="mt-2 p-0">
            <StatsTabs
              activeGameStatsStatus={selectedTab === STATS_TABS.GAME_STATS}
              gameStatsClickHandler={() => setSelectedTab(STATS_TABS.GAME_STATS)}
              activePlayByPlayStatsStatus={selectedTab === STATS_TABS.PLAY_BY_PLAY_STATS}
              playByPlayStatsClickHandler={() => setSelectedTab(STATS_TABS.PLAY_BY_PLAY_STATS)}
              activeTeamStatsStatus={selectedTab === STATS_TABS.TEAM_STATS}
              teamStatsClickHandler={() => setSelectedTab(STATS_TABS.TEAM_STATS)}
              activePlayerStatsStatus={selectedTab === STATS_TABS.PLAYER_STATS}
              playerStatsClickHandler={() => setSelectedTab(STATS_TABS.PLAYER_STATS)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const GameCancelled = ({
  game,
  gameStatus,
  homeTeamTotalPeriodScore,
  awayTeamTotslPeriodScore,
  setIsPlayEventsLoading,
}) => {
  useEffect(() => {
    setIsPlayEventsLoading(false);
  }, [game]);

  return (
    <>
      {game && 
        <div style={gameCancelledStyles}>
          {gameStatus === GAME_STATUS.CANCELED 
            ? 'This game was cancelled' 
            : ((gameStatus === GAME_STATUS.LIVE || gameStatus === GAME_STATUS.ENDED) && (homeTeamTotalPeriodScore === 0 && awayTeamTotslPeriodScore === 0))
                ? 'This game has no play time events.'
                : 'This game has no score. It was not canceled and the status is unknown.'
          }
        </div>
       }
     </>
  );
};

const PlayByPlayStatsPage = () => {
  const history = useHistory();
  const { gameId } = useParams();

  const [game, setGame] = useState();
  const [gameTeamStats, setGameTeamStats] = useState({}); 
  const [periodEventParams, setPeriodEventParams] = useState([]);
  const [isPlayEventsLoading, setIsPlayEventsLoading] = useState(false);
  const [homeTeamPlayers, setHomeTeamPlayers] = useState([]);
  const [awayTeamPlayers, setAwayTeamPlayers] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const [isTopSectionCollapsed, setIsTopSectionCollapsed] = useState(false);
  const [playByPlayEventParams, setPlayByPlayEventParams] = useState({ gameId, periodNumber: 1, periodType: 'gamePeriodNumber' });
  const [statsDisplayHeight, setStatsDisplayHeight] = useState(0);
  const [periodColumnWidth, setPeriodColumnWidth] = useState(0);

  const listRef = useRef();
  const isMobileRef = useRef(isMobile());
  const homeTeamGamePointsRef = useRef(0);
  const awayTeamGamePointsRef = useRef(0);

  useEffect(() => {
    setGameForStats(gameId);
  }, []);

  const TEAM_ASSETS = useRef({});
  const TEAM_IDS = useRef({});

  const setGameForStats = async gameId => {
    setIsPlayEventsLoading(true);

    /* This retrieves the game and game stats by gameId */
    const game = await getGame(gameId);
    const gameStats = await getGameTeamStats(gameId);

    /* This gets the game stats data from the gameStats response above */
    const gameStatsData = gameStats?.data?.getGameTeamStats;

    /* The homeTeamId and awayTeamId is used to get the team logo images */
    const { homeTeamId, awayTeamId } = game;
    game.homeTeamImage = await getTeamImageProfile(homeTeamId);
    game.awayTeamImage = await getTeamImageProfile(awayTeamId);

    const homeTeam = await getTeam(homeTeamId);
    const awayTeam = await getTeam(awayTeamId);

    /* This retrieves homeTeam and awayTeam players. */
    const homeTeamPlayers = homeTeam.players.items.map(player => ({ playerId: player.id, playerName: `${player.jerseyNumber} - ${player.firstName} ${player.lastName}` }))
                                                  .sort((a, b) => a.playerName.localeCompare(b.playerName));

    const awayTeamPlayers = awayTeam.players.items.map(player => ({ playerId: player.id, playerName: `${player.jerseyNumber} - ${player.firstName} ${player.lastName}` }))
                                                  .sort((a, b) => a.playerName.localeCompare(b.playerName));

    TEAM_IDS.current.homeTeamId = homeTeamId;
    TEAM_IDS.current.awayTeamId = awayTeamId;

    TEAM_ASSETS.current[homeTeamId] = game.homeTeamImage;
    TEAM_ASSETS.current[awayTeamId] = game.awayTeamImage;

    /* This gets the home and away teams total score points, and for a game with any score points a default total score point of 0 is assigned instead. */
    homeTeamGamePointsRef.current = (((game.gameStatus === GAME_STATUS.CANCELED) || (game.gameStatus === GAME_STATUS.LIVE) || (game.gameStatus === null)) && !gameStatsData?.home_total_points) ? 0 : gameStatsData?.home_total_points;
    awayTeamGamePointsRef.current = (((game.gameStatus === GAME_STATUS.CANCELED) || (game.gameStatus === GAME_STATUS.LIVE) || (game.gameStatus === null)) && !gameStatsData?.away_total_points) ? 0 : gameStatsData?.away_total_points;

    setSelectedTab(STATS_TABS.GAME_STATS);
    setGame(game);
    setGameTeamStats(gameStatsData);
    setHomeTeamPlayers([{ playerName: 'All Players', playerId: '' }, ...homeTeamPlayers]);
    setAwayTeamPlayers([{ playerName: 'All Players', playerId: '' }, ...awayTeamPlayers]);
  };

  // This returns the homeTeamLineup and awayTeamLineup
  const {
    data: { homeTeamLineup, awayTeamLineup },
  } = useGetGameLineupQuery(
    game?.homeTeamGameLineupId, 
    game?.awayTeamGameLineupId,
  );

  // This is the react query response for homeGameTeamPlayersStats
  const homeTeamGamePlayersStats = useGameTeamPlayersStats(
    'homeGamePlayersStatsResponse', 
    game?.id, 
    game?.homeTeamId, 
    homeTeamLineup, 
  );

  // This is the react query response for awayGameTeamPlayersStats
  const awayTeamGamePlayersStats = useGameTeamPlayersStats(
    'awayGamePlayersStatsResponse', 
    game?.id, 
    game?.awayTeamId, 
    awayTeamLineup, 
  );

  // This is the react query response for the game and overtime periods
  const { 
    gamePeriods, 
    overtimePeriods, 
    initialPeriodEventParams, 
    periodOptions, 
  } = useGamePeriods(
    game?.id, 
  );

  // This is the react query response for the game shot locations for each game period
  const gameShotCourtLocations = useGameShotLocation(
    'shotLocationsForGame', 
    game?.id, 
  );

  const onLoadStatsDispay = event => {
    new ResizeObserver(resizeEntry => {
      const height = resizeEntry[0].target.clientHeight * 0.83;
      const periodColumnWidth = resizeEntry[0].target.clientWidth / initialPeriodEventParams?.length;
      setPeriodColumnWidth(periodColumnWidth);
      setStatsDisplayHeight(height);
    }).observe(event.currentTarget.parentElement);
  };

  return (
    <BodyStyled>
      {isPlayEventsLoading && <Spinner/>}

      <GameScoresDisplay
        game={game}
        history={history}
        isCollapsed={isTopSectionCollapsed}
        homeTeamTotalScore={homeTeamGamePointsRef.current}
        awayTeamTotalScore={awayTeamGamePointsRef.current}
      />
      <TabsDisplay
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div onLoad={onLoadStatsDispay} style={{ ...statsDisplaySectionStyles, height: statsDisplayHeight, }}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
          {(homeTeamGamePointsRef.current > 0 || awayTeamGamePointsRef.current > 0)
            ?
            <ScrollListItemsContainer
              scrollRef={listRef}
              onScroll={() => onListScrollHandler(isMobileRef.current, listRef.current)}
              listStyle={{ flexDirection: 'row', justifyContent: 'center', }}
              isCollapsedState={isTopSectionCollapsed}
              setIsCollapsedState={setIsTopSectionCollapsed}
            >
              <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, }}>
                {selectedTab === STATS_TABS.GAME_STATS 
                  &&
                  <GameStats
                    awayTeamName={game?.awayTeamName}
                    homeTeamName={game?.homeTeamName}
                    awayTeamImage={game?.awayTeamImage}
                    homeTeamImage={game?.homeTeamImage}
                    awayTeamId={game?.awayTeamId}
                    homeTeamId={game?.homeTeamId}
                    awayTeamPlayersGameStats={awayTeamGamePlayersStats}
                    homeTeamPlayersGameStats={homeTeamGamePlayersStats}
                    gameStatsData={gameTeamStats}
                    setStatsTab={setSelectedTab}
                    shotLocationData={gameShotCourtLocations}
                    setIsPlayEventsLoading={setIsPlayEventsLoading}
                    scrollListRef={listRef}
                  />
                }
                {selectedTab === STATS_TABS.PLAY_BY_PLAY_STATS
                  &&
                  <PlayByPlayStats
                    game={game}
                    gameId={gameId}
                    homeTeamPlayers={homeTeamPlayers}
                    awayTeamPlayers={awayTeamPlayers}
                    periodOptions={periodOptions}
                    periodEventParams={periodEventParams.length === 0 ? initialPeriodEventParams : periodEventParams}
                    playByPlayEventParams={playByPlayEventParams}
                    setPlayByPlayEventParams={setPlayByPlayEventParams}
                    setPeriodEventParams={setPeriodEventParams}
                    periodColumnWidth={periodColumnWidth}
                    teamAssets={TEAM_ASSETS.current}
                    shotLocationData={gameShotCourtLocations}
                    contentHeight={statsDisplayHeight}
                    scrollListRef={listRef}
                  />
                }
                {selectedTab === STATS_TABS.TEAM_STATS
                  &&
                  <TeamStats
                    awayTeamImage={game?.awayTeamImage}
                    homeTeamImage={game?.homeTeamImage}
                    awayTeamPlayersGameStats={awayTeamGamePlayersStats}
                    homeTeamPlayersGameStats={homeTeamGamePlayersStats}
                    gameStatsData={gameTeamStats}
                    setStatsTab={setSelectedTab}
                    scrollListRef={listRef}
                  />
                }
                {selectedTab === STATS_TABS.PLAYER_STATS
                  &&
                  <PlayerStats
                    awayTeamImage={game?.awayTeamImage}
                    homeTeamImage={game?.homeTeamImage}
                    awayTeamName={game?.awayTeamName}
                    homeTeamName={game?.homeTeamName}
                    awayTeamPlayersGameStats={awayTeamGamePlayersStats}
                    homeTeamPlayersGameStats={homeTeamGamePlayersStats}
                    scrollListRef={listRef}
                  />
                }
              </div>
            </ScrollListItemsContainer>
            :
            <GameCancelled
              game={game}
              gameStatus={game?.gameStatus}
              awayTeamTotslPeriodScore={awayTeamGamePointsRef.current}
              homeTeamTotalPeriodScore={homeTeamGamePointsRef.current}
              setIsPlayEventsLoading={setIsPlayEventsLoading}
            />
          }
        </div>
      </div>
    </BodyStyled>
  );
};

export default PlayByPlayStatsPage;