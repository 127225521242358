import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { GameStatsContext } from './GameStatsContext';
import { textEllipsisStyle } from '../../../styles/typography';

const TeamInfo = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
`;

const TeamImage = styled.div`
  width: 13%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TeamName = styled.div`
  width: auto;
  max-width: 250px;
  height: 40px;
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font: normal normal 600 18px/35px 'Segoe UI Bold';
  color: #000000;
`;

const StatsDataRowItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
`;

const StatTitle = styled.div`
  width: 100%;
  height: 100%;
  color: ${props => props.color ?? '#9C9C9C'};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  font-weight: ${props => props.fontWeight ?? 'normal'};
  font-size: ${props => props.fontSize ?? 12}px;
  border-right: 2px solid #D4D4D4;
`;

const StatData = styled.div`
  width: 100%;
  height: 100%;
  color: #9C9C9C;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 12px;
`;

const StatDataItem = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PLAYER_STATS_DATA = [
  // {data: 'MIN'},
  {data: 'FG'},
  {data: '3PT'},
  {data: 'FT'},
];

const GamePlayerStatsRecordData = ({
  title,
  titleColor,
  titleFontWeight,
  titleFontSize,
  recordData,
}) => {
  const statsDataRef = useRef();
  const [dataWidth, setDataWidth] = useState();

  useEffect(() => {
    const dataDisplayWidth = statsDataRef.current?.clientWidth;
    const dataItemWidth = Math.ceil(dataDisplayWidth / recordData?.length);
    const percentageDataItemWidth = ( dataItemWidth / dataDisplayWidth ) * 100;
    setDataWidth(`${percentageDataItemWidth}%`);
  }, []);

  return (
    <Row style={{ width: '100%', height: '100%', margin: 0, }}>
      <Col xs={6} className="p-0">
        <StatTitle 
          color={titleColor} 
          fontWeight={titleFontWeight} 
          fontSize={titleFontSize}
        >
          {title}
        </StatTitle>
      </Col>
      <Col xs={6} className="p-0">
        <StatData ref={statsDataRef}>
          {recordData.map(({ data }) => (
            <StatDataItem width={dataWidth}>
              {data}
            </StatDataItem>
          ))}
        </StatData>
      </Col>
    </Row>
  );
};

const GamePlayerStatsRecordDataItems = ({
  teamPlayersGameStats,
}) => {
  const topThreeGamePlayersStats = teamPlayersGameStats
                                    ?.sort((a, b) => b?.total_points - a?.total_points)
                                    ?.slice(0, 3)
                                    ?.map(playerGameStats => {
                                      const { player, minutes_played, fg_made, fg_att, three_point_made, three_point_att, free_throw, free_throw_atts } = playerGameStats;
                                      const playerName = player?.firstName && player?.lastName ? `${player.firstName.charAt(0).toUpperCase()}. ${player.lastName}` : 'N/A';
                                      const fieldGoalStats = (fg_made >= 0 && fg_att >= 0) ? `${fg_made}-${fg_made + fg_att}` : 'N/A';
                                      const threePointStats = (three_point_made >= 0 && three_point_att >= 0) ? `${three_point_made}-${three_point_made + three_point_att}` : 'N/A';
                                      const freeThrowStats = (free_throw >= 0 && free_throw_atts >= 0) ? `${free_throw}-${free_throw + free_throw_atts}` : 'N/A';
                                      return { 
                                        playerName, 
                                        playerStatsItems: [ 
                                          // { data: minutes_played }, 
                                          { data: fieldGoalStats }, 
                                          { data: threePointStats }, 
                                          { data: freeThrowStats },
                                         ] 
                                      }; 
                                    });

  return (
    <>
      {topThreeGamePlayersStats?.map(({ playerName, playerStatsItems }, index) => (
        <StatsDataRowItem style={{ backgroundColor: index % 2 === 0 ? '#F3F3F3' : '#FFFFFF' }}>
          <GamePlayerStatsRecordData
            title={playerName}
            titleFontWeight={600}
            recordData={playerStatsItems}
          />
        </StatsDataRowItem>
      ))}
    </>
  );
};

const GameTeamPlayersStat = ({
  teamName,
  teamImage,
  teamPlayersGameStats
}) => {
  return (
    <Col xs={6} className='p-0' style={{ width: '49.5%', border: '1px solid #DFDFDF' }}>
      <TeamInfo>
        <TeamImage>
          <img
            src={teamImage}
            width="40px"
            height="40px"
            style={{ objectFit: 'contain', border: "4px solid #D5D5D5", borderRadius: 5, }}
            alt=""
          />
        </TeamImage>
        <TeamName
          style={textEllipsisStyle}
        >
          {teamName}
        </TeamName>
      </TeamInfo>
      <StatsDataRowItem style={{ backgroundColor: '#2E2E2E' }}>
        <GamePlayerStatsRecordData
          title="STARTERS"
          titleColor="#FFFFFF"
          titleFontWeight="bold"
          titleFontSize={14}
          recordData={PLAYER_STATS_DATA}
        />
      </StatsDataRowItem>
      <GamePlayerStatsRecordDataItems
        teamPlayersGameStats={teamPlayersGameStats}
      />
    </Col>
  );
};

const GameAwayTeamPlayersStat = ({
  awayTeamName,
  awayTeamImage,
  awayTeamPlayersGameStats,
}) => {
  return (
    <GameTeamPlayersStat
      teamName={awayTeamName}
      teamImage={awayTeamImage}
      teamPlayersGameStats={awayTeamPlayersGameStats}
    />
  );
};

const GameHomeTeamPlayersStat = ({
  homeTeamName,
  homeTeamImage,
  homeTeamPlayersGameStats,
}) => {
  return (
    <GameTeamPlayersStat
      teamName={homeTeamName}
      teamImage={homeTeamImage}
      teamPlayersGameStats={homeTeamPlayersGameStats}
    />
  );
};

const GamePlayerStatsData = ({}) => {
  const {
    awayTeamImage, 
    homeTeamImage, 
    awayTeamName, 
    homeTeamName, 
    awayTeamPlayersGameStats, 
    homeTeamPlayersGameStats, 
  } = useContext(GameStatsContext);

  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, }} className='mt-2 mb-2 d-flex justify-content-between'>
      <GameHomeTeamPlayersStat
        homeTeamName={homeTeamName}
        homeTeamImage={homeTeamImage}
        homeTeamPlayersGameStats={homeTeamPlayersGameStats}
      />
      <GameAwayTeamPlayersStat
        awayTeamName={awayTeamName}
        awayTeamImage={awayTeamImage}
        awayTeamPlayersGameStats={awayTeamPlayersGameStats}
      />
    </Row>
  );
};

export default GamePlayerStatsData;