import { Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import * as yup from 'yup';
import logger from '../../../../logger';
import { colors, gradients } from '../../../../styles/colorsStatsCollector';
import CourtLocation from '../CourtLocation';
import ClockInput from './ClockInput';
import { EventTypeSelect, TeamLineupSelect } from './EventSelectControls';
const log = logger('EditPointsForm', 'debug');

export const EditPointsForm = {
  FieldsetComponent: EditPointsFieldset,
  getValidationSchema: ({ periodDuration, allowedEventTypes }) =>
    yup.object({
      minutes: yup.number().required().min(0).max(periodDuration),
      seconds: yup
        .number()
        .required()
        .min(0)
        .when('minutes', {
          is: periodDuration,
          then: (schema) => schema.max(0),
          otherwise: (schema) => schema.max(59),
        }),
      gameLineupPlayerId: yup.string().required(),
      eventType: yup.string().oneOf(allowedEventTypes),
      shotLocation: yup.object({
        area: yup.string(),
        imageHeight: yup.number(),
        imageWidth: yup.number(),
        imageX: yup.number(),
        imageY: yup.number(),
        x: yup.number(),
        y: yup.number(),
      }),
      /* TODO assist */
    }),
  getInitialValues: ({ event }) => ({
    minutes: event.gamePeriodMinutes ?? '',
    seconds: event.gamePeriodSeconds ?? '',
    gameLineupPlayerId: event.gameLineupPlayerId,
    eventType: event.eventType,
    shotLocation: event.shotLocation,
  }),
  handleSubmit: async ({
    play,
    values,
    gameData,
    lineupData,
    queryClient,
  }) => {},
};

export function EditPointsFieldset({
  allowedEventTypes,
  periodDuration,
  courtPlayers,
  play,
  event,
}) {
  const { values } = useFormikContext();
  const { minutes, seconds, eventType } = values;
  const [editShotChart, setEditShotChart] = useState(false);
  const shotLocation = JSON.parse(event.shotLocation);
  const assistEvent = play.subPlay?.event;
  const { x, y, area } = shotLocation;

  log.debug('shotLocation', {
    eventType,
    shotLocation,
    x,
    y,
    area,
    assistEvent,
  });

  if (editShotChart)
    return (
      <CourtLocation
        x={x}
        y={y}
        area={area}
        isTwoPointer={eventType === 'TWO_POINT_MADE'}
      />
    );

  return (
    <>
      <Row className="mb-3">
        <Col sm={4} className="pe-0 mx-auto">
          <ClockInput
            minutes={minutes}
            seconds={seconds}
            periodDuration={periodDuration}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={12}>
          <TeamLineupSelect name="gameLineupPlayerId" players={courtPlayers} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={12}>
          <EventTypeSelect allowedEventTypes={allowedEventTypes} />
        </Col>
      </Row>

      <StyledButton onClick={() => { setEditShotChart(true) }}>Edit Shot Chart</StyledButton>

      <StyledDivider />

      <StyledButton onClick={() => {}}>+ Assist</StyledButton>
    </>
  );
}

const StyledButton = styled(Button)`
  &&& {
    background: none;
    color: ${colors.WHITE[100]};
    border: 1px solid ${colors.BLUE[100]};
    font: normal normal normal 14px/19px Open Sans;
    padding: 8px 20px;

    &:hover {
      background: ${gradients.BLUE};
    }
  }
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin: 25px 0;
    background-color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.GRAY[975] : colors.GRAY[975]};
  }
`;
