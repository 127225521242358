import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
// import { useDrag } from 'react-dnd';
import PlayerTile from './PlayerTile';
import EditDark from '../../assets/svg/EditDark.svg';
import EditLight from '../../assets/svg/EditLight.svg';
import { colors } from '../../styles/colors';
import { colors as colorsNew } from '../../styles/colorsStatsCollector';
import HeadingWithLine from '../ScoreKeeper-new/components/HeadingWithLine';
import StyledButton from './components/StyledButton';

const StyledCategoryDiv = styled.div`
  padding: 0 0.25vw 1vh 0.25vw;
  position: relative;
  color: white;
  height: 100%;
  align-items: center;
`;

const EditButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  height: 20px;
  width: 20px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

  background: ${({ theme }) =>
    theme.lightMode ? colors.GRAY[100] : colorsNew.BLACK[400]};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    opacity: ${(props) => (props.disabled ? '0.4' : '1.0')};
  }
`;

const TeamTitle = ({ notStarted, onCourt, isHomeTeam, handleModalClick }) => {
  const lightMode = useContext(ThemeContext).lightMode;

  return (
    <Grid item container style={{ position: 'relative' }}>
      <HeadingWithLine
        label={onCourt ? 'Court' : 'Bench'}
        color={lightMode ? colorsNew.GRAY[400] : colorsNew.GRAY[2300]}
        labelStyle={{
          color: lightMode ? colorsNew.BLACK[100] : colorsNew.WHITE[100],
        }}
      />
      <EditButton
        disabled={!notStarted && onCourt}
        onClick={() => handleModalClick(isHomeTeam)}
      >
        <img src={lightMode ? EditLight : EditDark} alt="edit" />
      </EditButton>
    </Grid>
  );
};

const Team = ({
  players,
  notStarted,
  onCourt,
  isHomeTeam,
  selectedPlayerOne,
  selectedPlayerTwo,
  handleModalClick,
  isSubstitutionAllowed,
  substitutionHandler,
  isPlayerHighlighted,
  getPlayerHighlightColor,
  disable: disableTeam,
}) => {
  const isPlayerTileDisabled = (player) =>
    disableTeam ||
    (!isSubstitutionAllowed && player?.id !== selectedPlayerOne?.id);

  const isPlayerTileSelected = (player) =>
    (selectedPlayerOne && selectedPlayerOne.id === player?.id) ||
    (selectedPlayerTwo && selectedPlayerTwo.id === player?.id);

  return (
    <StyledCategoryDiv isSubstitutionAllowed={isSubstitutionAllowed}>
      <Grid
        container
        justify="center"
        align="center"
        alignItems="flex-start"
        style={{ height: '100%' }}
      >
        <TeamTitle
          notStarted={notStarted}
          onCourt={onCourt}
          isHomeTeam={isHomeTeam}
          handleModalClick={handleModalClick}
        />

        {players &&
          players.map((player, idx) => (
            <Grid
              container
              xs={players?.length > 6 ? 3 : 4}
              style={players?.length > 8 ? { maxWidth: '20%' } : {}}
              key={idx}
              spacing={0}
              item={true}
            >
              <PlayerTile
                player={player}
                disabled={isPlayerTileDisabled(player)}
                isSelected={isPlayerTileSelected(player)}
                onCourt={onCourt}
                isHighlighted={isPlayerHighlighted(player?.playerId)}
                highlightColor={getPlayerHighlightColor(player?.playerId)}
                substitutionHandler={substitutionHandler}
              />
            </Grid>
          ))}
        {players && players.length === 0 && onCourt && (
          <StyledButton
            onClick={() => handleModalClick(isHomeTeam)}
            style={{ width: 'auto', paddingLeft: 20, paddingRight: 20 }}
          >
            Add Players
          </StyledButton>
        )}
      </Grid>
    </StyledCategoryDiv>
  );
};

export default Team;
