import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import uniqby from 'lodash.uniqby';
import ModalComponent from './ModalComponent';
import InputAsyncSelectDropdown from '../Dropdowns/InputAsyncSelectDropdown';
import { Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import TagsInput from 'react-tagsinput';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import GameItemInformation from '../GameItemInformation';
import {
  seasonFormatDisplay,
  getLeagueSeasonLabelFormat,
} from '../../utils/seasonsUtil';
import {
  isDateStringValid,
  isMomentDateStringValid,
  isDateTimeStringEqualToQueryString,
  getMappedFormattedDateTimeString,
} from '../../utils/momentDateUtil';
import { textEllipsisStyle } from '../../styles';
import ScrollListItemsContainer from '../ScrollListItemsContainer';
import { isMobile, onListScrollHandler } from '../../utils/scrollUtil';
import { ITEM_TYPE } from '../../utils/constantsUtils';
import { useLeaguesQuery } from '../../api/leaguesQueries';
import { useSeasonsQuery } from '../../api/seasonsQueries';
import {
  aggregateBooleanPropUsingOr,
  useAutoFetchAll,
  useFetchAll,
  useFetchOnQuery,
} from '../../utils/reactQueryToolkit';
import { useTeamsQuery } from '../../api/teamsQueries';
import { useVenuesQuery } from '../../api/venuesQueries';
import { useUsersQuery } from '../../api/userQueries';
import { useFutureGamesQuery } from '../../api/gamesQueries';
const log = require('../../logger')('AdminPanel/AssignUserGamesModal', 'debug');

const checkboxListItemStyle = {
  width: '98%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  borderRadius: 5,
  marginBottom: 5,
  paddingLeft: 5,
};

const checkboxRowLabelStyle = {
  width: '100%',
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const checkboxRowLabelItemStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const checkboxRowLabelItemFirstChildStyle = {
  width: 'auto',
  lineHeight: '25px',
  paddingRight: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  borderRight: '1px solid #B1B1B1',
};

const checkboxRowLabelItemLastChildStyle = {
  width: 'auto',
  lineHeight: '25px',
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const checboxRowLabelItemNthChildStyle = {
  width: 'auto',
  lineHeight: '25px',
  paddingLeft: 10,
  paddingRight: 10,
  borderRight: '1px solid #B1B1B1',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const teamNameTextStyle = {
  maxWidth: 150,
  lineHeight: '25px',
  ...textEllipsisStyle,
};

const gameLeagueNameTextStyle = {
  maxWidth: 550, 
  marginRight: 2,
};

const teamLeagueNameTextStyle = {
  maxWidth: 330, 
  marginRight: 2,
};

const otherGameItemTextStyle = {
  maxWidth: 220,
  width: 'auto',
  lineHeight: '25px',
  ...textEllipsisStyle,
};

const tagInputContainerStyle = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const tagInputTeamNameStyle = {
  width: 'auto',
  maxWidth: 150,
  ...textEllipsisStyle,
};

const userNameStyle = { 
  width: 'auto', 
  height: 'auto', 
  maxWidth: '50%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'center', 
};

const userNameContentStyle = { 
  width: 'auto', 
  height: 'fit-content', 
  maxWidth: '100%', 
  textAlign: 'left', 
  ...textEllipsisStyle, 
}

const setOptionLabel = (option) => {
  let optionLabel;

  if (option.abbreviation) {
    /* League */
    optionLabel = `${option.name} (${option.abbreviation})`;
  } else if (option.gameDateTime) {
    /* Game */
    optionLabel = option.gameDateTime;
  } else if (option.seasonType) {
    /* Season */
    optionLabel = getLeagueSeasonLabelFormat(option);
  } else if(option.firstName) {
    /* User: Team Users (Coach) & Game Users (Admin, Clock Manager, Score Keeper) */
    optionLabel = option.firstName;
  } else if(option.lastName) {
    /* User: Team Users (Coach) & Game Users (Admin, Clock Manager, Score Keeper) */
    optionLabel = option.lastName;
  } else {
    optionLabel = option.name;
  }

  return optionLabel;
};

const TaggedGamesSelection = ({ tags, onTagsChanged }) => {
  const listRef = useRef();
  const _isMobile = isMobile();

  return (
    <div
      style={{
        width: '100%',
        height: '30%',
        borderBottom: '2px solid #9B9B9B',
        padding: 7,
        backgroundColor: '#F5F5F5',
      }}
    >
      {tags.length > 0 ? (
        <ScrollListItemsContainer
          data={tags}
          listStyle={{
            height: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
          ref={listRef}
          onScroll={() => onListScrollHandler(_isMobile, listRef.current)}
        >
          <TagsInput
            className="game-user-roles-tagsinput"
            value={tags.map((tagItem) => Object.values(tagItem)[0])}
            onChange={(tags, changed, index) => onTagsChanged(index)}
            renderInput={() => {}}
            tagProps={{
              className: 'user-role-react-tagsinput-tag',
              classNameRemove: 'user-role-react-tagsinput-remove',
            }}
            onlyUnique
          />
        </ScrollListItemsContainer>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            font: 'normal normal normal 12px/24px Open Sans',
            color: '#616161',
          }}
        >
          Selections made will appear here
        </div>
      )}
    </div>
  );
};

const GameCheckboxItem = ({
  gameItem,
  previousTags,
  setPreviousTags,
  setTags,
  getTagItem,
  gamesList,
  setGamesList,
  setIsButtonDisabled,
  selectedOptionLabelRef,
  itemType,
}) => {
  const getCheckboxLabel = () => {
    const getCheckboxLabelRowItem = (checkboxLabelItems) => (
      <div style={checkboxRowLabelItemStyle}>
        {[...checkboxLabelItems].map((labelItem, index, labels) => (
          <React.Fragment key={index}>
            {index === 0 && (
              <div style={checkboxRowLabelItemFirstChildStyle}>{labelItem}</div>
            )}
            {index > 0 && index < labels.length - 1 && (
              <div style={checboxRowLabelItemNthChildStyle}>{labelItem}</div>
            )}
            {index === labels.length - 1 && (
              <div style={checkboxRowLabelItemLastChildStyle}>{labelItem}</div>
            )}
          </React.Fragment>
        ))}
      </div>
    );

    const checkAndSetForBoldText = (text, labelStr) => {
      if (text.includes('Vs')) {
        let [homeTeamStr, awayTeamStr] = text.split('Vs');
        homeTeamStr = homeTeamStr.trim();
        awayTeamStr = awayTeamStr.trim();

        if (text.includes(labelStr)) {
          if (homeTeamStr === labelStr) {
            return (
              <>
                <div style={teamNameTextStyle}>
                  <b>{homeTeamStr}</b>
                </div>
                &nbsp;Vs&nbsp;<div style={teamNameTextStyle}>{awayTeamStr}</div>
              </>
            );
          } else if (awayTeamStr === labelStr) {
            return (
              <>
                <div style={teamNameTextStyle}>{homeTeamStr}</div>&nbsp;Vs&nbsp;
                <div style={teamNameTextStyle}>
                  <b>{awayTeamStr}</b>
                </div>
              </>
            );
          }
        } else {
          return (
            <>
              <div style={teamNameTextStyle}>{homeTeamStr}</div>&nbsp;Vs&nbsp;
              <div style={teamNameTextStyle}>{awayTeamStr}</div>
            </>
          );
        }
      }

      if (text.includes(' (')) {
        let [leagueName, leagueAbbreviation] = text.split('(');
        leagueName = leagueName.trim();
        leagueAbbreviation = `(${leagueAbbreviation.trim()}`;

        const leagueNameTextStyle = itemType === ITEM_TYPE.TEAM 
                                      ? teamLeagueNameTextStyle 
                                      : gameLeagueNameTextStyle;

        if (text === labelStr) {
          return (
            <>
              <div style={{ ...otherGameItemTextStyle, ...leagueNameTextStyle, }}>
                <b>{leagueName}</b>
              </div>
              <div>
                <b>{leagueAbbreviation}</b>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div style={{ ...otherGameItemTextStyle, ...leagueNameTextStyle, }}>
                {leagueName}
              </div>
              <div>
                {leagueAbbreviation}
              </div>
            </>
          );
        }
      }

      if (isMomentDateStringValid(text, 'MM/DD/YYYY, h:mmA')) {
        let [dateStr, timeStr] = text.split(',');

        if (isDateStringValid(labelStr)) {
          return (
            <>
              <div style={{ width: 'auto' }}>
                <b>{dateStr}</b>,{timeStr}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div style={{ width: 'auto' }}>
                {dateStr},{timeStr}
              </div>
            </>
          );
        }
      }

      if (
        text === labelStr ||
        labelStr.split(' - ')[0].trim().includes(text.trim())
      ) {
        return (
          <>
            <div style={otherGameItemTextStyle}>
              <b>{text}</b>
            </div>
          </>
        );
      }

      return (
        <>
          <div style={otherGameItemTextStyle}>{text}</div>
        </>
      );
    };

    /* Checkbox List Items For Team User Coach Roles */
    const getCheckboxListForTeamCoachRoles = ({ 
      teamName,
      seasonType,
      seasonStartDate,
      seasonEndDate,
      leagueName,
      leagueAbbreviation,
    }) => {
      return (
        <div style={{ ...checkboxRowLabelStyle, height: 45, }}>
          {getCheckboxLabelRowItem([
            checkAndSetForBoldText(
              teamName,
              selectedOptionLabelRef.current
            ),
            checkAndSetForBoldText(
              seasonFormatDisplay({
                seasonType,
                startDate: seasonStartDate,
                endDate: seasonEndDate,
              }),
              selectedOptionLabelRef.current
            ),
            checkAndSetForBoldText(
              `${leagueName} (${leagueAbbreviation})`,
              selectedOptionLabelRef.current
            ),
          ])}
        </div>
      );
    };

    /* Checkbox List Items For Users */
    const getCheckboxListForUsers = ({
      firstName,
      lastName,
    }) => {
      return (
        <div style={{ ...checkboxRowLabelStyle, height: 30, flexDirection: 'row', justifyContent: 'flex-start', }}>
          <div style={userNameStyle}>
            <div style={userNameContentStyle}>
              {firstName}
            </div>
          </div>
          &nbsp;&nbsp;
          <div style={userNameStyle}>
            <div  style={userNameContentStyle}>
              {lastName}
            </div>
          </div>
        </div>
      );
    };

    /* Checkbox List Items For Game Roles */
    const getCheckboxListForGameRoles = ({
      homeTeamName,
      awayTeamName,
      gameDateTime,
      venueName,
      seasonType,
      seasonStartDate,
      seasonEndDate,
      leagueName,
      leagueAbbreviation,
    }) => {
      return (
        <div style={checkboxRowLabelStyle}>
          {getCheckboxLabelRowItem([
            checkAndSetForBoldText(
              `${homeTeamName} Vs ${awayTeamName}`,
              selectedOptionLabelRef.current
            ),
            checkAndSetForBoldText(
              moment(gameDateTime).format('MM/DD/YYYY, h:mmA'),
              selectedOptionLabelRef.current
            ),
            checkAndSetForBoldText(venueName, selectedOptionLabelRef.current),
          ])}
          {getCheckboxLabelRowItem([
            checkAndSetForBoldText(
              seasonFormatDisplay({
                seasonType,
                startDate: seasonStartDate,
                endDate: seasonEndDate,
              }),
              selectedOptionLabelRef.current
            ),
            checkAndSetForBoldText(
              `${leagueName} (${leagueAbbreviation})`,
              selectedOptionLabelRef.current
            ),
          ])}
        </div>
      );
    };

    const showCheckboxListItems = (itemType) => {
      switch(itemType) {
        case ITEM_TYPE.TEAM:
          return getCheckboxListForTeamCoachRoles(gameItem);
        case ITEM_TYPE.COACH:
          return getCheckboxListForUsers(gameItem);
        default:
          return getCheckboxListForGameRoles(gameItem);
      };
    };

    return (
      showCheckboxListItems(itemType)
    );
  };

  const onChangeHandler = (checked, gameId, seasonId, leagueId) => {
    const updatedGamesList = gamesList.map((game) => {
      return ((game.id === gameId) && (game.seasonId === seasonId) && (game.leagueId === leagueId)) ? { ...game, checked: !checked } : { ...game };
    });
    setGamesList(updatedGamesList);
    const selectedGames = updatedGamesList.filter(({ checked }) => checked);
    const tagItems = selectedGames
      .map(getTagItem)
      .filter(
        (tagItem) =>
          !previousTags
            .map((prevTagItem) => Object.keys(prevTagItem)[0])
            .includes(Object.keys(tagItem)[0])
      );
    previousTags = previousTags.filter((prevTag) =>
      Object.keys(prevTag)[0] !== gameId
        ? true
        : Object.keys(prevTag)[0] === gameId && !checked === true
    );
    const combinedTags = [...previousTags, ...tagItems];
    setPreviousTags(previousTags);
    setIsButtonDisabled(!(combinedTags.length > 0));
    setTags(combinedTags);
  };

  return (
    <div
      style={
        itemType === ITEM_TYPE.COACH
        ? {
            width: '33.33%',
            height: 40,
            paddingTop: 4,
          }
        : {
            width: '100%',
            height: 'auto',
            paddingTop: 4,
            borderBottom: '1px solid #E2E2E2',
          }
      }
    >
      <div
        style={{
          ...checkboxListItemStyle,
          backgroundColor: gameItem.checked ? '#D8E6FF' : 'transparent',
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <CheckboxWithLabel
          label={getCheckboxLabel()}
          labelStyles={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
          checkboxStyles={itemType === ITEM_TYPE.COACH && { paddingTop: 5, paddingBottom: 5, }}
          checked={gameItem.checked}
          name={gameItem.id}
          onChange={() => onChangeHandler(gameItem.checked, gameItem.id, gameItem.seasonId, gameItem.leagueId)}
        />
      </div>
    </div>
  );
};

const GameItemsSelection = ({
  tags,
  setTags,
  assignedGameIds,
  assignedTeamIds,
  assignedUserIds,
  setGamesList,
  gamesList,
  getTagItem,
  setIsButtonDisabled,
  selectedOptionLabelRef,
  formik,
  onSearch,
  isLoading,
  optionItems,
  itemType,
  placeHolderText,
  inputString,
  setInputString,
  gameItem,
  teamItemId,
}) => {
  const [previousTags, setPreviousTags] = useState([]);

  const listRef = useRef();
  const _isMobile = isMobile();

  const propertyId = formik.values.searchId;
  const { fetchAllCallback, cancelFetchAll, onSuccessCallback } = useFetchAll();

  useEffect(() => {
    if(propertyId.length === 0 && itemType === ITEM_TYPE.COACH) {
      setInputString('');
    }
  }, [propertyId]);

  const futureGamesQuery = useFutureGamesQuery({
    propertyId,
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(futureGamesQuery)(data),
  });
  const { data: matchingGames, isLoading: showSpinner } = futureGamesQuery;

  const usersQuery = useUsersQuery({
    filter: inputString,
    enabled: itemType === ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(usersQuery)(data),
  });
  const { data: users } = usersQuery;

  useFetchOnQuery(
    propertyId,
    () => fetchAllCallback([futureGamesQuery]),
    cancelFetchAll
  );

  useEffect(() => {
    setGamesList(
      getListItems(
        itemType, 
        matchingGames, 
        users, 
        assignedGameIds, 
        assignedTeamIds, 
        assignedUserIds, 
        tags
      )
    );
  }, [
    assignedGameIds, 
    assignedTeamIds, 
    assignedUserIds, 
    matchingGames, 
    users, 
    setGamesList, 
    tags,
    propertyId
  ]);

  const getListItems = (
    itemType, 
    matchingGames, 
    users,
    assignedGameIds, 
    assignedTeamIds, 
    assignedUserIds, 
    tags
  ) => {
    let listItems;

    switch(itemType) {
      case ITEM_TYPE.TEAM:
        listItems = getItemsForCoachRole(matchingGames, assignedTeamIds, tags);
        break;
      case ITEM_TYPE.COACH:
        listItems = getItemsForCoachUsers(users.filter(({ role }) => role.includes(ITEM_TYPE.COACH)), gameItem, assignedUserIds, tags);
        break;
      default:
        listItems = getItemsForGameRole(matchingGames, assignedGameIds, tags);
    };

    return listItems;
  };

  const getItemsForGameRole = (matchingGames, assignedGameIds, tags) => {
    return matchingGames
            .map((gameItem) => ({
              ...gameItem,
              checked: tags.some((tag) => Object.keys(tag)[0] === gameItem.id),
            }))
            .filter(({ id }) => !assignedGameIds.includes(id));
  };

  const getItemsForCoachRole = (matchingGames, assignedTeamIds, tags) => {
    let coachRoleItems = 
      matchingGames
        ?.map(game => {
            const {
              homeTeamId,
              awayTeamId,
              homeTeamName,
              awayTeamName,
              seasonId,
              seasonType,
              seasonStartDate,
              seasonEndDate,
              leagueId,
              leagueName,
              leagueAbbreviation,
            } = game;
            return [
              JSON.stringify({
                id: homeTeamId,
                teamName: homeTeamName,
                seasonId,
                seasonType,
                seasonStartDate,
                seasonEndDate,
                leagueId,
                leagueName,
                leagueAbbreviation,
              }),
              JSON.stringify({
                id: awayTeamId,
                teamName: awayTeamName,
                seasonId,
                seasonType,
                seasonStartDate,
                seasonEndDate,
                leagueId,
                leagueName,
                leagueAbbreviation,
              })
            ]
          })
          ?.reduce((updated, current) => {
            const [ homeTeam, awayTeam ] = current;
            return [ ...updated, homeTeam, awayTeam ];
          }, []);
          coachRoleItems = Array.from( 
                              new Set(coachRoleItems) 
                            )
                            ?.map(item => {
                              const itemObj = JSON.parse(item);
                              const games = matchingGames
                                                ?.filter(({ homeTeamId, awayTeamId, seasonId, leagueId }) => (homeTeamId === itemObj.id || awayTeamId === itemObj.id) && (seasonId === itemObj.seasonId) && (leagueId === itemObj.leagueId));
                              return { 
                                ...itemObj, 
                                checked: tags.some((tag) => Object.keys(tag)[0] === `${itemObj.id}_${itemObj.seasonId}_${itemObj.leagueId}`), 
                                games,
                              };
                            })
                            ?.filter(({ id, seasonId, leagueId }) => !(assignedTeamIds.find(assignedTeam => assignedTeam.id === id && assignedTeam.seasonId === seasonId && assignedTeam.leagueId === leagueId)))
                            ?.sort((a, b) => a.teamName.localeCompare(b.teamName));

          coachRoleItems = coachRoleItems.map(({ id }) => id)
                           ?.includes(propertyId) 
                            ? coachRoleItems?.filter(({ id }) => id === propertyId) 
                            : coachRoleItems;
    return coachRoleItems;
  };

  const getItemsForCoachUsers = (users, gameItem, assignedUserIds, tags) => {
    const findAssignedUserHandler = (id) => {
      return ({ userId, teamId, gameId, gameRole }) => {
        return gameItem
                ? userId === id && gameRole === ITEM_TYPE.COACH && gameId === gameItem.id && (teamId === gameItem.homeTeamId || teamId === gameItem.awayTeamId)
                : userId === id && gameRole === ITEM_TYPE.COACH && teamId === teamItemId;
      }
    };

    users = users
              .map(userItem => {
                return {
                  ...userItem,
                  checked: tags.some(tag => Object.keys(tag)[0] === userItem.id)
                }
              })
              .filter(({ id }) => !(assignedUserIds?.find(findAssignedUserHandler(id))))
              .sort((a, b) => a.firstName.localeCompare(b.firstName));

    return getUpdatedUsers(users, propertyId);
  };

  const getUpdatedUsers = (users, propertyId) => {
    const userItem = users?.find(({ id }) => id === propertyId);
    return userItem 
            ? users?.filter(({ firstName, lastName }) => (firstName === userItem?.firstName) || (lastName === userItem?.lastName))
            : users;
  };

  return (
    <div style={{ width: '100%', height: '70%', padding: 7 }}>
      <div
        style={{
          width: '100%',
          height: '15%',
          font: 'normal normal 600 11px/14px Open Sans',
          color: '#585858',
        }}
      >
        <GameItemSearchEntry
          searchInputEntryStyle={{
            height: 40,
            backgroundColor: '#F2F2F2',
            textAlign: 'left',
            paddingBottom: 0,
            paddingTop: 0,
          }}
          setGamesList={setGamesList}
          tags={tags}
          setPreviousTags={setPreviousTags}
          selectedOptionLabelRef={selectedOptionLabelRef}
          formik={formik}
          onSearch={onSearch}
          isLoading={isLoading}
          optionItems={optionItems}
          itemType={itemType}
          placeHolderText={placeHolderText}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: '82%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 10,
          position: 'relative',
        }}
      >
        {showSpinner ? (
          <Spinner
            animation="border"
            role="status"
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              zIndex: 100,
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            {gamesList.length > 0 ? (
              <ScrollListItemsContainer
                scrollRef={listRef}
                data={gamesList}
                listStyle={
                  itemType === ITEM_TYPE.COACH
                  ? {
                      flexGrow: 0,
                      flexFlow: 'row wrap',
                    }
                  : {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingRight: 5,
                    }
                }
                onScroll={() => onListScrollHandler(_isMobile, listRef.current)}
              >
                {gamesList.map((gameItem) => {
                  return (
                    <GameCheckboxItem
                      key={gameItem.id}
                      previousTags={previousTags}
                      setPreviousTags={setPreviousTags}
                      gameItem={gameItem}
                      setTags={setTags}
                      getTagItem={getTagItem}
                      gamesList={gamesList}
                      setGamesList={setGamesList}
                      setIsButtonDisabled={setIsButtonDisabled}
                      selectedOptionLabelRef={selectedOptionLabelRef}
                      itemType={itemType}
                    />
                  );
                })}
              </ScrollListItemsContainer>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  font: 'normal normal normal 16px/24px Inter',
                  color: '#5A5A5A',
                }}
              >
                No games available for the above search.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const GameListAssignment = ({
  setTags,
  tags,
  assignedGameIds,
  assignedTeamIds,
  assignedUserIds,
  setGamesList,
  gamesList,
  setIsButtonDisabled,
  selectedOptionLabelRef,
  formik,
  onSearch,
  optionItems,
  isLoading,
  itemType,
  placeHolderText,
  inputString,
  setInputString,
  gameItem,
  teamItemId,
}) => {
  const getTagItem = (objItem) => {
    /* Returns Tag Items For Teams */
    const getTeamNameTags = ({ 
      id,
      seasonId,
      leagueId,
      teamName,
    }) => {
      const getHtmlTag = (teamName) => (
        <div style={tagInputContainerStyle}>
          <div style={tagInputTeamNameStyle}>{teamName}</div>
        </div>
      );

      return (
        { [`${id}_${seasonId}_${leagueId}`] : getHtmlTag(teamName) }
      );
    };

    /* Returns Tag Items For Users */
    const getUserNameTags = ({
      id,
      firstName,
      lastName,
    }) => {
      const getHtmlTag = (firstName, lastName) => (
        <div style={tagInputContainerStyle}>
          <div style={tagInputTeamNameStyle}>{`${firstName} ${lastName}`}</div>
        </div>        
      );

      return (
        { [id]: getHtmlTag(firstName, lastName) }
      );
    };

    /* Returns Tag Items For Games */
    const getGameInfoTags = ({
      id,
      homeTeamName,
      awayTeamName,
      seasonType,
      leagueAbbreviation,
      seasonEndDate,
    }) => {
      const getHtmlTag = (homeTeamName, awayTeamName, leagueAbbreviation, seasonEndDate) => {
        let seasonStrFormat;
        switch (seasonType) {
          case 'Regular Season':
            seasonStrFormat = 'Reg';
            break;
          case 'Pre Season':
            seasonStrFormat = 'Pre';
            break;
          case 'Post Season':
            seasonStrFormat = 'Post';
            break;
          default:
            log.warn(`Unhandled season type: ${seasonType}`);
            break;
        };
    
        return (
          <div style={tagInputContainerStyle}>
            <div>{leagueAbbreviation}</div>,&nbsp;
            <div>{moment(seasonEndDate).format('YYYY')}</div>&nbsp;
            <div>{seasonStrFormat}</div>&nbsp;-&nbsp;
            <div style={tagInputTeamNameStyle}>{homeTeamName}</div>&nbsp;Vs&nbsp;
            <div style={tagInputTeamNameStyle}>{awayTeamName}</div>
          </div>
        );
      };

      return (
        { [id]: getHtmlTag(homeTeamName, awayTeamName, leagueAbbreviation, seasonEndDate) }
      );
    };

    const showTags = (itemType, objItem) => {
      switch(itemType) {
        case ITEM_TYPE.TEAM:
          return getTeamNameTags(objItem);
        case ITEM_TYPE.COACH:
          return getUserNameTags(objItem);
        default:
          return getGameInfoTags(objItem);
      };
    };

    return showTags(itemType, objItem);
  };

  const tagsChangedHandler = (index, tags) => {
    const tagItem = tags[index[0]];
    const gameId = Object.keys(tagItem)[0];
    const updateSelectedGames = gamesList.map((gameItem) => {
      return gameItem.id === gameId
        ? { ...gameItem, checked: false }
        : { ...gameItem };
    });
    setGamesList(updateSelectedGames);
    const updatedTags = tags.filter((tag) => Object.keys(tag)[0] !== gameId);
    setIsButtonDisabled(!(updatedTags.length > 0));
    setTags(updatedTags);
  };

  return (
    <>
      <label
        style={{
          font: 'normal normal normal 16px/24px Open Sans',
          textAlign: 'left',
          color: '#616161',
          marginBottom: 5,
        }}
      >
        {`Assign ${itemType}(s)`}
      </label>
      <div
        style={{
          width: '100%',
          height: 360,
          border: '2px solid #9B9B9B',
          marginBottom: 10,
        }}
      >
        <TaggedGamesSelection
          tags={tags}
          onTagsChanged={(index) => tagsChangedHandler(index, tags)}
        />
        <GameItemsSelection
          tags={tags}
          setTags={setTags}
          assignedGameIds={assignedGameIds}
          assignedTeamIds={assignedTeamIds}
          assignedUserIds={assignedUserIds}
          setGamesList={setGamesList}
          gamesList={gamesList}
          getTagItem={getTagItem}
          setIsButtonDisabled={setIsButtonDisabled}
          selectedOptionLabelRef={selectedOptionLabelRef}
          formik={formik}
          onSearch={onSearch}
          optionItems={optionItems}
          isLoading={isLoading}
          itemType={itemType}
          placeHolderText={placeHolderText}
          inputString={inputString}
          setInputString={setInputString}
          gameItem={gameItem}
          teamItemId={teamItemId}
        />
      </div>
    </>
  );
};

const useComboSearchQuery = (queryString, itemType, assignedUserIds) => {
  const {
    fetchAllCallback,
    cancelFetchAll,
    onSuccessCallback,
  } = useAutoFetchAll(queryString);

  const leaguesQuery = useLeaguesQuery({
    filter: queryString,
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(leaguesQuery)(data),
  });
  const leagues = leaguesQuery.data;

  const seasonsQuery = useSeasonsQuery({
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(seasonsQuery)(data),
  });
  const seasons = useMemo(
    () =>
      (itemType !== ITEM_TYPE.COACH)
        ? (queryString
            ? seasonsQuery.data.filter(
                ({ startDate, endDate, seasonType, league }) =>
                  getLeagueSeasonLabelFormat({
                    startDate,
                    endDate,
                    seasonType,
                    league,
                  })
                    .toLowerCase()
                    .includes(queryString.toLowerCase())
              )
            : seasonsQuery.data)
        : [],
    [queryString, seasonsQuery.data]
  );

  const teamsQuery = useTeamsQuery({
    filter: queryString,
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(teamsQuery)(data),
  });
  const teams = teamsQuery.data;

  const venuesQuery = useVenuesQuery({
    filter: queryString,
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(venuesQuery)(data),
  });
  const venues = venuesQuery.data;

  const gamesQuery = useFutureGamesQuery({
    enabled: itemType !== ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(gamesQuery)(data),
  });

  const games = useMemo(
    () =>
    (itemType !== ITEM_TYPE.COACH)
      ? (queryString
        ? Array.from(
            new Set(
              gamesQuery.data
                .filter(({ gameDateTime }) =>
                  isDateTimeStringEqualToQueryString(gameDateTime, queryString)
                )
                .map(({ gameDateTime }) =>
                  getMappedFormattedDateTimeString(gameDateTime, queryString)
                )
            )
          ).map((gameDateTime) => ({
            id: gameDateTime,
            gameDateTime: gameDateTime,
          }))
        : gamesQuery.data)
      : [],
    [gamesQuery.data, queryString]
  );

  const usersQuery = useUsersQuery({
    filter: queryString,
    enabled: itemType === ITEM_TYPE.COACH,
    onSuccess: (data) => onSuccessCallback(usersQuery)(data),
  });
  const usersFirstName = useMemo(
    () => 
      itemType === ITEM_TYPE.COACH
        ? usersQuery.data
            .filter(({ role }) => role.includes(ITEM_TYPE.COACH))
            .filter(({ id }) => !assignedUserIds?.find(({ userId }) => userId === id))
            .map(({ id, firstName }) => ({ id, firstName }))
            .sort((a, b) => a.firstName.localeCompare(b.firstName))
        : [],
    [usersQuery.data]
  );
  const usersLastName = useMemo(
    () => 
      itemType === ITEM_TYPE.COACH
        ? usersQuery.data
            .filter(({ role }) => role.includes(ITEM_TYPE.COACH))
            .filter(({ id }) => !assignedUserIds?.find(({ userId }) => userId === id))
            .map(({ id, lastName }) => ({ id, lastName }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [usersQuery.data]
  );

  const fetchAll = useCallback(
    () => fetchAllCallback([seasonsQuery, gamesQuery]),
    [fetchAllCallback, seasonsQuery, gamesQuery]
  );

  const queries = [
    leaguesQuery,
    seasonsQuery,
    teamsQuery,
    venuesQuery,
    gamesQuery,
    usersQuery,
  ];

  const isLoading = aggregateBooleanPropUsingOr(queries, 'isLoading');
  const isFetching = aggregateBooleanPropUsingOr(queries, 'isFetching');

  return {
    data: useMemo(
      () => [...leagues, ...seasons, ...teams, ...venues, ...games, ...usersFirstName, ...usersLastName],
      [leagues, seasons, teams, venues, games, usersFirstName, usersLastName]
    ),
    fetchAll,
    cancelFetchAll,
    isLoading,
    isFetching,
  };
};

const GameItemInitialEntry = ({
  setIsButtonDisabled,
  setInitialValues,
  formik,
  onSearch,
  optionItems,
  isLoading,
  selectedOptionLabelRef,
  itemType,
  placeHolderText,
}) => {
  useEffect(() => {
    const propertyId = formik.values.initialId;
    if (propertyId.length > 0) {
      setInitialValues({ initialId: propertyId, searchId: propertyId });
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formik.values.initialId, setInitialValues, setIsButtonDisabled]);

  return (
    <InputAsyncSelectDropdown
      label={`Assign ${itemType}(s)`}
      name="initialId"
      required={true}
      placeholder={placeHolderText}
      inputClassName="async-input-select-focused"
      isLoading={isLoading}
      asyncSearch={onSearch}
      options={optionItems}
      labelKey="label"
      onChangeCallback={(selected) => {
        selectedOptionLabelRef.current = selected ? selected.label : '';
      }}
    />
  );
};

const GameItemSearchEntry = ({
  searchInputEntryStyle,
  setGamesList,
  tags,
  setPreviousTags,
  selectedOptionLabelRef,
  formik,
  onSearch,
  isLoading,
  optionItems,
  itemType,
  placeHolderText,
}) => {
  let typeahead = useRef();
  const propertyId = formik.values.searchId;

  useEffect(() => {
    log.debug('debugging undefined value', {
      optionItems,
      propertyId,
    });

    if (!propertyId && optionItems.length === 0) {
      setGamesList([]);
      setPreviousTags(tags);
      typeahead.focus();
    }
  }, [optionItems, propertyId, setGamesList, setPreviousTags, tags]);

  return (
    <InputAsyncSelectDropdown
      name="searchId"
      required={false}
      placeholder={placeHolderText}
      inputClassName="async-input-select-blurred"
      isLoading={isLoading}
      asyncSearch={onSearch}
      options={optionItems}
      labelKey="label"
      style={searchInputEntryStyle}
      setRef={(ref) => (typeahead = ref)}
      onChangeCallback={(selected) => {
        selectedOptionLabelRef.current = selected ? selected.label : '';
      }}
    />
  );
};

const AssignUserGamesModal = ({
  gameUser,
  itemType,
  assignedGameIds,
  assignedTeamIds,
  assignedUserIds,
  modalOpen,
  setModalOpen,
  doneHandler,
  pickedGamesRemoved = [],
  isCancelledBtnClicked,
  setIsCancelledBtnClicked = () => {},
  onClose,
  gameItem = null,
  itemName = null,
  teamItemId = null,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [gamesList, setGamesList] = useState([]);
  const [isInEntryPointMode, setIsInEntryPointMode] = useState(false);
  const [initialValues, setInitialValues] = useState({
    initialId: '',
    searchId: '',
  });
  const [placeHolderText, setPlaceHolderText] = useState('');
  /** Games selected by user */
  const [tags, setTags] = useState([]);
  /** Search string */
  const [inputString, setInputString] = useState();
  /**
   * This ref stores the label from the option selected by the user.
   */
  const selectedOptionLabelRef = useRef();

  const {
    data: optionItems,
    fetchAll,
    /* TODO cancel fetching when the user makes a selection */
    cancelFetchAll,
    isFetching,
  } = useComboSearchQuery(inputString, itemType, assignedUserIds);
  const onSearch = (query) => {
    setInputString(query);
    fetchAll();
  };

  const optionItemsWithLabels = useMemo(
    () =>
      uniqby(
        optionItems.map((option) => ({
          ...option,
          label: setOptionLabel(option),
        })),
        'label'
      ),
    [optionItems]
  );

  useEffect(() => {
    if (modalOpen) {
      setIsCancelledBtnClicked(false);
      const text = itemType === ITEM_TYPE.COACH
                    ? 'Search for a coach by first or last name'
                    : 'Search for game by team, season, league, venue, or game date';
      setPlaceHolderText(text);
    }
  }, [modalOpen, setIsCancelledBtnClicked]);

  useEffect(() => {
    if (pickedGamesRemoved.length > 0) {
      const updatedTags = tags.filter(
        (tag) => (itemType === ITEM_TYPE.TEAM) 
                    ? !pickedGamesRemoved.map(({ id, seasonId, leagueId }) => `${id}_${seasonId}_${leagueId}`).includes(Object.keys(tag)[0]) 
                    : !pickedGamesRemoved.includes(Object.keys(tag)[0])
      );
      setTags(updatedTags);
      const updatedGames = gamesList.map((game) => {
        return pickedGamesRemoved.find((pickedGame) => pickedGame === game.id)
          ? { ...game, checked: false }
          : game;
      });
      setGamesList(updatedGames);

      if (updatedTags.length === 0) {
        setTimeout(() => setIsButtonDisabled(true), 200);
      }
    }
  }, [pickedGamesRemoved]);

  useEffect(() => {
    if (isCancelledBtnClicked) {
      clearAllModalDependencies();
    }
  }, [isCancelledBtnClicked]);

  useEffect(() => {
    if (isInEntryPointMode) {
      setIsButtonDisabled(isInEntryPointMode);
      if (itemType === ITEM_TYPE.TEAM) {
        setPlaceHolderText('Search for team, league, or season');
      }
    }
  }, [isInEntryPointMode]);

  const clearAllModalDependencies = () => {
    setIsCancelledBtnClicked(true);
    setInitialValues({ initialId: '', searchId: '' });
    setIsInEntryPointMode(false);
    setIsButtonDisabled(true);
    setGamesList([]);
    setInputString(null);
    setTags([]);
  };

  const sendPickedItems = (itemType, gameIds, gamesList) => {
    switch(itemType) {
      case ITEM_TYPE.TEAM:
        return gamesList.filter(({ id, seasonId, leagueId }) => gameIds.includes(`${id}_${seasonId}_${leagueId}`));
      case ITEM_TYPE.COACH:
        return gamesList.filter(({ id }) => gameIds.includes(id));
      default:
        return gameIds;
    };
  };

  /* Returns the modal header title with respect to modal type */
  const getModalHeaderTitle = (itemType) => {
    switch(itemType) {
      case ITEM_TYPE.COACH:
        return itemName ? `Assign Coaches to ${itemName}` : 'Assign Coaches to Teams';
      default:
        return `Assign ${itemType}(s) to ${gameUser}`;
    };
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {(formik) => {
        return (
          <ModalComponent
            headerTitle={getModalHeaderTitle(itemType)}
            open={modalOpen}
            setOpen={setModalOpen}
            modalWidth={900}
            handleClose={() => {
              clearAllModalDependencies();
              onClose();
            }}
            buttonDimmed={isButtonDisabled}
            buttonDisabled={isButtonDisabled}
            showNextButton={true}
            handleSubmit={(e) => {
              e.preventDefault();
            }}
            nextHandler={(e) => {
              const gameIds = tags.map((tag) => Object.keys(tag)[0]);
              log.debug('gameIds', gameIds);
              if (gameIds.length > 0) {
                setInitialValues({
                  initialId: formik.values.searchId,
                  searchId: formik.values.searchId,
                });
                doneHandler(
                  sendPickedItems(itemType, gameIds, gamesList)
                );
                onClose();
              } else {
                setIsInEntryPointMode(true);
              }
              e.preventDefault();
            }}
            modalHeaderStyles={{ marginBottom: (itemType === ITEM_TYPE.COACH) ? 0 : 24, borderBottomWidth: 0, }}
            modalBodyStyles={{ padding: 0 }}
            buttonContainerStyles={{ paddingLeft: 24, paddingRight: 24 }}
          >
            {(gameItem && (itemType === ITEM_TYPE.COACH)) &&
              <GameItemInformation
                gameItem={gameItem}
              />
            }
            <Row style={{ paddingLeft: 24, paddingRight: 24 }} className="mt-2 mb-2">
              <Col xl={12} xs={12} md={12}>
                {!isInEntryPointMode ? (
                  <GameItemInitialEntry
                    setIsButtonDisabled={setIsButtonDisabled}
                    setInitialValues={setInitialValues}
                    formik={formik}
                    onSearch={onSearch}
                    optionItems={optionItemsWithLabels}
                    selectedOptionLabelRef={selectedOptionLabelRef}
                    isLoading={isFetching}
                    itemType={itemType}
                    placeHolderText={placeHolderText}
                  />
                ) : (
                  <GameListAssignment
                    setTags={setTags}
                    tags={tags}
                    assignedGameIds={assignedGameIds}
                    assignedTeamIds={assignedTeamIds}
                    assignedUserIds={assignedUserIds}
                    setGamesList={setGamesList}
                    gamesList={gamesList}
                    setIsButtonDisabled={setIsButtonDisabled}
                    selectedOptionLabelRef={selectedOptionLabelRef}
                    formik={formik}
                    optionItems={optionItemsWithLabels}
                    onSearch={onSearch}
                    isLoading={isFetching}
                    itemType={itemType}
                    placeHolderText={placeHolderText}
                    inputString={inputString}
                    setInputString={setInputString}
                    gameItem={gameItem}
                    teamItemId={teamItemId}
                  />
                )}
              </Col>
            </Row>
          </ModalComponent>
        );
      }}
    </Formik>
  );
};

export default AssignUserGamesModal;
