export const getGameByDateTimeCustom = /* GraphQL */ `
  query GetGameByDatTim(
    $keySortId: ID
    $gameDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByDatTim(
      keySortId: $keySortId
      gameDateTime: $gameDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getGameByDateTimeMinimal = /* GraphQL */ `
  query GetGameByDatTim(
    $keySortId: ID
    $gameDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByDatTim(
      keySortId: $keySortId
      gameDateTime: $gameDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;