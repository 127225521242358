import { createSlice } from '@reduxjs/toolkit';
import { DisplayType } from './displayType';
import InputEvents from './inputEvents';

const initialState = {
  /* The Value of the event: InputEvent enum */
  value: null,
  /* Variable detailing what elements should be displayed and active on the scorekeeper: DisplayType enum */
  displayType: 'None',
  /* The active button that should be active for the current game flow */
  activeEventButton: '',
  /* Currently Selected Player for event */
  player: {},
  playerSecondary: {},
  userInstructions: '',
  /** An object containing the location (x, y) of the current event */
  location: {},
  /** The action type of the current game flow path, dictates the color of the location selection screen */
  actionType: {},
  /** An object containing the minute, seconds, etc. of the first event of this game flow */
  eventStartTime: {},
  /** An array of breadcrumbs populated as one progresses through different game flow states */
  breadcrumbs: [],
  /** Game ID */
  gameId: '',
  /** Current Logged in ScoreKeeper User */
  user: {},
  /** Team ID with Possession */
  possessionId: '',
  /** Game Timer */
  gameTimerStatus: '',
  /** Current Made/Missed free throw */
  freeThrowTracking: {
    made: 0,
    miss: 0,
    violation: 0,
  },
  /** State of the possession change routine */
  isTeamInPosessionSelected: false,
  /** The play being edited in the Play Feed */
  playBeingEdited: null,
};

export const eventsSlice = createSlice({
  name: 'scoreKeeperEvents',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    buttonEvent: (state, action) => {
      state.value = action.payload;
    },
    playerSelectEvent: (state, action) => {
      state.value = InputEvents.PLAYER;
      state.player = action.payload;
    },
    playerSecondarySelectEvent: (state, action) => {
      state.value = InputEvents.PLAYER;
      state.playerSecondary = action.payload;
    },
    changeDisplayType: (state, action) => {
      state.value = InputEvents.UPDATE_DISPLAY_TYPE;
      state.displayType = action.payload;
    },
    locationSelection: (state, action) => {
      state.value = InputEvents.LOCATION;
      state.location = action.payload;
    },
    addBreadcrumb: (state, action) => {
      state.breadcrumbs = [...state.breadcrumbs, action.payload];
    },
    removeBreadcrumb: (state, action) => {
      state.breadcrumbs = state.breadcrumbs.slice(0, -1);
    },
    clearBreadcrumb: (state, action) => {
      state.breadcrumbs = [];
    },
    setActionType: (state, action) => {
      state.actionType = action.payload;
    },
    setEventStartTime: (state, action) => {
      state.eventStartTime = action.payload;
    },
    setGameId: (state, action) => {
      state.gameId = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPossessionId: (state, action) => {
      state.possessionId = action.payload;
    },
    setActiveEventButton: (state, action) => {
      state.activeEventButton = action.payload;
    },
    setUserInstructions: (state, action) => {
      state.userInstructions = action.payload;
    },
    setGameTimerStatus: (state, action) => {
      state.gameTimerStatus = action.payload;
    },
    setFreeThrowMadeCount: (state, action) => {
      state.freeThrowTracking.made = action.payload;
    },
    setFreeThrowMissCount: (state, action) => {
      state.freeThrowTracking.miss = action.payload;
    },
    setFreeThrowViolationCount: (state, action) => {
      state.freeThrowTracking.violation = action.payload;
    },
    setTeamInPossessionSelected: (state, action) => {
      state.isTeamInPosessionSelected = action.payload;
    },
    setPlayBeingEdited: (state, action) => {
      state.playBeingEdited = action.payload;
      state.displayType = action.payload
        ? DisplayType.EDIT
        : DisplayType.ACTION;
    },
  },
});

export const {
  buttonEvent,
  playerSelectEvent,
  playerSecondarySelectEvent,
  changeDisplayType,
  locationSelection,
  addBreadcrumb,
  removeBreadcrumb,
  clearBreadcrumb,
  setActionType,
  setEventStartTime,
  setGameId,
  setUser,
  setPossessionId,
  setActiveEventButton,
  setUserInstructions,
  setGameTimerStatus,
  setFreeThrowMadeCount,
  setFreeThrowMissCount,
  setFreeThrowViolationCount,
  setTeamInPossessionSelected,
  setPlayBeingEdited,
} = eventsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.events.value)`
export const selectState = (state) => state.scoreKeeperEvents;
export const selectEvent = (state) => state.scoreKeeperEvents.value;
export const selectPlayer = (state) => state.scoreKeeperEvents.player;
export const selectPlayerSecondary = (state) =>
  state.scoreKeeperEvents.playerSecondary;
export const selectDisplayType = (state) => state.scoreKeeperEvents.displayType;
export const selectBreadcrumbs = (state) => state.scoreKeeperEvents.breadcrumbs;
export const selectActionType = (state) => state.scoreKeeperEvents.actionType;
export const selectEventStartTime = (state) =>
  state.scoreKeeperEvents.eventStartTime;
export const selectActiveEventButton = (state) =>
  state.scoreKeeperEvents.activeEventButton;
export const selectUserInstructions = (state) =>
  state.scoreKeeperEvents.userInstructions;
export const selectShotLocation = (state) => state.scoreKeeperEvents.location;
export const selectGameTimerStatus = (state) =>
  state.scoreKeeperEvents.gameTimerStatus;
export const selectFreeThrowMadeCount = (state) =>
  state.scoreKeeperEvents.freeThrowTracking.made;
export const selectFreeThrowMissCount = (state) =>
  state.scoreKeeperEvents.freeThrowTracking.miss;
export const selectFreeThrowViolationCount = (state) =>
  state.scoreKeeperEvents.freeThrowTracking.violation;
export const selectFreeThrowShotCount = (state) =>
  state.scoreKeeperEvents.freeThrowTracking.made +
  state.scoreKeeperEvents.freeThrowTracking.miss +
  state.scoreKeeperEvents.freeThrowTracking.violation;
export const selectIsTeamInPosessionSelected = (state) =>
  state.scoreKeeperEvents.isTeamInPosessionSelected;
export const selectPlayBeingEdited = (state) =>
  state.scoreKeeperEvents.playBeingEdited &&
  JSON.parse(state.scoreKeeperEvents.playBeingEdited);

export default eventsSlice.reducer;
