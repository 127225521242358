import { createSlice } from '@reduxjs/toolkit';

export const switchViewSlice = createSlice({
	name: 'switchView',
	initialState: {
		value: true,
	},
	reducers: {
		updateSwitchView: (state, action) => {
			state.value = action.payload
		},
		flipSwitchView: (state) => {
			state.value = !state.value;
		},
	},
});

export const { updateSwitchView, flipSwitchView } = switchViewSlice.actions;
export default switchViewSlice.reducer;
