import { Storage } from '@aws-amplify/storage';
import { API, graphqlOperation } from 'aws-amplify';
import {
  listLeagues as listLeaguesQuery,
  getLeague as getLeaguesQuery,
  getLeagueByUpdatedAt as getLeagueByUpdatedAtQuery,
} from '../graphql/queries';
import {
  createLeague as createLeagueMutation,
  updateLeague as updateLeagueMutation,
} from '../graphql/mutations';
import PlaceholderImage from '../assets/LeaguePlaceholder.png';
import { KEY_SORT_ID } from '../utils/constantsUtils';
const log = require('../logger')('api');

Storage.configure({ level: 'public' });

const saveLeagueImageProfile = async (leagueId, imageFile) => {
  try {
    const response = await Storage.put(`league/${leagueId}`, imageFile, {
      contentType: imageFile.type,
      level: 'public',
    });
    return response;
  } catch (error) {
    log.error(`Unable to save image ${leagueId} to storage...`);
  }
};

export const getLeagueImageProfile = async (leagueId) => {
  try {
    const doesImageExist = await Storage.list(`league/${leagueId}`);
    if (doesImageExist.length > 0) {
      const imageURL = await Storage.get(`league/${leagueId}`);
      return imageURL;
    } else {
      return PlaceholderImage;
    }
  } catch (error) {
    log.error(`Unable to read image ${leagueId} from storage...`);
  }
};

// const deleteLeagueImageProfile = async (leagueId) => {
//   try {
//     const deletedImage = await Storage.remove(`league/${leagueId}`);
//     return deletedImage;
//   } catch (error) {
//     log.error(`Unable to delete image ${leagueId} from storage...`);
//   }
// };

// get call with parameter
export const listLeaguesSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    // Perform Query got list of league
    const response = await API.graphql(
      graphqlOperation(getLeagueByUpdatedAtQuery, vars)
    );
    // Return the League Items
    return response.data.getLeagueByUpdatedAt;
  } catch (err) {
    log.error('Error fetching league list...', err);
    return err;
  }
};

export const listLeagues = async (variables = null) => {
  try {
    // Perform Query got list of league
    const response = await API.graphql(
      graphqlOperation(listLeaguesQuery, variables)
    );
    // Return extracted the League Items
    /* TODO remove .items to support pagination */
    return response.data.listLeagues.items;
  } catch (err) {
    log.error('Error fetching league list...', err);
    return err;
  }
};

// get call with parameter
export const getLeague = async (id) => {
  try {
    const responce = await API.graphql(
      graphqlOperation(getLeaguesQuery, { id: id })
    );
    // TODO: Should we return the image here too, or make it optional
    const league = responce.data.getLeague;
    league.image = await getLeagueImageProfile(id);
    return league;
  } catch (err) {
    log.error('Error fetching league...', err);
  }
};

//create call with parameter
export const createLeague = async (values) => {
  try {
    // Create League
    const response = await API.graphql(
      graphqlOperation(createLeagueMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          name: values.name,
          abbreviation: values.abbreviation,
          teamSize: values.teamSize,
          numPlayersOnCourt: values.numPlayersOnCourt,
          numPlayersOnBench: values.numPlayersOnBench,
          numTimeOuts: values.numTimeOuts,
          numFoulsPerPlayer: values.numFoulsPerPlayer,
          numPeriods: values.numPeriods,
          timePerPeriodInMins: values.timePerPeriodInMins,
          overtimeDurationInMins: values.overtimeDurationInMins,
          sportId: values.sportId,
          searchName: values.name.toLowerCase(),
          searchAbbreviation: values.abbreviation.toLowerCase(),
        },
      })
    );
    const league = response.data.createLeague;
    const saveImaged = await saveLeagueImageProfile(league.id, values.image);
    return { ...league, saveImaged };
  } catch (err) {
    log.error('Error creating league...', err);
    return err;
  }
};

export const updateLeague = async (id, values) => {
  /**
   * Only the name and abbreviation is allowed to be updated
   */
  try {
    const response = await API.graphql(
      graphqlOperation(updateLeagueMutation, {
        input: {
          id: id,
          name: values.name,
          abbreviation: values.abbreviation,
          searchName: values.name.toLowerCase(),
          searchAbbreviation: values.abbreviation.toLowerCase(),
          _version: values._version,
        },
      })
    );
    if (values.image.type) {
      await saveLeagueImageProfile(id, values.image);
    }
    return response.data.updateLeague;
  } catch (err) {
    log.error('Error updating league...', err);
    return err;
  }
};
