import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import BasketballPlayerImage from '../../../assets/basketball-player-multicolor@2x.png';
import UpChevron from '../../../assets/upArrow.png';
import { MOBILE_MEDIA } from '../../../styles';
import { colors, gradients } from '../../../styles/colorsStatsCollector';
import {
  getEventOptions,
  shouldShowActionButtons,
  shouldShowCancelButton,
  shouldShowCourtSelection,
  shouldShowFreeThrowFollowUp,
  shouldShowFreeThrowMissFollowUp,
  shouldShowOptionsButtons,
  shouldShowPlayerButtons,
  shouldShowViolationScreen,
  showLocation2PT,
} from '../controller/displayType';
import InputEvents from '../controller/inputEvents';
import {
  buttonEvent,
  selectActionType,
  selectBreadcrumbs,
  selectDisplayType,
  selectFreeThrowShotCount,
  selectPlayBeingEdited,
  selectUserInstructions,
} from '../controller/scoreKeeperEventSlice';
import { TEAM_PLAYERS_DEF } from '../PropTypeDefs.type';
import CourtLocation from './CourtLocation';
import EndPlayScreen from './EndPlayScreen';
import FreeThrowFollowUpScreen from './FreeThrowFollowUpScreen';
import HeadingWithLine from './HeadingWithLine';
import PlayerSelection from './PlayerSelection';
import ScorekeeperEditScreen from './ScorekeeperEditScreen';
import ViolationScreen from './ViolationScreen';

export const EventActions = ({
  homeTeamLogo,
  awayTeamLogo,
  homeTeamPlayers,
  awayTeamPlayers,
  possessionTeam,
  gameData,
  lineupData,
  ...props
}) => {
  const MAX_FT_COUNT = 3;
  const dispatch = useDispatch();
  const displayType = useSelector(selectDisplayType);
  const actionType = useSelector(selectActionType);
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const userInstructions = useSelector(selectUserInstructions);
  const freeThrowShotCount = useSelector(selectFreeThrowShotCount);
  const playBeingEdited = useSelector(selectPlayBeingEdited);

  return (
    <Container {...props}>
      {breadcrumbs && <ActionBreadcrumbs actions={breadcrumbs} />}
      {playBeingEdited && (
        <ScorekeeperEditScreen
          playBeingEdited={playBeingEdited}
          gameData={gameData}
          lineupData={lineupData}
        />
      )}

      {shouldShowActionButtons(displayType) && <DefaultScreen />}
      {shouldShowPlayerButtons(displayType) && (
        <PlayerSelection
          homeTeamLogo={homeTeamLogo}
          awayTeamLogo={awayTeamLogo}
          homeTeamPlayers={homeTeamPlayers}
          awayTeamPlayers={awayTeamPlayers}
          possessionTeam={possessionTeam}
          instructions={userInstructions}
        />
      )}
      {shouldShowCourtSelection(displayType) && (
        <CourtLocation
          actionType={actionType}
          isTwoPointer={showLocation2PT(displayType)}
        />
      )}
      {shouldShowOptionsButtons(displayType) && (
        <EndPlayScreen
          endPlayClickHandler={() =>
            dispatch(buttonEvent(InputEvents.END_PLAY))
          }
          additionalOptions={getEventOptions(displayType, dispatch)}
        />
      )}
      {shouldShowViolationScreen(displayType) && (
        <ViolationScreen options={getEventOptions(displayType, dispatch)} />
      )}
      {shouldShowFreeThrowFollowUp(displayType) && (
        <FreeThrowFollowUpScreen
          endPlayClickHandler={() =>
            dispatch(buttonEvent(InputEvents.END_PLAY))
          }
          shouldShowEndPlayOptions={freeThrowShotCount > 0}
          shouldShowReboundOptions={shouldShowFreeThrowMissFollowUp(
            displayType
          )}
          shouldShowFreeThrowOptions={freeThrowShotCount < MAX_FT_COUNT}
        />
      )}
      {!shouldShowActionButtons(displayType) && (
        <div>
          {breadcrumbs.length > 1 && (
            <BackButton
              onClick={() => dispatch(buttonEvent(InputEvents.BACK))}
              style={{ marginRight: 18 }}
            />
          )}

          {shouldShowCancelButton(displayType) && <CancelButton />}
        </div>
      )}
    </Container>
  );
};

EventActions.propTypes = {
  homeTeamLogo: PropTypes.any,
  awayTeamLogo: PropTypes.any,
  homeTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF)),
  awayTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF)),
};

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DefaultScreen = styled(({ next, ...props }) => {
  return (
    <div {...props}>
      <EventActionsHeading />

      <div className="instructions">Select an action to collect stats</div>
      <div className="image-container">
        <img
          src={BasketballPlayerImage}
          alt="Basketball Player Illustration in Multicolor"
          onClick={next}
        />
      </div>
    </div>
  );
})`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-height: 100%;

    h3 {
      text-transform: none;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: bold;
    }

    .instructions {
      font: normal normal normal 18px/24px Open Sans;
      letter-spacing: -0.36px;
      color: ${({ theme }) =>
        theme.lightMode ? colors.GRAY[2300] : colors.GRAY[500]};
      margin: 36px 0;
    }

    .image-container {
      flex: 1;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        position: absolute;
        max-height: 100%;
      }
    }

    @media ${MOBILE_MEDIA} {
      h3 {
        font-size: 13px;
      }
      .instructions {
        font-size: 15px;
      }
    }
  }
`;

const ActionBreadcrumbs = styled(({ actions, ...props }) => {
  const lastIdx = actions.length - 1;

  return (
    <div {...props}>
      {actions.map((action, idx) => (
        <React.Fragment key={idx}>
          <Button variant="outlined">{action}</Button>
          {idx !== lastIdx && <RightChevron className="breadcrumb-separator" />}
        </React.Fragment>
      ))}
    </div>
  );
})`
  &&& {
    // margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button {
      text-transform: none;
      font: normal normal 600 14px/19px Open Sans;
      letter-spacing: -0.28px;
      color: ${({ theme }) =>
        theme.lightMode ? colors.GRAY[2300] : colors.GRAY[100]};
      border: 1px solid
        ${({ theme }) =>
          theme.lightMode
            ? colors.GRAY_TRANSPARENT[700]
            : colors.GRAY_TRANSPARENT[900]};
      margin-bottom: 5px;
      min-width: 25px;
    }

    .breadcrumb-separator {
      margin: 0 12px;
    }

    @media ${MOBILE_MEDIA} {
      button {
        font-size: 11px;
        padding: 3px 6px;
      }
      .breadcrumb-separator {
        margin: 0 7px;
      }
    }
  }
`;
ActionBreadcrumbs.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const LeftChevron = (props) => (
  <img
    src={UpChevron}
    alt="Back"
    style={{
      ...props.style,
      transform: 'rotate(-90deg)',
    }}
  />
);

export const RightChevron = (props) => (
  <img
    src={UpChevron}
    alt=""
    {...props}
    style={{
      ...props.style,
      transform: 'rotate(90deg)',
    }}
  />
);

export const StyledButton = styled(Button)`
  &&& {
    // width: 141px;
    padding: 8px 30px;
    // height: 41px;
    background: ${({ theme }) =>
        theme.lightMode ? colors.GRAY[100] : gradients.BLACK}
      0% 0% no-repeat padding-box;
    border: 1px solid #828282;
    margin-right: 9px;
    margin-left: 9px;

    text-transform: none;
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.WHITE[100]};

    &:disabled {
      opacity: 0.35;
    }
  }
`;

export function EventActionsHeading({ style = {} }) {
  const lightMode = useContext(ThemeContext).lightMode;
  return (
    <HeadingWithLine
      label={'Event Actions'}
      color={lightMode ? colors.BLACK[300] : colors.GRAY[500]}
      style={{
        width: '250px',
        ...style,
      }}
    />
  );
}

export function BackButton(props) {
  return (
    <StyledButton
      variant="outlined"
      {...props}
      style={{ position: 'relative' }}
    >
      <LeftChevron
        style={{
          marginRight: 7,
        }}
      />

      <span>Back</span>
    </StyledButton>
  );
}

export function NextButton(props) {
  return (
    <StyledButton
      variant="outlined"
      {...props}
      style={{ position: 'relative', ...props.style }}
    >
      <span>Next</span>
      <RightChevron alt="Next" style={{ marginLeft: 7 }} />
    </StyledButton>
  );
}

export function CancelButton(props) {
  const dispatch = useDispatch();
  return (
    <StyledButton
      onClick={() => dispatch(buttonEvent(InputEvents.CANCEL))}
      variant="outlined"
      {...props}
    >
      Cancel
    </StyledButton>
  );
}

export default EventActions;
