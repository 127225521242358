import React, { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import TextButton from '../components/Buttons/TextButton';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'
import { colors } from '../styles/colors'
import { typeScale } from '../styles/typography'
import { getGame } from '../api/gamesService'
import { getLeague } from '../api/leaguesService'
import { useParams, useHistory } from 'react-router-dom';
import { getSport } from '../api/sportsService';

const ActionsStyled = styled.div`
	&&& {
		font-weight: bold;
		font-size: ${typeScale.MEDIUM.fontSize};
	}
`;

const ActionsBox = styled.div`
	&&& {
		background-color: ${colors.GRAY[100]};
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 3px solid ${colors.GRAY[200]};
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
`;

const StatBox = styled(Box)`
	&&& {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 1rem;
		padding-left: 1rem;

		border-top: 2px solid ${colors.GRAY[200]};
		border-bottom: 2px solid ${colors.GRAY[200]};
		border-right: 2px solid ${colors.GRAY[200]};
	}
`;

const FirstStatBox = styled(Box)`
	&&& {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 1rem;
		padding-left: 1rem;
		border-top: 2px solid ${colors.GRAY[200]};
		border-bottom: 2px solid ${colors.GRAY[200]};
		border-left: 2px solid ${colors.GRAY[200]};
		border-right: 2px solid ${colors.GRAY[200]};
	}
`;

const LabelStyled = styled.div`
	&&& {
		color: ${colors.GRAY[400]};
		font-size: ${typeScale.MEDIUM.fontSize};
		const StyledLabel = styled.span;
		font-weight: 600;
	}
`;

const ValueStyled = styled.div`
	&&& {
		color: ${colors.GRAY[300]};
		font-size: ${typeScale.MEDIUM.fontSize};
	}
`;

const GameIndividualPage = () => {

	const { id } = useParams();
	const history = useHistory();
	const [leagueName, setLeagueName] = useState(null)
	const [leagueId, setLeagueId] = useState(null)
	const [sportName, setSportName] = useState(null)
	const [homeName, setHomeName] = useState(null)
	const [awayName, setAwayName] = useState(null)
	const [firstHomePlayer, setfirstHomePlayer] = useState(null)
	const [firstAwayPlayer, setFirstAwayPlayer] = useState(null);
	const [homeTeamLineup, setHomeTeamLineup] = useState([])
	const [awayTeamLineup, setAwayTeamLineup] = useState([])
	const [venueName, setVenueName] = useState(null)

	useEffect(() => {
		const apiCalls = async () => {
			const gameData = await getGame(id);
			const game = gameData.data.getGame;
			let sportData = null;
			const leagueData = await getLeague(game.leagueId);
			if (leagueData.data.getLeague) {
				sportData = await getSport(leagueData.data.getLeague.sportId);
			}

			leagueData.data.getLeague &&
				setLeagueName(leagueData.data.getLeague.name);
			sportData.data.getSport && setSportName(sportData.data.getSport.name);
			setHomeName(game.teamOne?.name);
			setAwayName(game.teamTwo?.name);
			setVenueName(game.venue?.name);
			setLeagueId(game.season?.leagueId);

			const homeTeamLineup = game.teamOneLineup?.players.items || [];
			const awayTeamLineup = game.teamTwoLineup?.players.items || [];
			homeTeamLineup[0] &&
				setfirstHomePlayer(
					`${homeTeamLineup[0].player.firstName} ${homeTeamLineup[0].player.lastName} `
				);
			awayTeamLineup[0] &&
				setFirstAwayPlayer(
					`${awayTeamLineup[0].player.firstName} ${awayTeamLineup[0].player.lastName} `
				);
			setHomeTeamLineup(homeTeamLineup.slice(1));
			setAwayTeamLineup(awayTeamLineup.slice(1));
		}
		apiCalls()
	}, [id])

	return (
		<MainLayout title='Game Details' bodyContent={''}>
			<ActionsBox>
				<Grid container alignItems='center'>
					<Grid item xs={9} />
					<Grid container justify='center' xs={3}>
						<ActionsStyled>Actions</ActionsStyled>
					</Grid>
				</Grid>
			</ActionsBox>

			<Grid container alignItems='center'>
				<Grid item xs={2}>
					<LabelStyled>Sport</LabelStyled>
				</Grid>
				{sportName && (
					<Grid container direction='row' xs={7}>
						<FirstStatBox>
							<ValueStyled>{sportName}</ValueStyled>
						</FirstStatBox>
					</Grid>
				)}
			</Grid>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>League</LabelStyled>
					</Grid>
					<Grid container direction='row' xs={7}>
						{leagueName && (
							<FirstStatBox>
								<ValueStyled>{leagueName}</ValueStyled>
							</FirstStatBox>
						)}
					</Grid>
					{leagueId && (
						<Grid justify='center' direction='row' container xs={3}>
							<TextButton
								label='View'
								handleClick={() => history.push(`/leagues/${leagueId}`)}
							/>
						</Grid>
					)}
				</Grid>
			</div>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>Home Team</LabelStyled>
					</Grid>
					<Grid container direction='row' xs={7}>
						{homeName && (
							<FirstStatBox>
								<ValueStyled>{homeName}</ValueStyled>
							</FirstStatBox>
						)}
					</Grid>
				</Grid>
			</div>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>Home Team Lineup</LabelStyled>
					</Grid>
					<Grid container direction='row' wrap='wrap' xs={7}>
						{firstHomePlayer && (
							<FirstStatBox>
								<ValueStyled>{firstHomePlayer}</ValueStyled>
							</FirstStatBox>
						)}
						{homeTeamLineup.map((player) => (
							<StatBox>
								<ValueStyled>{`${player.player.firstName} ${player.player.lastName}`}</ValueStyled>
							</StatBox>
						))}
					</Grid>
					{firstHomePlayer && (
						<Grid justify='center' direction='row' container xs={3}>
							<TextButton
								label='View'
								handleClick={() => history.push(`/gameLineup/${id}`)}
							/>
						</Grid>
					)}
				</Grid>
			</div>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>Away Team</LabelStyled>
					</Grid>
					<Grid container direction='row' xs={7}>
						{awayName && (
							<FirstStatBox>
								<ValueStyled>{awayName}</ValueStyled>
							</FirstStatBox>
						)}
					</Grid>
				</Grid>
			</div>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>Away Team Lineup</LabelStyled>
					</Grid>
					<Grid container direction='row' wrap='wrap' xs={7}>
						{firstAwayPlayer && (
							<FirstStatBox>
								<ValueStyled>{firstAwayPlayer}</ValueStyled>
							</FirstStatBox>
						)}
						{awayTeamLineup.map((player) => (
							<StatBox>
								<ValueStyled>{`${player.player.firstName} ${player.player.lastName}`}</ValueStyled>
							</StatBox>
						))}
					</Grid>
					{firstAwayPlayer && (
						<Grid justify='center' direction='row' container xs={3}>
							<TextButton
								label='View'
								handleClick={() => history.push(`/gameLineup/${id}`)}
							/>
						</Grid>
					)}
				</Grid>
			</div>

			<div style={{ marginTop: 24 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<LabelStyled>Away Team</LabelStyled>
					</Grid>
					<Grid container direction='row' xs={7}>
						{venueName && (
							<FirstStatBox>
								<ValueStyled>{venueName}</ValueStyled>
							</FirstStatBox>
						)}
					</Grid>
				</Grid>
			</div>
		</MainLayout>
	);
};

export default GameIndividualPage;
