import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useListLeaguesQuery } from '../api/leaguesQueries';
import { seasonsKeys, useSeasonsQuery } from '../api/seasonsQueries';
import { createSeason, getSeason } from '../api/seasonsService';
import ToastAlert from '../components/alerts/ToastAlert';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import SquareGrid from '../components/Grids/SquareGrid';
import MainLayout from '../components/layout/MainLayout';
import AddEditSeasonModal from '../components/Modals/AddEditSeasonModal';
import SeasonContent from '../components/PageContent/SeasonContent';
import Spinner from '../components/Spinner';
import { colors } from '../styles';
const log = require('../logger')('adminPanel');

const SeasonsPage = () => {
  const queryClient = useQueryClient();
  const [isCreate, setIsCreate] = useState();
  const [seasonItem, setSeasonItem] = useState({
    seasonType: '',
    startDate: '',
    endDate: '',
    league: '',
    leagueId: '',
    infoCheck: false,
  });
  const [error, setError] = useState();

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const { data: leagues, isSuccess: leaguesSuccess } = useListLeaguesQuery();

  const {
    data: seasons,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isLoading,
    isFetching,
  } = useSeasonsQuery({
    onError: (error) => {
      log.error(error);
      setShowToast(true);
      setToastText('An error occurred while retrieving seasons.');
      setToastVariant('danger');
    },
  });

  const handleSubmit = async (values) => {
    /**
     * Create or Edit
     */
    const returnData = await createSeason(
      values.seasonType,
      values.leagueId,
      values.startDate,
      values.endDate
    );

    /* If League Create/Update failed show an error, else close the modal */
    if (returnData.errors) {
      setError(`Unable to create seasson. ${returnData.errors[0].message}.`);
    } else {
      /**
       * Success
       * Clear error, update list, close model, show toast, reset form
       */
      setError(null);
      queryClient.invalidateQueries(seasonsKeys.all);
      setOpen(false);
      setToastText(
        `The Season "${values.seasonType}" was created successfully.`
      );
      setShowToast(true);
    }
  };

  const onModalClose = () => {
    setError(null);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsCreate(true);
  };

  const handleEditModalOpen = async (seasonId) => {
    const season = await getSeason(seasonId);

    const { id, seasonType, startDate, endDate, leagueId, league } = season;
    const { name } = league;

    const seasonItem = {
      id,
      seasonType,
      startDate,
      endDate,
      leagueId,
      league: name,
      infoCheck: false,
    };

    setSeasonItem(seasonItem);
    setIsCreate(false);
    setOpen(true);
  };

  return (
    <MainLayout
      title="Seasons"
      buttonLabel="+ Add Season"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {isSuccess && seasons.length === 0 && (
        <SquareGrid
          height={275}
          width={250}
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          justifyContent={'center'}
        >
          <AddCircleButton
            buttonLabel="Add Season"
            buttonClickHandler={handleOpen}
          />
        </SquareGrid>
      )}

      {isSuccess && seasons.length > 0 && (
        <SeasonContent
          searchId="__seasonId"
          placeholder="Search Seasons"
          data={seasons}
          handleEditModalOpenHook={handleEditModalOpen}
          fetchNextPage={() => fetchNextPage()}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
        />
      )}

      <AddEditSeasonModal
        isNewSeason={isCreate}
        isOpen={open}
        setOpen={setOpen}
        leaguesData={leagues}
        onSubmit={handleSubmit}
        onClose={onModalClose}
        seasonData={seasonItem}
        error={error}
        setError={setError}
        isLoading={!leaguesSuccess}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};
export default SeasonsPage;
