/**
 * Truncates a string to a maximum number of characters,
 * followed by an ellipsis (...) if the character limit is exceeded.
 *
 * @param {string} text input string
 * @param {number} limit number of characters to limit text to
 * @returns truncated string
 */
export const truncateText = (text, limit) => {
  const isGreater = text?.toString()?.length > limit;
  return `${text?.substring(0, limit)}${isGreater ? '...' : ''}`;
};

/** capitalize first letter of each word */
export const capitalizeFirstLetter = (string) => {
  return string
    ?.toLowerCase()
    ?.split(' ')
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word.slice(1))
    ?.join(' ');
};
