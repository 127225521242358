import React from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

const InputSwitchComponent = ({ marginLeft, marginRight, isHorizontal, disabled = false, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'switch' });

  return (
    <Form.Group className={'d-flex flex-column'}>
      <Form.Label style={{ color: disabled ? '#808080' : '#000000', }}>
        {props.required ? `${props.switchLabel}*` : props.switchLabel}
      </Form.Label>
      <Form.Check
        type="switch"
        defaultChecked={field.value}
        {...field}
        {...props}
        label={field.value ? props.labelPositive : props.labelNegative}
        style={{
          marginLeft: marginLeft || 0,
          marginRight: marginRight || 0,
        }}
        disabled={disabled}
        isInvalid={meta.touched && meta.error}
        feedback={meta.error}
        feedbackType="invalid"
      />
    </Form.Group>
  );
};

export default InputSwitchComponent;
