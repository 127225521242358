import { useInfiniteQuery, useQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { transformPlayerLineupData } from '../utils/gameLineupUtils';
import { useItemsFromPages, useFetchAll } from '../utils/reactQueryToolkit';
import {
  getTeam,
  getTeamImageProfile,
  listTeams,
  listTeamsSortedByUpdatedAt,
  listTeamUsers, 
} from './teamsService';

export const teamsKeys = {
  all: ['teams'],
  filter: (filter) => [...teamsKeys.all, { filter }],
  detail: (teamId) => [...teamsKeys.all, { teamId }],
  image: (teamId) => [...teamsKeys.all, 'image', { teamId }],
};

export const teamUsersKeys = {
  all: ['teamUsers']
};

/**
 * @deprecated Should use useTeamsQuery with pagination, since this can hit DynamoDB's fetch limit.
 */
export const useListTeamsQuery = () =>
  useQuery({
    queryKey: teamsKeys.all,
    queryFn: async () => {
      const teams = await listTeams();
      return teams;
    },
    refetchOnWindowFocus: false,
  });

export const useTeamsQuery = ({ filter, ...options } = {}) => {
  /**
   * This useInfiniteQuery retrieves a set of teams page by page using the listTeams teamsService API method.
   */
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: teamsKeys.filter(filter),
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      if (filter?.length > 0) {
        const filterLowerCase = filter.toLowerCase();
        queryVars = {
          filter: {
            or: [
              { searchName: { contains: filterLowerCase } },
              { searchCity: { contains: filterLowerCase } },
              { searchState: { contains: filterLowerCase } },
            ],
          },
          nextToken: pageParam,
        };
      }

      const teamsData = await listTeamsSortedByUpdatedAt(queryVars);

      // Get the Image for each team
      const teamsWithImages = await Promise.all(
        teamsData.items &&
          teamsData.items.map(async (team) => {
            team.image = await getTeamImageProfile(team.id);
            return team;
          })
      );

      return { ...teamsData, items: teamsWithImages };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  // Aggregate teams across all query pages
  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};

export const useGetTeamQuery = (teamId, options = {}) =>
  useQuery({
    ...options,
    queryKey: teamsKeys.detail(teamId),
    queryFn: () => getTeam(teamId),
    enabled: !!teamId,
  });

export const useGetTeamPlayersQuery = (teamId) =>
  useGetTeamQuery(teamId, {
    select: (team) => team.players?.items.map(transformPlayerLineupData),
  });

export const useGetTeamImageQuery = (teamId) =>
  useQuery({
    queryKey: teamsKeys.image(teamId),
    queryFn: () => getTeamImageProfile(teamId),
    enabled: !!teamId,
  });

export const useTeamUsersListQuery = () => {
  const { onSuccessCallback } = useFetchAll(true);
  const query = useInfiniteQuery({
    queryKey: teamUsersKeys.all,
    queryFn: async ({ pageParam }) => {
      const queryParams = {
        limit: LIST_PAGINATION_LIMIT.LARGE,
        nextToken: pageParam,
      };

      const teamUsers = await listTeamUsers(queryParams);

      return teamUsers;
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    onSuccess: data => onSuccessCallback(query)(data),
  });

  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};