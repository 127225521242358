import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { colors } from '../../styles';
import styled from 'styled-components';
import HorizontalGrid from '../Grids/HorizontalGrid';
import { PLAYERS_POSITIONS_ABBREVIATION } from '../../utils/playersUtil';
import { isMobile, addScrollListClass, onListScrollHandler } from '../../utils/scrollUtil';

/************** START STYLES **************/
const teamPlayersContainerStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'space-around', 
};

const teamInformationStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'center', 
};

const teamInformationDisplayStyle = { 
  width: '97%', 
  paddingTop: 15, 
  paddingBottom: 15, 
  borderBottom: `1px solid ${colors.GRAY[200]}`, 
};

const teamItemInformationStyle = { 
  width: '100%', 
  height: '100%', 
  paddingLeft: 15, 
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const teamStyle = { 
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
};

const teamNameLabelStyle = {
  ...teamStyle, 
  alignItems: 'flex-end', 
  font: 'normal normal normal 12px/16px Inter', 
  color: '#3C4152', 
};

const teamNameStyle = {
  ...teamStyle, 
  alignItems: 'flex-start', 
  font: 'normal normal 600 14px/30px Open Sans', 
  color: '#111111', 
};

const playersContentLabelStyle = { 
  width: '97%', 
  height: 'auto', 
  font: 'normal normal normal 12px/16px Inter', 
  color: '#3C4152', 
  marginTop: 15, 
  marginBottom: 15, 
};

const noPlayersContentTextStyle = { 
  width: '97%', 
  height: '100%', 
  font: 'normal normal normal 16px/24px Inter', 
  color: colors.GRAY[300],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const PlayerItemsContainerStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-start',  
};

const playerInfoItemRowContainer = { 
  width: '100%', 
  height: '50%', 
  margin: 0, 
};

const playerInfoItemDataRowContainer = { 
  width: '100%', 
  height: '50%', 
  margin: 0, 
  paddingLeft: 5, 
};

const positionItemStyle = {
  width: 'auto',
  display: 'inline-block',
  marginLeft: 1,
  marginRight: 1,
};

const activeStatusRowContainer = { 
  width: '100%', 
  height: '35%', 
  margin: 0, 
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
  width: 'auto', 
  height: 'auto', 
};

const headshotRowContainer = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const headShotDisplay = {
  width: 60,
  height: 60,
  borderRadius: 30,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.GRAY[150],
  overflow: 'hidden',
};

const jerseyNumberStyle = {
  font: 'normal normal 600 40px/45px Barlow Condensed', 
  color: '#191919', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'flex-start', 
  height: '100%', 
  width: '100%', 
  minWidth: 64, 
};

const playerNameStyle = {
  font: 'normal normal 600 20px/30px Barlow Condensed', 
  color: '#111111', 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  alignItems: 'center', 
};

const positionsStyle = {
  font: 'normal normal normal 10px/16px Barlow', 
  color: colors.GRAY[300], 
  width: '100%', 
  height: 'auto', 
};
/***************** END STYLES *****************/

const LabelValue = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  word-break: break-all;
`;

const PlayerListItemsWithFlexGrow = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 0px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content: baseline;
`;

const PlayerListItemsWithoutFlexGrow = styled.div`
  flex-grow: 0;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content: baseline;
`;

const displayPlayerPositions = (position, abbreviate = false) => {
  return (
    <>
      {position.map((pos, index) => {
        return index < position.length - 1 ? (
          <>
            <div style={positionItemStyle}>
              {abbreviate ? PLAYERS_POSITIONS_ABBREVIATION[pos] : pos}
            </div>
            <span style={{ color: '#BCBCBC' }}>
              &nbsp;|&nbsp;
            </span>
          </>
        ) : (
          <div style={positionItemStyle}>
            {abbreviate ? PLAYERS_POSITIONS_ABBREVIATION[pos] : pos}
          </div>
        );
      })}
    </>
  );
};

const PlayerItemActiveStatus = ({ 
  active, 
}) => {
 return (
  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start', }}>
    <div style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'flex-end', paddingBottom: 3, }}>
      <div
        style={{
          ...activeStatusIndicator,
          backgroundColor: active ? '#2DCC38' : '#EE3B3B',
        }}
      >
      </div>
    </div>
    <div style={activeStatusText}>{active ? 'Active' : 'Inactive'}</div>
  </div>
 );
};

const ItemImage = ({ 
  imgSrc, 
  extraStyles = {}, 
  imageWidth, 
  imageHeight 
 }) => {
  return (
    <img
      src={imgSrc}
      style={{ ...imageStyle, ...extraStyles }}
      width={imageWidth || "100%"}
      height={imageHeight || "100%"}
      alt=""
    />
  );
};

const NoTeamPlayersContent = () => {
  return (
    <div style={noPlayersContentTextStyle}>
      You don&apos;t have any players in this team.
    </div>
  );
};

const PlayerItemCard = ({ playerItem }) => {
  const {
    id, 
    firstName, 
    lastName, 
    image, 
    position, 
    jerseyNumber, 
    active, 
  } = playerItem;

  return (
    <HorizontalGrid
      width={'260px'}
      height={'80px'}
      borderColor={colors.GRAY[150]}
    >
      <Row style={{ width: '100%', height: '100%', }}>
        <Col xl={4} xs={4} md={4} className="p-0" style={headshotRowContainer}>
          <div style={headShotDisplay}>
            <ItemImage
              imgSrc={image}
              extraStyles={{ objectFit: 'cover', }}
              imageWidth={60}
              imageHeight={60}
            />
          </div>
        </Col>
        <Col xl={8} xs={8} md={8} className="p-0">
          <Row style={activeStatusRowContainer}>
            <Col xl={12} xs={12} md={12} className="p-0">
              <PlayerItemActiveStatus 
                active={active}
              />
            </Col>
          </Row>
          <Row style={playerInfoItemRowContainer}>
            <Col xl={12} xs={12} md={12} className="p-0" style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', }}>
              <div style={{ width: '100%', height: '100%', }}>
                <Row style={{ width: '100%', height: '100%', margin: 0, }}>
                  <Col xl={2} xs={2} md={2} className="p-0" style={{ borderRight: `1px solid ${colors.GRAY[200]}`, width: 40, height: '85%', }}>
                    <div style={jerseyNumberStyle}>{jerseyNumber}</div>
                  </Col>
                  <Col xl={10} xs={10} md={10} className="p-0" style={{ width: 'calc(100% - 65px)', height: '85%', }}>
                    <Row style={{ ...playerInfoItemDataRowContainer, height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                      <Col xl={12} xs={12} md={12} className="p-0" style={playerNameStyle}>
                        <LabelValue>{`${firstName} ${lastName}`}</LabelValue>
                      </Col>
                    </Row>
                    <Row style={{ ...playerInfoItemDataRowContainer, height: '35%', }}>
                      <Col xl={12} xs={12} md={12} className="p-0" style={positionsStyle}>
                        {displayPlayerPositions(position, true)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </HorizontalGrid>
  );
};

const PlayerItemsListContainer = ({ players }) => {
  const listRef = useRef();

  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  
    if(players?.length > 0) {
      if(!isMobile()) {
        addScrollListClass(listRef.current);
      }
    }
  }, [players]);

  const PlayersItemContainerComponent = players.length <= 9 ? PlayerListItemsWithoutFlexGrow : PlayerListItemsWithFlexGrow;
  return (
    <PlayersItemContainerComponent ref={listRef} onScroll={() => onListScrollHandler(_isMobile, listRef.current)}>
      {players.map(player => (
                    <PlayerItemCard 
                      key={player.id} 
                      playerItem={player}
                    />
                  )
                )
      }
    </PlayersItemContainerComponent>
  );
};

const PlayerItemsContent = ({ players }) => {
  return (
    <Row style={{ width: '97%', height: '100%' }}>
      <Col xl={12} xs={12} md={12} className="p-0" style={PlayerItemsContainerStyle}>
        <PlayerItemsListContainer players={players} />
      </Col>
    </Row>
  );
};

const TeamInfoItem = ({ 
  teamItemLabel, 
  teamItemValue, 
  teamItemStyles = {},
}) => {
  return (
    <div style={{ ...teamItemInformationStyle, ...teamItemStyles, }}>
      <div style={teamNameLabelStyle}>{teamItemLabel}</div>
      <div style={teamNameStyle}>
        <LabelValue>{teamItemValue}</LabelValue>
      </div>
    </div>
  );
};
 
const LeagueInfoItem = ({
  leagueItemLabel,
  leagueName,
  leagueAbbreviation,
  teamItemStyles = {},
}) => {
  return (
    <div style={{ ...teamItemInformationStyle, ...teamItemStyles, }}>
      <div style={teamNameLabelStyle}>{leagueItemLabel}</div>
      <div style={teamNameStyle}>
        <div style={{ width: 'auto', maxWidth: '70%', marginRight: 2, }}>
          <LabelValue>{leagueName}</LabelValue>
        </div>
        <div style={{ width: 'auto' }}>
          {leagueAbbreviation}
        </div>
      </div>
    </div>
  );
};

const TeamInfoContent = ({
  teamImage, 
  teamName, 
  teamLeague, 
}) => {
  return (
    <Row style={{ width: '100%', height: '20%', }}>
      <Col className="p-0" xs={12} xl={12} md={12} style={teamInformationStyle}>
        <div style={teamInformationDisplayStyle}>
          <Row className="p-0 m-0" style={{ width: '100%', height: '100%', }}>
            <Col xl={1} xs={1} md={1} className="p-0" style={{ minWidth: 85, }}>
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', }}>
                <ItemImage
                  imgSrc={teamImage}
                  imageWidth={65}
                  imageHeight={65}
                />
              </div>
            </Col>

            <Col xl={4} xs={4} md={4} className="p-0" >
              <TeamInfoItem
                teamItemLabel={'TEAM'}
                teamItemValue={teamName}
                teamItemStyles={{ paddingRight: 5, }}
              />
            </Col>

            <Col xl={6} xs={6} md={6} className="p-0" style={{ minWidth: 443, borderLeft: `1px solid ${colors.GRAY[200]}`, }}>
              <LeagueInfoItem
                leagueItemLabel={'LEAGUE'}
                leagueName={teamLeague?.name}
                leagueAbbreviation={teamLeague ? `(${teamLeague.abbreviation})` : 'Pending Update'}
                teamItemStyles={{ paddingLeft: 30, }}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const PlayersInfoContent = ({ players }) => {
  return (
    <Row style={{ width: '100%', height: '80%', }}>
      <Col xl={12} xs={12} md={12} className="p-0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
        {players.length === 0
          ?
        <NoTeamPlayersContent 
        />
          :
        <PlayerItemsContent 
          players={players.sort((a, b) => parseInt(a.jerseyNumber) - parseInt(b.jerseyNumber))}
        />
        }
      </Col>
    </Row>
  );
};

const TeamPlayersContent = ({ team }) => {
  const {
    id,
    name,
    image,
    league,
    players,
    city,
    state,
  } = team;

  return (
    <div style={teamPlayersContainerStyle}>
      <TeamInfoContent
        teamImage={image}
        teamName={name}
        teamLeague={league}
      />
      <PlayersInfoContent
        players={players.items}
      />
    </div>
  );
};

export default TeamPlayersContent;