import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const StyledTab = styled.div`
  flex: 1 1 auto;
  min-width: 12vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;
  font: normal normal bold 14px/24px arial;
  color: #ffffff;
  padding: ${isMobile ? "0.5vw 1vw" : "1vw 1vw"};
  border-bottom-style: ${(props) => props.selected && "none"};
  border-top: ${(props) => props.selected && "4px solid #26A9E1"};
  // border-top-left-radius: ${(props) => props.selected && "20px"};
  // border-top-right-radius: ${(props) => props.selected && "20px"};
`;

const StyledCenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.img`
  height: 2vw;
  width: auto;
  margin-right: 1vw;
`;

const TabLabel = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  text-align: left;
  word-break: break-all;
`;

const Tab = ({ selected, teamName, teamLogoSrc, onClick }) => {
  return (
    <StyledTab onClick={onClick} selected={selected}>
      <StyledCenterDiv>
        <StyledLogo src={teamLogoSrc} />
        <TabLabel>{teamName}</TabLabel>
      </StyledCenterDiv>
    </StyledTab>
  );
};

export default Tab;
