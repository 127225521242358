const URL = 'https://dev-clocktimer.faststats.live/api/clockTimer';
const header = new Headers({
  'content-type': 'application/json',
  accept: '*/*',
});

export const createGameTimer = async (
  gameId,
  totalQuartersInGame,
  eachQuarterTimeInMin,
  overtimeInMin,
  returnRawResponse = false
) => {
  const body = JSON.stringify({
    gameId,
    totalQuartersInGame,
    eachQuarterTimeInMin,
    overtimeInMin,
  });

  try {
    const rawResponse = await fetch(`${URL}/createGameTimer`, {
      method: 'POST',
      headers: header,
      body: body,
    });
    return returnRawResponse ? rawResponse : await rawResponse.json();
  } catch (error) {
    return error;
  }
};

export const clockTimerStatus = async (gameId, returnRawResponse = false) => {
  const body = JSON.stringify({
    gameId: gameId,
  });
  try {
    const rawResponse = await fetch(`${URL}/timerStatus`, {
      method: 'POST',
      headers: header,
      body: body,
    });
    return returnRawResponse ? rawResponse : await rawResponse.json();
  } catch (error) {
    return error;
  }
};

export const startClock = async (gameId, isOvertime = false) => {
  const body = JSON.stringify({
    gameId: gameId,
    isOverTime: isOvertime,
  });

  const rawResponse = await fetch(`${URL}/start`, {
    method: 'POST',
    headers: header,
    body: body,
  });
  return await rawResponse.json();
};

export const pauseClock = async (gameId, isOvertime) => {
  const body = JSON.stringify({
    gameId: gameId,
    isOverTime: isOvertime,
  });

  const rawResponse = await fetch(`${URL}/pause`, {
    method: 'POST',
    headers: header,
    body: body,
  });
  return await rawResponse.json();
};

export const resumeClock = async (gameId, isOvertime) => {
  const body = JSON.stringify({
    gameId: gameId,
    isOverTime: isOvertime,
  });

  const rawResponse = await fetch(`${URL}/resume`, {
    method: 'POST',
    headers: header,
    body: body,
  });
  return await rawResponse.json();
};

export const deleteGameTimer = async (gameId) => {
  try {
    const rawResponse = await fetch(`${URL}/game/${gameId}`, {
      method: 'DELETE',
      headers: header,
    });
    return rawResponse;
  } catch (error) {
    return error;
  }
};
