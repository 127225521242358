import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeadingWithLine = styled(({ label, labelStyle = {}, ...props }) => {
  return (
    <div {...props}>
      <h3 style={labelStyle}>{label}</h3>
    </div>
  );
})`
  display: flex;
  align-items: center;
  text-align: center;
  height: auto;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ color }) => color ?? '#000'};
  }

  h3 {
    margin: 0 6px;
    color: ${({ color }) => color ?? '#000'};
    text-align: center;
    font: normal normal bold 13px/18px Open Sans;
    letter-spacing: 0px;
    text-transform: uppercase;
    display: inline-block;
    height: auto;
  }
`;

HeadingWithLine.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  labelStyle: PropTypes.object,
};

export default HeadingWithLine;
