import React from 'react';
import styled from 'styled-components';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';
import { colors } from '../../styles/colors';

const Grid = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  background-color: ${colors.GRAY[400]};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header header'
    'nav main'
    'footer footer';
`;

const GridNav = styled.nav`
  grid-area: nav;
`;

const GridHeader = styled.header`
  grid-area: header;
  z-index: 10;
`;

// commenting out scroll for main content
const GridMain = styled.main`
  margin-left: 20px;
  width: calc(100% - 40px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    // hide the scrollbar but keep the functionality
    display: none;
  }
  background: #edeef2;
  border-radius: 20px;
`;

const GridFooter = styled.footer`
  grid-area: footer;
  z-index: 10;
`;

const LoginLayout = ({ children }) => {
  return (
    <Grid>
      <GridHeader>
        <Header />
      </GridHeader>
      <GridMain>{children}</GridMain>
      <GridFooter>
        <Footer />
      </GridFooter>
    </Grid>
  );
};

export default LoginLayout;
