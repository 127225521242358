import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import * as yup from 'yup';
import { updateEvent } from '../../../../api/eventsService';
import { gameLineupKeys } from '../../../../api/gamesQueries';
import { updateGameLineupPlayerViaDatastore } from '../../../../api/gamesService';
import { ItemTypes } from '../../../../components/Modals/ItemTypes';
import logger from '../../../../logger';
import ClockInput from './ClockInput';
import { TeamLineupSelect, TeamSelect } from './EventSelectControls';
const log = logger('EditSubstitutionForm', 'info');

export const EditSubstitutionForm = {
  FieldsetComponent: EditSubstitutionFieldset,
  getValidationSchema: ({ periodDuration, homeTeamId, awayTeamId }) =>
    yup.object({
      minutes: yup.number().required().min(0).max(periodDuration),
      seconds: yup
        .number()
        .required()
        .min(0)
        .when('minutes', {
          is: periodDuration,
          then: (schema) => schema.max(0),
          otherwise: (schema) => schema.max(59),
        }),
      teamId: yup.string().required().oneOf([homeTeamId, awayTeamId]),
      courtGameLineupPlayerId: yup.string().required(),
      benchGameLineupPlayerId: yup.string().required(),
    }),
  getInitialValues: ({ event: event1, subPlay: { event: event2 } }) => ({
    minutes: event1.gamePeriodMinutes ?? '',
    seconds: event1.gamePeriodSeconds ?? '',
    teamId: event1.teamId,
    courtGameLineupPlayerId: event1.gameLineupPlayerId,
    benchGameLineupPlayerId: event2.gameLineupPlayerId,
  }),
  handleSubmit: async ({ play, values, gameData, lineupData, queryClient }) => {
    const {
      event: event1,
      subPlay: { event: event2 },
    } = play;
    const { teamId, courtGameLineupPlayerId, benchGameLineupPlayerId } = values;
    log.debug('handleSubmit params', {
      play,
      values,
      gameData,
      lineupData,
      event1,
      event2,
    });

    let courtGameLineupPlayer, benchGameLineupPlayer;
    if (teamId === gameData.homeTeamId) {
      courtGameLineupPlayer = lineupData.homeTeamLineupMap.get(
        courtGameLineupPlayerId
      );
      benchGameLineupPlayer = lineupData.homeTeamLineupMap.get(
        benchGameLineupPlayerId
      );
    } else {
      courtGameLineupPlayer = lineupData.awayTeamLineupMap.get(
        courtGameLineupPlayerId
      );
      benchGameLineupPlayer = lineupData.awayTeamLineupMap.get(
        benchGameLineupPlayerId
      );
    }

    let gameLineupPlayerUpdates = [];
    /* Handle updateGameLineupPlayer calls for possible court player change */
    if (event1.gameLineupPlayerId !== courtGameLineupPlayerId) {
      gameLineupPlayerUpdates = [
        ...gameLineupPlayerUpdates,
        /* Previous court player should be returned to bench */
        event1.gameLineupPlayer &&
          updateGameLineupPlayerViaDatastore(
            event1.gameLineupPlayer.id,
            ItemTypes.BENCH
          ),
        /* New court player */
        updateGameLineupPlayerViaDatastore(
          courtGameLineupPlayer.id,
          ItemTypes.COURT
        ),
      ];
    }

    /* Handle updateGameLineupPlayer calls for possible bench player change */
    if (event2.gameLineupPlayerId !== benchGameLineupPlayerId) {
      gameLineupPlayerUpdates = [
        ...gameLineupPlayerUpdates,
        /* Previous benched player should be returned to court */
        event2.gameLineupPlayer &&
          updateGameLineupPlayerViaDatastore(
            event2.gameLineupPlayer.id,
            ItemTypes.COURT
          ),
        /* New bench player */
        updateGameLineupPlayerViaDatastore(
          benchGameLineupPlayer.id,
          ItemTypes.BENCH
        ),
      ];
    }

    /* Make all Datastore updates. */
    await Promise.all([
      ...gameLineupPlayerUpdates,
      updateEvent({
        ...event1,
        ...values,
        playerId: courtGameLineupPlayer.playerId,
        gameLineupPlayerId: courtGameLineupPlayerId,
        gameLineupPlayer: courtGameLineupPlayer,
        playType: ItemTypes.COURT,
      }),
      updateEvent({
        ...event2,
        ...values,
        playerId: benchGameLineupPlayer.playerId,
        gameLineupPlayer: benchGameLineupPlayer,
        gameLineupPlayerId: benchGameLineupPlayerId,
        playType: ItemTypes.BENCH,
      }),
    ]);

    /**
     * This doesn't work perfectly. I think it's because of the delay between saving to
     * DataStore and syncing with the backend.
     * Should be fixed when we switch queries to only DataStore for Clock Manager/Score Keeper.
     */
    queryClient?.invalidateQueries(gameLineupKeys.all);
  },
};

export function EditSubstitutionFieldset({
  periodDuration,
  homeTeamId,
  homeTeamName,
  awayTeamId,
  awayTeamName,
  homeTeamOnCourtPlayers,
  homeTeamOnBenchPlayers,
  awayTeamOnCourtPlayers,
  awayTeamOnBenchPlayers,
  eventTeamId,
  /** Court player from the original substitution event */
  eventCourtPlayer,
  /** Bench player from the original substitution event */
  eventBenchPlayer,
}) {
  const { values } = useFormikContext();
  const { minutes, seconds, teamId } = values;
  /** New court player picked from current bench players */
  const [courtPlayerOptions, setCourtPlayerOptions] = useState([]);
  /** New bench players picked from current court players */
  const [benchPlayerOptions, setBenchPlayerOptions] = useState([]);

  useEffect(() => {
    let _courtPlayerOptions, _benchPlayerOptions;
    /**
     * Players listed under the Court dropdown come from the Bench.
     * They are being subbed into the game.
     *
     * Players listed under the Bench dropdown come from the Court.
     * They are being subbed out.
     */
    if (teamId === homeTeamId) {
      _courtPlayerOptions = homeTeamOnBenchPlayers;
      _benchPlayerOptions = homeTeamOnCourtPlayers;
    } else if (teamId === awayTeamId) {
      _courtPlayerOptions = awayTeamOnBenchPlayers;
      _benchPlayerOptions = awayTeamOnCourtPlayers;
    }

    /**
     * If we're handling subs for the originally selected team, the originally
     * selected players need to be handled differently since they weren't
     * on the court/bench at the time of substitution.
     */
    if (eventTeamId === teamId) {
      /**
       * Remove the originally substituted players from options that weren't available
       * at the time.
       */
      _courtPlayerOptions = _courtPlayerOptions.filter(
        (player) => player.id !== eventBenchPlayer.id
      );
      _benchPlayerOptions = _benchPlayerOptions.filter(
        (player) => player.id !== eventCourtPlayer.id
      );

      /**
       * Make sure original selections are in the appropriate dropdowns.
       */
      if (
        eventCourtPlayer &&
        !_courtPlayerOptions.some((p) => p.id === eventCourtPlayer.id)
      ) {
        _courtPlayerOptions = [eventCourtPlayer, ..._courtPlayerOptions];
      }
      if (
        eventBenchPlayer &&
        !_benchPlayerOptions.some((p) => p.id === eventBenchPlayer.id)
      ) {
        _benchPlayerOptions = [eventBenchPlayer, ..._benchPlayerOptions];
      }
    }

    setCourtPlayerOptions(_courtPlayerOptions);
    setBenchPlayerOptions(_benchPlayerOptions);
  }, [
    awayTeamId,
    awayTeamOnBenchPlayers,
    awayTeamOnCourtPlayers,
    eventBenchPlayer,
    eventCourtPlayer,
    eventTeamId,
    homeTeamId,
    homeTeamOnBenchPlayers,
    homeTeamOnCourtPlayers,
    teamId,
  ]);

  return (
    <>
      <Row className="mb-3">
        <Col sm={4} className="pe-0">
          <ClockInput
            minutes={minutes}
            seconds={seconds}
            periodDuration={periodDuration}
          />
        </Col>

        <Col sm={8}>
          <TeamSelect
            homeTeamId={homeTeamId}
            homeTeamName={homeTeamName}
            awayTeamId={awayTeamId}
            awayTeamName={awayTeamName}
          />
        </Col>
      </Row>

      <Stack direction="horizontal" gap={3} className="mb-3">
        <TeamLineupSelect
          name="courtGameLineupPlayerId"
          /* New court player picked from current bench players */
          players={courtPlayerOptions}
          lineupType={ItemTypes.COURT}
        />
        <TeamLineupSelect
          name="benchGameLineupPlayerId"
          /* New bench players picked from current court players */
          players={benchPlayerOptions}
          lineupType={ItemTypes.BENCH}
        />
      </Stack>
    </>
  );
}
