import React from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

const InputCheckboxLabel = ({ label, hideFeedback, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const controlSize = 'md';

  return (
    <Form.Group className={meta.touched && meta.error && !hideFeedback ? 'pb-0' : 'pb-2'}>
      <Form.Check
        size={controlSize}
        {...field}
        {...props}
        label={label}
        isInvalid={meta.touched && meta.error}
        feedback={!hideFeedback && meta.error}
        feedbackType="invalid"
      />
    </Form.Group>
  );
};

InputCheckboxLabel.defaultProps = {
  hideFeedback: true
};

export default InputCheckboxLabel;
