/**
 * Returns a loglevel instance, with default trace level set based off of the environment (prod/dev).
 * https://www.npmjs.com/package/loglevel
 *
 * Used to capture console logs and display based off of the environment,
 * or module specific log level.
 *
 * The loggerName passed is used to create a unique logging object
 * which can each have their own filtering level set by log.setLevel("DEBUG")
 *
 * Log Levels:
 *  TRACE - Probably don't use this unless you need too; it contains the stacktrace. The React app is compiled, making the trace less useful.
 *  DEBUG - A log level used for events considered to be useful during software debugging when more granular information is needed.
 *  INFO  - An event (button press, successful api response, etc.) happened, the event is purely informative and can be ignored during normal operations.
 *  WARN  - Unexpected behavior happened inside the application, but it is continuing its work and the key business features are operating as expected.
 *  ERROR - One or more functionalities are not working, preventing some functionalities from working correctly.
 *
 * Usage:
 *  Get the root logger
 *    const log = require('./logger')()
 *
 *  Get a logger with name 'foobar'
 *    const log = require('./logger')('foobar')
 *
 *  Get a logger with name 'foobar' and set the log level to 'debug'
 *    const log = require('./logger')('foobar', 'debug')
 *
 *  Logging to the different levels
 *    log.trace(...)
 *    log.debug(...)
 *    log.info(...)
 *    log.warn(...)
 *    log.error(...)
 *
 * @param {*} id An identifier for each logger. A unique name will return a new logger, a used name will return the existing logger. Each instance can have its own log level.
 * @param {*} logLevel 'trace', 'debug', 'info', 'warn', 'error',
 * @returns a log object (log.debug(...), log.info(...))
 */
module.exports = function (id, logLevel) {
  /** If a logger name is not passed, use the root logger */
  const log = id ? require('loglevel').getLogger(id) : require('loglevel');

  /** Change the trace level based on the environment */
  if (process.env.NODE_ENV === 'development') {
    /** Development Environment: Show INFO by default, but let each logger set their own level*/
    if (logLevel) {
      /** Log level set: 'trace', 'debug', 'info', 'warn', 'error' */
      log.setLevel(logLevel);
    } else {
      /** Default development log level: INFO */
      log.setLevel(log.levels.INFO);
    }
  } else {
    /** Production Environment: Only show ERROR and WARN*/
    log.setLevel(log.levels.WARN);
  }

  return log;
};
