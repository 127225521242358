import React from 'react';
import styled from 'styled-components';
import { Container } from './TeamContainer';
import GameLeaderItem from './GameLeaderItem';

const ContainerItem = styled.div`
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D5D5D5;
  border-left: 1px solid #D5D5D5;
  border-right: 1px solid #D5D5D5;
`;

const AwayGameLeaderStat = ({
  awayTeamImage, 
  awayPlayerStats, 
}) => {
  return (
    <GameLeaderItem
      teamImage={awayTeamImage}
      teamPlayer={awayPlayerStats?.playerInformation}
      playerRecord={awayPlayerStats?.playerRecord}
      playerStatRecord={awayPlayerStats?.playerStatRecord}
      playerStatInfo={awayPlayerStats?.playerStatInfo}
    />
  );
};

const HomeGameLeaderStat = ({
  homeTeamImage, 
  homePlayerStats, 
}) => {
  return (
    <GameLeaderItem
      teamImage={homeTeamImage}
      teamPlayer={homePlayerStats?.playerInformation}
      playerRecord={homePlayerStats?.playerRecord}
      playerStatRecord={homePlayerStats?.playerStatRecord}
      playerStatInfo={homePlayerStats?.playerStatInfo}
    />
  );
};

const GameLeadersContent = ({
  awayTeamImage, 
  homeTeamImage, 
  awayPlayerStats, 
  homePlayerStats, 
}) => {
  return (
    <Container
      height={'110px'}
    >
      <ContainerItem>
        <HomeGameLeaderStat
          homeTeamImage={homeTeamImage}
          homePlayerStats={homePlayerStats}
        />
        <AwayGameLeaderStat
          awayTeamImage={awayTeamImage}
          awayPlayerStats={awayPlayerStats}
        />
      </ContainerItem>
    </Container>
  );
};

export default GameLeadersContent;