import moment from 'moment';
import { getGame, getGameUser } from '../api/gamesService';
import { getTeamImageProfile } from '../api/teamsService';
import { seasonFormatDisplay } from './seasonsUtil';

export const GamesTabs = {
  /** "Live Games" has been changed to "Today's Games" */
  TODAY: 'today',
  UPCOMING: 'upcoming',
  PAST: 'past',
  /** This is the initial state  */
  NONE: 'none',
};

export const GAMES_SORTING_FN = {
  byGameDateTimeAsc: (a, b) =>
    moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf(),
  byGameDateTimeDesc: (a, b) =>
    moment(b.gameDateTime).valueOf() - moment(a.gameDateTime).valueOf(),
};

const GamesTabsMsgs = {
  [GamesTabs.TODAY]: {
    noGames: 'There are no games today.',
    noGamesFiltered: 'There are no games today matching the search terms.',
  },
  [GamesTabs.UPCOMING]: {
    noGames: 'There are no upcoming games.',
    noGamesFiltered: 'There are no upcoming games matching the search terms.',
  },
  [GamesTabs.PAST]: {
    noGames: 'There are no past games.',
    noGamesFiltered: 'There are no past games matching the search terms.',
  },
  [GamesTabs.NONE]: {
    noGames: '',
    noGamesFiltered: '',
  },
  default: {
    noGames: 'There are no games to display.',
    noGamesFiltered: 'There are no games matching the search terms.',
  },
};

export function getNoGamesMsgByGameTab(tab, filtered = false) {
  if (Object.values(GamesTabs).includes(tab))
    return GamesTabsMsgs[tab][filtered ? 'noGamesFiltered' : 'noGames'];
  else return GamesTabsMsgs.default[filtered ? 'noGamesFiltered' : 'noGames'];
}

export const getTabByGameDateTime = (datetime) => {
  const gameDateTime = moment(datetime);
  const startOfToday = moment().startOf('day');
  const endOfToday = moment().endOf('day');

  if (gameDateTime.isBefore(startOfToday)) {
    return GamesTabs.PAST;
  } else if (gameDateTime.isBetween(startOfToday, endOfToday, undefined, '[]')) {
    return GamesTabs.TODAY;
  } else if (gameDateTime.isAfter(endOfToday)) {
    return GamesTabs.UPCOMING;
  }
};

export const formatDate = (datetime) =>
  moment(datetime).format('MMMM Do, YYYY');
export const formatTime = (datetime) => moment(datetime).format('h:mm A');

export const searchFilter = (option, search) =>
  option.homeTeamVsAwayTeam.toLowerCase().includes(search.toLowerCase()) ||
  option.role?.toLowerCase().includes(search.toLowerCase()) ||
  formatDate(option.gameDateTime)
    .toLowerCase()
    .includes(search.toLowerCase()) ||
  formatTime(option.gameDateTime)
    .toLowerCase()
    .includes(search.toLowerCase()) ||
  seasonFormatDisplay({
    seasonType: option.seasonType,
    startDate: option.seasonStartDate,
    endDate: option.seasonEndDate,
  })
    .toLowerCase()
    .includes(search.toLowerCase()) ||
  option.leagueName.toLowerCase().includes(search.toLowerCase()) ||
  option.leagueAbbreviation.toLowerCase().includes(search.toLowerCase()) ||
  option.venueName.toLowerCase().includes(search.toLowerCase()) ||
  option.venueCity.toLowerCase().includes(search.toLowerCase()) ||
  option.venueState.toLowerCase().includes(search.toLowerCase());

/**
 * 
 * @param {Object} game
 * @returns {String} searchable lowercase string to populate the searchText field
 */
export const searchFilterText = (game) =>
  [
    game.homeTeamVsAwayTeam.toLowerCase(),
    game.role?.toLowerCase(),
    formatDate(game.gameDateTime).toLowerCase(),
    formatTime(game.gameDateTime).toLowerCase(),
    seasonFormatDisplay({
      seasonType: game.seasonType,
      startDate: game.seasonStartDate,
      endDate: game.seasonEndDate,
    }).toLowerCase(),
    game.leagueName.toLowerCase(),
    game.leagueAbbreviation.toLowerCase(),
    game.venueName.toLowerCase(),
    game.venueCity.toLowerCase(),
    game.venueState.toLowerCase(),
  ].join(' ');

export const getGames = async (gameIds) => {
  const asyncGameItems = gameIds.map(async (gameId) => await getGame(gameId));
  return await Promise.all(asyncGameItems);
};

export const getGameUsers = async (gameUserIds) => {
  const asyncGameItems = gameUserIds.map(async (id) => await getGameUser(id));
  return await Promise.all(asyncGameItems);
};

export const getTeamImages = async (homeTeamId, awayTeamId) => {
  const teamImages = [homeTeamId, awayTeamId];
  const [homeTeamImage, awayTeamImage] = await Promise.all(
    teamImages.map(async (teamId) => await getTeamImageProfile(teamId))
  );
  return { homeTeamImage, awayTeamImage };
};

export const getGameHomeAndAwayTeamsImages = async (game) => {
  const { homeTeamId, awayTeamId } = game;
  const { homeTeamImage, awayTeamImage } = await getTeamImages(
    homeTeamId,
    awayTeamId
  );
  game.homeTeamImage = homeTeamImage;
  game.awayTeamImage = awayTeamImage;
  return game;
};

export const getGamesByUserId = async (gameUserIds, userId) => {
  let asyncGameItemsResponse = await getGameUsers(gameUserIds);
  asyncGameItemsResponse = asyncGameItemsResponse.filter(
    ({ _deleted }) => _deleted === null
  );
  const gameItemsForUserAsync = asyncGameItemsResponse.map(async (gameItem) => {
    let { gameRole, game } = gameItem;
    game = await getGameHomeAndAwayTeamsImages(game);
    game.role = gameRole;
    game.gameUserId = userId;
    return game;
  });
  return await Promise.all(gameItemsForUserAsync);
};

export const getUniqueGames = async (gameIds) => {
  const uniqueGameIds = Array.from(new Set(gameIds)).map((gameId) => gameId);
  let uniqueGames = await getGames(uniqueGameIds);
  uniqueGames = await Promise.all(
    uniqueGames.map(async (game) => await getGameHomeAndAwayTeamsImages(game))
  );
  return uniqueGames;
};

export const getGameUserParameters = (
  gameUserIds,
  gameUserParams,
  gameItemsRemoved
) => {
  gameUserParams = gameUserParams.map((param) => {
    const gameUserId = gameUserIds.find(
      ({ gameId }) => param.gameId === gameId
    );
    if (gameUserId) {
      const { id, _version } = gameUserId;
      return { ...param, id, _version };
    }
    return null;
  });
  gameItemsRemoved = gameItemsRemoved?.map((gameId) => {
    const { id, _version } = gameUserIds.find(
      (gameUser) => gameUser.gameId === gameId
    );
    return { id, gameId, _version };
  });
  return {
    updateGameUserParams: gameUserParams?.length > 0 ? gameUserParams : null,
    deleteGameUserParams: gameItemsRemoved,
  };
};

export const sortByUpdatedAtDesc = (a, b) =>
  moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf();

export const getGamesForGameTabs = (allGames) => {
  const startOfToday = moment().startOf('day');
  const endOfToday = moment().endOf('day');

  const todaysGames = allGames
    .filter(({ gameDateTime }) =>
      moment(gameDateTime).isBetween(startOfToday, endOfToday, undefined, '[]')
    )
    .sort(GAMES_SORTING_FN.byGameDateTimeAsc);

  const upcomingGames = allGames
    .filter(({ gameDateTime }) => moment(gameDateTime).isAfter(endOfToday))
    .sort(GAMES_SORTING_FN.byGameDateTimeAsc);

  const pastGames = allGames
    .filter(({ gameDateTime }) => moment(gameDateTime).isBefore(startOfToday))
    .sort(GAMES_SORTING_FN.byGameDateTimeDesc);

  return { todaysGames, upcomingGames, pastGames };
};

/**
 * Converts the new style Game object to the older style. New style does not contain nesting of fields (venue, homeTeam, league, etc.) and instead pulls everything out to the top level.
 * @param {*} game Game object from graphql query or DataStore update
 * @returns Game object including nested fields of older style Game object
 */
export function gameAdapter(game) {
  /** Sometimes the Game returned from data store is an
   * immutable 'model' object and needs to be converted to and from JSON*/
  game = JSON.parse(JSON.stringify(game));
  game.league = {
    id: game.leagueId,
    name: game.leagueName,
    abbreviation: game.leagueAbbreviation,
    teamSize: game.leagueTeamSize,
    numPlayersOnCourt: game.leagueNumPlayersOnCourt,
    numPlayersOnBench: game.leagueNumPlayersOnBench,
    numTimeOuts: game.leagueNumTimeOuts,
    numFoulsPerPlayer: game.leagueNumFoulsPerPlayer,
    numPeriods: game.leagueNumPeriods,
    timePerPeriodInMins: game.leagueTimePerPeriodInMins,
    overtimeDurationInMins: game.leagueOvertimeDurationInMins,
  };
  game.season = {
    id: game.seasonId,
    startDate: game.seasonStartDate,
    endDate: game.seasonEndDate,
    type: game.seasonType,
  };
  game.homeTeam = {
    id: game.homeTeamId,
    name: game.homeTeamName,
    city: game.homeTeamCity,
    state: game.homeTeamState,
    active: game.homeTeamActive,
  };
  game.awayTeam = {
    id: game.awayTeamId,
    name: game.awayTeamName,
    city: game.awayTeamCity,
    state: game.awayTeamState,
    active: game.awayTeamActive,
  };
  game.venue = {
    id: game.venueId,
    name: game.venueName,
    city: game.venueCity,
    state: game.venueState,
    zipcode: game.venueZipcode,
    active: game.venueActive,
  };
  game.homeTeamGameLineupId = game.homeTeamGameLineup?.id;
  game.awayTeamGameLineupId = game.awayTeamGameLineup?.id;
  return game;
}
