import React, { useState, useEffect, useRef, useMemo } from 'react';
import ToastAlert from '../components/alerts/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { updateScrollPosition } from '../redux/statsGameListScrollPositionSlice';
import MainLayout from '../components/layout/MainLayout';
import GameContent from '../components/PageContent/GameContent';
import Spinner from '../components/Spinner';
import { useGameStatsQuery, useCoachGamesByUserId } from '../api/gamesQueries';
const log = require('../logger')('gameStats', 'debug');

const StatsPage = () => {
  const dispatch = useDispatch();
  const [isGameContentLoaded, setIsGameContentLoaded] = useState(false);
  const gameContentRef = useRef();
  const gameContentScrollPosition = useSelector(
    (state) => state.statsGameListScrollPosition.scrollPositionValue
  );
  const userId = useSelector((state) => state.user.id);
  const group = useSelector((state) => state.group.value);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState();
  const [toastVariant, setToastVariant] = useState('success');

  const useQueryErrorHandler = (error) => {
    log.error(error);
    setShowToast(true);
    setToastText('An error occurred while retrieving games with statuses.');
    setToastVariant('danger');
  };

  let {
    data,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isLoading,
    isFetching,
    fetchAll,
    cancelFetchAll,
  } = useGameStatsQuery(group, {
    onError: useQueryErrorHandler,
  });

  const coachQuery = useCoachGamesByUserId(userId, group, {
    onError: useQueryErrorHandler,    
  });

  useEffect(() => {
    if(coachQuery.data.length > 0) {
console.log();
    }
  }, [coachQuery.data]);

  useEffect(() => {
    if (isGameContentLoaded && isSuccess) {
      gameContentRef.current?.scrollTo({
        top: gameContentScrollPosition,
        behavior: 'smooth',
      });
    }
  }, [gameContentScrollPosition, isGameContentLoaded, isSuccess]);

  const onScrollGameContentHandler = useMemo(
    () =>
      debounce((event) => {
        const { scrollTop } = event.target;
        dispatch(updateScrollPosition({ scrollPosition: scrollTop }));
      }, 200),
    [dispatch]
  );

  return (
    <MainLayout title="Game Statistics">
      {isLoading && <Spinner />}

      {!isLoading && data?.length > 0 && (
        <GameContent
          searchId={'__gameId'}
          currentTabGames={data}
          searchableGames={data}
          fetchAllSearchableGames={fetchAll}
          cancelFetchAll={cancelFetchAll}
          isFetchingSearchableGames={isFetching}
          placeholder={'Search Games'}
          navigateToGameStatsPage={true}
          pageRef={gameContentRef}
          onScrollListHandler={onScrollGameContentHandler}
          setIsPageLoaded={setIsGameContentLoaded}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      )}

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default StatsPage;
