import styled from 'styled-components';

export const PeriodColumnHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const PeriodColumnHeaderContent = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const PlayByPlayHeaderContainer = styled.div`
  width: 100%; 
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  font: normal normal 16px/21px 'Segoe UI Bold';
  color: #0A0A0A;
`;
