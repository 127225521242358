import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		value: '',
		id:'',
	},
	reducers: {
		updateUser: (state, action) => {
			state.value = action.payload;
		},
		updateUserId: (state, action) => {
			state.id = action.payload;
		},
	}
})

export const { updateUser, updateUserId } = userSlice.actions
export default userSlice.reducer;


