import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { colors, gradients } from '../../../styles/colorsStatsCollector';

const EndPlayScreen = ({ endPlayClickHandler, additionalOptions }) => {
  // const dispatch = useDispatch();

  return (
    <Container>
      <StyledButton
        variant="outlined"
        style={{
          font: 'normal normal bold 15px/20px Open Sans',
          letterSpacing: '0px',
          textTransform: 'uppercase',
        }}
        onClick={endPlayClickHandler}
      >
        End Play
      </StyledButton>
      {additionalOptions && additionalOptions.length > 0 && (
        <>
          <OrSeparator />
          <ButtonContainer>
            {additionalOptions.map(
              ({ onClick, primaryLabel, secondaryLabel }) => (
                <RoundButton
                  onClick={onClick}
                  key={`${primaryLabel}${secondaryLabel}`}
                >
                  <div
                    style={{
                      font: 'normal normal bold 15px/20px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {primaryLabel}
                  </div>
                  <div
                    style={{
                      font: 'normal normal bold 10px/14px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {secondaryLabel}
                  </div>
                </RoundButton>
              )
            )}
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

EndPlayScreen.propTypes = {
  endPlayClickHandler: PropTypes.func.isRequired,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      primaryLabel: PropTypes.string,
      secondaryLabel: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, auto));
  justify-items: center;
  row-gap: 1em;

  > button {
    margin: 0 0.5em;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    border-radius: 24px;
    text-transform: none;
    padding: 12px 50px;
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[100] : gradients.BLACK};
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.GRAY[900]};
    border: 1px solid ${colors.BLUE[100]};

    font-family: Open Sans, sans-serif;
    font-weight: 500;
    font-size: 19px;

    &:hover,
    &:focus,
    &:active {
      background: ${colors.BLUE[100]};
      color: ${colors.WHITE[100]};
    }
  }
`;

const OrSeparator = styled((props) => (
  <div {...props}>
    <span>OR</span>
  </div>
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  span {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2400] : colors.GRAY[950]};
    margin: 4px 0;
  }

  &:before,
  &:after {
    content: '';
    height: 30px;
    border-left: 1px solid ${colors.GRAY[900]};
  }
`;

const RoundButton = styled(Button)`
  &&& {
    width: 86px;
    height: 86px;
    border-radius: 43px;
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[100] : gradients.BLACK};
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.GRAY[900]};
    box-shadow: 4px 7px 16px ${colors.GRAY_TRANSPARENT[400]};
    border: 1px solid ${colors.BLUE[100]};

    .MuiButton-label {
      flex-direction: column;
    }

    &:hover,
    &:focus,
    &:active {
      background: ${colors.BLUE[100]};
      color: ${colors.WHITE[100]};
    }
  }
`;

export default EndPlayScreen;
