import { createSlice } from '@reduxjs/toolkit';

export const homeGameListScrollPositionSlice = createSlice({
  name: 'homeGameListScrollPosition',
  initialState: {
    scrollPositions: {
      todaysGamesScrollValue: 0,
      upcomingGamesScrollValue: 0,
      pastGamesScrollvalue: 0,
    },
  },
  reducers: {
    updateTodaysGameScrollPosition: (state, action) => {
      state.scrollPositions.todaysGamesScrollValue = action.payload;
    },
    updateUpcomingGameScrollPosition: (state, action) => {
      state.scrollPositions.upcomingGamesScrollValue = action.payload;
    },
    updatePastGameScrollPosition: (state, action) => {
      state.scrollPositions.pastGamesScrollvalue = action.payload;
    },
    resetAllScrollPositions: (state) => {
      state.scrollPositions.todaysGamesScrollValue = 0;
      state.scrollPositions.upcomingGamesScrollValue = 0;
      state.scrollPositions.pastGamesScrollvalue = 0;
    },
  },
});

export const {
  updateTodaysGameScrollPosition,
  updateUpcomingGameScrollPosition,
  updatePastGameScrollPosition,
  resetAllScrollPositions,
} = homeGameListScrollPositionSlice.actions;

export const selectHomeGameListScrollPositions = (state) =>
  state.homeGameListScrollPosition.scrollPositions;

export default homeGameListScrollPositionSlice.reducer;
