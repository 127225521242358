import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ImageMapper from 'react-image-mapper';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import BasketballIcon from '../../../assets/basket_ball.png';
import { MOBILE_MEDIA, StyledFlexRow } from '../../../styles';
import { colors } from '../../../styles/colorsStatsCollector';
import {
  CourtLocationArea,
  CourtProps,
  getShotLocation
} from '../../../utils/shotLocationUtils';
import { ActionTypes } from '../ActionTypes.type';
import { locationSelection } from '../controller/scoreKeeperEventSlice';
import { CancelButton, NextButton } from './EventActions';

const log = require('../../../logger')('CourtLocation', 'debug');

const CourtLocation = styled(({ 
  actionType, 
  isTwoPointer, 
  ...props 
}) => {
  const [coordinates, setCoordinates] = useState();
  const [x, setX] = useState(props.x);
  const [y, setY] = useState(props.y);
  const [area, setArea] = useState(props.area);
  const [width, setWidth] = useState();
  const dispatch = useDispatch();

  return (
    <div {...props}>
      <div className="instructions">
        Tap where the shot was {actionType.label.toLowerCase()}
      </div>

      <ClickableCourt
        x={x}
        y={y}
        area={area}
        coordinates={coordinates}
        setY={setY}
        setX={setX}
        setArea={setArea}
        setCoordinates={setCoordinates}
        width={width}
        setWidth={setWidth}
        actionType={actionType}
        isTwoPointer={isTwoPointer}
      />

      <StyledFlexRow className="align-items-center">
        <CancelButton />

        <NextButton
          onClick={() => {
            const shotLocation = getShotLocation(x, y, area, width);
            log.info('Shot Confirmed', shotLocation);
            dispatch(locationSelection(shotLocation));
          }}
          style={{ margin: '10px' }}
          disabled={!x || !y}
        />
      </StyledFlexRow>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .instructions {
    text-align: center;
    font: normal normal normal 18px/24px Open Sans;
    letter-spacing: -0.36px;
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.GRAY[500]};
    margin-bottom: 20px;
  }

  @media ${MOBILE_MEDIA} {
    .instructions {
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
`;

const ClickableCourt = ({
  x,
  y,
  area,
  coordinates,
  setX,
  setY,
  setArea,
  setCoordinates,
  width,
  setWidth,
  actionType,
  isTwoPointer,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef?.current?.clientWidth) {
        log.debug(
          'Court Image Container Resize',
          containerRef.current.clientWidth
        );
        setX(null);
        setY(null);
        setWidth(containerRef.current.clientWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        position: 'relative',
      }}
      ref={containerRef}
    >
      <div
        className="container"
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div style={{ position: 'relative' }}>
          {x && y && (
            <img
              src={BasketballIcon}
              alt="Basketball Icon"
              style={{
                width: '15px',
                height: 'auto',
                position: 'absolute',
                left: x - 7.5,
                top: y - 7.5,
                borderRadius: 7.5,
                boxShadow: `0 0 10px 5px ${actionType.color}`,
                zIndex: 2,
              }}
            />
          )}
          <ImageMapper
            src={isTwoPointer ? CourtProps.image2pt : CourtProps.image3pt}
            map={CourtProps.map}
            width={width}
            imgWidth={CourtProps.imgWidth}
            onMouseMove={(area, _, evt) => {
              setCoordinates({
                x: evt.nativeEvent.layerX,
                y: evt.nativeEvent.layerY,
              });
            }}
            onClick={(area) => {
              if (isTwoPointer && area.name !== CourtLocationArea.THREE_PT) {
                setX(coordinates.x);
                setY(coordinates.y);
                setArea(area.name);
              } else if (
                !isTwoPointer &&
                area.name === CourtLocationArea.THREE_PT
              ) {
                setX(coordinates.x);
                setY(coordinates.y);
                setArea(area.name);
              }
              log.debug(
                'Click Location',
                getShotLocation(coordinates.x, coordinates.y, area.name, width)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

CourtLocation.propTypes = {
  actionType: PropTypes.oneOf(Object.values(ActionTypes)).isRequired,
};

// Specifies the default values for props:
CourtLocation.defaultProps = {
  actionType: ActionTypes.Made,
};

export default CourtLocation;
