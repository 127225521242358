import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles';
import { TABBED_ITEM } from './TabbedItemEnum';
import PlayersStatsRecords from './PlayersStatsRecords';

const PlayerStatsContainer = styled.div`
  padding: 20px;
  background-color: ${colors.WHITE[100]};
  border-left: 1px solid #D5D5D5;
  border-right: 1px solid #D5D5D5;
  border-bottom: 1px solid #D5D5D5;
`;

const StatsContainer = ({
  activeTab, 
  awayTeamPlayersGameStats, 
  homeTeamPlayersGameStats, 
}) => {
  return (
    <PlayerStatsContainer>
      {(activeTab === TABBED_ITEM.AWAY_TEAM_ITEM)
        ?
        <PlayersStatsRecords
          teamPlayerGameStats={awayTeamPlayersGameStats}
        />
        :
        <PlayersStatsRecords
          teamPlayerGameStats={homeTeamPlayersGameStats}
        />
      }
    </PlayerStatsContainer>
  );
};

export default StatsContainer;