/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncSports = /* GraphQL */ `
  query SyncSports(
    $filter: ModelSportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSport = /* GraphQL */ `
  query GetSport($id: ID!) {
    getSport(id: $id) {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSports = /* GraphQL */ `
  query ListSports(
    $filter: ModelSportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeagues = /* GraphQL */ `
  query SyncLeagues(
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeagues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLeague = /* GraphQL */ `
  query GetLeague($id: ID!) {
    getLeague(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLeagues = /* GraphQL */ `
  query ListLeagues(
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeagues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSeasons = /* GraphQL */ `
  query SyncSeasons(
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSeasons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSeason = /* GraphQL */ `
  query GetSeason($id: ID!) {
    getSeason(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSeasons = /* GraphQL */ `
  query ListSeasons(
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeams = /* GraphQL */ `
  query SyncTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlayers = /* GraphQL */ `
  query SyncPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVenues = /* GraphQL */ `
  query SyncVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVenues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        city
        state
        zipcode
        active
        searchName
        searchCity
        searchState
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        city
        state
        zipcode
        active
        searchName
        searchCity
        searchState
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGameUsers = /* GraphQL */ `
  query SyncGameUsers(
    $filter: ModelGameUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGameUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameUser = /* GraphQL */ `
  query GetGameUser($id: ID!) {
    getGameUser(id: $id) {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGameUsers = /* GraphQL */ `
  query ListGameUsers(
    $filter: ModelGameUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamUsers = /* GraphQL */ `
  query SyncTeamUsers(
    $filter: ModelTeamUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        leagueId
        seasonId
        teamId
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamUser = /* GraphQL */ `
  query GetTeamUser($id: ID!) {
    getTeamUser(id: $id) {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTeamUsers = /* GraphQL */ `
  query ListTeamUsers(
    $filter: ModelTeamUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        leagueId
        seasonId
        teamId
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGames = /* GraphQL */ `
  query SyncGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGameLineups = /* GraphQL */ `
  query SyncGameLineups(
    $filter: ModelGameLineupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGameLineups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameLineup = /* GraphQL */ `
  query GetGameLineup($id: ID!) {
    getGameLineup(id: $id) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGameLineups = /* GraphQL */ `
  query ListGameLineups(
    $filter: ModelGameLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameLineups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGameLineupPlayers = /* GraphQL */ `
  query SyncGameLineupPlayers(
    $filter: ModelGameLineupPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGameLineupPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameLineupPlayer = /* GraphQL */ `
  query GetGameLineupPlayer($id: ID!) {
    getGameLineupPlayer(id: $id) {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGameLineupPlayers = /* GraphQL */ `
  query ListGameLineupPlayers(
    $filter: ModelGameLineupPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameLineupPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCareerPlayerStats = /* GraphQL */ `
  query SyncCareerPlayerStats(
    $filter: ModelCareerPlayerStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCareerPlayerStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        last_in
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        dd2
        dd3
        eff_fg
        tov_percent
        usage_percent
        assist_percent
        possession
        win_percent
        loss
        personal_foul
        tech_foul
        win
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCareerPlayerStats = /* GraphQL */ `
  query GetCareerPlayerStats($id: ID!) {
    getCareerPlayerStats(id: $id) {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCareerPlayerStatss = /* GraphQL */ `
  query ListCareerPlayerStatss(
    $filter: ModelCareerPlayerStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerPlayerStatss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        last_in
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        dd2
        dd3
        eff_fg
        tov_percent
        usage_percent
        assist_percent
        possession
        win_percent
        loss
        personal_foul
        tech_foul
        win
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCareerTeamStats = /* GraphQL */ `
  query SyncCareerTeamStats(
    $filter: ModelCareerTeamStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCareerTeamStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        loss
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        win
        true_shoot_att
        true_shoot_percent
        win_percent
        last_in
        tov_percent
        usage_percent
        dd2
        dd3
        possession
        personal_foul
        tech_foul
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCareerTeamStats = /* GraphQL */ `
  query GetCareerTeamStats($id: ID!) {
    getCareerTeamStats(id: $id) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCareerTeamStatss = /* GraphQL */ `
  query ListCareerTeamStatss(
    $filter: ModelCareerTeamStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerTeamStatss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        loss
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        win
        true_shoot_att
        true_shoot_percent
        win_percent
        last_in
        tov_percent
        usage_percent
        dd2
        dd3
        possession
        personal_foul
        tech_foul
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGamePlayerStats = /* GraphQL */ `
  query SyncGamePlayerStats(
    $filter: ModelGamePlayerStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGamePlayerStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        last_in
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        assist_percent
        eff_fg
        gameLineupId
        tov_percent
        block_percent
        rebounds
        game_score
        personal_fowls_drawn
        dd2
        dd3
        def_rebound_percent
        off_rebound_percent
        personal_foul
        steal_percent
        tech_foul
        total_rebound_percent
        true_shoot_att
        true_shoot_percent
        usage_percent
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGamePlayerStats = /* GraphQL */ `
  query GetGamePlayerStats($id: ID!) {
    getGamePlayerStats(id: $id) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGamePlayerStatss = /* GraphQL */ `
  query ListGamePlayerStatss(
    $filter: ModelGamePlayerStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGamePlayerStatss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        last_in
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        assist_percent
        eff_fg
        gameLineupId
        tov_percent
        block_percent
        rebounds
        game_score
        personal_fowls_drawn
        dd2
        dd3
        def_rebound_percent
        off_rebound_percent
        personal_foul
        steal_percent
        tech_foul
        total_rebound_percent
        true_shoot_att
        true_shoot_percent
        usage_percent
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSeasonPlayerStats = /* GraphQL */ `
  query SyncSeasonPlayerStats(
    $filter: ModelSeasonPlayerStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSeasonPlayerStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        assist_percent
        block_percent
        blocks
        dd2
        dd3
        def_rebound
        def_rebound_percent
        eff_fg
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        game_score
        minutes_played
        off_rebound
        off_rebound_percent
        personal_foul
        steal
        steal_percent
        team_minutes_played
        tech_foul
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebound_percent
        total_rebounds
        tov_percent
        true_shoot_atts
        true_shoot_percent
        turnover
        two_percent
        two_point_att
        two_point_made
        usage_percent
        win_percent
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSeasonPlayerStats = /* GraphQL */ `
  query GetSeasonPlayerStats($id: ID!) {
    getSeasonPlayerStats(id: $id) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSeasonPlayerStatss = /* GraphQL */ `
  query ListSeasonPlayerStatss(
    $filter: ModelSeasonPlayerStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasonPlayerStatss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        assist_percent
        block_percent
        blocks
        dd2
        dd3
        def_rebound
        def_rebound_percent
        eff_fg
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        game_score
        minutes_played
        off_rebound
        off_rebound_percent
        personal_foul
        steal
        steal_percent
        team_minutes_played
        tech_foul
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebound_percent
        total_rebounds
        tov_percent
        true_shoot_atts
        true_shoot_percent
        turnover
        two_percent
        two_point_att
        two_point_made
        usage_percent
        win_percent
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSeasonTeamStats = /* GraphQL */ `
  query SyncSeasonTeamStats(
    $filter: ModelSeasonTeamStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSeasonTeamStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        assist
        assist_percent
        block_percent
        blocks
        dd2
        dd3
        def_rebound
        def_rebound_percent
        eff_fg
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        game_score
        losses
        minutes_played
        off_rebound
        off_rebound_percent
        opp_3p_atts
        opp_def_rebounds
        opp_fg
        opp_fg_atts
        opp_ft_atts
        opp_off_rebounds
        opp_points
        opp_possessions
        opp_total_rebounds
        opp_tov
        personal_foul
        steal
        steal_percent
        team_minutes_played
        tech_foul
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebound_percent
        total_rebounds
        tov_percent
        true_shoot_att
        true_shoot_percent
        turnover
        two_percent
        two_point_att
        two_point_made
        usage_percent
        win_percent
        wins
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSeasonTeamStats = /* GraphQL */ `
  query GetSeasonTeamStats($id: ID!) {
    getSeasonTeamStats(id: $id) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSeasonTeamStatss = /* GraphQL */ `
  query ListSeasonTeamStatss(
    $filter: ModelSeasonTeamStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasonTeamStatss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        assist
        assist_percent
        block_percent
        blocks
        dd2
        dd3
        def_rebound
        def_rebound_percent
        eff_fg
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        game_score
        losses
        minutes_played
        off_rebound
        off_rebound_percent
        opp_3p_atts
        opp_def_rebounds
        opp_fg
        opp_fg_atts
        opp_ft_atts
        opp_off_rebounds
        opp_points
        opp_possessions
        opp_total_rebounds
        opp_tov
        personal_foul
        steal
        steal_percent
        team_minutes_played
        tech_foul
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebound_percent
        total_rebounds
        tov_percent
        true_shoot_att
        true_shoot_percent
        turnover
        two_percent
        two_point_att
        two_point_made
        usage_percent
        win_percent
        wins
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScoreTypes = /* GraphQL */ `
  query SyncScoreTypes(
    $filter: ModelScoreTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScoreTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getScoreType = /* GraphQL */ `
  query GetScoreType($id: ID!) {
    getScoreType(id: $id) {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listScoreTypes = /* GraphQL */ `
  query ListScoreTypes(
    $filter: ModelScoreTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamRosterPlayers = /* GraphQL */ `
  query SyncTeamRosterPlayers(
    $filter: ModelTeamRosterPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamRosterPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        teamRosterId
        teamRoster {
          id
          name
          info
          startDateTime
          endDateTime
          dateCreated
          dateUpdated
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamRosters = /* GraphQL */ `
  query SyncTeamRosters(
    $filter: ModelTeamRosterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamRosters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamRoster = /* GraphQL */ `
  query GetTeamRoster($id: ID!) {
    getTeamRoster(id: $id) {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTeamRosters = /* GraphQL */ `
  query ListTeamRosters(
    $filter: ModelTeamRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamRosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTimeEvents = /* GraphQL */ `
  query SyncTimeEvents(
    $filter: ModelTimeEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimeEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        dateTime
        type
        description
        note
        data
        frontendGameQuarterNumber
        frontendGameQuarterMinutes
        frontendGameQuarterSeconds
        backendGameQuarterNumber
        backendGameQuarterMinutes
        backendGameQuarterSeconds
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTimeEvent = /* GraphQL */ `
  query GetTimeEvent($id: ID!) {
    getTimeEvent(id: $id) {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTimeEvents = /* GraphQL */ `
  query ListTimeEvents(
    $filter: ModelTimeEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        dateTime
        type
        description
        note
        data
        frontendGameQuarterNumber
        frontendGameQuarterMinutes
        frontendGameQuarterSeconds
        backendGameQuarterNumber
        backendGameQuarterMinutes
        backendGameQuarterSeconds
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomStats = /* GraphQL */ `
  query SyncCustomStats(
    $filter: ModelCustomStatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        entityId
        scoreTypeId
        scoreType {
          id
          leagueId
          name
          category
          displayName
          type
          data
          points
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        dateUpdated
        positiveInstanceCount
        negativeInstanceCount
        allInstanceCount
        positiveToAllPercentage
        otherCounterOne
        otherCounterTwo
        otherCounterThree
        otherCounterFour
        otherData
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomStat = /* GraphQL */ `
  query GetCustomStat($id: ID!) {
    getCustomStat(id: $id) {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCustomStats = /* GraphQL */ `
  query ListCustomStats(
    $filter: ModelCustomStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        entityId
        scoreTypeId
        scoreType {
          id
          leagueId
          name
          category
          displayName
          type
          data
          points
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        dateUpdated
        positiveInstanceCount
        negativeInstanceCount
        allInstanceCount
        positiveToAllPercentage
        otherCounterOne
        otherCounterTwo
        otherCounterThree
        otherCounterFour
        otherData
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventPlayers = /* GraphQL */ `
  query SyncEventPlayers(
    $filter: ModelEventPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventId
        event {
          id
          createdAt
          updatedAt
          eventType
          relatedEventId
          gameId
          teamId
          playerId
          gameLineupPlayerId
          eventAbsoluteTime
          eventCreatorRole
          gamePeriodNumber
          gamePeriodMinutes
          gamePeriodSeconds
          gameOvertimeNumber
          gameOvertimeMinutes
          gameOvertimeSeconds
          shotLocation
          possessionTeam
          shootingFoul
          playType
          isEdited
          isDeleted
          isFlaged
          statcollSub
          statcollEmail
          statcollFirstName
          statcollLastName
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLeagueByCreatedAt = /* GraphQL */ `
  query GetLeagueByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeagueByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLeagueByUpdatedAt = /* GraphQL */ `
  query GetLeagueByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLeagueByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSeasonByCreatedAt = /* GraphQL */ `
  query GetSeasonByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSeasonByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSeasonByUpdatedAt = /* GraphQL */ `
  query GetSeasonByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSeasonByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamByCreatedAt = /* GraphQL */ `
  query GetTeamByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamByUpdatedAt = /* GraphQL */ `
  query GetTeamByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlayerByCreatedAt = /* GraphQL */ `
  query GetPlayerByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlayerByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlayerByUpdatedAt = /* GraphQL */ `
  query GetPlayerByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlayerByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVenueByCreatedAt = /* GraphQL */ `
  query GetVenueByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVenueByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        city
        state
        zipcode
        active
        searchName
        searchCity
        searchState
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVenueByUpdatedAt = /* GraphQL */ `
  query GetVenueByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVenueByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        name
        city
        state
        zipcode
        active
        searchName
        searchCity
        searchState
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameUserByUserIdGameId = /* GraphQL */ `
  query GetGameUserByUserIdGameId(
    $userId: ID
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameUserByUserIdGameId(
      userId: $userId
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamUserByUserIdTeamId = /* GraphQL */ `
  query GetTeamUserByUserIdTeamId(
    $userId: ID
    $teamId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamUserByUserIdTeamId(
      userId: $userId
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        leagueId
        seasonId
        teamId
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        userId
        user {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          email
          role
          active
          searchFirstName
          searchLastName
          _version
          _deleted
          _lastChangedAt
        }
        gameRole
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserByCreatedAt = /* GraphQL */ `
  query GetUserByCreatedAt(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCreatedAt(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserByUpdatedAt = /* GraphQL */ `
  query GetUserByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameByDateTime = /* GraphQL */ `
  query GetGameByDateTime(
    $id: ID
    $gameDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByDateTime(
      id: $id
      gameDateTime: $gameDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameByDatTim = /* GraphQL */ `
  query GetGameByDatTim(
    $keySortId: ID
    $gameDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByDatTim(
      keySortId: $keySortId
      gameDateTime: $gameDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameByCreatedAt = /* GraphQL */ `
  query GetGameByCreatedAt(
    $keySortId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByCreatedAt(
      keySortId: $keySortId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameByUpdatedAt = /* GraphQL */ `
  query GetGameByUpdatedAt(
    $keySortId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGameByUpdatedAt(
      keySortId: $keySortId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventbyEventAbsoluteTime = /* GraphQL */ `
  query GetEventbyEventAbsoluteTime(
    $gameId: ID
    $eventAbsoluteTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventbyEventAbsoluteTime(
      gameId: $gameId
      eventAbsoluteTime: $eventAbsoluteTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventByGameIdUpdatedAt = /* GraphQL */ `
  query GetEventByGameIdUpdatedAt(
    $gameId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventByGameIdUpdatedAt(
      gameId: $gameId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventByGameIdCreatedAt = /* GraphQL */ `
  query GetEventByGameIdCreatedAt(
    $gameId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventByGameIdCreatedAt(
      gameId: $gameId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventByGameIdGamePeriod = /* GraphQL */ `
  query GetEventByGameIdGamePeriod(
    $gameId: ID
    $gamePeriodNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventByGameIdGamePeriod(
      gameId: $gameId
      gamePeriodNumber: $gamePeriodNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventByGameIdGameOvertime = /* GraphQL */ `
  query GetEventByGameIdGameOvertime(
    $gameId: ID
    $gameOvertimeNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventByGameIdGameOvertime(
      gameId: $gameId
      gameOvertimeNumber: $gameOvertimeNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGPStatsByGameIdTeamId = /* GraphQL */ `
  query GetGPStatsByGameIdTeamId(
    $gameId: ID
    $teamId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGamePlayerStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGPStatsByGameIdTeamId(
      gameId: $gameId
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        game {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        playerId
        player {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        assist
        blocks
        def_rebound
        fg_att
        fg_made
        fg_percent
        free_throw
        free_throw_atts
        ft_percent
        last_in
        minutes_played
        off_rebound
        steal
        three_percent
        three_point_att
        three_point_made
        total_points
        total_rebounds
        turnover
        two_percent
        two_point_att
        two_point_made
        assist_percent
        eff_fg
        gameLineupId
        tov_percent
        block_percent
        rebounds
        game_score
        personal_fowls_drawn
        dd2
        dd3
        def_rebound_percent
        off_rebound_percent
        personal_foul
        steal_percent
        tech_foul
        total_rebound_percent
        true_shoot_att
        true_shoot_percent
        usage_percent
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGameTeamStats = /* GraphQL */ `
  query GetGameTeamStats($id: ID!) {
    getGameTeamStats(id: $id) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGameTeamStatss = /* GraphQL */ `
  query ListGameTeamStatss(
    $filter: ModelGameTeamStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameTeamStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamId
        homeTeam {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamId
        awayTeam {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        curr_possession
        end_pos_tov
        margin_of_victory
        regular
        home_assist
        home_blocks
        home_def_rebound
        home_fg_att
        home_fg_made
        home_fg_percent
        home_free_throw
        home_free_throw_atts
        home_ft_percent
        home_last_in
        home_minutes_played
        home_num_pos
        home_off_rebound
        home_steal
        home_three_percent
        home_three_point_att
        home_three_point_made
        home_total_points
        home_total_rebounds
        home_turnover
        home_two_percent
        home_two_point_att
        home_two_point_made
        home_assist_percent
        home_eff_fg
        home_tov_percent
        home_true_shoot_att
        home_true_shoot_percent
        home_usage_percent
        home_dd2
        home_dd3
        home_block_percent
        home_def_rebound_percent
        home_game_score
        home_off_rebound_percent
        home_personal_foul
        home_steal_percent
        home_tech_foul
        home_timeouts
        home_total_rebound_percent
        home_win
        away_assist
        away_blocks
        away_def_rebound
        away_fg_att
        away_fg_made
        away_fg_percent
        away_free_throw
        away_free_throw_atts
        away_ft_percent
        away_last_in
        away_minutes_played
        away_num_pos
        away_off_rebound
        away_steal
        away_three_percent
        away_three_point_att
        away_three_point_made
        away_total_points
        away_total_rebounds
        away_turnover
        away_two_percent
        away_two_point_att
        away_two_point_made
        away_assist_percent
        away_eff_fg
        away_tov_percent
        away_true_shoot_att
        away_true_shoot_percent
        away_usage_percent
        away_dd2
        away_dd3
        away_block_percent
        away_def_rebound_percent
        away_game_score
        away_off_rebound_percent
        away_personal_foul
        away_steal_percent
        away_tech_foul
        away_timeouts
        away_total_rebound_percent
        away_win
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGameTeamStats = /* GraphQL */ `
  query SyncGameTeamStats(
    $filter: ModelGameTeamStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGameTeamStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        seasonId
        season {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamId
        homeTeam {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamId
        awayTeam {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        curr_possession
        end_pos_tov
        margin_of_victory
        regular
        home_assist
        home_blocks
        home_def_rebound
        home_fg_att
        home_fg_made
        home_fg_percent
        home_free_throw
        home_free_throw_atts
        home_ft_percent
        home_last_in
        home_minutes_played
        home_num_pos
        home_off_rebound
        home_steal
        home_three_percent
        home_three_point_att
        home_three_point_made
        home_total_points
        home_total_rebounds
        home_turnover
        home_two_percent
        home_two_point_att
        home_two_point_made
        home_assist_percent
        home_eff_fg
        home_tov_percent
        home_true_shoot_att
        home_true_shoot_percent
        home_usage_percent
        home_dd2
        home_dd3
        home_block_percent
        home_def_rebound_percent
        home_game_score
        home_off_rebound_percent
        home_personal_foul
        home_steal_percent
        home_tech_foul
        home_timeouts
        home_total_rebound_percent
        home_win
        away_assist
        away_blocks
        away_def_rebound
        away_fg_att
        away_fg_made
        away_fg_percent
        away_free_throw
        away_free_throw_atts
        away_ft_percent
        away_last_in
        away_minutes_played
        away_num_pos
        away_off_rebound
        away_steal
        away_three_percent
        away_three_point_att
        away_three_point_made
        away_total_points
        away_total_rebounds
        away_turnover
        away_two_percent
        away_two_point_att
        away_two_point_made
        away_assist_percent
        away_eff_fg
        away_tov_percent
        away_true_shoot_att
        away_true_shoot_percent
        away_usage_percent
        away_dd2
        away_dd3
        away_block_percent
        away_def_rebound_percent
        away_game_score
        away_off_rebound_percent
        away_personal_foul
        away_steal_percent
        away_tech_foul
        away_timeouts
        away_total_rebound_percent
        away_win
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
