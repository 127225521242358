import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { colors } from '../styles/colors';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import LoginLayout from '../components/layout/LoginLayout';
import {
  selectAuthSessionExpired,
  setHasSessionExpired,
} from '../redux/authStateSlice';
import { useDispatch, useSelector } from 'react-redux';
const log = require('../logger')();

function LogoutNotificationModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Session Expired
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Session Expired</h4> */}
        Your session has expired. Please sign in again.
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            padding: '0.8rem 2rem',
            fontSize: 'var(--amplify-text-sm)',
            fontWeight: '500',
            letterSpacing: '0.75px',
            borderRadius: '0px',
          }}
          onClick={props.onHide}
        >
          SIGN IN
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const LoginPage = () => {
  const hasAuthSessionExpired = useSelector(selectAuthSessionExpired);
  const dispatch = useDispatch();

  return (
    <>
      <LoginLayout>
        <AmplifyAuthenticator>
          <AmplifySignIn hideSignUp={true} slot="sign-in" />
        </AmplifyAuthenticator>
      </LoginLayout>
      <LogoutNotificationModal
        show={hasAuthSessionExpired}
        onHide={() => dispatch(setHasSessionExpired(false))}
      />
    </>
  );
};

export default LoginPage;
