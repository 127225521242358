import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { Check, X } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { clockTimerStatus, createGameTimer } from '../api/clockService';
import { getGame } from '../api/gamesService';
import { getLeague } from '../api/leaguesService';
import { getSeason, getSeasonImageProfile } from '../api/seasonsService';
import { getTeamImageProfile } from '../api/teamsService';
import { useGetUserGamesQuery } from '../api/userQueries';
import ToastAlert from '../components/alerts/ToastAlert';
import LaunchLayout from '../components/layout/LaunchLayout';
import GameContent from '../components/PageContent/GameContent';
import SeasonContent from '../components/PageContent/SeasonContent';
import { CLOCKMANAGER, SCOREKEEPER } from '../data/roles';
import { GAME_STATUS } from '../utils/constantsUtils';
import { GamesTabs } from '../utils/gameUtil';
const log = require('../logger')('GameChoice', 'debug');

const SeasonFilterDisplayOptions = {
  CURRENT: 'CURRENT',
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
  NONE: 'NONE',
};

const SelectionMode = {
  SEASON: 'SEASON',
  GAME: 'GAME',
  NONE: 'NONE',
};

const BodyStyled = styled.div`
  height: 100%;
  margin: auto 20px;
`;

const NoContentText = styled.div`
  height: 85%;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  ${(props) => (props.selected ? 'font-weight: bold;' : '')}
`;

const LaunchButtonGroupContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  padding-right: 470px;
  width: 100%;
`;

const LaunchButtonGroup = styled(ButtonGroup)``;

const LaunchFilterButton = styled(Button)`
  padding: 6px 0;
  margin-left: 6px;
  margin-right: 12px;
  text-align: left !important;
  border: 0;
  background-color: transparent !important;
  font: normal normal normal 14px/20px Inter;
  letter-spacing: 0px;
  color: #111111 !important;
  opacity: 1;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
  &:hover {
    border: 0;
    border-bottom: 2px solid #26a9e1;
  }
  &:active,
  &.active {
    font-weight: bold;
    border-color: transparent;
    border-bottom: 2px solid #26a9e1;
  }
  &:disabled {
    border: 0;
    border-bottom: 2px solid transparent;
  }
`;

const LaunchFilterButtonCurrent = styled(LaunchFilterButton)`
  min-width: 165px;
`;
const LaunchFilterButtonPast = styled(LaunchFilterButton)`
  min-width: 140px;
`;
const LaunchFilterButtonUpcoming = styled(LaunchFilterButton)`
  min-width: 180px;
`;

const LaunchContentContainer = styled.div`
  padding-top: 50px;
  height: 100%;
`;

const SelectDeselectText = styled.span`
  font: normal normal bold 12px/24px Inter;
  letter-spacing: 0px;
  color: #2e2e2e;
  opacity: 1;
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SelectAllDivider = styled.div`
  border-left: 1px solid #babcc0;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const GameChoicePage = () => {
  const history = useHistory();
  /** List of UserGames assigned to the logged in User */
  const [userGame, setUserGames] = useState([]);
  const [selectionMode, setSelectionMode] = useState(SelectionMode.SEASON);

  /**
   * Toast Variables
   */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');

  /**
   * Season Selection variables
   */

  /** List of seasons containing games assigned to the logged in User */
  const [seasons, setSeasons] = useState([]);
  /** List of seasons currently taking place */
  const [seasonsCurrent, setSeasonsCurrent] = useState([]);
  /** List of seasons that have finished */
  const [seasonsPast, setSeasonsPast] = useState([]);
  /** List of seasons that have not started yet */
  const [seasonsUpcoming, setSeasonsUpcoming] = useState([]);
  /** List of seasons to display on the page */
  const [seasonsDisplayed, setSeasonsDisplayed] = useState([]);
  /** List of seasons IDs that the user has selected */
  const [seasonsSelected, setSeasonsSelected] = useState([]);
  /** Count of selected current seasons */
  const [seasonsCurrentCount, setSeasonsCurrentCount] = useState([]);
  /** Count of selected past seasons */
  const [seasonsPastCount, setSeasonsPastCount] = useState([]);
  /** Count of selected upcoming seasons */
  const [seasonsUpcomingCount, setSeasonsUpcomingCount] = useState([]);
  /** Enumeration to keep track of and change the currently displayed seasons */
  const [seasonOption, setSeasonOption] = useState(
    SeasonFilterDisplayOptions.NONE
  );

  /**
   * Game Selection variables
   */

  /** List of games currently taking place */
  const [gamesCurrent, setGamesCurrent] = useState([]);
  /** List of games that have finished */
  const [gamesPast, setGamesPast] = useState([]);
  /** List of games that have not started yet */
  const [gamesUpcoming, setGamesUpcoming] = useState([]);
  /** List of games to display on the page */
  const [gamesDisplayed, setGamesDisplayed] = useState([]);
  /** List of games IDs that the user has selected */
  const [gamesSelected, setGamesSelected] = useState([]);
  /** Game object the user has selected */
  const [gameSelected, setGameSelected] = useState([]);
  /** Count of selected current games */
  const [gamesCurrentCount, setGamesCurrentCount] = useState();
  /** Count of selected past games */
  const [gamesPastCount, setGamesPastCount] = useState();
  /** Count of selected upcoming games */
  const [gamesUpcomingCount, setGamesUpcomingCount] = useState();
  /** Enumeration to keep track of and change the currently displayed games */
  const [gameOption, setGameOption] = useState(GamesTabs.NONE);

  /** Boolean to show spinner until data is loaded */
  const [showSpinner, setShowSpinner] = useState(true);
  /** Selector to get the logged in user from App state */
  const userId = useSelector((state) => state.user?.id);

  /**
   * Update Item Counts for each season list
   */
  useEffect(() => {
    setSeasonsCurrentCount(
      seasonsCurrent.filter((item) => seasonsSelected.includes(item.id)).length
    );
    setSeasonsPastCount(
      seasonsPast.filter((item) => seasonsSelected.includes(item.id)).length
    );
    setSeasonsUpcomingCount(
      seasonsUpcoming.filter((item) => seasonsSelected.includes(item.id)).length
    );
  }, [seasonsCurrent, seasonsPast, seasonsSelected, seasonsUpcoming]);

  /**
   * Handle change of displayed season option (current, upcoming, past)
   */
  useEffect(() => {
    switch (seasonOption) {
      case SeasonFilterDisplayOptions.CURRENT:
        setSeasonsDisplayed(seasonsCurrent);
        break;
      case SeasonFilterDisplayOptions.UPCOMING:
        setSeasonsDisplayed(seasonsUpcoming);
        break;
      case SeasonFilterDisplayOptions.PAST:
        setSeasonsDisplayed(seasonsPast);
        break;
      case SeasonFilterDisplayOptions.NONE:
        setSeasonsDisplayed([]);
        break;
      default:
        log.error('Unknown SeasonOption: ', seasonOption);
        break;
    }
  }, [seasonOption, seasonsCurrent, seasonsPast, seasonsUpcoming]);

  /**
   * Handle change of displayed games option (current, upcoming, past)
   */
  useEffect(() => {
    switch (gameOption) {
      case GamesTabs.TODAY:
        setGamesDisplayed(gamesCurrent);
        break;
      case GamesTabs.UPCOMING:
        setGamesDisplayed(gamesUpcoming);
        break;
      case GamesTabs.PAST:
        setGamesDisplayed(gamesPast);
        break;
      case GamesTabs.NONE:
        setGamesDisplayed([]);
        break;
      default:
        log.error('Unknown GameOption: ', gameOption);
        break;
    }
  }, [gameOption, gamesCurrent, gamesPast, gamesUpcoming]);

  const { data: games, isSuccess } = useGetUserGamesQuery(userId);

  /**
   * ON INITIAL LOAD
   * Get logged in user data
   * Get the games they are assigned to
   * Find the seasons associated with the assigned games
   * Sort seasons into current, upcoming, past
   * Set which seasons are displayed
   */
  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    (async function () {
      setShowSpinner(true);

      /** Find unique seasons from game objects */
      const uniqueSeasonIds = Array.from(
        new Set(games?.map((item) => item.game.seasonId))
      );
      log.debug('uniqueSeasonIds', uniqueSeasonIds);

      /** Get Season data */
      const uniqueSeasons = await Promise.all(
        uniqueSeasonIds.map(async (id) => {
          const season = await getSeason(id);
          log.debug(`season ${id}: `, season);
          const [league, leagueImage] = await Promise.all([
            getLeague(season.leagueId),
            getSeasonImageProfile(season.leagueId),
          ]);
          season.league = league;
          season.league.image = leagueImage;
          return season;
        })
      );

      /** The Amplify store uses a date string format of YYYY-MM-DD
       * this does not play well with the javascript datetimes because is assumes
       * that is part of an iso datetime string, and then defaults to the UTC timezone.
       * To compensate for this we do the following:
       * 1. Get the current Date (Date.now())
       * 2. Convert that to an ISO string (YYYY-MM-DDTHH:mm:ss.sssZ)
       * 3. Extract the date subset of the string (chars 0 through 10, YYYY-MM-DD)
       * Now we have an UTC/ISO date the same as the Amplify date, and we can compare them.
       */
      const currentDateString = new Date(Date.now())
        .toISOString()
        .substring(0, 10);
      const dateNow = new Date(currentDateString);

      /** Sort seasons into current, past, upcoming */
      const pastSeasons = uniqueSeasons.filter(
        (season) => new Date(season?.endDate) < dateNow
      );
      const upcomingSeasons = uniqueSeasons.filter(
        (season) => new Date(season?.startDate) > dateNow
      );
      const currentSeasons = uniqueSeasons.filter(
        (season) =>
          new Date(season?.startDate) <= dateNow &&
          new Date(season?.endDate) >= dateNow
      );

      /** TODO Remove test logs */
      // log.debug('User', userId);
      // log.debug('Full User', userData);
      // log.debug('Games', userData.games.items);
      // log.debug('Seasons - All', seasons);
      log.debug('Seasons - Unique', uniqueSeasons);
      log.debug('Current Seasons', currentSeasons);
      log.debug('Past Seasons', pastSeasons);
      log.debug('Upcoming Seasons', upcomingSeasons);

      /** Update the lists of seasons and games */
      setUserGames(games);
      setSeasons(uniqueSeasons);
      setSeasonsCurrent(currentSeasons);
      setSeasonsUpcoming(upcomingSeasons);
      setSeasonsPast(pastSeasons);

      /** Set the current displayed seasons.
       * Default should be CURRENT, if empty then try UPCOMING or PAST */
      if (currentSeasons.length > 0) {
        setSeasonOption(SeasonFilterDisplayOptions.CURRENT);
      } else if (upcomingSeasons.length > 0) {
        setSeasonOption(SeasonFilterDisplayOptions.UPCOMING);
      } else if (pastSeasons.length > 0) {
        setSeasonOption(SeasonFilterDisplayOptions.PAST);
      } else {
        setSeasonOption(SeasonFilterDisplayOptions.NONE);
      }

      /** Loading finished, hide spinner to show content */
      setShowSpinner(false);
    })();
  }, [games, isSuccess]);

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  async function updateGamesFromSelectedSeasons() {
    setShowSpinner(true);

    /** Add GameUser properties to the Game object */
    const gamesFromGameUserPairs = userGame.map((gameUser) => {
      gameUser.game.role = gameUser.gameRole;
      gameUser.game.gameUserId = gameUser.id;
      return gameUser.game;
    });

    /** Get the games for the selected seasons */
    const gamesForSelectedSeasons = gamesFromGameUserPairs.filter((game) =>
      seasonsSelected.includes(game.seasonId)
    );

    /** Get Images for Games */
    await Promise.all(
      gamesForSelectedSeasons.map(async (game) => {
        game.homeTeamVsAwayTeam = `${game.homeTeamName} VS ${game.awayTeamName}`;
        const [homeTeamImage, awayTeamImage] = await Promise.all([
          await getTeamImageProfile(game.homeTeamId),
          await getTeamImageProfile(game.awayTeamId),
        ]);
        game.homeTeamImage = homeTeamImage;
        game.awayTeamImage = awayTeamImage;
        return game;
      })
    );

    /** Sort games into current, past, upcoming */
    const today = new Date(Date.now());
    const pastGames = gamesForSelectedSeasons.filter(
      (game) =>
        !datesAreOnSameDay(new Date(game.gameDateTime), today) &&
        new Date(game.gameDateTime) < today
    );
    const upcomingGames = gamesForSelectedSeasons.filter(
      (game) =>
        !datesAreOnSameDay(new Date(game.gameDateTime), today) &&
        new Date(game.gameDateTime) > today
    );
    const currentGames = gamesForSelectedSeasons.filter((game) =>
      datesAreOnSameDay(new Date(game.gameDateTime), today)
    );

    setGamesCurrent(currentGames);
    setGamesUpcoming(upcomingGames);
    setGamesPast(pastGames);

    /** Set the current displayed games.
     * Default should be CURRENT, if empty then try UPCOMING or PAST */
    if (currentGames.length > 0) {
      setGameOption(GamesTabs.TODAY);
    } else if (upcomingGames.length > 0) {
      setGameOption(GamesTabs.UPCOMING);
    } else if (pastGames.length > 0) {
      setGameOption(GamesTabs.PAST);
    } else {
      setGameOption(GamesTabs.NONE);
    }

    setShowSpinner(false);
  }

  /**
   * Item click handler to toggle the selected state by storing/removing the season id
   */
  function onSeasonItemClick(seasonId) {
    log.debug('Season Item Clicked', seasonId);
    if (seasonsSelected.includes(seasonId)) {
      setSeasonsSelected([...seasonsSelected.filter((id) => id !== seasonId)]);
    } else {
      setSeasonsSelected((arr) => [...arr, seasonId]);
    }
  }

  /**
   * Item click handler to toggle the selected state by storing/removing the game id
   */
  function onGameItemClick(gameUserId) {
    log.debug('Game Item Clicked', gameUserId);
    const isSelectedCurrentGame = gamesCurrent.filter(
      (game) => game.gameUserId === gameUserId
    ).length;
    if (isSelectedCurrentGame && !gamesSelected.includes(gameUserId)) {
      setGamesSelected([gameUserId]);
      const newGameSelected = gamesCurrent.filter(
        (game) => game.gameUserId === gameUserId
      )[0];
      setGameSelected(newGameSelected);
    } else if (isSelectedCurrentGame) {
      setGamesSelected([]);
      setGameSelected({});
    }
  }

  const showSelectGame = async () => {
    setSelectionMode(SelectionMode.GAME);
    updateGamesFromSelectedSeasons();
  };

  const showSelectSeason = async () => {
    setSelectionMode(SelectionMode.SEASON);
  };

  /**
   * Search component style to pass to the layout,
   * changing the component absolute location in the layout
   */
  const defaultControlStyle = {
    position: 'absolute',
    top: '145px',
    right: '70px',
    zIndex: '2',
    width: '350px',
    height: '50px',
  };

  /**
   * JSX Component for Season Filter Buttons (Current, Upcoming, Past)
   * */
  function SeasonFilterButtons(props) {
    return (
      <LaunchButtonGroupContainer>
        <LaunchButtonGroup aria-label="Basic example">
          {/* Current Seasons */}
          <LaunchFilterButton
            disabled={!seasonsCurrent.length}
            onClick={() => setSeasonOption(SeasonFilterDisplayOptions.CURRENT)}
            active={seasonOption === SeasonFilterDisplayOptions.CURRENT}
          >
            Current Seasons{' '}
            {seasonsCurrentCount > 0 ? `(${seasonsCurrentCount})` : ''}
          </LaunchFilterButton>
          {/* Upcoming Seasons */}
          <LaunchFilterButton
            disabled={!seasonsUpcoming.length}
            onClick={() => setSeasonOption(SeasonFilterDisplayOptions.UPCOMING)}
            active={seasonOption === SeasonFilterDisplayOptions.UPCOMING}
          >
            Upcoming Seasons{' '}
            {seasonsUpcomingCount > 0 ? `(${seasonsUpcomingCount})` : ''}
          </LaunchFilterButton>
          {/* Past Seasons */}
          <LaunchFilterButton
            disabled={!seasonsPast.length}
            onClick={() => setSeasonOption(SeasonFilterDisplayOptions.PAST)}
            active={seasonOption === SeasonFilterDisplayOptions.PAST}
          >
            Past Seasons {seasonsPastCount > 0 ? `(${seasonsPastCount})` : ''}
          </LaunchFilterButton>
        </LaunchButtonGroup>
        <SelectDeselectText
          style={{
            marginLeft: 'auto',
            marginRight: '10px',
            color: isSelectAllEnabled() ? '#25A9E1' : '#434343',
          }}
          onClick={() => selectAllSeasons()}
        >
          <Check size="20" className="mx-1" />
          Select All
        </SelectDeselectText>
        <SelectAllDivider></SelectAllDivider>
        <SelectDeselectText
          onClick={() => deselectAllSeasons()}
          style={{
            color: isDeselectAllEnabled() ? '#25A9E1' : '#434343',
          }}
        >
          <X size="20" className="mx-1" />
          Deselect All
        </SelectDeselectText>
      </LaunchButtonGroupContainer>
    );
  }

  /**
   * JSX Component for Message to show when no items are available
   * */
  function NoGamesMessage(props) {
    return (
      <NoContentText>
        You have no games. An Admin will need to create a game and assign it to
        you.
      </NoContentText>
    );
  }

  /**
   * JSX Component for Season Content (Selectable Cards, Search Menu)
   * */
  function SeasonSelectionContent(props) {
    return (
      <LaunchContentContainer>
        <SeasonContent
          searchId="__seasonId"
          placeholder="Search Seasons"
          data={seasonsDisplayed}
          searchControlStyle={defaultControlStyle}
          selectedIds={seasonsSelected}
          onClickedItemHook={onSeasonItemClick}
        />
      </LaunchContentContainer>
    );
  }

  /**
   * JSX Component for Game Filter Buttons (Current, Upcoming, Past)
   * */
  function GameFilterButtons(props) {
    return (
      <LaunchButtonGroupContainer>
        <LaunchButtonGroup aria-label="Basic example">
          {/* Current Games */}
          <LaunchFilterButton
            disabled={!gamesCurrent.length}
            onClick={() => setGameOption(GamesTabs.TODAY)}
            active={gameOption === GamesTabs.TODAY}
          >
            Current Games{' '}
            {gamesCurrentCount > 0 ? `(${gamesCurrentCount})` : ''}
          </LaunchFilterButton>
          {/* Upcoming Games */}
          <LaunchFilterButton
            disabled={!gamesUpcoming.length}
            onClick={() => setGameOption(GamesTabs.UPCOMING)}
            active={gameOption === GamesTabs.UPCOMING}
          >
            Upcoming Games{' '}
            {gamesUpcomingCount > 0 ? `(${gamesUpcomingCount})` : ''}
          </LaunchFilterButton>
          {/* Past Games */}
          <LaunchFilterButton
            disabled={!gamesPast.length}
            onClick={() => setGameOption(GamesTabs.PAST)}
            active={gameOption === GamesTabs.PAST}
          >
            Past Games {gamesPastCount > 0 ? `(${gamesPastCount})` : ''}
          </LaunchFilterButton>
        </LaunchButtonGroup>
      </LaunchButtonGroupContainer>
    );
  }

  /**
   * JSX Component for Game Content (Selectable Cards, Search Menu)
   * */
  function GameSelectionContent(props) {
    return (
      <LaunchContentContainer>
        <GameContent
          searchId="__gameId"
          placeholder="Search Games"
          currentTabGames={gamesDisplayed}
          searchControlStyle={defaultControlStyle}
          selectedIds={gamesSelected}
          onClickedItemHook={onGameItemClick}
          navigateToGameDetailsPage={false}
        />
      </LaunchContentContainer>
    );
  }

  function isRightButtonEnabled() {
    /** On Game Selection mode, only enable the right (Launch)
     * button when we are displaying the current 'Current Games' filter
     * and a game has been selected to launch
     */
    if (selectionMode === SelectionMode.GAME) {
      return gameOption === GamesTabs.TODAY && gamesSelected.length;
    } else {
      /** On Season Selection mode, only enable the right (Next)
       * button when one or more seasons have been selected
       */
      return !!seasonsSelected.length;
    }
  }

  function isLeftButtonEnabled() {
    /** On Game Selection mode, always allow the user to go (Back) */
    if (selectionMode === SelectionMode.GAME) {
      return true;
    } else {
      /** On Season Selection mode, I'm not sure */
      return false;
    }
  }

  function getAllSelectableSeasons() {
    switch (selectionMode) {
      case SelectionMode.SEASON:
        switch (seasonOption) {
          case SeasonFilterDisplayOptions.CURRENT:
            return seasonsCurrent;
          case SeasonFilterDisplayOptions.UPCOMING:
            return seasonsUpcoming;
          case SeasonFilterDisplayOptions.PAST:
            return seasonsPast;
          default:
            return [];
        }
      case SelectionMode.GAME:
      default:
        return [];
    }
  }

  /**
   * Determines when the selectall button should show enable
   */
  function isSelectAllEnabled() {
    /** Get all selectable elements in this filter option (current, past, upcoming) */
    var allSelectableElements = getAllSelectableSeasons();
    /** Check if all elements are already selected, if not enable select all */
    for (let index = 0; index < allSelectableElements.length; index++) {
      const element = allSelectableElements[index];
      if (!seasonsSelected.includes(element.id)) {
        return true;
      }
    }
    /** If all elements are selected, disable select all */
    return false;
  }

  /**
   * Determines when the deselect all button should show enable
   */
  function isDeselectAllEnabled() {
    /** Get all selectable elements in this filter option (current, past, upcoming) */
    var allSelectableElements = getAllSelectableSeasons();
    /** Check if all any is already selected, if so enable deselect all */
    for (let index = 0; index < allSelectableElements.length; index++) {
      const element = allSelectableElements[index];
      if (seasonsSelected.includes(element.id)) {
        return true;
      }
    }
    /** If all elements are selected, disable select all */
    return false;
  }

  /**
   * Select all Seasons (Current, Past, Upcoming)
   */
  async function selectAllSeasons() {
    /** Get all selectable elements in this filter option (current, past, upcoming) */
    var allSelectableElements = getAllSelectableSeasons();
    let tempSelectedSeasons = seasonsSelected;
    /** Check if all elements are already selected, if not 'select it' */
    for (let index = 0; index < allSelectableElements.length; index++) {
      const element = allSelectableElements[index];
      if (!seasonsSelected.includes(element.id)) {
        tempSelectedSeasons = [...tempSelectedSeasons, element.id];
      }
    }
    setSeasonsSelected(tempSelectedSeasons);
  }

  /**
   * Clear Season selection
   */
  function deselectAllSeasons() {
    /** Get all selectable elements in this filter option (current, past, upcoming) */
    var allSelectableElements = getAllSelectableSeasons();
    let tempSelectedSeasons = seasonsSelected;
    /** Remove each selectable element from the selected list */
    for (let index = 0; index < allSelectableElements.length; index++) {
      const element = allSelectableElements[index];
      if (tempSelectedSeasons.includes(element.id)) {
        tempSelectedSeasons = tempSelectedSeasons.filter(
          (id) => id !== element.id
        );
      }
    }
    setSeasonsSelected(tempSelectedSeasons);
  }

  /**
   * Subtitle to be displayed on the page layout
   */
  function getSubtitle() {
    if (selectionMode === SelectionMode.GAME) {
      if (gameOption === GamesTabs.TODAY) {
        return 'Select and launch a game';
      } else if (gameOption === GamesTabs.PAST) {
        return 'View past games';
      } else if (gameOption === GamesTabs.UPCOMING) {
        return 'View upcoming games';
      }
    } else {
      return 'Select one or more seasons below';
    }
  }

  /**
   * Sync expressions get evaluated whenever DataStore starts. In order to have
   * your expressions reevaluated, you can execute DataStore.clear() or
   * DataStore.stop() followed by DataStore.start().
   *
   * https://docs.amplify.aws/lib/datastore/sync/q/platform/js/#reevaluate-expressions-at-runtime
   */
  const clearDataStore = async () => {
    await DataStore.stop();
    await DataStore.clear();
    await DataStore.start();
  };

  /**
   * Launch the ScoreKeeper or ClockManager
   * Decided based off of the user role of the selected game
   */
  function launchGameInterface() {
    datastoreReadyListener();
    clearDataStore();
  }

  /**
   * Listen for the datastore 'ready' events.
   * After the datastore is restarted and the sync expressions are
   * re-evaluated it will throw an event when the model data is up
   * to date. Then we are ready to start the CM or SK interfaces
   */
  const datastoreReadyListener = Hub.listen('datastore', (hubData) => {
    const { event } = hubData.payload;
    if (event === 'ready') {
      gameSelected?.role &&
        history.push(
          gameSelected.role === SCOREKEEPER
            ? '/scorekeeper'
            : gameSelected.role === CLOCKMANAGER
            ? '/clockmanager'
            : '/'
        );
      setShowSpinner(false);
    }
  });

  /**
   * Check to see if the GameTimer exists by calling the time server endpoint
   * @returns true of 200 response, else false
   */
  async function doesGameTimerExist() {
    const response = await clockTimerStatus(gameSelected.id, true);
    log.debug('Response clockTimerStatus:', response);
    return response?.status === 200;
  }

  /**
   * Attempts to create a new game timer
   * Enables a toast notification on a network or create error
   * @returns true if 200 response, else false
   */
  async function createNewGameTimer() {
    const response = await createGameTimer(
      gameSelected.id,
      gameSelected.leagueNumPeriods,
      gameSelected.leagueTimePerPeriodInMins,
      gameSelected.leagueOvertimeDurationInMins,
      true
    );
    log.debug('API Response /createGameTimer:', response);
    if (response.status === 200) {
      return true;
    } else if (response.status === undefined) {
      log.warn('Cannot create game timer, no internet connectivity');
      setToastText('OFFLINE - Please connect to WiFi to Launch Game');
      setShowToast(true);
      return false;
    } else {
      log.warn('Cannot create game timer, api error', response);
      setToastText(
        'ERROR - Please reload this Page and try again. If the issue persist, please contact System Admin.'
      );
      setShowToast(true);
      return false;
    }
  }

  async function isGameEndedOrCanceled(gameId) {
    const response = await getGame(gameId);
    log.debug('GetGame Query Response:', response);
    if (response?.gameStatus) {
      return [GAME_STATUS.ENDED, GAME_STATUS.CANCELED].includes(
        response.gameStatus
      );
    } else {
      log.warn('Error getting game information,');
      setShowToast(true);
      return false;
    }
  }

  const handleNav = async () => {
    /** Selected Game to Launch */
    /** Store game id in local storage */
    const gameString = JSON.stringify(gameSelected);
    localStorage.setItem('currentGame', gameString);

    /** Show the loading spinner until an error occurs creating the timer
     * or until the interfaces are loaded */
    setShowSpinner(true);

    /** Check if a timer already exists before creating one */
    if (await doesGameTimerExist()) {
      log.debug('GameTimer already Exists');
      launchGameInterface();
    } else {
      log.debug('GameTimer does NOT Exist');
      /** Check if the game is finished (cancelled or ended) before creating one */
      if (await isGameEndedOrCanceled(gameSelected.id)) {
        log.debug('Game is Ended or Canceled, not creating new GameTimer');
        launchGameInterface();
      } else {
        /** Create a new game timer */
        if (await createNewGameTimer()) {
          log.info('GameTimer Created Successfully');
          launchGameInterface();
        } else {
          log.warn('Create GameTimer Failed');
          setShowSpinner(false);
        }
      }
    }
  };

  return (
    <BodyStyled>
      <LaunchLayout
        title="Launch Game"
        subTitle={getSubtitle()}
        leftButtonLabel="Back"
        leftButtonHandler={() => {
          showSelectSeason();
        }}
        leftButtonEnabled={isLeftButtonEnabled()}
        rightButtonLabel={
          selectionMode === SelectionMode.GAME ? 'Launch' : 'Next'
        }
        rightButtonHandler={() => {
          selectionMode === SelectionMode.SEASON
            ? showSelectGame()
            : handleNav();
        }}
        rightButtonEnabled={isRightButtonEnabled()}
        leftButtonHidden={selectionMode === SelectionMode.GAME ? false : true}
        rightButtonHidden={
          selectionMode === SelectionMode.GAME &&
          (gameOption === GamesTabs.PAST || gameOption === GamesTabs.UPCOMING)
        }
      >
        {/* Spinner */}
        {showSpinner && (
          <Spinner
            animation="border"
            role="status"
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {/** SEASON Edit Content */}
        {!showSpinner && selectionMode === SelectionMode.SEASON && (
          <>
            {/* Season Filter Buttons (Current, Upcoming, Past) */}
            <SeasonFilterButtons />
            {/* Empty Content Message */}
            {!seasonsDisplayed.length && <NoGamesMessage />}
            {/* Season Content (Selectable Cards and Searchbar) */}
            {!!seasonsDisplayed.length && <SeasonSelectionContent />}
          </>
        )}
        {/** GAME Edit Content */}
        {!showSpinner && selectionMode === SelectionMode.GAME && (
          <>
            {/* Game Filter Buttons (Current, Upcoming, Past) */}
            <GameFilterButtons />
            {/* Empty Content Message */}
            {!gamesDisplayed.length && <NoGamesMessage />}
            {/* Game Content (Selectable Cards and Searchbar) */}
            {!!gamesDisplayed.length && <GameSelectionContent />}
          </>
        )}
      </LaunchLayout>
      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={'danger'}
        delay={8000}
      />
    </BodyStyled>
  );
};

export default GameChoicePage;
