import { useCallback, useEffect, useRef } from 'react';
import { detectMobileMedia as checkIsMobile } from '../styles';

const addScrollListClass = (
  scrollListElement,
  scrollListClass = 'list-items-scroll'
) => {
  scrollListElement?.classList.add(scrollListClass);
};

const removeScrollListClass = (scrollListElement, scrollListClass) => {
  scrollListElement?.classList.remove(scrollListClass);
};

const useListScroll = (data, scrollListClass = 'ipad-page-list-Items') => {
  const listRef = useRef();
  const scrollTimer = useRef();
  const clearScrollTimer = () => {
    if (scrollTimer.current) {
      clearTimeout(scrollTimer.current);
    }
  };
  const isMobile = useRef();
  const updateIsMobile = () => {
    isMobile.current = checkIsMobile();
  };

  useEffect(() => {
    updateIsMobile();

    if (data?.length > 0) {
      if (!isMobile.current) {
        addScrollListClass(listRef.current);
      }
    }
  }, [data]);

  useEffect(() => () => clearScrollTimer(), []);

  const onListScrollHandler = useCallback(() => {
    if (isMobile.current) {
      clearScrollTimer();

      if (listRef.current?.classList.contains(scrollListClass) === false) {
        addScrollListClass(listRef.current, scrollListClass);
      }

      scrollTimer.current = setTimeout(() => {
        removeScrollListClass(listRef.current, scrollListClass);
      }, 200);
    }
  }, [scrollListClass]);

  return {
    ref: listRef,
    onScroll: onListScrollHandler,
  };
};

export default useListScroll;
