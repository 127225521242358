/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Hub, Auth, Amplify } from 'aws-amplify';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { MemoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { QueryClient, QueryClientProvider } from 'react-query';

import config from './aws-exports';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import store from './store.js';
import './styles/scss/bootstrap_modified.scss';
import './index.css';
import {
  CareerPlayerStats,
  GamePlayerStats,
  GameTeamStats,
  SeasonPlayerStats,
  SeasonTeamStats,
  CareerTeamStats,
  Event,
  GameUser,
  User,
  Sport,
  League,
  Season,
  Game,
  Team,
  GameLineupPlayer,
  GameLineup,
  Venue,
  Player,
} from '../src/models';
import logger from './logger';

const log = logger('App', 'debug');

Auth.configure(config);
Amplify.configure(config);

// Create a React Query client
const queryClient = new QueryClient();

/** Gets and stores the size of the display area - accounting for the browser bar */
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

/** This prevents the zooming in/out on iOS browsers that ignore the user-scalable=no meta flag */
document.addEventListener('touchmove', function (event) {
  if (event.scale !== undefined && event.scale !== 1) { event.preventDefault(); }
}, { passive: false });

// THIS IS TO AVOID SYNCING STAT TABLES. DO NOT TOUCH. SYNCING STAT TABLES WILL CAUSE ERRORS.
// const getCurrentGame = async () => {
//   const gameId = localStorage.getItem('currentGame');
//   const currentGame = getGame(gameId);
//   return currentGame;
// };

DataStore.configure({
  syncExpressions: [
    syncExpression(Sport, () => {
      return (post) => post.id('eq', 0);
    }),

    syncExpression(League, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) => post.id('eq', parsedGame?.leagueId);
    }),

    syncExpression(Season, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) => post.id('eq', parsedGame?.seasonId);
    }),

    syncExpression(Game, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) => post.id('eq', parsedGame?.id);
    }),

    syncExpression(Team, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) =>
        post.or((post) =>
          post.id('eq', parsedGame?.awayTeamId).id('eq', parsedGame?.homeTeamId)
        );
    }),

    syncExpression(GameLineupPlayer, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) =>
        post.or((post) =>
          post
            .gameLineupId('eq', parsedGame?.awayTeamGameLineupId)
            .gameLineupId('eq', parsedGame?.homeTeamGameLineupId)
        );
    }),

    syncExpression(GameLineup, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) =>
        post.or((post) =>
          post
            .id('eq', parsedGame?.awayTeamGameLineupId)
            .id('eq', parsedGame?.homeTeamGameLineupId)
        );
    }),

    syncExpression(Venue, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) => post.id('eq', parsedGame?.venueId);
    }),

    syncExpression(GameUser, () => {
      return (post) => post.id('eq', 0);
    }),

    syncExpression(User, () => {
      return (post) => post.id('eq', 0);
    }),

    syncExpression(Player, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) =>
        post.or((post) =>
          post
            .teamId('eq', parsedGame?.awayTeamId)
            .teamId('eq', parsedGame?.homeTeamId)
        );
    }),

    syncExpression(CareerPlayerStats, () => {
      return (post) => post.id('eq', 0);
    }),

    syncExpression(GamePlayerStats, () => {
      return (post) => post.id('eq', 0);
    }),
    syncExpression(GameTeamStats, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      return (post) => post.id('eq', parsedGame?.id);
    }),
    syncExpression(SeasonPlayerStats, () => {
      return (post) => post.id('eq', 0);
    }),
    syncExpression(SeasonTeamStats, () => {
      return (post) => post.id('eq', 0);
    }),
    syncExpression(CareerTeamStats, () => {
      return (post) => post.id('eq', 0);
    }),

    syncExpression(Event, () => {
      const game = localStorage.getItem('currentGame');
      const parsedGame = JSON.parse(game);
      log.debug(`Syncing Events with gameId ${parsedGame?.id}`);
      /* The comparator operator 'eq' appears not to work correctly
       * with this non-key field 'gameId' but 'contains', and 'beginsWith'
       * both work in its place. */
      return (post) => post.gameId('beginsWith', parsedGame?.id);
    }),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <DndProvider
            backend={TouchBackend}
            options={{
              enableMouseEvents: true,
              touchSlop: 10,
            }}
          >
            <div className="message">
              Please change screen orientation to Landscape mode.
            </div>
            <App />
          </DndProvider>
        </QueryClientProvider>
      </Provider>
    </MemoryRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
