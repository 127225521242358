import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  createGameLineupPlayer,
  deleteGameLineupPlayer,
  updateGameLineupPlayer,
} from '../../../api/gamesService';
import {
  gameLineupKeys,
  useGetGameLineupQuery,
} from '../../../api/gamesQueries';
import { ItemTypes } from '../ItemTypes';
import { useGetTeamPlayersQuery } from '../../../api/teamsQueries';
const remove = require('lodash.remove');
const log = require('../../../logger')('AddPlayersModal queries'); // eslint-disable-line no-unused-vars

/**
 * Used when moving moving players Lineup -> Roster
 *
 * @param {*} homeTeamGameLineupId
 * @param {*} awayTeamGameLineupId
 * @returns
 */
export const useDeletePlayerMutation = (
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  markPlayerRecentlyMoved
) => {
  const queryKey = gameLineupKeys.gameLineupPair(
    homeTeamGameLineupId,
    awayTeamGameLineupId
  );
  const queryClient = useQueryClient();
  
  return useMutation(
    ({ playerId, version }) => deleteGameLineupPlayer(playerId, version),
    {
      // retry: 2,
      onMutate: async (item) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(queryKey);
        const homeTeamLineup = [...previousData.homeTeamLineup];
        const awayTeamLineup = [...previousData.awayTeamLineup];
        let [player] = remove(
          homeTeamLineup,
          (player) => player.id === item.playerId
        );
        if (!player)
          [player] = remove(
            awayTeamLineup,
            (player) => player.id === item.playerId
          );

        // Optimistically set expected post-transaction data
        queryClient.setQueryData(queryKey, (old) => ({
          homeTeamLineup,
          awayTeamLineup,
        }));

        return { previousData };
      },
      onSuccess: ({ data }) => {
        const playerId = data?.deleteGameLineupPlayer?.playerId;
        if (playerId) {
          markPlayerRecentlyMoved(playerId);
        }
      },
      onError: ({ previousData }) => {
        queryClient.setQueryData(queryKey, previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

/**
 * Used when moving players Roster -> Lineup
 *
 * @param {*} homeTeamGameLineupId
 * @param {*} awayTeamGameLineupId
 * @param {*} lineup
 * @returns
 */
export const useUpdatePlayerFromRosterMutation = (
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  homeTeamId,
  awayTeamId,
  markPlayerRecentlyMoved
) => {
  const queryClient = useQueryClient();
  const queryKey = gameLineupKeys.gameLineupPair(
    homeTeamGameLineupId,
    awayTeamGameLineupId
  );

  const {
    data: { homeTeamLineup, awayTeamLineup },
  } = useGetGameLineupQuery(homeTeamGameLineupId, awayTeamGameLineupId);

  const { data: homeTeamPlayers } = useGetTeamPlayersQuery(homeTeamId);
  const { data: awayTeamPlayers } = useGetTeamPlayersQuery(awayTeamId);

  const updatePlayerFromRoster = useCallback(
    async ({ player, playerId, type, version }) => {
      const teamLineup =
        player?.teamId === homeTeamId ? homeTeamLineup : awayTeamLineup;
      const gameLineupId =
        player?.teamId === homeTeamId
          ? homeTeamGameLineupId
          : awayTeamGameLineupId;

      const gameLineupPlayerIds = teamLineup.map(({ playerId }) => playerId);
      if (!gameLineupPlayerIds.includes(player.playerId)) {
        return await createGameLineupPlayer({
          gameLineupId,
          player,
          playerOnCourtBenchStatus: ItemTypes.LINEUP,
        });
      }
    },
    [
      awayTeamGameLineupId,
      awayTeamLineup,
      homeTeamGameLineupId,
      homeTeamId,
      homeTeamLineup,
    ]
  );

  return useMutation(updatePlayerFromRoster, {
    onMutate: async (item) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey);
      const homeTeamLineup = [...previousData.homeTeamLineup];
      const awayTeamLineup = [...previousData.awayTeamLineup];

      const player = homeTeamPlayers.find(
        (player) => player.playerId === item.playerId
      );

      if (player) {
        const newPlayer = {
          ...player,
          gameLineupId: homeTeamGameLineupId,
          playerOnCourtBenchStatus: ItemTypes.LINEUP,
          isMutating: true,
        };
        homeTeamLineup.push(newPlayer);
      } else {
        const player = awayTeamPlayers.find(
          (player) => player.playerId === item.playerId
        );

        const newPlayer = {
          ...player,
          gameLineupId: awayTeamGameLineupId,
          playerOnCourtBenchStatus: ItemTypes.LINEUP,
          isMutating: true,
        };

        awayTeamLineup.push(newPlayer);
      }

      queryClient.setQueryData(queryKey, (old) => ({
        homeTeamLineup,
        awayTeamLineup,
      }));

      // Return a context object with the snapshotted value
      return { previousData };
    },
    onSuccess: ({ data }) => {
      const playerId = data?.createGameLineupPlayer?.playerId;
      if (playerId) {
        markPlayerRecentlyMoved(playerId);
      }
    },
    onError: ({ previousData }) => {
      queryClient.setQueryData(queryKey, previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

/**
 * Used when moving players from Lineup -> Court/Bench
 *
 * @param {*} homeTeamGameLineupId
 * @param {*} awayTeamGameLineupId
 * @param {*} playerOnCourtBenchStatus
 * @returns
 */
export const useUpdatePlayerMutation = (
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  playerOnCourtBenchStatus,
  markPlayerRecentlyMoved
) => {
  const queryClient = useQueryClient();
  const queryKey = gameLineupKeys.gameLineupPair(
    homeTeamGameLineupId,
    awayTeamGameLineupId
  );

  const updatePlayer = useCallback(
    async ({ playerId, version }) =>
      await updateGameLineupPlayer(playerId, playerOnCourtBenchStatus, version),
    [playerOnCourtBenchStatus]
  );

  return useMutation(updatePlayer, {
    // retry: 2,
    onMutate: async (item) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey);

      const mapFunc = (player) =>
        player.id === item.playerId
          ? {
              ...player,
              playerOnCourtBenchStatus,
              isMutating: true,
            }
          : player;

      const homeTeamLineup = previousData.homeTeamLineup.map(mapFunc);
      const awayTeamLineup = previousData.awayTeamLineup.map(mapFunc);

      // Optimistically set expected post-transaction data
      queryClient.setQueryData(queryKey, (old) => ({
        homeTeamLineup,
        awayTeamLineup,
      }));

      return { previousData };
    },
    onSuccess: ({ data }) => {
      const playerId = data?.updateGameLineupPlayer?.playerId;
      if (playerId) {
        markPlayerRecentlyMoved(playerId);
      }
    },
    onError: ({ previousData }) => {
      queryClient.setQueryData(queryKey, previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
