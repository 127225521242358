import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styles';

const EndOfResultsContainer = styled.div`
  width: 100%;
  color: ${colors.GRAY[450]};
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 10px;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    width: 150px;
    border-bottom: 1px solid ${colors.GRAY[150]};
  }
`;
const EndOfResultsText = styled.div`
  margin: 0 30px;
  font-size: 0.9em;
`;

/**
 * Indicator to be displayed at the end of results when lazy loading.
 *
 * Props:
 *    enabled (boolean, defaults to true) - if the component should be shown
 *    timeout (number, defaults to 0) - hides the component after `timeout` ms
 */
const EndOfResultsIndicator = ({ enabled = true, timeout = 0 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (timeout && timeout > 0) {
      const timer = setTimeout(() => setVisible(false), timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  return visible && enabled ? (
    <EndOfResultsContainer>
      <EndOfResultsText>End of Results</EndOfResultsText>
    </EndOfResultsContainer>
  ) : (
    <></>
  );
};

export default EndOfResultsIndicator;
