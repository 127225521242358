import React, { useState } from 'react';
import styled from 'styled-components';
import HeaderRow from '../Navigation/HeaderRow';
import { colors } from '../../styles/colors';

const Grid = styled.div`
  height: 100%;
  width: ${props => `${props.gridWidth}px`};
  transition: width 0.05s linear;
  padding: 20px;
  background-color: ${colors.GRAY[250]};
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'body';
`;

const GridHeader = styled.div`
  grid-area: header;
`;

// Use overflow-x to enable horizontal scrolling on the tables
const GridBody = styled.div`
  grid-area: body;
  // overflow-x: scroll;
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    // hide the scrollbar but keep the functionality
    display: none;
  }
`;

const MainLayout = ({
  title,
  buttonLabel,
  rowButtonLabel,
  rowButtonWidth,
  buttonHandler,
  buttonDisabled, 
  navigationLinkBack,
  children,
}) => {
  const [gridWidth, setGridWidth] = useState();

  const onLoadMain = event => {
    new ResizeObserver(resizeEntry => {
      const mainWidth = resizeEntry[0].target.clientWidth;
      setGridWidth(mainWidth);
    }).observe(event.currentTarget.parentElement);
  };

  return (
    <Grid gridWidth={gridWidth} onLoad={onLoadMain}>
      <GridHeader>
        <HeaderRow
          title={title}
          buttonLabel={buttonLabel}
          rowButtonLabel={rowButtonLabel}
          rowButtonWidth={rowButtonWidth}
          buttonHandler={buttonHandler}
          buttonDisabled={buttonDisabled}
          navigationLinkBack={navigationLinkBack}
        />
      </GridHeader>
      <GridBody>{children}</GridBody>
    </Grid>
  );
};

export default MainLayout;
