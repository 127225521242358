import { buttonEvent } from './scoreKeeperEventSlice';
import InputEvents from './inputEvents';

export const DisplayType = {
  /** Enables the action buttons */
  ACTION: 'action',
  /** Displays form for editing a past play */
  EDIT: 'edit',
  /** Enables player buttons for both teams */
  PLAYER: 'player',
  /** Enables player buttons for team in possesion */
  PLAYER_OFFENSE: 'player_offense',
  PLAYER_OFFENSE_SECONDARY: 'player_offense_secondary',
  /** Enables player buttons for team out of possesion */
  PLAYER_DEFENSE: 'player_defense',
  PLAYER_DEFENSE_SECONDARY: 'player_defense_secondary',
  /** Displays map to select shot location */
  LOCATION_2PT: 'location_two_point',
  LOCATION_3PT: 'location_three_point',
  /** Displays a 'Assist' and 'No Assist' button player buttons for team in possesion */
  ASSIST: 'assist',
  /** Displays a 'Block' and 'No Block' button player buttons for team in possesion */
  BLOCK: 'block',
  /** Displays a 'No Rebound' button and enables player buttons for team in possesion and out of possesion */
  REBOUND: 'rebound',
  /** Displays a 'No Steal' button and enables player buttons for team out of possesion */
  STEAL: 'steal',
  /** Displays a button for each team */
  TURNOVER: 'turnover',
  /** Displays buttons for after a free throw was made */
  FREE_THROW_FOLLOW_UP: 'FREE_THROW_FOLLOW_UP',
  FREE_THROW_MISS_FOLLOW_UP: 'FREE_THROW_MISS_FOLLOW_UP',
  VIOLATION: 'violation',
};

export function isPrimaryPlayerSelectType(displayType) {
  return [
    DisplayType.PLAYER,
    DisplayType.PLAYER_OFFENSE,
    DisplayType.PLAYER_DEFENSE,
  ].includes(displayType);
}

export function shouldShowActionButtons(displayType) {
  return displayType === DisplayType.ACTION;
}

export function shouldShowPlayerButtons(displayType) {
  return [
    DisplayType.PLAYER,
    DisplayType.PLAYER_OFFENSE,
    DisplayType.PLAYER_DEFENSE,
    DisplayType.PLAYER_OFFENSE_SECONDARY,
    DisplayType.PLAYER_DEFENSE_SECONDARY,
  ].includes(displayType);
}

export function shouldShowPlayerOffenseButtons(
  displayType,
  playerToDisplay,
  playerPrimarySelected
) {
  return (
    displayType === DisplayType.PLAYER ||
    displayType === DisplayType.PLAYER_OFFENSE ||
    (displayType === DisplayType.PLAYER_OFFENSE_SECONDARY &&
      playerToDisplay !== playerPrimarySelected)
  );
}

export function shouldShowPlayerDefenseButtons(
  displayType,
  playerToDisplay,
  playerPrimarySelected
) {
  return (
    displayType === DisplayType.PLAYER ||
    displayType === DisplayType.PLAYER_DEFENSE ||
    (displayType === DisplayType.PLAYER_DEFENSE_SECONDARY &&
      playerToDisplay !== playerPrimarySelected)
  );
}

export function shouldShowOptionsButtons(displayType) {
  return [
    DisplayType.ASSIST,
    DisplayType.BLOCK,
    DisplayType.REBOUND,
    DisplayType.TURNOVER,
  ].includes(displayType);
}

/** 
 * Hide cancel button for Court Location screens and Edit forms, 
 * because they're rendered within the component instaed 
 */
export function shouldShowCancelButton(displayType) {
  return ![
    DisplayType.LOCATION_2PT,
    DisplayType.LOCATION_3PT,
    DisplayType.EDIT,
  ].includes(displayType);
}

export function shouldShowFreeThrowFollowUp(displayType) {
  return [DisplayType.FREE_THROW_FOLLOW_UP, DisplayType.FREE_THROW_MISS_FOLLOW_UP].includes(displayType);
}

export function shouldShowFreeThrowMissFollowUp(displayType) {
  return [DisplayType.FREE_THROW_MISS_FOLLOW_UP].includes(displayType);
}

export function shouldShowViolationScreen(displayType) {
  return [DisplayType.VIOLATION].includes(displayType);
}

export function shouldShowCourtSelection(displayType) {
  return [DisplayType.LOCATION_2PT, DisplayType.LOCATION_3PT].includes(
    displayType
  );
}

export function showLocation2PT(displayType) {
  return [DisplayType.LOCATION_2PT].includes(displayType);
}

export function showLocation3PT(displayType) {
  return [DisplayType.LOCATION_3PT].includes(displayType);
}

export function getEventOptions(displayType, dispatch) {
  switch (displayType) {
    case DisplayType.ASSIST:
      return [
        {
          onClick: () => dispatch(buttonEvent(InputEvents.ASSIST)),
          primaryLabel: 'Assist',
          secondaryLabel: null,
        },
      ];
    case DisplayType.REBOUND:
      return [
        {
          onClick: () => dispatch(buttonEvent(InputEvents.REBOUND_OFFENSIVE)),
          primaryLabel: 'OFF',
          secondaryLabel: 'Rebound',
        },
        {
          onClick: () => dispatch(buttonEvent(InputEvents.REBOUND_DEFENSIVE)),
          primaryLabel: 'DEF',
          secondaryLabel: 'Rebound',
        },
      ];
    case DisplayType.TURNOVER:
      return [
        {
          onClick: () => dispatch(buttonEvent(InputEvents.TURNOVER)),
          primaryLabel: null,
          secondaryLabel: 'Turnover',
        },
      ];
    case DisplayType.VIOLATION:
      return [
        /** Traveling */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION_TRAVELING)),
          primaryLabel: '',
          secondaryLabel: 'Traveling',
        },
        /** Offensive Goaltending */
        {
          onClick: () =>
            dispatch(buttonEvent(InputEvents.VIOLATION_OFF_GOALTENDING)),
          primaryLabel: '',
          secondaryLabel: 'OFF Goaltending',
        },
        /** Carrying */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION_CARRYING)),
          primaryLabel: '',
          secondaryLabel: 'Carrying',
        },
        /** 3 Seconds */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION_3_SECONDS)),
          primaryLabel: '',
          secondaryLabel: '3 Seconds',
        },
        /** Double Dribble */
        {
          onClick: () =>
            dispatch(buttonEvent(InputEvents.VIOLATION_DOUBLE_DRIBBLE)),
          primaryLabel: '',
          secondaryLabel: 'Double Dribble',
        },
        /** 5 Seconds */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION_5_SECONDS)),
          primaryLabel: '',
          secondaryLabel: '5 Seconds (Inbounding)',
        },
        /** Backcourt */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION_BACKCOURT)),
          primaryLabel: '',
          secondaryLabel: 'Backcourt',
        },
        /** 10 Seconds */
        {
          onClick: () =>
            dispatch(buttonEvent(InputEvents.VIOLATION_10_SECONDS)),
          primaryLabel: '',
          secondaryLabel: '10 Seconds',
        },
      ];
    case DisplayType.FREE_THROW_FOLLOW_UP:
      return [
        /** FT Made */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.SHOT_FT_MADE)),
          primaryLabel: '',
          secondaryLabel: 'FT Made',
        },
        /** FT Miss */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.SHOT_FT_MISS)),
          primaryLabel: '',
          secondaryLabel: 'FT Miss',
        },
        /** FT Violation */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION)),
          primaryLabel: '',
          secondaryLabel: 'FT Violation',
        },
      ];
    case DisplayType.FREE_THROW_MISS_FOLLOW_UP:
      return [
        /** FT Made */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.SHOT_FT_MADE)),
          primaryLabel: '',
          secondaryLabel: 'FT Made',
        },
        /** FT Miss */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.SHOT_FT_MISS)),
          primaryLabel: '',
          secondaryLabel: 'FT Miss',
        },
        {
          onClick: () => dispatch(buttonEvent(InputEvents.REBOUND_OFFENSIVE)),
          primaryLabel: 'OFF',
          secondaryLabel: 'Rebound',
        },
        {
          onClick: () => dispatch(buttonEvent(InputEvents.REBOUND_DEFENSIVE)),
          primaryLabel: 'DEF',
          secondaryLabel: 'Rebound',
        },
        /** FT Violation */
        {
          onClick: () => dispatch(buttonEvent(InputEvents.VIOLATION)),
          primaryLabel: '',
          secondaryLabel: 'FT Violation',
        },
      ];
    default:
      return [
        {
          onClick: () => {},
          primaryLabel: 'Invalid State',
          secondaryLabel: null,
        },
      ];
  }
}
