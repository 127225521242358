import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RoleItem = styled(Row)`
  position: absolute;
  background-color: ${props => props.$roleColor};
  width: ${(props) => props.$cardItemWidth}px;
  height: 25px;
  border-radius: 5px 5px 0px 0px;
  border: 2px solid ${props => props.$roleColor};
  margin-top: -2px;
  margin-right: 0px;
  margin-left: 0px;
  align-content: center;
  top: 0px;
  &.selected {
    border: 2px solid #25a9e1;
    border-bottom: 0px solid #91964b;
  }
`;

export const GameRoleSpan = styled.span`
  text-align: center;
  font: normal normal bold 9px/9px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
`;
