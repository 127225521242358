import React, { useLayoutEffect } from 'react';
import { Row } from 'react-bootstrap';
import GameLeftStats from './GameLeftStats';
import GameRightStats from './GameRightStats';
import { GameStatsContext } from './GameStatsContext';
import { SHOT_LOCATION_EVENTS } from '../../../utils/constantsUtils';

const statsContentStyles = {
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const GameStats = ({
  awayTeamImage,
  homeTeamImage,
  awayTeamName,
  homeTeamName,
  awayTeamId,
  homeTeamId,
  awayTeamPlayersGameStats,
  homeTeamPlayersGameStats,
  gameStatsData,
  setStatsTab,
  shotLocationData,
  setIsPlayEventsLoading,
  scrollListRef,
}) => {
  useLayoutEffect(() => {
    setIsPlayEventsLoading(false);
    scrollListRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const allPeriodsShotLocationsData = Object.values(shotLocationData).reduce((updatedShotLocations, currentGamePeriodShotLocations) => [ ...updatedShotLocations, ...currentGamePeriodShotLocations ], []);

  const awayTeamShotMadeLocations = allPeriodsShotLocationsData?.filter(({ teamId, eventType }) => teamId === awayTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE));
  const awayTeamShotMissedLocations = allPeriodsShotLocationsData?.filter(({ teamId, eventType }) => teamId === awayTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED));
  const homeTeamShotMadeLocations = allPeriodsShotLocationsData?.filter(({ teamId, eventType }) => teamId === homeTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE));
  const homeTeamShotMissedLocations = allPeriodsShotLocationsData?.filter(({ teamId, eventType }) => teamId === homeTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED));
  
  return (
    <GameStatsContext.Provider value={{ 
      awayTeamImage, 
      homeTeamImage, 
      awayTeamName, 
      homeTeamName, 
      awayTeamPlayersGameStats, 
      homeTeamPlayersGameStats, 
      setStatsTab, 
      gameStatsData,
      awayTeamShotMadeLocations,
      awayTeamShotMissedLocations,
      homeTeamShotMadeLocations,
      homeTeamShotMissedLocations,
      statsContentStyles, 
    }}>
      <Row style={{ width: '100%', height: 'auto', margin: 0, }} className='d-flex justify-content-center'>
        <GameLeftStats
        />
        <GameRightStats
        />
      </Row>
    </GameStatsContext.Provider>
  )
};

export default GameStats;