import { Route } from 'react-router-dom';
import LeagueIndividualPage from '../../pages/LeagueIndividualPage';
import Routes from './Routes';
import PlayerDetailPage from '../../pages/PlayerDetailPage';
import PlayerProfilePage from '../../pages/PlayerProfilePage';
import TeamPlayersPage from '../../pages/TeamPlayersPage';
import GameDetailsPage from '../../pages/GameDetailsPage';
import UserProfilePage from '../../pages/UserProfilePage';
import GameLineup from '../../pages/GameLineup';
import GameIndividualPage from '../../pages/GameIndividualPage';
import Layout from '../layout/Layout';

const AdminRoute = () => {
  return (
    <Layout>
      {Routes.map((item) => (
        <Route exact path={item.pathName} key={item.pathName}>
          <item.component />
        </Route>
      ))}
      <Route exact path="/admin/leagues/:id">
        <LeagueIndividualPage />
      </Route>      
      <Route exact path="/admin/player/:id">
        <PlayerDetailPage />
      </Route>
      <Route exact path="/admin/gameLineup/:id">
        <GameLineup />
      </Route>
      <Route exact path="/admin/game/:id">
        <GameIndividualPage />
      </Route>
      <Route exact path="/admin/player/profile/:id">
        <PlayerProfilePage />
      </Route>
      <Route exact path="/admin/team/players/:id">
        <TeamPlayersPage />
      </Route>
      <Route exact path="/admin/game/details/:id">
        <GameDetailsPage />
      </Route>
      <Route exact path="/admin/user/profile/:id">
        <UserProfilePage />
      </Route>
    </Layout>
  );
};

export default AdminRoute;
