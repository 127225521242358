import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import ToastAlert from '../components/alerts/ToastAlert';
import MainLayout from '../components/layout/MainLayout';
import PageContainerLayout from '../components/layout/PageContainerLayout';
import AddEditPlayerModal from '../components/Modals/AddEditPlayerModal';
import PlayerProfileContent from '../components/PageContent/PlayerProfileContent';
import { getPlayer, updatePlayer, listPlayers } from '../api/playersService';
import { getTeam, getTeamImageProfile, listTeams } from '../api/teamsService';
import { listGames } from '../api/gamesService';
import { listLeagues } from '../api/leaguesService';
import { getGamesForGameTabs } from '../utils/gameUtil';
import { useQueryClient } from 'react-query';
import { playersKeys } from '../api/playersQueries';

const PlayerProfilePage = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [playerItem, setPlayerItem] = useState();
  const [modalPlayerData, setModalPlayerData] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [createPlayerError, setCreatePlayerError] = useState();

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState();

  useEffect(() => {
    setShowSpinner(true);
    getPlayerProfileInfo(id);
  }, []);

  const handleSubmit = async (values, resetFormFunct) => {
    setShowSpinner(true);
    setOpen(false);

    values.position =
      values.position.indexOf(',') !== -1
        ? values.position.split(',').map((pos) => pos.trim())
        : [values.position.trim()];
    values.height =
      parseFloat(values.heightInFeet) * 12 + parseFloat(values.heightInInches);
    values.teamId = values.teamId.length === 0 ? 'null' : values.teamId;

    delete values.heightInFeet;
    delete values.heightInInches;

    /**
     * Check for duplicate
     */
    const duplicateData = await listPlayers({
      filter: {
        firstName: { eq: values.firstName.toString() },
        lastName: { eq: values.lastName.toString() },
        jerseyNumber: { eq: values.jerseyNumber },
        position: { eq: values.position },
        dateOfBirth: { eq: values.dateOfBirth },
        birthCity: { eq: values.birthCity },
        birthState: { eq: values.birthState },
        birthCountry: { eq: values.birthCountry },
        weight: { eq: values.weight },
        height: { eq: values.height },
        active: { eq: values.active },
        institute: { eq: values.institute },
        teamId: { eq: values.teamId },
        leagueId: { eq: values.leagueId },
      },
    });

    if (duplicateData.errors) {
      /** Error checking for duplicate */
      setCreatePlayerError(
        `Unable to create player. ${duplicateData.errors[0].message}.`
      );
    } else if (duplicateData.length >= 1) {
      const { firstName, lastName, teamId } = values;
      const teamName = teams.find(({ id }) => id === teamId).name;

      /** Duplicate Exists */
      setCreatePlayerError(
        `The player "${firstName} ${lastName}" of "${teamName}" team already exists.`
      );
    } else {
      const updatedPlayerData = await updatePlayer(values);

      if (updatedPlayerData.errors) {
        setCreatePlayerError(
          `Unable to create player. ${updatedPlayerData.errors[0].message}.`
        );
      } else {
        await getPlayerProfileInfo(updatedPlayerData.data.updatePlayer.id);
        resetFormFunct();
        setToastText(
          `The Player "${values.firstName} ${values.lastName}" was updated successfully.`
        );
        queryClient.invalidateQueries(playersKeys.all);
        setShowToast(true);
      }
    }
  };

  const onPlayerManualModalClose = (modalPlayerData) => {
    setCreatePlayerError(null);
    setOpen(false);
    setModalPlayerData({ ...modalPlayerData });
  };

  const getPlayerProfileInfo = async (id) => {
    const player = await getPlayer(id);

    const { firstName, lastName, height, teamId } = player;

    player.name = `${firstName} ${lastName}`;
    player.heightInFeet = Math.floor(height / 12).toString();
    player.heightInInches = (height % 12).toString();

    player.team = teamId != 'null' ? await getTeam(teamId) : { name: 'Free Agent' };

    const games = await listGames();
    const gamesWithTeamImages = await Promise.all(
      games?.map(async (game) => {
        const { homeTeamId, awayTeamId, homeTeamName, awayTeamName } = game;
        game.homeTeamVsAwayTeam = `${homeTeamName} VS ${awayTeamName}`;
        game.homeTeamImage = await getTeamImageProfile(homeTeamId);
        game.awayTeamImage = await getTeamImageProfile(awayTeamId);
        return game;
      })
    );

    const getGames = games => games.filter(({ homeTeamId, awayTeamId }) => (homeTeamId === teamId) || (awayTeamId === teamId));

    const { todaysGames, upcomingGames, pastGames } = getGamesForGameTabs(
      gamesWithTeamImages
    );

    player.teamTodaysGames = getGames(todaysGames);
    player.teamPastGames = getGames(pastGames);
    player.teamUpcomingGames = getGames(upcomingGames);

    const leagueList = await listLeagues();
    leagueList &&
      setLeagues(leagueList.sort((a, b) => a.name.localeCompare(b.name)));

    const teamList = await listTeams();
    teamList.items &&
      setTeams(teamList.items.sort((a, b) => a.name.localeCompare(b.name)));

    const __modalPlayerData = { ...player };
    __modalPlayerData.position = __modalPlayerData.position.join(', ');
    setModalPlayerData(__modalPlayerData);

    setPlayerItem({ ...player });
    setShowSpinner(false);
  };

  return (
    <MainLayout
      title="Player Profile"
      rowButtonLabel="Edit Player"
      buttonHandler={() => setOpen(true)}
      navigationLinkBack={'/admin/players'}
    >
      {showSpinner && (
        <Spinner
          animation="border"
          role="status"
          style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 100 }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      {modalPlayerData && (
        <AddEditPlayerModal
          isNewPlayer={false}
          playerData={modalPlayerData}
          leagueList={leagues}
          teamList={teams}
          modalOpen={open}
          setModalOpen={setOpen}
          onSubmit={handleSubmit}
          onClose={() => onPlayerManualModalClose(modalPlayerData)}
          error={createPlayerError}
        />
      )}

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={'success'}
      />

      <PageContainerLayout>
        {!showSpinner && playerItem && (
          <PlayerProfileContent playerItem={playerItem} />
        )}
      </PageContainerLayout>
    </MainLayout>
  );
};

export default PlayerProfilePage;
