export const ADMIN = 'Admin';
export const SCOREKEEPER = 'ScoreKeepers';
export const CLOCKMANAGER = 'ClockManagers';
export const COACH = 'Coach';
export const SUPERUSER = 'SuperUser';

export const roles = [{ name: CLOCKMANAGER }, { name: SCOREKEEPER }, { name: COACH }];

export const ROLE_COLOR = {
  [CLOCKMANAGER]: '#008d39',
  [SCOREKEEPER]: '#c78c00',
  [COACH]: '#184453',
};

export const rolesWithAdmin = [
  { name: ADMIN },
  { name: CLOCKMANAGER },
  { name: SCOREKEEPER },
];
