import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import ToastAlert from '../components/alerts/ToastAlert';
import PageContainerLayout from "../components/layout/PageContainerLayout";
import MainLayout from "../components/layout/MainLayout";
import TeamPlayersContent from "../components/PageContent/TeamPlayersContent";
import AddRemoveTeamPlayersModal from '../components/Modals/AddRemoveTeamPlayersModal';
import { 
  updatePlayer, 
} from "../api/playersService";
import {
  getTeam,
} from "../api/teamsService";
import {
  listPlayers,
  getPlayerImageProfile, 
} from '../api/playersService';
import { PENDING_CHANGE_LEAGUE_ID } from '../utils/constantsUtils';

const TeamPlayersPage = () => {
  const { id } = useParams();

  const [ open, setOpen ] = useState(false);
  const [ team, setTeam ] = useState();
  const [ players, setPlayers ] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [createTeamProfileError, setCreateTeamProfileError] = useState();

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');

  useEffect(() => {
    setShowSpinner(true);
    setTeamFromId(id);
  }, []);

  useEffect(() => {
    if(team) {
      const { leagueId } = team;
      getAllPlayers(leagueId);
    }
  }, [team]);

  const setTeamFromId = async id => {
    const team = await getTeam(id);
    const { players, league } = team;
    team.players.items = await getTeamPlayersHeadshots(players.items, league);
    setTeam({...team});
  };

  const getTeamPlayersHeadshots = async (players, league) => {
    const playerWithHeadshots = await Promise.all(
      players.map(async (player) => {
        player.image = await getPlayerImageProfile(player.id);
        player.team = await getTeam(player.teamId);
        player.league = league;
        return player;      
      })
    );
    return playerWithHeadshots;
  };

  const getAllPlayers = async teamLeagueId => {
    const allPlayers = await listPlayers();
    const playersNotOnTeam = allPlayers.items.filter(({ teamId, leagueId }) => teamId === 'null' && teamLeagueId === leagueId)
                                       .sort((a, b) => parseInt(a.jerseyNumber) - parseInt(b.jerseyNumber));

    playersNotOnTeam.map(player => {
      player.team = { name: 'Free Agent' };
      return player;
    });

    setPlayers(playersNotOnTeam);
    setShowSpinner(false);
  };

  const handleSubmit = async (addedTeamPlayers, removedTeamPlayers, team, resetForm) => {
    setOpen(false);
    setShowSpinner(true);

    const getPlayerObjectsForUpdate = (teamPlayers, _teamId, _leagueId) => {
      return teamPlayers.map(({
        id, 
        active, 
        jerseyNumber, 
        teamId, 
        leagueId, 
        position, 
        weight, 
        height,
        _version, 
      }) => ({
        id, 
        active, 
        jerseyNumber, 
        teamId: _teamId, 
        leagueId: _leagueId || leagueId, 
        position, 
        weight,
        height, 
        _version, 
      }));
    };

    const getUpdatedTeamPlayers = async (teamPlayers, teamId, leagueId) => {
      const teamPlayerObjects = getPlayerObjectsForUpdate(teamPlayers, teamId, leagueId);
      const asyncTeamPlayersUpdate = teamPlayerObjects.map(async teamPlayer => await updatePlayer(teamPlayer));
      return await Promise.all(asyncTeamPlayersUpdate);
    };

    if(addedTeamPlayers.length > 0) {
      const addTeamPlayersUpdateResponse = await getUpdatedTeamPlayers(addedTeamPlayers, team.id, team.leagueId);

      if(addTeamPlayersUpdateResponse.errors) {
        setCreateTeamProfileError(`Not able to add players to ${team.name}`);
        return;
      }
    }

    if(removedTeamPlayers.length > 0) {
      const removeTeamPlayersUpdateResponse = await getUpdatedTeamPlayers(removedTeamPlayers, 'null');

      if(removeTeamPlayersUpdateResponse.errors) {
        setCreateTeamProfileError(`Not able to remove players from ${team.name}`);
        return;
      }
    }

    await setTeamFromId(team.id);
    resetForm();
    setCreateTeamProfileError(null);
    setToastText(`The players added to / removed from team ,"${team.name}", was updated successfully.`);
    setShowToast(true);
  };

  return (
    <MainLayout
      title='Team Players'
      buttonLabel='+/- Team Players'
      buttonHandler={() => setOpen(true)}
      buttonDisabled={team?.leagueId === PENDING_CHANGE_LEAGUE_ID}
      navigationLinkBack={'/admin/teams'}
    >
      <AddRemoveTeamPlayersModal
        modalOpen={open}
        setModalOpen={setOpen}
        onSubmit={handleSubmit}
        onClose={() => setOpen(false)}
        players={players}
        team={team}
        error={createTeamProfileError}
      />

      <PageContainerLayout>
        {showSpinner && (
          <Spinner
            animation="border"
            role="status"
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {team && 
        <TeamPlayersContent
          team={team}
        />}
      </PageContainerLayout>

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={'success'}
      />
    </MainLayout>
  );
};

export default TeamPlayersPage;