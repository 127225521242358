/**
 * These are the categories of buttons in the Score Keeper.
 */
export const ActionTypes = {
  Made: {
    label: 'Made',
    color: '#0B9E22',
  },
  Missed: {
    label: 'Missed',
    color: '#FF0000',
  },
  Fouls: {
    label: 'Fouls',
    color: '#FD7643',
  },
  Actions: {
    label: 'Actions',
    color: '#438EFD',
  },
};
