const SeasonValidation = {
  seasonType: {
    isRequired: { value: true, message: 'Season Type is required' },
  },
  startDate: {
    isRequired: { value: true, message: 'Start Date is required' },
  },
  endDate: {
    isRequired: { value: true, message: 'End Date is required' },
  },
  leagueId: {
    isRequired: { value: true, message: 'League is required' },
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' },
  },
};

export default SeasonValidation