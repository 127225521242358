import { API, graphqlOperation } from 'aws-amplify';
import {
  listTeams as listTeamsQuery,
  listTeamUsers as listTeamsUsersQuery,
  getTeam as getTeamQuery,
  getTeamUser as getTeamUserQuery,
  getTeamByUpdatedAt as getTeamByUpdatedAtQuery,
  getTeamUserByUserIdTeamId as getTeamUserByUserIdTeamIdQuery,
} from '../graphql/queries';
import {
  createTeam as createTeamMutation,
  updateTeam as updateTeamMutation,
  deleteTeam as deleteTeamMutation,
  createTeamUser as createTeamUserMutation,
  deleteTeamUser as deleteTeamUserMutation,
} from '../graphql/mutations';
import { Storage } from '@aws-amplify/storage';
import TeamLogoImage from '../assets/Team@2x.png';
import { KEY_SORT_ID } from '../utils/constantsUtils';
const log = require('../logger')('api');

Storage.configure({ level: 'public' });

const saveTeamImageProfile = async (teamId, imageFile) => {
  if (imageFile) {
    try {
      const response = await Storage.put(`team/${teamId}`, imageFile, {
        level: 'public',
      });
      return response;
    } catch (error) {
      log.error(`Unable to save image ${teamId} to storage...`);
    }
  }
};

export const getTeamImageProfile = async (teamId) => {
  try {
    const doesImageExist = await Storage.list(`team/${teamId}`);
    if (doesImageExist.length > 0) {
      const imageURL = await Storage.get(`team/${teamId}`);
      return imageURL;
    } else {
      return TeamLogoImage;
    }
  } catch (error) {
    log.error(`Unable to read image ${teamId} from storage...`);
  }
};


export const listTeams = async (variables = null) => {
  try {
    const teamData = await API.graphql(
      graphqlOperation(listTeamsQuery, variables)
    );
    // const teamData = await DataStore.query(Team);
    return teamData.data.listTeams;
  } catch (err) {
    log.error('error fetching teams...', err);
  }
};
export const listTeamsSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    const teamData = await API.graphql(
      graphqlOperation(getTeamByUpdatedAtQuery, vars)
    );
    return teamData.data.getTeamByUpdatedAt;
  } catch (err) {
    log.error('error fetching teams...', err);
  }
};

export const getTeam = async (id) => {
  try {
    const [teamData, teamImage] = await Promise.all([
      API.graphql(graphqlOperation(getTeamQuery, { id: id })),
      getTeamImageProfile(id),
    ]);
    // const teamData = await DataStore.query(Team, id);
    const teamItem = teamData.data.getTeam;
    teamItem.image = teamImage;
    return teamItem;
  } catch (err) {
    log.error('error fetching team...', err);
  }
};

//create call with parameter

export const createTeam = async (
  name,
  city,
  state,
  leagueId,
  image,
  active
) => {
  try {
    const teamData = await API.graphql(
      graphqlOperation(createTeamMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          name: name,
          city: city,
          state: state,
          leagueId: leagueId,
          active: active,
          searchName: name.toLowerCase(),
          searchCity: city.toLowerCase(),
          searchState: state.toLowerCase(),
        },
      })
    );
    const { id } = teamData.data.createTeam;
    const saveImaged = await saveTeamImageProfile(id, image); // eslint-disable-line no-unused-vars
    // const teamData = await DataStore.save(new Team({ name: name, city: city, mascot: mascot, state: state, description: description, leagueId: leagueId, league: league }));
    return teamData;
  } catch (err) {
    log.error('error creating team...', err);
    return err;
  }
};

export const updateTeam = async (id, values, isPendingUpdate) => {
  try {
    const teamData = await API.graphql(
      graphqlOperation(updateTeamMutation, {
        input: {
          id: id,
          name: values.name,
          city: values.city,
          state: values.state,
          leagueId: values.leagueId,
          active: values.active,
          searchName: values.name.toLowerCase(),
          searchCity: values.city.toLowerCase(),
          searchState: values.state.toLowerCase(),
          _version: values._version,
        },
      })
    );

    if (isPendingUpdate) {
      const pendingUpdateImage = await saveTeamImageProfile(id, values.image); // eslint-disable-line no-unused-vars
    }

    // const original = await DataStore.query(Team, teamId);

    // const teamData = await DataStore.save(
    // 	Team.copyOf(original, updated => {
    // 		updated.name = name;
    // 		updated.description = description;
    // 		updated.city = city;
    // 		updated.state = state;
    // 		updated.mascot = mascot;
    // 		updated.leagueId = leagueId;
    // 	}));

    return teamData;
  } catch (err) {
    log.error('error updating team...', err);
    return err;
  }
};

export const deleteTeam = async (id) => {
  try {
    const teamData = await API.graphql(
      graphqlOperation(deleteTeamMutation, {
        input: {
          id: id,
          _version: 1,
        },
      })
    );
    // const toDelete = await DataStore.query(Team, id);
    // const teamData = await DataStore.delete(toDelete);
    return teamData;
  } catch (err) {
    log.error('error deleting team...', err);
  }
};

export const getTeamUser = async (id) => {
  try {
    const teamUserData = await API.graphql(
      graphqlOperation(getTeamUserQuery, { id })
    );
    return teamUserData.data.getTeamUser;
  }
  catch (error) {
    log.error('error getting the team user....');
    return error;
  }
};

export const listTeamUsers = async (variables = null) => {
  try {
    const teamUsersList = await API.graphql(
      graphqlOperation(listTeamsUsersQuery, variables)
    );
    return teamUsersList.data.listTeamUsers;
  }
  catch (error) {
    log.error('error retrieving team users list...');
    return error;
  }
};

export const getTeamUserByUserIdTeamId = async (userId, teamId) => {
  try {
    const teamUserData = await API.graphql(
      graphqlOperation(getTeamUserByUserIdTeamIdQuery, {  
        userId: userId,
        teamId: {
          eq: teamId
        }
      })
    );
    return teamUserData.data.getTeamUserByUserIdTeamId;
  }
  catch (error) {
    log.error('error getting the team user by userId and teamId....');
  }
};

export const createTeamUser = async (userId, teamId, teamRole, gameId, leagueId, seasonId) => {
  try {
    const teamUser = await API.graphql(
      graphqlOperation(createTeamUserMutation, {
        input: {
          userId,
          teamId,
          gameId,
          seasonId,
          leagueId,
          gameRole: teamRole,
        }
      })
    );
    return teamUser;
  }
  catch (error) {
    log.error('error creating team user...', error);
    return error;
  }
};

export const deleteTeamUser = async (id, _version) => {
  try {
    const deletedTeamUser = await API.graphql(
      graphqlOperation(deleteTeamUserMutation, {
        input: {
          id,
          _version,
        }
      })
    );
    return deletedTeamUser;
  }
  catch (error) {
    log.error('error deleting team user...', error);
    return error;
  }
};