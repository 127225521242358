import React, { useLayoutEffect } from 'react';
import BasketballCourtControl from './BasketballCourtControl';
import PlayByPlayControl from './PlayByPlayControl';

const PlayByPlayStats = ({
  game,
  gameId,
  homeTeamPlayers,
  awayTeamPlayers,
  periodOptions,
  periodEventParams,
  setPeriodEventParams,
  playByPlayEventParams,
  setPlayByPlayEventParams,
  periodColumnWidth,
  teamAssets,
  shotLocationData,
  contentHeight,
  scrollListRef,
}) => {
  useLayoutEffect(() => {
    scrollListRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <BasketballCourtControl
        game={game}
        shotLocationJsonData={shotLocationData}
        homeTeamPlayers={homeTeamPlayers}
        awayTeamPlayers={awayTeamPlayers}
        gamePeriodOptions={periodOptions}
      />
      <PlayByPlayControl
        game={game}
        gameId={gameId}
        periodColumnWidth={periodColumnWidth}
        periodEventParams={periodEventParams}
        playByPlayEventParams={playByPlayEventParams}
        setPlayByPlayEventParams={setPlayByPlayEventParams}
        setPeriodEventParams={setPeriodEventParams}
        teamAssets={teamAssets}
        contentHeight={contentHeight}
      />
    </>
  );
};

export default PlayByPlayStats;