import React from 'react';
import ReactToolTip from 'react-tooltip';

/**
 * 
 * FOR REFERENCES: 
 *    https://github.com/wwayne/react-tooltip
 *    https://wwayne.github.io/react-tooltip/
 *
 */

const Tooltip = ({
  tooltipId, 
  tooltipPosition = 'bottom', // Position can be 'top', 'left', 'right', or 'bottom'. Made it 'bottom' by default.
  tooltipClass,  
  tooltipType = 'info', // Type can be 'info', 'dark', 'success', 'warning', 'error', or 'light'. Made it 'info' by default.
  tooltipTextColor, 
  tooltipBackgroundColor,
  tooltipEffect = 'solid', // Effect can be 'solid' or 'float" Made it 'solid' by default.
  tooltipEvent = 'click', 
  tooltipContent, 
  tooltipBorderColor, 
  tooltipDelayShow = 0, 
  tooltipDelayHide = 0, 
  hasBorder = true, 
  hasCapture = false, 
  afterShowHandler = () => {},
}) => {
  return (
    <ReactToolTip
      id={tooltipId}
      type={tooltipType}
      place={tooltipPosition}
      effect={tooltipEffect}
      event={tooltipEvent}
      globalEventOff={tooltipEvent}
      isCapture={hasCapture}
      insecure={false}
      border={hasBorder}
      borderColor={tooltipBorderColor}
      textColor={tooltipTextColor}
      backgroundColor={tooltipBackgroundColor}
      getContent={() => tooltipContent}
      className={`tooltip ${tooltipClass}`}
      delayShow={tooltipDelayShow}
      delayHide={tooltipDelayHide}
      afterShow={afterShowHandler}
    />
  );
};

export default Tooltip;