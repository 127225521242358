import React, { useState, } from 'react';
import { useSelector } from 'react-redux';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import ToastAlert from '../components/alerts/ToastAlert';
import MainLayout from '../components/layout/MainLayout';
import SquareGrid from '../components/Grids/SquareGrid';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import AddEditUserModal from '../components/Modals/AddEditUserModal';
import AssignGameUserRolesModal from '../components/Modals/AssignGameUserRolesModal';
import UsersContent from '../components/PageContent/UsersContent';
import { colors } from '../styles';
import {
  createUser, 
  createUserGroup, 
  disableUser, 
  getUser,
  listUsersSortedByUpdatedAt,
} from '../api/userService';
import {
  updateGameUser,
  deleteGameUser,
} from '../api/gamesService';
import {
  getGamesByUserId, 
  getGameUserParameters, 
} from '../utils/gameUtil';
import {
  updateUserStatus, 
  updateUserInfoData, 
  updateUserForGame, 
  deleteUserFromGame, 
} from '../utils/userUtil';
import { truncateText } from '../utils/stringUtil';
import { rolesWithAdmin } from '../data/roles'
import Spinner from '../components/Spinner';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { ITEM_TYPE } from '../utils/constantsUtils';
import { userKeys } from '../api/userQueries';

const ROLES = rolesWithAdmin.map(r => r.name);

const initialUserItem = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  active: true,
  acceptedTerms: false,
};

const log = require('../logger')('adminPanel');


/**
 * 
 * @param {string} query 
 * @returns An array of graphQL query filters for the searchFirstName and searchLastName fields
 */
const generateNameFilters = (query) => (
  query.split(' ').reduce((result, curr) => {
    if (!result.prevString) {
      return {
        prevString: curr,
        filters: [
          { searchFirstName: { contains: curr } },
          { searchLastName: { contains: curr } },
        ]
      }
    }

    return {
      prevString: `${result.prevString} ${curr}`,
      filters: [
        ...result.filters,
        { searchFirstName: { contains: `${result.prevString} ${curr}` } },
        { searchLastName: { contains: `${result.prevString} ${curr}` } },
        { searchFirstName: { contains: curr } },
        { searchLastName: { contains: curr } },
      ]
    }
  }, { prevString: '', filters: [] }).filters
)


const UsersPage = () => {
  const queryClient = useQueryClient();
  const [queryFilter, setQueryFilter] = useState("")
  const onSearch = (query) => setQueryFilter(query.toLowerCase());

  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [createUserError, setCreateUserError] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastVariant, setToastVariant] = useState('success')
  const [userItem, setUserItem] = useState(initialUserItem);
  const [userGames, setUserGames] = useState([]);
  const [editedUserData, setEditedUserData] = useState();
  const [openAssignGameRolesModal, setOpenAssignGameRolesModal] = useState(false);
  const [originalRoles, setOriginalRoles] = useState('');
  const [isEditingGameRoles, setIsEditingGameRoles] = useState(false);
  const [isEditingUserProfile, setIsEditingUserProfile] = useState(false);

  const user = useSelector(
    (state) => state.user.value && JSON.parse(state.user.value)
  );
  const token = user.signInUserSession.accessToken.jwtToken;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isSuccess, // eslint-disable-line no-unused-vars
    isLoading,
    isFetching,
  } = useInfiniteQuery(['users', { filter: queryFilter }],
    async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      if (queryFilter.length > 0) {
        // Check if the search text matches any of the roles
        const rolesFilters = ROLES.filter(r => r.toLowerCase().includes(queryFilter))
          .map(r => ({ role: { contains: r } }))
        const nameFilters = generateNameFilters(queryFilter)

        queryVars = {
          filter: {
            or: [
              ...nameFilters,
              ...rolesFilters,
              { email: { contains: queryFilter } },
            ]
          },
          nextToken: pageParam,
        }
      }
      
      const users = await listUsersSortedByUpdatedAt(queryVars);
      const userItems = users.items.map(userItem => {
        userItem.name = `${userItem.firstName} ${userItem.lastName}`;
        return userItem;
      });

      userItems.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));

      return {...users, items: userItems};
    }, {
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    onError: (error) => {
      log.error(error)
      setShowToast(true)
      setToastText('An error occurred while retrieving users.')
      setToastVariant('danger')
    }
  })
  
  // Aggregate users across all query pages
  const users = data?.pages.reduce((items, page) => [...items, ...page.items], []) 

  const transformRole = role => {
    return Array.isArray(role) 
            ? role 
            : role.indexOf(',') != -1 
                ? role.split(',').map(_role => _role.trim()) 
                : [role.trim()];
  };

  const handleSubmit = async (values, resetFormFunct = () => {}) => {
    let { 
      firstName, 
      lastName, 
      email, 
      role, 
      active, 
    } = values;

    firstName = firstName.toString()[0].toUpperCase() + firstName.toString().substring(1);
    lastName = lastName.toString()[0].toUpperCase() + lastName.toString().substring(1);
    role = transformRole(role);

    const alreadyExistingUser = users.find(user => user.email === email);

    if(isCreate) {
      if(alreadyExistingUser) {
        setCreateUserError(`The user with the email address, '${alreadyExistingUser.email}', already exists.`);
        return;
      }
  
      if(!active) {
        await disableUser(email, token);
        active = !active;
      }

      const createdUser = await createUser(firstName, lastName, email, role, active, token);

      if(createdUser.errors) {
        setCreateUserError('Unable to create user.');
        return;
      } else if (createdUser.isAxiosError) {
        setCreateUserError(`Unable to create user. ${createdUser.response.data?.message}`);
        return;
      } else {
        const asyncAddGroup = role.map(async _role => await createUserGroup(email, _role, token));
        await Promise.all(asyncAddGroup);

        setToastText(`"${firstName} ${lastName}" was created successfully.`);
        setToastVariant('success')
      }
    }
    else {
      const id = values.id;
      const _version = values._version;
      const previousUserActive = alreadyExistingUser.active;
      const previousUserRoles  = alreadyExistingUser.role;

      if(previousUserActive != active) {
        const userStatusResponse = await updateUserStatus(active, email, token);

        if(userStatusResponse.error) {
          setCreateUserError(`Unable to ${active ? 'enable' : 'disable'} user.`);
          return;
        }
      }

      const updatedUser = await updateUserInfoData(id, firstName, lastName, role, email, active, _version, previousUserRoles, token);

      if(updatedUser.errors) {
        setCreateUserError('Unable to update user.');
        return;
      }
      else {
        setToastText(`"${firstName} ${lastName}" was updated successfully.`);
        setToastVariant('success')
      }
    }

    resetFormFunct();
    handleClose();

    queryClient.invalidateQueries(userKeys.all)
    setShowToast(true);
    setUserItem(initialUserItem);
  };

  const handleOpen = () => {
    setIsCreate(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCreateUserError(null);
    setUserItem(initialUserItem);
    setOriginalRoles("");
    setIsEditingGameRoles(false);
    setIsEditingUserProfile(false);
  };

  const openAssignGameRoleModal = (updatedValues, resetFormFunc) => {
    const { games } = userItem;
    const role = transformRole(updatedValues.role);
    setEditedUserData({ ...updatedValues, games,  role });
    // From 2-Role
    const isOriginalRolesAdminLike = originalRoles.includes('Admin') || (originalRoles.includes('ClockManagers') && originalRoles.includes('ScoreKeepers'));
    // To 1-Role
    const isClockManagerOrScoreKeeperRole = role.length === 1 && (role[0] === 'ClockManagers' || role[0] === 'ScoreKeepers');
    const areAllGamesOfTheSameRole = userGames.every(game => game.role === role[0]);
    if(isOriginalRolesAdminLike && isClockManagerOrScoreKeeperRole && !areAllGamesOfTheSameRole) {
      const filteredUserGames = userGames.filter(game => game.role !== role[0]);
      setUserGames(filteredUserGames);
    }
    setOpen(false);
    resetFormFunc();
    setCreateUserError(null);
    setOpenAssignGameRolesModal(true);
  };

  const openAddEditUserModalBackHandler = async () => {
    setUserItem({ ...editedUserData, role: editedUserData.role.join(',') });
    setOpenAssignGameRolesModal(false);
    setOpen(true);
    const games = await getGamesAssignedToUser(userItem);
    setUserGames(games);
  };

  const closeAssignGameRoleModal = () => {
    setUserItem(initialUserItem);
    setOriginalRoles("");
    setOpenAssignGameRolesModal(false);
    setEditedUserData({});
  };

  const getGamesAssignedToUser = async userItem => {
    let { id, games } = userItem;
    const gameUserIds = games.items.filter(({ _deleted }) => !_deleted).map(({ id }) => id);
    const asyncGameItemsResponse = await getGamesByUserId(gameUserIds, id);
    return asyncGameItemsResponse.filter(({ gameDateTime }) => moment(gameDateTime).valueOf() > moment().valueOf()).sort((a, b) => moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf());
  };

  const getUserItemData = async (id) => {
    const userItem = await getUser(id);
    let { role } = userItem;
    role = role.join(',').trim();
    setOriginalRoles(role);
    setUserItem({ ...userItem, role, acceptedTerms: true, });
    setIsCreate(false);
    setOpen(true);
    return userItem;
  };

  const handleUserGameRolesEdit = async (id) => {
    const userItem = await getUserItemData(id);
    const games = await getGamesAssignedToUser(userItem);
    userItem.games.items = userItem.games.items.filter(userGame => games.find(gameItem =>  userGame._deleted === null && userGame.gameId === gameItem.id));
    setUserGames(games);
    setIsEditingGameRoles(true);
  };

  const handleUserProfileEdit = async (id) => {
    await getUserItemData(id);
    setIsEditingUserProfile(true);
  };

  const submitGameUserParams = async (gameUserParams, gameItemsRemoved) => {
    const { firstName, lastName } = userItem;
    const gameUserIds = userItem.games.items.map(({ id, gameId, _version }) => ({ id, gameId, _version }));
    const { updateGameUserParams, deleteGameUserParams } = getGameUserParameters(gameUserIds, gameUserParams, gameItemsRemoved);

    if(deleteGameUserParams) {
      const deleteGamesUserResponse = await deleteUserFromGame(deleteGameUserParams);

      if(!deleteGamesUserResponse.error) {
        setToastText(`Game roles assigned to ${firstName} ${lastName} were successfully deleted for.`);
        setToastVariant('success')
      }
      else {
        setToastText(`Game roles assigned to ${firstName} ${lastName} failed to be deleted.`);
        setToastVariant('danger')
        return;
      }
    }

    const isEveryGameUserRoleEditable = updateGameUserParams?.every(param => param);
    if(isEveryGameUserRoleEditable) {
      const updateGamesUserResponse = await updateUserForGame(updateGameUserParams);

      if(!updateGamesUserResponse.error) {
        setToastText(`Game roles assigned to ${firstName} ${lastName} were successfully updated for.`);
        setToastVariant('success')
      }
      else {
        setToastText(`Game roles assigned to ${firstName} ${lastName} failed to be updated.`);
        setToastVariant('danger')
        return;
      }
    }

    if(!isCreate) {
      await getGamesAssignedToUser(userItem);
      handleSubmit(editedUserData);
    }
  };

  return (
    <MainLayout
      title='User Management'
      buttonLabel="+ Add User"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {!isLoading && users && users.length === 0 && (
        <SquareGrid
          height={275}
          width={250}
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          justifyContent={'center'}
        >
          <AddCircleButton
            buttonLabel="Add User"
            buttonClickHandler={handleOpen}
          />
        </SquareGrid>
      )}

      <UsersContent
        searchId={"__userId"}
        data={users}
        placeholder={"Search Users"}
        editUserGameRoles={handleUserGameRolesEdit}
        editUserProfile={handleUserProfileEdit}
        fetchNextPage={() => fetchNextPage()}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        onSearch={onSearch}
      />

      <AddEditUserModal
        isNewUser={isCreate}
        modalOpen={open}
        setModalOpen={setOpen}
        userData={userItem}
        originalRoles={originalRoles}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onNext={openAssignGameRoleModal}
        error={createUserError}
        isEditingUserRoles={isEditingGameRoles}
        isEditingUserProfile={isEditingUserProfile}
      />

      {userItem && 
        <AssignGameUserRolesModal
          isNewGameRoleAssignment={false}
          userName={`${truncateText(`${editedUserData?.firstName || userItem?.firstName}`, 12)} ${truncateText(`${editedUserData?.lastName || userItem?.lastName}`, 12)}`}
          userItem={{ ...userItem, role: originalRoles.split(',') }}
          modalOpen={openAssignGameRolesModal}
          setModalOpen={setOpenAssignGameRolesModal}
          submitHandler={submitGameUserParams}
          backHandler={openAddEditUserModalBackHandler}
          games={userGames}
          editedUserData={editedUserData}
          setIsCancelledBtnClicked={() => {}}
          onClose={closeAssignGameRoleModal}
          hasPreviousModal={!open}
          itemType={ITEM_TYPE.GAME}
        />
      }

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default UsersPage;
