import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Alert } from 'react-bootstrap';
import { City } from 'country-state-city';
import ModalComponent from './ModalComponent';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import ImageUploadComponent from '../Inputs/ImageUploadComponent';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import InputSwitchComponent from '../Checkbox/InputSwitchComponent';
import InputSelectDropdown from '../Dropdowns/InputSelectDropdown';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import PendingUpdate from '../PendingUpdate';
import { statesList } from '../../data/statesList';
import TeamLogoImage from '../../assets/Team@2x.png';
import { imageUploadChangeHandler } from '../../utils/imageUploadUtil';
import validation from '../../validation-config/team';
import { PENDING_CHANGE_LEAGUE_ID, DEFAULT_TEAMS_IMAGE } from '../../utils/constantsUtils';
import { modalContainerStyle, modalBodyContainerStyle } from '../../styles';

const StateSelectDropdown = ({
  isNewTeam,
  statesList,
  getStateCities,
  isPendingUpdate, 
  formik,
}) => {
  useEffect(() => {
    const { state } = formik.values;
    getStateCities(state);
  }, [formik.values.state]);

  return (
    <SelectDropdown
      name="state"
      label="State"
      required={true}
      options={statesList}
      disabled={isPendingUpdate ? !isPendingUpdate : !isNewTeam}
      helperText={'Select State'}
    >
      <option value="" disabled>Select State</option>
      {statesList?.map(({ name }) => {
        return (
          <option key={name} value={name}>
            {name}
          </option>
        );
      })}
    </SelectDropdown>
  );
};

const StateCitiesInputSelectDropdown = ({
  isNewTeam,
  isCityDisabled,
  stateCities,
  isBackButtonClicked,
  setIsBackButtonClicked,
  isPendingUpdate,
  formik,
}) => {
  let typeahead = useRef();

  const { state } = formik.values;

  useEffect(() => {
    if(!isBackButtonClicked) {
      if(isNewTeam) {
        formik.setFieldValue('city', '');
        typeahead.clear();
      }
    } else {
      setIsBackButtonClicked(false);
    }
  }, [state]);

  return (
    <InputSelectDropdown
      label="City"
      name="city"
      required={true}
      options={stateCities}
      disabled={(isPendingUpdate ? !isPendingUpdate : !isNewTeam) || isCityDisabled}
      labelKey={(option) =>
        option && `${option.name}`
      }
      setRef={(ref) => (typeahead = ref)}
    />
  );
};

const LeagueSelectDropdown = ({
  isNewTeam, 
  isPendingUpdate, 
  leagueList, 
  formik, 
  isPendingTeamUpdated, 
}) => {
  const [ isLeagueSelected, setIsLeagueSelected ] = useState(isPendingUpdate);

  useEffect(() => {
    setIsLeagueSelected(formik.values.leagueId === PENDING_CHANGE_LEAGUE_ID);
    isPendingTeamUpdated(formik);
  }, [formik.values.leagueId]);

  return (
    <InputSelectDropdown
      label="League"
      name="leagueId"
      required={true}
      options={leagueList}
      disabled={isPendingUpdate ? !isPendingUpdate : !isNewTeam}
      inputStyle={isLeagueSelected && { border: '2px solid #FF9419' }}
      labelKey={(option) =>
        option && `${option.name} (${option.abbreviation})`
      }
    />
  );
};

const AddEditTeamModal = ({
  isNewTeam,
  teamData,
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  leagueList,
  error,
  backToTeamInfoEntryModal, 
}) => {
  const selectedStateRef = useRef();
  const isPendingUpdateRef = useRef();
  const [stateCities, setStateCities] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isPendingUpdate, setIsPendingUpdate] = useState(false);

  useEffect(() => {
    getStateCities(teamData.state);
    setIsPendingUpdate(teamData.leagueId === PENDING_CHANGE_LEAGUE_ID);
    isPendingUpdateRef.current = teamData.leagueId === PENDING_CHANGE_LEAGUE_ID;
  }, [modalOpen]);

  const UNITED_STATES_COUNTRY_CODE = 'US';

  const getStateCities = state => {
    if(state != "" && selectedStateRef.current != state) {
      const stateCities = City.getCitiesOfState(UNITED_STATES_COUNTRY_CODE, state)
                              .map(({ name }) => ({ id:name, name }));
      setIsCityDisabled(false);
      setStateCities(stateCities);
      selectedStateRef.current = state;
    }
  };

  const isPendingTeamUpdated = formik => {
    const isPendingUpdated = (formik.values.leagueId === PENDING_CHANGE_LEAGUE_ID) || (formik.values.image === DEFAULT_TEAMS_IMAGE); 
    setIsPendingUpdate(isPendingUpdated);
  };

  const teamValidationSchema = Yup.object({
    name: Yup.string()
             .when('null', {
               is: () => validation.name.isRequired.value,
               then: Yup.string().required(validation.name.isRequired.message)
             }),
    city: Yup.string()
             .matches(validation.city.regex.value, validation.city.regex.message)
             .oneOf(stateCities.map(({ name }) => name), validation.city.options.message)
             .when('null', {
               is: () => validation.city.isRequired.message,
               then: Yup.string().required(validation.city.isRequired.message)
             }),
    leagueId: Yup.string()
                 .when('null', {
                    is: () => validation.leagueId.isRequired.value, 
                    then: Yup.string().required(validation.leagueId.isRequired.message)
                  }),
    state: Yup.string()
              .when('null', {
                is: () => validation.state.isRequired.value, 
                then: Yup.string().required(validation.state.isRequired.message)
              }),
    image: Yup.mixed()
              .when('null', {
                is: () => validation.image.isRequired.value, 
                then: Yup.string().required(validation.image.isRequired.message)
              }),
    acceptedTerms: Yup.boolean()
              .oneOf([true], validation.acceptedTerms.isRequired.message)
              .when('null', {
                is: () => validation.acceptedTerms.isRequired.value, 
                then: Yup.boolean().required(validation.acceptedTerms.isRequired.message)
              }),
  });

  const getInitialValues = () => {
    const emptyValues = {
      name: '',
      leagueId: '',
      state: '',
      city: '',
      image: '',
      active: true,
      acceptedTerms: false,
    };

    if (!isNewTeam) {
      teamData.acceptedTerms = true;
    }

    return isNewTeam ? emptyValues : teamData;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={teamValidationSchema}
      isInitialValid={teamValidationSchema.isValidSync(getInitialValues())}
      enableReinitialize
    >
      {formik => (
        <ModalComponent
          headerTitle={isPendingUpdateRef.current ? 'Complete Team Profile' : (isNewTeam ? 'Add Team' : 'Edit Team')}
          buttonTitle={(isNewTeam && !isPendingUpdateRef.current) ? 'Add Team' : 'Save Changes'}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(formik);
            if (formik.isValid) {
              selectedStateRef.current = null;
              onSubmit(formik.values, formik.resetForm, isPendingUpdateRef.current);
              setIsCityDisabled(true);
              setIsBackButtonClicked(false);
              setIsPendingUpdate(false);
            }
          }}
          modalWidth={675}
          handleClose={() => {
            setIsCityDisabled(true);
            setIsBackButtonClicked(false);
            selectedStateRef.current = null;
            formik.resetForm();
            setIsPendingUpdate(false);
            onClose();
          }}
          isFooterVisible={true}
          buttonDimmed={!formik.isValid}
          buttonDisabled={isPendingUpdate ? isPendingUpdate : (!isNewTeam && !formik.dirty)}
          showBackButton={isNewTeam}
          backHandler={() => {
            setIsBackButtonClicked(true);
            setIsPendingUpdate(false);
            backToTeamInfoEntryModal();
          }}
          modalHeaderStyles={isPendingUpdate && { marginBottom: 0 }}
          modalBodyStyles={isPendingUpdate && { padding: 0, paddingTop: 10, }}
          buttonContainerStyles={isPendingUpdate && { paddingLeft: 20, paddingRight: 20 }}
        >
          <div style={modalContainerStyle}>
            {isPendingUpdate && 
              <PendingUpdate 
                topPosition={-65}
                pendingUpdateStatement='Please fill in the missing information below'
              />
            }
            {/* Alert Column */}
            <Col sm={12} className="px-1">
              {/* Edit Warning */}
              {(!isNewTeam && !isPendingUpdateRef.current) && (
                <Alert variant={'warning'}>
                  <i>
                    If you need to edit any of the disabled fields please
                    contact your Fast Stats administrator.
                  </i>
                </Alert>
              )}
              {/* Error Message */}
              {error && <Alert variant={'danger'}>{error}</Alert>}
            </Col>
            <Row className="mb-1" style={isPendingUpdate ? { ...modalBodyContainerStyle, marginTop: 53 } : { marginTop: 0 }}>
              <Col xs={4}>
                <Row>
                  <Col className="mb-3" xs={12}>
                    <ImageUploadComponent
                      id="file-upload-component"
                      label="Upload Team Image"
                      name="image"
                      imageHeight={'135px'}
                      imageMaxHeight={'135px'}
                      formik={formik}
                      onChange={imageUploadChangeHandler}
                      value={formik.values.image}
                      error={(formik.touched.image && formik.errors.image)}
                      errorMsg="Upload Team Image"
                      disabled={isPendingUpdateRef.current ? !isPendingUpdateRef.current : !isNewTeam}
                      placeHolder={TeamLogoImage}
                      pendingUpdateStatus={isPendingUpdate}
                      isPendingImageUpdated={isPendingTeamUpdated}
                    />
                  </Col>

                  <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <InputSwitchComponent
                      name="active"
                      switchLabel="Team Active?"
                      labelPositive={'Yes'}
                      labelNegative={'No'}
                      marginLeft={15}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={8}>
                <Row className="mb-2">
                  <Col xs={12}>
                    <InputFieldComponent
                      name="name"
                      label="Team Name"
                      type="text"
                      required={true}
                      disabled={isPendingUpdateRef.current ? !isPendingUpdateRef.current : !isNewTeam}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col xs={12}>
                    <LeagueSelectDropdown
                      isNewTeam={isNewTeam}
                      isPendingUpdate={isPendingUpdateRef.current}
                      leagueList={leagueList}
                      isPendingTeamUpdated={isPendingTeamUpdated}
                      formik={formik}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <StateSelectDropdown
                      isNewTeam={isNewTeam}
                      statesList={statesList}
                      getStateCities={getStateCities}
                      formik={formik}
                      isPendingUpdate={isPendingUpdateRef.current}
                    />
                  </Col>

                  <Col xs={6}>
                    <StateCitiesInputSelectDropdown
                      isNewTeam={isNewTeam}
                      isCityDisabled={isCityDisabled}
                      stateCities={stateCities}
                      isBackButtonClicked={isBackButtonClicked}
                      setIsBackButtonClicked={setIsBackButtonClicked}
                      formik={formik}
                      isPendingUpdate={isPendingUpdateRef.current}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {isNewTeam &&
            <Row className="mb-2">
              <Col xs={12}>
                <InputCheckboxLabel
                  label="Please check the information you have entered as all or some of the fields will not be editable once the Team has been added."
                  name="acceptedTerms"
                />
              </Col>
            </Row>}
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditTeamModal;