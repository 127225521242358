import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { getLeague, listLeagues, updateLeague } from '../api/leaguesService';
import { useSportsQuery } from '../api/sportsQueries';
import UploadFileImage from '../assets/LeaguePlaceholder.png';
import ToastAlert from '../components/alerts/ToastAlert';
import MainLayout from '../components/layout/MainLayout';
import { AddEditCustomStatsModal } from '../components/Modals/AddEditCustomStatsModal';
import {
  AddEditLeagueModal,
  EditMode
} from '../components/Modals/AddEditLeagueModal';
import { useFetchAll } from '../utils/reactQueryToolkit';

const LeagueIndividualPage = () => {
  const { id } = useParams();

  const [singleLeagueData, setSingleLeagueData] = useState();

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');

  const getLeagueData = useCallback(async () => {
    const leagueData = await getLeague(id);
    setSingleLeagueData(leagueData);
  }, [id]);

  useEffect(() => {
    if (id) {
      getLeagueData();
    }
  }, [id]);

  const { onSuccessCallback } = useFetchAll(true);
  const sportsQuery = useSportsQuery({
    onSuccess: (data) => onSuccessCallback(sportsQuery)(data),
  });
  const sports = sportsQuery.data;

  /* League Modal */

  const [leagueModalOpen, setLeagueModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onLeagueModalSubmit = async (values) => {
    /* Are we only updating the image? */
    if (
      singleLeagueData.name === values.name &&
      singleLeagueData.abbreviation === values.abbreviation
    ) {
      /* Update Image */
      await updateLeagueImage(values);
    } else {
      /* Update Fields */
      await updateLeagueFields(values);
    }
  };

  const updateLeagueImage = async (values) => {
    const returnData = await updateLeague(id, { image: values.image });
    if (returnData.errors) {
      setErrorMessage(
        `Unable to update league image. ${returnData.errors[0].message}.`
      );
    } else {
      setLeagueModalOpen(false);
      getLeagueData();
      setToastText(
        `The League "${values.name} (${values.abbreviation})" image was updated.`
      );
      setShowToast(true);
      setErrorMessage(null);
    }
  };

  const updateLeagueFields = async (values) => {
    /* Check to see is a League with the same name and abbreviation exist */
    const filteredLeagues = await listLeagues({
      filter: {
        name: { eq: values.name },
        abbreviation: { eq: values.abbreviation },
      },
    });
    if (filteredLeagues.length >= 1) {
      /** A League with the name and abbreviation already exists. Show an error message in the form */
      setErrorMessage(
        `The League "${values.name} (${values.abbreviation})" already exists.`
      );
    } else {
      /** A League with the name and abbreviation does not exists. Update the League */
      const returnData = await updateLeague(id, values);
      if (returnData.errors) {
        setErrorMessage(
          `Unable to update league. ${returnData.errors[0].message}.`
        );
      } else {
        setLeagueModalOpen(false);
        getLeagueData();
        setToastText(
          `The League "${values.name} (${values.abbreviation})" was updated.`
        );
        setShowToast(true);
        setErrorMessage(null);
      }
    }
  };

  const onLeagueModalClose = () => {
    setLeagueModalOpen(false);
    setErrorMessage(null);
  };

  /* Custom Stats Modal */

  const [customStatsModalOpen, setCustomStatsModalOpen] = useState(false);

  const onCustomStatsModalSubmit = async (values) => {
    setCustomStatsModalOpen(false);
  };

  const onCustomStatsModalClose = () => {
    setCustomStatsModalOpen(false);
  };

  const imageStyle = {
    opacity: '1',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    padding: '10px',
    borderRadius: '7px',
  };

  const imageContainerStyle = {
    height: '142px',
    width: '100%',
    aspectRatio: '4/3',
    padding: '0px',
    // margin: '4px',
    textAlign: 'left',
    color: '#20212F',
    // background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9DBDE',
    borderRadius: '5px',
    opacity: '1',
  };

  const sectionHeading = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: 'bold',
    font: 'normal normal bold 18px/24px Inter',
    letterSpacing: '0px',
    color: '#111111',
    opacity: 1,
    padding: '20px 4px 14px 4px',
  };

  const statsHeading = {
    margin: 'auto',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#20212F',
    opacity: '1',
    lineHeight: 'normal',
    paddingLeft: '4px',
    // font: 'normal normal normal 15px/53px Inter',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    fontFamily: 'Inter',
  };

  const editIcon = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#20212F',
    opacity: '1',
    lineHeight: 'normal',
    // font: 'normal normal normal 15px/53px Inter',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    fontFamily: 'Inter',
  };

  const statsItems = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#555555',
    opacity: '1',
    // font: 'normal normal medium 16px/24px Inter',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'medium',
    fontSize: '16px',
    fontFamily: 'Inter',
  };

  const statBox = {
    padding: '8px 12px 8px 12px',
    margin: '4px',
    textAlign: 'left',
    color: '#20212F',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9DBDE',
    borderRadius: '5px',
    opacity: '1',
    // font: 'normal normal normal 15px/19px Inter',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'medium',
    fontSize: '15px',
    fontFamily: 'Inter',
    letterSpacing: '0px',
    display: 'flex',
    alignItems: 'center',
  };

  const statBoxBold = {
    fontWeight: 'bold',
    fontSize: '16px',
  };

  const overview = [
    { id: 1, name: 'League Name', value: singleLeagueData?.name },
    { id: 2, name: 'Abbreviation', value: singleLeagueData?.abbreviation },
    // { id: 3, name: 'Sport', value: singleLeagueData?.sport?.name },
    { id: 4, name: 'Team Size', value: singleLeagueData?.teamSize },
    {
      id: 5,
      name: 'Players on Court',
      value: singleLeagueData?.numPlayersOnCourt,
    },
    {
      id: 6,
      name: 'Players on Bench',
      value: singleLeagueData?.numPlayersOnBench,
    },
    { id: 7, name: 'Num Timeouts', value: singleLeagueData?.numTimeOuts },
    {
      id: 8,
      name: 'Fouls/Player',
      value: singleLeagueData?.numFoulsPerPlayer,
    },
    { id: 9, name: 'Periods', value: singleLeagueData?.numPeriods },
    {
      id: 10,
      name: 'Time per Period',
      value: singleLeagueData?.timePerPeriodInMins,
    },
    {
      id: 11,
      name: 'Overtime Duration',
      value: singleLeagueData?.overtimeDurationInMins,
    },
  ];

  const stats = [
    {
      id: 0,
      name: 'Scoring Types',
      values: ['2pt made', '3pt made', 'Free throw made'],
    },
    {
      id: 1,
      name: 'Scoring Missed Types',
      values: ['2pt miss', '3pt miss', 'Free throw miss'],
    },
    { id: 2, name: 'Foul Stats', values: ['Personal foul', 'Technical foul'] },
    {
      id: 3,
      name: 'Other Stats',
      values: ['Block', 'Turnover', 'Violation', 'Deflect'],
    },
    { id: 4, name: 'Custom Stats', values: ['Custom 1', 'Custom 2'] },
  ];

  return (
    <MainLayout
      title={singleLeagueData ? singleLeagueData?.name : 'League'}
      bodyContent={
        singleLeagueData && singleLeagueData?.abbreviation
          ? `View the stats configured for the ${singleLeagueData.abbreviation}. Add custom stat categories.`
          : 'View the stats configured for the league. Add custom stat categories.'
      }
      buttonLabel={null}
      navigationLinkBack={'/admin/leagues'}
    >
      {!singleLeagueData && (
        <Spinner
          animation="border"
          role="status"
          style={{ position: 'fixed', top: '50%', left: '50%' }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      {singleLeagueData && (
        <Container fluid>
          <Row>
            <Row className="mt-2">
              <Col sm="12" md="2" className="px-1 py-1">
                <div style={imageContainerStyle}>
                  <img
                    style={imageStyle}
                    src={singleLeagueData.image}
                    onError={(event) => (event.target.src = UploadFileImage)}
                    alt="League Logo"
                  />
                </div>
              </Col>
              <Col>
                <Row>
                  {overview.map((item) => (
                    <Col key={item.id} sm="auto" style={statBox}>
                      <div>{item.name}:&nbsp;</div>
                      <div style={statBoxBold}>{item.value}</div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col sm="1" style={{ ...editIcon, marginTop: '8px' }}>
                <PencilSquare
                  color="#656565"
                  size={26}
                  onClick={() => {
                    setLeagueModalOpen(true);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <span style={sectionHeading}>Stats Configuration</span>
            </Row>
            {stats.map((stat, i) => (
              <Row key={stat.id}>
                <Col sm="12" lg="2" style={statsHeading}>
                  {stat.name}
                </Col>
                <Col sm="12" lg="9" className="p-0">
                  <Row
                    style={{
                      ...statBox,
                      background:
                        i % 2 !== 0
                          ? '#E2E2E2 0% 0% no-repeat padding-box'
                          : '#FFFFFF 0% 0% no-repeat padding-box',
                      border:
                        i % 2 !== 0 ? '1px solid #CECECE' : '1px solid #D9DBDE',
                    }}
                  >
                    {stat.values.map((item, i, { length }) => (
                      <Col
                        key={item}
                        sm="auto"
                        style={{
                          ...statsItems,
                          borderRight:
                            i + 1 !== length ? '2px solid #75C8EB' : '',
                        }}
                      >
                        {item}
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col sm="12" md="1" style={{ ...editIcon, margin: 'auto' }}>
                  {stat.name === 'Custom Stats' && (
                    <PencilSquare
                      color="#656565"
                      size={26}
                      onClick={() => {
                        setCustomStatsModalOpen(true);
                      }}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </Row>
        </Container>
      )}

      <AddEditCustomStatsModal
        modalOpen={customStatsModalOpen}
        setModalOpen={setCustomStatsModalOpen}
        onClose={onCustomStatsModalClose}
        onSubmit={onCustomStatsModalSubmit}
        initialValues={{
          customStat1: '',
          customStat2: '',
          customStat3: '',
        }}
      />

      <AddEditLeagueModal
        editMode={EditMode.EDIT}
        modalOpen={leagueModalOpen}
        setModalOpen={setLeagueModalOpen}
        onClose={onLeagueModalClose}
        onSubmit={onLeagueModalSubmit}
        sports={sports}
        initialValues={singleLeagueData}
        error={errorMessage}
        setError={setErrorMessage}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={'success'}
      />
    </MainLayout>
  );
};

export default LeagueIndividualPage;
