import { createSlice } from '@reduxjs/toolkit';
import { NEW_GROUP_USER } from '../utils/constantsUtils';

export const groupSlice = createSlice({
	name: 'group',
	initialState: {
		value: NEW_GROUP_USER,
	},
	reducers: {
		updateGroup: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { updateGroup } = groupSlice.actions;
export default groupSlice.reducer;
