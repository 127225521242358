import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { listSeasons } from '../../api/seasonsService';
import logger from '../../logger';
import { LIST_PAGINATION_LIMIT } from '../../utils/constantsUtils';
import { generateValidateCallback } from '../../utils/formikToolkit';
import { dateStringToAwsDate } from '../../utils/momentDateUtil';
import validation from '../../validation-config/season';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import InputSelectDropdown from '../Dropdowns/InputSelectDropdown';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import Spinner from '../Spinner';
import ModalComponent from './ModalComponent';
const log = logger('AddEditSeasonModal', 'info');

const SeasonContentForm = ({ isNewSeason, leaguesData }) => {
  const seasonTypes = [
    {
      abbreviation: 'Pre Season',
      name: 'Pre Season',
    },
    {
      abbreviation: 'Regular Season',
      name: 'Regular Season',
    },
    {
      abbreviation: 'Post Season',
      name: 'Post Season',
    },
  ];

  const leagueOptionFinder = (leagueText) => {
    return leaguesData.find(({ name, abbreviation }) => {
      return (
        leagueText.includes(name) && leagueText.includes(`(${abbreviation})`)
      );
    });
  };

  return (
    <>
      <Row className="mb-2">
        <Col xs={12}>
          <SelectDropdown
            id='seasonType'
            label='Season Type'
            name='seasonType'
            required={validation.seasonType.isRequired.value}
            disabled={!isNewSeason}
            helperText='Select Type'
          >
            <option value=''>Select Season Type</option>
            {seasonTypes?.map(({ name }) => {
              return (
                <option key={name} value={name}>
                  {name}
                </option>
              );
            })}
          </SelectDropdown>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={6}>
          <InputFieldComponent
            id='startDate'
            name='startDate'
            label='Start Date'
            type='date'
            required={validation.seasonType.isRequired.value}
            disabled={!isNewSeason}
          />
        </Col>

        <Col xs={6}>
          <InputFieldComponent
            id='endDate'
            name='endDate'
            label='End Date'
            type='date'
            required={validation.endDate.isRequired.value}
            disabled={!isNewSeason}
          />
        </Col>
      </Row>

      <Row className="mb-2" style={{ height: 70 }}>
        <Col xs={12}>
          <InputSelectDropdown
            label='League'
            name='leagueId'
            required={validation.leagueId.isRequired.value}
            options={leaguesData}
            disabled={!isNewSeason}
            labelKey={(option) =>
              option && `${option.name} (${option.abbreviation})`
            }
            optionFinderFunction={leagueOptionFinder}
          />
        </Col>
      </Row>

      {isNewSeason && (
        <Row className="mb-2">
          <Col xs={12}>
            <InputCheckboxLabel
              id='acceptedTerms'
              label='Please check the information you have entered as all or some of the fields will not be editable once the Season has been added.'
              name='acceptedTerms'
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const SeasonContentInfo = ({ formik, leaguesData }) => {
  const { seasonType, startDate, endDate, leagueId } = formik.values;
  const leagueData = leaguesData.find(({ id }) => id === leagueId);

  const infoTitleStyle = {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#707070',
    padding: 0,
    marginBottom: 10,
  };

  const dataTitleStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#616161',
  };

  const dataValueStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
    color: '#707070',
    width: '65%',
  };

  const seasonInfoData = [
    {
      seasonTitle: 'Season Type',
      seasonValue: seasonType,
    },
    {
      seasonTitle: 'Start Date',
      seasonValue: moment(startDate).format('MM.DD.YYYY'),
    },
    {
      seasonTitle: 'End Date',
      seasonValue: moment(endDate).format('MM.DD.YYYY'),
    },
  ];

  const leagueInfoData = [
    {
      leagueTitle: 'Name',
      leagueValue: leagueData?.name,
    },
    {
      leagueTitle: 'Abbreviation',
      leagueValue: leagueData?.abbreviation,
    },
    {
      leagueTitle: 'Team Size',
      leagueValue: leagueData?.teamSize,
    },
    {
      leagueTitle: 'Players On Court',
      leagueValue: leagueData?.numPlayersOnCourt,
    },
    {
      leagueTitle: 'Players On Bench',
      leagueValue: leagueData?.numPlayersOnBench,
    },
    {
      leagueTitle: 'Periods',
      leagueValue: leagueData?.numPeriods,
    },
    {
      leagueTitle: 'Time Per Period',
      leagueValue: leagueData?.timePerPeriodInMins,
    },
  ];

  const titleInfoRow = (title) => {
    return (
      <Col xs={12} style={infoTitleStyle}>
        {title}:
      </Col>
    );
  };

  const dataInfoRow = (title, value) => {
    return (
      <tr key={`${title}_${value}`}>
        <td style={dataTitleStyle}>{title}</td>
        <td style={dataValueStyle}>{value}</td>
      </tr>
    );
  };

  return (
    <>
      <Row className='d-flex justify-content-center mx-0'>
        {titleInfoRow('Season Info')}
        <Table striped bordered hover size='sm' className='pb-3'>
          <tbody>
            {seasonInfoData.map(({ seasonTitle, seasonValue }) =>
              dataInfoRow(seasonTitle, seasonValue)
            )}
          </tbody>
        </Table>

        {titleInfoRow('League Info')}
        <Table striped bordered hover size='sm'>
          <tbody>
            {leagueInfoData.map(({ leagueTitle, leagueValue }) =>
              dataInfoRow(leagueTitle, leagueValue)
            )}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

const AddEditSeasonModal = ({
  isNewSeason,
  isOpen: modalOpen,
  setOpen: setModalOpen,
  leaguesData,
  seasonData,
  onSubmit,
  onClose,
  error,
  setError,
  isLoading,
}) => {
  const [hasNextBtn, setHasNextBtn] = useState(true);
  const [hasBackBtn, setHasBackBtn] = useState(false);

  const seasonValidationSchema = Yup.object({
    seasonType: Yup.string().when('null', {
      is: () => validation.seasonType.isRequired.value,
      then: Yup.string().required(validation.seasonType.isRequired.message),
    }),
    leagueId: Yup.string().when('null', {
      is: () => validation.leagueId.isRequired.value,
      then: Yup.string().required(validation.leagueId.isRequired.message),
    }),
    acceptedTerms: Yup.boolean()
      .oneOf([true], validation.acceptedTerms.isRequired.message)
      .when('null', {
        is: () => validation.acceptedTerms.isRequired.value,
        then: Yup.boolean().required(
          validation.acceptedTerms.isRequired.message
        ),
      }),
  }).shape({
    startDate: Yup.date().when('null', {
      is: () => validation.startDate.isRequired.value,
      then: Yup.date().required(validation.startDate.isRequired.message),
    }),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), 'Start Date is after End Date')
      .when('null', {
        is: () => validation.endDate.isRequired.value,
        then: Yup.date().required(validation.endDate.isRequired.message),
      }),
  });

  const duplicateCheck = useCallback(async (values, context) => {
    const { seasonType, startDate, endDate, leagueId } = values;

    /* If any of these fields are missing, there can't be a duplicate in the DB. */
    if (!seasonType || !startDate || !endDate || !leagueId) return true;

    const queryVars = {
      filter: {
        seasonType: { eq: seasonType },
        startDate: { eq: dateStringToAwsDate(startDate) },
        endDate: { eq: dateStringToAwsDate(endDate) },
        leagueId: { eq: leagueId },
      },
      limit: LIST_PAGINATION_LIMIT.XLARGE,
    };

    let duplicateDataResponse = await listSeasons(queryVars);

    if (duplicateDataResponse.errors) {
      /** Error checking for duplicate */
      return context.createError({
        message: `${duplicateDataResponse.errors[0].message}.`,
      });
    }

    let items = duplicateDataResponse.items;
    while (items.length === 0 && duplicateDataResponse.nextToken) {
      duplicateDataResponse = await listSeasons({
        ...queryVars,
        nextToken: duplicateDataResponse.nextToken,
      });
      items = [...items, ...duplicateDataResponse.items];
    }

    if (items.length >= 1) {
      /** Duplicate Exists */
      return context.createError({
        message: `This season already exists for the selected league.`,
      });
    }

    return true;
  }, []);

  const seasonValidationSchemaAsync = seasonValidationSchema.test(
    'duplicate-check',
    'A duplicate season was found.',
    duplicateCheck
  );

  const getInitialValues = () => {
    const emptyValues = {
      seasonType: '',
      startDate: '',
      endDate: '',
      league: '',
      leagueId: '',
      acceptedTerms: false,
      /** Test Values */
      // seasonType: 'Pre Season',
      // startDate: '2021-10-24',
      // endDate: '2021-10-25',
      // league: '',
      // leagueId: 'dfc20d4d-519f-44e7-b620-9d288e15013d',
      // acceptedTerms: true,
    };
    return isNewSeason ? emptyValues : seasonData;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      isInitialValid={seasonValidationSchema.isValidSync(getInitialValues())}
      validateOnMount
      validate={generateValidateCallback(
        seasonValidationSchemaAsync,
        (msg) =>
          setError(
            `Unable to ${isNewSeason ? 'create' : 'edit'} season. ${msg}`
          ),
        () => setError()
      )}
      onReset={(values, formik) => {
        setHasNextBtn(true);
        setHasBackBtn(false);
      }}
      onSubmit={async (values, formik) => {
        try {
          await onSubmit(values);
          formik.resetForm();
          onClose();
        } catch (err) {
          log.error(err);
        }
      }}
    >
      {(formik) => (
        <ModalComponent
          headerTitle={isNewSeason ? 'Add Season' : 'Edit Season'}
          buttonTitle={isNewSeason ? 'Add Season' : 'Save Changes'}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={formik.handleSubmit}
          modalWidth={500}
          handleClose={() => {
            formik.resetForm();
            onClose();
          }}
          buttonDimmed={!formik.isValid || error}
          buttonDisabled={!formik.isValid || error}
          isFooterVisible={isNewSeason}
          showNextButton={hasNextBtn}
          showBackButton={hasBackBtn}
          nextHandler={(e) => {
            e.preventDefault();
            if (formik.isValid && !error) {
              setHasNextBtn(false);
              setHasBackBtn(true);
            }
          }}
          backHandler={() => {
            setHasBackBtn(false);
            setHasNextBtn(true);
          }}
        >
          {isLoading && <Spinner />}

          {/* Modal Component Children are wrapped in a Form */}
          {/* Alert Column */}
          <Col sm={12} className="px-1">
            {/* Edit Warning */}
            {!isNewSeason && (
              <Alert variant={'warning'}>
                <i>
                  All edits are disabled. To make changes please contact your
                  administrator.
                </i>
              </Alert>
            )}
            {/* Error Message */}
            {error && <Alert variant={'danger'}>{error}</Alert>}
          </Col>

          {hasNextBtn ? (
            <SeasonContentForm
              isNewSeason={isNewSeason}
              leaguesData={leaguesData}
              formik={formik}
            />
          ) : (
            <SeasonContentInfo formik={formik} leaguesData={leaguesData} />
          )}
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditSeasonModal;
