const TeamsValidation = {
  name: {
    isRequired: { value: true, message: 'Team Name is required' }
  },
  city: {
    isRequired: { value: true, message: 'City is required' },
    regex: { value: /^([A-Za-z](\s)?[A-Za-z]*)+$/g, message: 'City is invalid' },
    options: { message: 'Invalid City for selected State' }
  },
  leagueId: {
    isRequired: { value: true, message: 'League is required' }
  },
  state: {
    isRequired: { value: true, message: 'State is required' }
  },
  image: {
    isRequired: { value: true, message: 'Upload Team Image' }
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' }
  }
};

export default TeamsValidation;