import React, { useState } from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Row, Col } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const defaultControlStyle = {
  position: 'absolute',
  top: '65px',
  right: '55px',
  zIndex: '2',
  width: '350px',
  height: '50px',
};

const SearchInputComponent = ({
  id,
  data,
  dataProperty: labelKey,
  placeholder,
  onChange,
  searchControlStyle = defaultControlStyle,
  filterBy,
  renderMenuItemChildren, 
  setRef, 
  isLoading = false,
  onSearch = () => {},
}) => {
  const [singleSelections, setSingleSelections] = useState([]);
  
  const onSelectedChange = (selected, setSelected) => {
    setSelected(selected);
    if (selected && selected[0]) {
      if (typeof labelKey === 'function') {
        onChange(selected[0]);
      } else {
        onChange(selected[0][labelKey]);
      }
    }
  };

  return (
    <Row className="mb-2 p-1" style={searchControlStyle}>
      <Col md={12} xs={12}>
        <AsyncTypeahead
          isLoading={isLoading}
          onSearch={onSearch}

          clearButton={true}
          id={id}
          labelKey={labelKey}
          options={data}
          placeholder={placeholder}
          /* For capturing the reference to the Typeahead instance when needed */
          ref={setRef}

          onInputChange={(text) => onChange(text)}
          onChange={(selected) =>
            onSelectedChange(selected, setSingleSelections)
          }
          selected={singleSelections}

          /* For Custom Filtering (Object Filtering) */
          filterBy={filterBy}
          
          /**
           * Option 1. Example using just fields
           * filterBy = ['capital', 'name'];
           *
           * Option 2. Example Using a callback
           * filterBy = (option, props) => (
           *   option.capital.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
           *   option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
           * );
           */

          renderMenuItemChildren={renderMenuItemChildren}
          /**
           * Example of displaying Name and Capital
           * (option) => (
           *  <div>{option.name}
           *    <div><small>Capital: {option.capital}</small></div>
           *  </div>)
           */
        />
      </Col>
    </Row>
  );
};

SearchInputComponent.defaultProps = {
  placeholder: 'Search...',
};

export default SearchInputComponent;
