/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSport = /* GraphQL */ `
  mutation CreateSport(
    $input: CreateSportInput!
    $condition: ModelSportConditionInput
  ) {
    createSport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSport = /* GraphQL */ `
  mutation UpdateSport(
    $input: UpdateSportInput!
    $condition: ModelSportConditionInput
  ) {
    updateSport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSport = /* GraphQL */ `
  mutation DeleteSport(
    $input: DeleteSportInput!
    $condition: ModelSportConditionInput
  ) {
    deleteSport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLeague = /* GraphQL */ `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLeague = /* GraphQL */ `
  mutation DeleteLeague(
    $input: DeleteLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    deleteLeague(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSeason = /* GraphQL */ `
  mutation CreateSeason(
    $input: CreateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    createSeason(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSeason = /* GraphQL */ `
  mutation UpdateSeason(
    $input: UpdateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    updateSeason(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSeason = /* GraphQL */ `
  mutation DeleteSeason(
    $input: DeleteSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    deleteSeason(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVenue = /* GraphQL */ `
  mutation CreateVenue(
    $input: CreateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    createVenue(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVenue = /* GraphQL */ `
  mutation DeleteVenue(
    $input: DeleteVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    deleteVenue(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGameUser = /* GraphQL */ `
  mutation CreateGameUser(
    $input: CreateGameUserInput!
    $condition: ModelGameUserConditionInput
  ) {
    createGameUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGameUser = /* GraphQL */ `
  mutation UpdateGameUser(
    $input: UpdateGameUserInput!
    $condition: ModelGameUserConditionInput
  ) {
    updateGameUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGameUser = /* GraphQL */ `
  mutation DeleteGameUser(
    $input: DeleteGameUserInput!
    $condition: ModelGameUserConditionInput
  ) {
    deleteGameUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTeamUser = /* GraphQL */ `
  mutation CreateTeamUser(
    $input: CreateTeamUserInput!
    $condition: ModelTeamUserConditionInput
  ) {
    createTeamUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTeamUser = /* GraphQL */ `
  mutation UpdateTeamUser(
    $input: UpdateTeamUserInput!
    $condition: ModelTeamUserConditionInput
  ) {
    updateTeamUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTeamUser = /* GraphQL */ `
  mutation DeleteTeamUser(
    $input: DeleteTeamUserInput!
    $condition: ModelTeamUserConditionInput
  ) {
    deleteTeamUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGameLineup = /* GraphQL */ `
  mutation CreateGameLineup(
    $input: CreateGameLineupInput!
    $condition: ModelGameLineupConditionInput
  ) {
    createGameLineup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGameLineup = /* GraphQL */ `
  mutation UpdateGameLineup(
    $input: UpdateGameLineupInput!
    $condition: ModelGameLineupConditionInput
  ) {
    updateGameLineup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGameLineup = /* GraphQL */ `
  mutation DeleteGameLineup(
    $input: DeleteGameLineupInput!
    $condition: ModelGameLineupConditionInput
  ) {
    deleteGameLineup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGameLineupPlayer = /* GraphQL */ `
  mutation CreateGameLineupPlayer(
    $input: CreateGameLineupPlayerInput!
    $condition: ModelGameLineupPlayerConditionInput
  ) {
    createGameLineupPlayer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGameLineupPlayer = /* GraphQL */ `
  mutation UpdateGameLineupPlayer(
    $input: UpdateGameLineupPlayerInput!
    $condition: ModelGameLineupPlayerConditionInput
  ) {
    updateGameLineupPlayer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGameLineupPlayer = /* GraphQL */ `
  mutation DeleteGameLineupPlayer(
    $input: DeleteGameLineupPlayerInput!
    $condition: ModelGameLineupPlayerConditionInput
  ) {
    deleteGameLineupPlayer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCareerPlayerStats = /* GraphQL */ `
  mutation CreateCareerPlayerStats(
    $input: CreateCareerPlayerStatsInput!
    $condition: ModelCareerPlayerStatsConditionInput
  ) {
    createCareerPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCareerPlayerStats = /* GraphQL */ `
  mutation UpdateCareerPlayerStats(
    $input: UpdateCareerPlayerStatsInput!
    $condition: ModelCareerPlayerStatsConditionInput
  ) {
    updateCareerPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCareerPlayerStats = /* GraphQL */ `
  mutation DeleteCareerPlayerStats(
    $input: DeleteCareerPlayerStatsInput!
    $condition: ModelCareerPlayerStatsConditionInput
  ) {
    deleteCareerPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCareerTeamStats = /* GraphQL */ `
  mutation CreateCareerTeamStats(
    $input: CreateCareerTeamStatsInput!
    $condition: ModelCareerTeamStatsConditionInput
  ) {
    createCareerTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCareerTeamStats = /* GraphQL */ `
  mutation UpdateCareerTeamStats(
    $input: UpdateCareerTeamStatsInput!
    $condition: ModelCareerTeamStatsConditionInput
  ) {
    updateCareerTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCareerTeamStats = /* GraphQL */ `
  mutation DeleteCareerTeamStats(
    $input: DeleteCareerTeamStatsInput!
    $condition: ModelCareerTeamStatsConditionInput
  ) {
    deleteCareerTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGamePlayerStats = /* GraphQL */ `
  mutation CreateGamePlayerStats(
    $input: CreateGamePlayerStatsInput!
    $condition: ModelGamePlayerStatsConditionInput
  ) {
    createGamePlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGamePlayerStats = /* GraphQL */ `
  mutation UpdateGamePlayerStats(
    $input: UpdateGamePlayerStatsInput!
    $condition: ModelGamePlayerStatsConditionInput
  ) {
    updateGamePlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGamePlayerStats = /* GraphQL */ `
  mutation DeleteGamePlayerStats(
    $input: DeleteGamePlayerStatsInput!
    $condition: ModelGamePlayerStatsConditionInput
  ) {
    deleteGamePlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSeasonPlayerStats = /* GraphQL */ `
  mutation CreateSeasonPlayerStats(
    $input: CreateSeasonPlayerStatsInput!
    $condition: ModelSeasonPlayerStatsConditionInput
  ) {
    createSeasonPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSeasonPlayerStats = /* GraphQL */ `
  mutation UpdateSeasonPlayerStats(
    $input: UpdateSeasonPlayerStatsInput!
    $condition: ModelSeasonPlayerStatsConditionInput
  ) {
    updateSeasonPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSeasonPlayerStats = /* GraphQL */ `
  mutation DeleteSeasonPlayerStats(
    $input: DeleteSeasonPlayerStatsInput!
    $condition: ModelSeasonPlayerStatsConditionInput
  ) {
    deleteSeasonPlayerStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSeasonTeamStats = /* GraphQL */ `
  mutation CreateSeasonTeamStats(
    $input: CreateSeasonTeamStatsInput!
    $condition: ModelSeasonTeamStatsConditionInput
  ) {
    createSeasonTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSeasonTeamStats = /* GraphQL */ `
  mutation UpdateSeasonTeamStats(
    $input: UpdateSeasonTeamStatsInput!
    $condition: ModelSeasonTeamStatsConditionInput
  ) {
    updateSeasonTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSeasonTeamStats = /* GraphQL */ `
  mutation DeleteSeasonTeamStats(
    $input: DeleteSeasonTeamStatsInput!
    $condition: ModelSeasonTeamStatsConditionInput
  ) {
    deleteSeasonTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createScoreType = /* GraphQL */ `
  mutation CreateScoreType(
    $input: CreateScoreTypeInput!
    $condition: ModelScoreTypeConditionInput
  ) {
    createScoreType(input: $input, condition: $condition) {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateScoreType = /* GraphQL */ `
  mutation UpdateScoreType(
    $input: UpdateScoreTypeInput!
    $condition: ModelScoreTypeConditionInput
  ) {
    updateScoreType(input: $input, condition: $condition) {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteScoreType = /* GraphQL */ `
  mutation DeleteScoreType(
    $input: DeleteScoreTypeInput!
    $condition: ModelScoreTypeConditionInput
  ) {
    deleteScoreType(input: $input, condition: $condition) {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTeamRosterPlayer = /* GraphQL */ `
  mutation CreateTeamRosterPlayer(
    $input: CreateTeamRosterPlayerInput!
    $condition: ModelTeamRosterPlayerConditionInput
  ) {
    createTeamRosterPlayer(input: $input, condition: $condition) {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamRosterPlayer = /* GraphQL */ `
  mutation UpdateTeamRosterPlayer(
    $input: UpdateTeamRosterPlayerInput!
    $condition: ModelTeamRosterPlayerConditionInput
  ) {
    updateTeamRosterPlayer(input: $input, condition: $condition) {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamRosterPlayer = /* GraphQL */ `
  mutation DeleteTeamRosterPlayer(
    $input: DeleteTeamRosterPlayerInput!
    $condition: ModelTeamRosterPlayerConditionInput
  ) {
    deleteTeamRosterPlayer(input: $input, condition: $condition) {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTeamRoster = /* GraphQL */ `
  mutation CreateTeamRoster(
    $input: CreateTeamRosterInput!
    $condition: ModelTeamRosterConditionInput
  ) {
    createTeamRoster(input: $input, condition: $condition) {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamRoster = /* GraphQL */ `
  mutation UpdateTeamRoster(
    $input: UpdateTeamRosterInput!
    $condition: ModelTeamRosterConditionInput
  ) {
    updateTeamRoster(input: $input, condition: $condition) {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamRoster = /* GraphQL */ `
  mutation DeleteTeamRoster(
    $input: DeleteTeamRosterInput!
    $condition: ModelTeamRosterConditionInput
  ) {
    deleteTeamRoster(input: $input, condition: $condition) {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTimeEvent = /* GraphQL */ `
  mutation CreateTimeEvent(
    $input: CreateTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    createTimeEvent(input: $input, condition: $condition) {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTimeEvent = /* GraphQL */ `
  mutation UpdateTimeEvent(
    $input: UpdateTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    updateTimeEvent(input: $input, condition: $condition) {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTimeEvent = /* GraphQL */ `
  mutation DeleteTimeEvent(
    $input: DeleteTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    deleteTimeEvent(input: $input, condition: $condition) {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomStat = /* GraphQL */ `
  mutation CreateCustomStat(
    $input: CreateCustomStatInput!
    $condition: ModelCustomStatConditionInput
  ) {
    createCustomStat(input: $input, condition: $condition) {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomStat = /* GraphQL */ `
  mutation UpdateCustomStat(
    $input: UpdateCustomStatInput!
    $condition: ModelCustomStatConditionInput
  ) {
    updateCustomStat(input: $input, condition: $condition) {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomStat = /* GraphQL */ `
  mutation DeleteCustomStat(
    $input: DeleteCustomStatInput!
    $condition: ModelCustomStatConditionInput
  ) {
    deleteCustomStat(input: $input, condition: $condition) {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEventPlayer = /* GraphQL */ `
  mutation CreateEventPlayer(
    $input: CreateEventPlayerInput!
    $condition: ModelEventPlayerConditionInput
  ) {
    createEventPlayer(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEventPlayer = /* GraphQL */ `
  mutation UpdateEventPlayer(
    $input: UpdateEventPlayerInput!
    $condition: ModelEventPlayerConditionInput
  ) {
    updateEventPlayer(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEventPlayer = /* GraphQL */ `
  mutation DeleteEventPlayer(
    $input: DeleteEventPlayerInput!
    $condition: ModelEventPlayerConditionInput
  ) {
    deleteEventPlayer(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGameTeamStats = /* GraphQL */ `
  mutation CreateGameTeamStats(
    $input: CreateGameTeamStatsInput!
    $condition: ModelGameTeamStatsConditionInput
  ) {
    createGameTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGameTeamStats = /* GraphQL */ `
  mutation UpdateGameTeamStats(
    $input: UpdateGameTeamStatsInput!
    $condition: ModelGameTeamStatsConditionInput
  ) {
    updateGameTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGameTeamStats = /* GraphQL */ `
  mutation DeleteGameTeamStats(
    $input: DeleteGameTeamStatsInput!
    $condition: ModelGameTeamStatsConditionInput
  ) {
    deleteGameTeamStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
