import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from '../../styles/colors';
import { typeScale } from '../../styles/typography';

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    color: `${colors.GRAY[500]}`,
    '&$checked': {
      color: colors.PRIMARY[200],
      '&:hover': {
        backgroundColor: colors.PRIMARY[400],
      },
    },
    '&:hover': {
      backgroundColor: colors.PRIMARY[400],
    },
  },
  checked: {},
  labelRoot: {
    fontSize: typeScale.SMEDIUM.fontSize,
    lineHeight: typeScale.SMEDIUM.lineHeight,
    color: colors.GRAY[300],
    width: '100%',
  },
}));

export default function CheckboxWithLabel({ checked, onChange, name, label, checkboxStyles = {}, labelStyles = {}, disabled = false }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      style={{ ...labelStyles }}
      classes={{ label: classes.labelRoot }}      
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
          classes={{ root: classes.checkboxRoot, checked: classes.checked }}
          style={{ ...checkboxStyles }}
        />
      }
      label={label}
    />
  );
}
