import React from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import { colors } from '../../styles/colorsStatsCollector';
import { TeamLogo } from '../ScoreKeeper-new/Scoreboard';

const Score = ({ number }) => {
  return (
    <StyledScoreBox>
      <StyledScoreText>{number}</StyledScoreText>
    </StyledScoreBox>
  );
};

const Fouls = ({ number }) => {
  return (
    <StyledFoulBox>
      <StyledFoulText>{number}</StyledFoulText>
    </StyledFoulBox>
  );
};

const Scores = ({
  score = 0,
  numFouls = 0,
  teamImage,
  hasPossession,
  isHomeTeam,
  ...props
}) => {
  return (
    <StyledFlexDiv {...props}>
      <Grid>
        <div
          style={{
            gridRowStart: 'span 2',
            placeSelf: 'center start',
          }}
        >
          <TeamLogo src={teamImage} hasPossession={hasPossession} isHomeTeam={isHomeTeam}/>
        </div>
        <Fouls number={numFouls} />
        <StyledDivider orientation="vertical" />
        <Score number={score} />
        <StyledTitleText>Fouls</StyledTitleText>
        <StyledTitleText style={{ gridColumnStart: '4' }}>
          Points
        </StyledTitleText>
      </Grid>
    </StyledFlexDiv>
  );
};

const DIVIDER_WIDTH = '1px';
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr ${DIVIDER_WIDTH} 1fr;
  flex: 1;
  place-items: center;
`;

const StyledDivider = styled(Divider)`
  &&& {
    height: 100%;
    width: ${DIVIDER_WIDTH};
    background-color: ${colors.GRAY[1600]};
    opacity: 0.5;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme: { lightMode } }) =>
    lightMode ? colors.GRAY[600] : colors.BLACK[800]};
  padding: 8px 11px;
  border-radius: 10px;
  margin-bottom: 20px;
  ${({ reverseColumns }) => (reverseColumns ? "direction: rtl;" : "")};
`;

const StyledTitleText = styled.div`
  text-align: center;
  text-transform: uppercase;
  font: normal normal 500 11px/14px Inter;
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.GRAY[1700] : colors.GRAY[1075]};
`;

const StyledScoreBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledScoreText = styled.div`
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  font: normal normal bold 57px/57px Barlow;
`;

const StyledFoulBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font: normal normal 600 21px/28px Open Sans;
  width: 43px;
  height: 43px;
  background-color: ${({ theme: { lightMode } }) => lightMode ? colors.WHITE[100] : colors.GRAY[1800] };
  border-radius: 4px;
`;

const StyledFoulText = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: arial;
  color: ${({ theme: { lightMode } }) => lightMode ? colors.GRAY[1800] : colors.WHITE[100] };
`;

export default Scores;
