import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles';

const StyledRosterDiv = styled.div`
	max-height: 100vh;
	padding: 1vh 0vh 0vh 0vh;
	border-radius: 10px;
	height: 100%;
	border-width: 3px;
	border-color: #448fff;
	border-style: ${(props) => props.borderStyle};
	background-color: white;
	display: flex;
	
	align-items: center;
  justify-content: ${(props) => props.justifyContent};
	flex-direction: column;
	overflow-y: scroll;
	overflow: scroll;
	overflow-x: hidden;
	
	::-webkit-scrollbar {
		width: 13px;
	}

	//helpful resource: https://css-tricks.com/custom-scrollbars-in-webkit/

	/* Track */
	::-webkit-scrollbar-track {
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		border 4px ${colors.WHITE[100]} solid;
		background: ${colors.GRAY[450]};
	}
`;

const RosterOutline = ({
  children,
  drop,
  borderStyle = 'none',
  justifyContent,
}) => {
  return (
    <StyledRosterDiv
      borderStyle={borderStyle}
      ref={drop}
      justifyContent={justifyContent}
    >
      {children}
    </StyledRosterDiv>
  );
};

export default RosterOutline;
