import React from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'react-bootstrap';

const LaunchButtonGroupContainer = styled.div`
  z-index: 1;
  padding-right: 0px;
  width: auto;
`;

const LaunchButtonGroup = styled(ButtonGroup)`
  width: max-content;
  left: 0px;
`;

const LaunchFilterButton = styled(Button)`
  padding: 6px 0;
  margin-left: 6px;
  margin-right: 12px;
  text-align: left !important;
  border: 0;
  background-color: transparent !important;
  font: normal normal normal 14px/20px Inter;
  letter-spacing: 0px;
  color: #111111 !important;
  opacity: 1;
  font-size: 14px;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
  &:hover {
    border: 0;
    border-bottom: 2px solid #26a9e1;
  }
  &:active,
  &.active {
    font-weight: bold;
    border-color: transparent;
    border-bottom: 2px solid #26a9e1;
  }
  &:disabled {
    border: 0;
    border-bottom: 2px solid transparent;
  }
`;

const StatsTabs = ({
  gameStatsClickHandler = () => {},
  playByPlayStatsClickHandler = () => {},
  teamStatsClickHandler = () => {},
  playerStatsClickHandler = () => {},
  activeGameStatsStatus = false,
  activePlayByPlayStatsStatus = false,
  activeTeamStatsStatus = false,
  activePlayerStatsStatus = false
}) => {
  return (
    <LaunchButtonGroupContainer>
      <LaunchButtonGroup>
        {
        <LaunchFilterButton
          onClick={gameStatsClickHandler}
          active={activeGameStatsStatus}
        >
          Game Stats
        </LaunchFilterButton>
        }
        <LaunchFilterButton
          onClick={playByPlayStatsClickHandler}
          active={activePlayByPlayStatsStatus}
        >
          Play-By-Play
        </LaunchFilterButton>
        <LaunchFilterButton
          onClick={teamStatsClickHandler}
          active={activeTeamStatsStatus}
        >
          Team Stats
        </LaunchFilterButton>
        <LaunchFilterButton
          onClick={playerStatsClickHandler}
          active={activePlayerStatsStatus}
        >
          Player Stats
        </LaunchFilterButton>
      </LaunchButtonGroup>
    </LaunchButtonGroupContainer>
  );
};

export default StatsTabs;