import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { typeScale } from '../../styles/typography';
import { colors } from '../../styles';
import { DEFAULT_TEAMS_IMAGE } from '../../utils/constantsUtils';

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  backgroundcolor: transparent;
  padding: 5px;
  border: 2px solid
    ${(props) => (props.error ? 
                    colors.ERROR[100] : 
                      props.pendingUpdateStatus ? 
                        '#FF9419' : colors.GRAY[200])};
  border-radius: 5px;
`;

const ImageContainer = styled.div`
  height: 100%;
  max-height: ${(props) =>
    props.imageMaxHeight ? props.imageMaxHeight : '100%'};
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  position: relative;
`;

const AddCircleButtonStyled = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: ${colors.BLUE[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 100;
  border-width: 2px;
  border-style: solid;
  border-color: #74C7EB;
  &:hover {
    background-color: ${colors.PRIMARY[300]};
    cursor: pointer;
  }
`;

const PlusSignStyled = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.WHITE[100]};
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.BLACK[200]};
  opacity: 0.7;
`;

const ImageUploadComponent = ({
  label,
  onChange,
  id,
  name,
  value,
  error,
  errorMsg,
  formik,
  image,
  placeHolder,
  imageHeight,
  imageMaxHeight,
  disabled = false,
  pendingUpdateStatus = false, 
  isPendingImageUpdated = () => {},
}) => {
  const uploadImage = image ? image : placeHolder;
  const [uploadedImage, setUploadedImage] = useState(value ? value : uploadImage);
  const [imageError, setImageError] = useState(false);
  const [isPendingImageSelected, setIsPendingImageSelected] = useState(pendingUpdateStatus);

  useEffect(() => {
    isPendingImageUpdated(formik);
  }, [pendingUpdateStatus, isPendingImageSelected]);

  useEffect(() => {
    if(value) {
      const reader = new FileReader();

      reader.onload = () => {
        setImageError(false);
        setUploadedImage(reader.result);
        setIsPendingImageSelected(reader.result === DEFAULT_TEAMS_IMAGE);
      };
    
      reader.onerror = () => {
        //NOTE: WHAT TO DO IF THE IMAGE FAILS TO LOAD???
        setUploadedImage();
      };
    
      if (value && value instanceof Blob) {
        reader.readAsDataURL(value);
      }
      else if(typeof value === 'string') {
        setUploadedImage(value);
      }
    }
  }, [value])

  const useStyles = makeStyles({
    labelContainerStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 5,
      cursor: 'pointer',
    },
    inputLabelStyle: {
      fontWeight: 600,
      fontSize: typeScale.SMEDIUM.fontSize,
      color: colors.GRAY[450],
      marginLeft: 10,
      cursor: 'pointer',
      textAlign: '',
    },
    plusLabelStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.WHITE[100],
      fontSize: typeScale.SMEDIUM.fontSize,
      width: 20,
      height: 20,
      borderRadius: 10,
      backgroundColor: colors.BLUE[100],
    },
    imageSrcStyle: {
      cursor: 'pointer',
    },
    errorMsgStyle: {
      color: colors.ERROR[100],
      fontSize: typeScale.SMEDIUM.fontSize,
    },
  });
  const classes = useStyles();

  return (
    <div>
      <RowContainer error={imageError || error} pendingUpdateStatus={pendingUpdateStatus && isPendingImageSelected}>
        <input
          id={id}
          type="file"
          name={name}
          onChange={(value) => onChange(value, setImageError, formik)}
          error={imageError || error}
          accept=".jpg, .jpeg, .png, .gif"
          hidden
          disabled={disabled}
        />
        <ImageContainer imageMaxHeight={imageMaxHeight}>
          <label
            for={id}
            style={{
              display: 'flex',
              height: imageHeight ? imageMaxHeight :'100%',
              width: '100%',
              maxHeight: imageMaxHeight ? imageMaxHeight : '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              className={classes.imageSrcStyle}
              src={uploadedImage}
              style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: imageHeight ? imageMaxHeight :'inherit', }}
              onError={(event) => {
                event.target.src = placeHolder;
              }}
              alt=""
            />
            {(!value || isPendingImageSelected) && 
            (<>
            <ImageOverlay/>
            <AddCircleButtonStyled>
              <PlusSignStyled>+</PlusSignStyled>
            </AddCircleButtonStyled>
            </>)}
          </label>
        </ImageContainer>
        {/* <div className={classes.labelContainerStyle}>
				<div className={classes.plusLabelStyle}>+</div>
				<label for={id} className={classes.inputLabelStyle}>{label}</label>
			</div> */}
      </RowContainer>
      {(imageError || error) && <span className={classes.errorMsgStyle}>{errorMsg}</span>}
    </div>
  );
};

export default ImageUploadComponent;

ImageUploadComponent.defaultProps = {
  fullWidth: false,
  placeholder: '',
};

ImageUploadComponent.propTypes = {
  /**
   * Does the input field stretch horizontally?
   */
  fullWidth: PropTypes.bool,
  /*
	Sets the label of the input field - also sets id+name is none are provied
	*/
  label: PropTypes.string.isRequired,
  /*
	html attributes id and name
	*/
  id: PropTypes.string,
  name: PropTypes.string,
  // Placeholder text
  placeholder: PropTypes.string,
  //Controls value of input
  value: PropTypes.any,
  // onChange function-works with value to control component
  onChange: PropTypes.func,
  // error message - usually passed through Formik
  error: PropTypes.any,
  //Styles the width - set this if not using fullWidth prop
  width: PropTypes.number,
};
