import React, { useCallback } from 'react';
import { Row, Col, } from 'react-bootstrap';
import styled from 'styled-components';
import GamePeriodColumn from './GamePeriodColumn';
import PlayByPlayHeaders from './PlayByPlayHeaders';
import PlayByPlayEventDisplay from './PlayByPlayEventDisplay';
import { colors, } from '../../../styles';
import { GAME_POINTS } from '../../../utils/constantsUtils';
import usePeriodPlayByPlayEvent from '../../../utils/usePeriodPlayByPlayEvent';
import Spinner from '../../../components/Spinner';

const PlayByPlayEventsContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: ${props => props.contentMinHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  position: relative;
`;

const EmptyEvents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayByPlayControl = ({
  game,
  gameId,
  periodEventParams,
  setPeriodEventParams,
  playByPlayEventParams,
  setPlayByPlayEventParams,
  periodColumnWidth,
  teamAssets,
  contentHeight,
}) => {
  const { selectedPeriodEvents, periodRelatedEvents, isPeriodEventsLoading } = usePeriodPlayByPlayEvent(
    'gamePeriodPlayByPlayEventResponse', 
    playByPlayEventParams, 
    teamAssets
  );

  const activateGamePeriodColumnHandler = useCallback((period, periodParams, gameId, periodNumber, periodType) => {
    periodParams = periodParams.map(gamePeriod => {
      gamePeriod.isSelected = gamePeriod.period === period;
      return gamePeriod;
    });
    setPeriodEventParams([...periodParams]);
    setPlayByPlayEventParams({ gameId, periodNumber, periodType });
  }, [playByPlayEventParams, periodEventParams]);


  const GamePeriodColumns = ({
    periodParams, 
    columnWidth, 
    gameId, 
  }) => {
    return (
      <>
        {periodParams?.map((periodEventParameter, index) => {
          return (
            <GamePeriodColumn
              key={`game-column_period_${index}`}
              gameId={gameId}
              columnIndex={index}
              columnWidth={columnWidth}
              periodEventParamter={periodEventParameter}
              periodParams={periodParams}
              periodColumnHandler={activateGamePeriodColumnHandler}
            />
          )
        })}
      </>
    )
  };

  const PlayByPlayEvents = ({
    game, 
    selectedPeriodEvents,
    periodRelatedEvents,
    periodEventParams,
    minimumContentHeight = 0,
    isPeriodEventsLoading,
  }) => {
  const getTeamScore = (eventType, eventTeamId, teamId) => {
    eventType = eventTeamId === teamId ? eventType : null;
    switch(eventType) {
      case 'FREE_THROW_MADE':
      case 'TWO_POINT_MADE':
      case 'THREE_POINT_MADE':
        return parseInt(GAME_POINTS[eventType]);
      default:
        return 0;
    };
  };

  const getHomeTeamCummulativeScore = (eventType, eventTeamId, homeTeamId) => {
    return getTeamScore(eventType, eventTeamId, homeTeamId);
  };

  const getAwayTeamCummulativeScore = (eventType, eventTeamId, awayTeamId) => {
    return getTeamScore(eventType, eventTeamId, awayTeamId);
  };

  const getRelatedEventCummulativeFreeThrowScore = (periodRelatedEvents, eventId, eventTeamId, teamId, cummulativeScoreFunc) => {
    const relatedEvent = periodRelatedEvents.find(({ relatedEventId, eventType }) => relatedEventId === eventId && eventType === 'FREE_THROW_MADE');
    return relatedEvent
            ? cummulativeScoreFunc(relatedEvent.eventType, eventTeamId, teamId) + getRelatedEventCummulativeFreeThrowScore(periodRelatedEvents, relatedEvent.id, eventTeamId, teamId, cummulativeScoreFunc)
            : 0;
  };

  let homeTeamScore = 0;
  let awayTeamScore = 0;

  const getPlayByPlayEventsContent = (game, selectedPeriodEvents, periodRelatedEvents) => {
    return (
        selectedPeriodEvents?.length > 0
        ?
        selectedPeriodEvents.map((playByPlayItem, index) => {
          const { eventType, teamId } = playByPlayItem;

          homeTeamScore += getRelatedEventCummulativeFreeThrowScore(periodRelatedEvents, playByPlayItem.id, teamId, game?.homeTeamId, getHomeTeamCummulativeScore);
          awayTeamScore += getRelatedEventCummulativeFreeThrowScore(periodRelatedEvents, playByPlayItem.id, teamId, game?.awayTeamId, getAwayTeamCummulativeScore);

          homeTeamScore += getHomeTeamCummulativeScore(eventType, teamId, game?.homeTeamId);
          awayTeamScore += getAwayTeamCummulativeScore(eventType, teamId, game?.awayTeamId);
  
          return (
            <PlayByPlayEventDisplay
              key={`play_by_play_item_index_${index}`}
              itemIndex={index}
              homeTeamId={game?.homeTeamId}
              playByPlayItem={playByPlayItem}
              relatedEvents={periodRelatedEvents}
              homeTeamScore={homeTeamScore}
              awayTeamScore={awayTeamScore}
            />
            )
          })
        :
        <EmptyEvents>
          {`There are no events for this ${periodEventParams.find(({ isSelected }) => isSelected)?.period} game period.`}
        </EmptyEvents>
    )
  };

  return (
    <PlayByPlayEventsContainer
      contentMinHeight={minimumContentHeight}
      justifyContent={(periodEventParams?.length === 0 || selectedPeriodEvents?.length === 0) ? 'center' : 'flex-start'}
    >
      {
        periodEventParams?.length > 0
        ?
          isPeriodEventsLoading
          ?
            <Spinner 
              position="absolute"
            />
          :
            getPlayByPlayEventsContent(game, selectedPeriodEvents, periodRelatedEvents)
        :
        <EmptyEvents>
          There are no events for this game.
        </EmptyEvents>
      }
    </PlayByPlayEventsContainer>
    );
  };

  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, }} className='mt-2'>
      <Col xs={12} className='p-0 m-0' style={{ backgroundColor: colors.WHITE[100], borderTopLeftRadius: 10, borderTopRightRadius: 10, position: 'relative', }}>
        <div style={{ width: '100%', height: 50, display: 'flex', flexDirection: 'row', }}>
          <GamePeriodColumns 
            periodParams={periodEventParams} 
            columnWidth={periodColumnWidth} 
            gameId={gameId} 
          />
        </div>
        <div style={{ width: '100%', height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', }}>
          <PlayByPlayHeaders
            homeTeamImage={game?.homeTeamImage}
            awayTeamImage={game?.awayTeamImage}
          />
        </div>
        <PlayByPlayEvents 
          game={game} 
          selectedPeriodEvents={selectedPeriodEvents}
          periodRelatedEvents={periodRelatedEvents}
          periodEventParams={periodEventParams}
          minimumContentHeight={contentHeight}
          isPeriodEventsLoading={isPeriodEventsLoading}
        />
      </Col>
    </Row>
  )
};

export default PlayByPlayControl;