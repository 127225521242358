import React from 'react';
import { StatsTitleHeader } from '../team/StatsTitleHeader';
import { SummaryTeamStats } from '../team/SummaryTeamStatsContainer';
import { ViewStats, ViewStatsItem } from '../team/TeamViewStats';
import GameTeamStatsPercentages from './GameTeamStatsPercentages';

const GameTeamStats = ({
  setStatsTab = () => {},
}) => {
  return (
    <SummaryTeamStats
      height={'auto'}
      marginTop={10}
    >
      <StatsTitleHeader
        justifyContent="center"
        alignItems="flex-start"
        borderWidth={1}
      >
        Team Stats
      </StatsTitleHeader>
      <GameTeamStatsPercentages
      />
      <ViewStats
        paddingTop='3px'
        paddingBottom='3px'
      >
        <ViewStatsItem
          onClick={() => setStatsTab('team_stats')}
        >
          View Team Stats
        </ViewStatsItem>
      </ViewStats>
    </SummaryTeamStats>
  );
};

export default GameTeamStats;