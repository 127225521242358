import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colorsStatsCollector';
import { capitalizeFirstLetter } from '../../utils/stringUtil';
import { StyledFlexRow } from '../../styles';
import StyledButton from './components/StyledButton';
import {
  canGameTimerBeStartedJumpball,
  isFinalQuarterEnded,
  isGameNotStarted,
  isQuarterEnded,
} from '../../utils/gameTimeUtil';

const log = require('../../logger')('JumpballTimeoutControl', 'debug');

const StyledTeamDiv = styled.div`
  padding: 19px 15px;
  border: ${({ $isActive }) =>
    $isActive
      ? `2px solid ${colors.BLUE[100]}`
      : `1px solid ${colors.GRAY_TRANSPARENT[750]}`};
  border-radius: 20px;
  z-index: 2;
  background-color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.GRAY[100] : colors.BLACK[100]};
`;

const StyledTeamDiv2 = styled.div`
  text-align: center;
`;

const ButtonWithLine = styled(StyledButton)`
  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    width: 50px;
    height: 2px;
    background: ${({ selected }) =>
      selected ? colors.BLUE[100] : colors.GRAY[1700]};
    z-index: -1;
    display: block;
  }
`;

const TeamButton = styled(StyledButton)`
  font-weight: normal;
`;

const StyledTimeoutDiv = styled.div`
  height: calc(40px + 1vh);
  width: calc(40px + 1vh);
  align-items: center;
  padding: 2px;
  justify-content: center;
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  border: 2px solid ${colors.GRAY[1000]};
  border-radius: 8px;
  font: normal normal 600 19px/26px Open Sans;
  letter-spacing: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeoutLabel = styled.div`
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  font: normal normal 500 14px/19px Open Sans;
  margin-bottom: 3px;
  margin-top: -3px;
`;

const JumpballTimeoutControl = ({
  benchCourtPlayersSelected,
  enableOverTimeStart,
  gameTeamStats,
  numTimeouts,
  gameData,
  gameTimer,
  isClockRunning,
  homeTeam,
  awayTeam,
  setIsRecordingJumpballOrTimeout,
  numPeriods,
  onJumpball,
  onTimeout,
  disabled,
}) => {
  /** jumpball selected when jumpball button clicked */
  const [jumpballSelected, setJumpballSelected] = useState(false);

  /** timeout selected when timeout button clicked */
  const [timeoutSelected, setTimeoutSelected] = useState(false);

  /** timeout disabled based on timer */
  const [timeoutDisabled, setTimeoutDisabled] = useState(false);

  /** home team disabled based on timeouts remaining */
  const [homeTeamDisabled, sethomeTeamDisabled] = useState(true);

  /** away team disabled based on timeouts remaining */
  const [awayTeamDisabled, setawayTeamDisabled] = useState(true);

  /** Whether either team button has been selected by the user */
  const [homeTeamSelected, setHomeTeamSelected] = useState(false);
  const [awayTeamSelected, setAwayTeamSelected] = useState(false);

  /** total number of home team and away team timeouts left */
  const homeTeamTimeouts = gameTeamStats?.home_timeouts;
  const awayTeamTimeouts = gameTeamStats?.away_timeouts;

  useEffect(() => {
    log.debug('timeouts remaining', { homeTeamTimeouts, awayTeamTimeouts });
  }, [awayTeamTimeouts, homeTeamTimeouts]);

  useEffect(() => {
    if (timeoutSelected) {
      sethomeTeamDisabled(homeTeamTimeouts === 0);
      setawayTeamDisabled(awayTeamTimeouts === 0);
    } else if (jumpballSelected) {
      sethomeTeamDisabled(false);
      setawayTeamDisabled(false);
    } else {
      sethomeTeamDisabled(true);
      setawayTeamDisabled(true);
    }
  }, [timeoutSelected, jumpballSelected, homeTeamTimeouts, awayTeamTimeouts]);

  /**
   * Disables the timeout button when jumpball is selected, the game is not 
   * running, or both teams are out of remaining timeouts
   */
  useEffect(() => {
    jumpballSelected ||
    (homeTeamTimeouts?.toString() === '0' &&
      awayTeamTimeouts?.toString() === '0') ||
    !isClockRunning
      ? setTimeoutDisabled(true)
      : setTimeoutDisabled(false);
  }, [
    awayTeamTimeouts,
    homeTeamTimeouts,
    isClockRunning,
    jumpballSelected,
    numTimeouts,
  ]);

  /** On jumpball and timeout events, set the state jumpball/timeout selected 
   * state to enable team selection and the state to tell the CM that we are
   * in a jumpball/timeout selection routine */
  const OnActionClick = (isTimeout, setSelected, selected) => {
    setSelected(!selected);
    setIsRecordingJumpballOrTimeout(!selected);
  };

  /** Selected the team for jumpball and timeout */
  const onTeamClick = async (team) => {
    const teamId =
      team === homeTeam ? gameData?.homeTeam?.id : gameData?.awayTeam?.id;

    /** Show the appropriate team button as selected in UI */
    team === homeTeam ? setHomeTeamSelected(true) : setAwayTeamSelected(true);

    /** Call Jumpball/Timeout callback */
    if (jumpballSelected) {
      await onJumpball(teamId);
    } else {
      await onTimeout(teamId);
    }

    /** Reset UI state */
    setIsRecordingJumpballOrTimeout(false);
    setJumpballSelected(false);
    setTimeoutSelected(false);
    setHomeTeamSelected(false);
    setAwayTeamSelected(false);
  };

  /** Disable the Jumpball button at the end/start of a quarter that is not the  */
  const disableJumpballOnQuarterEnded =
    isQuarterEnded(gameTimer) && !isFinalQuarterEnded(gameTimer, numPeriods);
  const disableOvertimeJumpballWhenNotStarted =
    !isGameNotStarted(gameTimer) &&
    canGameTimerBeStartedJumpball(gameTimer, numPeriods) &&
    !enableOverTimeStart;

  const isJumpballDisabled = () => {
    return (
      disabled ||
      timeoutSelected ||
      isClockRunning ||
      !benchCourtPlayersSelected ||
      disableJumpballOnQuarterEnded ||
      disableOvertimeJumpballWhenNotStarted
    );
  };

  const isTimeoutDisabled = () => {
    return disabled || timeoutDisabled;
  };

  return (
    <StyledFlexRow style={{ padding: 0 }}>
      <StyledTeamDiv
        className="col-md-4 px-0"
        style={{ border: 'none', background: 'none', marginTop: 1, zIndex: 1 }}
      >
        {/* Jumpball Button */}
        <ButtonWithLine
          onClick={() =>
            OnActionClick(false, setJumpballSelected, jumpballSelected)
          }
          selected={jumpballSelected}
          disabled={isJumpballDisabled()}
        >
          Jumpball
        </ButtonWithLine>

        <div className="mt-4">
          {/* Timeout Button */}
          <ButtonWithLine
            onClick={() =>
              OnActionClick(true, setTimeoutSelected, timeoutSelected)
            }
            selected={timeoutSelected}
            disabled={isTimeoutDisabled()}
          >
            Timeout
          </ButtonWithLine>
        </div>
      </StyledTeamDiv>

      <div className="col-md-5 px-0" style={{ zIndex: 2 }}>
        <StyledTeamDiv $isActive={!homeTeamDisabled || !awayTeamDisabled}>
          {/* Home Team Button */}
          <div>
            <TeamButton
              onClick={() => onTeamClick(homeTeam)}
              selected={homeTeamSelected}
              disabled={homeTeamDisabled}
            >
              {capitalizeFirstLetter(
                homeTeam ? homeTeam.substring(0, 18) : 'home team'
              )}
            </TeamButton>
          </div>
          {/* Away Team Button */}
          <div className="mt-4">
            <TeamButton
              onClick={() => onTeamClick(awayTeam)}
              selected={awayTeamSelected}
              disabled={awayTeamDisabled}
            >
              {capitalizeFirstLetter(
                awayTeam ? awayTeam.substring(0, 18) : 'away team'
              )}
            </TeamButton>
          </div>
        </StyledTeamDiv>
      </div>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <StyledTeamDiv2>
          <TimeoutLabel>T/O</TimeoutLabel>
          {/* Home Timeouts */}
          <StyledTimeoutDiv>
            {homeTeamTimeouts ?? numTimeouts}
            <span style={{ margin: '0 2px' }}>/</span>
            {numTimeouts}
          </StyledTimeoutDiv>
          {/* Away Timeouts */}
          <StyledTimeoutDiv className="mt-4">
            {awayTeamTimeouts ?? numTimeouts}
            <span style={{ margin: '0 2px' }}>/</span>
            {numTimeouts}
          </StyledTimeoutDiv>
        </StyledTeamDiv2>
      </div>
    </StyledFlexRow>
  );
};

export default JumpballTimeoutControl;
