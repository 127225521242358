import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Button } from '@material-ui/core';
import { colors, gradients } from '../../../styles/colorsStatsCollector';
import { useContext } from 'react';
import { ActionTypes } from '../ActionTypes.type';

const ViolationScreen = ({ options }) => {
  const lightMode = useContext(ThemeContext).lightMode;

  return (
    <Container>
      <div
        style={{
          textAlign: 'center',
          font: 'normal normal normal 18px/24px Open Sans',
          letterSpacing: '-0.36px',
          color: lightMode ? colors.GRAY[2300] : colors.WHITE[100],
          marginBottom: 40,
        }}
      >
        Select a type of violation
      </div>
      <ButtonContainer>
        {options.map(({ onClick, primaryLabel, secondaryLabel }) => (
          <OptionButton
            onClick={onClick}
            key={`${primaryLabel}${secondaryLabel}`}
            actionType={ActionTypes.Fouls}
          >
            <div>{primaryLabel}</div>
            <div>{secondaryLabel}</div>
          </OptionButton>
        ))}
      </ButtonContainer>
    </Container>
  );
};

ViolationScreen.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      primaryLabel: PropTypes.string,
      secondaryLabel: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, auto));
  justify-items: center;
  row-gap: 36px;
  column-gap: 29px;

  > button {
    margin: 0 0.5em;
  }
`;

const OptionButton = styled(Button)`
  &&& {
    width: 159px;
    height: 57px;
    border-radius: 5px;
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[100] : gradients.BLACK};
    color: ${({ theme }) =>
      theme.lightMode ? colors.BLACK[100]
      : colors.GRAY[100]};
    box-shadow: 5px 5px 5px ${colors.GRAY_TRANSPARENT[600]};
    border: 1px solid ${({ actionType }) => actionType.color};
    text-transform: uppercase;
    font: normal normal 600 12px/18px Open Sans;
    letter-spacing: 0px;

    &:hover,
    &:focus,
    &:active {
      background: ${({ $isActive, actionType, theme }) =>
        $isActive
          ? `${actionType.color}`
          : theme.lightMode
          ? colors.GRAY[100]
          : colors.BLACK[1000]};
    }
  }
`;

export default ViolationScreen;
