import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typeScale } from '../../styles/typography';
import { buttonThemes } from '../../styles/themes';
import { Button } from 'react-bootstrap';

/**
 * Theme variables expected in props.theme:
 *    backgroundColor
 *    hoverColor
 *    border
 *    borderHover
 *    textColor
 */
const StyledButton = styled(Button)`
  &&& {
    background: ${(props) =>
      props.disabled
        ? buttonThemes.GRAY.disabledColor
        : props.theme.backgroundColor};
    border: ${(props) =>
      props.disabled ? buttonThemes.GRAY.border : props.theme.border};
    color: #fff;
    margin-right: 16px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    height: ${(props) => props.height || '50px'};
    font-family: Inter;
    font-size: ${typeScale.MEDIUM.fontSize};
    color: ${(props) => props.theme.textColor};
    align-content: center;
    opacity: 1;
    cursor: pointer;
    &.dimmed {
      opacity: 0.6;
    }
    &:hover {
      background: ${(props) => props.theme.hoverColor};
      border: ${(props) => props.theme.borderHover};
    }
    &.outlined {
      background: ${(props) => props.theme.backgroundColor};
      color: ${(props) => props.theme.textColor};
      border: ${(props) => props.theme.border};
      box-shadow: none;
    }
    &.outlined &:hover {
      background: ${(props) => props.theme.hoverColor};
      border: ${(props) => props.theme.borderHover};
    }
  }
`;

const ButtonElement = ({
  label,
  minWidth,
  dimmed,
  outlined,
  hidden,
  icon,
  styleWithIcon = {},
  ...props
}) => {
  const classDimmed = !!dimmed ? 'dimmed' : '';
  const classOutlined = !!outlined ? 'outlined' : '';
  const classHidden = !!hidden ? 'invisible' : 'visible';
  return (
    <StyledButton
      className={`${classDimmed} ${classOutlined} ${classHidden}`}
      style={{ minWidth: `${minWidth}`, ...styleWithIcon }}
      {...props}
    >
      {icon && <img src={icon} width="20px" height="20px" alt="" />}
      {label}
    </StyledButton>
  );
};

export default ButtonElement;

ButtonElement.propTypes = {
  /**
   * Specify the button type
   */
  type: PropTypes.string,
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  theme: PropTypes.object,
  /**
   * Use Outlined Button
   */
  outlined: PropTypes.bool,
  /**
   * Should Hide Button
   */
  hidden: PropTypes.bool,
  /**
   * Button size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

ButtonElement.defaultProps = {
  theme: buttonThemes.BLUE,
  type: 'button',
  size: 'medium',
  minWidth: '160px',
  onClick: undefined,
  outlined: false,
  hidden: false,
};
