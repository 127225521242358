import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { colors, gradients } from '../../styles/colors';
import {
  colors as colorsNew,
  gradients as gradientsNew,
} from '../../styles/colorsStatsCollector';
import { typeScale } from '../../styles/typography';
import ClockManagerEvents from './ClockManagerEvents';
import { resumeClock, pauseClock, startClock } from '../../api/clockService';
import {
  canGameTimerBeResumed,
  canGameTimerBeStarted,
  canGameTimerBeStartedJumpball,
  formatGameClock,
  GameTimerStatus,
  getNextPeriodText,
  isFinalQuarterEnded,
  isGameNotStarted,
  isOvertimeEnded,
  isQuarterEnded,
} from '../../utils/gameTimeUtil';
import { GAME_STATUS } from '../../utils/constantsUtils';
import logger from '../../logger';
import { useSelector } from 'react-redux';
import { selectLightMode } from '../../redux/themeSlice';

const log = logger('ClockDiv', 'info');


const ContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledClock = styled.div`
  height: 47px;
  border-radius: 4px;
  width: 49%;
  min-width: fit-content;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font: normal normal normal 33px/29px 'Digital Display';
  letter-spacing: 5px;
  background: ${({ disabled, theme }) =>
    !disabled
      ? colorsNew.BLUE[100]
      : theme.lightMode
      ? colorsNew.GRAY[1000]
      : colorsNew.GRAY[1800]};
  color: ${colorsNew.WHITE[100]};
`;

const StyledStartButton = styled.button`
  display: flex;
  grid-template-areas: 'left right';
  grid-template-rows: 100%;

  grid-template-columns: auto 60%;
  background: ${(props) => props.linearGradient};
  color: white;
  line-height: ${typeScale.LMEDIUM.lineHeight};
  height: 47px;
  width: 49%;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: none;
`;

const StyledText = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  grid-area: right;
  font: normal normal 500 18px/24px Open Sans;
`;

const StyledArrowIcon = styled.div`
  font-size: 24px;
  font-familiy: arial;
  grid-area: left;
  display: flex;
  align-self: center;
  justify-self: end;
  margin-right: 3px;
`;

const ClockDiv = ({
  disableStartButton,
  numPeriods,
  callCreateEvent,
  gameId,
  updateGameStatus,
  disabled,
  gameTimer,
}) => {
  const lightMode = useSelector(selectLightMode);
  const [time, setTime] = useState('00:00');

  /** Is this a overtime start event, defined by the final quarter end or end of another overtime period*/
  const isOvertimeEvent =
    gameTimer?.timerStatus?.includes('Overtime') ||
    (gameTimer?.timerStatus?.includes(GameTimerStatus.QUARTER_ENDED) &&
      gameTimer?.quarter === numPeriods);

  const eventQuarter = isOvertimeEvent
    ? gameTimer?.overtimeTracker
      ? gameTimer?.overtimeTracker
      : 1
    : gameTimer?.quarter;

  const minutes = gameTimer?.minutes;
  const seconds = gameTimer?.seconds;
  const gameRunning = [
    GameTimerStatus.QUARTER_RUNNING,
    GameTimerStatus.OVERTIME_RUNNING,
  ].includes(gameTimer?.timerStatus);

  const TEXT_BTN_START = 'START';
  const TEXT_BTN_PAUSE = 'PAUSE';
  const TEXT_BTN_RESUME = 'RESUME';

  useEffect(() => {
    setTime(formatGameClock(minutes, seconds));
  }, [minutes, seconds]);

  /** clicking the start/resume/pause button */
  const handleClick = async (buttonText) => {
    if (buttonText === TEXT_BTN_PAUSE) {
      await pauseClock(gameId, isOvertimeEvent);
      await callCreateEvent({
        eventType: ClockManagerEvents.GAME_PAUSED,
        overTime: isOvertimeEvent,
        quarter: eventQuarter,
        eventMinutes: gameTimer?.minutes,
        eventSeconds: gameTimer?.seconds,
      });
    } else if (buttonText === TEXT_BTN_RESUME) {
      await resumeClock(gameId, isOvertimeEvent);
      await callCreateEvent({
        eventType: ClockManagerEvents.GAME_RESUMED,
        overTime: isOvertimeEvent,
        quarter: eventQuarter,
      });
    } else if (buttonText === TEXT_BTN_START) {
      const eventType =
        isQuarterEnded(gameTimer) || isOvertimeEnded(gameTimer)
          ? `${getNextPeriodText(gameTimer, numPeriods)} STARTED`
          : ClockManagerEvents.GAME_STARTED;
      await startClock(gameId, isOvertimeEvent);
      await callCreateEvent({
        eventType,
        quarter:
          isQuarterEnded || isOvertimeEnded ? eventQuarter + 1 : eventQuarter,
        overTime: isOvertimeEvent,
      });

      if (eventType === ClockManagerEvents.GAME_STARTED)
        updateGameStatus(GAME_STATUS.LIVE);
    } else {
      return null;
    }
  };

  /** button text as start/pause/resume based on clock timer and other criteria */
  const StartButton = () => {
    const gameNotStarted = isGameNotStarted(gameTimer);

    const buttonText =
      gameTimer &&
      (canGameTimerBeStarted(gameTimer)
        ? TEXT_BTN_START
        : canGameTimerBeResumed(gameTimer)
        ? TEXT_BTN_RESUME
        : TEXT_BTN_PAUSE);

    const isStartButtonDisabled = () => {
      return (
        disabled ||
        disableStartButton ||
        gameNotStarted ||
        canGameTimerBeStartedJumpball(gameTimer, numPeriods)
      );
    };

    return (
      <StyledStartButton
        disabled={isStartButtonDisabled()}
        linearGradient={
          isStartButtonDisabled()
            ? (lightMode
              ? colorsNew.GRAY[1000]
              : colorsNew.GRAY[1800])
            : buttonText === TEXT_BTN_PAUSE
            ? gradients.YELLOW
            : gradients.GREEN
        }
        onClick={() => handleClick(buttonText)}
      >
        <StyledArrowIcon>
          {buttonText === TEXT_BTN_START || buttonText === TEXT_BTN_RESUME ? (
            <PlayArrowIcon fontSize="inherit" />
          ) : (
            <PauseIcon style={{ color: 'black' }} fontSize="inherit" />
          )}
        </StyledArrowIcon>
        <StyledText
          style={{
            color:
              buttonText === TEXT_BTN_START || buttonText === TEXT_BTN_RESUME
                ? '#fffff'
                : 'black',
          }}
        >
          {buttonText}
        </StyledText>
      </StyledStartButton>
    );
  };

  return (
    <ContainerDiv>
      <StartButton />
      <StyledClock disabled={!gameRunning}>{time}</StyledClock>
    </ContainerDiv>
  );
};

export default ClockDiv;
