import { useInfiniteQuery } from 'react-query';
import { getGamePeriodPlayEvents, getGameOvertimePeriodPlayEvents } from '../api/eventsService';
import { LIST_PAGINATION_LIMIT } from './constantsUtils';
import { useFetchAll, useItemsFromPages } from './reactQueryToolkit';

const GAME_PERIODS = {
  1: '1ST QUARTER',
  2: '2ND QUARTER',
  3: '3RD QUARTER',
  4: '4TH QUARTER',
  '1H': '1ST HALF',
  '2H': '2ND HALF',
};

const getGamePeriodList = async (gameId, nextToken) => {
  const gamePeriodList = await getGamePeriodPlayEvents({
    limit: LIST_PAGINATION_LIMIT.LARGE,
    nextToken: nextToken,
    gameId: gameId
  });
  return gamePeriodList;
};

const getOvertimePeriodList = async (gameId, nextToken) => {
  const overtimePeriodList = await getGameOvertimePeriodPlayEvents({
    limit: LIST_PAGINATION_LIMIT.LARGE,
    nextToken: nextToken,
    gameId: gameId,
    gameOvertimeNumber: {
      gt: 0
    }
  });
  return overtimePeriodList;
};

const useGamePeriods = (
  gameId, 
) => {
  const { onSuccessCallback } = useFetchAll(true);

  const gameTimePeriodListResponse = useInfiniteQuery(
    ["gamePeriodsList", { gameId }], 
    async ({ pageParam }) => {
      const gameTimePeriods = await getGamePeriodList(gameId, pageParam);
      return gameTimePeriods;
    }, 
    {
      enabled: !!gameId,
      onSuccess: (data) => onSuccessCallback(gameTimePeriodListResponse)(data),
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken
    }
  );

  const overTimePeriodListResponse = useInfiniteQuery(
    ["overtimePeriodsList", { gameId }], 
    async ({ pageParam }) => {
      const overTimePeriods = await getOvertimePeriodList(gameId, pageParam);
      return overTimePeriods;
    }, 
    {
      enabled: !!gameId,
      onSuccess: (data) => onSuccessCallback(overTimePeriodListResponse)(data),
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    }
  );

    let gamePeriods = useItemsFromPages(gameTimePeriodListResponse.data?.pages)
                        ?.filter(({ gamePeriodNumber }) => gamePeriodNumber !== null)
                        ?.map(({ gamePeriodNumber }) => gamePeriodNumber)
                        ?.sort((a, b) => a - b);

    gamePeriods = Array.from( new Set(gamePeriods) );

    let overtimePeriods = useItemsFromPages(overTimePeriodListResponse.data?.pages)
                            ?.filter(({ gameOvertimeNumber }) => gameOvertimeNumber !== null)
                            ?.map(({ gameOvertimeNumber }) => gameOvertimeNumber)
                            ?.sort((a, b) => a - b);

    overtimePeriods = Array.from( new Set(overtimePeriods) );

    const gamePeriodEventParamters = gamePeriods.map((gp, index) => ({ period: `Q${gp}`, periodNumber: gp, periodType: 'gamePeriodNumber', isSelected: (index === 0) }));

    const gamePeriodItems = gamePeriods.map(gp => ({ periodItem: GAME_PERIODS[gp], periodValue: { gamePeriodNumber: gp }, period: `Q${gp}`, }));

    const overtimePeriodEventParameters = overtimePeriods.map(ot => ({ period: `OT${ot}`, periodNumber: ot, periodType: 'gameOvertimeNumber', isSelected: false }));

    const overtimePeriodItems = overtimePeriods.map(ot => ({ periodItem: `OVERTIME ${ot}`, periodValue: { gameOvertimeNumber: ot }, period: `OT${ot}`, }));

    return {
      gamePeriods, 
      overtimePeriods, 
      initialPeriodEventParams: [ ...gamePeriodEventParamters, ...overtimePeriodEventParameters ],
      periodOptions: [ {  periodItem: 'ALL PERIODS', periodValue: '', period: 'All Periods', }, ...gamePeriodItems, ...overtimePeriodItems ]
    };
};

export default useGamePeriods;