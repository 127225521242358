import React, { useContext } from 'react';
import { StatsTitleHeader } from '../team/StatsTitleHeader';
import { SummaryTeamStats } from '../team/SummaryTeamStatsContainer';
import { ViewStats, ViewStatsItem } from '../team/TeamViewStats';
import GamePlayerStatsData from './GamePlayerStatsData';
import { GameStatsContext } from './GameStatsContext';

const GamePlayerStats = ({
}) => {
  const { 
    setStatsTab, 
  } = useContext(GameStatsContext);

  return (
    <SummaryTeamStats
      height={'auto'}
    >
      <StatsTitleHeader
        justifyContent="center"
        alignItems="flex-start"
        borderWidth={1}
      >
        Player Stats
      </StatsTitleHeader>
      <GamePlayerStatsData
      />
      <ViewStats
        paddingTop='3px'
        paddingBottom='3px'
      >
        <ViewStatsItem
          onClick={() => setStatsTab('player_stats')}
        >
          View Player Stats
        </ViewStatsItem>
      </ViewStats>
    </SummaryTeamStats>
  );
};

export default GamePlayerStats;