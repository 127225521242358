import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageContent from './PageContent';
import HorizontalGrid from '../Grids/HorizontalGrid';
import { colors } from '../../styles';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import {
  SearchMenuDiv,
  SearchMenuMainCriteria,
  // SearchMenuSubCriteria,
  SearchMenuSubCriteriaDark,
  getHighlightedText,
} from '../../utils/searchUtils';
import InfiniteScrollList from '../InfiniteListScroll';

const horizontalGridContentStyle = {
  width: '519px',
  height: '211px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 15,
};

const imageContainerStyle = {
  width: '100%',
  height: 150,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  border: '1px solid #e5e5e5',
  borderRadius: '5px',
};

const imageStyle = {
  opacity: 1,
  objectFit: 'cover',
  width: 'inherit',
  height: 'inherit',
  // border: '1px solid rgb(229, 229, 229)',
  borderRadius: 5,
};

const venueInfoContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const venueInfoItemStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const labelItem = {
  fontWeight: 'normal',
  fontFamily: 'Inter',
  fontSize: '11px',
  lineHeight: '20px',
  marginTop: '-7px',
  color: '#5A5A5A',
};

const venueData = {
  lineHeight: '32px',
  fontSize: '28px',
  fontFamily: 'Barlow Condensed',
  fontWeight: '600',
  color: '#434343',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const locationData = {
  fontSize: '14px',
  lineHeight: '20px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  color: '#434343',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '160px',
};

const locationDataState = {
  fontSize: '14px',
  lineHeight: '20px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  color: '#434343',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const activeStatusContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px 0px 10px 12px',
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const dividerStyle = {
  marginTop: '10px',
  marginBottom: '10px',
  borderBottom: '1px solid rgb(112 112 112 / 20%)',
  width: '80%',
};

const noLeftGutter = {
  paddingLeft: '0px',
};

const noRightGutter = {
  paddingRight: '0px',
};

const VenueItem = ({
  id,
  name,
  city,
  state,
  zipcode,
  image,
  // games,
  active,
  editVenueCtrl,
}) => {
  return (
    <HorizontalGrid
      bgColor={colors.WHITE[100]}
      borderColor={colors.GRAY[150]}
      width={'440px'}
      height={'211px'}
    >
      <Row style={horizontalGridContentStyle}>
        <Col>
          {/* Row Containin active status */}
          <Row>
            <Col style={activeStatusContainer}>
              <div
                style={{
                  ...activeStatusIndicator,
                  backgroundColor: active ? '#2DCC38' : '#EE3B3B',
                }}
              ></div>
              <span style={activeStatusText}>
                {active ? 'Active' : 'Inactive'}
              </span>
            </Col>
          </Row>
          <Row>
            {/* Column containing image container */}
            <Col sm="6" className="align-self-end" style={noRightGutter}>
              <div style={imageContainerStyle}>
                <img src={image} style={imageStyle} alt="" />
              </div>
            </Col>
            {/* Column containing Venue name and Location */}
            <Col
              style={{ ...venueInfoContainerStyle }}
              className="align-items-start"
            >
              <div style={venueInfoItemStyle}>
                <label style={labelItem}>Venue</label>
                <div style={{ ...venueData }}>{name}</div>
              </div>
              <div style={dividerStyle}></div>
              <div
                style={{
                  ...venueInfoItemStyle,
                  // paddingTop: 20,
                  paddingTop: 0,
                }}
              >
                <label style={labelItem}>Location</label>
                <Row className={'flex-nowrap'}>
                  <Col
                    style={{ ...locationData, ...noRightGutter }}
                    sm="auto"
                  >{`${city}`}</Col>
                  <Col style={{ ...locationDataState, ...noLeftGutter }} sm="4">
                    {`, ${state}`}
                  </Col>
                </Row>
                <div style={locationData}>{zipcode}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <OptionsOverflowMenu
        menuItems={[{ label: 'Edit', onClick: () => editVenueCtrl(id) }]}
      />
    </HorizontalGrid>
  );
};

const VenueContent = ({
  searchId,
  placeholder,
  data,
  handleEditModalOpenHook,
  fetchNextPage,
  hasNextPage,
  isFetching,
  onSearch,
}) => {
  const [displayedVenues, setDisplayedVenues] = useState([]);
  const [venueSearch, setVenueSearch] = useState('');
  const searchFilter = (option, search) =>
    option.name.toLowerCase().includes(search.toLowerCase()) ||
    option.city.toLowerCase().includes(search.toLowerCase()) ||
    option.zipcode.toLowerCase().includes(search.toLowerCase()) ||
    option.state.toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (venueSearch) {
      /** Check if the input is an object or a search string */
      if (typeof venueSearch === 'object') {
        /** Object Selection */
        setDisplayedVenues([venueSearch]);
      } else if (venueSearch.length > 0) {
        /** Search String */
        setDisplayedVenues(
          data?.filter((option) => searchFilter(option, venueSearch))
        );
      }
    } else {
      /** Display ALL Seasons */
      setDisplayedVenues(data);
    }
  }, [venueSearch, data]);

  // Clear search when input clears
  useEffect(() => {
    if (!venueSearch)
      onSearch('')
  }, [venueSearch, onSearch])

  const getDisplayedData = displayedVenues => {
    return (
      <>
        {displayedVenues?.map(
          ({ 
            id, 
            name, 
            city, 
            state, 
            zipcode, 
            image, 
            games, 
            active, 
          }) => (
            <VenueItem
              key={id}
              id={id}
              name={name}
              city={city}
              state={state}
              zipcode={zipcode}
              image={image}
              games={games}
              active={active}
              editVenueCtrl={handleEditModalOpenHook}
            />
          )
        )}
      </>
    );
  };

  return (
    <PageContent
      searchId={searchId}
      data={data}
      dataProperty={(option) =>
        `${option.name} - ${option.city}, ${option.state}`
      }
      placeholder={placeholder}
      filterBy={(option, props) => searchFilter(option, props.text)}
      onChange={setVenueSearch}
      isFetching={isFetching}
      onSearch={onSearch}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuDiv>
            <SearchMenuMainCriteria>
              {getHighlightedText(option.name, props.text)}
            </SearchMenuMainCriteria>
            <SearchMenuSubCriteriaDark>
              {getHighlightedText(option.city, props.text)},
              {getHighlightedText(option.state, props.text)}
              {getHighlightedText(option.zipcode, props.text)}
            </SearchMenuSubCriteriaDark>
          </SearchMenuDiv>
        );
      }}
    >       
      <InfiniteScrollList
        displayedData={displayedVenues}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        displayedDataFilter={() => getDisplayedData(displayedVenues)}
      />
    </PageContent>
  );
};

export default VenueContent;
