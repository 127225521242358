import { useState, useEffect, } from 'react';
import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from './constantsUtils';
import { getGamePlayerStatByTeamId } from '../api/gamesService';
import { getPlayerImageProfile } from '../api/playersService';
import { useFetchAll } from './reactQueryToolkit';

const getGameTeamPlayersStats = async (gameId, teamId, nextToken) => {
  let gameTeamPlayersStats = await getGamePlayerStatByTeamId({
    limit: LIST_PAGINATION_LIMIT.LARGE,
    nextToken: nextToken,
    gameId: gameId,
    teamId: {
      eq: teamId
    }
  });

  return gameTeamPlayersStats;
};

const getAllTeamGameStats = async (gameTeamPlayersStats, teamId, teamGameLineupPlayers, setAllTeamGameStats) => {
  const playerIds = gameTeamPlayersStats.map(({ player }) => player?.id);
  let gameTeamPlayersWithoutStats = teamGameLineupPlayers
                                      .filter(({ playerId }) => !playerIds.includes(playerId))
                                      .map(async lineupPlayer => {
                                        const {
                                          playerId,
                                          playerFirstName,
                                          playerLastName,
                                          playerPosition,
                                          playerJerseyNumber,
                                          playerDateOfBirth,
                                          playerBirthCity,
                                          playerBirthState,
                                          playerBirthCountry,
                                          playerWeight,
                                          playerHeight,
                                          playerInstitute,
                                          playerActive,
                                          playerOnCourtBenchStatus,
                                        } = lineupPlayer;

                                        const player = {
                                          id: playerId,
                                          firstName: playerFirstName,
                                          lastName: playerLastName,
                                          jerseyNumber: playerJerseyNumber,
                                          position: playerPosition,
                                          dateOfBirth: playerDateOfBirth,
                                          birthCity: playerBirthCity,
                                          birthState: playerBirthState,
                                          birthCountry: playerBirthCountry,
                                          weight: playerWeight,
                                          height: playerHeight,
                                          institute: playerInstitute,
                                          active: playerActive,
                                          teamId: teamId,
                                          lineupPlayer: playerOnCourtBenchStatus
                                        };

                                        player.playerImage = await getPlayerImageProfile(playerId);

                                        return {  playerId, player, coachDecision: "DNP-COACH'S DECISION" };
                                      });

  gameTeamPlayersWithoutStats = await Promise.all(gameTeamPlayersWithoutStats);

  gameTeamPlayersStats = gameTeamPlayersStats.map(async gamePlayerStat => {
    if(gamePlayerStat.player) {
      gamePlayerStat.player.lineupPlayer = teamGameLineupPlayers.find(({ playerId }) => gamePlayerStat.playerId === playerId)?.playerOnCourtBenchStatus;
      gamePlayerStat.player.playerImage = await getPlayerImageProfile(gamePlayerStat.playerId);
    }
    return gamePlayerStat;
  });

  gameTeamPlayersStats = await Promise.all(gameTeamPlayersStats);

  setAllTeamGameStats([ ...gameTeamPlayersStats, ...gameTeamPlayersWithoutStats ]);
};

const useGameTeamPlayersStats = (queryKey, gameId, teamId, teamLineupPlayers) => {
  const { onSuccessCallback } = useFetchAll(true);
  const [allTeamGameStats, setAllTeamGameStats] = useState([]);

  const gameTeamPlayersStatsResponse = 
  useInfiniteQuery([queryKey, { teamLineupPlayers }], 
    async ({ pageParam }) => {
      if(teamLineupPlayers) {
        const gameTeamPlayersStats = await getGameTeamPlayersStats(gameId, teamId, pageParam);
        return gameTeamPlayersStats;
      }
    }, 
    {
      enabled: teamLineupPlayers != undefined,
      onSuccess: (data) => onSuccessCallback(gameTeamPlayersStatsResponse)(data),
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken
    }
  );

  useEffect(() => {
    if(gameTeamPlayersStatsResponse.isSuccess && !gameTeamPlayersStatsResponse.hasNextPage) {
      const gameTeamPlayersStats = gameTeamPlayersStatsResponse?.data?.pages?.reduce((items, page) => (page && [...items, ...page.items]), []);
      getAllTeamGameStats(gameTeamPlayersStats, teamId, teamLineupPlayers, setAllTeamGameStats);
    }
  }, [gameTeamPlayersStatsResponse.isSuccess, gameTeamPlayersStatsResponse.hasNextPage]);

  return allTeamGameStats;
};

export default useGameTeamPlayersStats;