import { useReducer } from 'react';

export const multiSelectionActionTypes = {
  TOGGLE_MULTI_SELECTION: 'toggle',
  TOGGLE_PLAYER_SELECTION: 'toggle_player_selection',
  RESET: 'deselect',
};

const multiSelectionInitialState = {
  active: false,
  activeDragGroup: null, // Track active drag group using ItemTypes
  selectedPlayers: [],
};

const multiSelectionReducer = (state, action) => {
  const { type, newActiveDragGroup, playerItem } = action;
  switch (type) {
    case multiSelectionActionTypes.TOGGLE_MULTI_SELECTION:
      return {
        active: true,
        activeDragGroup: newActiveDragGroup,
        selectedPlayers: [playerItem],
      };
    case multiSelectionActionTypes.TOGGLE_PLAYER_SELECTION: {
      const playerIndex = state.selectedPlayers.findIndex(
        (player) => player.playerId === playerItem.playerId
      );
      let newSelectedPlayers;

      if (playerIndex < 0)
        // Add player to selection
        newSelectedPlayers = [...state.selectedPlayers, playerItem];
      // Remove player from selection
      else
        newSelectedPlayers = [
          ...state.selectedPlayers.slice(0, playerIndex),
          ...state.selectedPlayers.slice(playerIndex + 1),
        ];

      if (newSelectedPlayers.length === 0)
        // If nothing is selected anymore, turn multi-selection off
        return multiSelectionInitialState;

      return { ...state, selectedPlayers: newSelectedPlayers };
    }
    case multiSelectionActionTypes.RESET:
      return multiSelectionInitialState;
    default:
      throw new Error(
        `[multiSelectionReducer] Unsupported action type: ${type}`
      );
  }
};

export const useMultiSelectionReducer = () => {
  const [state, dispatch] = useReducer(
    multiSelectionReducer,
    multiSelectionInitialState
  );

  const isMultiSelectionActive = (playerGroup) =>
    state.active && playerGroup === state.activeDragGroup;

  const toggleMultiSelection = (newActiveDragGroup, playerItem) =>
    dispatch({
      type: multiSelectionActionTypes.TOGGLE_MULTI_SELECTION,
      newActiveDragGroup: newActiveDragGroup,
      playerItem: playerItem,
    });

  const isPlayerSelected = (playerId) =>
    state.selectedPlayers.some(
      (playerItem) => playerItem.playerId === playerId
    );

  const togglePlayerSelection = (playerItem) => {
    dispatch({
      type: multiSelectionActionTypes.TOGGLE_PLAYER_SELECTION,
      playerItem: playerItem,
    });
  };

  const resetMultiSelection = () =>
    dispatch({
      type: multiSelectionActionTypes.RESET,
    });

  return [
    state,
    dispatch,
    {
      isMultiSelectionActive,
      toggleMultiSelection,
      isPlayerSelected,
      togglePlayerSelection,
      resetMultiSelection,
    },
  ];
};

export default useMultiSelectionReducer;
