import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { colors } from '../../../styles';
import { textEllipsisStyle } from '../../../styles/typography';
import { truncateText } from '../../../utils/stringUtil';

const TeamPlayerSelectionControl = ({
  teamName,
  teamId,
  teamLogo,
  teamPlayers,
  teamPlayerShotsMadeIndicator,
  teamPlayerShotsMissedIndicator,
  teamShotsMadeStatus,
  teamShotsMadeStatusHandler,
  teamShotsMissedStatus,
  teamShotsMissedStatusHandler,
  teamShotsByPlayerHandler,
  teamPlayerValue,
  bottomRightRadius = 0,
}) => {
  return (
    <Row style={{ width: '100%', height: '40%', margin: 0, }}>
      <Col xs={12} className='p-0' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', borderTop: '2px solid #D5D5D5', }}>
        <div style={{ width: '97%', height: '100%', }}>
          <Row style={{ width: '100%', height: '100%', margin: 0, }}>
            <Col xs={8} className='p-0'>
              <Row style={{ width: '100%', height: '55%', margin: 0, }}>
                <Col xs={12} className='p-0 d-flex align-items-center'>
                  <Row style={{ width: '100%', height: '80%', margin: 0, }}>
                    <Col xs={3} className='p-0' style={{ width: 60, maxWidth: 60, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                      <div style={{ width: 50, height: 50, }}>
                        <img
                          src={teamLogo}
                          width="100%"
                          height="100%"
                          style={{ objectFit: 'contain', opacity: 1, borderRadius: 7, border: '4px solid #D5D5D5', }}
                        />
                      </div>
                    </Col>
                    <Col xs={9} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                      <div style={{ width: '100%', height: '50%', textAlign: 'left', font: 'normal normal 600 18px/35px "Segoe UI"', color: colors.BLACK[100], ...textEllipsisStyle, }}>
                        {teamName}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ width: '100%', height: '40%', margin: 0, }}>
                <Col xs={12} className='p-0'>
                  <Form.Select 
                    style={{ width: '95%', height: 45, }}
                    onChange={event => teamShotsByPlayerHandler(event.currentTarget.value, teamId)}
                    value={teamPlayerValue}
                  >
                    {teamPlayers?.map(player => (
                      <option key={player.playerId} value={player.playerId}>{truncateText(player.playerName, 45)}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col xs={4} className='p-0' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
              <div style={{ width: '100%', height: '100%', borderLeft: '2px solid #D5D5D5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#EFEFEF', borderBottomRightRadius: bottomRightRadius, }}>
                <Row style={{ width: '100%', height: '45%', margin: 0, }}>
                  <Col xs={3} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', }}>
                    <Form.Check
                      value={teamShotsMadeStatus}
                      type="checkbox"
                      checked={teamShotsMadeStatus}
                      onChange={() => teamShotsMadeStatusHandler(teamShotsMadeStatus, teamId)}
                    >
                    </Form.Check>
                  </Col>
                  <Col xs={2} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', position: 'relative', }}>
                    <img
                      src={teamPlayerShotsMadeIndicator}
                      width="15px"
                      height="15px"
                      alt="shots_made"
                      style={{ position: 'absolute', bottom: 4, }}
                    />
                  </Col>
                  <Col xs={4} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', font: `normal normal ${ teamShotsMadeStatus ? 700 : 'normal' } 18px/22px Open Sans`, color: '#707070', cursor: 'pointer', }} onClick={() => teamShotsMadeStatusHandler(teamShotsMadeStatus, teamId)}>
                    Made
                  </Col>
                </Row>
                <Row style={{ width: '100%', height: '45%', margin: 0, }}>
                  <Col xs={3} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', }}>
                    <Form.Check
                      value={teamShotsMissedStatus}
                      type="checkbox"
                      checked={teamShotsMissedStatus}
                      onChange={() => teamShotsMissedStatusHandler(teamShotsMissedStatus, teamId)}
                    >
                    </Form.Check>
                  </Col>
                  <Col xs={2} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', position: 'relative', }}>
                    <img
                      src={teamPlayerShotsMissedIndicator}
                      width="15px"
                      height="15px"
                      alt="shots_missed"
                      style={{ position: 'absolute', top: 4, }}
                    />
                  </Col>
                  <Col xs={4} className='p-0' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', font: `normal normal ${ teamShotsMissedStatus ? 700 : 'normal' } 18px/22px Open Sans`, color: '#707070', cursor: 'pointer', }} onClick={() => teamShotsMissedStatusHandler(teamShotsMissedStatus, teamId)}>
                    Missed
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default TeamPlayerSelectionControl