import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import HorizontalGrid from '../components/Grids/HorizontalGrid';
import MainLayout from '../components/layout/MainLayout';
import AddEditVenueModal from '../components/Modals/AddEditVenueModal';
// import DeleteVenueModal from '../components/Modals/DeleteVenueModal';
import { useVenuesQuery, venuesKeys } from '../api/venuesQueries';
import { createVenue, getVenue, updateVenue } from '../api/venuesService';
import ToastAlert from '../components/alerts/ToastAlert';
import VenueContent from '../components/PageContent/VenueContent';
import Spinner from '../components/Spinner';
import { colors } from '../styles';
const log = require('../logger')('adminPanel');

const VenuesPage = () => {
  const queryClient = useQueryClient();
  const [queryFilter, setQueryFilter] = useState('');
  const onSearch = (query) => setQueryFilter(query);
  // const [venueID, setVenueID] = useState();
  // const [venueName, setVenueName] = useState();
  // const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState();
  const [venueItem, setVenueItem] = useState({
    name: '',
    city: '',
    zipcode: '',
    state: '',
    image: '',
    active: false,
    infoCheck: false,
  });
  const [createVenueError, setCreateVenueError] = useState();

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const {
    data: venues,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isSuccess,
  } = useVenuesQuery({
    filter: queryFilter,

    onError: (error) => {
      log.error(error);
      setShowToast(true);
      setToastText('An error occurred while retrieving venues.');
      setToastVariant('danger');
    },
  });

  const handleSubmit = async (values) => {
    /**
     * Create or Edit
     */
    let returnData;
    isCreate
      ? (returnData = await createVenue(
          values.name,
          values.city,
          values.zipcode,
          values.state,
          values.image,
          values.active
        ))
      : (returnData = await updateVenue(values.id, values));
    /* If League Create/Update failed show an error, else close the modal */
    if (returnData.errors) {
      setCreateVenueError(
        `Unable to create venue. ${returnData.errors[0].message}.`
      );
    } else {
      /**
       * Success
       * Clear error, update list, close model, show toast, reset form
       */
      setCreateVenueError(null);
      queryClient.invalidateQueries(venuesKeys.all); // Invalidate query and refetch
      setOpen(false);
      if (isCreate) {
        setToastText(`The Venue "${values.name}" was created successfully.`);
      } else {
        setToastText(`The Venue "${values.name}" was updated successfully.`);
      }
      setShowToast(true);
    }
  };

  const onModalClose = () => {
    setCreateVenueError(null);
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setIsCreate(true);
    setOpen(true);
  };

  const handleEditModalOpen = async (venueId) => {
    const venue = await getVenue(venueId);
    setVenueItem(venue);
    setIsCreate(false);
    setOpen(true);
  };

  // const handleDelete = async () => {
  //   venuesHook([]);
  //   await deleteVenue(venueID);
  //   deleteModalSwitch(!deleteModalOpen);
  //   await callGetVenues();
  // };

  // const deleteModalSwitch = (row) => {
  //   setDeleteModalOpen(!deleteModalOpen);
  // };

  return (
    <MainLayout
      title="Venues"
      buttonLabel="+ Add venue"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {isSuccess && venues.length === 0 && (
        <HorizontalGrid
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          width={'462px'}
          height={'211px'}
        >
          <AddCircleButton
            buttonLabel="Add Venue"
            buttonClickHandler={handleOpen}
          />
        </HorizontalGrid>
      )}

      <VenueContent
        searchId="__venueId"
        placeholder="Search Venues"
        data={venues}
        handleEditModalOpenHook={handleEditModalOpen}
        fetchNextPage={() => fetchNextPage()}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        onSearch={onSearch}
      />

      <AddEditVenueModal
        isNewVenue={isCreate}
        modalOpen={open}
        setModalOpen={setOpen}
        onSubmit={handleSubmit}
        onClose={onModalClose}
        venueData={venueItem}
        error={createVenueError}
        setError={setCreateVenueError}
      />

      {/* <DeleteVenueModal
        venueName={venueName}
        isOpen={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteVenue={() => handleDelete()}
      /> */}

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default VenuesPage;
