export const typeScale = {
	LARGE: {
		fontSize: '24px',
		lineHeight: '24px',
	},
	LMEDIUM: {
		fontSize: '20px',
		lineHeight: '24px',
	},
	MEDIUM: {
		fontSize: '16px',
		lineHeight: '24px',
	},
	SMEDIUM: {
		fontSize: '14px',
		lineHeight: '24px',
	},
	SMALL: {
		fontSize: '12px',
		lineHeight: '24px',
	},
};

export const textEllipsisStyle = {
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-all',
};