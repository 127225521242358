export const getUserGames = /* GraphQL */ `
  query GetUser($id: ID!, $gamesLimit: Int, $gamesNextToken: String) {
    getUser(id: $id) {
      games(limit: $gamesLimit, nextToken: $gamesNextToken) {
        items {
          id
          createdAt
          updatedAt
          gameId
          game {
            id
            homeTeamId
            homeTeamName
            homeTeamScore
            homeTeamGameLineupId
            awayTeamId
            awayTeamName
            awayTeamScore
            awayTeamGameLineupId
            seasonId
            leagueId
            gameDateTime
            gameStatus
            leagueName
            leagueAbbreviation
            leagueNumPeriods
            leagueTimePerPeriodInMins
            leagueOvertimeDurationInMins
            seasonStartDate
            seasonEndDate
            seasonType
            venueId
            venueName
            venueCity
            venueState
          }
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
