import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import uniqby from 'lodash.uniqby';
import ToastAlert from '../components/alerts/ToastAlert';
import MainLayout from "../components/layout/MainLayout";
import PageContainerLayout from "../components/layout/PageContainerLayout";
import UserProfileContent from '../components/PageContent/UserProfileContent';
import AddEditUserModal from '../components/Modals/AddEditUserModal';
import AssignUserGamesModal from '../components/Modals/AssignUserGamesModal';
import AssignGameUserRolesModal from '../components/Modals/AssignGameUserRolesModal';
import {
  getUser,
} from '../api/userService';
import {
  getGames, 
  getGamesByUserId,
  getGameUserParameters,
  getUniqueGames,
} from '../utils/gameUtil';
import {
  getTeams,
  getTeamUserByTeamIds,
  getSelectedTeams,
  getAssignedTeams,
} from '../utils/teamUtils';
import {
  updateUserStatus, 
  updateUserInfoData, 
  addUserToGame, 
  deleteUserFromGame, 
  updateUserForGame, 
  addUserToTeam, 
  deleteUserFromTeam, 
} from '../utils/userUtil';
import { truncateText } from '../utils/stringUtil';
import { USER_ROLES } from '../utils/userUtil';
import { ITEM_TYPE } from '../utils/constantsUtils';

const UserProfilePage = () => {
  const initialUserInfo = {
    firstName: '',
    lastName: '',
    email: '',
    role: [],
    active: true,
    acceptedTerms: false,
  };

  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [createUserError, setCreateUserError] = useState();

  const [userItem, setUserItem] = useState();
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [assignedGameIds, setAssignedGameIds] = useState([]);
  const [assignedTeamIds, setAssignedTeamIds] = useState([]);
  const [userGameItems, setUserGameItems] = useState([]);
  const [userTeamItems, setUserTeamItems] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [gameUser, setGameUser] = useState();
  const [pickedGames, setPickedGames] = useState([]);
  const [pickedGamesRemoved, setPickedGamesRemoved] = useState([]);
  const [openAssignUserGamesModal, setOpenAssignUserGamesModal] = useState(false);
  const [openAssignGameRolesModal, setOpenAssignGameRolesModal] = useState(false);
  const [isCancelledBtnClicked, setIsCancelledBtnClicked] = useState(false);
  const [isNewGameRoleAssignment, setIsNewGameRoleAssignment] = useState(false);
  const [editedUserData, setEditedUserData] = useState();
  const [isGameRolesEditable, setIsGameRolesEditable] = useState(false);
  const [originalRoles, setOriginalRoles] = useState('');
  const [openNextModal, setOpenNextModal] = useState(false);
  const [isEditingUserProfile, setIsEditingUserProfile] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastVariant, setToastVariant] = useState();
  const [itemType, setItemType] = useState();

  const user = useSelector(
    (state) => state.user.value && JSON.parse(state.user.value)
  );
  const token = user.signInUserSession.accessToken.jwtToken;

  useEffect(() => {
    setShowSpinner(true);
    getSelectedUser(id);
  }, []);

  useEffect(() => {
    if(pickedGames.length > 0) {
      setShowSpinner(false);
      openAssignGameRoleModal();
    }
  }, [pickedGames]);

  const getSelectedUser = async userId => {
    const user = await getUser(userId);
    const { firstName, lastName, games, teams, role, } = user;

    if(role?.includes(USER_ROLES.COACH.VALUE)) {
      await getSelectedUserTeams(teams);
    }
    else {
      await getSelectedUserGames(games, user);
    }

    setGameUser(`${firstName} ${lastName}`);
    setUserItem(user);
    setShowSpinner(false);
  };

  const getSelectedUserTeams = async (teams) => {
    const teamItems = teams.items.filter(({ _deleted }) => _deleted === null);

    const gameIds = teamItems.map(({ gameId }) => gameId).filter(gameId => gameId !== null);
    const uniqueGames = await getUniqueGames(gameIds);

    const asyncAssignedTeamsResponse = await getAssignedTeams(teamItems);
    const assignedTeams = asyncAssignedTeamsResponse.map(({ id, seasonId, leagueId }) => ({ id, seasonId, leagueId }));

    setItemType(ITEM_TYPE.TEAM);
    setUserGameItems(uniqueGames.sort((a, b) => moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf()));
    setUserTeamItems(uniqby(asyncAssignedTeamsResponse.sort((a, b) => a.name.localeCompare(b.name)), 'id'));
    setAssignedTeamIds(assignedTeams);
  };

  const getSelectedUserGames = async (games, user) => {
    const gameUserIds = games.items.filter(({ _deleted }) => !_deleted).map(({ id }) => id);
    const gameItemsForUserAsyncResponse = await getGamesByUserId(gameUserIds, user.id);
    const gameIds = gameItemsForUserAsyncResponse.map(({ id }) => id);
    const upcomingGameItems = gameItemsForUserAsyncResponse.filter(({ gameDateTime }) => moment(gameDateTime).valueOf() > moment().valueOf());
    user.games.items = user.games.items.filter(userGame => upcomingGameItems.find(gameItem => userGame._deleted === null && userGame.gameId === gameItem.id));

    setItemType(ITEM_TYPE.GAME);
    setAssignedGameIds(gameIds);
    setUserGameItems(gameItemsForUserAsyncResponse.sort((a, b) => moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf()));
  };

  const transformRole = role => {
    return Array.isArray(role) 
            ? role 
            : role.indexOf(',') != -1 
                ? role.split(',').map(_role => _role.trim()) 
                : [role.trim()];
  };

  const openAssignGameUserModal = () => {
    setOpenAssignUserGamesModal(true);
  };

  const closeAssignGameUserModal = () => {
    setOpenAssignUserGamesModal(false);
  };

  const openAssignGameRoleModal = () => {
    setOpen(false);
    setOpenAssignGameRolesModal(true);
  };

  const closeAssignGameRoleModal = () => {
    setOpenAssignGameRolesModal(false);
    setIsNewGameRoleAssignment(false);
    setUserInfo(initialUserInfo);
    setEditedUserData(null);
    setIsGameRolesEditable(false);
    setOriginalRoles("");
  };

  const openAddEditUserModalHandler = () => {
    let { role } = userItem;
    role = role.join(',').trim();
    setOriginalRoles(role);
    setUserInfo({ ...userItem, role });
    setOpenNextModal(true);
    setIsEditingUserProfile(true);
    setOpen(true);
  }; 

  const onEditGameRoleProfile = event => {
    setTimeout(() => openAddEditUserModalHandler(), 250);
    closeAssignGameRoleModal();
    return event.preventDefault();
  };

  const assignGameRoleModalBackHandler = pickedGamesRemoved => {
    setPickedGamesRemoved([ ...pickedGamesRemoved ]);
    closeAssignGameRoleModal();
    setTimeout(() => openAssignGameUserModal(), 250);
  };

  const pickedGameIds = async gameIds => {
    setShowSpinner(true);
    const asyncGameItemsResponse = await getGames(gameIds);
    setIsNewGameRoleAssignment(true);
    setPickedGames([ ...asyncGameItemsResponse ]);
  };

  const pickedCoachedTeams = teams => {
    setShowSpinner(true);
    setIsNewGameRoleAssignment(true);
    setPickedGames([ ...teams ]);
  };

  const onNextHandler = updatedValues => {
    const { games } = userItem;
    const role = transformRole(updatedValues.role);
    setEditedUserData({ ...updatedValues, games, role });
    setIsNewGameRoleAssignment(false);
    onSetPickedGamesHandler(role);
  };

  const openAddEditUserModalBackHandler = () => {
    setUserInfo({ ...editedUserData, role: editedUserData.role.join(',').trim() });
    setOpenAssignGameRolesModal(false);
    setOpen(true);
  };

  const onSetPickedCoachTeamAndGamesHandler = async () => {
    const teamIds = userTeamItems?.map(({ id }) => id);

    let uniqueTeamIdsAndSeasonIds = Array
                                      .from( new Set(
                                        userItem.teams.items
                                          .filter(({ _deleted }) => _deleted === null)
                                          .map(({ teamId, seasonId }) => JSON.stringify({ teamId, seasonId }))
                                        ) 
                                      )
                                      .map(item => JSON.parse(item));

    let teamUsers = await getTeamUserByTeamIds(id, teamIds);
    teamUsers = teamUsers.filter(({ game }) => moment(game.gameDateTime).valueOf() > moment().valueOf());

    uniqueTeamIdsAndSeasonIds = uniqueTeamIdsAndSeasonIds.filter(({ teamId }) => teamUsers.map(({ teamId }) => teamId).includes(teamId));

    const teamGamesItems = teamUsers
                            ?.map(({ teamId, game }) => {
                              const { seasonId, seasonType, seasonStartDate, seasonEndDate } = game;
                              const season = { seasonId, seasonType, seasonStartDate, seasonEndDate };
                              return { teamId, game, season };
                            });

    const assignedPickedTeams = getSelectedTeams(userTeamItems, teamGamesItems, uniqueTeamIdsAndSeasonIds);

    setPickedGames(assignedPickedTeams);
  };

  const onSetPickedGamesHandler = role => {
    // From 2-Role
    const isOriginalRolesAdminLike = originalRoles.includes('Admin') || (originalRoles.includes('ClockManagers') && originalRoles.includes('ScoreKeepers'));
    // To 1-Role
    const isClockManagerOrScoreKeeperRole = role.length === 1 && (role[0] === 'ClockManagers' || role[0] === 'ScoreKeepers');
    let displayedPickedGames = [ ...userGameItems ].filter(({ gameDateTime }) => moment(gameDateTime).valueOf() > moment().valueOf()).sort((a, b) => moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf());
    const areAllGamesOfTheSameRole = displayedPickedGames.every(game => game.role === role[0]);
    if(isOriginalRolesAdminLike && isClockManagerOrScoreKeeperRole && !areAllGamesOfTheSameRole) {
      displayedPickedGames = displayedPickedGames.filter(pickedGame => pickedGame.role !== role[0]);
    }
    setPickedGames(displayedPickedGames);
  };

  const onEditUserGamesHandler = role => {
    setOpenNextModal(false);

    role = transformRole(role);
    if(role[0] === 'Coach') {
      setIsGameRolesEditable(false);
      onSetPickedCoachTeamAndGamesHandler();
    }
    else {
      const isGameRoleEditable = (role[0] === 'Admin') || (role[0] === 'ClockManagers' && role[1] === 'ScoreKeepers');
      setIsGameRolesEditable(!isGameRoleEditable);
      onSetPickedGamesHandler(role);
    }
  };

  const submitGameUserParams = async (gameUserParams, gameItemsRemoved) => {
    const gameUserIds = userItem.games.items.map(({ id, gameId, _version }) => ({ id, gameId, _version }));
    const { updateGameUserParams, deleteGameUserParams } = getGameUserParameters(gameUserIds, gameUserParams, gameItemsRemoved);

    setShowSpinner(true);

    const isEveryGameUserRoleEditable = updateGameUserParams?.every(param => param);
    if(!isNewGameRoleAssignment) {
      if(isEveryGameUserRoleEditable || deleteGameUserParams) 
      {
        if(deleteGameUserParams) {
          const deleteGameUsersResponse = await deleteUserFromGame(deleteGameUserParams);

          if(!deleteGameUsersResponse.error) {
            setToastVariant('success');
            setToastText('Selected games assigned were deleted successfully for user.');
          }
          else {
            setToastVariant('danger');
            setToastText('Selected games assigned failed to be deleted for user.');
            return;
          }
        }

        if(isEveryGameUserRoleEditable) {
          const updateGameUsersResponse = await updateUserForGame(updateGameUserParams);

          if(!updateGameUsersResponse.error) {
            setToastVariant('success');
            setToastText('Assigned roles for selected games were successfully updated for.');
          }
          else {
            setToastVariant('danger');
            setToastText('Assigned roles for selected games failed to be updated.');
            return;
          }
        }
      }

      if(editedUserData && Object.keys(editedUserData).length > 0) {
        await handleSubmit(editedUserData);
      }
      else {
        await getSelectedUser(id);
      }
      setShowSpinner(false);
    }
    else {
      const createGameUsersResponse = await addUserToGame(gameUserParams);

      if(!createGameUsersResponse.error) {
        await getSelectedUser(id);
        setToastVariant('success');
        setToastText('Roles were successfully assigned to selected games.');
      }
      else {
        setShowSpinner(false);
        setToastVariant('danger');
        setToastText('Roles failed to be assigned to selected games.');
      }
    }
  };

  const submitTeamUserParams = async (teamUserParams, teamItemsRemoved) => {
    setShowSpinner(true);

    if(isNewGameRoleAssignment) {
      const createTeamUsersResponse = await addUserToTeam(teamUserParams);

      if(!createTeamUsersResponse.error) {
        setToastVariant('success');
        setToastText('Coach role successfully assigned to selected user.');
      }
      else {
        setToastVariant('danger');
        setToastText('Coach role failed to be assigned to selected user.');
      }
    }
    else {
      const deleteTeamUsersResponse = await deleteUserFromTeam(userItem.teams.items, teamItemsRemoved);

      if(!deleteTeamUsersResponse.error) {
        setToastVariant('success');
        setToastText('Selected teams assigned were deleted successfully for team user.');
      }
      else {
        setToastVariant('danger');
        setToastText('Selected teams assigned failed to be deleted from team user.');
        return;
      }
    }

    await getSelectedUser(id);
    setShowSpinner(false);
  };

  const handleSubmit = async values => {
    let { 
      firstName, 
      lastName, 
      email, 
      role, 
      active, 
    } = values;

    firstName = firstName.toString()[0].toUpperCase() + firstName.toString().substring(1);
    lastName = lastName.toString()[0].toUpperCase() + lastName.toString().substring(1);
    role = transformRole(role);

    const id = values.id;
    const _version = values._version;
    const previousUserActive = userItem.active;
    const previousUserRoles  = userItem.role;

    if(previousUserActive != active) {
      const userStatusResponse = await updateUserStatus(active, email, token);

      if(userStatusResponse.error) {
        setCreateUserError(`Unable to ${active ? 'enable' : 'disable'} user.`);
        return;
      }
    }

    const updatedUser = await updateUserInfoData(id, firstName, lastName, role, email, active, _version, previousUserRoles, token);

    if(updatedUser.errors) {
      setCreateUserError('Unable to update user.');
      return;
    }
    else {
      handleClose();
      setToastVariant('success');
      setToastText(`"${firstName} ${lastName}" was updated successfully.`);
      setShowToast(true);
      setEditedUserData(null);
      await getSelectedUser(id);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCreateUserError(null);
    setUserInfo(initialUserInfo);
    setOriginalRoles("");
  };

  return (
    <MainLayout
      title='User Profile'
      rowButtonLabel={itemType ? `Assign ${itemType}(s)` : `Loading...`}
      rowButtonWidth='135px'
      buttonHandler={openAssignGameUserModal}
      navigationLinkBack={'/admin/users'}
    >
      {showSpinner && (
        <Spinner
          animation="border"
          role="status"
          style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 100, }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <AddEditUserModal
        isNewUser={false}
        modalOpen={open}
        setModalOpen={setOpen}
        userData={userInfo}
        originalRoles={originalRoles}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onNext={onNextHandler}
        error={createUserError}
        isEditingUserProfile={isEditingUserProfile}
      />

      <AssignUserGamesModal
        gameUser={gameUser}
        itemType={itemType}
        assignedGameIds={assignedGameIds}
        assignedTeamIds={assignedTeamIds}
        modalOpen={openAssignUserGamesModal}
        setModalOpen={setOpenAssignUserGamesModal}
        doneHandler={pickedItems => itemType === ITEM_TYPE.TEAM ? pickedCoachedTeams(pickedItems) : pickedGameIds(pickedItems)}
        pickedGamesRemoved={pickedGamesRemoved}
        isCancelledBtnClicked={isCancelledBtnClicked}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        onClose={closeAssignGameUserModal}
      />

      <AssignGameUserRolesModal
        isNewGameRoleAssignment={isNewGameRoleAssignment}
        userName={`${truncateText(`${editedUserData?.firstName || userItem?.firstName}`, 12)} ${truncateText(`${editedUserData?.lastName || userItem?.lastName}`, 12)}`}
        userItem={userItem}
        itemType={itemType}
        modalOpen={openAssignGameRolesModal}
        setModalOpen={setOpenAssignGameRolesModal}
        submitHandler={itemType === ITEM_TYPE.TEAM ? submitTeamUserParams : submitGameUserParams}
        backHandler={isNewGameRoleAssignment ? assignGameRoleModalBackHandler : openAddEditUserModalBackHandler}
        games={pickedGames}
        editedUserData={editedUserData}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        isGameRolesEditable={isGameRolesEditable}
        onEditGameRoleProfile={onEditGameRoleProfile}
        onClose={closeAssignGameRoleModal}
        hasPreviousModal={openNextModal}
      />

      <PageContainerLayout>
        {!showSpinner && userItem && 
        <UserProfileContent
          userInfo={userItem}
          userGames={userGameItems}
          userTeams={userTeamItems}
          itemType={itemType}
          openAddEditUserModal={openAddEditUserModalHandler}
          openAssignGameUserRolesModal={onEditUserGamesHandler}
        />}
      </PageContainerLayout>

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default UserProfilePage;