export const colors = {
  WHITE: {
    100: '#FFFFFF',
  },
  BLACK: {
    100: '#000000',
    200: '#2a2a2a',
    250: '#1a1a1a',
    300: '#161616',
  },

  GRAY: {
    100: '#F5F5F5',
    120: '#d8d8d8',
    150: '#BEBEBE',
    200: '#DDDDDD',
    250: '#EDEEF2',
    300: '#5A5A5A',
    350: 'rgba(112, 112, 112, 0.5)', //used for borders - should be applied to all of clock-manager-ui
    375: 'rgba(112, 112, 112, 0.25)', //used for dividers - ScoreKeeper UI
    400: '#2E2E2E',
    // 450: '#A0A0A0',
    450: '#878787',
    500: '#707070',
    550: '#646464',
    600: '#6B6B6B', // disabled button color
    // used for linear gradient on clock-manager-ui
    700: '#545454',
    750: '#8d8d8d',
    800: '#171717',
    850: '#8f8f8f',
    900: '#919191',
    950: '#9E9E9E',
    1000: '#3E3E3E',
    1100: '#00000026', // used for box shadow in ScoreKeeper UI
  },
  PRIMARY: {
    100: '#ECF5F8',
    200: '#255ADB',
    300: '#5469d4',
    400: '#e9effb', // added by Nathan for checkbox hover color
    500: '#448FFF',
    600: '#224880',
  },

  // used for linear gradient on clock-manager-ui
  GREEN: {
    100: '#008d18',
    200: '#00470c',
    300: '#079611',
  },
  BLUE: {
    100: '#25A9E1',
    200: '#4DA5B4',
    300: '#356CBC',
    400: '#448fff',
    500: '#7da0ff',
  },
  ERROR: {
    100: '#dc3545', // Bootstrap ERROR Red
  },
  RED: {
    100: '#FF1F1F',
    200: '#FF6262',
    300: '#FFF1F1',
    400: '#C1212F',
    500: '#D80000',
  },
  ORANGE: {
    100: '#cc5500',
  },
  PURPLE: {
    100: '#920162',
  },
};

export const gradients = {
  GRAY: `linear-gradient(180deg, ${colors.GRAY[700]}, ${colors.GRAY[800]}`,
  GREEN: `linear-gradient(180deg, ${colors.GREEN[100]}, ${colors.GREEN[200]})`,
  BLUE: `linear-gradient(180deg, ${colors.PRIMARY[500]}, ${colors.PRIMARY[600]})`,
  YELLOW: 'linear-gradient(180deg, #FFE42B, #9F6F01)',
};
