import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useField } from 'formik';

const SelectDropdown = ({ label, wrapWithGroup = true, ...props }) => {
  const [field, meta] = useField(props);

  const selectComponent = (
    <>
      <Form.Select {...props} {...field} isInvalid={meta.touched && meta.error}>
        {/* The children <options/> are display */}
        {props.children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );

  if (!wrapWithGroup) return selectComponent;

  return (
    <Form.Group>
      {label ? (
        <FloatingLabel
          label={props.required ? `${label}*` : label}
          className={meta.touched && meta.error ? 'pb-0' : 'pb-2'}
        >
          {selectComponent}
        </FloatingLabel>
      ) : (
        selectComponent
      )}
    </Form.Group>
  );
};

export default SelectDropdown;
