import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'react-bootstrap';
import { colors } from '../../styles/colorsStatsCollector';

const StyledButtonGroup = styled(ButtonGroup)`
  &&& {
    // background: none;
    justify-content: flex-start;
    border-bottom: 1px solid ${colors.GRAY[1650]};
  }
`;

const StyledButton = styled(Button)`
  &&& {
    // background: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font: normal 11px/15px Open Sans;
    display: inline-block;
    width: auto;
    flex: none;
    padding: 10px 20px;
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[100] : colors.GRAY[1075]};

    &:hover {
      border: none;
    }
    &:active,
    &.active {
      color: ${({ theme: { lightMode } }) =>
        lightMode ? colors.BLACK[100] : colors.WHITE[100]};
      font-weight: bold;
      border: none;
      border-bottom: 1px solid ${colors.GRAY[1600]};
    }
    &:disabled {
      border: none;
    }
  }
`;

const Tabs = ({ tabs }) => (
  <StyledButtonGroup>
    {tabs.map((t, idx) => (
      <StyledButton key={idx} onClick={t.clickHandler} active={!!t.active}>
        {t.label}
      </StyledButton>
    ))}
  </StyledButtonGroup>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      clickHandler: PropTypes.func.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
};

export default Tabs;
