import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { useItemsFromPages } from '../utils/reactQueryToolkit';
import {
  getSeasonImageProfile,
  listSeasonsSortedByUpdatedAt,
} from './seasonsService';

export const seasonsKeys = {
  all: ['seasons'],
};

export const useSeasonsQuery = (options = {}) => {
  const query = useInfiniteQuery({
    ...options,
    queryKey: seasonsKeys.all,
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      const seasonsItems = await listSeasonsSortedByUpdatedAt(queryVars);

      const seasonsWithImages = await Promise.all(
        seasonsItems.items?.map(async (season) => {
          season.league.image = await getSeasonImageProfile(season.league.id);
          return season;
        })
      );

      return { ...seasonsItems, items: seasonsWithImages };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  /* Aggregate seasons from all query pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return { ...query, data };
};
