import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Row, Col, Alert, Form, } from 'react-bootstrap';
import intersection from 'lodash.intersection';
import ModalComponent from './ModalComponent';
import GameItemInformation from '../GameItemInformation';
import { seasonFormatDisplay } from '../../utils/seasonsUtil';
import { textEllipsisStyle } from '../../styles'
import RemoveIcon from '../../assets/remove@2x.png';
import RefreshIcon from '../../assets/refresh@2x.png';
import RightChevronArrow from '../../assets/rightArrowChevron.png';
import { buttonThemes } from '../../styles/themes';
import { isMobile, onListScrollHandler } from '../../utils/scrollUtil';
import { USER_ROLES } from '../../utils/userUtil';
import { ITEM_TYPE } from '../../utils/constantsUtils';

const ROW_ITEM_HEIGHT = 40;

const gameItemTableRowStyle = { 
  width: '75%', 
  height: 50, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
};

const gameItemTableFirstItemStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'center', 
  fontSize: 14, 
  fontWeight: 600, 
  marginBottom: 3, 
};

const gameItemTableSecondItemStyle = { 
  width: '100%', 
  height: 'auto', 
  color: '#5A5A5A', 
  font: 'normal normal normal 12px/18px Inter', 
  display: 'flex', 
  flexDirection: 'row', 
};

const homeVsAwayTeamsTextDisplayStyle = { 
  width: 'auto', 
  height: 20, 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingRight: 12, 
  color: '#5A5A5A', 
};

const teamNameTextStyle = {
  height: 'auto', 
  maxWidth: 150,
  ...textEllipsisStyle,
};

const gameDateTimeTextDisplayStyle = { 
  width: 'auto', 
  height: 20, 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingLeft: 12, 
  paddingRight: 12, 
  color: '#5A5A5A', 
  borderRight: '1px solid #B1B1B1', 
  borderLeft: '1px solid #B1B1B1', 
};

const venueNameTextDisplayStyle = { 
  width: 'auto', 
  maxWidth: 200, 
  height: 20, 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingLeft: 12, 
  color: '#5A5A5A', 
  ...textEllipsisStyle, 
};

const seasonTextDisplayStyle = { 
  width: 'auto', 
  height: 'auto', 
  paddingRight: 12, 
  borderRight: '1px solid #B1B1B1', 
};

const leagueTextDisplayStyle = { 
  width: 'auto', 
  height: 'auto', 
  paddingLeft: 12, 
  display: 'flex', 
  flexDirection: 'row', 
};

const leagueNameTextStyle = {
  height: 'auto', 
  maxWidth: 400,
  ...textEllipsisStyle, 
};

const leagueAbbreviationTextStyle = { 
  width: 'auto', 
  height: 'auto', 
};

const gameRoleTableRowStyle = { 
  width: '25%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-end', 
  alignItems: 'center', 
};

const selectRoleStyles = { 
  width: 170, 
  height: 35, 
  paddingTop: 0,
  paddingBottom: 0,
};

const crossRemoveRowStyle = { 
  width: 30, 
  height: 'auto', 
  padding: 5, 
  paddingBottom: 14, 
  fontSize: 20, 
  marginLeft: 10,
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center',
  cursor: 'pointer',
};

const tableStyle = { 
  width: '100%', 
  height: 'auto', 
  tableLayout: 'fixed', 
  marginTop: 5, 
  border: '0.5px solid black', 
};

const tableHeaderStyle = { 
  width: '100%', 
  height: 50, 
  display: 'block', 
  borderCollapse: 'separate', 
  borderBottom: '0.5px solid black', 
  backgroundColor: '#F4F4F7', 
};

const tableBodyStyle = { 
  width: '100%', 
  height: 275, 
  display: 'block', 
  borderCollapse: 'separate', 
  paddingTop: 12, 
  paddingBottom: 12, 
  paddingLeft: 12, 
  paddingRight: 12, 
  overflowY: 'overlay', 
  overflowX: 'hidden', 
};

const tableRoleChildStyle = { 
  padding: 10, 
  display: 'flex', 
  flexDirection: 'row',  
  borderRadius: 5, 
};

const gameRoleItemStyle = { 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  fontSize: 14, 
};

const gameRoleItemTextStyle = { 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const modalFooterTextStyle = { 
  display: 'flex', 
  flexDirection: 'row',
  justifyContent: 'flex-start',
  font: 'normal normal bold 16px/20px Inter', 
  color: '#B20000', 
  textAlign: 'left', 
  marginTop: 33, 
};

const teamsGamesContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'column', 
};

const teamGameControlContainerStyle = { 
  width: '100%', 
  height: 22, 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'center', 
  marginTop: 5,
  marginBottom: 5,
};

const teamGameControlStyle = { 
  width: 18, 
  height: 18, 
  borderRadius: 9, 
  border: '1px solid #0386BE', 
  backgroundColor: '#25A9E1', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  cursor: 'pointer', 
  color: '#FFFFFF', 
  fontWeight: 'bold', 
  fontSize: 12, 
};

const gamesTextStyle = { 
  width: 'auto', 
  height: 'auto', 
  font: 'italic normal 600 13px/25px Open Sans', 
  color: '#1C1C1C', 
  paddingLeft: 5, 
};

const featureGamesDisplayStyles = { 
  width: '100%', 
  borderRadius: 5, 
  display: 'flex', 
  flexDirection: 'column', 
  transition: 'height 0.5s linear',
};

const featureGameRowItemStyle = { 
  width: '100%', 
  height: ROW_ITEM_HEIGHT, 
  display: 'flex', 
  flexDirection: 'column', 
  flexDirection: 'row', 
  alignItems: 'flex-start', 
  justifyContent: 'center',
  overflow: 'hidden', 
};

const featureGameRowItemContentStyle = { 
  width: '100%', 
  height: '80%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'flex-start', 
  borderRadius: 5, 
};

const versesTextStyle = { 
  width: 'auto', 
  height: 'auto', 
  marginLeft: 5, 
  marginRight: 5, 
  font: 'normal normal bold 12px/25px Open Sans', 
  color: '#1C1C1C', 
};

const gameTeamNameTextStyle = { 
  width: 125, 
  height: 'auto', 
  maxWidth: 125, 
  marginRight: 5, 
  textAlign: 'center', 
  font: 'normal normal normal 12px/18px Inter', 
  ...textEllipsisStyle, 
};

const textSeparatorStyle = { 
  width: 2, 
  height: '70%', 
  borderLeft: '2px solid #ABABAB', 
  marginLeft: 5, 
  marginRight: 5, 
};

const gameDateTimeTextStyle = { 
  width: 190, 
  height: 'auto',  
  textAlign: 'center', 
  font: 'normal normal normal 12px/18px Inter', 
  marginLeft: 5, 
  marginRight: 5, 
};

const venueNameTextStyle = { 
  width: 'auto', 
  height: 'auto', 
  maxWidth: 300, 
  textAlign: 'left', 
  marginLeft: 5, 
  font: 'normal normal normal 12px/18px Inter', 
  ...textEllipsisStyle, 
};

const userContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'center', 
};

const userContainerTextStyle = { 
  width: 'auto', 
  height: 'fit-content', 
  maxWidth: '75%', 
  font: 'normal normal 600 14px/25px Open Sans', 
  color: '#1C1C1C', 
  ...textEllipsisStyle, 
};

const ROLES = [
  { name: USER_ROLES.CLOCK_MANAGER.LABEL, value: USER_ROLES.CLOCK_MANAGER.VALUE }, 
  { name: USER_ROLES.SCORE_KEEPER.LABEL, value: USER_ROLES.SCORE_KEEPER.VALUE },
  { name: USER_ROLES.COACH.VALUE, value: USER_ROLES.COACH.VALUE },
];

const TableRow = ({ rowStyles = {}, children }) => {
  return (
    <tr style={{ width: '100%', display: 'inline-flex', ...rowStyles, }}>
      {children}
    </tr>
  );
};

const TableHeaderItem = ({ headerText, headerStyles = {}, }) => {
  return (
    <th style={{ height: '100%', display: 'flex', alignItems: 'center', ...headerStyles, }}>
      {headerText}
    </th>
  );
};

const TableGameRowItem = ({gameItem}) => {
  const {
    homeTeamName,
    awayTeamName,
    leagueName, 
    leagueAbbreviation,	
    seasonStartDate, 
    seasonEndDate, 
    seasonType, 
    venueName,
    gameDateTime,
  } = gameItem;

  return (
    <td style={gameItemTableRowStyle}>
      <div style={gameItemTableFirstItemStyle}>
        <div style={homeVsAwayTeamsTextDisplayStyle}>
          <div style={teamNameTextStyle}>{homeTeamName}</div>&nbsp;Vs&nbsp;<div style={teamNameTextStyle}>{awayTeamName}</div>
        </div>
        <div style={gameDateTimeTextDisplayStyle}>
          {moment(gameDateTime).format('MM/DD/YY, h:mmA')}
        </div>
        <div style={venueNameTextDisplayStyle}>
          {venueName}
        </div>
      </div>
      <div style={gameItemTableSecondItemStyle}>
        <div style={seasonTextDisplayStyle}>
          {`${seasonFormatDisplay({ seasonType, startDate: seasonStartDate, endDate: seasonEndDate })}`}
        </div>
        <div style={leagueTextDisplayStyle}>
          <div style={leagueNameTextStyle}>{leagueName}</div> <div style={leagueAbbreviationTextStyle}>{`(${leagueAbbreviation})`}</div>
        </div>
      </div>
    </td>
  );
};

const TableTeamRowItem = ({
  teamItem, 
  userItem,
  isNewGameRoleAssignment,
  commonTeamGames,
}) => {
  const rowItemHeightRef = useRef();

  const [isGamesExpanded, setIsGamesExpanded] = useState(false);
  const [gameHeight, setGamesHeight] = useState();
  const [currentAssignedTeamIds, setCurrentAssignedTeamIds] = useState([]);
  const [currentAssignedTeamGameIds, setCurrentAssignedTeamGameIds] = useState([]);
  const [currentAssignedTeamSeasonIds, setCurrentAssignedTeamSeasonIds] = useState([]);
  const [currentAssignedTeamLeagueIds, setCurrentAssignedTeamLeagueIds] = useState([]);
  const [colorTeamNameText, setColorTeamNameText] = useState();

  useEffect(() => {
    setGamesHeight(isGamesExpanded ? rowItemHeightRef.current : 0);
  }, [isGamesExpanded]);

  useEffect(() => {
    if(isNewGameRoleAssignment && userItem.teams.items.length > 0) {
      const nonDeletedTeamUsers = userItem.teams.items.filter(({ _deleted }) => _deleted === null);

      let assignedTeamIds = nonDeletedTeamUsers.map(({ teamId }) => teamId);
      assignedTeamIds = Array.from( new Set(assignedTeamIds) );

      let assignedTeamGameIds = nonDeletedTeamUsers.map(({ gameId }) => gameId);
      assignedTeamGameIds = Array.from( new Set(assignedTeamGameIds) );

      let assignedTeamSeasonIds = nonDeletedTeamUsers.map(({ seasonId }) => seasonId);
      assignedTeamSeasonIds = Array.from( new Set(assignedTeamSeasonIds) );

      let assignedTeamLeagueIds = nonDeletedTeamUsers.map(({ leagueId }) => leagueId);
      assignedTeamLeagueIds = Array.from( new Set(assignedTeamLeagueIds) );

      setCurrentAssignedTeamIds(assignedTeamIds);
      setCurrentAssignedTeamGameIds(assignedTeamGameIds);
      setCurrentAssignedTeamSeasonIds(assignedTeamSeasonIds);
      setCurrentAssignedTeamLeagueIds(assignedTeamLeagueIds);
    }
  }, [userItem.teams, isNewGameRoleAssignment]);

  const {
    id,
    teamName,
    leagueName, 
    leagueAbbreviation,	
    seasonStartDate, 
    seasonEndDate, 
    seasonType,
    games, 
  } = teamItem;

  useEffect(() => {
    const hasTeamAlreadyBeenAssigned = games.some(({ id, homeTeamId, awayTeamId, seasonId, leagueId }) => (
      (currentAssignedTeamIds.includes(homeTeamId) || currentAssignedTeamIds.includes(awayTeamId)) && 
      (currentAssignedTeamGameIds.includes(id)) && 
      (currentAssignedTeamSeasonIds.includes(seasonId)) && 
      (currentAssignedTeamLeagueIds.includes(leagueId))
    ));

    const hasSomeCommonGamesScheduled = games.some(({ id }) => commonTeamGames.includes(id));
    const teamNameTexColor = (hasTeamAlreadyBeenAssigned || hasSomeCommonGamesScheduled) ? '#DC3545' : '#000000';

    setIsGamesExpanded(hasTeamAlreadyBeenAssigned || hasSomeCommonGamesScheduled);
    setColorTeamNameText(teamNameTexColor);
  }, [
    commonTeamGames, 
    currentAssignedTeamIds, 
    currentAssignedTeamGameIds,
    currentAssignedTeamSeasonIds, 
    currentAssignedTeamLeagueIds
  ]);

  rowItemHeightRef.current = (games.length * ROW_ITEM_HEIGHT);

  const FutureGamesDisplay = ({
    teamId,
    games,
    currentAssignedTeamIds,
    currentAssignedTeamGameIds,
    currentAssignedTeamSeasonIds,
    currentAssignedTeamLeagueIds,
    commonTeamGames,
  }) => {
    const highlightRowBackgroundColor = (
      currentAssignedTeamIds, 
      currentAssignedTeamGameIds,
      currentAssignedTeamSeasonIds, 
      currentAssignedTeamLeagueIds, 
      homeTeamId, 
      awayTeamId, 
      seasonId, 
      leagueId, 
      gameId, 
      commonTeamGames
    ) => {
      const isTeamNameAlreadyUsed = (currentAssignedTeamIds.includes(homeTeamId) || currentAssignedTeamIds.includes(awayTeamId)) && 
                                    (currentAssignedTeamGameIds.includes(gameId)) && 
                                    (currentAssignedTeamSeasonIds.includes(seasonId)) && 
                                    (currentAssignedTeamLeagueIds.includes(leagueId));

      const isCommonTeamGame = commonTeamGames.includes(gameId);

      const backgroundColor = isTeamNameAlreadyUsed 
                                ? '#FFC4C4' 
                                : isCommonTeamGame 
                                    ? '#FFF3CD' 
                                    : 'transparent';

      return backgroundColor;
    };
  
    return (
      <>
      {games.map(({ 
        id,
        homeTeamId, 
        homeTeamName, 
        awayTeamId, 
        awayTeamName, 
        gameDateTime, 
        venueName, 
        seasonId, 
        leagueId, 
      }) => {
        return (
          <div style={featureGameRowItemStyle}>
            <div style={{ 
              ...featureGameRowItemContentStyle, 
              backgroundColor: highlightRowBackgroundColor(
                currentAssignedTeamIds, 
                currentAssignedTeamGameIds,
                currentAssignedTeamSeasonIds, 
                currentAssignedTeamLeagueIds, 
                homeTeamId, 
                awayTeamId, 
                seasonId, 
                leagueId, 
                id, 
                commonTeamGames) 
            }}>
              <img
                width="7px"
                height="7px"
                src={RightChevronArrow}
                alt="Right Arrow Pointer"
                style={{ marginLeft: 7, }}
              />
              <div style={versesTextStyle}>
                Vs
              </div>
              <div style={gameTeamNameTextStyle}>
                {teamId === homeTeamId ? awayTeamName : homeTeamName}
              </div>
              <div style={textSeparatorStyle}
              />
              <div style={gameDateTimeTextStyle}>
                {moment(gameDateTime).format('MMMM Do, YYYY - h:mma')}
              </div>
              <div style={textSeparatorStyle}
              />
              <div style={venueNameTextStyle}>
                {venueName}
              </div>
            </div>
          </div>
        );
      })}
      </>
    );
  };

  return (
    <td style={{ ...gameItemTableRowStyle, height: 'auto', }}>
      <div style={gameItemTableFirstItemStyle}>
        <div style={homeVsAwayTeamsTextDisplayStyle}>
          <div style={{ ...teamNameTextStyle, maxWidth: 600, color: colorTeamNameText, }}>{teamName}</div>
        </div>
      </div>
      <div style={gameItemTableSecondItemStyle}>
        <div style={seasonTextDisplayStyle}>
          {`${seasonFormatDisplay({ seasonType, startDate: seasonStartDate, endDate: seasonEndDate })}`}
        </div>
        <div style={leagueTextDisplayStyle}>
          <div style={leagueNameTextStyle}>{leagueName}</div> <div style={leagueAbbreviationTextStyle}>{`(${leagueAbbreviation})`}</div>
        </div>
      </div>
      <div style={teamsGamesContainerStyle}>
        <div style={teamGameControlContainerStyle}>
          <div style={teamGameControlStyle} onClick={() => setIsGamesExpanded(!isGamesExpanded)}>
            {isGamesExpanded ? '-' : '+'}
          </div>
          <div style={gamesTextStyle}>
            Games
          </div>
        </div>
        <div style={{ ...featureGamesDisplayStyles, height: gameHeight, }}>
          <FutureGamesDisplay
            teamId={id}
            games={games}
            currentAssignedTeamIds={currentAssignedTeamIds}
            currentAssignedTeamGameIds={currentAssignedTeamGameIds}
            currentAssignedTeamSeasonIds={currentAssignedTeamSeasonIds}
            currentAssignedTeamLeagueIds={currentAssignedTeamLeagueIds}
            commonTeamGames={commonTeamGames}
          />
        </div>
      </div>
    </td>
  );
};

const TableCoachUserRowItem = ({
  game,
}) => {
  const { name } = game;
  return (
    <td style={{ ...gameItemTableRowStyle, justifyContent: 'center', }}>
      <div style={userContainerStyle}>
        <div style={userContainerTextStyle}>
          {name}
        </div>
      </div>
    </td>
  );
};

const GameAssignedTableDataItem = ({
  game,
  itemType,
  userItem,
  isNewGameRoleAssignment,
  commonTeamGames,
}) => {
  const getTableRowItem = (itemType) => {
    switch(itemType){
      case ITEM_TYPE.TEAM:
        return (
          <TableTeamRowItem 
            teamItem={game}
            userItem={userItem}
            isNewGameRoleAssignment={isNewGameRoleAssignment}
            commonTeamGames={commonTeamGames}
          />
        );
      case ITEM_TYPE.COACH:
        return (
          <TableCoachUserRowItem
            game={game}
          />
        );
      default:
        return (
          <TableGameRowItem 
            gameItem={game}
          />
        );
    };
  };

  return (
    getTableRowItem(itemType)
  );
};

const AssigneRoleTableDataItem = ({
  gameId,
  team,
  userId,
  userRole,
  gameRole,
  items,
  addGameUserParams,
  addTeamUserParams,
  newUserRole,
  getNamedRoles,
  isToggled,
  unassignedMsg,
  isNewUserNotLikeAdmin,
  isNewGameRoleAssignment,
  showCustomButtons,
  error,
  isGameRolesEditable,
  isSelectChanged,
  setIsSelectChanged,
  assignedOptionList,
  itemType,
}) => {
  const [role, setRole] = useState();
  const [roleDisabled, setRoleDisabled] = useState(false);
  const [rolesList, setRolesList] = useState(assignedOptionList || ROLES);

  const currentGameRole = items.find(({ id }) => id === gameId)?.role;
  const areAllGameRolesSimilar = !isNewUserNotLikeAdmin 
                                  && newUserRole 
                                  && newUserRole.length === 1 
                                  && items.every(({ role }) => role === newUserRole[0]); 

  useEffect(() => {
    if(gameRole && !isSelectChanged && !isNewGameRoleAssignment) {
      addGameUserParams(userId, gameId, "");
    }
  }, [isSelectChanged]);

  useEffect(() => {
    if(userRole?.includes(USER_ROLES.ADMIN.VALUE) || 
       ((userRole?.includes(USER_ROLES.CLOCK_MANAGER.VALUE) && 
        userRole?.includes(USER_ROLES.SCORE_KEEPER.VALUE))||
        (!userRole?.includes(USER_ROLES.COACH.VALUE) && 
         !isNewGameRoleAssignment))
    ) {
      setRolesList(rolesList.filter(role => role.name !== USER_ROLES.COACH.VALUE));
    }

    setRoleDisabled(showCustomButtons);

    if(!showCustomButtons) {
      if(currentGameRole) {
        if(isNewUserNotLikeAdmin) {
          addGameUserParams(userId, gameId, newUserRole[0]);
        }
        else {
          setRole(gameRole || currentGameRole);
          setIsSelectChanged(gameRole ? true : false);
        }
      }
      else {
        if((JSON.stringify(userRole) === JSON.stringify([USER_ROLES.CLOCK_MANAGER.VALUE])) || 
          (JSON.stringify(userRole) === JSON.stringify([USER_ROLES.SCORE_KEEPER.VALUE])) || 
          (JSON.stringify(userRole) === JSON.stringify([USER_ROLES.COACH.VALUE]) && !Array.isArray(assignedOptionList))
        ) {
          const roleValue = userRole[0];
          if(roleValue === USER_ROLES.COACH.VALUE) {
            addTeamUserParams(userId, team, roleValue);
          }
          else {
            addGameUserParams(userId, gameId, roleValue);
          }
          setRole(roleValue);
          setRoleDisabled(true);
        }
        else if(gameRole) {
          setRole(gameRole);
        }
        else {
          setRole('');
        }
      }
    }
  }, [userRole, gameRole, showCustomButtons]);

  const onSelectChangeHandler = (itemType, event) => {
    const selectedValue = event.currentTarget.value;
    switch(itemType) {
      case ITEM_TYPE.COACH:
        const teamItem = rolesList.find(({ name, value }) => name === selectedValue && value === selectedValue);
        addTeamUserParams(userId, teamItem?.team, userRole[0]);
        setRole(selectedValue);
        break;
      default:
        addGameUserParams(userId, gameId, selectedValue);
    };
  };

  return (
    <td style={gameRoleTableRowStyle}>
      {(isNewUserNotLikeAdmin || isSelectChanged)
        ?
        <div style={{ ...gameRoleItemStyle, width: 250, justifyContent: 'flex-end', }}>
          {isToggled
            ?
            <span>{unassignedMsg}</span>
            :
            <>
              <div style={{ width: 'auto', ...gameRoleItemTextStyle, }}>
                {getNamedRoles([currentGameRole])}
              </div>
              <div style={{ width: '10%', ...gameRoleItemTextStyle, }}>
                {'->'}
              </div>
              <div style={{ width: 'auto', ...gameRoleItemTextStyle, }}>
                {isSelectChanged ? getNamedRoles([gameRole]) : getNamedRoles(newUserRole)}
              </div>
            </>
          }
        </div>
        :
        <div style={{ ...gameRoleItemStyle, justifyContent: 'flex-end' }}>
          {isToggled
            ?
            <span>{unassignedMsg}</span>
            :
            <Form.Select
              style={{ ...selectRoleStyles, border: (error && !role) ? '2px solid red' : `1px solid ${(roleDisabled || isGameRolesEditable || areAllGameRolesSimilar) ? '#D1D1D1' : 'black'}` }} 
              className={(roleDisabled || isGameRolesEditable || areAllGameRolesSimilar) ? 'disabled' : 'enabled'}
              placeholder='Select...'
              onChange={event => onSelectChangeHandler(itemType, event)}
              disabled={roleDisabled || isGameRolesEditable || areAllGameRolesSimilar}
              value={role}
            >
              <option value={""}> </option>
              {rolesList.map(roleItem => (
                <option key={gameId} value={roleItem.value} style={{ height: '100%' }}>{roleItem.name}</option>
              ))}
            </Form.Select>
          }
        </div>
      }
    </td>
  );
};

const GameTableRow = ({
  item, 
  userItem, 
  userFirstName, 
  itemType,
  gameRole, 
  items, 
  itemsRemoved,
  addGameUserParams, 
  addTeamUserParams, 
  updateItemsHandler,
  toggleGameItemHandler, 
  newUserRole, 
  getNamedRoles, 
  showCustomButtons, 
  isNewGameRoleAssignment, 
  isNewUserNotLikeAdmin, 
  error, 
  isGameRolesEditable, 
  commonTeamGames,
  assignedOptionList,
}) => {
  const [backgroundColorState, setBackgroundColorState] = useState(isNewUserNotLikeAdmin ? '#F3F3F3' : '#FFFFFF');
  const [icon, setIcon] = useState(RemoveIcon);
  const [isToggled, setIsToggled] = useState(false);
  const [isSelectChanged, setIsSelectChanged] = useState(false);

  useEffect(() => {
    if(isToggled || isSelectChanged) {
      setIcon(RefreshIcon);
      setBackgroundColorState(isSelectChanged ? '#CBEAF8' : '#FFC4C4');
    }
    else {
      setIcon(RemoveIcon);
      setBackgroundColorState(isNewUserNotLikeAdmin ? '#F3F3F3' : '#FFFFFF');
    }
  }, [isToggled, isSelectChanged]);

  const changeToggleState = (event, isToggled) => {
    if(!isNewGameRoleAssignment) {
      setIsToggled(!isToggled);
      toggleGameItemHandler(event, item.id, itemsRemoved);
    }
    else {
      updateItemsHandler(event, item, items, itemsRemoved);
    }
  };

  const getTeamForItemType = (itemType, item) => {
    switch(itemType) {
      case ITEM_TYPE.TEAM:
        return item;
      case ITEM_TYPE.COACH:
        return item.team;
      default:
        return null;
    };
  };

  return (
    <TableRow
      key={item.id}
      rowStyles={{ height: 'auto', marginBottom: 10, paddingRight: 10, }}
    >
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', borderBottom: !isNewUserNotLikeAdmin && '1px solid #B1B1B1', }}>
        <div style={{ ...tableRoleChildStyle, width: showCustomButtons ? '99%' : '97%', paddingTop: isNewUserNotLikeAdmin ? 10 : 0, marginBottom: !isNewUserNotLikeAdmin && (isToggled || isSelectChanged) ? 10 : 0, backgroundColor: backgroundColorState, border: isNewUserNotLikeAdmin && '1px solid #9B9B9B', }}>
          <GameAssignedTableDataItem
            game={item}
            itemType={itemType}
            userItem={userItem}
            isNewGameRoleAssignment={isNewGameRoleAssignment}
            commonTeamGames={commonTeamGames}
          />
          <AssigneRoleTableDataItem
            gameId={itemType === ITEM_TYPE.GAME && item.id}
            team={getTeamForItemType(itemType, item)}
            userId={itemType === ITEM_TYPE.COACH ? item.id : userItem.id}
            userRole={itemType === ITEM_TYPE.COACH ? item.role : userItem.role} 
            gameRole={gameRole}
            items={items}
            addGameUserParams={addGameUserParams}
            addTeamUserParams={addTeamUserParams}
            newUserRole={newUserRole}
            getNamedRoles={getNamedRoles}
            isToggled={isToggled}
            unassignedMsg={`${userFirstName} will be unassigned`}
            isNewUserNotLikeAdmin={isNewUserNotLikeAdmin}
            isNewGameRoleAssignment={isNewGameRoleAssignment}
            showCustomButtons={showCustomButtons}
            error={error}
            isGameRolesEditable={isGameRolesEditable}
            isSelectChanged={isSelectChanged}
            setIsSelectChanged={setIsSelectChanged}
            assignedOptionList={assignedOptionList}
            itemType={itemType}
          />
        </div>
        {!showCustomButtons && !isGameRolesEditable && 
          <div style={crossRemoveRowStyle}>
            <img
              src={icon}
              width={15}
              height={15}
              onClick={event => isSelectChanged ? setIsSelectChanged(false) : changeToggleState(event, isToggled)}
            />
          </div>
        }
    </div>
    </TableRow>
  );
};

const AssignGameUserRolesModal = ({
  isNewGameRoleAssignment, 
  userName = null, 
  userItem = null, 
  modalOpen, 
  setModalOpen, 
  submitHandler, 
  backHandler, 
  setIsCancelledBtnClicked, 
  onClose, 
  games, 
  editedUserData = {},
  isGameRolesEditable,
  onEditGameRoleProfile = () => {},
  hasPreviousModal = false,
  itemType,
  modalTitle = null,
  assignedOptionList = null,
  assignedColumnTitle = null,
  gameItem = null,
}) => {
  const [items, setItems] = useState([]);
  const [itemsRemoved, setItemsRemoved] = useState([]);
  const [itemUserParams, setItemUserParams] = useState([]);
  const [error, setError] = useState();
  const [showCustomButtons, setShowCustomButtons] = useState(false);
  const [showPreviousModal, setShowPreviousModal] = useState(false);
  const [isNewUserNotLikeAdmin, setIsNewUserNotLikeAdmin] = useState();
  const [isUpdateReady, setIsUpdateReady] = useState(false);
  const [isTeamAlreadyAssigned, setIsTeamAlreadyAssigned] = useState(false);
  const [commonTeamGames, setCommonTeamGames] = useState([]);

  const assignItemUsersRef = useRef([]);
  const userFirstName = userName?.split(' ')[0];

  useEffect(() => {
    if(!isNewGameRoleAssignment) {
      setIsNewUserNotLikeAdmin(
        (editedUserData?.role && 
        (editedUserData?.role[0] !== USER_ROLES.ADMIN.VALUE) && 
        !(editedUserData?.role[0] === USER_ROLES.CLOCK_MANAGER.VALUE && editedUserData?.role[1] === USER_ROLES.SCORE_KEEPER.VALUE))
        &&
        !(games.every(game => game.role === editedUserData?.role[0]))
      );
    }
  }, [editedUserData?.role]);

  useEffect(() => {
    if(modalOpen) {
      assignItemUsersRef.current = [];
      setShowCustomButtons(false);
      setShowPreviousModal(false);
      setItemUserParams([]);
      setItemsRemoved([]);
    }
    else {
      setItems([]);
      setIsNewUserNotLikeAdmin(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    setItems(games);
  }, [games]);

  useEffect(() => {
    if(items.length === itemUserParams.length) {
      setError(null);
    }
    else if(error) {
      setError(`There are ${items.length - itemUserParams.length} game(s) without roles assigned.`);
    }
  }, [itemUserParams]);

  useEffect(() => {
    if(modalOpen && !isNewGameRoleAssignment) {
      if(!editedUserData?.role) {
        return;
      }
      else if(hasPreviousModal) {
        setShowPreviousModal(!showCustomButtons);
      }
    }
  }, [modalOpen, showCustomButtons]);

  const listRef = useRef();
  const _isMobile = isMobile();

  useEffect(() => {
    setIsUpdateReady( 
      userItem?.role[0] === USER_ROLES.COACH.VALUE 
        ? (itemsRemoved.length > 0) 
        : ((itemsRemoved.length > 0) || (itemUserParams.length > 0)) 
    );
  }, [itemUserParams, itemsRemoved]);

  useEffect(() => {
    if(itemType === ITEM_TYPE.TEAM && isNewGameRoleAssignment) {
      const nonDeletedTeamUsers = userItem.teams.items.filter(({ _deleted }) => _deleted === null);

      let assignedTeamIds = nonDeletedTeamUsers.map(({ teamId }) => teamId);
      assignedTeamIds = Array.from( new Set(assignedTeamIds) );

      let assignedTeamGameIds = nonDeletedTeamUsers.map(({ gameId }) => gameId);
      assignedTeamGameIds = Array.from( new Set(assignedTeamGameIds) );

      let assignedTeamSeasonIds = nonDeletedTeamUsers.map(({ seasonId }) => seasonId);
      assignedTeamSeasonIds = Array.from( new Set(assignedTeamSeasonIds) );

      let assignedTeamLeagueIds = nonDeletedTeamUsers.map(({ leagueId }) => leagueId);
      assignedTeamLeagueIds = Array.from( new Set(assignedTeamLeagueIds) );

      const itemTeamGames = items.map(({ id, seasonId, leagueId, games }) => ({ games, seasonId, leagueId, teamId: id }));
      const itemListWithAlreadyAssignedTeams = itemTeamGames.map(({ games }) => (games.some(({ id, homeTeamId, awayTeamId, seasonId, leagueId }) => (assignedTeamIds.includes(homeTeamId) || assignedTeamIds.includes(awayTeamId)) && assignedTeamGameIds.includes(id) && assignedTeamSeasonIds.includes(seasonId) && assignedTeamLeagueIds.includes(leagueId))));
      const hasAlreadyAssignedTeams = itemListWithAlreadyAssignedTeams.some(hasAssignedTeam => hasAssignedTeam === true);
      setIsTeamAlreadyAssigned(hasAlreadyAssignedTeams);

      const itemTeamGamesData = itemTeamGames.map(({ teamId, seasonId, leagueId, games }) => ({ teamId, seasonId, leagueId, gameIds: games.map(({ id }) => id) }));
      const teamGamesDataSize = itemTeamGamesData.length;
      let gameIdsIntersections = [];

      for(let i=0; i<teamGamesDataSize; i++) {
        const current = itemTeamGamesData[i];

        for(let j=0; j<teamGamesDataSize; j++) {
          const { teamId, seasonId, leagueId, gameIds } = itemTeamGamesData[j];

          if(current.teamId === teamId && current.seasonId === seasonId && current.leagueId === leagueId) {
            continue;
          }

          gameIdsIntersections = [ ...gameIdsIntersections, ...intersection(current.gameIds, gameIds) ];
        }
      }

      const commonTeamGameIds = Array.from( new Set(gameIdsIntersections) );

      setCommonTeamGames( commonTeamGameIds );
    }
  }, [items]);

  const CUSTOM_MODAL_BUTTONS = [
    {
      buttonLabel: 'No', 
      buttonTheme: buttonThemes.WHITE, 
      styles: { float: 'right', border: '1px solid #616161' },
      buttonHandler: () => setShowCustomButtons(false)
    },
    {
      buttonLabel: 'Yes', 
      buttonTheme: buttonThemes.WHITE, 
      styles: { float: 'right', border: '1px solid #616161' },
      buttonHandler: () => submitItemRoleAssignment(itemUserParams, itemsRemoved)
    },
  ];

  const getTableHeaderWidth = (oldUserRole, newUserRole, isNewUserNotLikeAdmin) => {
    if(isNewUserNotLikeAdmin) {
      if(newUserRole) {
        if(oldUserRole[0] === USER_ROLES.ADMIN.VALUE && newUserRole[0] === USER_ROLES.CLOCK_MANAGER.VALUE) {
          return !showCustomButtons ? '77%' : '80%';
        }
        else if(oldUserRole[0] === USER_ROLES.ADMIN.VALUE && newUserRole[0] === USER_ROLES.SCORE_KEEPER.VALUE) {
          return !showCustomButtons ? '78%' : '81%';
        }
        else if(oldUserRole[0] === USER_ROLES.CLOCK_MANAGER.VALUE || oldUserRole[0] === USER_ROLES.SCORE_KEEPER.VALUE) {
          return !showCustomButtons ? '72.5%' : '75.5%';
        }
      }
    }
    else {
      return !showCustomButtons ? (isGameRolesEditable ? '79.5%' : '78.5%') : '81.5%';
    }
  };

  const updateItemsHandler = (event, item, items, itemsRemoved) => {
    const updatedItems = items.filter(({ id, seasonId, leagueId }) => (itemType === ITEM_TYPE.TEAM) 
                                                                        ? (id !== item.id || seasonId !== item.seasonId || leagueId !== item.leagueId)
                                                                        : (id !== item.id));

    const removedItems = [ ...itemsRemoved, (itemType === ITEM_TYPE.TEAM) ? item : item.id ];

    const getFilterItemTypeResult = (itemUser, itemType) => {
      switch(itemType) {
        case ITEM_TYPE.TEAM:
          return (itemUser.team.id !== item.id || itemUser.team.seasonId !== item.seasonId || itemUser.team.leagueId !== item.leagueId);
        case ITEM_TYPE.COACH:
          return (itemUser.userId !== item.id);
        default:
          return (itemUser.gameId !== item.id);
      };
    };

    assignItemUsersRef.current = assignItemUsersRef.current.filter(itemUser => getFilterItemTypeResult(itemUser, itemType));

    setItemUserParams([ ...assignItemUsersRef.current ]);

    if(updatedItems.length === 0) {
      backHandler(removedItems);
      return;
    }
    else {
      setItemsRemoved(removedItems);
      setItems(updatedItems);
    }

    event.stopPropagation();
  };

  const toggleGameItemHandler = (event, itemId, itemsRemoved) => {
    let removedItems;
    const hasItemId = itemsRemoved.find(id => id === itemId);

    if(hasItemId) {
      removedItems = itemsRemoved.filter(id => id !== itemId);
    }
    else {
      removedItems = [ ...itemsRemoved, itemId ];
    }

    setItemsRemoved(removedItems);
    event.stopPropagation();
  };

  const addGameUserParams = (userId, gameId, gameRole) => {
    const userGameParam = assignItemUsersRef.current.find(param => param.gameId === gameId);
    if(gameRole.length > 0 && !userGameParam) {
      assignItemUsersRef.current.push({ userId, gameId, gameRole });
    }
    else {
      if(!isNewUserNotLikeAdmin) {
        const currentGameRole = items.find(({ id }) => gameId === id)?.role;
        if((userGameParam?.gameId === gameId && gameRole.length === 0) || (currentGameRole === gameRole)) {
          assignItemUsersRef.current = assignItemUsersRef.current.filter(param => param.gameId !== gameId);
        }
        else if(userGameParam) {
          userGameParam.gameRole = gameRole;
        }
      }
    }
    setItemUserParams([ ...assignItemUsersRef.current ]);
  };

  const addTeamUserParams = (userId, team, teamRole) => {
    if(!team) {
      assignItemUsersRef.current = assignItemUsersRef.current.filter(teamUser => teamUser.userId !== userId);
      setItemUserParams([ ...assignItemUsersRef.current ]);
      return;
    }

    const { id, seasonId, leagueId } = team;
    let hasUserTeamParam;

    if(itemType === ITEM_TYPE.TEAM) {
      hasUserTeamParam = assignItemUsersRef.current.some(({ team }) => team.id === id && team.seasonId === seasonId && team.leagueId === leagueId);
    }
    else if(itemType === ITEM_TYPE.COACH) {
      hasUserTeamParam = assignItemUsersRef.current.some((teamUser) => teamUser.userId === userId);
    }

    if(!hasUserTeamParam) {
      assignItemUsersRef.current.push({ userId, team, teamRole });
    }
    else {
      if(itemType === ITEM_TYPE.COACH) {
        if(team) {
          const userTeamParam = assignItemUsersRef.current.find((teamUser) => teamUser.userId === userId);
          userTeamParam.team = team;
        }
      }
    }

    setItemUserParams([ ...assignItemUsersRef.current ]);
  };

  const getNamedRoles = (roles, newRoles = []) => {
    if(roles && roles?.length > 0) {
      let newRole = roles.find(role => !newRoles.includes(role));
      newRole = newRole || roles[0];
      return ROLES.find(({ value }) => value === newRole)?.name || 'Admin';
    }
  };

  const getRoles = roles => {
    if(roles && roles?.length > 0) {
      const roleValue = ROLES.find(({ value }) => value === roles[0])?.name;
      const roleValue2 = roles[1] && ROLES.find(({ value }) => value === roles[1])?.name;
      if(roles[0] === USER_ROLES.ADMIN.VALUE) {
        return USER_ROLES.ADMIN.VALUE;
      }
      else if(roles[0] === USER_ROLES.CLOCK_MANAGER.VALUE && roles[1] === USER_ROLES.SCORE_KEEPER.VALUE) {
        return `${roleValue} & ${roleValue2}`;
      }
      else if(roles[0] === USER_ROLES.CLOCK_MANAGER.VALUE || roles[0] === USER_ROLES.SCORE_KEEPER.VALUE) {
        return roleValue;
      }
    }
  };

  const submitItemRoleAssignment = (itemUserParams, itemsRemoved) => {
    if(itemsRemoved?.length > 0) {
      submitHandler([...itemUserParams], [...itemsRemoved]);
    }
    else {
      submitHandler([...itemUserParams]);
    }
    clearDependencies();
  };

  const clearDependencies = () => {
    assignItemUsersRef.current = [];
    setIsCancelledBtnClicked(true);
    setItemUserParams([]);
    setItemsRemoved([]);
    setShowCustomButtons(false);
    setShowPreviousModal(false);
    setIsNewUserNotLikeAdmin(false);
    setIsUpdateReady(false);
    setIsTeamAlreadyAssigned(false);
    setItems([]);
    onClose();
  };

  return (
    <ModalComponent
      headerTitle={userName && !modalTitle ? `Assign ${itemType} Role(s) To ${userName}` : modalTitle}
      buttonTitle={!isGameRolesEditable && (isNewGameRoleAssignment ? 'Save & Return To Dashboard' : 'Next')}
      open={modalOpen}
      setOpen={setModalOpen}
      handleSubmit={(e) => {
        if(isNewGameRoleAssignment) {
          if(items.length === itemUserParams.length) {
            submitItemRoleAssignment([...itemUserParams]);
          }
          else {
            setError(`There are ${items.length - itemUserParams.length} ${itemType.toLowerCase()}(s) without roles assigned.`);
          }
        }
        else {
          setShowCustomButtons(true);
        }
        e.preventDefault();
      }}
      modalWidth={1100}
      handleClose={() => clearDependencies()}
      buttonDimmed={(isNewGameRoleAssignment ? (!!(itemUserParams.length === 0 || isTeamAlreadyAssigned || commonTeamGames.length > 0)) : (!isUpdateReady)) || error}
      buttonDisabled={(isNewGameRoleAssignment ? (!!(itemUserParams.length === 0 || isTeamAlreadyAssigned || commonTeamGames.length > 0)) : (!isUpdateReady)) || error}
      showBackButton={isNewGameRoleAssignment || showPreviousModal}
      backHandler={showPreviousModal ? backHandler : () => backHandler(itemsRemoved)}
      customModalButtons={showCustomButtons ? CUSTOM_MODAL_BUTTONS : []}
      footerText={showCustomButtons && 'Are you sure you want to make the above changes?'}
      footerTextStyles={modalFooterTextStyle}
      modalHeaderStyles={{ marginBottom: (itemType === ITEM_TYPE.COACH) ? 0 : 24, borderBottomWidth: 0, }}
      modalBodyStyles={{ padding: 0 }}
      buttonContainerStyles={{ paddingLeft: 24, paddingRight: 24 }}
    >
      {(gameItem && (itemType === ITEM_TYPE.COACH)) &&
        <GameItemInformation
          gameItem={gameItem}
        />
      }
      <Row style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Col xl={12} xs={12} md={12}>
          {error && 
          <Alert variant={'danger'}>
            {error}
          </Alert>}
          {(!showCustomButtons && !isNewGameRoleAssignment && isNewUserNotLikeAdmin) && 
          <Alert variant={'warning'} style={{ width: '100%', height: 'auto', fontSize: 12, marginBottom: 0, }}>
            <div style={{ fontWeight: 'bold' }}>IMPORTANT:</div>
            <div style={{ fontWeight: 500 }}>
              {`The role of ${userName} will be changed from "${getNamedRoles(userItem?.role, editedUserData?.role)}" to "${getNamedRoles(editedUserData?.role)}" for the upcoming games below.`}
            </div>
            <div style={{ fontStyle: 'italic' }}>
              {`(If you do not want ${userName} to be assigned "${getNamedRoles(editedUserData?.role)}" roles for the games below, then you can remove the game assignments by pressing 'X';)`}
            </div>
            <div style={{ fontStyle: 'italic' }}>
              {`However, removing a game will automatically unassign ${userName} as a "${getNamedRoles(userItem?.role, editedUserData?.role)}".)`}
            </div>
          </Alert>
          }
          {(!isNewGameRoleAssignment && isGameRolesEditable) &&
          <Alert variant={'warning'} style={{ width: '100%', height: 'auto', fontSize: 12, marginBottom: 0, }}>
            <div style={{ fontStyle: 'italic' }}>
              {`${userName} is assigned "${getNamedRoles(userItem?.role)}" role only. You will not be able to change game roles unless the user role is both "Clock Manager" and "Score Keeper".`}
            </div>
            <div style={{ fontStyle: 'italic' }}>
              {`If you want to change ${userName}'s role you can do so in the `}<a href="" onClick={onEditGameRoleProfile}>Edit User Profile</a>{` screen.`}
            </div>
          </Alert>
          }
          {(isNewGameRoleAssignment && itemType === ITEM_TYPE.TEAM && isTeamAlreadyAssigned) && 
            <Alert variant={'danger'} style={{ marginBottom: 5, }}>
              <div>
                {`The teams highlighted in red cannot be assigned to ${userName}, because ${userItem.firstName} has been assigned coach role for the opposing team of the highlighted game(s) shown below (click on '+' icon to view scheduled games)`} 
              </div>
            </Alert>
          }
          {(isNewGameRoleAssignment && itemType === ITEM_TYPE.TEAM && (commonTeamGames.length > 0)) &&
            <Alert variant={'warning'} style={{ marginBottom: 0, }}>
              <div>
                {`Teams scheduled in similar games cannot be assigned to ${userName}.`}
              </div>
            </Alert>
          }
          {(showCustomButtons && editedUserData?.role) && 
            <Row className="m-0" style={{ width: '100%', height: 110 }}>
              <Col xl={12} xs={12} md={12} className="p-0">
                <div style={{ width: '100%', height: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', }}>
                  <div style={{ width: '37%', border: '2px solid #D5D5D5', }}></div>
                  <div style={{ font: 'normal normal bold 22px/24px Inter' }}>Summary of Changes</div>
                  <div style={{ width: '37%', border: '2px solid #D5D5D5', }}></div>
                </div>
                <div style={{ width: '100%', height: 70, marginTop: 5, }}>
                  <label style={{ font: 'normal normal bold 16px/16px Inter' }}>User Role(s)</label>
                  <div style={{ width: '100%', height: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#F3F3F3', border: '0.5px solid #000000' }}>
                    <div style={{ font: 'normal normal normal 14px/24px Inter', color: '#6A6A6A', paddingLeft: 20, }}>{`${userName}'s role will change to ${getRoles(editedUserData?.role)}`}</div>
                    <div style={{ font: 'normal normal normal 14px/24px Inter', color: '#6A6A6A', paddingRight: 20, }}>{`${getRoles(userItem?.role)} -> ${getRoles(editedUserData?.role)}`}</div>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row className="mb-3" style={{ height: 350, paddingLeft: 24, paddingRight: 24, }}>
        <Col xl={12} xs={12} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
          {(showCustomButtons && editedUserData?.role) && 
           <label style={{ font: 'normal normal bold 16px/16px Inter' }}>Game Role(s)</label>
          }
          <table style={tableStyle}>
            <thead style={tableHeaderStyle}>
              <TableRow
                rowStyles={{ height:'100%' }}
              >
                <TableHeaderItem 
                  headerText={itemType === ITEM_TYPE.COACH ? `${itemType}es` : `${itemType}s`}
                  headerStyles={{ width: getTableHeaderWidth(userItem?.role, editedUserData?.role, isNewUserNotLikeAdmin), paddingLeft: 20, }}
                />
                <TableHeaderItem 
                  headerText={assignedColumnTitle || 'Assign Role'}
                  headerStyles={{ justifyContent: 'center', }}
                />
              </TableRow>
            </thead>
            <tbody 
              ref={listRef} 
              className={_isMobile ? 'ipad-page-list-Items' : 'list-items-scroll'} 
              style={tableBodyStyle} onScroll={() => onListScrollHandler(_isMobile, listRef.current)}>
              {
                items.map((item, index) => (
                  <GameTableRow
                    key={`${index}_${item.id}_table_row`}
                    gameIndex={index}
                    item={item}
                    userItem={userItem}
                    itemType={itemType}
                    userFirstName={userFirstName}
                    gameRole={itemType === ITEM_TYPE.GAME && assignItemUsersRef.current.find(game => item.id === game.gameId)?.gameRole}
                    items={items}
                    itemsRemoved={itemsRemoved}
                    addGameUserParams={addGameUserParams}
                    addTeamUserParams={addTeamUserParams}
                    updateItemsHandler={updateItemsHandler}
                    toggleGameItemHandler={toggleGameItemHandler}
                    newUserRole={editedUserData?.role}
                    getNamedRoles={getNamedRoles}
                    showCustomButtons={showCustomButtons}
                    isNewGameRoleAssignment={isNewGameRoleAssignment}
                    isNewUserNotLikeAdmin={isNewUserNotLikeAdmin}
                    isGameRolesEditable={isGameRolesEditable}
                    commonTeamGames={commonTeamGames}
                    error={error}
                    assignedOptionList={assignedOptionList}
                  />
                ))
              }
            </tbody>
          </table>
        </Col>
      </Row>
    </ModalComponent>
  );
};

export default AssignGameUserRolesModal;