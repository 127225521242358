import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export const CenteredSpinner = styled(Spinner)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;