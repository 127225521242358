import { useInfiniteQuery } from "react-query";
import { LIST_PAGINATION_LIMIT } from "../utils/constantsUtils";
import { useItemsFromPages } from "../utils/reactQueryToolkit";
import { listSports } from "./sportsService";

export const sportsKeys = {
  all: ['sports'],
}

export const useSportsQuery = (options = {}) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: sportsKeys.all,
    queryFn: async ({ pageParam }) => {
      const queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      const teamsData = await listSports(queryVars);
      return teamsData;
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  // Aggregate across all query pages
  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};