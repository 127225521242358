import React, { useContext } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { getGamePercentageStatsData } from '../team/teamStatsUtility';
import { GameStatsContext } from './GameStatsContext';

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const DataItem = styled.div`
  width: auto;
  height: auto;
  padding-bottom: 10px;
  border-bottom: ${props => !props.isLastDataItem && '1px solid #D5D5D5'};
`;

const ImageItem = styled.div`
  width: 75%;
  height: 75%;
`;

const StatsData = styled.div`
  width: auto;
  height: auto;
  font: normal normal 13px/17px 'Segoe UI Bold';
  color: #000000;
`;

const StatsItemTitle = styled.div`
  width: auto;
  height: auto;
  font: normal normal 600 12px/16px 'Segoe UI';
  color: #000000;
  margin-Left: 7px;
`;

const StatsTitle = ({
  title,
}) => {
  return (
    <Row style={{ width: '100%', height: 20, margin: 0 }}>
      <Col xs={12} className="p-0 d-flex justify-content-start align-items-end">
        <StatsItemTitle>
          {title}
        </StatsItemTitle>
      </Col>
    </Row>
  );
};

const CustomProgressBar = ({
  statsData,
  maxData,
  progressBarBackgroundColor,
}) => {
  const useStyles = makeStyles(() => ({
    progressBar: {
      width: '100%',
      height: 12,
      '& .progress-bar': {
          backgroundColor: progressBarBackgroundColor
      },
  }
  }));

  const classes = useStyles();
  
  return (
    <ProgressBar 
      now={statsData}
      max={maxData}
      className={classes.progressBar}
    />
  );
};

const StatsProgressItem = ({
  teamImage,
  statsData,
  maxData = 100,
  progressBarBackgroundColor,
}) => {
  return (
    <Row style={{ width: '100%', height: 35, margin: 0 }}>
      <Col xs={2} className="p-0 d-flex justify-content-end align-items-center">
        <ImageItem>
          <img
            src={teamImage}
            width="28px"
            height="28px"
            style={{ objectFit: 'contain', border: "3px solid #D5D5D5", borderRadius: 5, }}
            alt=""
          />
      </ImageItem>
      </Col>
      <Col xs={8} className="p-0 d-flex justify-content-center align-items-center">
        <CustomProgressBar
          statsData={statsData}
          maxData={maxData}
          progressBarBackgroundColor={progressBarBackgroundColor}
        />
      </Col>
      <Col xs={2} className="p-0 d-flex justify-content-center align-items-center">
        <StatsData>
          {statsData}
        </StatsData>
      </Col>
    </Row>
  );
};

const AwayStatProgressItem = ({
  awayTeamImage,
  statItemData,
  maxData,  
}) => {
  return (
    <StatsProgressItem
      teamImage={awayTeamImage}
      statsData={statItemData}
      maxData={maxData}
      progressBarBackgroundColor="#552583"
    />
  );
};

const HomeStatProgressItem = ({
  homeTeamImage,
  statItemData,
  maxData, 
}) => {
  return (
    <StatsProgressItem
      teamImage={homeTeamImage}
      statsData={statItemData}
      maxData={maxData}
      progressBarBackgroundColor="#1C854A"
    />
  );
};

const StatsDataItem = ({
  statItemTitle,
  awayTeamImage, 
  homeTeamImage, 
  awayTeamStatItemData,
  homeTeamStatItemData,
  maxStatData,
  isLastDataItem,
}) => {
  return (
    <DataItem
      isLastDataItem={isLastDataItem}
    >
      <StatsTitle
        title={statItemTitle}
      />
      <HomeStatProgressItem
        homeTeamImage={homeTeamImage}
        statItemData={homeTeamStatItemData}
        maxData={maxStatData}
      />
      <AwayStatProgressItem
        awayTeamImage={awayTeamImage}
        statItemData={awayTeamStatItemData}
        maxData={maxStatData}
      />
    </DataItem>
  );
};

const GameTeamStatsPercentages = ({
}) => {
  const {
    awayTeamImage, 
    homeTeamImage, 
    gameStatsData,
  } = useContext(GameStatsContext);

  const statsData = getGamePercentageStatsData(gameStatsData);

  return (
    <Container>
      {statsData?.map((statsDataItem, index, arr) => {
        const [ statField, awayTeamStat, homeTeamStat, maxData ] = Object.values(statsDataItem);
        return (
          <StatsDataItem
            statItemTitle={statField}
            awayTeamImage={awayTeamImage}
            homeTeamImage={homeTeamImage}
            awayTeamStatItemData={typeof awayTeamStat == 'string' ? parseInt(awayTeamStat.replace('%', '')) : awayTeamStat}
            homeTeamStatItemData={typeof homeTeamStat == 'string' ? parseInt(homeTeamStat.replace('%', '')) : homeTeamStat}
            maxStatData={maxData}
            isLastDataItem={index === (arr.length - 1)}
          />
        );
      })}
    </Container>
  );
};

export default GameTeamStatsPercentages;