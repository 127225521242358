import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { listVenues } from '../../api/venuesService';
import UploadFileImage from '../../assets/FileUpload.png';
import { statesList } from '../../data/statesList';
import logger from '../../logger';
import { LIST_PAGINATION_LIMIT } from '../../utils/constantsUtils';
import { generateValidateCallback } from '../../utils/formikToolkit';
import { imageUploadChangeHandler } from '../../utils/imageUploadUtil';
import validation from '../../validation-config/venue';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import InputSwitchComponent from '../Checkbox/InputSwitchComponent';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import ImageUploadComponent from '../Inputs/ImageUploadComponent';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import ModalComponent from './ModalComponent';
const log = logger('AddEditVenueModal', 'info');

const AddEditVenueModal = ({
  isNewVenue,
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  venueData,
  error,
  setError,
}) => {

  const getInitialValues = () => {
    var initialValues = {
      name: '',
      city: '',
      zipcode: '',
      state: '',
      image: '',
      active: true,
      acceptedTerms: false,
    };
    // initialValues = {
    //   name: 'Test Stadium',
    //   city: 'Test City',
    //   zipcode: '11111',
    //   state: 'PA',
    //   image: null,
    //   active: true,
    //   acceptedTerms: true,
    // };
    if (!isNewVenue) {
      venueData.acceptedTerms = true;
    }
    return isNewVenue ? initialValues : venueData;
  };

  const venueValidationSchema = Yup.object({
    name: Yup.string().when('null', {
      is: () => validation.name.isRequired.value,
      then: Yup.string().required(validation.name.isRequired.message),
    }),
    city: Yup.string()
      .matches(validation.city.regex.value, validation.city.regex.message)
      .when('null', {
        is: () => validation.city.isRequired.value,
        then: Yup.string().required(validation.city.isRequired.message),
      }),
    zipcode: Yup.string()
      .min(validation.zipcode.length.value, validation.zipcode.length.message)
      .max(validation.zipcode.length.value, validation.zipcode.length.message)
      .when('null', {
        is: () => validation.zipcode.isRequired.value,
        then: Yup.string().required(validation.zipcode.isRequired.message),
      }),
    state: Yup.string().when('null', {
      is: () => validation.state.isRequired.value,
      then: Yup.string().required(validation.state.isRequired.message),
    }),
    image: Yup.mixed().when('null', {
      is: () => validation.image.isRequired.value,
      then: Yup.mixed().required(validation.image.isRequired.message),
    }),
    acceptedTerms: Yup.boolean()
      .oneOf([true], validation.acceptedTerms.isRequired.message)
      .when('null', {
        is: () => validation.acceptedTerms.isRequired.value,
        then: Yup.boolean().required(
          validation.acceptedTerms.isRequired.message
        ),
      }),
  });

  const duplicateCheck = useCallback(async (values, context) => {
    const { name, city, state, zipcode, active } = values;

    /* Skip check for editing venues, since only the enable setting can be changed. */
    if (!isNewVenue)
      return true;

    /* If any of these fields are missing, there can't be a duplicate in the DB. */
    if (!name || !city || !state || !zipcode || !active) return true;

    const queryVars = {
      filter: {
        name: { eq: name },
        city: { eq: city },
        state: { eq: state },
        zipcode: { eq: zipcode },
        active: { eq: active },
      },
      limit: LIST_PAGINATION_LIMIT.XLARGE,
    };
    
    let duplicateDataResponse = await listVenues(queryVars);

    if (duplicateDataResponse.errors) {
      /** Error checking for duplicate */
      return context.createError({
        message: `${duplicateDataResponse.errors[0].message}.`,
      });
    } 

    let items = duplicateDataResponse.items;
    while (items.length === 0 && duplicateDataResponse.nextToken) {
      duplicateDataResponse = await listVenues({
        ...queryVars,
        nextToken: duplicateDataResponse.nextToken,
      });
      items = [...items, ...duplicateDataResponse.items];
    }
    
    if (items.length >= 1) {
      /** Duplicate Exists */
      return context.createError({
        message: `The venue "${values.name} in ${values.city} ${values.state}, ${values.zipcode}" already exists.`,
      });
    }

    return true;
  }, [isNewVenue]);

  const venueValidationSchemaAsync = venueValidationSchema.test(
    'duplicate-check',
    'A duplicate venue was found.',
    duplicateCheck
  );

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      isInitialValid={venueValidationSchema.isValidSync(getInitialValues())}
      validateOnMount
      validate={generateValidateCallback(
        venueValidationSchemaAsync,
        (msg) =>
          setError(`Unable to ${isNewVenue ? 'create' : 'edit'} venue. ${msg}`),
        () => setError()
      )}
      onSubmit={async (values, formik) => {
        try {
          await onSubmit(values);
          formik.resetForm();
          onClose();
        } catch (err) {
          log.error(err);
        }
      }}
    >
      {(formik) => (
        <ModalComponent
          headerTitle={isNewVenue ? 'Add Venue' : 'Edit Venue'}
          buttonTitle={isNewVenue ? 'Add Venue' : 'Save Changes'}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={formik.handleSubmit}
          modalWidth={730}
          handleClose={() => {
            formik.resetForm();
            onClose();
          }}
          isFooterVisible={true}
          buttonDimmed={!formik.isValid || error}
          buttonDisabled={
            (!isNewVenue && !formik.dirty) || !formik.isValid || error
          }
        >
          {/* Modal Component Children are wrapped in a Form */}
          {/* Alert Column */}
          <Col sm={12} className="px-1">
            {/* Edit Warning */}
            {!isNewVenue && (
              <Alert variant={'warning'}>
                <i>
                  If you need to edit any of the disabled fields please contact
                  your Fast Stats administrator.
                </i>
              </Alert>
            )}
            {/* Error Message */}
            {error && <Alert variant={'danger'}>{error}</Alert>}
          </Col>
          <Row className="mb-2">
            <Col lg={4} xs={4}>
              <Row>
                <Col className="mb-3" lg={12} xs={12}>
                  <ImageUploadComponent
                    id="file-upload-component"
                    label="Upload Venue Image"
                    name="image"
                    imageHeight={'125px'}
                    imageMaxHeight={'125px'}
                    formik={formik}
                    onChange={imageUploadChangeHandler}
                    value={formik.values.image}
                    error={formik.touched.image && formik.errors.image}
                    errorMsg={formik.errors.image}
                    disabled={!isNewVenue}
                    placeHolder={UploadFileImage}
                  />
                </Col>

                <Col
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <InputSwitchComponent
                    name="active"
                    switchLabel="Venue Active?"
                    labelPositive={'Yes'}
                    labelNegative={'No'}
                    marginLeft={15}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={8}>
              <Row className="mb-2">
                <Col xs={12}>
                  <InputFieldComponent
                    name="name"
                    label="Venue Name"
                    type="text"
                    required={validation.name.isRequired.value}
                    disabled={!isNewVenue}
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col lg={12} xs={12}>
                  <SelectDropdown
                    name="state"
                    label="State"
                    required={validation.state.isRequired.value}
                    options={statesList}
                    disabled={!isNewVenue}
                    helperText={'Select State'}
                  >
                    <option value="">Select State</option>
                    {statesList?.map(({ name }) => {
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </SelectDropdown>
                </Col>
              </Row>

              <Row className="mb-0">
                <Col xs={6}>
                  <InputFieldComponent
                    name="city"
                    label="City"
                    type="text"
                    required={validation.city.isRequired.value}
                    disabled={!isNewVenue}
                  />
                </Col>

                <Col xs={6}>
                  <InputFieldComponent
                    /** Using "type=tel" with "maxlength=5" will keeps max num chars entered to 5 */
                    name="zipcode"
                    label="Zip Code"
                    type="tel"
                    maxlength="5"
                    required={validation.zipcode.isRequired.value}
                    disabled={!isNewVenue}
                    oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {isNewVenue && (
            <Row className="mb-2">
              <Col xs={12}>
                <InputCheckboxLabel
                  label="Please check the information you have entered as all or some of the fields will not be editable once the Venue has been added."
                  name="acceptedTerms"
                />
              </Col>
            </Row>
          )}
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditVenueModal;
