import styled from 'styled-components';
import escapeRegExp from 'lodash.escaperegexp';

export const SearchMenuDiv = styled.div`
  border-bottom: 0.5px solid #8080803d;
  border-opacity: 0.5;
  font-size: 14px;
  font-weight: bold;
  display: grid;
  padding-bottom: 14px;
  padding-top: 4px;
`;

export const SearchMenuMainCriteria = styled.span`
  text-align: left;
  font: normal normal 600 18px/25px Barlow Condensed;
  letter-spacing: 0px;
  color: #111111;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchMenuSubCriteriaDark = styled.span`
  font: normal normal 500 11px/16px Inter;
  letter-spacing: 0px;
  color: #2E2E2E;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchMenuSubCriteria = styled.span`
  font: normal normal 400 11px/16px Inter;
  letter-spacing: 0px;
  color: #878787;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { color: '#25A9E1' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
}
