
/**
 * Specifically for CSV Files Only
 * @returns 
 */
export const getCsvRowData = rawData => {
  const csvRows = [];
  const headers = Object.keys(rawData);
  csvRows.push(headers.join(','));
  return csvRows.join('\n')
};

/**
 * Handler for downloading files with a specific mime-type
 * @param: fileData: contents of the file to be downloaded
 * @param: filename: name given to the file to be downloaded
 * @param: mimeType: file extension of the file to be downloaded
 */
export const downloadFileHandler = (fileData, filename, mimeType) => {
  const blob = new Blob([fileData], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};

