import { Backdrop, makeStyles } from '@material-ui/core';
import { DataStore } from 'aws-amplify';
import isEqual from 'lodash.isequal';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socketClient from 'socket.io-client';
import styled, { ThemeProvider } from 'styled-components';
import { clockTimerStatus } from '../../api/clockService';
import { createEvent } from '../../api/eventsService';
import { useDerivedGameLineupDataQuery } from '../../api/gamesQueries';
import {
  getGameDataStore,
  getGameTeamStatsDatastore,
} from '../../api/gamesService';
import { useGetTeamImageQuery } from '../../api/teamsQueries';
import { ALERT_VARIANTS } from '../../components/alerts/Alert';
import { ItemTypes } from '../../components/Modals/ItemTypes';
import { SCOREKEEPER } from '../../data/roles';
import { Game, GameTeamStats } from '../../models';
import { selectLightMode } from '../../redux/themeSlice';
import {
  StyledColumnFlexStart as Column,
  StyledFlexRow as Row,
} from '../../styles';
import { colors } from '../../styles/colorsStatsCollector';
import { GAME_STATUS } from '../../utils/constantsUtils';
import { getGamePeriod, isPeriodEnded } from '../../utils/gameTimeUtil';
import { gameAdapter } from '../../utils/gameUtil';
import ClockHeader from '../ClockManager/ClockHeader';
import {
  selectActiveEventButton,
  selectIsTeamInPosessionSelected,
  selectState,
  setGameId,
  setGameTimerStatus,
  setTeamInPossessionSelected,
  setUser,
} from './controller/scoreKeeperEventSlice';
import StateManager from './controller/stateManager';
import { schema } from './controller/stateTemplate';
import { PossessionTeam } from './enums';
import EventActionPanel from './EventActionPanel';
import PlayFeed from './PlayFeed';
import { Scoreboard } from './Scoreboard';
import usePlayByPlay from './usePlayByPlay';

const log = require('../../logger')('ScoreKeeper', 'info');
const logGameTimer = require('../../logger')('GameTimer', 'debug');

/**
 * Timeout duration (in ms)
 * Used for banner alerts and highlighted players (changing lineup and substitutions)
 */
const NOTIFICATION_TIMEOUT = 3000;

let stateManager;

/**
 * Used by custom hook usePlayByPlay.
 * Is a filter function on events that determines which events should be shown.
 *
 * @param {*} event an event object
 * @returns true if the event should be shown, false otherwise
 */
function shouldDisplayEvent(event) {
  if (event.eventCreatorRole === 'ScoreKeeper') {
    /** Display ALL ScoreKeeper events */
    return true;
  } else if (event.eventCreatorRole === 'ClockManager') {
    /* Display all ClockManager events in ScoreKeeper */
    return true;
  } else {
    log.warn(
      'Received a game event with improper Creator Role ',
      event.eventCreatorRole
    );
    return false;
  }
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    cursor: 'not-allowed',
  },
  aboveBackdrop: {
    zIndex: theme.zIndex.drawer + 2,
  },
}));

const GameEndedText = styled.div`
  font: normal normal 600 20px/24px Open Sans;
  color: ${({ color }) => color};
  background: ${colors.BLACK[100]};
  padding: 30px 60px;
  border-radius: 5px;
  border: 1px solid ${colors.GRAY[1700]};
`;

const ScoreKeeperPage = () => {
  const classes = useStyles();
  /** local storage */
  const localStorage = window.localStorage;
  /** current game json data, which we stored when we switch from game choice page to this page */
  const currentGameJSON = localStorage.getItem('currentGame');
  const currentGame = JSON.parse(currentGameJSON);
  const gameId = currentGame.id;

  const lastEvent = useRef();
  const dispatch = useDispatch();
  /* Selector for ScoreKeeper Events */
  const state = useSelector(selectState);
  const lightMode = useSelector(selectLightMode);
  const isTeamInPossessionSelected = useSelector(
    selectIsTeamInPosessionSelected
  );
  const activeEventButton = useSelector(selectActiveEventButton);

  /** notification text for substitution */
  const [notificationText, setNotificationText] = useState();

  /* Create StateManager */
  useEffect(() => {
    stateManager = new StateManager(schema, dispatch);
  }, [dispatch]);

  /* Notify State Manager of every Event change*/
  useEffect(() => {
    if (!lastEvent.current || state.value !== lastEvent.current) {
      stateManager.onEvent(state);
    }
    lastEvent.current = state.value;
  }, [state]);

  /** Event List for Play-by-Play (Unique events exclude children events with a relatedEventId prop)*/
  const { playMap, playsLoaded } = usePlayByPlay(gameId, shouldDisplayEvent);

  const playMapAsArray = useMemo(
    () => Array.from(playMap?.values()).reverse(),
    [playMap]
  );
  const prevMostRecentPlay = useRef();

  /**
   * Detect a new substitution so we can notify the Score Keeper.
   */
  useEffect(() => {
    const mostRecentPlay = playMapAsArray[0];

    /* Check if we have a new substitution */
    if (
      mostRecentPlay?.eventType === 'SUBSTITUTION' &&
      mostRecentPlay?.subPlay &&
      prevMostRecentPlay.current &&
      !isEqual(mostRecentPlay, prevMostRecentPlay.current)
    ) {
      log.debug('Sub Notify (Scorekeeper)', mostRecentPlay);

      const p1 = mostRecentPlay.playerName;
      const p2 = mostRecentPlay.subPlay.playerName;
      const swapOrder =
        mostRecentPlay.playerOnCourtBenchStatus === ItemTypes.BENCH &&
        mostRecentPlay.subPlay.playerOnCourtBenchStatus === ItemTypes.COURT;

      setNotificationText({
        text: `${swapOrder ? p2 : p1} successfully substituted for ${
          swapOrder ? p1 : p2
        }.`,
        variant: ALERT_VARIANTS.BLUE,
      });
    } else {
      log.debug('Sub DID NOT Notify (Scorekeeper)', mostRecentPlay);
    }

    /* Keep track of most recent play, so we know when we have an update */
    prevMostRecentPlay.current = mostRecentPlay;
  }, [playMapAsArray]);

  /** TeamId-to-logo map to be used by components */
  const [teamLogoMap, setTeamLogoMap] = useState({});
  /** game timer when clock is running */
  const [gameTimer, setGameTimer] = useState();
  /** online and offline Score Keeper and Clock Manager */
  const [users, setUsers] = useState();
  /** stats for team, like total points, current possession */
  const [gameTeamStats, setGameTeamStats] = useState();

  const parsedUser = useSelector(
    (state) => state?.user?.value && JSON.parse(state?.user?.value)
  );

  const firstName = parsedUser?.attributes?.name;
  const lastName = parsedUser?.attributes?.family_name;
  const userSub = parsedUser?.attributes?.sub;
  const userEmail = parsedUser?.attributes?.email;
  const eventCreatorRole = 'ScoreKeeper';

  const parameters = `?sub=${userSub}&fn=${firstName}&ln=${lastName}&role=${SCOREKEEPER}`;

  /**
   * Information about current game like hometeam/away team names, ids, number of periods
   * of game and lineups
   */
  const [gameData, setGameData] = useState();

  /** Subscribe to Game and GameTeamStats updates and get initial data */
  useEffect(() => {
    log.debug('Subscribing to Game Data and Stats changes');

    (async function () {
      setGameData(gameAdapter(await getGameDataStore(gameId)));
      setGameTeamStats(await getGameTeamStatsDatastore(gameId));
    })();

    const subscriptionGame = DataStore.observe(Game).subscribe((msg) => {
      log.debug('Game Data Updated', msg);
      setGameData(gameAdapter(msg.element));
    });

    const subscriptionStats = DataStore.observe(GameTeamStats).subscribe(
      (msg) => {
        log.debug('Game Team Stats Updated', msg);
        const newStats = msg.element;
        setGameTeamStats((oldStats) => {
          /**
           * This is just for debugging purposes
           * Determine which fields on the game stats object changed to
           * make sure that the events are working correctly
           */
          if (log.getLevel() <= log.levels.DEBUG) {
            if (Object.entries(oldStats) && newStats) {
              let changes = Object.entries(oldStats).filter(
                ([key, value]) => value !== newStats[key]
              );
              if (changes?.length > 0)
                log.debug('GameTeamStats Changed Fields', changes);
            }
          }
          /** End Test */
          return newStats;
        });
      }
    );

    return () => {
      subscriptionGame.unsubscribe();
      subscriptionStats.unsubscribe();
    };
  }, [gameId]);

  const { data: homeTeamImage } = useGetTeamImageQuery(gameData?.homeTeamId);
  const { data: awayTeamImage } = useGetTeamImageQuery(gameData?.awayTeamId);

  const { data: lineupData } = useDerivedGameLineupDataQuery(
    gameData?.homeTeamGameLineupId,
    gameData?.awayTeamGameLineupId,
    gameData?.homeTeamId,
    gameData?.awayTeamId
  );

  const { homeTeamOnCourtPlayers, awayTeamOnCourtPlayers } = lineupData || {};

  /** When the game/clock timer changes update the local storage values and the game timer states */
  function onGameTimerUpdate(data) {
    logGameTimer.debug('GameTimer Socket - Timer Update', data);

    localStorage.setItem('minutes', data?.minutes);
    localStorage.setItem('seconds', data?.seconds);
    localStorage.setItem('quarter', data?.quarter);
    localStorage.setItem(
      'gameOvertimeNumber',
      data?.overtimeTracker ? data.overtimeTracker : null
    );
    setGameTimer(data);
    dispatch(setGameTimerStatus(data));
  }

  /** Socket connection and clock timer */
  useEffect(() => {
    /** Store Game and User info in the ScoreKeeper State Slice */
    dispatch(setGameId(gameId));
    dispatch(
      setUser({
        statcollFirstName: firstName,
        statcollLastName: lastName,
        statcollSub: userSub,
        statcollEmail: userEmail,
        eventCreatorRole: eventCreatorRole,
      })
    );

    /* Setup timeserver socket callbacks */
    const SERVER = `https://dev-clocktimer.faststats.live${parameters}`;
    const socket = socketClient(SERVER);
    logGameTimer.info('GameTimer Socket - Setup', SERVER);

    /* Client side connect callback */
    socket.on('connect', () => {
      logGameTimer.info(`GameTimer Socket - Connected ${socket.id}`);

      /* Set the Game ID for the time server */
      socket.emit('room', gameId);
      logGameTimer.info(`GameTimer Socket - Emitted Room ${gameId}`);

      /* Asynchronously get the timer status for the current game */
      (async function () {
        onGameTimerUpdate(await clockTimerStatus(gameId));
      })();
    });

    /* Client side disconnect callback */
    socket.on('disconnect', () => {
      logGameTimer.info(`GameTimer Socket - Disconnect`);
    });

    /* Subscribe a timer update callback */
    socket.on('timer', (data) => {
      onGameTimerUpdate(data);
    });

    /* Subscribe an online user callback */
    socket.on('users', (userList) => {
      logGameTimer.debug(`GameTimer Socket - users`, userList);
      log.debug('User List', userList);
      const filterUserList = userList.filter(
        (user) => user?.[1]?.room === gameId
      );
      setUsers(filterUserList);
    });

    /* Subscribe an offline user callback */
    socket.on('offline', (userList) => {
      logGameTimer.debug(`GameTimer Socket - offline`, userList);
      const filterUserList = userList.filter(
        (user) => user?.[1]?.room === gameId
      );
      setUsers(filterUserList);
    });

    return function offline() {
      logGameTimer.info(`GameTimer Socket - Exiting`);
      socket.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      setTeamLogoMap({
        [gameData?.homeTeamId]: homeTeamImage,
        [gameData?.awayTeamId]: awayTeamImage,
      });
    },
    [homeTeamImage, awayTeamImage, gameData?.homeTeamId, gameData?.awayTeamId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /** ID of the team currently in possession */
  const currentPossessionTeamId = gameTeamStats?.curr_possession;

  /** ID of the team currently out of possession */
  const currentOutOfPosessionTeamId =
    gameTeamStats?.curr_possession === gameData?.homeTeamId
      ? gameData?.awayTeamId
      : gameData?.homeTeamId;

  /** String enumeration (PossessionTeam) of the team in possesion */
  const currentPossession =
    currentPossessionTeamId === gameData?.homeTeamId
      ? PossessionTeam.HOME
      : currentPossessionTeamId === gameData?.awayTeamId
      ? PossessionTeam.AWAY
      : PossessionTeam.NONE;

  const homeTeamScore = gameTeamStats?.home_total_points || 0;
  const awayTeamScore = gameTeamStats?.away_total_points || 0;
  const homeTeamFouls =
    (gameTeamStats?.home_personal_foul || 0) +
    (gameTeamStats?.home_tech_foul || 0);
  const awayTeamFouls =
    (gameTeamStats?.away_personal_foul || 0) +
    (gameTeamStats?.away_tech_foul || 0);

  const hasGameEnded = useMemo(
    () =>
      [GAME_STATUS.ENDED, GAME_STATUS.CANCELED].includes(gameData?.gameStatus),
    [gameData]
  );

  const isScoreboardDataLoaded =
    gameData === undefined || !homeTeamImage || !awayTeamImage;

  /** Enable the Team in posession when there is no active event button */
  function isTeamInPossessionSelectable() {
    return !activeEventButton || activeEventButton === '';
  }

  /** Enable the Team out of posession when the team in posession is selected */
  function isTeamOutOfPossessionSelectable() {
    return isTeamInPossessionSelected;
  }

  /** When the team in posession is selected toggle the redux state */
  function onSelectTeamInPosession() {
    log.debug('onSelectTeamInPosession');
    if (isTeamInPossessionSelected) {
      dispatch(setTeamInPossessionSelected(false));
    } else {
      dispatch(setTeamInPossessionSelected(true));
    }
  }

  /** When the team out of posession is selected crate a change possession event and clear the selections */
  function onSelectTeamOutOfPosession() {
    log.debug('onSelectTeamOutOfPosession');
    changePossession();
    dispatch(setTeamInPossessionSelected(false));
  }

  /** Change Posession */
  const changePossession = async () => {
    log.info('Changing Possession');

    /* Get Stat Collector and Current Timer info from local storage */
    const firstName = localStorage?.getItem('firstName');
    const lastName = localStorage?.getItem('lastName');
    const minutes = localStorage?.getItem('minutes');
    const seconds = localStorage?.getItem('seconds');
    const quarter = localStorage?.getItem('quarter');
    const gameOvertimeNumber =
      localStorage?.getItem('gameOvertimeNumber') &&
      parseInt(localStorage?.getItem('gameOvertimeNumber'));

    /* Team data object for the out of posession team */
    const teamOutOfPosession =
      currentOutOfPosessionTeamId === gameData?.homeTeamId
        ? gameData?.homeTeam
        : gameData?.awayTeam;

    /* Create a posession change event for the team out of posession */
    const possessionEvent = await createEvent({
      gameOvertimeNumber: gameOvertimeNumber ? gameOvertimeNumber : undefined,
      eventType: 'POSSESSION',
      minutes,
      seconds,
      quarter,
      game: gameData,
      team: teamOutOfPosession,
      posessionId: currentOutOfPosessionTeamId,
      statcollFirstName: firstName ? firstName : 'First Name',
      statcollLastName: lastName ? lastName : 'Last Name',
      statcollEmail: userEmail ? userEmail : null,
      statcollSub: userSub ? userSub : null,
      eventCreatorRole: eventCreatorRole,
    });

    // Show a notification to the user for successful or unsuccesful change poss event
    setNotificationText({
      text: possessionEvent
        ? `Possession changed to ${teamOutOfPosession?.name}`
        : 'Possession change was not successful',
      variant: possessionEvent ? ALERT_VARIANTS.GREEN : ALERT_VARIANTS.RED,
    });
  };

  return (
    <ThemeProvider theme={{ lightMode: lightMode }}>
      {/* When game has ended, disable interaction except for the header */}
      <Backdrop open={hasGameEnded} className={classes.backdrop}>
        {gameData?.gameStatus === GAME_STATUS.ENDED ? (
          <GameEndedText color={colors.BLUE[400]}>Game Ended</GameEndedText>
        ) : gameData?.gameStatus === GAME_STATUS.CANCELED ? (
          <GameEndedText color={colors.RED[400]}>Game Canceled</GameEndedText>
        ) : (
          <></>
        )}
      </Backdrop>
      <Container>
        <ClockHeader
          users={users}
          endGameModalOpen={false}
          hasGameEnded={hasGameEnded}
          notificationText={notificationText}
          notificationTimeout={NOTIFICATION_TIMEOUT}
          className={`mx-n3 ${classes.aboveBackdrop}`}
          style={{
            height: HEADER_HEIGHT,
            minHeight: HEADER_HEIGHT,
            borderBottom: 'none',
            padding: '0 17px',
            backgroundColor: colors.BLACK[100],
          }}
        />

        <MainContent>
          {isPeriodEnded(gameTimer) && (
            <TimerStatusBanner>
              {getGamePeriod(
                gameData?.leagueNumPeriods,
                gameTimer?.quarter,
                gameTimer?.overtimeTracker
              )}{' '}
              Ended
            </TimerStatusBanner>
          )}

          <Column
            style={{
              marginRight: 20,
              flex: 0.9,
            }}
          >
            <Scoreboard
              style={{ marginBottom: 20 }}
              gameTimer={gameTimer}
              homeTeamName={gameData?.homeTeamName}
              awayTeamName={gameData?.awayTeamName}
              homeTeamImage={homeTeamImage}
              awayTeamImage={awayTeamImage}
              homeTeamScore={homeTeamScore}
              awayTeamScore={awayTeamScore}
              homeTeamFouls={homeTeamFouls}
              awayTeamFouls={awayTeamFouls}
              currentPossession={currentPossession}
              numPeriods={gameData?.leagueNumPeriods}
              isLoading={isScoreboardDataLoaded}
              isTeamInPossessionSelectable={isTeamInPossessionSelectable()}
              isTeamOutOfPossessionSelectable={isTeamOutOfPossessionSelectable()}
              isTeamInPosessionSelected={isTeamInPossessionSelected}
              onSelectTeamInPosession={onSelectTeamInPosession}
              onSelectTeamOutOfPosession={onSelectTeamOutOfPosession}
            />
            <PlayFeed
              plays={playMapAsArray}
              isLoading={!playsLoaded}
              gameData={gameData}
              teamLogoMap={teamLogoMap}
              numPeriods={gameData?.leagueNumPeriods}
              style={{
                flex: 1,
              }}
            />
          </Column>

          <EventActionPanel
            homeTeamLogo={homeTeamImage}
            awayTeamLogo={awayTeamImage}
            homeTeamPlayers={homeTeamOnCourtPlayers}
            awayTeamPlayers={awayTeamOnCourtPlayers}
            possessionTeam={currentPossession}
            gameData={gameData}
            lineupData={lineupData}
            style={{
              maxHeight: '100%',
              flex: 1.875,
            }}
          />
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

const HEADER_HEIGHT = '50px';
const MIN_HEIGHT_CONTAINER = '675px';
const MIN_HEIGHT_CONTENT = `calc(${MIN_HEIGHT_CONTAINER} - ${HEADER_HEIGHT})`;
const MIN_WIDTH_CONTAINER = '1195px';

const Container = styled.div`
  background-color: ${colors.BLACK[100]};
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  width: 100vw;
  min-height: ${MIN_HEIGHT_CONTAINER};
  min-width: ${MIN_WIDTH_CONTAINER};
`;

const TimerStatusBanner = styled.div`
  width: calc(100% - 40px);
  height: 18px;
  position: absolute;
  top: 1px;
  left: 20px;
  background: ${colors.GRAY[975]} 0% 0% no-repeat padding-box;
  border-radius: 5px;

  text-align: center;
  font: normal normal bold 12px/20px Open Sans;
  letter-spacing: 2.4px;
`;

const MainContent = styled(Row)`
  &&& {
    height: calc(var(--app-height) - ${HEADER_HEIGHT});
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[600] : colors.BLACK[900]};
    padding: 20px;
    position: relative;
    overflow: auto;
    min-height: ${MIN_HEIGHT_CONTENT};
    min-width: ${MIN_WIDTH_CONTAINER};
  }
`;

export default ScoreKeeperPage;
