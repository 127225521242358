import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import FormControlWithFormik from '../../../../components/Inputs/FormControlWithFormik';
import { colors } from '../../../../styles/colorsStatsCollector';

const ClockInputGroup = styled(InputGroup)`
  &&& {
    background: ${colors.BLACK[400]};
    border: 1px solid
      ${({ $isInvalid }) => ($isInvalid ? colors.RED[200] : colors.GRAY[1600])};
    border-radius: 5px;
  }
`;

const ClockNumberInput = styled((props) => {
  const [field, , helper] = useField(props);

  useEffect(() => {
    const numToString = field.value.toString();
    if (numToString.length > 2)
      helper.setValue(parseInt(numToString.slice(0, 2)));
  }, [field.value, helper]);

  return <FormControlWithFormik {...props} type="number" step={1} />;
})`
  &&& {
    border: none;
    background: ${colors.BLACK[400]};
    color: ${colors.WHITE[100]};
    font: normal normal normal 28px/24px Digital Display;
    height: 39px;
    letter-spacing: 5px;

    &.is-invalid {
      padding-right: inherit;
      background-image: none;
    }
  }
`;

const ClockInputSeparator = styled(InputGroup.Text)`
  &&& {
    padding: 0;
    border: none;
    background: ${colors.BLACK[400]};
    color: ${colors.WHITE[100]};
    font: normal normal normal 28px/24px Digital Display;
  }
`;

const ClockInput = ({ minutes, seconds, periodDuration }) => {
  const { touched, errors } = useFormikContext();
  const isInvalid =
    touched.minutes && touched.seconds && (errors.minutes || errors.seconds);

  return (
    <ClockInputGroup $isInvalid={isInvalid}>
      <ClockNumberInput
        name="minutes"
        min={0}
        max={periodDuration}
        value={minutes?.toString().padStart(2, '0')}
      />
      <ClockInputSeparator>:</ClockInputSeparator>
      <ClockNumberInput
        name="seconds"
        min={0}
        max={59}
        value={seconds?.toString().padStart(2, '0')}
      />
    </ClockInputGroup>
  );
};

export default ClockInput;
