import React from 'react';
import styled from 'styled-components';

const GameStatusDisplay = styled.div`
  width: auto;
  height: auto;
  font: normal normal 16px/18px 'Segoe UI Bold';
  border-radius: 10px;
  border: 2px solid ${props => (props.isGameDrawn && !props.isGameCancelled) ?  '#2B92BE' : '#B50000'};
  color: ${props => (props.isGameDrawn && !props.isGameCancelled) ? '#2B92BE' : '#B50000'};
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  padding-left: ${props => props.paddingLeft}px;
  padding-right: ${props => props.paddingRight}px;
`;

const GameStatus = ({
  isGameDrawn = false,
  isGameCancelled = false,
  paddingTop = 8,
  paddingBottom = 8,
  paddingLeft = 18,
  paddingRight = 18,
}) => {
  return (
    <GameStatusDisplay
      isGameDrawn={isGameDrawn}
      isGameCancelled={isGameCancelled}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      {(isGameDrawn && !isGameCancelled) ? 'DRAW' : 'CANCELLED'}      
    </GameStatusDisplay>
  );
};

export default GameStatus;