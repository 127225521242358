import React from 'react';
import { colors, typeScale } from '../../styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const TextButtonStyled = styled(Button)`
  &&& {
    color: ${colors.PRIMARY[200]};
    text-transform: capitalize;
    font-size: ${typeScale.MEDIUM.fontSize};
  }
`;

const TextButton = ({ label, handleClick }) => {
  return (
    <TextButtonStyled onClick={() => handleClick()}>{label}</TextButtonStyled>
  );
};

export default TextButton;
