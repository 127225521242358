// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Sport, League, Season, Game, GameLineup, Team, Player, GameLineupPlayer, GameUser, User, TeamUser, Venue, Event, CareerPlayerStats, CareerTeamStats, GamePlayerStats, GameTeamStats, SeasonPlayerStats, SeasonTeamStats, ScoreType, TeamRosterPlayer, TeamRoster, TimeEvent, CustomStat, EventPlayer } = initSchema(schema);

export {
  Sport,
  League,
  Season,
  Game,
  GameLineup,
  Team,
  Player,
  GameLineupPlayer,
  GameUser,
  User,
  TeamUser,
  Venue,
  Event,
  CareerPlayerStats,
  CareerTeamStats,
  GamePlayerStats,
  GameTeamStats,
  SeasonPlayerStats,
  SeasonTeamStats,
  ScoreType,
  TeamRosterPlayer,
  TeamRoster,
  TimeEvent,
  CustomStat,
  EventPlayer
};