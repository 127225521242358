import React, { useState, useEffect } from 'react';
import CheckboxWithLabel from './CheckboxWithLabel';

const checkboxListItemStyle = { 
  width: '98%', 
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: 5,
  marginBottom: 5, 
  paddingLeft: 5,
};

const CustomCheckboxSelectItem = ({ 
  id,
  checkboxItemLabel,
  checkboxItem, 
  selectedCheckboxItems, 
  setSelectedCheckboxItems, 
  resetCheckboxState, 
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(checked) {
      setSelectedCheckboxItems([ ...selectedCheckboxItems, checkboxItem ]);
    }
    else {
      const selectedPlayerIndex = selectedCheckboxItems.findIndex(({ id }) => id === checkboxItem.id);
      selectedCheckboxItems.splice(selectedPlayerIndex, 1);
      setSelectedCheckboxItems([...selectedCheckboxItems]);
    }
  }, [checked]);

  useEffect(() => {
    if(checked) {
      setChecked(false);
    }
  }, [resetCheckboxState]);

  const onChangeHandler = (event, checked) => {
    setChecked(!checked);
    event.stopPropagation();
  };

  return (
    <div 
      style={{ ...checkboxListItemStyle, backgroundColor: checked ? '#D8E6FF' : 'transparent' }} 
      onClick={event => event.stopPropagation()}
    >
      <CheckboxWithLabel
        label={(() => checkboxItemLabel(checked))()}
        checked={checked}
        name={id}
        key={id}
        onChange={event => onChangeHandler(event, checked)}
      />
    </div>
  );
};

export default CustomCheckboxSelectItem;