import { createSlice } from '@reduxjs/toolkit'

export const pathLocationSlice = createSlice({
	name: 'pathLocation',
	initialState: {
		value: ''
	},
	reducers: {
	  updatePathLocation: (state, action) => {
      state.value = action.payload?.pathLocationValue;
    }
	}
});

export const { updatePathLocation } = pathLocationSlice.actions;

export default pathLocationSlice.reducer;