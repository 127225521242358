import { createSlice } from '@reduxjs/toolkit';

export const statsGameListScrollPositionSlice = createSlice({
  name: "statsGameListScrollPosition",
  initialState: {
    scrollPositionValue: 0
  },
  reducers: {
    updateScrollPosition: (state, action) => {
      state.scrollPositionValue = action.payload?.scrollPosition;
    }
  }
});

export const { updateScrollPosition } = statsGameListScrollPositionSlice.actions;

export default statsGameListScrollPositionSlice.reducer;