import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles';
import TeamStatRecord from './TeamStatRecord';
import StatsHeader from './StatsHeader';

const MainTeamStats = styled.div`
  width: 100%;
  background-color: ${colors.WHITE[100]};
  border-radius: 10px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: 1px solid #D5D5D5;
`;

const MainStatsHeader = ({
  homeTeamImage, 
  awayTeamImage, 
}) => {
  return (
    <StatsHeader
      awayTeamImage={awayTeamImage}
      homeTeamImage={homeTeamImage}
      imageHeaderSize="55px"
      headerHeight="85px"
      colSize1={8}
      colSize2={4}
    >
      Team Stats
    </StatsHeader>
  );
};

const MainStats = ({
  homeTeamImage, 
  awayTeamImage, 
  teamStatsData,
}) => {
  return (
    <MainTeamStats>
      <MainStatsHeader
        homeTeamImage={homeTeamImage}
        awayTeamImage={awayTeamImage}
      />
      {teamStatsData?.map((statData, index) => {
        const [ statsField, awayTeamStatValue, homeTeamStatValue ] = Object.values(statData);

        return (
          <TeamStatRecord
            key={`stat_field_${index}`}
            statField={statsField}
            awayTeamStatValue={awayTeamStatValue}
            homeTeamStatValue={homeTeamStatValue}
            backgroundColor={ index % 2 === 0 ? '#F3F3F3' : 'transparent' }
            borderBottom={ index === (teamStatsData?.length - 1) ? '1px solid #EBEBEB' : null }
            paddingLeft={20}
          />
        )
      })}
    </MainTeamStats>
  )
};

export default MainStats;