import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ButtonElement from '../Buttons/ButtonElement';
import { colors, textEllipsisStyle } from '../../styles';
import { UserGameRole } from '../../styles/users';
import { SCOREKEEPER, CLOCKMANAGER, COACH, ROLE_COLOR } from '../../data/roles';
import { seasonFormatDisplay } from '../../utils/seasonsUtil';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import EditIcon from '../../assets/edit@2x.png';
import DarkEditIcon from '../../assets/dark_edit@2x.png';
import PlayerJersey from '../../assets/regular-jersey@2x.png';
import { useUserRoles } from '../../api/userQueries';
import {
  getAssignedUserGameRole,
  getAssignedTeamCoaches,
} from '../../utils/userUtil';
import { isMobile, addScrollListClass, onListScrollHandler } from '../../utils/scrollUtil';
import { getFormattedDateInUppercaseWithDaySuffixedSuperscript } from '../../utils/momentDateUtil';
import { GAME_STATUS, ITEM_TYPE } from '../../utils/constantsUtils';

/***************** START STYLES ***************/
const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const gameLineupInfoContainerStyles = { 
  width: '100%', 
  height: '80%', 
  margin: 0, 
};

const gameDetailsRowContainerStyle = { 
  display: 'flex', 
  flexDirection: 'row',  
  height: '100%',
};

const gameLineupInfoLayoutStyles = { 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-start' 
};

const gameDateInfoContentDisplayStyle = { 
  width: '100%', 
  maxHeight: 36, 
  height: 36, 
  borderTopLeftRadius: 5, 
  borderTopRightRadius: 5, 
  backgroundColor: colors.BLACK[200],
  position: 'relative',
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'center',
  font: 'normal normal bold 16px/9px Inter',
  color: colors.WHITE[100],
};

const gameHomeTeamAndAwayTeamContainerLayoutStyle = { 
  width: '100%', 
  height: '100%', 
  margin: 0, 
};

const gameHomeTeamContainerStyle = { 
  ...gameDetailsRowContainerStyle, 
  justifyContent: 'flex-start', 
};

const gameAwayTeamContainerStyle = { 
  ...gameDetailsRowContainerStyle, 
  justifyContent: 'flex-end', 
};

const teamContainerLayoutStyle = { 
  width: '99.5%', 
  height: '100%', 
  backgroundColor: colors.WHITE[100], 
  borderBottomLeftRadius: 6, 
  borderBottomRightRadius: 6, 
};

const gamePlayersContentDisplayStyle = { 
  width: '100%', 
  height: 'calc(98% - 36px)', 
  position: 'relative', 
};

const gameDetailsInfoContainerStyles = { 
  width: '100%', 
  height: '20%', 
  margin: 0, 
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const versusContainerTextStyle = { 
  width: 40, 
  height: 40, 
  borderRadius: 20, 
  backgroundColor: '#111111', 
  position: 'absolute', 
  top: 60, 
  left: '50%',
  transform: 'translate(-50%, 0)', 
  font: 'normal normal 600 22px/25px Barlow Condensed', 
  color: colors.WHITE[100], 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  zIndex: 10,
};

const teamTextContainerStyle = {
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start',
};

const teamNameTextContainerStyle = { 
  width: '100%', 
  height: '50%',
  display: 'flex', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-end', 
  font: 'normal normal 600 26px/34px Barlow Condensed', 
  color: '#111111', 
  marginTop: 15, 
};

const courtAndBenchContainerStyles = { 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  position: 'relative', 
};

const courtAndBenchTextStyle = { 
  width: 'auto', 
  height: 'auto', 
  paddingLeft: 10, 
  paddingRight: 10, 
  backgroundColor: colors.WHITE[100], 
  position: 'absolute', 
  left: '50%', 
  top: -12, 
  transform: 'translate(-50%, 0)', 
  font: 'normal normal bold 12px/24px Inter', 
  color: '#111111', 
};

const courtContainerStyle = { 
  width: '94%', 
  height: '98%', 
  borderTop: `1px solid ${colors.GRAY[200]}`, 
  paddingBottom: 8, 
  position: 'relative',
};

const benchContainerStyle = { 
  width: '94%', 
  height: '70%', 
  borderTop: `1px solid ${colors.GRAY[200]}`,
  position: 'relative',
 };

const courtPlayerItemContainerStyle = { 
  width: '30%', 
  height: '50%', 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const courtPlayerItemJerseyNumberContainerStyle = { 
  display: 'flex', 
  justifyContent: 'center', 
  position: 'relative', 
};

const teamGamLineupNoPlayerContainerTextStyle = { 
  width:'100%', 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'flex-end', 
  font: 'normal normal normal 12px/16px Inter', 
};

const courtPlayerItemNameContainerStyle = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'flex-start', 
};

const teamGameCourtPlayersLineupContainerStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  flexFlow: 'row wrap', 
};

const teamGameBenchPlayersLineupContainerStyle = { 
  width: '100%', 
  height: 0, 
  margin: 0, 
  padding: 10, 
  display: 'flex', 
  flexDirection: 'row', 
  flexWrap: 'wrap', 
};

const benchPlayerItemContainerStyle = { 
  width: 110, 
  height: 20, 
  display: 'flex', 
  flexDirection: 'row', 
};

const benchPlayerItemBulletCircleContainerStyle = { 
  width: 10, 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const benchPlayerItemBulletCircleStyle = { 
  width: 6, 
  height: 6, 
  borderRadius: 3, 
  backgroundColor: 
  colors.BLACK[100], 
};

const benchPlayerItemNameContainerStyle = { 
  width: 100, 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  font: 'normal normal normal 12px/20px Inter', 
  color: '#6D6D6D', 
};

 const jerseyNumberStyle = {
  position: 'absolute',
  top: 31,
  width: '100%',
  textAlign: 'center',
  font: 'normal normal bold 14px/24px Open Sans',
  color: '#111111',
};

const editPlayerHandlerIcon = { 
  width: 40, 
  height: 20, 
  backgroundColor: colors.WHITE[100], 
  position: 'absolute', 
  top: -8, 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'center', 
  padding: 10, 
};

 const addPlayerButtonStyle = {
   position: 'absolute', 
   top: '50px', 
   left: '50%', 
   transform: 'translate(-50%, 0)', 
   zIndex: 99, 
   font: 'normal normal bold 14px/24px Inter', 
   minWidth: '165px', 
  };

const teamTypeTextContainerStyle = { 
  ...teamTextContainerStyle,
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-start', 
  font: 'normal normal normal 12px/16px Inter', 
  color: '#979797', 
};

const gameDetailsRowFlexStartContainerStyle = {
  ...gameDetailsRowContainerStyle,
  justifyContent: 'flex-start',
};

const gameDetailsRowFlexEndContainerStyle = {
  ...gameDetailsRowContainerStyle,
  justifyContent: 'flex-end',
};

const gameDetailsContentStyle = { 
  height: '100%', 
  borderRadius: 6, 
  backgroundColor: colors.WHITE[100],
  position: 'relative',
};

const detailsTitleContainerStyle = {
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  alignItems: 'flex-end', 
  justifyContent: 'center',
  font: 'normal normal normal 10px/13px Inter',
  color: colors.GRAY[300],
};

const detailsValueContainerStyle = {
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  alignItems: 'flex-start', 
  justifyContent: 'center',
  font: 'normal normal 600 14px/19px Barlow Condensed',
  color: '#111111',
};

const detailImageContainerStyle = { 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'center', 
};

const gameUserDetailsTitleContainerStyle = { 
  width: '100%', 
  height: 25, 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
};

const gameUserDetailsTitleStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  font: 'normal normal bold 11px/15px Barlow', 
  color: '#5A5A5A', 
  position: 'relative',
};

const gameUsersRolesItemsStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  overflowY: 'auto', 
};

const gameUserRoleContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const gameUserRoleEmptyMessageContainerStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
  font: 'normal normal normal 10px/12px Inter', 
  color: '#5D5D5D', 
};

const gameRoleVerticalDividerContainerStyle = { 
  width: '4%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
};

const gameRoleVerticalDividerStyle = { 
  width: 2, 
  height: '100%', 
  borderLeft: `1px solid ${colors.GRAY[200]}`, 
};

const leagueTitleStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
  font: 'normal normal normal 10px/13px Inter', 
  color: colors.GRAY[300], 
};

const leagueValueStyle = { 
  width: 'auto', 
  maxWidth: '85%', 
  height: '100%', 
  ...textEllipsisStyle, 
  WebkitLineClamp: 1, 
  font: 'normal normal 600 16px/30px Barlow Condensed', 
  color: '#111111', 
};

const leagueLogoImageStyle = { 
  width: '100%', 
  height: '100%', 
  ...detailImageContainerStyle, 
  alignItems: 'flex-end', 
};

const userRoleNameStyle = { 
  width: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
};

const userRoleNameTextStyle = { 
  width: 'auto', 
  maxWidth: '85%',
  ...textEllipsisStyle, 
};

/***************** END STYLES *****************/

const PlayerName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  font: normal normal normal 10px/24px Inter;
  color: #414141;
  text-align: center;
  position: absolute;
  top: 53px;
`;

const PlayerListItem = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  font: normal normal normal 10px/24px Inter;
  color: #6D6D6D;
  text-align: left;
  margin: 0;
`;

const GameDetailItem = ({
  title,
  value,
  titleStyles = {},
  valueStyles = {},
  colQuery = 12,
}) => {
  return (
    <Col xl={colQuery} xs={colQuery} md={colQuery} className="p-0">
      <div style={{ ...detailsTitleContainerStyle, ...titleStyles, }}>
        {title}
      </div>
      <div style={{ ...detailsValueContainerStyle, ...valueStyles, }}>
        {value}
      </div>
    </Col>
  );
};

const CourtPlayerItem = ({ 
  player, 
  playerJerseyNumber, 
}) => {
  const { firstName, lastName } = player;
  const playerName = `${firstName} ${lastName}`;
  return (
    <div style={courtPlayerItemContainerStyle}>
      <Row style={{ width: '100%', height: '75%', margin: 0, }}>
        <Col xs={12} xl={12} md={12} className="p-0" style={courtPlayerItemJerseyNumberContainerStyle}>
          <img
            src={PlayerJersey}
            style={{ ...imageStyle, position: 'absolute', top: 24, }}
            width="50px"
            height="30px"
            alt=""
          />
          <div style={jerseyNumberStyle}>{playerJerseyNumber}</div>
          <PlayerName>{playerName}</PlayerName>
        </Col>
      </Row>
    </div>
  );
};

const BenchPlayerItem = ({ 
  player 
}) => {
  const playerName = `${player.firstName} ${player.lastName}`;
  return (
    <div style={benchPlayerItemContainerStyle}>
      <div style={benchPlayerItemBulletCircleContainerStyle}>
        <div style={benchPlayerItemBulletCircleStyle}>
        </div>
      </div>
      <div style={benchPlayerItemNameContainerStyle}>
        <PlayerListItem>{playerName}</PlayerListItem>
      </div>
    </div>
  );
};

const GameTeamCourtPlayersLineup = ({
  courtPlayers,
}) => {
  return (
    <div style={teamGameCourtPlayersLineupContainerStyle}>
      {courtPlayers?.map(courtPlayer => {
        const { id, player, playerJerseyNumber } = courtPlayer;
        return (
          <CourtPlayerItem
            key={id}
            player={player}
            playerJerseyNumber={playerJerseyNumber}
          />
        );
      })
      }
    </div>
  );
};

const GameTeamBenchPlayersLineup = ({
  benchPlayers,
}) => {
  return (
    <div style={teamGameBenchPlayersLineupContainerStyle}>
      {benchPlayers.map(benchPlayer => {
        const { id, player } = benchPlayer;
        return (
          <BenchPlayerItem
            key={id}
            player={player}
          />
        );
      })}
    </div>
  );
};

const EditItemCtrl = ({
  editIconSize,
  onClickHandler,
  isDisabled = false,
}) => {
  return (
    <img
      src={DarkEditIcon}
      style={{ cursor: 'pointer', display: isDisabled ? 'none' : 'block' }}
      width={`${editIconSize}px`}
      height={`${editIconSize}px`}
      onClick={onClickHandler}
      alt=""
    />
  );
};

const GameTeamLineupDetailItem = ({
  team,
  teamType,
  gameDateTime,
  setAddPlayersModalOpen,
  setIsHomeTabSelected,
  gameTeamContainerStyle,
  courtPlayers,
  benchPlayers,
  editDisabled,
}) => {
  const {
    name,
    image,
    // players,
  } = team;

  return (
    <Col xs={6} xl={6} md={6} className="p-0" style={gameTeamContainerStyle}>
      <div style={teamContainerLayoutStyle}>
        <Row style={{ width: '100%', height: '22%', margin: 0 }}>
          <Col
            xs={12}
            xl={12}
            md={12}
            className="p-0"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '65%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '30%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <img
                  src={image}
                  style={{ ...imageStyle, width: null }}
                  width="60px"
                  height="70px"
                  alt=""
                />
              </div>
              <div
                style={{
                  width: '70%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={teamNameTextContainerStyle}>
                  <span style={textEllipsisStyle}>&nbsp;{name}</span>
                </div>
                <div style={teamTypeTextContainerStyle}>
                  <span>&nbsp;{teamType}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {courtPlayers?.length === 0 &&
        benchPlayers?.length === 0 &&
        moment(gameDateTime, 'YYYY-MM-DD h:mmA').valueOf() <
          moment().valueOf() ? (
          <Row style={{ width: '100%', height: '43%', margin: 0 }}>
            <Col
              xs={12}
              xl={12}
              md={12}
              className="p-0"
              style={courtAndBenchContainerStyles}
            >
              <div style={{ ...editPlayerHandlerIcon, right: 8 }}></div>
              <div style={courtContainerStyle}>
                <div style={teamGamLineupNoPlayerContainerTextStyle}>
                  <div
                    style={{
                      width: '60%',
                      height: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    {
                      'This is a past game and is no longer active. No players were added to the team.'
                    }
                  </div>
                </div>
              </div>
              <div style={{ ...editPlayerHandlerIcon, left: 6 }}></div>
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ width: '100%', height: '43%', margin: 0 }}>
              <Col
                xs={12}
                xl={12}
                md={12}
                className="p-0"
                style={courtAndBenchContainerStyles}
              >
                <div style={courtContainerStyle}>
                  {courtPlayers?.length > 0 && (
                    <GameTeamCourtPlayersLineup courtPlayers={courtPlayers} />
                  )}
                  <div style={courtAndBenchTextStyle}>COURT</div>
                </div>
                <div
                  style={{
                    ...editPlayerHandlerIcon,
                    width: 20,
                    padding: 0,
                    left: teamType === 'Away' ? 18 : 6,
                  }}
                ></div>
                <div
                  style={{ ...editPlayerHandlerIcon, right: 8, paddingLeft: 6 }}
                >
                  <EditItemCtrl
                    editIconSize={14}
                    isDisabled={courtPlayers?.length > 0 && editDisabled}
                    onClickHandler={() => {
                      setIsHomeTabSelected(teamType === 'Home');
                      setAddPlayersModalOpen(true);
                    }}
                  />
                </div>
                {courtPlayers?.length === 0 && (
                  <ButtonElement
                    style={addPlayerButtonStyle}
                    label={'+ Add Player'}
                    onClick={() => {
                      setIsHomeTabSelected(teamType === 'Home');
                      setAddPlayersModalOpen(true);
                    }}
                  />
                )}
              </Col>
            </Row>

            <Row style={{ width: '100%', height: '35%', margin: 0 }}>
              <Col
                xs={12}
                xl={12}
                md={12}
                className="p-0"
                style={courtAndBenchContainerStyles}
              >
                <div style={benchContainerStyle}>
                  {benchPlayers?.length > 0 && (
                    <GameTeamBenchPlayersLineup benchPlayers={benchPlayers} />
                  )}
                  <div style={courtAndBenchTextStyle}>BENCH</div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Col>
  );
};

const SeasonDetails = ({
  season,
}) => {
  const {
    startDate,
    endDate,
    seasonType,
  } = season;

  return (
    <div style={{ width: '98%', ...gameDetailsContentStyle }}>
      <Row style={{ width: '100%', height: '50%', margin: 0, }}>
        <GameDetailItem
          title={'Season'}
          value={seasonFormatDisplay(season)}
          valueStyles={{fontSize: 16}}
        />
      </Row>
      <Row style={{ width: '100%', height: '50%', margin: 0, }}>
        <GameDetailItem
          title={'Start Date'}
          value={moment(startDate).format('MM.DD.YYYY')}
          titleStyles={{height: '35%'}}
          valueStyles={{height: '65%'}}
          colQuery={6}
        />
        <GameDetailItem
          title={'End Date'}
          value={moment(endDate).format('MM.DD.YYYY')}
          titleStyles={{height: '35%'}}
          valueStyles={{height: '65%'}}
          colQuery={6}
        />
      </Row>
    </div>
  );
};

const LeagueDetails = ({
  league,
  history,
}) => {
  const {
    id,
    image,
    name,
    abbreviation,
  } = league;

  return (
    <div style={{ width: '98%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', ...gameDetailsContentStyle }}>
      <Row style={{ width: '100%', height: '10%', margin: 0, }}>
        <Col xl={12} xs={12} md={12} className="p-0">
          <div style={leagueTitleStyle}>
            League
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%', height: '50%', margin: 0, }}>
        <Col xl={12} xs={12} md={12} className="p-0">
          <div style={leagueLogoImageStyle}>
            <img
              src={image}
              style={imageStyle}
              width="45px"
              height="45px"
              alt=""
            />
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%', height: '30%', margin: 0, }}>
        <Col xl={12} xs={12} md={12} className="p-0" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
          <div style={leagueValueStyle}>
            {`${abbreviation} ${name}`}
          </div>
        </Col>
      </Row>
      <OptionsOverflowMenu
          menuItems={[
            { label: 'View League', onClick: (e) => {
              history.push(`/admin/leagues/${id}`);
                e.stopPropagation();
              } 
            }, 
          ]}
        />
    </div>
  );
};

const VenueDetails = ({
  venue,
}) => {
  const {
    image,
    name,
    city,
    state,
    zipcode,
  } = venue;

  return (
    <div style={{ width: '100%', ...gameDetailsContentStyle }}>
      <Row style={{ width: '100%', height: '100%', margin: 0, }}>
        <Col xl={4} xs={4} md={4} className="p-0" style={{ ...detailImageContainerStyle, width: '40%', }}>
          <div style={{ width: '85%', height: '75%', ...detailImageContainerStyle, }}>
            <img
              src={image}
              style={{ ...imageStyle, objectFit: 'cover', borderRadius: 5, overflow: 'hidden',  }}
              width="100%"
              height="100%"
              alt=""
            />
          </div>
        </Col>
        <Col xl={8} xs={8} md={8} className="p-0" style={{ width: '60%' }}>
          <Row style={{ width: '100%', height: '50%', margin: 0, }}>
            <GameDetailItem
              title={'Venue'}
              value={name}
              titleStyles={{justifyContent: 'flex-start'}}
              valueStyles={{justifyContent: 'flex-start', fontSize: 16, ...textEllipsisStyle, height: 'auto', maxWidth: '85%',}}
            />
          </Row>
          <Row style={{ width: '100%', height: '50%', margin: 0, }}>
            <GameDetailItem
              title={'Location'}
              value={`${city}, ${state}, ${zipcode}`}
              titleStyles={{height: '28%', justifyContent: 'flex-start'}}
              valueStyles={{justifyContent: 'flex-start'}}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const GameUsersDetails = ({
  gameId, 
  homeTeamId,
  awayTeamId,
  history, 
  editDisabled,
  setModalItemType,
  setAssignedUserIds,
  isUserRolesQueried,
  setIsUserRolesQueried,
}) => {
  const { userItems } = useUserRoles(isUserRolesQueried);
  const [coaches, setCoaches] = useState([]);
  const [clockManagers, setClockManagers] = useState([]);
  const [scoreKeepers, setScoreKeepers] = useState([]);
  const [rolesContainerHeight, setRolesContainerHeight] = useState(0);

  useEffect(() => {
    if(userItems?.length > 0) {
      const gameUserRoles = getAssignedUserGameRole(userItems, gameId);
      const coaches = getAssignedTeamCoaches(userItems, gameId, homeTeamId, awayTeamId);
      const clockManagers = gameUserRoles.filter(gameUser => gameUser.gameRole.gameRole === CLOCKMANAGER);
      const scoreKeepers = gameUserRoles.filter(gameUser => gameUser.gameRole.gameRole === SCOREKEEPER);
      setAssignedUserIds([
        ...coaches.map(({ userId, teamId, gameId, gameRole }) => ({ userId, teamId, gameId, gameRole }))
      ]);
      setCoaches(coaches);
      setClockManagers(clockManagers);
      setScoreKeepers(scoreKeepers);
      setIsUserRolesQueried(false);
    }
  }, [userItems]);

  const onLoadHandler = (event) => {
    new ResizeObserver(resizeEntry => {
      const height = resizeEntry[0].target.clientHeight;
      setRolesContainerHeight(height - 25);
    }).observe(event.currentTarget);
  };

  return (
    <div onLoad={onLoadHandler} style={{ width: '100%', display: 'flex', flexDirection: 'column',  ...gameDetailsContentStyle }}>
      <GameUserRoleTitles 
        editDisabled={editDisabled}
        setModalItemType={setModalItemType}
      />
      <GameUserRolesContainer
        coachesRoles={coaches}
        clockManagerRoles={clockManagers}
        scoreKeeperRoles={scoreKeepers}
        history={history}
        containerHeight={rolesContainerHeight}
      />
    </div>
  );
};

const GameUserRoleTitles = ({
  editDisabled,
  setModalItemType,
}) => {
  const roleTitles = [
    'Coaches', 
    'Clock Managers', 
    'Score Keepers',
  ];
  const roleItemFunctions = {
    'Coaches': () => setModalItemType(ITEM_TYPE.COACH), 
    'Clock Managers': () => {}, 
    'Score Keepers': () => {}, 
  };
  return (
    <div style={gameUserDetailsTitleContainerStyle}>
      {roleTitles.map(roleTitle => (
        <div key={`${roleTitle}_title_item`} style={gameUserDetailsTitleStyle}>
          <span>{roleTitle}</span>
          <div style={{ position: 'absolute', right: 6, bottom: 8, width: 'auto', height: 'auto', }}>
            <EditItemCtrl
              editIconSize={12}
              onClickHandler={roleItemFunctions[roleTitle]}
              isDisabled={editDisabled}
            />
          </div>
        </div>
       )
      )}
    </div>
  );
};

const GameUserRolesContainer = ({
  coachesRoles = [],
  clockManagerRoles = [], 
  scoreKeeperRoles = [], 
  history, 
  containerHeight, 
}) => {
  const [ verticalDividerHeight, setVerticalDividerHeight ] = useState();
  const coachesDivRef = useRef();
  const clockManagersDivRef = useRef();
  const scoreKeepersDivRef = useRef();

  const listRef = useRef();
  let _isMobile;

  useEffect(() => {
    const scrollContainerHeight = listRef.current?.clientHeight;
    const coachesDiv = coachesDivRef.current;
    const clockManagersDiv = clockManagersDivRef.current;
    const scoreKeepersDiv = scoreKeepersDivRef.current;
    let verticalDividerHeight = ((coachesDiv?.clientHeight >= clockManagersDiv?.clientHeight) && (coachesDiv?.clientHeight >= scoreKeepersDiv?.clientHeight))
                                  ? coachesDiv?.clientHeight 
                                    : clockManagersDiv?.clientHeight >= scoreKeepersDiv?.clientHeight
                                      ? clockManagersDiv?.clientHeight
                                        : scoreKeepersDiv?.clientHeight;
    setVerticalDividerHeight(scrollContainerHeight > verticalDividerHeight ? scrollContainerHeight : verticalDividerHeight);

    if(!isMobile()) {
      addScrollListClass(listRef.current);
    }
  }, [containerHeight, coachesRoles, clockManagerRoles, scoreKeeperRoles]);

  return (
    <div style={{ width: '100%', maxHeight: containerHeight, height: '100%', }}>
      <div ref={listRef} style={gameUsersRolesItemsStyle} onScroll={() => onListScrollHandler(_isMobile, listRef.current)}>
        <GameUserRoles
          key={"COACH_USER_ROLES"}
          userRoleRef={coachesDivRef}
          gameUserRoles={coachesRoles}
          roleColor={ROLE_COLOR[COACH]}
          emptyRoleMsg="No Coaches"
          history={history}
        />
        <GameUserRolesVerticalDivider
          verticalHeight={verticalDividerHeight}
        />
        <GameUserRoles
          key={"CLOCK_MANAGER_USER_ROLES"}
          userRoleRef={clockManagersDivRef}
          gameUserRoles={clockManagerRoles}
          roleColor={ROLE_COLOR[CLOCKMANAGER]}
          emptyRoleMsg="No Clock Managers"
          history={history}
        />
        <GameUserRolesVerticalDivider
          verticalHeight={verticalDividerHeight}
        />
        <GameUserRoles
          key={"SCORE_KEEPER_USER_ROLES"}
          userRoleRef={scoreKeepersDivRef}
          gameUserRoles={scoreKeeperRoles}
          roleColor={ROLE_COLOR[SCOREKEEPER]}
          emptyRoleMsg="No Score Keepers"
          history={history}
        />
      </div>
    </div>
  );
};

const GameUserRoles = ({
  userRoleRef = null,
  gameUserRoles, 
  roleColor,
  emptyRoleMsg, 
  history, 
}) => {
  return (
    <div style={{ width: '48%', height: gameUserRoles.length === 0 ? '100%' : 'auto' }}>
      {gameUserRoles.length > 0
        ?
        <div ref={userRoleRef} style={gameUserRoleContainerStyle}>
          {gameUserRoles.map(({ name, userId, gameId, gameRole, isHomeTeam, isAwayTeam, }) => (
            <UserGameRole 
              key={`${gameId}_${userId}`} 
              roleColor={roleColor}
              width='82%'
              onClick={event => {
                history.push(`/admin/user/profile/${userId}`);
                event.stopPropagation();
              }}
            >
              <div style={userRoleNameStyle}>
                <div style={userRoleNameTextStyle}>
                  {name}
                </div>
                {(gameRole === COACH) && 
                  <>
                    {isHomeTeam && 
                      <div>&nbsp;{`(HT)`}</div>
                    }
                    {isAwayTeam && 
                      <div>&nbsp;{`(AT)`}</div>
                    }
                  </>
                }
              </div>
            </UserGameRole>
          ))}
        </div>
        :
        <div ref={userRoleRef} style={gameUserRoleEmptyMessageContainerStyle}>
          {emptyRoleMsg}
        </div>
      }
    </div>
  );
};

const GameUserRolesVerticalDivider = ({
  verticalHeight = '100%'
}) => {
  return (
    <div style={{ ...gameRoleVerticalDividerContainerStyle, height: verticalHeight, }}>
      <div style={gameRoleVerticalDividerStyle}>
      </div>
    </div>
  );
};
 
const GameLineupInfo = ({
  gameDateTime,
  homeTeam,
  awayTeam,
  setAddEditGameModalOpen,
  setAddPlayersModalOpen,
  setIsHomeTabSelected,
  homeCourtPlayers = [],
  homeBenchPlayers = [],
  awayCourtPlayers = [],
  awayBenchPlayers = [],
  editDisabled,
}) => {
  const dateFormatString = getFormattedDateInUppercaseWithDaySuffixedSuperscript(gameDateTime, 'Do MMMM YYYY, dddd');

  return (
    <Row style={gameLineupInfoContainerStyles}>
      <Col
        xs={12}
        xl={12}
        md={12}
        className="p-0"
        style={gameLineupInfoLayoutStyles}
      >
        <div style={gameDateInfoContentDisplayStyle}>
          {dateFormatString}
          {/* {!editDisabled && ( */}
            <img
              style={{
                position: 'absolute',
                top: 9,
                right: 12,
                cursor: 'pointer',
              }}
              src={EditIcon}
              width="18px"
              height="18px"
              onClick={() => setAddEditGameModalOpen(true)}
              alt="Edit Game"
            />
          {/* )} */}
        </div>
        <div style={gamePlayersContentDisplayStyle}>
          <Row style={gameHomeTeamAndAwayTeamContainerLayoutStyle}>
            <GameTeamLineupDetailItem
              team={homeTeam}
              teamType={'Home'}
              gameDateTime={gameDateTime}
              gameTeamContainerStyle={gameHomeTeamContainerStyle}
              setAddPlayersModalOpen={setAddPlayersModalOpen}
              setIsHomeTabSelected={setIsHomeTabSelected}
              courtPlayers={homeCourtPlayers}
              benchPlayers={homeBenchPlayers}
              editDisabled={editDisabled}
            />
            <GameTeamLineupDetailItem
              team={awayTeam}
              teamType={'Away'}
              gameDateTime={gameDateTime}
              gameTeamContainerStyle={gameAwayTeamContainerStyle}
              setAddPlayersModalOpen={setAddPlayersModalOpen}
              setIsHomeTabSelected={setIsHomeTabSelected}
              courtPlayers={awayCourtPlayers}
              benchPlayers={awayBenchPlayers}
              editDisabled={editDisabled}
            />
          </Row>
          <div style={versusContainerTextStyle}>VS</div>
        </div>
      </Col>
    </Row>
  );
};

const GameDetailsInfo = ({
  gameId,
  homeTeamId,
  awayTeamId,
  season,
  league,
  venue,
  history,
  editDisabled,
  setModalItemType,
  setAssignedUserIds,
  isUserRolesQueried,
  setIsUserRolesQueried,
}) => {
  return (
    <Row style={gameDetailsInfoContainerStyles}>
      <Col xs={2} xl={2} md={2} className="p-0" style={gameDetailsRowFlexStartContainerStyle}>
        <SeasonDetails
          season={season}
        />
      </Col>
      <Col xs={2} xl={2} md={2} className="p-0" style={gameDetailsRowFlexStartContainerStyle}>
        <div style={{ width: '98%', ...gameDetailsContentStyle }}>
          <LeagueDetails
            league={league}
            history={history}
          />
        </div>
      </Col>
      <Col xs={2} xl={2} md={2} className="p-0" style={{ ...gameDetailsRowFlexEndContainerStyle, width: '22%', }}>
        <div style={{ width: '100%', ...gameDetailsContentStyle }}>
          <VenueDetails
            venue={venue}
          />
        </div>
      </Col>
      <Col xs={6} xl={6} md={6} className="p-0" style={{ ...gameDetailsRowFlexEndContainerStyle, width: '44.5%', }}>
        <div style={{ width: '99%', ...gameDetailsContentStyle }}>
          <GameUsersDetails
            gameId={gameId}
            homeTeamId={homeTeamId}
            awayTeamId={awayTeamId}
            history={history}
            editDisabled={editDisabled}
            setModalItemType={setModalItemType}
            setAssignedUserIds={setAssignedUserIds}
            isUserRolesQueried={isUserRolesQueried}
            setIsUserRolesQueried={setIsUserRolesQueried}
          />
        </div>
      </Col>
    </Row>
  );
};

const GameDetailsContent = ({
  game,
  setAddEditGameModalOpen,
  setAddPlayersModalOpen,
  setItemType, 
  setAssignedUserIds, 
  setIsHomeTabSelected,
  homeCourtPlayers,
  homeBenchPlayers,
  awayCourtPlayers,
  awayBenchPlayers,
  isUserRolesQueried,
  setIsUserRolesQueried,
}) => {
  const history = useHistory();

  useEffect(() => {
    setIsUserRolesQueried(true);
  }, []);

  const {
    id,
    awayTeamId,
    homeTeamId,
    awayTeamName,
    homeTeamName,
    awayTeamImage,
    homeTeamImage,
    gameDateTime,
    league,
    season,
    venue,
    gameStatus,
  } = game;

  const gameDateTimeMomentInstance = moment(gameDateTime);
  const gameScheduleStatus = gameDateTimeMomentInstance.isBefore(moment()) || gameStatus === GAME_STATUS.LIVE;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: `${colors.GRAY[250]}`,
      }}
    >
      <GameLineupInfo
        gameDateTime={gameDateTimeMomentInstance.format('YYYY-MM-DDTH:mm')}
        homeTeam={{ name: homeTeamName, image: homeTeamImage }}
        awayTeam={{ name: awayTeamName, image: awayTeamImage }}
        setAddEditGameModalOpen={setAddEditGameModalOpen}
        setAddPlayersModalOpen={setAddPlayersModalOpen}
        setIsHomeTabSelected={setIsHomeTabSelected}
        homeCourtPlayers={homeCourtPlayers}
        homeBenchPlayers={homeBenchPlayers}
        awayCourtPlayers={awayCourtPlayers}
        awayBenchPlayers={awayBenchPlayers}
        editDisabled={gameScheduleStatus}
      />
      <GameDetailsInfo
        gameId={id}
        homeTeamId={homeTeamId}
        awayTeamId={awayTeamId}
        season={season}
        league={league}
        venue={venue}
        history={history}
        editDisabled={gameScheduleStatus}
        setModalItemType={itemType => setItemType(itemType)}
        setAssignedUserIds={setAssignedUserIds}
        isUserRolesQueried={isUserRolesQueried}
        setIsUserRolesQueried={setIsUserRolesQueried}
      />
    </div>
  );
};

export default GameDetailsContent;