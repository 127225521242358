import React, { useState, useEffect, useRef, } from 'react';
import ModalComponent from './ModalComponent';
import ButtonElement from '../Buttons/ButtonElement';
import ScrollListItemsContainer from '../ScrollListItemsContainer';
import FloatingTextNotification from '../FloatingTextNotification';
import { Row, Col, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Country, State, City } from 'country-state-city';
import { isMobile, onListScrollHandler } from '../../utils/scrollUtil';
import { colors, textEllipsisStyle, buttonThemes, modalContainerStyle, modalBodyContainerStyle } from '../../styles';
import PendingUpdate from '../PendingUpdate';
import Warning from '../../assets/Warning@2x.png';
import Delete from '../../assets/delete@2x.png';
import Edit from '../../assets/dark_edit@2x.png';

/******************************* BEGIN STYLES *******************************/
const csvOpenStatementTextStyle = { 
  width: 700, 
  textAlign: 'left', 
  font: 'normal normal normal 16px/24px Inter', 
  color: '#616161', 
};

const numberDataTypeUploadTextStyle = { 
  width: '100%', 
  textAlign: 'left', 
  font: 'normal normal normal 16px/24px Inter, Medium', 
  color: '#616161', 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-end', 
};

const dataItemsContainerStyle = { 
  width: '100%', 
  height: 280, 
  display: 'flex', 
  flexDirection: 'column', 
  marginBottom: 5, 
};

const csvDataItemContainerStyle = { 
  width: '99%', 
  height: 'auto', 
  marginBottom: 10, 
  border: '1px solid #D8D8D8', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
};

const csvTeamsDataItemRowStyle = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
};

const csvTeamsDataItemLabelStyle = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-end', 
  font: 'normal normal bold 16px/36px Open Sans', 
  color: '#616161', 
  textAlign: 'left', 
  height: 30, 
  maxWidth: 150, 
};

const csvTeamsDataItemLabelTextStyle = { 
  width: '70%', 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  textAlign: 'left', 
};

const csvTeamsDataItemLabelColonStyle = { 
  width: '5%', 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  textAlign: 'right', 
};

const csvTeamsDataItemValueStyle = { 
  width: '100%', 
  height: 30, 
  font: 'normal normal normal 16px/30px Open Sans', 
  color: '#616161', 
  paddingLeft: 10, 
  textAlign: 'left', 
  ...textEllipsisStyle, 
};

const csvDataItemWarningIconStyle = { 
  width: '25%', 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const imageIconContainerStyle = { 
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  borderLeft: '1px solid #D8D8D8', 
};

const defaultCsvTemplateStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
};
/******************************* END STYLES *******************************/

const EditableInput = ({
  inputId, 
  inputType = 'text', 
  inputValue = null,
  inputWidth = '100%', 
  editableChangeHandler = () => {}, 
  selectableValues = [], 
  selectableOption, 
  selectableItem, 
  setSelectableItem = () => {}, 
  isSelectable = false, 
}) => {
  const getEditableInput = (
    inputId, 
    inputValue, 
    inputWidth, 
    inputType, 
    selectableOption, 
    selectableValues, 
    selectableItem, 
    setSelectableItem, 
    editableChangeHandler = () => {}, 
    isSelectable
  ) => {
    if(isSelectable) {
      return (
        <Typeahead
          id={inputId}
          labelKey={selectableOption}
          options={selectableValues}
          onChange={setSelectableItem}
          onInputChange={(text, event) => editableChangeHandler(event.target.value)}
          defaultInputValue={inputValue}
          selected={selectableItem}
          style={{ width: inputWidth, height: 30, }}
          allowNew={false}
        />
      );
    }
    else {
      return (
        <input 
          type={inputType} 
          id={inputId}
          aria-label='' 
          style={{ width: inputWidth, height: 30, }} 
          value={inputValue} 
          onChange={event => editableChangeHandler(event.currentTarget.value)}
        />
      );
    };
  };

  return (
    getEditableInput(
      inputId,
      inputValue, 
      inputWidth, 
      inputType, 
      selectableOption, 
      selectableValues, 
      selectableItem, 
      setSelectableItem, 
      editableChangeHandler,
      isSelectable
    )
  );
};

const CsvPlayerDataItem = ({
  playerIndex, 
  playerItem, 
  editPlayer, 
}) => {
  //TODO: PROVIDE IMPLEMENTATION WHEN ITS TIME FOR THIS TASK.
};

const CsvTeamDataItem = ({
  teamIndex, 
  teamItem, 
  editTeam, 
  teamStates = [], 
  teamCities = [], 
  isTeamNameRepeated = false, 
  dataIsInvalid = false,
  setIsDataInvalid = () => {},
  getCountryStates = () => {}, 
  getStateCities = () => {}, 
  saveCsvTeamRecord = () => {}, 
}) => {
  const {
    Name, 
    City,
    State, 
  } = teamItem;

  const teamNameRef = useRef(Name);
  const stateNameRef = useRef(State);
  const [selectedState, setSelectedState] = useState(State);
  const [selectedCity, setSelectedCity] = useState(City);
  const [isStateInvalid, setIsStateInvalid] = useState(dataIsInvalid);
  const [isCityInvalid, setIsCityInvalid] = useState(dataIsInvalid);

  useEffect(() => {
    if(teamStates.length === 0) {
      getCountryStates('US');
    }
  }, [teamStates]);

  useEffect(() => {
    if((editTeam === false) && (teamStates.length > 0)) {
      setSelectedState(State);
      setSelectedCity(City);
      teamNameRef.current = Name;
      stateNameRef.current = teamStates?.find(state => (state.name === State) || (state.isoCode === State))?.name;
    }
  }, [editTeam]);

  useEffect(() => {
    if(editTeam) {
      setSelectedCity('');
    }
  }, [selectedState]);

  useEffect(() => {
    if(teamCities.length > 0) {
      const stateCity = teamCities.find(({ name }) => name === selectedCity);
      setIsCityInvalid(stateCity === undefined);
    }
  }, [teamCities, selectedCity]);

  useEffect(() => {
    if(teamStates.length > 0) {
      const stateProvince = teamStates?.find(state => (state.name === selectedState) || (state.isoCode === selectedState));
      stateNameRef.current = stateProvince?.name || State;
      setIsStateInvalid(stateProvince === undefined);
      getStateCities('US', stateProvince?.isoCode);
    }
  }, [teamStates, selectedState]);

  useEffect(() => {
    setIsDataInvalid(isStateInvalid || isCityInvalid);
  }, [isStateInvalid, isCityInvalid]);

  const DataItem = ({
    dataItemId, 
    dataLabel, 
    dataValue,
    canEdit, 
    dataItemOption = null, 
    dataItemValues = [], 
    dataItemUpdate = () => {}, 
    isSelectable = false, 
    isDataInvalid = false, 
    setSelectValue = () => {}, 
  }) => {
    const [dataItemValue, setDataItemValue] = useState(dataValue);
    const [dataItemSelected, setDataItemSelected] = useState([]);

    useEffect(() => {
      if(dataItemSelected.length > 0) {
        const selectedItemValue = dataItemSelected[0]?.name;
        setSelectValue(selectedItemValue);
      }
    }, [dataItemSelected]);

    return (
      <Row style={{ width: '100%', height: 'auto', marginBottom: editTeam && 10, }}>
        <Col xl={3} md={3} xs={3} style={csvTeamsDataItemLabelStyle} className="p-0">
          <div style={csvDataItemWarningIconStyle}>
            {(!canEdit && (dataItemValue?.length === 0 || isDataInvalid)) && 
             <img width={20} height={20} src={Warning} alt=""/>}
          </div>
          <div style={csvTeamsDataItemLabelTextStyle}>
            {dataLabel}
          </div>
          <div style={csvTeamsDataItemLabelColonStyle}>:</div>
        </Col>
        <Col xl={9} md={9} xs={9} style={{ minWidth: 500, }} className="p-0">
          {canEdit
          ?
          <EditableInput
            inputId={dataItemId}
            inputValue={dataItemValue}
            editableChangeHandler={textValue => dataItemUpdate(textValue, setDataItemValue)}
            selectableValues={dataItemValues}
            selectableOption={dataItemOption}
            selectableItem={dataItemSelected}
            setSelectableItem={setDataItemSelected}
            isSelectable={isSelectable}
          />
          :
          <div style={csvTeamsDataItemValueStyle}>
            {dataItemValue}
          </div>
          }
        </Col>
      </Row>
    );
  };

  return (
    <div style={csvTeamsDataItemRowStyle}>
      <DataItem
        dataItemId={`team_name_${teamIndex}`}
        dataLabel={"Team Name"}
        dataValue={editTeam ? teamNameRef.current : Name}
        canEdit={editTeam}
        isDataInvalid={isTeamNameRepeated}
        dataItemUpdate={(value, callback) => {
          if(editTeam) {
            callback(value);
            saveCsvTeamRecord('Name', value);
            teamNameRef.current = value;
          }
        }}
      />
      <DataItem
        dataItemId={`state_${teamIndex}`}
        dataLabel={"State"}
        dataValue={editTeam ? selectedState : stateNameRef.current}
        canEdit={editTeam}
        isSelectable={true}
        isDataInvalid={isStateInvalid}
        dataItemValues={teamStates}
        dataItemOption={"name"}
        setSelectValue={selectedValue => {
          setSelectedState(selectedValue);
          saveCsvTeamRecord('State', selectedValue);
        }}
        dataItemUpdate={(value, callback) => {
          if(editTeam) {
            callback(value);
          }
        }}
      />
      <DataItem
        dataItemId={`city_${teamIndex}`}
        dataLabel={"City"}
        dataValue={editTeam ? selectedCity : City}
        canEdit={editTeam}
        isSelectable={true}
        isDataInvalid={isCityInvalid}
        dataItemValues={teamCities}
        dataItemOption={"name"}
        setSelectValue={selectedValue => {
          setSelectedCity(selectedValue);
          saveCsvTeamRecord('City', selectedValue);
        }}
        dataItemUpdate={(value, callback) => {
          if(editTeam) {
            callback(value);
          }
        }}
      />
    </div>
  );
};

const CsvDataItem = ({
  dataIndex,
  dataType, 
  dataItem,
  hasNewCsvDataItemRecord = false, 
  csvDataItemSaveHandler, 
  csvDataItemDeleteHandler,
  csvDataItemCanEditNewRecordHandler,
  csvDataItemDeleteNewRecordHandler, 
  csvDataItemPendingUpdateStatusHandler, 
}) => {
  const [dataItemRecord, setDataItemRecord] = useState(dataItem);
  const [canEdit, setCanEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isDataRecordInvalid, setIsDataRecordInvalid] = useState(false);
  const [isDataValueRepeated, setIsDataValueRepeated] = useState(false);
  const [isDataTypeValid, setIsDataTypeValid] = useState(false);
  const csvDataItemRecordRef = useRef({ ...dataItem });

  useEffect(() => {
    setIsDataTypeValid(
      dataType === 'teams' || 
      dataType === 'players'
    );
  }, [dataType]);

  useEffect(() => {
    setDataItemRecord(dataItem);
  }, [dataItem]);

  useEffect(() => {
    if(dataItemRecord?.isNewRecord) {
      setCanEdit(dataItemRecord.isNewRecord);
    }
  }, [dataItemRecord?.isNewRecord]);

  useEffect(() => {
    setIsDataValueRepeated(dataItemRecord?.hasRepeatedDataValue || dataItemRecord?.isAlreadyExistingDataValue);
  }, [dataItemRecord?.hasRepeatedDataValue, dataItemRecord?.isAlreadyExistingDataValue]);

  useEffect(() => {
    csvDataItemCanEditNewRecordHandler(dataIndex, canEdit);
  }, [canEdit]);

  useEffect(() => {
    dataItemRecord.hasInvalidDataValue = isDataRecordInvalid;
    csvDataItemPendingUpdateStatusHandler(dataIndex, dataItemRecord);
  }, [isDataRecordInvalid]);

  const getCountries = () => {
    const countries = Country.getAllCountries().map(({ isoCode, name }) => ({
                        isoCode,
                        name,
                        id: name,
                      }));
    setCountries(countries);
  };

  const getStatesOrProvinces = (countryCode) => {
    const states = State.getStatesOfCountry(countryCode).map(({ isoCode, name }) => ({
                      isoCode,
                      name,
                      id: name,
                    }));
    setStates(states);
  };

  const getCities = (countryCode, stateOrProvince) => {
    const cities =  City.getCitiesOfState(
                      countryCode,
                      stateOrProvince
                    ).map(({ name }) => ({ id: name, name }));
    setCities(cities);
  };

  const clearStates = () => setStates([]);

  const clearCities = () => setCities([]);

  const getCsvDataTypeItemComponent = (
    dataIndex, 
    dataType, 
    dataItemRecord, 
    canEdit, 
    isDataValueRepeated,
    isDataRecordInvalid
  ) => {
    switch(dataType) {
      case 'teams':
        return <CsvTeamDataItem 
                  teamIndex={dataIndex}
                  teamItem={dataItemRecord}
                  editTeam={canEdit}
                  teamStates={states}
                  teamCities={cities}
                  isTeamNameRepeated={isDataValueRepeated}
                  dataIsInvalid={isDataRecordInvalid}
                  setIsDataInvalid={setIsDataRecordInvalid}
                  getCountryStates={(countryCode) => getStatesOrProvinces(countryCode)}
                  getStateCities={(countryCode, stateCode) => getCities(countryCode, stateCode)}
                  saveCsvTeamRecord={(key, value) => csvDataItemRecordRef.current[key] = value}
                />
      case 'players':
        return <CsvPlayerDataItem 
                  playerIndex={dataIndex}
                  playerItem={dataItemRecord}
                  editPlayer={canEdit}
                />
      default:
        return <div style={defaultCsvTemplateStyle}>
                  {'Invalid CSV Record Template Type'}
               </div>
    };
  };

  const csvDeleteClickHandler = (event, dataIndex) => {
    csvDataItemDeleteHandler(dataIndex);
    event.stopPropagation();
  };

  const csvEditDataItemHandler = (event, canEdit, isNewRecord) => {
    if(isNewRecord) {
      csvDataItemDeleteNewRecordHandler();
    }
    else {
      setCanEdit(!canEdit);
      setDataItemRecord(dataItemRecord);
    }
    event.stopPropagation();
  };

  const csvSaveDataItemHandler = (event, canEdit, dataIndex, dataItemRecord = {}) => {
    setDataItemRecord(dataItemRecord);
    csvDataItemSaveHandler(dataIndex, dataItemRecord);
    setCanEdit(!canEdit);
    event.stopPropagation();
  };

  return (
    <div style={csvDataItemContainerStyle}>
      <Row style={{ width: '100%' }}>
        <Col xl={isDataTypeValid ? 10 : 12} md={isDataTypeValid ? 10 : 12} xs={isDataTypeValid ? 10 : 12} className="p-0 pt-2 pb-2">
          {getCsvDataTypeItemComponent(
            dataIndex, 
            dataType, 
            dataItemRecord, 
            canEdit, 
            isDataValueRepeated,
            isDataRecordInvalid
          )}
        </Col>

        {isDataTypeValid && 
        <Col xl={2} md={2} xs={2} className="p-0" style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ ...imageIconContainerStyle, justifyContent: canEdit ? 'flex-end' : 'center', paddingBottom: canEdit && 3, borderBottom: !canEdit && '1px solid #D8D8D8', backgroundColor: (!dataItemRecord.isNewRecord && hasNewCsvDataItemRecord) ? '#F5F5F5' : 'transparent', }}>
            {canEdit 
            ? 
              <ButtonElement
                onClick={event => csvSaveDataItemHandler(event, canEdit, dataIndex, csvDataItemRecordRef.current)}
                label="Save"
                minWidth="100px"
                height={"40px"}
                styleWithIcon={{ marginRight: 0 }}
              />
            :
              <img 
                width={18} 
                height={18} 
                style={{ opacity: hasNewCsvDataItemRecord ? 0.3 : 1 }}
                src={Edit} 
                alt="" 
                onClick={event => !hasNewCsvDataItemRecord && csvEditDataItemHandler(event, canEdit)}
              />
            }
          </div>
          <div style={{ ...imageIconContainerStyle, justifyContent: canEdit ? 'flex-start' : 'center', paddingTop: canEdit && 3, backgroundColor: (!dataItemRecord.isNewRecord && hasNewCsvDataItemRecord) ? '#F5F5F5' : 'transparent', }}>
            {canEdit 
            ? 
              <ButtonElement
                theme={buttonThemes.WHITE}
                onClick={event => csvEditDataItemHandler(event, canEdit, dataItemRecord.isNewRecord)}
                label={dataItemRecord.isNewRecord ? "Delete" : "Cancel"}
                height={"40px"}
                minWidth="100px"
                styleWithIcon={{ marginRight: 0 }}
              />
            :
              <img 
                width={18} 
                height={18} 
                style={{ opacity: hasNewCsvDataItemRecord ? 0.3 : 1 }}
                src={Delete} 
                alt="" 
                onClick={event => !hasNewCsvDataItemRecord && csvDeleteClickHandler(event, dataIndex)}
              />
            }
          </div>
        </Col>}
      </Row>
    </div>
  );
};

const DataTypeItemsContainer = ({
  dataType, 
  dataItems, 
  setCsvRowItems, 
  setIsEditingDataItems, 
  findRepeatedPropertyValues, 
  displayNotification, 
}) => {
  const [isNewRecordEntered, setIsNewRecordEntered] = useState(false);

  const onDeleteCsvDataItem = (csvRowItemIndex, dataItems) => {
    const deletedRowItem = dataItems[csvRowItemIndex];
    dataItems = dataItems.filter((_, index) => index !== csvRowItemIndex);
    const updatedDataItems = findRepeatedPropertyValues(dataItems);
    setCsvRowItems(updatedDataItems);
    displayNotification('was successfully deleted',  deletedRowItem["Name"]);
  };

  const onSaveCsvDataItem = (csvRowItemIndex, selectedCsvRowItem, dataItems) => {
    const { canEdit } = dataItems[csvRowItemIndex];
    selectedCsvRowItem.canEdit = canEdit;
    if(selectedCsvRowItem.isNewRecord) {
      displayNotification('was successfully added', selectedCsvRowItem["Name"]);
      delete selectedCsvRowItem.isNewRecord;
    }
    else {
      displayNotification('Edited successfully');
    }
    setIsNewRecordEntered(false);
    showCsvDataItemPendingUpdateStatus(csvRowItemIndex, selectedCsvRowItem, dataItems);
  };

  const canEditNewCsvDataItemRecord = (csvRowItemIndex, canEditValue, dataItems) => {
    const dataItem = dataItems[csvRowItemIndex];
    dataItem.canEdit = canEditValue;

    const areAnyDataItemsInEditMode = dataItems.some(dataItem => dataItem.canEdit === true);
    setIsEditingDataItems(areAnyDataItemsInEditMode);

    if(dataItem.isNewRecord) {
      setIsNewRecordEntered(true);
    }
  }; 

  const deleteNewCsvDataItemRecord = dataItems => {
    dataItems = dataItems.filter((_, index) => index > 0);
    setIsEditingDataItems(false);
    setIsNewRecordEntered(false);
    setCsvRowItems([...dataItems]);
  };

  const showCsvDataItemPendingUpdateStatus = (csvRowItemIndex, csvDataItemRecord, dataItems) => {
    dataItems[csvRowItemIndex] = csvDataItemRecord;
    const updatedDataItems = findRepeatedPropertyValues(dataItems);
    setCsvRowItems([...updatedDataItems]);
  };

  return (
    <>
      {dataItems.map((dataItem, index) => (
        <CsvDataItem
          key={`${dataItem.Name}_${index}`}
          dataIndex={index}
          dataType={dataType}
          dataItem={dataItem}
          hasNewCsvDataItemRecord={isNewRecordEntered}
          csvDataItemSaveHandler={(csvRowItemIndex, csvRowItem) => onSaveCsvDataItem(csvRowItemIndex, csvRowItem, dataItems)}
          csvDataItemDeleteHandler={(csvRowItemIndex) => onDeleteCsvDataItem(csvRowItemIndex, dataItems)}
          csvDataItemCanEditNewRecordHandler={(csvRowItemIndex, canEditValue) => canEditNewCsvDataItemRecord(csvRowItemIndex, canEditValue, dataItems)}
          csvDataItemDeleteNewRecordHandler={() => deleteNewCsvDataItemRecord(dataItems)}
          csvDataItemPendingUpdateStatusHandler={(csvRowItemIndex, csvDataItemRecord) => showCsvDataItemPendingUpdateStatus(csvRowItemIndex, csvDataItemRecord, dataItems)}
        />
      ))}
    </>
  );
};

const UnavailableCsvDataItems = ({
  dataType, 
}) => {
  return (
    <div style={{ ...defaultCsvTemplateStyle, font: 'normal normal normal 16px/24px Inter, Medium', color: '#616161', }}>
      {`There is no CSV upload ${dataType} data available.`}
    </div>
  );
};

const CsvAddDataModal = ({
  csvParsedData,
  dataType = '',
	modalTitle, 
  modalOpen,
  setModalOpen,
  csvFileHeaders = [], 
  onSubmit,
  onClose,
  repeatedDataValueProperty = '',
  error,
  setError,
}) => {
  const [csvRowsItems, setCsvRowItems] = useState([]);
  const [isAnyCsvDataRowItemEmpty, setIsAnyCsvDataRowItem] = useState(false);
  const [isAnyCsvDataRowItemInvalid, setIsAnyCsvDataRowItemInvalid] = useState(false);
  const [isAnyCsvDataRowItemRepeated, setIsAnyCsvDataRowItemRepeated] = useState(false);
  const [isAnyCsvDataRowItemAlreadyExisted, setIsAnyCsvDataRowItemAlreadyExisted] = useState(false);
  const [isEdittingDataItems, setIsEditingDataItems] = useState(false);
  const [pendingUpdateStatement, setPendingUpdateStatement] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationSubjectText, setNotificationSubjectText] = useState('');
  const [notificationDisplayText, setNotificationDisplayText] = useState('');
  const [isCsvTemplateDataTypeInvalid, setIsCsvTemplateDataTypeInvalid] = useState(false);

  useEffect(() => {
    setIsCsvTemplateDataTypeInvalid((dataType !== 'teams' && dataType !== 'players'));
  }, [dataType]);

  useEffect(() => {
    const csvDataItems = csvParsedData?.map(data => { 
                          data.canEdit = false;
                          data.hasInvalidDataValue = false;
                          data.hasRepeatedDataValue = false;
                          data.isAlreadyExistingDataValue = false;
                          return data;
                        });
    setCsvRowItems(findRepeatedPropertyValues(csvDataItems));
  }, [csvParsedData]);

  useEffect(() => {
    const isAnyCsvDataItemRecordEmpty = csvRowsItems.some(rowItem => (Object.values(rowItem).find(item => item.length === 0)?.length === 0));
    setIsAnyCsvDataRowItem(isAnyCsvDataItemRecordEmpty);
    const isAnyCsvDataItemRecordInvalid = csvRowsItems.some(rowItem => rowItem.hasInvalidDataValue === true);
    setIsAnyCsvDataRowItemInvalid(isAnyCsvDataItemRecordInvalid);
    const isAnyCsvDataItemRecordRepeated = csvRowsItems.some(rowItem => rowItem.hasRepeatedDataValue === true);
    setIsAnyCsvDataRowItemRepeated(isAnyCsvDataItemRecordRepeated);
    const isAnyCsvDataItemRecordAlreadyExisted = csvRowsItems.some(rowItem => rowItem.isAlreadyExistingDataValue === true);
    setIsAnyCsvDataRowItemAlreadyExisted(isAnyCsvDataItemRecordAlreadyExisted);
  }, [csvRowsItems]);

  useEffect(() => {
    setPendingStatement();
  }, [isAnyCsvDataRowItemEmpty, isAnyCsvDataRowItemInvalid, isAnyCsvDataRowItemRepeated, isAnyCsvDataRowItemAlreadyExisted]);

  useEffect(() => {
    if(isEdittingDataItems) {
      setError(null);
    }
  }, [isEdittingDataItems]);

  const listRef = useRef();

  const isMobileRef = useRef(isMobile());

  const findRepeatedPropertyValues = dataItems => {
    if(repeatedDataValueProperty.length > 0 && csvFileHeaders.some(header => header === repeatedDataValueProperty)) {
      dataItems = dataItems.map(dataItem => {
        dataItem.hasRepeatedDataValue = false;
        return dataItem;
      });

      const propertyValues = dataItems.map(dataItem => dataItem[repeatedDataValueProperty]);
      const uniquePropertyValues = new Set(propertyValues);

      let filteredRepeatedDataItems = [];
      const indexedMapDataItems = dataItems.map((dataItem, index) => ({ index, dataItem }));

      uniquePropertyValues.forEach(propertyValue => {
        const filteredIndexedDataItems = indexedMapDataItems.filter((indexedDataItem) => Object.values(indexedDataItem)[1][repeatedDataValueProperty].toLowerCase() === propertyValue.toLowerCase());
        if(filteredIndexedDataItems.length > 1) {
          filteredRepeatedDataItems = filteredRepeatedDataItems.concat(filteredIndexedDataItems);
        }
      });

      if(filteredRepeatedDataItems.length > 0) {
        filteredRepeatedDataItems = filteredRepeatedDataItems.forEach(indexedDataItem => {
          const index = Object.values(indexedDataItem)[0];
          const dataItem = Object.values(indexedDataItem)[1];
          dataItem.hasRepeatedDataValue = true;
          dataItems[index] = { ...dataItem };
        });

        return dataItems;
      }
    }

    return dataItems;
  };

  const addNewCsvRecordItem = (csvRowsItems, listRef) => {
    listRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    const newCsvRecordItem = { 
      canEdit: false, 
      hasInvalidDataValue: false, 
      hasRepeatedDataValue: false, 
      isAlreadyExistingDataValue: false, 
      isNewRecord: true 
    };
    csvFileHeaders.forEach(item => newCsvRecordItem[item] = '');
    csvRowsItems.unshift(newCsvRecordItem);
    setCsvRowItems([...csvRowsItems]);
  };

  const displayNotification = (displayText, subjectText = '') => {
    setNotificationSubjectText(subjectText);
    setNotificationDisplayText(displayText);

    if(!showNotification) {
      setShowNotification(true);
    }
    else {
      setTimeout(() => setShowNotification(true), 6000);
    }
  };

  const setPendingStatement = () => {
    let pendingStatement = '';

    if(isAnyCsvDataRowItemEmpty) {
      pendingStatement = 'Please fill in the missing information below';
    }
    else if(isAnyCsvDataRowItemInvalid) {
      pendingStatement = 'Correct the invalid information below';
    }
    else if(isAnyCsvDataRowItemRepeated) {
      pendingStatement = 'Correct the repeated information below';
    }
    else if(isAnyCsvDataRowItemAlreadyExisted) {
      pendingStatement = 'The indicated data already exists and needs to be changed';
    }

    setPendingUpdateStatement(pendingStatement);
  };

  const isCsvDataRowItemsInvalidated = () => {
    return (
      isAnyCsvDataRowItemEmpty || 
      isAnyCsvDataRowItemInvalid || 
      isAnyCsvDataRowItemRepeated || 
      isAnyCsvDataRowItemAlreadyExisted || 
      isCsvTemplateDataTypeInvalid || 
      csvRowsItems?.length === 0
    );
  };

	return (
		<ModalComponent
			headerTitle={modalTitle}
			buttonTitle={modalTitle}
			open={modalOpen}
			setOpen={setModalOpen}
			handleSubmit={(e) => {
        e.preventDefault();
        onSubmit(csvRowsItems, setCsvRowItems);
      }}
			handleClose={onClose}
			buttonDisabled={isCsvDataRowItemsInvalidated() || isEdittingDataItems}
			modalWidth={840}
      modalBackgroundColor={'#F2F2F2'}
      modalHeaderStyles={{ marginBottom: 0 }}
      modalBodyStyles={{ padding: 0, paddingTop: 10, backgroundColor: colors.WHITE[100], }}
      buttonContainerStyles={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, backgroundColor: '#F2F2F2' }}
		>
      <div style={modalContainerStyle}>
        <FloatingTextNotification
          subjectTitle={notificationSubjectText}
          textDisplay={notificationDisplayText}
          isNotified={showNotification}
          setIsNotified={setShowNotification}
        />
        {((isAnyCsvDataRowItemEmpty || isAnyCsvDataRowItemInvalid || isAnyCsvDataRowItemRepeated || isAnyCsvDataRowItemAlreadyExisted) && !isEdittingDataItems) &&
          <PendingUpdate 
            topPosition={-26}
            pendingUpdateStatement={pendingUpdateStatement}
          />
        }
        <Row style={modalBodyContainerStyle}>
          <Col xl={12} md={12} xs={12} className="p-0 mb-2" style={{ marginTop: ((isAnyCsvDataRowItemEmpty || isAnyCsvDataRowItemInvalid || isAnyCsvDataRowItemRepeated || isAnyCsvDataRowItemAlreadyExisted) && !isEdittingDataItems) ? 40 : 0, }}>
            {error
              ?
              <Alert variant={'danger'}>{error}</Alert>
              :
              <div style={csvOpenStatementTextStyle}>
                Review team information below. If you need to make any changes then press cancel, update the csv file, and upload your updated file.
              </div>
            }
          </Col>

          <Col xl={12} md={12} xs={12} className="p-0 mb-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 40, }}>
            <div style={numberDataTypeUploadTextStyle}>
              {`${csvRowsItems?.length} ${dataType.slice(0, 1).toUpperCase()}${dataType.slice(1, dataType.length).toLowerCase()} Upload(s)`}
            </div>
            {!isEdittingDataItems && 
            <ButtonElement
              onClick={() => addNewCsvRecordItem(csvRowsItems, listRef)}
              label={'+'}
              minWidth="50px"
              height={"40px"}
              styleWithIcon={{ marginRight: 0, fontSize: '36px', lineHeight: 0 }}
              disabled={(isAnyCsvDataRowItemEmpty || isAnyCsvDataRowItemInvalid || isAnyCsvDataRowItemRepeated || isAnyCsvDataRowItemAlreadyExisted || isCsvTemplateDataTypeInvalid)}
            />
            }
          </Col>

          <Col xl={12} md={12} xs={12} className="p-0" style={{ height: 'auto' }}>
            <div style={dataItemsContainerStyle}>
              <ScrollListItemsContainer
                data={csvRowsItems}
                scrollRef={listRef}
                onScroll={() => onListScrollHandler(isMobileRef.current, listRef.current)}
                listStyle={{ flexDirection: 'column', alignItems: 'flex-start', }}
              >
                {csvRowsItems?.length > 0
                  ?
                    <DataTypeItemsContainer
                      dataType={dataType}
                      dataItems={csvRowsItems}
                      setCsvRowItems={setCsvRowItems}
                      setIsEditingDataItems={setIsEditingDataItems}
                      findRepeatedPropertyValues={findRepeatedPropertyValues}
                      displayNotification={displayNotification}
                    />
                  :
                    <UnavailableCsvDataItems
                      dataType={dataType}
                    />
                }
              </ScrollListItemsContainer>
            </div>
          </Col>
        </Row>
      </div>
		</ModalComponent>
	);
};

export default CsvAddDataModal;