import React, { useState, useEffect, useRef, } from 'react';
import { Formik } from 'formik';
import { Row, Col, Alert } from 'react-bootstrap';
import ModalComponent from './ModalComponent';
import CustomCheckboxSelectItem from '../Checkbox/CustomCheckboxSelectItem';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import ButtonElement from '../Buttons/ButtonElement';
import { colors, textEllipsisStyle } from '../../styles';
import { buttonThemes } from '../../styles/themes';
import PlayerJersey from '../../assets/regular-jersey@2x.png';
import RemoveItem from '../../assets/remove-item@2x.png';
import ClearItems from '../../assets/broom@2x.png';
import ScrollListItemsContainer from '../ScrollListItemsContainer';
import { isMobile, onListScrollHandler } from '../../utils/scrollUtil';

/********************* BEGIN STYLES *********************/
const modalInstructionStyles = {
  width: 582,
  height: 'auto',
  font: 'normal normal normal 14px/19px "Segoe UI"',
  color: '#4D4D4D',
  paddingBottom: 20,
};

const playerListContainerStyle = {
  width: '100%',
};

const teamPlayersListContainerStyle = {
  width: '100%',
};

const containerTitleStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal bold 16px/19px Inter',
  color: '#000000',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const containerItemsStyle = {
  width: '100%',
  height: 400,
};

const playersListItemsStyle = {
  ...containerItemsStyle,
  border: '1px solid #989898',
  borderRadius: 3,
  padding: 6,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: '100%',
};

const jerseyNumberStyle = {
  position: 'absolute',
  top: 19,
  width: '100%',
  textAlign: 'center',
  font: 'normal normal bold 12px/13px Open Sans',
  color: '#111111',
};

const playerListItemTextStyle = { 
  font: 'normal normal bold 13px/38px Open Sans', 
  color: '#1C1C1C',
  width: 175, 
  marginLeft: 10,
  ...textEllipsisStyle,
};

const teamNameAndLeagueNameStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: 200,
  font: 'normal normal normal 13px/38px Open Sans', 
  color: '#1C1C1C', 
};

const teamPlayerListItemTextStyle = {
  font: 'normal normal 600 14px/44px Open Sans',
  color: '#111111',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
};

const teamPlayersListItemsStyle = {
  ...containerItemsStyle,
  border: '1px solid #C8C8C8',
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  overflowY: 'clip',
};

const playerListBaseContainerStyle = { 
  width: '100%', 
  height: 70, 
  borderTop: `1px solid ${colors.GRAY[200]}`, 
  display: 'flex', 
  alignItems: 'flex-end', 
  paddingBottom: 5, 
};

const playerListBaseButtonItemsContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'space-between', 
  alignItems: 'flex-end', 
};

const noneClearCheckboxesStyles = {
  ...buttonThemes.WHITE, 
  border: `2px solid ${colors.BLACK[300]}`, 
};

const clearCheckboxesStyles = {
  ...buttonThemes.BLUE, 
  backgroundColor: colors.WHITE[100], 
  textColor: colors.BLUE[100], 
  border: `2px solid ${colors.BLUE[100]}`, 
};
/********************* END STYLES *********************/

const ContainerTitle = ({ 
  title 
}) => {
  return (
    <Row className="mb-2">
      <Col xl={12} xs={12} md={12}>
        <div style={containerTitleStyle}>
          {title}
        </div>
      </Col>
    </Row>
  );
};

const TeamPlayerListItem = ({ 
  playerItem, 
  itemBackgroundColor, 
  removeTeamPlayerItemHandler, 
}) => {
  const {
    id,
    firstName,
    lastName,
    jerseyNumber,
  } = playerItem;

  return (
    <Row style={{ width: '100%', height: 40, backgroundColor: itemBackgroundColor }}>
      <Col xl={2} xs={2} md={2} className="p-0" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src={PlayerJersey}
          style={imageStyle}
          width="30px"
          height="30px"
          alt=""
        />
        <div style={jerseyNumberStyle}>
          {jerseyNumber}
        </div>
      </Col>
      <Col xl={8} xs={8} md={8} className="p-0" style={teamPlayerListItemTextStyle}>
        {`${firstName} ${lastName}`}
      </Col>
      <Col xl={2} xs={2} md={2} className="p-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src={RemoveItem}
          style={imageStyle}
          width="25px"
          height="25px"
          alt=""
          onClick={() => removeTeamPlayerItemHandler(playerItem)}
        />
      </Col>
    </Row>
  );
};

const ListItemsContainer = ({ 
  children, 
  containerStyles 
}) => {
  return (
    <Row>
      <Col xl={12} xs={12} md={12}>
        <div style={containerStyles}>
          {children}
        </div>
      </Col>
    </Row>
  );
};

const PlayersListItemsContainer = ({ 
  league,
  teamPlayers,
  playersList, 
  setPlayersSize, 
  containerStyles, 
  addPlayersHandler,
  setWarning,
  formik, 
}) => {
  const { playerSearch } = formik.values;

  const [playerItems, setPlayerItems] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [unCheckAllCheckboxes, setUnCheckAllCheckboxes] = useState(false);
  const [isAddPlayersDisabled, setIsAddPlayersDisabled] = useState(false);

  useEffect(() => {
    if(playerSearch.trim().length > 0) {
      const filteredPlayers = playersList.filter(({ firstName, lastName, jerseyNumber, team, league, }) => {
        const { name } = team;
        const { abbreviation } = league;
        const playerItemLabel = `${jerseyNumber} - ${firstName} ${lastName} (${name} - ${abbreviation})`;
        return playerItemLabel.toLowerCase().includes(playerSearch.trim());
      });

      setPlayerItems(filteredPlayers);
      setPlayersSize(filteredPlayers.length);
    }
    else {
      setPlayerItems(playersList);
      setPlayersSize(playersList.length);
    }
  }, [playerSearch]);

  useEffect(() => {
    setPlayerItems(playersList);
    setPlayersSize(playersList.length);
    setSelectedPlayers([]);
  }, [playersList]);

  useEffect(() => {
    setDisabledButtonStatus(selectedPlayers, setWarning);
  }, [selectedPlayers]);

  const listRef = useRef();
  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  }, []);

  const setDisabledButtonStatus = (selectedPlayers, setWarning) => {
    const selectedPlayersStatus = (teamPlayers.length > league.teamSize) || ((selectedPlayers.length + teamPlayers.length) > league.teamSize);
    setWarning(selectedPlayersStatus);
    return setIsAddPlayersDisabled((selectedPlayers.length === 0) || selectedPlayersStatus);
  };

  return (
    <ListItemsContainer 
      containerStyles={containerStyles}
    >
      <Row>
        <Col xl={12} xs={12} md={12}>
          <InputFieldComponent
            name="playerSearch"
            label="Search For Player"
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col xl={12} xs={12} md={12}>
          <div style={{ width: '100%', height: 255, display:'flex', flexDirection: 'column', paddingBottom: 20, }}>
            <ScrollListItemsContainer data={playerItems} ref={listRef} listStyle={{ flexDirection: 'column', alignItems: 'flex-start' }} onScroll={() => onListScrollHandler(_isMobile, listRef.current)}>
              {playerItems.map(playerItem => {
                  const {
                    id,
                    firstName,
                    lastName,
                    jerseyNumber,
                    team, 
                    league, 
                  } = playerItem;

                  const getCheckboxLabel = checked => {
                    return (
                      <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', }}>
                        <div style={playerListItemTextStyle}>
                          {`${jerseyNumber} - ${firstName} ${lastName}`}
                        </div>
                        <div style={teamNameAndLeagueNameStyle}>
                          {`(`}
                          <div style={{ width: 'auto', maxWidth: 150, ...textEllipsisStyle, }}>{team.name}</div>
                          &nbsp;-&nbsp;
                          <div style={{ width: 'auto' }}>{league.abbreviation}</div>
                          {`)`}
                        </div>
                      </div>
                    );
                  };

                  return (
                    <CustomCheckboxSelectItem
                      key={id}
                      id={id}
                      checkboxItemLabel={getCheckboxLabel}
                      checkboxItem={playerItem}
                      selectedCheckboxItems={selectedPlayers}
                      setSelectedCheckboxItems={setSelectedPlayers}
                      resetCheckboxState={unCheckAllCheckboxes}
                    />
                  );
                }
              )}
            </ScrollListItemsContainer>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12} xs={12} md={12}>
          <div style={playerListBaseContainerStyle}>
            <div style={playerListBaseButtonItemsContainerStyle}>
                <ButtonElement
                  size="large"
                  label="Add Player(s)"
                  onClick={() => addPlayersHandler(selectedPlayers, formik)}
                  dimmed={isAddPlayersDisabled}
                  disabled={isAddPlayersDisabled}
                />
                <ButtonElement
                  label="Clear All"
                  theme={selectedPlayers.length === 0 ? noneClearCheckboxesStyles : clearCheckboxesStyles}
                  minWidth={'130px'}
                  icon={ClearItems}
                  styleWithIcon={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: 0, }}
                  outlined={true}
                  onClick={() => setUnCheckAllCheckboxes(!unCheckAllCheckboxes)}
                />
            </div>
          </div>
        </Col>
      </Row>
    </ListItemsContainer>
  );
};

const TeamPlayersListItemsContainer = ({ 
  teamPlayers,
  removeTeamPlayer,
  containerStyles 
}) => {
  const listRef = useRef();
  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  }, []);

  return (
    <ListItemsContainer
      containerStyles={containerStyles}
    >
      <div style={{ width: '100%', height: '100%', display:'flex', flexDirection: 'column', }}>
        <ScrollListItemsContainer data={teamPlayers} ref={listRef} listStyle={{ flexDirection: 'column', alignItems: 'center' }} onScroll={() => onListScrollHandler(_isMobile, listRef.current)}>
          {teamPlayers.map((playerItem, index) => (
              <TeamPlayerListItem 
                key={playerItem.id} 
                playerItem={playerItem}
                removeTeamPlayerItemHandler={removeTeamPlayer}
                itemBackgroundColor={index % 2 === 0 ? '#F1F1F1' : '#F9F9F9'}
              />
            )
          )}
        </ScrollListItemsContainer>
      </div>
    </ListItemsContainer>
  );
};

const AddRemoveTeamPlayersModal = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  players,
  team,
  error,
}) => {
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [playersList, setPlayersList] = useState([]);
  const [playersSize, setPlayersSize] = useState(0);
  const [isDoneDisabled, setIsDoneDisabled] = useState(true);
  const [warning, setWarning] = useState(false);

  const teamPlayersAddedRef = useRef([]);
  const teamPlayersRemovedRef = useRef([]);

  useEffect(() => {
    setTeamPlayers(team?.players.items);
  }, [team]);

  useEffect(() => {
    setPlayersList(players);
  }, [players]);

  useEffect(() => {
    setPlayersSize(playersList.length);
  }, [playersList]);

  const addToTeamPlayers = selectedPlayers => {
    // Adding the selected players from the playersList to the teamPlayers.
    teamPlayersAddedRef.current = [ ...teamPlayersAddedRef.current, ...selectedPlayers ];

    // Removing the same selected player(s) from playersList that was added from teamPlayers.
    teamPlayersRemovedRef.current = teamPlayersRemovedRef.current.filter(({ id }) => !selectedPlayers.map(({ id }) => id).includes(id));

    // If the selectedPlayers was originally part of prop's team players, it should not be included among the new teamPlayers to be updated.
    teamPlayersAddedRef.current = teamPlayersAddedRef.current.filter(({ id }) => !team?.players.items.map(({ id }) => id).includes(id));

    // Update the list of team players displayed.
    const updatedTeamPlayers = [ ...teamPlayers, ...selectedPlayers ].sort((a, b) => parseInt(a.jerseyNumber) - parseInt(b.jerseyNumber));
    setTeamPlayers(updatedTeamPlayers);

    setIsDoneDisabled(!(teamPlayersAddedRef.current.length > 0 || teamPlayersRemovedRef.current.length > 0));
  };

  const removeFromTeamPlayers = teamPlayer => {
    setTeamPlayers(teamPlayers.filter(({ id }) => id != teamPlayer.id));
  };

  const addToPlayersList = teamPlayer => {
    // Adding a player from the teamPlayers to the playersList.
    teamPlayersRemovedRef.current.push(teamPlayer);

    // Removing the teamPlayer from the teamPlayers list if it was originally part of the teamPlayers list.
    teamPlayersAddedRef.current = teamPlayersAddedRef.current.filter(({ id }) => id != teamPlayer.id);

    // If this teamPlayer was originally part of the prop's players, it should not be included in among the playersList to be updated.
    teamPlayersRemovedRef.current = teamPlayersRemovedRef.current.filter(({ id }) => !players.map(({ id }) => id).includes(id));

    // Update the players list displayed.
    const updatedPlayersList = [ ...playersList, teamPlayer ].sort((a, b) => parseInt(a.jerseyNumber) - parseInt(b.jerseyNumber));
    setPlayersList(updatedPlayersList);

    setIsDoneDisabled(!(teamPlayersAddedRef.current.length > 0 || teamPlayersRemovedRef.current.length > 0));
  };

  const removeFromPlayersList = selectedPlayers => {
    const selectedPlayersIds = selectedPlayers.map(({ id }) => id);
    setPlayersList(playersList.filter(({ id }) => !selectedPlayersIds.includes(id)));
  };

  const addPlayersHandler = (selectedPlayers, formik) => {
    addToTeamPlayers(selectedPlayers);
    removeFromPlayersList(selectedPlayers);
    formik.resetForm();
  };

  const removeTeamPlayer = teamPlayer => {
    removeFromTeamPlayers(teamPlayer);
    addToPlayersList(teamPlayer);
  };

  const getInitialValues = () => {
    const emptyValues = {
      playerSearch: '',
    };

    return emptyValues;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
    >
      {formik => (
        <ModalComponent
          headerTitle={`Add/Remove Players - ${team?.name}`}
          buttonTitle={'Done'}
          open={modalOpen}
          setOpen={setModalOpen}
          modalWidth={895}
          isFooterVisible={true}
          buttonDimmed={isDoneDisabled}
          buttonDisabled={isDoneDisabled}
          handleSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(formik);
            setIsDoneDisabled(true);
            if (formik.isValid) {
              onSubmit([...teamPlayersAddedRef.current], [...teamPlayersRemovedRef.current], team, formik.resetForm);
              teamPlayersAddedRef.current = [];
              teamPlayersRemovedRef.current = [];
            }
          }}
          handleClose={() => {
            teamPlayersAddedRef.current = [];
            teamPlayersRemovedRef.current = [];
            setTeamPlayers(team?.players.items);
            setPlayersList(players);
            setIsDoneDisabled(true);
            formik.resetForm();
            onClose();
          }}
        >
          {/* Alert Column */}
          <Col sm={12} style={{ textAlign: 'center' }} className="px-1">
            {warning && 
            <Alert variant={'warning'}>
                <i>You have reached the maximum number of players (15) allowed on a team. To add another player to the team, you will need to first remove a player from the team.</i>
              </Alert>}
            {/* Error Message */}
            {error && <Alert variant={'danger'}>{error}</Alert>}
          </Col>

          <Row style={{ paddingLeft: 20, paddingRight: 20, }}>
            <Col xl={12} xs={12} md={12}>

              <Row>
                <Col xl={12} xs={12} md={12} className="mb-2">
                  <div style={modalInstructionStyles}>
                    Add Players to the team by first selecting the checkboxes and then use the Add Player button. Remove players from the team by pressing the X.
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: 30, }}>
                <Col xl={7} xs={7} md={7}>
                  <div style={playerListContainerStyle}>
                    <ContainerTitle
                      title={`Player List (${playersSize})`}
                    />

                    <PlayersListItemsContainer
                      league={team?.league}
                      teamPlayers={teamPlayers}
                      playersList={playersList}
                      setPlayersSize={setPlayersSize}
                      containerStyles={playersListItemsStyle}
                      addPlayersHandler={addPlayersHandler}
                      setWarning={setWarning}
                      formik={formik}
                    />
                  </div>
                </Col>

                <Col xl={5} xs={5} md={5}>
                  <div style={teamPlayersListContainerStyle}>
                    <ContainerTitle
                      title={`Team Players (${teamPlayers?.length} / ${team?.league?.teamSize})`}
                    />

                    <TeamPlayersListItemsContainer
                      teamPlayers={teamPlayers}
                      removeTeamPlayer={removeTeamPlayer}
                      containerStyles={teamPlayersListItemsStyle}
                    />
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddRemoveTeamPlayersModal;