import React from 'react';
import { modalWarningStyle } from '../styles';
import Warning from '../assets/Warning@2x.png';

const firstWarningTextStyle = { 
  width: 'auto', 
  marginLeft: 5, 
  marginRight: 5, 
  font: 'normal normal bold 16px/24px Inter', 
};

const secondWarningTextStyle = { 
  width: 'auto', 
  font: 'normal normal normal 16px/24px Inter, Medium', 
};

const PendingUpdate = ({
  topPosition = 0,
  pendingUpdateStatement = '',
}) => {
  return (
    <div style={{ ...modalWarningStyle, top: topPosition, }}>
      <img width={20} height={20} src={Warning} alt=""/>
      <div style={firstWarningTextStyle}>Pending update:</div>
      <div style={secondWarningTextStyle}>{pendingUpdateStatement}</div>
    </div>
  );
};

export default PendingUpdate;