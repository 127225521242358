import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setPlayBeingEdited } from '../controller/scoreKeeperEventSlice';
import { EventActionsHeading } from './EventActions';
import { EditTab } from './forms/EditTab';

function ScorekeeperEditScreen({ playBeingEdited, gameData, lineupData }) {
  const dispatch = useDispatch();

  return (
    <Container>
      <EventActionsHeading style={{ margin: '0 auto' }} />
      <EditTab
        play={playBeingEdited}
        exitEditMode={() => dispatch(setPlayBeingEdited())}
        gameData={gameData}
        lineupData={lineupData}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

export default ScorekeeperEditScreen;
