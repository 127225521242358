const VenueValidation = {
  name: {
    isRequired: { value: true, message: 'Venue is required' },
  },
  city: {
    regex: { value: /^([A-Za-z](\s)?[A-Za-z]*)+$/g, message: 'City is invalid' },
    isRequired: { value: true, message: 'City is required' },
  },
  zipcode: {
    length: { value: 5, message: 'Enter 5 digit Zip Code' },
    isRequired: { value: true, message: 'Zip Code is required' },
  },
  state: {
    isRequired: { value: true, message: 'State is required' },
  },
  image: {
    isRequired: { value: true, message: 'Upload Venue Image' },
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' },
  },
};

export default VenueValidation