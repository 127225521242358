import React, { useMemo, useState, useEffect } from 'react';
import ModalComponent from '../components/Modals/ModalComponent';
import MainLayout from '../components/layout/MainLayout';
import TableComponent from '../components/Tables/Table';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  getGame,
  deleteGame,
  createGameLineupPlayer,
  deleteGameLineupPlayer,
} from '../api/gamesService';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { listPlayers } from '../api/playersService';
import DeleteModal from '../components/Modals/DeleteModal';
import CheckboxBox from '../components/Checkbox/CheckboxBox';
import { Box } from '@material-ui/core';
import InputComponent from '../components/Inputs/InputComponent';

const ModalDiv = styled.div`
  margin-bottom: 2rem;
`;

const GameLineup = () => {
  const [game, gameHook] = useState([]);
  const [teams, teamsHook] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState();
  const [gameID, setGameID] = useState();
  const [value, setValue] = useState(0);
  const [checkableArray, setCheckableArray] = useState([]);
  const [playerListDisplay, setPlayerListDisplay] = useState(false);
  const [open, setOpen] = useState(false);

  const teamOneLineupPlayers =
    game && game.teamOneLineup && game.teamOneLineup.players.items;
  const teamTwoLineupPlayers =
    game && game.teamTwoLineup && game.teamTwoLineup.players.items;
  const gameLinupPlayers =
    value === 0 ? teamOneLineupPlayers : teamTwoLineupPlayers;

  let { id } = useParams();

  const callGetPlayers = async () => {
    const playersData = await listPlayers();
    const playersList = playersData?.data.listPlayers.items;
    initializePlayers(playersList);
  };

  useEffect(() => {
    callGetPlayers();
    getGameData();
  }, [callGetPlayers, getGameData]);

  const processTeam = () => {
    const selectedTeam = teams && teams[value] && teams[value].id;
    return selectedTeam;
  };

  const handleSubmit = async (values) => {
    const teamId = processTeam();
    handleCreateSubmssion();
  };

  const randomNumberGen = (max) => {
    return Math.floor(Math.random() * max);
  };

  const handleCreateSubmssion = async () => {
    let inLineup = false;

    const teamOneLineupPlayers =
      game && game.teamOneLineup && game.teamOneLineup.players.items;
    const teamTwoLineupPlayers =
      game && game.teamTwoLineup && game.teamTwoLineup.players.items;
    const gameLinupPlayers =
      value === 0 ? teamOneLineupPlayers : teamTwoLineupPlayers;

    const players = checkableArray;

    for (const i in players) {
      for (const j in gameLinupPlayers) {
        if (players[i].id === gameLinupPlayers[j].playerId) {
          inLineup = true;
        }
      }
      if (inLineup) {
        !players[i].checked &&
          (await deleteGameLineupPlayer(gameLinupPlayers[i].id));
      } else {
        players[i].checked &&
          (await createGameLineupPlayer(
            randomNumberGen(9999999999),
            value === 0 ? game.teamOneLineupId : game.teamTwoLineupId,
            players[i].id
          ));
      }
      inLineup = false;
    }
    getGameData();
    setOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'notes',
      },
      {
        Header: 'Player',
        accessor: (row) =>
          row && row.players && row.players.items
            ? row.players.items.length
            : 0,
      },
    ],
    []
  );

  const listPlayercolumns = useMemo(
    () => [
      {
        Header: 'Player Name',
        accessor: (row) => `${row.player.firstName} ${row.player.lastName}`,
      },
      {
        Header: 'League',
        accessor: (row) => row.player.league.name,
      },
    ],
    []
  );

  const handleDelete = async () => {
    await deleteGame(gameID);
    deleteModalSwitch(!deleteModalOpen);
  };

  const formik = useFormik({
    initialValues: {
      lineupName:
        game && game.teamOneLineup && value === 0
          ? game.teamOneLineup.notes
          : game && game.teamTwoLineup && value === 1
          ? game.teamTwoLineup.notes
          : '',
    },
    validationSchema: null,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const deleteModalSwitch = (row) => {
    setGameID(row ? row.id : null);
    setDeleteModalOpen(!deleteModalOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tab', newValue);
  };

  const initializePlayers = (playersList) => {
    let newArray = [];
    for (const i in playersList) {
      newArray.push({ ...playersList[i], checked: false });
    }
    setCheckableArray(newArray);
  };

  const selectedTeam = teams && teams[value] && teams[value].name;

  const getGameData = async () => {
    const gameData = await getGame(id);
    const gameDataObject = gameData && gameData.data.getGame;
    gameDataObject && gameHook(gameDataObject);
  };

  const handleEdit = async () => {
    initializeeditPlayers();
  };

  const initializeeditPlayers = async () => {
    let matches = false;
    const newArray = [];
    const selectedTab = localStorage.getItem('tab');
    const gameData = await getGame(id);
    const gameDataObject = gameData.data.getGame;
    const playersData = await listPlayers();
    const playersList = playersData?.data.listPlayers.items;
    const teamOneLineup = gameDataObject.teamOneLineup.players.items;
    const teamTwoLineup = gameDataObject.teamTwoLineup.players.items;
    const lineupPlayer = selectedTab === 0 ? teamOneLineup : teamTwoLineup;

    for (const i in playersList) {
      for (const j in lineupPlayer) {
        if (playersList[i].id === lineupPlayer[j].playerId) {
          matches = true;
        }
      }
      matches
        ? newArray.push({ ...playersList[i], checked: true })
        : newArray.push({ ...playersList[i], checked: false });
    }
    setCheckableArray(newArray);
    setOpen(true);
  };

  const switchPlayerListDisplay = () => {
    setPlayerListDisplay(!playerListDisplay);
  };

  return (
    <MainLayout
      title='Game Name'
      bodyContent={
        'Here you add all players on a team that are eligible to play in a game. Add game lineup for home and away team'
      }
    >
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
        >
          <Tab label='Team one' />
          <Tab label='Team Two' />
        </Tabs>
      </Paper>

      <ModalComponent
        headerTitle='Are you sure?'
        open={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        footerContent={true}
        setOpen={deleteModalSwitch}
      >
        <DeleteModal
          message={`Are you sure you want to permanently delete?`}
          handleDelete={handleDelete}
          handleCancel={deleteModalSwitch}
        />
      </ModalComponent>

      <div
        style={{ border: '1px solid', borderColor: '#898989', padding: '20px' }}
      >
        {playerListDisplay && (
          <div>
            <Link href='#' onClick={() => switchPlayerListDisplay()}>
              Back
            </Link>
          </div>
        )}

        {/* {!playerListDisplay ? (
          <TableComponent
            columns={columns}
            data={
              game && game.id && value === 0
                ? [game && game.teamOneLineup]
                : game && game.id && value === 1
                ? [game && game.teamTwoLineup]
                : []
            }
            handleEdit={handleEdit}
            handleView={switchPlayerListDisplay}
            viewLabel='View'
          />
        ) : (
          <TableComponent
            columns={listPlayercolumns}
            data={gameLinupPlayers ? gameLinupPlayers : []}
          />
        )} */}
      </div>

      <ModalComponent
        headerTitle='Add Game Lineup'
        buttonTitle='Add Game Lineup'
        open={open}
        setOpen={setOpen}
        topMargin='10%'
        handleSubmit={formik.handleSubmit}
      >
        <ModalDiv>
          <InputComponent
            label='Team 1 Lineup Name'
            id='name'
            name='lineupName'
            fullWidth={true}
            value={formik.values.lineupName}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </ModalDiv>

        <Box my={3}>
          <div>{selectedTeam}</div>
        </Box>
        <div>Add Players</div>
        <CheckboxBox
          label='jerseyName'
          checkableArray={checkableArray}
          setCheckableArray={setCheckableArray}
        />
      </ModalComponent>
    </MainLayout>
  );
};

export default GameLineup;
