import React from 'react';
import { Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { AuthState } from '@aws-amplify/ui-components';
import { PersonCircle } from 'react-bootstrap-icons';
import { colors } from '../../styles';
import { updateAuthState } from '../../redux/authStateSlice';
import { selectLightMode, toggleLightMode } from '../../redux/themeSlice';
import ExitIcon from '../../assets/exit.png';
import CancelIcon from '../../assets/cancel.png';
import CancelGray from '../../assets/cancelGray.png';
import SwitchIcon from '../../assets/switch@2x.png';
import BuildIcon from '../../assets/build.png';
import StatsIcon from '../../assets/stats@2x.png';
import { ADMIN, CLOCKMANAGER, SCOREKEEPER, COACH, SUPERUSER } from '../../data/roles';
import { NEW_GROUP_USER } from '../../utils/constantsUtils';
const log = require('../../logger')();

const DropdownStyle = styled(Dropdown)`
  &&& {
    background-color: transparent;
  }
`;

const DropdownButtonStyle = styled(Dropdown.Toggle)`
  &&& {
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const UserStyled = styled.div`
  &&& {
    padding: 10px 0;
    font-family: Inter;
    color: ${colors.WHITE[100]};
    text-align: center;
    background: ${colors.GRAY[700]} 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #6d6d6d;
  }
`;

const DropDownStyledImage = styled.img`
  color: ${colors.WHITE[100]};
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const MenuStyled = styled(Dropdown.Menu)`
  &&& {
    border: 1px solid #6d6d6d;
    background-color: #2d2d2d;
    padding-top: 0;
    padding-bottom: 0.5vh;
    margin-left: 1vw;
    margin-right: 1vw;
    z-index: 99;
  }
`;

const OuterItemStyled = styled(Dropdown.Item)`
  :hover {
    background-color: #6d6d6d;
  }
  background-color: #2d2d2d;
`;

const InnerItemStyled = styled.div`
  border-bottom: 1px solid #6d6d6d;
  width: 12vw;
  min-width: 12rem;
  max-width: 16rem;
  padding: 0.4vh 1vw 1vh 0.2vw;
  align-items: center;
  // box-shadow: 0px 1px #f5f5f5;
  display: flex;
  flex-direction: row;
  position: relative;
  :hover {
    background-color: #6d6d6d;
  }
  background-color: #2d2d2d;
  border-width: ${(props) => props.last && 0};
`;

const ItemTextStyled = styled.div`
  font-family: Inter;
  font-size: 1rem;
  color: ${(props) => (props.isDisabled ? 'gray' : 'white')};
  text-transform: capitalize;
`;

const StyledSwitch = styled(Form.Switch)`
  &&& {
    input:disabled {
      pointer-events: none;
      opacity: 1;
    }
  }
`;

const DropdownComponent = ({ handleOpenEndGameModal, hasGameEnded }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.group.value);
  const user = useSelector(
    (state) => state.user.value && JSON.parse(state.user.value)
  );
  const lightMode = useSelector(selectLightMode);

  const signoutUser = async () => {
    try {
      await Auth.signOut();
      dispatch(updateAuthState(AuthState.SignedOut));
      history.push('/');
    } catch (error) {
      log.error('error signing out: ', error);
    }
  };

  const handleNav = (url) => {
    history.push(url);
  };

  const disabledEndGame = hasGameEnded;

  const firstName = user?.attributes?.name?.substring(0, 1);
  const lastName = user?.attributes?.family_name?.substring(0, 9);

  return (
    <DropdownStyle>
      <DropdownButtonStyle drop="down" variant="success" id="dropdown-basic">
        <UserContainer>
          <PersonCircle style={{ fontSize: 20, color: colors.BLUE[100] }} />
        </UserContainer>
      </DropdownButtonStyle>

      <MenuStyled>
        <UserStyled>
          {(firstName && lastName && `${firstName}. ${lastName}`) ||
            user?.username ||
            'user'}
        </UserStyled>

        {(location?.pathname === '/scorekeeper' ||
          location?.pathname === '/clockmanager') && (
          <OuterItemStyled
            onClick={() => {
              dispatch(toggleLightMode());
            }}
          >
            <Form.Group
              className={'d-flex flex-row justify-content-start mx-auto'}
              style={{
                borderBottom: '1px solid #6d6d6d',
                marginTop: 10,
              }}
            >
              <Form.Label
                style={{
                  color: colors.WHITE[100],
                }}
              >
                Light Mode
              </Form.Label>
              <StyledSwitch
                style={{
                  marginLeft: 10,
                }}
                value={lightMode}
                checked={lightMode}
                disabled
              />
            </Form.Group>
          </OuterItemStyled>
        )}

        {location?.pathname === '/clockmanager' && (
          <OuterItemStyled
            onClick={handleOpenEndGameModal}
            disabled={disabledEndGame}
          >
            <InnerItemStyled>
              <DropDownStyledImage
                src={disabledEndGame ? CancelGray : CancelIcon}
                alt="cancel"
                isDisabled={disabledEndGame}
              />
              <ItemTextStyled isDisabled={disabledEndGame}>
                End game
              </ItemTextStyled>
            </InnerItemStyled>
          </OuterItemStyled>
        )}

        {group === ADMIN && (location?.pathname !== '/' && !location?.pathname.includes('/admin')) && (
          <OuterItemStyled onClick={() => handleNav('/')}>
            <InnerItemStyled>
              <DropDownStyledImage src={BuildIcon} alt="cancel" />
              <ItemTextStyled>Admin Panel</ItemTextStyled>
            </InnerItemStyled>
          </OuterItemStyled>
        )}

        {(group !== COACH) && 
         (location?.pathname !== '/gamechoice') && (
          <OuterItemStyled onClick={() => handleNav('/gamechoice')}>
            <InnerItemStyled>
              <DropDownStyledImage src={SwitchIcon} alt="cancel" />
              <ItemTextStyled>Switch Role</ItemTextStyled>
            </InnerItemStyled>
          </OuterItemStyled>
        )}

        {(group !== CLOCKMANAGER && 
          group !== SCOREKEEPER && 
          group !== SUPERUSER && 
          group !== NEW_GROUP_USER && 
          group !== COACH && 
          location?.pathname !== '/stats') && (
          <OuterItemStyled onClick={() => handleNav('/stats')}>
            <InnerItemStyled>
              <DropDownStyledImage src={StatsIcon} alt="cancel" />
              <ItemTextStyled>Game Stats</ItemTextStyled>
            </InnerItemStyled>
          </OuterItemStyled>
        )}

        <OuterItemStyled onClick={signoutUser}>
          <InnerItemStyled last={true}>
            <DropDownStyledImage src={ExitIcon} alt="cancel" />
            <ItemTextStyled handleClick={signoutUser}>Log out</ItemTextStyled>
          </InnerItemStyled>
        </OuterItemStyled>
      </MenuStyled>
    </DropdownStyle>
  );
};

export default DropdownComponent;
