import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PageContent from './PageContent';
import SquareGrid from '../Grids/SquareGrid';
import { colors } from '../../styles';
import RectangleIcon from '../../assets/RectangleSmall.png';
import UserIcon from '../../assets/user@2x.png';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import SearchMenuComponent from '../Inputs/SearchMenuComponent';
import { USER_ROLES } from '../../utils/userUtil';
import InfiniteScrollList from '../InfiniteListScroll';

const userItemRow = {
  width: '100%',
};

const activeStatusContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0px 0px 3px',
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const imageContainerStyle = {
  height: 80,
  width: '100%',
  margin: 0,
};

const imageContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 0,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const userNameStyle = {
  width: '100%',
  height: 35,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end',
  font: 'normal normal 600 24px/25px Barlow Condensed',
  color: '#111111',
  paddingBottom: 2,
};

const userEmailStyle = {
  width: '100%',
  height: 30,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  font: 'normal normal normal 13px/16px Open Sans',
  color: '#585858',
  paddingTop: 2,
};

const userRoleStyle = {
  width: '100%',
  height: 60,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'normal normal 600 14px/16px Open Sans',
  color: colors.BLACK[100],
  backgroundColor: '#F5F5F5',
  textAlign: 'center',
  position: 'relative',
};

const rectangleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'absolute',
  top: 60,
  margin: 0,
  width: '100%',
};

const UserInfoValue = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  width: 100%;
  text-align: center;
  word-break: break-all;
`;

const UserItem = ({
  id,
  name,
  email,
  role,
  active,
  editGameRolesCtrl,
  editUserProfileCtrl,
  history,
}) => {
  const USER_ROLES_VALUES = Object.values(USER_ROLES);
  const userRoleLabels = role.map(roleValue => USER_ROLES_VALUES.find(({ VALUE }) => VALUE === roleValue)?.LABEL);
  return (
    <SquareGrid
      key={id}
      bgColor={colors.WHITE[100]}
      borderColor={colors.GRAY[150]}
      height={280}
      width={250}
      onClick={() => history.push(`/admin/user/profile/${id}`)}
    >
      <Row style={userItemRow} className="mb-1">
        <Col xl={12} md={12} xs={12}>
          <div style={activeStatusContainer}>
            <div
              style={{
                ...activeStatusIndicator,
                backgroundColor: active ? '#2DCC38' : '#EE3B3B',
              }}
            ></div>
            <span style={activeStatusText}>
              {active ? 'Active' : 'Inactive'}
            </span>
          </div>
        </Col>
      </Row>

      <Row style={imageContainerStyle} className="mb-1">
        <Col style={imageContentStyle} xl={12} md={12} xs={12}>
          <img
            src={UserIcon}
            style={imageStyle}
            width="60px"
            height="60px"
            alt=""
          />
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col style={{ display: 'flex', flexDirection: 'column', }} xl={12} md={12} xs={12}>
          <div style={userNameStyle}>
            <UserInfoValue>{name}</UserInfoValue>
          </div>
          <div style={userEmailStyle}>
            <UserInfoValue>{email}</UserInfoValue>
          </div>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col xl={12} md={12} xs={12} className="p-0">
          <div style={userRoleStyle}>
            {userRoleLabels.join(', ')}
            <div style={rectangleStyle}>
              <img src={RectangleIcon} alt="user" />
            </div>
          </div>
        </Col>
      </Row>

      <OptionsOverflowMenu
        menuItems={[
          { label: 'View', onClick: () => history.push(`/admin/user/profile/${id}`) },
          { label: 'Edit Profile', onClick: (e) => { editUserProfileCtrl(id); e.stopPropagation(); } },
          { label: 'Edit Role', onClick: (e) => { editGameRolesCtrl(id); e.stopPropagation(); } }
        ]}
      />
    </SquareGrid>
  );
};

const UsersContent = ({
  searchId,
  data,
  placeholder,
  editUserGameRoles,
  editUserProfile,
  fetchNextPage,
  hasNextPage,
  isFetching,
  onSearch,
}) => {
  const history = useHistory();
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  const searchFilter = (option, search) =>
    option.name.toLowerCase().includes(search.toLowerCase()) ||
    option.email.toLowerCase().includes(search.toLowerCase()) ||
    option.role.join(',').toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (userSearch) {
      /** Check if the input is an object or a search string */
      if (typeof userSearch === 'object') {
        /** Object Selection */
        setDisplayedUsers([userSearch]);
      } else if (userSearch.length > 0) {
        /** Search String */
        setDisplayedUsers(
          data?.filter((option) => searchFilter(option, userSearch))
        );
      }
    } else {
      /** Display ALL users */
      setDisplayedUsers(data);
    }
  }, [userSearch, data]);


  useEffect(() => {
    if (!userSearch)
      onSearch('')
  }, [userSearch, onSearch])

  const getDisplayedData = displayedUsers => {
    return (
      <>
        {displayedUsers?.map(({ 
          id, 
          name, 
          email, 
          role, 
          active, 
        }) => {
          return (
            <UserItem
              key={id}
              id={id}
              name={name}
              email={email}
              role={role}
              active={active}
              editGameRolesCtrl={editUserGameRoles}
              editUserProfileCtrl={editUserProfile}
              history={history}
            />
          );
        })}
      </>
    );
  };

  return (
    <PageContent
      searchId={searchId}
      data={data}
      dataProperty={'name'}
      placeholder={placeholder}
      onChange={setUserSearch}
      filterBy={(option, props) => searchFilter(option, props.text)}
      isFetching={isFetching}
      onSearch={onSearch}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuComponent
            mainCriteriaText={[
              { option: option.name, text: props.text }
            ]}
            subCriteriaText={[
              { option: option.email, text: props.text }
            ]}
            subCriteriaDarkText={[
              { option: option.role.join(', '), text: props.text }
            ]}
          />
        );
      }}
    >
      <InfiniteScrollList
        displayedData={displayedUsers}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        displayedDataFilter={() => getDisplayedData(displayedUsers)}
      />
    </PageContent>
  );
};

export default UsersContent;