import React, { useEffect, useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import styled from 'styled-components';
import UserStatus from '../../pages/ClockManager/UserStatus';
import { colors } from '../../styles';

const AlertStyle = styled.div`
   {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: ${(props) => props.variant};
    border-radius: 3px;
  }
`;

const TextClass = styled.div`
  color: ${colors.WHITE[100]};
  text-align: center;
  font: normal normal 600 16px/21px 'Segoe UI';
`;

export const ALERT_VARIANTS = {
  GREEN: colors.GREEN[300],
  RED: colors.RED[400],
  ORANGE: colors.ORANGE[100],
  BLUE: colors.BLUE[200],
};

const AlertComponent = ({
  variant,
  notificationText,
  users,
  timeout,
  width,
  height,
  closeButton,
}) => {
  // const CloseButton = styled.button`
  //   color: ${colors.WHITE[100]};
  //   float: right;
  //   font: normal normal 600 16px/21px Segoe UI;
  //   text-align: center;
  //   background: none;
  //   border: none;
  // `;

  // the alert is displayed by default
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    setAlert(true);
  }, [notificationText]);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    if (timeout) {
      const timeoutId = setTimeout(() => {
        setAlert(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    }
  }, [notificationText, timeout]);

  return (
    <>
      {alert ? (
        <AlertStyle
          height={height}
          width={width}
          variant={variant}
          className="row"
        >
          <div className="col-md-11">
            <TextClass>{notificationText?.replace(/[0-9]/g, '')}</TextClass>
          </div>
          <div className="col-md-1">
            {/* <CloseButton onClick={() => setAlert(false)} className='btn-close' /> */}
            {closeButton && (
              <CloseButton
                style={{
                  textAlign: 'center',
                  float: 'right',
                  color: 'white',
                  width: '5px',
                  height: '5px',
                  opacity: '1',
                }}
                variant="white"
                onClick={() => setAlert(false)}
              />
            )}
          </div>
        </AlertStyle>
      ) : (
        <UserStatus users={users} />
      )}
    </>
  );
};

export default AlertComponent;
