/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSport = /* GraphQL */ `
  subscription OnCreateSport {
    onCreateSport {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSport = /* GraphQL */ `
  subscription OnUpdateSport {
    onUpdateSport {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSport = /* GraphQL */ `
  subscription OnDeleteSport {
    onDeleteSport {
      id
      createdAt
      updatedAt
      name
      leagues {
        items {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLeague = /* GraphQL */ `
  subscription OnCreateLeague {
    onCreateLeague {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLeague = /* GraphQL */ `
  subscription OnUpdateLeague {
    onUpdateLeague {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLeague = /* GraphQL */ `
  subscription OnDeleteLeague {
    onDeleteLeague {
      id
      keySortId
      createdAt
      updatedAt
      name
      abbreviation
      teamSize
      numPlayersOnCourt
      numPlayersOnBench
      numTimeOuts
      numFoulsPerPlayer
      numPeriods
      timePerPeriodInMins
      overtimeDurationInMins
      searchName
      searchAbbreviation
      sportId
      sport {
        id
        createdAt
        updatedAt
        name
        leagues {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      seasons {
        items {
          id
          keySortId
          createdAt
          updatedAt
          startDate
          endDate
          seasonType
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSeason = /* GraphQL */ `
  subscription OnCreateSeason {
    onCreateSeason {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSeason = /* GraphQL */ `
  subscription OnUpdateSeason {
    onUpdateSeason {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSeason = /* GraphQL */ `
  subscription OnDeleteSeason {
    onDeleteSeason {
      id
      keySortId
      createdAt
      updatedAt
      startDate
      endDate
      seasonType
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      keySortId
      createdAt
      updatedAt
      name
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          keySortId
          createdAt
          updatedAt
          firstName
          lastName
          position
          jerseyNumber
          dateOfBirth
          birthCity
          birthState
          birthCountry
          weight
          height
          institute
          active
          teamId
          leagueId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      city
      state
      active
      searchName
      searchCity
      searchState
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePlayer = /* GraphQL */ `
  subscription OnCreatePlayer {
    onCreatePlayer {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePlayer = /* GraphQL */ `
  subscription OnUpdatePlayer {
    onUpdatePlayer {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePlayer = /* GraphQL */ `
  subscription OnDeletePlayer {
    onDeletePlayer {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      position
      jerseyNumber
      dateOfBirth
      birthCity
      birthState
      birthCountry
      weight
      height
      institute
      active
      teamId
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVenue = /* GraphQL */ `
  subscription OnCreateVenue {
    onCreateVenue {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVenue = /* GraphQL */ `
  subscription OnUpdateVenue {
    onUpdateVenue {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVenue = /* GraphQL */ `
  subscription OnDeleteVenue {
    onDeleteVenue {
      id
      keySortId
      createdAt
      updatedAt
      name
      city
      state
      zipcode
      active
      searchName
      searchCity
      searchState
      games {
        items {
          sportId
          sportName
          keySortId
          leagueId
          leagueName
          leagueAbbreviation
          leagueTeamSize
          leagueNumPlayersOnCourt
          leagueNumPlayersOnBench
          leagueNumTimeOuts
          leagueNumFoulsPerPlayer
          leagueNumPeriods
          leagueTimePerPeriodInMins
          leagueOvertimeDurationInMins
          seasonId
          seasonStartDate
          seasonEndDate
          seasonType
          homeTeamId
          homeTeamName
          homeTeamCity
          homeTeamState
          homeTeamActive
          awayTeamId
          awayTeamName
          awayTeamCity
          awayTeamState
          awayTeamActive
          venueId
          venueName
          venueCity
          venueState
          venueZipcode
          venueActive
          id
          createdAt
          updatedAt
          gameDateTime
          gameStatus
          homeTeamGameLineupId
          homeTeamScore
          awayTeamGameLineupId
          awayTeamScore
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGameUser = /* GraphQL */ `
  subscription OnCreateGameUser {
    onCreateGameUser {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGameUser = /* GraphQL */ `
  subscription OnUpdateGameUser {
    onUpdateGameUser {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGameUser = /* GraphQL */ `
  subscription OnDeleteGameUser {
    onDeleteGameUser {
      id
      createdAt
      updatedAt
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTeamUser = /* GraphQL */ `
  subscription OnCreateTeamUser {
    onCreateTeamUser {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTeamUser = /* GraphQL */ `
  subscription OnUpdateTeamUser {
    onUpdateTeamUser {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTeamUser = /* GraphQL */ `
  subscription OnDeleteTeamUser {
    onDeleteTeamUser {
      id
      createdAt
      updatedAt
      leagueId
      seasonId
      teamId
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        email
        role
        active
        searchFirstName
        searchLastName
        games {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameRole
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      keySortId
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      active
      searchFirstName
      searchLastName
      games {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGame = /* GraphQL */ `
  subscription OnCreateGame {
    onCreateGame {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGame = /* GraphQL */ `
  subscription OnUpdateGame {
    onUpdateGame {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGame = /* GraphQL */ `
  subscription OnDeleteGame {
    onDeleteGame {
      sportId
      sportName
      keySortId
      leagueId
      leagueName
      leagueAbbreviation
      leagueTeamSize
      leagueNumPlayersOnCourt
      leagueNumPlayersOnBench
      leagueNumTimeOuts
      leagueNumFoulsPerPlayer
      leagueNumPeriods
      leagueTimePerPeriodInMins
      leagueOvertimeDurationInMins
      seasonId
      seasonStartDate
      seasonEndDate
      seasonType
      homeTeamId
      homeTeamName
      homeTeamCity
      homeTeamState
      homeTeamActive
      awayTeamId
      awayTeamName
      awayTeamCity
      awayTeamState
      awayTeamActive
      venueId
      venueName
      venueCity
      venueState
      venueZipcode
      venueActive
      id
      createdAt
      updatedAt
      gameDateTime
      gameStatus
      homeTeamGameLineupId
      homeTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamScore
      awayTeamGameLineupId
      awayTeamGameLineup {
        id
        createdAt
        updatedAt
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        gameId
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamScore
      users {
        items {
          id
          createdAt
          updatedAt
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coach {
        items {
          id
          createdAt
          updatedAt
          leagueId
          seasonId
          teamId
          gameId
          userId
          gameRole
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGameLineup = /* GraphQL */ `
  subscription OnCreateGameLineup {
    onCreateGameLineup {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGameLineup = /* GraphQL */ `
  subscription OnUpdateGameLineup {
    onUpdateGameLineup {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGameLineup = /* GraphQL */ `
  subscription OnDeleteGameLineup {
    onDeleteGameLineup {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      players {
        items {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGameLineupPlayer = /* GraphQL */ `
  subscription OnCreateGameLineupPlayer {
    onCreateGameLineupPlayer {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGameLineupPlayer = /* GraphQL */ `
  subscription OnUpdateGameLineupPlayer {
    onUpdateGameLineupPlayer {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGameLineupPlayer = /* GraphQL */ `
  subscription OnDeleteGameLineupPlayer {
    onDeleteGameLineupPlayer {
      id
      createdAt
      updatedAt
      gameLineupId
      playerId
      playerFirstName
      playerLastName
      playerPosition
      playerJerseyNumber
      playerDateOfBirth
      playerBirthCity
      playerBirthState
      playerBirthCountry
      playerWeight
      playerHeight
      playerInstitute
      playerActive
      playerOnCourtBenchStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      createdAt
      updatedAt
      eventType
      relatedEventId
      gameId
      teamId
      playerId
      gameLineupPlayerId
      gameLineupPlayer {
        id
        createdAt
        updatedAt
        gameLineupId
        playerId
        playerFirstName
        playerLastName
        playerPosition
        playerJerseyNumber
        playerDateOfBirth
        playerBirthCity
        playerBirthState
        playerBirthCountry
        playerWeight
        playerHeight
        playerInstitute
        playerActive
        playerOnCourtBenchStatus
        _version
        _deleted
        _lastChangedAt
      }
      eventAbsoluteTime
      eventCreatorRole
      gamePeriodNumber
      gamePeriodMinutes
      gamePeriodSeconds
      gameOvertimeNumber
      gameOvertimeMinutes
      gameOvertimeSeconds
      shotLocation
      possessionTeam
      shootingFoul
      playType
      isEdited
      isDeleted
      isFlaged
      statcollSub
      statcollEmail
      statcollFirstName
      statcollLastName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCareerPlayerStats = /* GraphQL */ `
  subscription OnCreateCareerPlayerStats {
    onCreateCareerPlayerStats {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCareerPlayerStats = /* GraphQL */ `
  subscription OnUpdateCareerPlayerStats {
    onUpdateCareerPlayerStats {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCareerPlayerStats = /* GraphQL */ `
  subscription OnDeleteCareerPlayerStats {
    onDeleteCareerPlayerStats {
      id
      createdAt
      updatedAt
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      dd2
      dd3
      eff_fg
      tov_percent
      usage_percent
      assist_percent
      possession
      win_percent
      loss
      personal_foul
      tech_foul
      win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCareerTeamStats = /* GraphQL */ `
  subscription OnCreateCareerTeamStats {
    onCreateCareerTeamStats {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCareerTeamStats = /* GraphQL */ `
  subscription OnUpdateCareerTeamStats {
    onUpdateCareerTeamStats {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCareerTeamStats = /* GraphQL */ `
  subscription OnDeleteCareerTeamStats {
    onDeleteCareerTeamStats {
      id
      createdAt
      updatedAt
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      loss
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      win
      true_shoot_att
      true_shoot_percent
      win_percent
      last_in
      tov_percent
      usage_percent
      dd2
      dd3
      possession
      personal_foul
      tech_foul
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGamePlayerStats = /* GraphQL */ `
  subscription OnCreateGamePlayerStats {
    onCreateGamePlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGamePlayerStats = /* GraphQL */ `
  subscription OnUpdateGamePlayerStats {
    onUpdateGamePlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGamePlayerStats = /* GraphQL */ `
  subscription OnDeleteGamePlayerStats {
    onDeleteGamePlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      blocks
      def_rebound
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      last_in
      minutes_played
      off_rebound
      steal
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebounds
      turnover
      two_percent
      two_point_att
      two_point_made
      assist_percent
      eff_fg
      gameLineupId
      tov_percent
      block_percent
      rebounds
      game_score
      personal_fowls_drawn
      dd2
      dd3
      def_rebound_percent
      off_rebound_percent
      personal_foul
      steal_percent
      tech_foul
      total_rebound_percent
      true_shoot_att
      true_shoot_percent
      usage_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSeasonPlayerStats = /* GraphQL */ `
  subscription OnCreateSeasonPlayerStats {
    onCreateSeasonPlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSeasonPlayerStats = /* GraphQL */ `
  subscription OnUpdateSeasonPlayerStats {
    onUpdateSeasonPlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSeasonPlayerStats = /* GraphQL */ `
  subscription OnDeleteSeasonPlayerStats {
    onDeleteSeasonPlayerStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      minutes_played
      off_rebound
      off_rebound_percent
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_atts
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSeasonTeamStats = /* GraphQL */ `
  subscription OnCreateSeasonTeamStats {
    onCreateSeasonTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSeasonTeamStats = /* GraphQL */ `
  subscription OnUpdateSeasonTeamStats {
    onUpdateSeasonTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSeasonTeamStats = /* GraphQL */ `
  subscription OnDeleteSeasonTeamStats {
    onDeleteSeasonTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      assist
      assist_percent
      block_percent
      blocks
      dd2
      dd3
      def_rebound
      def_rebound_percent
      eff_fg
      fg_att
      fg_made
      fg_percent
      free_throw
      free_throw_atts
      ft_percent
      game_score
      losses
      minutes_played
      off_rebound
      off_rebound_percent
      opp_3p_atts
      opp_def_rebounds
      opp_fg
      opp_fg_atts
      opp_ft_atts
      opp_off_rebounds
      opp_points
      opp_possessions
      opp_total_rebounds
      opp_tov
      personal_foul
      steal
      steal_percent
      team_minutes_played
      tech_foul
      three_percent
      three_point_att
      three_point_made
      total_points
      total_rebound_percent
      total_rebounds
      tov_percent
      true_shoot_att
      true_shoot_percent
      turnover
      two_percent
      two_point_att
      two_point_made
      usage_percent
      win_percent
      wins
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateScoreType = /* GraphQL */ `
  subscription OnCreateScoreType {
    onCreateScoreType {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScoreType = /* GraphQL */ `
  subscription OnUpdateScoreType {
    onUpdateScoreType {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScoreType = /* GraphQL */ `
  subscription OnDeleteScoreType {
    onDeleteScoreType {
      id
      leagueId
      league {
        id
        keySortId
        createdAt
        updatedAt
        name
        abbreviation
        teamSize
        numPlayersOnCourt
        numPlayersOnBench
        numTimeOuts
        numFoulsPerPlayer
        numPeriods
        timePerPeriodInMins
        overtimeDurationInMins
        searchName
        searchAbbreviation
        sportId
        sport {
          id
          createdAt
          updatedAt
          name
          _version
          _deleted
          _lastChangedAt
        }
        seasons {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      category
      displayName
      type
      data
      points
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeamRosterPlayer = /* GraphQL */ `
  subscription OnCreateTeamRosterPlayer {
    onCreateTeamRosterPlayer {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeamRosterPlayer = /* GraphQL */ `
  subscription OnUpdateTeamRosterPlayer {
    onUpdateTeamRosterPlayer {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeamRosterPlayer = /* GraphQL */ `
  subscription OnDeleteTeamRosterPlayer {
    onDeleteTeamRosterPlayer {
      id
      teamRosterId
      teamRoster {
        id
        name
        info
        startDateTime
        endDateTime
        dateCreated
        dateUpdated
        teamId
        team {
          id
          keySortId
          createdAt
          updatedAt
          name
          leagueId
          city
          state
          active
          searchName
          searchCity
          searchState
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeamRoster = /* GraphQL */ `
  subscription OnCreateTeamRoster {
    onCreateTeamRoster {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeamRoster = /* GraphQL */ `
  subscription OnUpdateTeamRoster {
    onUpdateTeamRoster {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeamRoster = /* GraphQL */ `
  subscription OnDeleteTeamRoster {
    onDeleteTeamRoster {
      id
      name
      info
      startDateTime
      endDateTime
      dateCreated
      dateUpdated
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      players {
        items {
          id
          teamRosterId
          playerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTimeEvent = /* GraphQL */ `
  subscription OnCreateTimeEvent {
    onCreateTimeEvent {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTimeEvent = /* GraphQL */ `
  subscription OnUpdateTimeEvent {
    onUpdateTimeEvent {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTimeEvent = /* GraphQL */ `
  subscription OnDeleteTimeEvent {
    onDeleteTimeEvent {
      id
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      dateTime
      type
      description
      note
      data
      frontendGameQuarterNumber
      frontendGameQuarterMinutes
      frontendGameQuarterSeconds
      backendGameQuarterNumber
      backendGameQuarterMinutes
      backendGameQuarterSeconds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCustomStat = /* GraphQL */ `
  subscription OnCreateCustomStat {
    onCreateCustomStat {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomStat = /* GraphQL */ `
  subscription OnUpdateCustomStat {
    onUpdateCustomStat {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomStat = /* GraphQL */ `
  subscription OnDeleteCustomStat {
    onDeleteCustomStat {
      id
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      teamId
      team {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      gameId
      game {
        sportId
        sportName
        keySortId
        leagueId
        leagueName
        leagueAbbreviation
        leagueTeamSize
        leagueNumPlayersOnCourt
        leagueNumPlayersOnBench
        leagueNumTimeOuts
        leagueNumFoulsPerPlayer
        leagueNumPeriods
        leagueTimePerPeriodInMins
        leagueOvertimeDurationInMins
        seasonId
        seasonStartDate
        seasonEndDate
        seasonType
        homeTeamId
        homeTeamName
        homeTeamCity
        homeTeamState
        homeTeamActive
        awayTeamId
        awayTeamName
        awayTeamCity
        awayTeamState
        awayTeamActive
        venueId
        venueName
        venueCity
        venueState
        venueZipcode
        venueActive
        id
        createdAt
        updatedAt
        gameDateTime
        gameStatus
        homeTeamGameLineupId
        homeTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        homeTeamScore
        awayTeamGameLineupId
        awayTeamGameLineup {
          id
          createdAt
          updatedAt
          teamId
          gameId
          _version
          _deleted
          _lastChangedAt
        }
        awayTeamScore
        users {
          nextToken
          startedAt
        }
        coach {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      entityId
      scoreTypeId
      scoreType {
        id
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        name
        category
        displayName
        type
        data
        points
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dateUpdated
      positiveInstanceCount
      negativeInstanceCount
      allInstanceCount
      positiveToAllPercentage
      otherCounterOne
      otherCounterTwo
      otherCounterThree
      otherCounterFour
      otherData
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventPlayer = /* GraphQL */ `
  subscription OnCreateEventPlayer {
    onCreateEventPlayer {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateEventPlayer = /* GraphQL */ `
  subscription OnUpdateEventPlayer {
    onUpdateEventPlayer {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteEventPlayer = /* GraphQL */ `
  subscription OnDeleteEventPlayer {
    onDeleteEventPlayer {
      id
      eventId
      event {
        id
        createdAt
        updatedAt
        eventType
        relatedEventId
        gameId
        teamId
        playerId
        gameLineupPlayerId
        gameLineupPlayer {
          id
          createdAt
          updatedAt
          gameLineupId
          playerId
          playerFirstName
          playerLastName
          playerPosition
          playerJerseyNumber
          playerDateOfBirth
          playerBirthCity
          playerBirthState
          playerBirthCountry
          playerWeight
          playerHeight
          playerInstitute
          playerActive
          playerOnCourtBenchStatus
          _version
          _deleted
          _lastChangedAt
        }
        eventAbsoluteTime
        eventCreatorRole
        gamePeriodNumber
        gamePeriodMinutes
        gamePeriodSeconds
        gameOvertimeNumber
        gameOvertimeMinutes
        gameOvertimeSeconds
        shotLocation
        possessionTeam
        shootingFoul
        playType
        isEdited
        isDeleted
        isFlaged
        statcollSub
        statcollEmail
        statcollFirstName
        statcollLastName
        _version
        _deleted
        _lastChangedAt
      }
      playerId
      player {
        id
        keySortId
        createdAt
        updatedAt
        firstName
        lastName
        position
        jerseyNumber
        dateOfBirth
        birthCity
        birthState
        birthCountry
        weight
        height
        institute
        active
        teamId
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateGameTeamStats = /* GraphQL */ `
  subscription OnCreateGameTeamStats {
    onCreateGameTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGameTeamStats = /* GraphQL */ `
  subscription OnUpdateGameTeamStats {
    onUpdateGameTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGameTeamStats = /* GraphQL */ `
  subscription OnDeleteGameTeamStats {
    onDeleteGameTeamStats {
      id
      createdAt
      updatedAt
      seasonId
      season {
        id
        keySortId
        createdAt
        updatedAt
        startDate
        endDate
        seasonType
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        games {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      homeTeamId
      homeTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      awayTeamId
      awayTeam {
        id
        keySortId
        createdAt
        updatedAt
        name
        leagueId
        league {
          id
          keySortId
          createdAt
          updatedAt
          name
          abbreviation
          teamSize
          numPlayersOnCourt
          numPlayersOnBench
          numTimeOuts
          numFoulsPerPlayer
          numPeriods
          timePerPeriodInMins
          overtimeDurationInMins
          searchName
          searchAbbreviation
          sportId
          _version
          _deleted
          _lastChangedAt
        }
        players {
          nextToken
          startedAt
        }
        city
        state
        active
        searchName
        searchCity
        searchState
        _version
        _deleted
        _lastChangedAt
      }
      curr_possession
      end_pos_tov
      margin_of_victory
      regular
      home_assist
      home_blocks
      home_def_rebound
      home_fg_att
      home_fg_made
      home_fg_percent
      home_free_throw
      home_free_throw_atts
      home_ft_percent
      home_last_in
      home_minutes_played
      home_num_pos
      home_off_rebound
      home_steal
      home_three_percent
      home_three_point_att
      home_three_point_made
      home_total_points
      home_total_rebounds
      home_turnover
      home_two_percent
      home_two_point_att
      home_two_point_made
      home_assist_percent
      home_eff_fg
      home_tov_percent
      home_true_shoot_att
      home_true_shoot_percent
      home_usage_percent
      home_dd2
      home_dd3
      home_block_percent
      home_def_rebound_percent
      home_game_score
      home_off_rebound_percent
      home_personal_foul
      home_steal_percent
      home_tech_foul
      home_timeouts
      home_total_rebound_percent
      home_win
      away_assist
      away_blocks
      away_def_rebound
      away_fg_att
      away_fg_made
      away_fg_percent
      away_free_throw
      away_free_throw_atts
      away_ft_percent
      away_last_in
      away_minutes_played
      away_num_pos
      away_off_rebound
      away_steal
      away_three_percent
      away_three_point_att
      away_three_point_made
      away_total_points
      away_total_rebounds
      away_turnover
      away_two_percent
      away_two_point_att
      away_two_point_made
      away_assist_percent
      away_eff_fg
      away_tov_percent
      away_true_shoot_att
      away_true_shoot_percent
      away_usage_percent
      away_dd2
      away_dd3
      away_block_percent
      away_def_rebound_percent
      away_game_score
      away_off_rebound_percent
      away_personal_foul
      away_steal_percent
      away_tech_foul
      away_timeouts
      away_total_rebound_percent
      away_win
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
