import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles';
import { StyledFlexRow } from '../../styles/flex';
import ButtonElement from '../Buttons/ButtonElement';

const ContainerStyled = styled.div`
  &&& {
    padding-bottom: 5px;
  }
`;

const TitleOnlyStyle = styled.div`
  &&& {
    font-family: Inter;
    font-size: 22px;
    color: #434343;
    font-weight: bold;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
`;

const LineStyled = styled.hr`
  &&& {
    background-color: ${colors.GRAY[300]};
    border-color: ${colors.GRAY[300]};
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
`;

const TitleStyle = styled.div`
  &&& {
    font-size: 18px;
    color: #434343;
    font-weight: bold;
    padding-top: 0px;
  }
`;

const SubtitleStyle = styled.div`
  &&& {
    font-size: 14px;
    color: #434343;
    padding-top: 0px;
  }
`;

const LaunchHeaderRow = ({
  title = 'Header Title',
  subTitle = '',
  rightButtonLabel = 'Next',
  rightButtonHandler,
  rightButtonEnabled,
  rightButtonHidden,
  leftButtonLabel = 'Back',
  leftButtonHandler,
  leftButtonEnabled,
  leftButtonHidden,
}) => {

  return (
    <ContainerStyled>
      <StyledFlexRow>
        <ButtonElement
          minWidth={'112px'}
          label={leftButtonLabel}
          onClick={leftButtonEnabled ? leftButtonHandler : undefined}
          dimmed={!leftButtonEnabled}
          hidden={leftButtonHidden}
          outlined={true}
        />
        {subTitle ? (
          <TitleAndSubtitleContainer>
            <TitleStyle>{title}</TitleStyle>
            <SubtitleStyle>{subTitle}</SubtitleStyle>
          </TitleAndSubtitleContainer>
        ) : (
          <TitleOnlyStyle>{title}</TitleOnlyStyle>
        )}
        <ButtonElement
          minWidth={'112px'}
          label={rightButtonLabel}
          onClick={rightButtonEnabled ? rightButtonHandler: undefined}
          dimmed={!rightButtonEnabled}
          hidden={rightButtonHidden}
        />
      </StyledFlexRow>
      <LineStyled />
    </ContainerStyled>
  );
};

export default LaunchHeaderRow;
