import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { StatsTitleHeader } from './StatsTitleHeader';
import { SummaryTeamStats } from './SummaryTeamStatsContainer';
import { ViewStats, ViewStatsItem } from './TeamViewStats';
import GameLeadersTab from './GameLeadersTab';
import GameLeadersContent from './GameLeadersContent';
import { PLAYERS_POSITIONS_ABBREVIATION } from '../../../utils/playersUtil';

const GAME_LEADERS_TABS = {
  POINTS: 'Points',
  REBOUNDS: 'Rebounds',
  ASSISTS: 'Assists',
};

const GameLeadersStats = ({
  setStatsTab = () => {}, 
  awayTeamImage, 
  homeTeamImage, 
  awayTeamPlayersGameStats, 
  homeTeamPlayersGameStats, 
}) => {
  const [selectedTab, setSelectedTab] = useState(GAME_LEADERS_TABS.POINTS);
  const [awayGameLeaderStats, setAwayGameLeaderStats] = useState();
  const [homeGameLeaderStats, setHomeGameLeaderStats] = useState();
  const awayTeamPlayersGameStatsRef = useRef();
  const homeTeamPlayersGameStatsRef = useRef();

  useEffect(() => {
    if(awayTeamPlayersGameStats && homeTeamPlayersGameStats) {
      awayTeamPlayersGameStatsRef.current = awayTeamPlayersGameStats;
      homeTeamPlayersGameStatsRef.current = homeTeamPlayersGameStats;
      setActiveGameLeadersTab(selectedTab);
    }
  }, [awayTeamPlayersGameStats, homeTeamPlayersGameStats]);

  const setGamePointLeaders = (awayGamePlayersStats, homeGamePlayersStats) => {
    const awayGamePointsLeader = getHighestPlayerStatValue(awayGamePlayersStats?.filter(({ total_points }) => total_points), "total_points");
    const homeGamePointsLeader = getHighestPlayerStatValue(homeGamePlayersStats?.filter(({ total_points }) => total_points), "total_points");
    const awayPlayerPoints = getPlayerPointsData(awayGamePointsLeader);
    const homePlayerPoints = getPlayerPointsData(homeGamePointsLeader);
    setAwayGameLeaderStats({ ...awayPlayerPoints });
    setHomeGameLeaderStats({ ...homePlayerPoints });
  };

  const setGameReboundLeaders = (awayGamePlayersStats, homeGamePlayersStats) => {
    const awayGameReboundsLeader = getHighestPlayerStatValue(awayGamePlayersStats?.filter(({ total_rebounds }) => total_rebounds), "total_rebounds");
    const homeGameReboundsLeader = getHighestPlayerStatValue(homeGamePlayersStats?.filter(({ total_rebounds }) => total_rebounds), "total_rebounds");
    const awayPlayerRebounds = getPlayerReboundsData(awayGameReboundsLeader);
    const homePlayerRebounds = getPlayerReboundsData(homeGameReboundsLeader);
    setAwayGameLeaderStats({ ...awayPlayerRebounds });
    setHomeGameLeaderStats({ ...homePlayerRebounds });
  };

  const setGameAssistLeaders = (awayGamePlayersStats, homeGamePlayersStats) => {
    const awayGameAssistsLeader = getHighestPlayerStatValue(awayGamePlayersStats?.filter(({ assist }) => assist), "assist");
    const homeGameAssistsLeader = getHighestPlayerStatValue(homeGamePlayersStats?.filter(({ assist }) => assist), "assist");
    const awayPlayerAssists = getPlayerAssistsData(awayGameAssistsLeader);
    const homePlayerAssists = getPlayerAssistsData(homeGameAssistsLeader);
    setAwayGameLeaderStats({ ...awayPlayerAssists });
    setHomeGameLeaderStats({ ...homePlayerAssists });
  };

  const getHighestPlayerStatValue = (gamePlayersStats, playerStatProperty) => {
    return gamePlayersStats?.reduce((accumulatedItem, currentItem) => (
      (accumulatedItem[playerStatProperty] > currentItem[playerStatProperty]) 
        ? accumulatedItem 
        : currentItem
      ), {}
    );
  };

  const getPlayerPointsData = teamGamePlayerLeader => {
    if(teamGamePlayerLeader && Object.keys(teamGamePlayerLeader).length > 0)
    {
      const { player, total_points, fg_made, fg_att, free_throw, free_throw_atts } = teamGamePlayerLeader;
      const playerInformation = getPlayerInformation(player);
      const playerRecord = { title: 'PTS', stat: total_points, };
      const playerStatRecord = { title: 'FG', stat: `${fg_made}/${fg_made + fg_att}`, };
      const playerStatInfo = { title: 'FT', stat: `${free_throw}/${free_throw + free_throw_atts}` };
      return { playerInformation, playerRecord, playerStatRecord, playerStatInfo };
    }
    else 
    {
      const playerRecord = { title: 'PTS', stat: 0, };
      const playerStatRecord = { title: 'FG', stat: `${0}/${0}`, };
      const playerStatInfo = { title: 'FT', stat: `${0}/${0}` };
      return { playerRecord, playerStatRecord, playerStatInfo };
    }
  };

  const getPlayerReboundsData = teamGamePlayerLeader => {
    if(teamGamePlayerLeader && Object.keys(teamGamePlayerLeader).length > 0) 
    {
      const { player, total_rebounds, def_rebound, off_rebound } = teamGamePlayerLeader;
      const playerInformation = getPlayerInformation(player);
      const playerRecord = { title: 'REB', stat: total_rebounds, };
      const playerStatRecord = { title: 'DREB', stat: def_rebound, };
      const playerStatInfo = { title: 'OREB', stat: off_rebound, };
      return { playerInformation, playerRecord, playerStatRecord, playerStatInfo };
    }
    else
    {
      const playerRecord = { title: 'REB', stat: 0, };
      const playerStatRecord = { title: 'DREB', stat: `${0}/${0}`, };
      const playerStatInfo = { title: 'OREB', stat: `${0}/${0}` };
      return { playerRecord, playerStatRecord, playerStatInfo };
    }
  };

  const getPlayerAssistsData = teamGamePlayerLeader => {
    if(teamGamePlayerLeader && Object.keys(teamGamePlayerLeader).length > 0)
    {
      const { player, assist, turnover, minutes_played } = teamGamePlayerLeader;
      const playerInformation = getPlayerInformation(player);
      const playerRecord = { title: 'AST', stat: assist, };
      const playerStatRecord = { title: 'TO', stat: turnover, };
      const playerStatInfo = { title: '', stat: minutes_played ? minutes_played: null }
      return { playerInformation, playerRecord, playerStatRecord, playerStatInfo };
    }
    else 
    {
      const playerRecord = { title: 'AST', stat: 0, };
      const playerStatRecord = { title: 'TO', stat: 0, };
      const playerStatInfo = { title: '', stat: null }
      return { playerRecord, playerStatRecord, playerStatInfo };
    }
  };

  const getPlayerInformation = player => {
    if(Object.keys(player).length > 0)
    {
      const { firstName, lastName, playerImage, position } = player;
      const playerName = `${firstName.toUpperCase().charAt(0)}. ${lastName}`;
      const playerPosition = position?.map(pos => PLAYERS_POSITIONS_ABBREVIATION[pos]).join('-');
      return { playerName, playerPosition, playerImage };
    }
    else 
    {
      return {};
    }
  };

  const setActiveGameLeadersTab = useCallback((selectedTab) => {
    switch(selectedTab) {
      case GAME_LEADERS_TABS.POINTS:
        setGamePointLeaders(awayTeamPlayersGameStatsRef.current, homeTeamPlayersGameStatsRef.current);
        break;
      case GAME_LEADERS_TABS.REBOUNDS:
        setGameReboundLeaders(awayTeamPlayersGameStatsRef.current, homeTeamPlayersGameStatsRef.current);
        break;
      case GAME_LEADERS_TABS.ASSISTS:
        setGameAssistLeaders(awayTeamPlayersGameStatsRef.current, homeTeamPlayersGameStatsRef.current);
        break;
    };

    setSelectedTab(selectedTab);
  }, [selectedTab])

  return (
    <SummaryTeamStats
      height={'210px'}
    >
      <StatsTitleHeader
        justifyContent="center"
        alignItems="flex-start"
        borderWidth={1}
      >
        Game Leaders
      </StatsTitleHeader>
      <GameLeadersTab
        activePointsStatus={selectedTab === GAME_LEADERS_TABS.POINTS}
        pointsClickHandler={() => setActiveGameLeadersTab(GAME_LEADERS_TABS.POINTS)}
        activeReboundsStatus={selectedTab === GAME_LEADERS_TABS.REBOUNDS}
        reboundsClickHandler={() => setActiveGameLeadersTab(GAME_LEADERS_TABS.REBOUNDS)}
        activeAssistsStatus={selectedTab === GAME_LEADERS_TABS.ASSISTS}
        assistsClickHandler={() => setActiveGameLeadersTab(GAME_LEADERS_TABS.ASSISTS)}
      />
      <GameLeadersContent
        awayTeamImage={awayTeamImage}
        homeTeamImage={homeTeamImage}
        awayPlayerStats={awayGameLeaderStats}
        homePlayerStats={homeGameLeaderStats}
      />
      <ViewStats>
        <ViewStatsItem
          onClick={() => setStatsTab('player_stats')}
        >
          View Player Stats
        </ViewStatsItem>
      </ViewStats>
    </SummaryTeamStats>
  );
};

export default GameLeadersStats;
  