import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import PageContent from './PageContent';
import SquareGrid from '../Grids/SquareGrid';
import { colors } from '../../styles';
import RectangleIcon from '../../assets/RectangleSmall.png';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import styled from 'styled-components';
import {
  seasonFormatDisplay,
  getLeagueSeasonLabelFormat,
} from '../../utils/seasonsUtil';
import {
  SearchMenuDiv,
  SearchMenuMainCriteria,
  SearchMenuSubCriteria,
  SearchMenuSubCriteriaDark,
  getHighlightedText,
} from '../../utils/searchUtils';
import InfiniteScrollList from '../InfiniteListScroll';

const imageHeight = 75;
const imageContainerHeight = 95;
const cardHeight = 255;
const cardWidth = 275;

const SeasonDateLabel = styled.div`
  text-align: left;
  font: normal normal normal 10px/20px Inter;
  letter-spacing: 0px;
  color: #5a5a5a;
  opacity: 1;
`;

const SeasonDate = styled.div`
  text-align: center;
  font: normal normal bold 12px/12px Inter;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
  align-items: flex-end;
  justify-content: center;
`;

const SeasonAbbreviation = styled.div`
  text-align: center;
  font: normal normal bold 18px/16px Open Sans;
  font-family: Open Sans;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #111111;
  padding-left: 7px;
  padding-right: 7px;
  opacity: 1;
  width: auto;
`;

const SeasonTypeAndYear = styled.div`
  text-align: center;
  font-family: Barlow;
  font-weight: medium;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SeasonLeague = styled.div`
  text-align: center;
  // font: normal normal 600 20px/25px Barlow Condensed;
  font-family: Barlow Condensed;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Line = styled.div`
  width: 22px;
  align-self: center;
  margin-left: 0px;
  margin-right: 0px;
  height: 2px;
  background-color: #25a9e1;
  opacity: 0.5;
`;

// #f5f5f5
const LeagueSeasonAbbrContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: #f5f5f5;
  &.selected {
    background-color: #ddeef5;
  }
`;

const imageContainerStyle = {
  height: imageContainerHeight,
  width: '100%',
  margin: 0,
};

const imageContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 35,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const dateStyle = {
  height: 70,
  width: '100%',
  margin: 0,
  color: '#434343',
};

const leagueStyle = {
  height: 40,
  width: '100%',
  margin: 0,
  position: 'relative',
};

const seasonItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const rectangleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'absolute',
  top: 70,
  margin: 0,
  width: '100%',
};

const SeasonItem = ({
  id,
  seasonType,
  seasonStartDate,
  seasonEndDate,
  leagueName,
  leagueImage,
  leagueAbbreviation,
  editSeasonCtrl,
  selected,
  onClickedItemHook,
}) => {
  return (
    <SquareGrid
      key={id}
      bgColor={colors.WHITE[100]}
      borderColor={colors.GRAY[150]}
      height={cardHeight}
      width={cardWidth}
      selected={selected}
      onClick={() => {
        onClickedItemHook && onClickedItemHook(id);
      }}
    >
      <Row style={imageContainerStyle}>
        <Col style={imageContentStyle} xl={12} md={12} xs={12}>
          <img
            src={leagueImage}
            style={imageStyle}
            width="100px"
            height={`${imageHeight}px`}
            alt=""
          />
        </Col>
      </Row>

      <Row style={dateStyle}>
        <Col style={seasonItemStyle} xl={6} md={6} xs={6}>
          <SeasonDateLabel>Start Date</SeasonDateLabel>
          <SeasonDate>
            {moment(seasonStartDate).format('MM/DD/YYYY')}
          </SeasonDate>
        </Col>
        <Col style={seasonItemStyle} xl={6} md={6} xs={6}>
          <SeasonDateLabel>End Date</SeasonDateLabel>
          <SeasonDate>{moment(seasonEndDate).format('MM/DD/YYYY')}</SeasonDate>
        </Col>
      </Row>

      <Row style={leagueStyle}>
        <LeagueSeasonAbbrContainer
          className={selected ? 'selected' : ''}
          xs={12}
        >
          <Row className="d-flex">
            <Line />
            <SeasonAbbreviation>{leagueAbbreviation}</SeasonAbbreviation>
            <Line />
          </Row>
          <SeasonLeague>{leagueName}</SeasonLeague>
          {moment(seasonStartDate).format('YY') ===
          moment(seasonEndDate).format('YY') ? (
            <SeasonTypeAndYear>{`${moment(seasonStartDate).format(
              'YYYY'
            )} ${seasonType}`}</SeasonTypeAndYear>
          ) : (
            <SeasonTypeAndYear>{`${moment(seasonStartDate).format(
              'YYYY'
            )}-${moment(seasonEndDate).format(
              'YY'
            )} ${seasonType}`}</SeasonTypeAndYear>
          )}
        </LeagueSeasonAbbrContainer>
        <div style={rectangleStyle}>
          <img src={RectangleIcon} alt="League" />
        </div>
      </Row>
      {editSeasonCtrl && (
        <OptionsOverflowMenu
          menuItems={[{ label: 'Edit', onClick: () => editSeasonCtrl(id) }]}
        />
      )}
    </SquareGrid>
  );
};

const SeasonContent = ({
  searchId,
  data,
  placeholder,
  handleEditModalOpenHook,
  onClickedItemHook,
  searchControlStyle,
  selectedIds,
  fetchNextPage,
  hasNextPage,
  isFetching,
}) => {
  const [displayedSeasons, setDisplayedSeasons] = useState([]);
  const [seasonSearch, setSeasonSearch] = useState('');
  const searchFilter = (option, search) =>
    seasonFormatDisplay(option).toLowerCase().includes(search.toLowerCase()) ||
    option.league.abbreviation.toLowerCase().includes(search.toLowerCase()) ||
    (moment(option.startDate).format('MM/DD/YYYY')).toLowerCase().includes(search.toLowerCase()) ||
    (moment(option.endDate).format('MM/DD/YYYY')).toLowerCase().includes(search.toLowerCase()) ||
    option.league.name.toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (seasonSearch) {
      /** Check if the input is an object or a search string */
      if (typeof seasonSearch === 'object') {
        /** Object Selection */
        setDisplayedSeasons([seasonSearch]);
      } else if (seasonSearch.length > 0) {
        /** Search String */
        setDisplayedSeasons(
          data.filter((option) => searchFilter(option, seasonSearch))
        );
      }
    } else {
      /** Display ALL Seasons */
      setDisplayedSeasons(data);
    }
  }, [seasonSearch, data]);

  const getDisplayedData = displayedSeasons => {
    return (
      <>
        {displayedSeasons?.map(
          ({ 
            id, 
            seasonType, 
            startDate, 
            endDate, 
            league, 
            selected, 
          }) => {
            const { name, abbreviation, image } = league;
            return (
              <SeasonItem
                key={id}
                id={id}
                seasonType={seasonType}
                seasonStartDate={startDate}
                seasonEndDate={endDate}
                leagueName={name}
                leagueAbbreviation={abbreviation}
                leagueImage={image}
                editSeasonCtrl={handleEditModalOpenHook}
                onClickedItemHook={onClickedItemHook}
                selected={selectedIds && selectedIds.includes(id)}
              />
            );
          }
        )}
      </>
    );
  };

  return (
    <PageContent
      searchId={searchId}
      data={data}
      dataProperty={(option) => getLeagueSeasonLabelFormat(option)}
      placeholder={placeholder}
      filterBy={(option, props) => searchFilter(option, props.text)}
      onChange={setSeasonSearch}
      searchControlStyle={searchControlStyle}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuDiv>
            <SearchMenuMainCriteria>
              {getHighlightedText(seasonFormatDisplay(option), props.text)}
            </SearchMenuMainCriteria>
            <SearchMenuSubCriteriaDark>
              {getHighlightedText(option.league.name, props.text)} (
              {getHighlightedText(option.league.abbreviation, props.text)})
            </SearchMenuSubCriteriaDark>
            <SearchMenuSubCriteria>
              {getHighlightedText(moment(option.startDate).format('MM/DD/YYYY'), props.text)} -
              {getHighlightedText(moment(option.endDate).format('MM/DD/YYYY'), props.text)}
            </SearchMenuSubCriteria>
          </SearchMenuDiv>
        );
      }}
    >
      <InfiniteScrollList
        displayedData={displayedSeasons}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        displayedDataFilter={() => getDisplayedData(displayedSeasons)}
      />
    </PageContent>
  );
};

export default SeasonContent;
