import { useCallback, useEffect, useRef, useState } from 'react';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import isEqual from 'lodash.isequal';
import {
  useDerivedGameLineupDataQuery,
  useGetGameQuery,
} from '../../api/gamesQueries';
import { Event } from '../../models';
import {
  getGameEventText,
  getGameEventActionType,
  getColorFromEvent,
  getTextColorFromEvent,
} from './controller/gameEvents';
import { formatGameTimestamp } from '../../utils/gameTimeUtil';
import ClockManagerEvents, {
  ClockEvents,
} from '../ClockManager/ClockManagerEvents';
import { shortenFullName } from '../../utils/gameLineupUtils';

const log = require('../../logger')('usePlayByPlay', 'info');

/* TODO: This recursive search is probably not the most performative way of
 * finding the object with the parent id. It might be better to maintain a
 * list of references to all play/event object and do a 1-D search */
function updateSubPlays(map, updatedSubPlay) {
  var hasParentBeenFound = false;
  for (const play of map.values()) {
    var parentPlay = play;
    while (parentPlay) {
      if (parentPlay.id === updatedSubPlay.relatedEventId) {
        hasParentBeenFound = true;
        if (isEqual(parentPlay.subPlay, updatedSubPlay)) {
          /* No change to the event object */
        } else {
          /* Change to the event object */
          /** By extracting the existing sub-play we fix the issue 
          with the "jiggle" occuring on the play feed, where the parent id is 
          updated and removes the child until the child is then also updated. 
          TODO: WILL THIS BREAK WHEN EDIT/DELETE ARE IMPLEMENTED? */
          const childSubPlay = parentPlay.subPlay?.subPlay;
          parentPlay.subPlay = {...updatedSubPlay, subPlay: childSubPlay};
        }
        break;
      } else {
        parentPlay = parentPlay.subPlay;
      }
    }
  }
  if (!hasParentBeenFound)
    log.warn('Play Map does NOT have relatedEventId for parent', map, updatedSubPlay);
}

const updateMap = (map, key, value) => {
  if (value.relatedEventId) {
    /** Sub-Play */
    if (map.has(value.relatedEventId)) {
      /** Does the parent ID exist in the top level of the map (this will be the case for *most* events) */
      const parentPlay = map.get(value.relatedEventId);
      return new Map(map).set(value.relatedEventId, {
        ...parentPlay,
        subPlay: value,
      });
    } else {
      updateSubPlays(map, value);
    }
  } else {
    /** Parent-Play */
    if (!map.has(key)) {
      /* Key does not exist in map */
      log.debug('Play Map', 'New Object');
      return new Map(map).set(key, value);
    } else {
      /* Key exists in map */
      if (isEqual(map.get(key), value)) {
        /* No change to the event object */
        log.debug('Play Map', 'Object not updated');
      } else {
        /* Change to the event object */
        log.debug('Play Map', 'Object updated');
        /** By extracting the existing sub-play we fix the issue 
        with the "jiggle" occuring on the play feed, where the parent id is 
        updated and removes the child until the child is then also updated. 
        TODO: WILL THIS BREAK WHEN EDIT/DELETE ARE IMPLEMENTED? */
        const subPlay = map.get(key)?.subPlay;
        return new Map(map).set(key, { ...value, subPlay: subPlay });
      }
    }
  }

  return map;
};

/**
 * We need to take the data stored in each Event db object and convert it into the
 * properties used by the play feed items
 */
function convertEventToPlay(event, lineupData, homeTeamId, awayTeamId) {
  const play = {
    timestamp: '0:00',
    gamePeriodNumber: event.gamePeriodNumber,
    gameOvertimeNumber: event.gameOvertimeNumber,
    /** Define the action (string) and action type (enum with color) for the game event type */
    action: getGameEventText(event),
    actionType: getGameEventActionType(event),
    logo: '',
    userInitials: '',
    homeTeamScore: '0',
    awayTeamScore: '0',
    relatedEventId: event?.relatedEventId,
    courtLocation: '',
    isSubstitution: event.eventType === ClockManagerEvents.SUBSTITUTION,
    color: getColorFromEvent(event),
    textColor: getTextColorFromEvent(event),
    id: event.id,
    eventType: event.eventType,
    isEdited: event.isEdited,
    event,
  };

  function shouldShowTeamLogo(event) {
    return !ClockEvents.includes(event.eventType);
  }

  /** Special Case for Violation which uses 2 fields to determine the play text */
  // if (event.eventType === 'VIOLATION') {
  //   play.action = getGameEventText(event.playType);
  //   play.actionType = getGameEventActionType(event.playType);
  // }

  /** Add Timeout details to action */
  if (event.eventType === 'TIMEOUT' && event.playType) {
    play.action += ` (${event.playType})`;
  }

  /** Look for the playerId in the home or away lineup map based on the event teamId */
  if (event.gameLineupPlayerId) {
    let player = null;
    if (homeTeamId === event.teamId) {
      player = lineupData?.homeTeamLineupMap?.get(event.gameLineupPlayerId);
    } else if (awayTeamId === event.teamId) {
      player = lineupData?.awayTeamLineupMap?.get(event.gameLineupPlayerId);
    } else {
      log.warn(
        `Event object for this game contains an invalid teamID (${event.teamId}), not corresponding to the home (${homeTeamId}) or away (${awayTeamId})`,
        event
      );
    }

    /** If the player is not defined (test data, or missing event fields), just use default data */
    if (!!player) {
      /** Format the player name as the first initial and last name (F. Last) */
      play.playerName = shortenFullName(
        player.playerFirstName,
        player.playerLastName
      );
      play.playerNumber = player.playerJerseyNumber;
      play.playerOnCourtBenchStatus =
        play.isSubstitution && event.playType
          ? event.playType.toLowerCase()
          : player.playerOnCourtBenchStatus;
    }
  }
  /** Define the timestamp for Quarter as the minutes and seconds (2:10) */
  if (event.gamePeriodMinutes != null && event.gamePeriodSeconds != null) {
    play.timestamp = formatGameTimestamp(
      event.gamePeriodMinutes,
      event.gamePeriodSeconds
    );
  } else if (
    event.gameOvertimeMinutes != null &&
    event.gameOvertimeSeconds != null
  ) {
    play.timestamp = formatGameTimestamp(
      event.gameOvertimeMinutes,
      event.gameOvertimeSeconds
    );
  }

  if (shouldShowTeamLogo(event)) play.teamId = event.teamId;

  play.userInitials =
    event.statcollFirstName.charAt(0) + event.statcollLastName.charAt(0);

  return play;
}

/**
 * Custom hook that returns a Map of Play-by-Play events for a given gameId and
 * filtered by the provided eventFilterFn (optional but recommended).
 *
 * @param {*} gameId
 * @param {*} eventFilterFn Function to determine whether an event should be shown.
 *                          If omitted, all events are displayed.
 * @returns Map of Play-by-Play events (Unique events exclude children events with a
 * relatedEventId prop)
 */
function usePlayByPlay(gameId, eventFilterFn = () => true) {
  /** Event List for Play-by-Play (Unique events exclude children events with a relatedEventId prop)*/
  const [playMap, setPlayMap] = useState(new Map());
  const [playsLoaded, setPlaysLoaded] = useState(false);

  const { data: gameData } = useGetGameQuery(gameId);
  const homeTeamId = gameData?.homeTeamId;
  const awayTeamId = gameData?.awayTeamId;

  const { data: lineupData, isSuccess } = useDerivedGameLineupDataQuery(
    gameData?.homeTeamGameLineupId,
    gameData?.awayTeamGameLineupId,
    homeTeamId,
    awayTeamId
  );

  useEffect(() => {
    log.debug('usePlayByPlay lineupData', lineupData);
  }, [lineupData]);

  useEffect(() => {
    log.debug('PlayMap', playMap);
  }, [playMap]);

  /** Get the list of Play-by-Play events */
  const initialPlaybyPlay = useCallback(async () => {
    const eventsData = await DataStore.query(Event, Predicates.ALL, {
      sort: (e) => e.updatedAt(SortDirection.ASCENDING),
    });

    log.debug('usePlayByPlay/initialPlaybyPlay eventsData', eventsData);

    const tempMap = new Map();
    const subPlays = [];
    for (const event of eventsData) {
      if (eventFilterFn(event)) {
        const play = convertEventToPlay(
          event,
          lineupData,
          homeTeamId,
          awayTeamId
        );
        /* Save subplays for later, since parent may not be in map yet */
        if (play.relatedEventId) subPlays.push(play);
        else tempMap.set(event.id, play);
      }
    }

    /* Add subPlays to their respective parent plays, based on relatedEventId */
    for (const subPlay of subPlays) {
      const parentId = subPlay.relatedEventId;
      if (tempMap.has(parentId)) {
        tempMap.set(parentId, { ...tempMap.get(parentId), subPlay: subPlay });
      } else {
        updateSubPlays(tempMap, subPlay);
      }
    }
    setPlayMap(tempMap);
    setPlaysLoaded(true);
  }, [eventFilterFn, lineupData, homeTeamId, awayTeamId]);

  /**
   * For debugging, keeping track of number of subscriptions
   */
  const subCounter = useRef(0);

  /** subscribe play by play updates events when new event comes */
  const subscribePlayByPlay = useCallback(() => {
    log.debug(
      'subscribePlayByPlay',
      `# of Subscriptions = ${++subCounter.current}`
    );

    return DataStore.observe(Event).subscribe((msg) => {
      if (eventFilterFn(msg.element)) {
        log.debug('Play-Feed Update', msg);
        setPlayMap((map) =>
          updateMap(
            map,
            msg.element.id,
            convertEventToPlay(msg.element, lineupData, homeTeamId, awayTeamId)
          )
        );
      }
    });
  }, [eventFilterFn, lineupData, homeTeamId, awayTeamId]);

  /**
   * Initialize the play by play window by querying for and subscribing to the event objects
   * We should only do this after the lineupdata returns because it contains the lineup maps
   * used in the event-to-play adapter method.
   */
  useEffect(() => {
    if (isSuccess && lineupData && gameData) {
      initialPlaybyPlay();
    }
  }, [gameData, initialPlaybyPlay, isSuccess, lineupData]);

  useEffect(() => {
    if (isSuccess && playsLoaded) {
      const subscription = subscribePlayByPlay();
      return () => {
        log.debug(
          'unsubbing (subscribePlayByPlay)',
          `Counter: ${--subCounter.current}` // eslint-disable-line react-hooks/exhaustive-deps
        );
        return subscription.unsubscribe();
      };
    }
  }, [subscribePlayByPlay, isSuccess, playsLoaded]);

  return { playMap, playsLoaded };
}

export default usePlayByPlay;
