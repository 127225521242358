import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { leaguesKeys, useLeaguesQuery } from '../api/leaguesQueries';
import {
  createLeague,
  getLeague, listLeagues, updateLeague
} from '../api/leaguesService';
import { useSportsQuery } from '../api/sportsQueries';
import ToastAlert from '../components/alerts/ToastAlert';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import HorizontalGrid from '../components/Grids/HorizontalGrid';
import MainLayout from '../components/layout/MainLayout';
import {
  AddEditLeagueModal,
  EditMode
} from '../components/Modals/AddEditLeagueModal';
import LeagueContent from '../components/PageContent/LeagueContent';
import Spinner from '../components/Spinner';
import { colors } from '../styles';
import { useFetchAll } from '../utils/reactQueryToolkit';
const log = require('../logger')('adminPanel', 'debug');

const LeaguesPage = () => {
  const queryClient = useQueryClient();
  const [queryFilter, setQueryFilter] = useState('');
  const onSearch = (query) => setQueryFilter(query);
  const history = useHistory();

  const [leagueId, setLeagueID] = useState();
  /** AddEditLeagueModal */
  const [leagueModalOpen, setLeagueModalOpen] = useState(false);
  const [leagueEditMode, setEditMode] = useState(EditMode.CREATE);
  const [errorMessage, setErrorMessage] = useState('');
  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const [leagueFormData, setLeagueFormData] = useState({
    /** Empty Data */
    name: '',
    abbreviation: '',
    sport: { id: '' },
    teamSize: '',
    numPlayersOnCourt: '',
    numPlayersOnBench: '',
    numTimeOuts: '',
    numFoulsPerPlayer: '',
    numPeriods: '',
    timePerPeriodInMins: '',
    overtimeDurationInMins: '',
    image: null,
    acceptedTerms: false,
    /** Test Data */
    // name: 'National Basketball Association',
    // abbreviation: 'NBA',
    // sport: { id: '91e7191b-68b3-4be7-a354-495e9b2f9e4f' },
    // teamSize: 15,
    // numPlayersOnCourt: 5,
    // numPlayersOnBench: 8,
    // numTimeOuts: 7,
    // numFoulsPerPlayer: 600,
    // numPeriods: 4,
    // timePerPeriodInMins: 12,
    // overtimeDurationInMins: 5,
    // image: null,
    // acceptedTerms: false,
  });

  const { onSuccessCallback } = useFetchAll(true);
  const sportsQuery = useSportsQuery({
    onSuccess: (data) => onSuccessCallback(sportsQuery)(data),
  });
  const sports = sportsQuery.data;

  useEffect(() => {
    if (!leagueFormData.sport.id && sportsQuery.isSuccess) {
      const basketball = sports.find((sport) => sport.name === 'Basketball');
      if (basketball)
        setLeagueFormData((prev) => ({ ...prev, sport: basketball }));
    }
  }, [leagueFormData, sportsQuery.isSuccess, sports]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isSuccess,
  } = useLeaguesQuery({
    filter: queryFilter,
    onError: (error) => {
      log.error(error);
      setShowToast(true);
      setToastText('An error occurred while retrieving leagues.');
      setToastVariant('danger');
    },
  });

  const onModalSubmit = async (values) => {
    /**
     * Create or Edit
     */
    let returnData;
    if (leagueEditMode === EditMode.CREATE) {
      values.sportId = values.sport.id;
      const playersOnBench = values.teamSize - values.numPlayersOnCourt;
      values.numPlayersOnBench = playersOnBench;
      returnData = await createLeague(values);
    } else if (leagueEditMode === EditMode.EDIT) {
      returnData = await updateLeague(leagueId, values);
    }
    /* If League Create/Update failed show an error, else close the modal */
    if (returnData.errors) {
      setErrorMessage(
        `Unable to create league. ${returnData.errors[0].message}.`
      );
    } else {
      /**
       * Success
       * Clear error, update list, close model, show toast, reset form
       */
      setErrorMessage(null);
      queryClient.invalidateQueries(leaguesKeys.all);

      setLeagueModalOpen(false);
      setToastText(
        `The League "${values.name} (${values.abbreviation})" was created successfully.`
      );
      setToastVariant('success');
      setShowToast(true);
    }
  };

  const onModalClose = () => {
    setErrorMessage(null);
    setLeagueModalOpen(false);
  };

  const handleView = async (leagueId) => {
    history.push(`/admin/leagues/${leagueId}`);
  };

  const handleCreate = () => {
    setEditMode(EditMode.CREATE);
    setLeagueModalOpen(true);
  };

  const handleEdit = async (leagueId) => {
    /* Put form in Edit Mode */
    setEditMode(EditMode.EDIT);
    /* Get League object for Row */
    const league = await getLeague(leagueId);
    setLeagueFormData(league);
    setLeagueID(league.id);
    /* Open Modal */
    setLeagueModalOpen(true);
  };

  return (
    <MainLayout
      title="Leagues"
      buttonLabel="+ Add League"
      buttonHandler={handleCreate}
    >
      {isLoading && <Spinner />}

      {isSuccess && data.length === 0 && (
        <HorizontalGrid
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          height={'342px'}
          width={'512px'}
        >
          <AddCircleButton
            buttonLabel="Add League"
            buttonClickHandler={handleCreate}
          />
        </HorizontalGrid>
      )}

      <LeagueContent
        searchId="__leagueId"
        placeholder="Search Leagues"
        data={data}
        openEditModalHook={handleEdit}
        openViewModalHook={handleView}
        fetchNextPage={() => fetchNextPage()}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        onSearch={onSearch}
      />

      <AddEditLeagueModal
        editMode={leagueEditMode}
        modalOpen={leagueModalOpen}
        setModalOpen={setLeagueModalOpen}
        onClose={onModalClose}
        onSubmit={onModalSubmit}
        sports={sports}
        initialValues={leagueFormData}
        error={errorMessage}
        setError={setErrorMessage}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default LeaguesPage;
