import { Hub } from 'aws-amplify';
const log = require('../logger')('info');

export function amplifyAuthStateListener(
  onTokenRefreshFailure,
  onUserSignedOut
) {
  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        log.info('Auth: User signed in');
        break;
      case 'signUp':
        log.info('Auth: user signed up');
        break;
      case 'signOut':
        log.info('Auth: user signed out');
        onUserSignedOut();
        break;
      case 'signIn_failure':
        log.error('Auth: user sign in failed');
        break;
      case 'tokenRefresh':
        log.info('Auth: token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        log.error('Auth: token refresh failed');
        onTokenRefreshFailure();
        break;
      case 'autoSignIn':
        log.info('Auth: Auto Sign In after Sign Up succeeded');
        break;
      case 'autoSignIn_failure':
        log.error('Auth: Auto Sign In after Sign Up failed');
        break;
      case 'configured':
        log.info('Auth: the Auth module is configured');
    }
  };

  Hub.listen('auth', listener);
}
