import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DownwardChevron from '../../assets/downwardChevron@2x.png';
import WinnerTrophy from '../../assets/trophy@2x.png';
import UpwardChevron from '../../assets/upwardChevron@2x.png';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import { CLOCKMANAGER, ROLE_COLOR, SCOREKEEPER } from '../../data/roles';
import {
  selectHomeShowGameUsers
} from '../../redux/homeShowGameUsersSlice';
import { colors, GameRoleSpan, RoleItem } from '../../styles';
import { UserGameRole } from '../../styles/users';
import { GAME_STATUS } from '../../utils/constantsUtils';
import { getFormattedDateCasedWithDaySuffixedSuperScript } from '../../utils/momentDateUtil';
import {
  addScrollListClass,
  isMobile,
  onListScrollHandler
} from '../../utils/scrollUtil';
import { seasonFormatDisplayCondensed } from '../../utils/seasonsUtil';
import GameStatus from '../GameStatus';
import SquareGrid from '../Grids/SquareGrid';

const cardHeight = 315;
const cardWidth = 300;
const leagueContainerHeight = 60;
const cardTeamImageHeight = 58;
const cardTeamNameFontSize = 17;
const cardVersusCircleSize = 40;
const cardVersusTextFontSize = 21;
const cardMarginTop = 30;
const cardDateFontSize = 16;
const cardVenueFontSize = 18;
const cardHomeAwayTeamHeight = 100;
const cardHomeAwayTeamMarginTop = 15;
const cardHomeAwayTeamMarginBottom = 8;
const cardHomeAwayTeamMinHeight = 19;

const GAME_USER_ROLE_CONTAINER_HEIGHT = 40;

const gameItemRow = {
  width: '100%',
};

const DateColumn = styled(Col)`
  border-bottom: 2px solid #3a3a3a;
  padding-bottom: ${(props) => props.$paddingBottom || '8px'};
  padding-left: 2px;
  padding-right: 2px;
`;

const LiveIndicator = styled.div`
  position: absolute;
  background-color: #e20102;
  color: #ffffff;
  top: 7px;
  left: 7px;
  padding: 0.3em 0.5em;
  border-radius: 3px;
  text-transform: uppercase;
  font: normal normal bold 0.625em/1em Inter;
  letter-spacing: 0px;

  &:before {
    content: '•';
    font-size: 1.8em;
    margin-right: 2px;
    vertical-align: text-top;
    vertical-align: -15%;
  }
`;

const LiveIndicatorWithRole = styled.div`
  position: absolute;
  background-color: #e20102;
  color: #ffffff;
  top: 2px;
  z-index: 5;
  left: 3px;
  padding: 0.3em 0.5em;
  border-radius: 3px;
  text-transform: uppercase;
  font: normal normal bold 0.625em/1em Inter;
  letter-spacing: 0px;
  &:before {
    content: '•';
    font-size: 1.8em;
    margin-right: 2px;
    vertical-align: text-top;
    vertical-align: -15%;
  }
`;

const GameScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const GameScoreRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
`;

const GameWinnerDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`;

const TeamScoreDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`;

const ScoreDisplay = styled.div`
  width: 90%;
  height: 90%;
  background-color: ${(props) => props.backgroundColor};
  font: normal normal 600 20px/14px Barlow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.WHITE[100]};
  textalign: ${(props) => props.textAlign};
  border-top-left-radius: ${(props) => props.topLeftRadius}px;
  border-bottom-left-radius: ${(props) => props.bottomRLeftRadius}px;
  border-top-right-radius: ${(props) => props.topRightRadius}px;
  border-bottom-right-radius: ${(props) => props.bottomRightRadius}px;
`;

const itemLayoutStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

const dateTimeStyle = {
  height: 20,
  font: 'normal normal 600 16px/14px Barlow Condensed',
  textAlign: 'center',
  color: '#111111',
};

const venueNameStyle = {
  height: 25,
  font: 'normal normal 600 18px/14px Barlow Condensed',
  color: '#111111',
};

const venueStateCityStyle = {
  paddingRight: '50px',
  paddingLeft: '50px',
  font: 'normal normal normal 9px/12px Inter',
  color: '#585858',
};

const venueCityStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  wordBreak: 'break-word',
};

const teamContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const versusStyle = {
  backgroundColor: '#111111',
  font: 'normal normal 600 21px/14px Barlow Condensed',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const teamStyle = {
  width: '100%',
  marginBottom: 5,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const teamTextStyle = {
  width: '100%',
  height: 75,
  minHeight: 19,
  font: 'normal normal 600 17px/19px Barlow Condensed',
  color: '#111111',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '3px',
  paddingLeft: '3px',
  // display: 'flex',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // textAlign: 'center',
  textAlign: 'center',
  wordBreak: 'break-word',
};

const homeAwayTextStyle = {
  width: '100%',
  height: 18,
  font: 'normal normal normal 9px/18px Inter',
  color: '#585858',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const LeagueContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => props.height || leagueContainerHeight}px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f5f5f5;
  &.selected {
    background-color: #ddeef5;
  }
`;

const AbbreviationText = styled.div`
  text-align: center;
  font-family: Barlow Condensed;
  font-weight: bold;
  font-size: ${(props) => props.fontSize || 14}px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #3a3a3a;
  padding-left: 7px;
  padding-right: 7px;
  opacity: 1;
  width: auto;
`;

const Line = styled.div`
  width: 22px;
  align-self: center;
  margin-left: 0px;
  margin-right: 0px;
  height: 2px;
  border-radius: 2px;
  background-color: #25a9e1;
  opacity: 0.5;
`;

const LeagueText = styled.div`
  width: 90%;
  text-align: center;
  font-family: Barlow Condensed;
  font-weight: 600;
  font-size: ${(props) => props.fontSize || 17}px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const seasonDisplay = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'absolute',
  top: -10,
};

const seasonContainerStyle = {
  height: 15,
  width: 'auto',
  backgroundColor: '#3A3A3A',
  paddingLeft: 12,
  paddingRight: 12,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'row',
  font: 'normal normal normal 7px/8px Inter',
  color: '#FFFFFF',
  alignItems: 'center',
};

const gameUserHeaderStyle = {
  width: '100%',
  height: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'normal normal bold 10px/12px Inter',
  color: '#5D5D5D',
};

const gameUsersContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 5,
};

const chevronImageStyle = {
  width: '100%',
  height: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const cardGameUserRolesStyle = {
  width: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  alignContent: 'baseline',
};

const cardItemAnimateTransitionStyle = {
  transition: 'height 1s ease',
};

const UserRoleItem = ({ name, gameRole, userId, history }) => {
  return (
    <UserGameRole
      gameRole={gameRole}
      roleColor={ROLE_COLOR[gameRole.gameRole]}
      onClick={(event) => {
        history.push(`/admin/user/profile/${userId}`);
        event.stopPropagation();
      }}
    >
      {name}
    </UserGameRole>
  );
};

const TeamWinner = ({ didTeamWin }) => {
  return (
    <GameWinnerDisplay>
      {didTeamWin && (
        <img src={WinnerTrophy} width="30px" height="30px" alt="" />
      )}
    </GameWinnerDisplay>
  );
};

const TeamScore = ({
  score,
  opponentScore,
  gameStatus,
  textAlign,
  topLeftRadius,
  bottomRLeftRadius,
  topRightRadius,
  bottomRightRadius,
}) => {
  return (
    <TeamScoreDisplay>
      <ScoreDisplay
        textAlign={textAlign}
        topLeftRadius={topLeftRadius}
        bottomRLeftRadius={bottomRLeftRadius}
        topRightRadius={topRightRadius}
        bottomRightRadius={bottomRightRadius}
        backgroundColor={
          gameStatus === GAME_STATUS.CANCELED
            ? '#B50000'
            : score === opponentScore && gameStatus !== GAME_STATUS.LIVE
            ? '#2B92BE'
            : colors.BLACK[100]
        }
      >
        {score}
      </ScoreDisplay>
    </TeamScoreDisplay>
  );
};

const TeamLogoImage = ({ imgSrc, didTeamWin, gameStatus, isStatsPage }) => {
  const getImageOpacity = (isStatsPage, gameStatus, didTeamWin) => {
    if (isStatsPage && gameStatus === GAME_STATUS.ENDED) {
      return didTeamWin ? 1 : 0.7;
    }

    return 1;
  };

  const getImageBlendMode = (isStatsPage, gameStatus, didTeamWin) => {
    if (isStatsPage && gameStatus === GAME_STATUS.ENDED) {
      return didTeamWin ? 'normal' : 'luminosity';
    }

    return 'normal';
  };

  return (
    <img
      src={imgSrc}
      style={{
        ...imageStyle,
        opacity: getImageOpacity(isStatsPage, gameStatus, didTeamWin),
        mixBlendMode: getImageBlendMode(isStatsPage, gameStatus, didTeamWin),
      }}
      width="100%"
      height="100%"
      alt=""
    />
  );
};

const GameContentItem = ({
  game,
  cardItemWidth = 0,
  cardItemHeight = 0,
  cardOuterMargin = 0,
  teamImageHeight = 0,
  teamTextFontSize = 0,
  teamTextLineHeight = '19px',
  versusCircleSize = 0,
  versusTextFontSize = 0,
  marginTopStyle = 0,
  dateFontSize = 0,
  datePaddingBottom = 0,
  venueFontSize = 0,
  homeAwayTeamHeight = 0,
  homeAwayTeamNameMinHeight = 0,
  homeAwayTeamMarginTop = 0,
  homeAwayTeamMarginBottom = 0,
  leagueMarginTop = 0,
  leagueHeight = 0,
  leagueNameFontSize = 0,
  leagueAbbrvFontSize = 0,
  selectedIds,
  history,
  hasChanged,
  onClickedItemHook = () => {},
  editTeamCtrl = () => {},
  navigateToGameDetailsPage = false,
  navigateToGameStatsPage = false,
  showGameUsers = false,
  cardStateHeight = 0,
  transitionProperty,
}) => {
  const [transitionCardItemHeight, setTransitionCardItemHeight] = useState(
    cardItemHeight
  );
  const [
    transitionUserRoleContainerHeight,
    setTransitionUserRoleContainerHeight,
  ] = useState(GAME_USER_ROLE_CONTAINER_HEIGHT);
  const [transitionGameUserHeight, setTransitionGameUserHeight] = useState(0);
  const [isCardHeightExpanded, setIsCardHeightExpanded] = useState();
  const [cardItemBorderColor, setCardItemBorderColor] = useState(
    colors.GRAY[150]
  );
  const [cardItemBorderWidth, setCardItemBorderWidth] = useState(1);
  const isUserGameRolesActivated = useSelector(selectHomeShowGameUsers);

  /* When the global switch is changed, update the card's expanded state */
  useEffect(() => {
    setIsCardHeightExpanded(isUserGameRolesActivated);
  }, [isUserGameRolesActivated]);
  
  useEffect(() => {
    setTransitionUserRoleContainerHeight(
      game?.gameRoles?.length > 0 && isCardHeightExpanded
        ? cardItemHeight + GAME_USER_ROLE_CONTAINER_HEIGHT
        : GAME_USER_ROLE_CONTAINER_HEIGHT
    );
    setTransitionCardItemHeight(
      game?.gameRoles?.length > 0 && isCardHeightExpanded
        ? cardItemHeight + cardStateHeight
        : cardItemHeight
    );
    setTransitionGameUserHeight(
      game?.gameRoles?.length > 0 && isCardHeightExpanded ? cardStateHeight : 0
    );
  }, [isCardHeightExpanded]);

  const gameItemRef = useRef();
  const listRef = useRef();
  const _isMobile = useRef(isMobile());

  useEffect(() => {
    if (!_isMobile.current) {
      addScrollListClass(listRef.current);
    }
  }, []);

  useEffect(() => {
    if (hasChanged) {
      gameItemRef.current.scrollIntoView({ behavior: 'smooth' });
      setCardItemBorderWidth(2);
      setCardItemBorderColor(colors.BLUE[300]);
    } else {
      setCardItemBorderWidth(1);
      setCardItemBorderColor(colors.GRAY[150]);
    }
  }, [hasChanged]);

  const {
    id,
    gameDateTime,
    gameStatus,
    awayTeamName,
    awayTeamImage,
    homeTeamName,
    homeTeamImage,
    leagueName,
    leagueAbbreviation,
    seasonStartDate,
    seasonEndDate,
    seasonType,
    venueName,
    venueCity,
    venueState,
    role /* Optional: Role of the GameUser object this game is accociated with */,
    gameUserId /* Optional: ID of the GameUser object this game is accociated with */,
    homeTeamScore,
    awayTeamScore,
  } = game;

  const formatDate = (datetime) =>
    getFormattedDateCasedWithDaySuffixedSuperScript(
      moment(datetime).startOf('d'),
      'MMMM Do YYYY'
    );
  const formatTime = (datetime) => moment(datetime).format('h:mm A');

  const gameDate = formatDate(gameDateTime);
  const gameTime = formatTime(gameDateTime);
  const seasonFormat = seasonFormatDisplayCondensed({
    seasonType,
    startDate: seasonStartDate,
    endDate: seasonEndDate,
  });
  const selected = selectedIds && selectedIds.includes(gameUserId);
  const versusSize = versusCircleSize || cardVersusCircleSize;
  const versusSizeRadius = versusSize / 2;

  const onActivateUserGameRoles = (event) => {
    if (game?.gameRoles?.length > 0) {
      setIsCardHeightExpanded((expanded) => !expanded);
    }
    event.stopPropagation();
  };

  return (
    <SquareGrid
      key={gameUserId ? gameUserId : id}
      bgColor={colors.WHITE[100]}
      borderColor={cardItemBorderColor}
      borderWidth={cardItemBorderWidth}
      height={transitionCardItemHeight || cardHeight}
      width={cardItemWidth || cardWidth}
      selected={selected}
      margin={cardOuterMargin}
      transitionProperty={transitionProperty}
      onClick={() => {
        if (navigateToGameDetailsPage) {
          history && history.push(`/admin/game/details/${id}`);
        } else if (navigateToGameStatsPage) {
          history && history.push(`/gameStats/play-by-play/${id}`);
        } else {
          onClickedItemHook && onClickedItemHook(gameUserId ? gameUserId : id);
        }
      }}
    >
      {gameStatus === GAME_STATUS.LIVE && role == null && (
        <LiveIndicator
          style={{ fontSize: 0.625 * (dateFontSize || cardDateFontSize) }}
        >
          Live
        </LiveIndicator>
      )}
      {gameStatus === GAME_STATUS.LIVE && role !== undefined && (
        <LiveIndicatorWithRole
          style={{ fontSize: 0.625 * (dateFontSize || cardDateFontSize) }}
        >
          Live
        </LiveIndicatorWithRole>
      )}
      {role === SCOREKEEPER && (
        <RoleItem
          $cardItemWidth={cardItemWidth || cardWidth}
          className={selected ? 'selected' : ''}
          $roleColor={ROLE_COLOR[SCOREKEEPER]}
        >
          <GameRoleSpan>Score Keeper</GameRoleSpan>
        </RoleItem>
      )}
      {role === CLOCKMANAGER && (
        <RoleItem
          $cardItemWidth={cardItemWidth || cardWidth}
          className={selected ? 'selected' : ''}
          $roleColor={ROLE_COLOR[CLOCKMANAGER]}
        >
          <GameRoleSpan>Clock Manager</GameRoleSpan>
        </RoleItem>
      )}
      <div
        ref={gameItemRef}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: role ? 15 : 0,
          scrollMargin: 10,
        }}
      >
        <Row
          xs={12}
          style={{ ...gameItemRow, position: 'relative', display: 'contents' }}
        >
          <DateColumn xs={'auto'} $paddingBottom={datePaddingBottom}>
            <div
              style={{
                ...itemLayoutStyle,
                ...dateTimeStyle,
                marginTop: marginTopStyle || cardMarginTop,
                fontSize: dateFontSize || cardDateFontSize,
              }}
            >
              <span>{gameDate}</span>
              <span style={{ color: '#BCBCBC', fontFamily: 'auto' }}>
                &nbsp;|&nbsp;
              </span>
              <span>{gameTime}</span>
            </div>
          </DateColumn>
        </Row>

        <Row style={{ ...gameItemRow, marginTop: venueFontSize ? 0 : 5 }}>
          <Col xs={12}>
            <div
              style={{
                ...itemLayoutStyle,
                ...venueNameStyle,
                fontSize: venueFontSize || cardVenueFontSize,
              }}
            >
              <LeagueText>{venueName}</LeagueText>
            </div>
            <div style={{ ...itemLayoutStyle, ...venueStateCityStyle }}>
              <div style={{ ...venueCityStyle }}>{venueCity}</div>
              <span>,</span>&nbsp;
              <div>{venueState}</div>
            </div>
          </Col>
        </Row>

        <Row style={gameItemRow}>
          <Col xs={12}>
            <div
              style={{
                ...itemLayoutStyle,
                height: homeAwayTeamHeight || cardHomeAwayTeamHeight,
                marginTop: homeAwayTeamMarginTop || cardHomeAwayTeamMarginTop,
                marginBottom:
                  homeAwayTeamMarginBottom || cardHomeAwayTeamMarginBottom,
              }}
            >
              <div
                style={{
                  ...teamContainerStyle,
                  width: game.isStatsPage && gameStatus != null ? '30%' : '40%',
                }}
              >
                <div
                  style={{
                    ...teamStyle,
                    height: teamImageHeight || cardTeamImageHeight,
                  }}
                >
                  <TeamLogoImage
                    imgSrc={homeTeamImage}
                    didTeamWin={
                      homeTeamScore > awayTeamScore ||
                      homeTeamScore === awayTeamScore
                    }
                    gameStatus={gameStatus}
                    isStatsPage={gameStatus != null && game.isStatsPage}
                  />
                </div>
                <div
                  style={{
                    ...teamTextStyle,
                    fontSize: teamTextFontSize || cardTeamNameFontSize,
                    lineHeight: teamTextLineHeight,
                    minHeight:
                      homeAwayTeamNameMinHeight || cardHomeAwayTeamMinHeight,
                  }}
                >
                  {homeTeamName.split(' ')[0]}
                </div>
                <div
                  style={{
                    ...teamTextStyle,
                    fontSize: teamTextFontSize || cardTeamNameFontSize,
                    lineHeight: teamTextLineHeight,
                    minHeight:
                      homeAwayTeamNameMinHeight || cardHomeAwayTeamMinHeight,
                  }}
                >
                  {homeTeamName.replace(homeTeamName.split(' ')[0], '')}
                </div>
                <div style={homeAwayTextStyle}>Home</div>
              </div>

              <div
                style={{
                  ...teamContainerStyle,
                  width: game.isStatsPage && gameStatus != null ? '40%' : '20%',
                }}
              >
                {!game.isStatsPage ||
                (gameStatus !== GAME_STATUS.ENDED &&
                  gameStatus !== GAME_STATUS.CANCELED &&
                  gameStatus !== GAME_STATUS.LIVE) ? (
                  <div
                    style={{
                      ...versusStyle,
                      width: versusSize,
                      height: versusSize,
                      borderRadius: versusSizeRadius,
                      fontSize: versusTextFontSize || cardVersusTextFontSize,
                    }}
                  >
                    VS
                  </div>
                ) : (
                  <GameScoreContainer>
                    <GameScoreRow>
                      {(gameStatus === GAME_STATUS.ENDED &&
                        homeTeamScore !== awayTeamScore) ||
                      gameStatus === GAME_STATUS.LIVE ? (
                        <>
                          <TeamWinner
                            didTeamWin={
                              homeTeamScore > awayTeamScore &&
                              gameStatus !== GAME_STATUS.LIVE
                            }
                          />
                          <TeamWinner
                            didTeamWin={
                              homeTeamScore < awayTeamScore &&
                              gameStatus !== GAME_STATUS.LIVE
                            }
                          />
                        </>
                      ) : (
                        <GameStatus
                          isGameDrawn={homeTeamScore === awayTeamScore}
                          isGameCancelled={gameStatus === GAME_STATUS.CANCELED}
                          paddingLeft={
                            gameStatus === GAME_STATUS.CANCELED ? 4 : 8
                          }
                          paddingRight={
                            gameStatus === GAME_STATUS.CANCELED ? 4 : 8
                          }
                        />
                      )}
                    </GameScoreRow>
                    <GameScoreRow>
                      {[
                        GAME_STATUS.ENDED,
                        GAME_STATUS.LIVE,
                        GAME_STATUS.CANCELED,
                      ].includes(gameStatus) && (
                        <>
                          <TeamScore
                            score={homeTeamScore}
                            opponentScore={awayTeamScore}
                            gameStatus={gameStatus}
                            textAlign="left"
                            topLeftRadius={10}
                            bottomRLeftRadius={10}
                          />
                          <TeamScore
                            score={awayTeamScore}
                            opponentScore={homeTeamScore}
                            gameStatus={gameStatus}
                            textAlign="right"
                            topRightRadius={10}
                            bottomRightRadius={10}
                          />
                        </>
                      )}
                    </GameScoreRow>
                  </GameScoreContainer>
                )}
              </div>

              <div
                style={{
                  ...teamContainerStyle,
                  width: game.isStatsPage && gameStatus != null ? '30%' : '40%',
                }}
              >
                <div
                  style={{
                    ...teamStyle,
                    height: teamImageHeight || cardTeamImageHeight,
                  }}
                >
                  <TeamLogoImage
                    imgSrc={awayTeamImage}
                    didTeamWin={
                      homeTeamScore < awayTeamScore ||
                      homeTeamScore === awayTeamScore
                    }
                    gameStatus={gameStatus}
                    isStatsPage={gameStatus != null && game.isStatsPage}
                  />
                </div>
                <div
                  style={{
                    ...teamTextStyle,
                    fontSize: teamTextFontSize || cardTeamNameFontSize,
                    lineHeight: teamTextLineHeight,
                    minHeight:
                      homeAwayTeamNameMinHeight || cardHomeAwayTeamMinHeight,
                  }}
                >
                  {awayTeamName.split(' ')[0]}
                </div>
                <div
                  style={{
                    ...teamTextStyle,
                    fontSize: teamTextFontSize || cardTeamNameFontSize,
                    lineHeight: teamTextLineHeight,
                    minHeight:
                      homeAwayTeamNameMinHeight || cardHomeAwayTeamMinHeight,
                  }}
                >
                  {awayTeamName.replace(awayTeamName.split(' ')[0], '')}
                </div>
                <div style={homeAwayTextStyle}>Away</div>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            ...gameItemRow,
            position: 'relative',
            marginTop: leagueMarginTop || 12,
          }}
        >
          <LeagueContainer
            xs={12}
            className={selected ? 'selected p-0' : 'p-0'}
            height={leagueHeight}
          >
            <LeagueText fontSize={leagueNameFontSize}>{leagueName}</LeagueText>
            <Row className="d-flex">
              <Line />
              <AbbreviationText fontSize={leagueAbbrvFontSize}>
                {leagueAbbreviation}
              </AbbreviationText>
              <Line />
            </Row>
          </LeagueContainer>
          <div style={seasonDisplay}>
            <div style={seasonContainerStyle}>{seasonFormat.toUpperCase()}</div>
          </div>
        </Row>

        {showGameUsers && (
          <div
            style={{
              ...cardItemAnimateTransitionStyle,
              ...gameUsersContainerStyle,
              height: transitionUserRoleContainerHeight,
            }}
          >
            <div style={gameUserHeaderStyle}>USER ROLES</div>
            {game?.gameRoles?.length > 0 && (
              <div
                ref={listRef}
                style={{
                  ...cardItemAnimateTransitionStyle,
                  ...cardGameUserRolesStyle,
                  height: transitionGameUserHeight,
                }}
                onScroll={() => onListScrollHandler(_isMobile, listRef.current)}
              >
                {game?.gameRoles?.map(({ name, gameRole, userId, gameId }) => (
                  <UserRoleItem
                    key={`${gameId}_${userId}`}
                    name={name}
                    gameRole={gameRole}
                    userId={userId}
                    history={history}
                  />
                ))}
              </div>
            )}
            <div
              style={{
                ...chevronImageStyle,
                borderTop: isCardHeightExpanded && '1px solid #D2D2D2',
              }}
              onClick={(event) => onActivateUserGameRoles(event)}
            >
              {game?.gameRoles?.length > 0 ? (
                <img
                  src={isCardHeightExpanded ? UpwardChevron : DownwardChevron}
                  width="14px"
                  height="8px"
                  alt=""
                />
              ) : (
                <span
                  style={{
                    font: 'italic normal normal 11px/8px Inter',
                    color: '#808080',
                  }}
                >
                  No User Roles Assigned
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      {editTeamCtrl && navigateToGameDetailsPage && (
        <OptionsOverflowMenu
          menuItems={[
            {
              label: 'View',
              onClick: (e) => {
                history.push(`/admin/game/details/${id}`);
                e.stopPropagation();
              },
            },
            {
              label: 'Edit',
              disabled:
                moment(gameDateTime).isBefore(moment()) ||
                gameStatus === GAME_STATUS.LIVE,
              onClick: (e) => {
                editTeamCtrl(id);
                e.stopPropagation();
              },
            },
          ]}
        />
      )}
    </SquareGrid>
  );
};

export default GameContentItem;
