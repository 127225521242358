import { validateYupSchema, yupToFormErrors } from 'formik';

/**
 * Generates a validate callback for Formik, provided a schema and optional 
 * form-level error message handling callbacks.
 * 
 * @param {ObjectSchema} validationSchema Yup validation schema
 * @param {function(msg: string)} [errorMsgCallback] - called when a root 
 *        form-level error occurs, passing in the error message.
 * @param {function()} [clearErrorMsgCallback] - called when there is no root 
 *        form-level error msg. Useful to clear error state.
 * @returns {function} validate function to be passed to Formik's validate prop
 */
export const generateValidateCallback = (
  validationSchema,
  errorMsgCallback = () => {},
  clearErrorMsgCallback = () => {},
) => async (values) => {
  try {
    await validateYupSchema(values, validationSchema, false);
  } catch (error) {
    if (error.name !== 'ValidationError') {
      throw error;
    }

    /**
     * Check for errors at the root object level of the schema.
     * Display the first one at the top of the form.
     */
    const rootLevelError = error.inner.find((e) => e.path === '');
    if (rootLevelError) {
      errorMsgCallback(rootLevelError.message);
    } else {
      clearErrorMsgCallback();
    }

    return yupToFormErrors(error);
  }

  clearErrorMsgCallback();
  return {};
};
