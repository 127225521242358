import styled from 'styled-components';

const PlayByPlayColumn = styled.div`
  width: ${props => props.width};
  height: ${props => props.height ? `${props.height}px` : '100%'};
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center; 
  border-right: ${props => props.rightBorderWidth}px solid #DCDCDC;
  border-bottom: ${props => props.bottomBorder}px solid #DCDCDC;
  position: relative;
`;

export default PlayByPlayColumn;