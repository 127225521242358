import CourtImage2PT from '../assets/CourtSelection2PT.png';
import CourtImage3PT from '../assets/CourtSelection3PT.png';

export const CourtLocationArea = {
  LAYUP_OR_DUNK: 'Layup/Dunk',
  THREE_PT: '3 Point',
  MIDRANGE: 'Midrange',
  INSIDE: 'Inside',
};

/** Debug Variables */
const DEBUG_SHOW_AREAS = false;

/**
 * Image Dimensions
 * NOTE: These dimensions are for the ~actual~ image, the displayed image scales based on the
 * width of its container while keeping the same aspect ratio for the image height.
 */
const actualImageWidth = 672;
const actualImageHeight = 526;
const actualImageOutOfBoundsWidth = 37;
const actualImageBottomBuffer = 12;

/**
 * Ratio of the displayed image to actual image
 * ex. 1.5  = the displayed images is 1.5x larger than the actual
 * @param {*} displayImageWidth width of the image container, changes on every screen resize
 * @returns displayed:actual ratio
 */
const displayedToActualRatio = (displayImageWidth) =>
  displayImageWidth / actualImageWidth;

/**
 * Calculation of the displayed image height based on the displayed image width and aspect ratio
 * @param {*} displayImageWidth width of the image container, changes on every screen resize
 * @returns Height of the displayed image in pixels
 */
const displayImageHeight = (displayImageWidth) =>
  actualImageHeight * displayedToActualRatio(displayImageWidth);

/**
 * Calculation of the displayed image out of bounds (top, left, right) court section dimensions
 * @param {*} displayImageWidth width of the image container, changes on every screen resize
 * @returns Size of out of bounds sections in pixels
 */
const displayedImageOutOfBoundsWidth = (displayImageWidth) =>
  actualImageOutOfBoundsWidth * displayedToActualRatio(displayImageWidth);

/**
 * Calculation of the displayed image bottom buffer (a small area of nothing at the boittom of
 * the image which exists for the ball to be placed near or on the half court line)
 * @param {*} displayImageWidth width of the image container, changes on every screen resize
 * @returns Size of the bottom buffer sections in pixels
 */
const displayedImageBottomBuffer = (displayImageWidth) =>
  actualImageBottomBuffer * displayedToActualRatio(displayImageWidth);

/**
 * Shot Coordinate Ranges
 *  Location (0,0) is on the baseline behind the basketball rim (center of baseline)
 *  X-coordinates (baseline coordinate) go from -50 to 50
 *     +50 is the right sideline assuming you are staring at the basket from center court
 *     -50 is the left sideline assuming you are staring at the basket from center court
 *  Y-coordinates (sideline coordinate) go from 0 to 100
 *     0 is the baseline
 *     100 is mid court line
 */
const minX = -50;
const maxX = 50;
const minY = 0;
const maxY = 100;
const coordinateScalingFactorX = (displayImageWidth) =>
  (maxX - minX) /
  (displayImageWidth - 2 * displayedImageOutOfBoundsWidth(displayImageWidth));
const coordinateScalingFactorY = (displayImageWidth) =>
  (maxY - minY) /
  (displayImageHeight(displayImageWidth) -
    displayedImageOutOfBoundsWidth(displayImageWidth) -
    displayedImageBottomBuffer(displayImageWidth));

/**
 * Image Map areas
 * DO NOT REMOVE: This is not dead code, this is for reference to the html object
 */

/* <map name="court-location-map">
    <area target="" alt="Layup/Dunk" title="Layup/Dunk" href="" coords="281,39,393,138" shape="rect">
    <area target="" alt="Inside" title="Inside" href="" coords="253,39,415,255" shape="rect">
    <area target="" alt="Midrange" title="Midrange" href="" coords="104,39,105,132,109,165,119,196,128,217,139,238,149,254,165,274,182,291,199,305,224,319,247,332,273,341,299,346,334,350,368,347,399,339,426,329,451,317,474,301,493,284,510,265,527,244,539,221,548,200,558,176,563,147,564,125,565,94,565,67,566,40" shape="poly">
    <area target="" alt="3pt" title="3pt" href="" coords="40,39,630,514" shape="rect">
</map> */

const areaLayup = {
  name: CourtLocationArea.LAYUP_OR_DUNK,
  shape: 'rect',
  coords: [281, 37, 393, 138],
  fillColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
  strokeColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
};

const areaInside = {
  name: CourtLocationArea.INSIDE,
  shape: 'rect',
  coords: [253, 37, 415, 255],
  fillColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
  strokeColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
};

const areaMidline = {
  name: CourtLocationArea.MIDRANGE,
  shape: 'poly',
  coords: [
    104,
    37,
    105,
    132,
    109,
    165,
    119,
    196,
    128,
    217,
    139,
    238,
    149,
    254,
    165,
    274,
    182,
    291,
    199,
    305,
    224,
    319,
    247,
    332,
    273,
    341,
    299,
    346,
    334,
    350,
    368,
    347,
    399,
    339,
    426,
    329,
    451,
    317,
    474,
    301,
    493,
    284,
    510,
    265,
    527,
    244,
    539,
    221,
    548,
    200,
    558,
    176,
    563,
    147,
    564,
    125,
    565,
    94,
    565,
    67,
    566,
    37,
  ],
  fillColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
  strokeColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
};

const areaThreePoint = {
  name: CourtLocationArea.THREE_PT,
  shape: 'rect',
  coords: [40, 37, 635, 514],
  fillColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
  strokeColor: DEBUG_SHOW_AREAS ? '#00FF0066' : 'transparent',
};

const areaMapAll = {
  name: 'court-location-map',
  areas: [areaLayup, areaInside, areaMidline, areaThreePoint],
};

export const CourtProps = {
  image2pt: CourtImage2PT,
  image3pt: CourtImage3PT,
  // width: displayImageWidth,
  imgWidth: actualImageWidth,
  map: areaMapAll,
};

/**
 * Determines the shot location in the X or Baseline coordinate
 * @param {*} imageX image coordinate of shot selection
 * @param {*} displayImageWidth width of the displayed image
 * @returns Shot location on baseline (-50 to 50)
 */
function getShotLocationX(imageX, displayImageWidth) {
  return (
    -coordinateScalingFactorX(displayImageWidth) *
    (displayImageWidth / 2 - imageX)
  );
}

/** TODO function to reverse shot location X to image coordinate X */
function getImageCoordinateX(x, displayImageWidth) {
  return (
    -coordinateScalingFactorX(displayImageWidth) *
    (displayImageWidth / 2 - x)
  );
}

/**
 * Determines the shot location in the Y or Sideline coordinate
 * @param {*} imageY image coordinate of shot selection
 * @param {*} displayImageWidth width of the displayed image
 * @returns Shot location on sideline (0-baseline to 100-midcourt)
 */
function getShotLocationY(imageY, displayImageWidth) {
  return (
    coordinateScalingFactorY(displayImageWidth) *
    (imageY - displayedImageOutOfBoundsWidth(displayImageWidth))
  );
}

/**
 * Derives the short coordinates and returns an object with shot location information
 * @param {*} imageX Shot selection location on img in pixels, on baseline
 * @param {*} imageY Shot selection location on img in pixels, on sideline
 * @param {*} area String identifier for the region of the shot attempt (Inside, 3PT, etc.)
 * @param {*} displayImageWidth Displayed width of the court selection image
 * @returns Object with shot location information and debug information about the image selection
 */
export function getShotLocation(imageX, imageY, area, displayImageWidth) {
  return {
    imageX: imageX,
    imageY: imageY,
    imageWidth: displayImageWidth,
    imageHeight: displayImageHeight(displayImageWidth),
    area: area,
    x: getShotLocationX(imageX, displayImageWidth),
    y: getShotLocationY(imageY, displayImageWidth),
  };
}
