import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { useItemsFromPages } from '../utils/reactQueryToolkit';
import { listPlayersSortedByUpdatedAt } from './playersService';

export const playersKeys = {
  all: ['players'],
};

export const usePlayersQuery = (options = {}, mapFn) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: playersKeys.all,
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      const players = await listPlayersSortedByUpdatedAt(queryVars);
      let items = players.items;
      if (mapFn) {
        items = await Promise.all(items?.map(mapFn));
      }

      return { ...players, items };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  return {
    ...query,
    data: useItemsFromPages(query.data?.pages),
  };
};
