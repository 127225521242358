export const PENDING_CHANGE_LEAGUE_ID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_TEAMS_IMAGE = '/static/media/Team@2x.88d46a2d.png';

export const GAME_STATUS = {
  CANCELED: 'CANCELED',
  ENDED: 'ENDED',
  LIVE: 'LIVE',
};

export const GAME_POINTS = {
  FREE_THROW_MADE: 1,
  TWO_POINT_MADE: 2,
  THREE_POINT_MADE: 3,
  FREE_THROW_MISS: 0,
  TWO_POINT_MISS: 0,
  THREE_POINT_MISS: 0,
};

export const SHOT_LOCATION_EVENTS = {
  TWO_PT_SHOT_MADE: 'TWO_POINT_MADE',
  TWO_PT_SHOT_MISSED: 'TWO_POINT_MISS',
  THREE_PT_SHOT_MADE: 'THREE_POINT_MADE',
  THREE_PT_SHOT_MISSED: 'THREE_POINT_MISS',
};

export const LIST_PAGINATION_LIMIT = {
  SMALL: 15,
  LARGE: 1000,
  XLARGE: 10000,
};

export const ITEM_TYPE = {
  GAME: 'Game',
  TEAM: 'Team',
  COACH: 'Coach',
};

/**
 * Constant used in the keySortId field to make use of secondary index sorting
 * by using a consistent hash key.
 */
export const KEY_SORT_ID = 'b5655567-f22a-4f86-ab0d-546864398790';

/**
 * A new user that has not yet been assigned to any group
 */
export const NEW_GROUP_USER = '';
