import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

const useSwitchStyle = makeStyles({
  switchBase: {
    color: colors.PRIMARY[100],
    '&$checked': {
      color: colors.PRIMARY[200],
    },
    '&$checked + $track': {
      backgroundColor: colors.PRIMARY[200],
    },
  },
  checked: {},
  track: {},
});

const SwitchComponent = ({
  label,
  disabled,
  handleChange = () => setIsChecked(!isChecked),
  isChecked,
  setIsChecked,
}) => {
  const classes = useSwitchStyle();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          classes={{
            switchBase: classes.switchBase,
            checked: classes.checked,
            track: classes.track,
          }}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};

export default SwitchComponent;
