
import styled from 'styled-components'

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const StyledFlexEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledColumnFlexStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export {
  StyledFlexRow,
	StyledFlexEnd,
  StyledColumnFlexStart,
}