import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { gamesKeys, useGamesTabsQuery } from '../api/gamesQueries';
import {
  createGame,
  createGameLineup,
  getGame,
  updateGame,
  updateGameSubscription,
} from '../api/gamesService';
import { useLeaguesQuery } from '../api/leaguesQueries';
import { useSeasonsQuery } from '../api/seasonsQueries';
import { useTeamsQuery, useTeamUsersListQuery } from '../api/teamsQueries';
import { createTeamUser } from '../api/teamsService';
import { useVenuesQuery } from '../api/venuesQueries';
import ToastAlert from '../components/alerts/ToastAlert';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import SquareGrid from '../components/Grids/SquareGrid';
import MainLayout from '../components/layout/MainLayout';
import AddEditGameModal from '../components/Modals/AddEditGameModal';
import GameContent from '../components/PageContent/GameContent';
import Spinner from '../components/Spinner';
import GameTabs from '../components/tabs/GameTabs';
import {
  selectScrollPositions,
  updateScrollPosition,
} from '../redux/gamesPageSlice';
import { 
  selectActiveTab, 
  updateActiveTab, 
  selectCanChangeTabs,
  updateCanChangeTabs,
} from '../redux/gamesPageTabsSlice';
import { colors } from '../styles';
import { GamesTabs, getTabByGameDateTime } from '../utils/gameUtil';
import {
  aggregateBooleanPropUsingAnd,
  aggregateBooleanPropUsingOr,
  useFetchAll,
} from '../utils/reactQueryToolkit';
const log = require('../logger')('AdminPanel/GamesPage', 'debug');

const GamesPage = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [createGameError, setCreateGameError] = useState();

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const activeTab = useSelector(selectActiveTab);
  const canChangeTabs = useSelector(selectCanChangeTabs);
  const scrollPositions = useSelector(selectScrollPositions);
  const scrollContainerRef = useRef();

  /* Should always be able to change tabs after initial render */
  useEffect(() => {
    dispatch(updateCanChangeTabs(true));
  }, [dispatch]);

  /** Game Add/Edit form fields */
  const [gameItem, setGameItem] = useState({
    gameDateTime: '',
    homeTeamId: '',
    awayTeamId: '',
    leagueId: '',
    seasonId: '',
    venueId: '',
    acceptedTerms: false,
  });
  const [changedGameId, setChangedGameId] = useState();

  /** Reset changedGameId after 2 seconds */
  useEffect(() => {
    if (changedGameId) {
      const timer = setTimeout(() => setChangedGameId(), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [changedGameId]);

  const { onSuccessCallback } = useFetchAll(true);

  const teamsQuery = useTeamsQuery({
    onSuccess: (data) => onSuccessCallback(teamsQuery)(data),
  });
  const teams = useMemo(
    () => teamsQuery.data.sort((a, b) => a.name.localeCompare(b.name)),
    [teamsQuery.data]
  );

  const seasonsQuery = useSeasonsQuery({
    onSuccess: (data) => onSuccessCallback(seasonsQuery)(data),
  });
  const seasons = useMemo(
    () =>
      seasonsQuery.data.sort(
        (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
      ),
    [seasonsQuery.data]
  );

  const venuesQuery = useVenuesQuery({
    onSuccess: (data) => onSuccessCallback(venuesQuery)(data),
  });
  const venues = useMemo(
    () => venuesQuery.data.sort((a, b) => a.name.localeCompare(b.name)),
    [venuesQuery.data]
  );

  const leaguesQuery = useLeaguesQuery({
    onSuccess: (data) => onSuccessCallback(leaguesQuery)(data),
  });
  const leagues = leaguesQuery.data;

  const isModalDataLoading = aggregateBooleanPropUsingOr(
    [teamsQuery, seasonsQuery, venuesQuery, leaguesQuery],
    'isLoading'
  );

  const teamUsersQuery = useTeamUsersListQuery();

  /**
   * This subscription call is called either after a game is created or updated.
   * The updateGameResult data received by this call back is used to add a game
   * to a team user of which is already a coach to either the home team or away
   * team.
   * @param {object} updateGameResult
   */
  const updateGameSubscriptionCallback = async (
    updateGameResult,
    teamUsersQueryData
  ) => {
    const {
      id,
      homeTeamId,
      awayTeamId,
      seasonId,
      leagueId,
      gameDateTime,
    } = updateGameResult.data.onUpdateGame;

    if (
      teamUsersQueryData?.length > 0 &&
      moment(gameDateTime).valueOf() > moment().valueOf()
    ) {
      const teamUserParams = teamUsersQueryData.map(
        ({ teamId, gameId, gameRole, seasonId, leagueId, userId }) => ({
          teamId,
          gameId,
          gameRole,
          seasonId,
          leagueId,
          userId,
        })
      );

      const teamUsersWithNeededTeamIds = teamUserParams
        .filter(
          (param) =>
            (param.teamId === homeTeamId || param.teamId === awayTeamId) &&
            param.seasonId === seasonId &&
            param.leagueId === leagueId
        )
        .map(({ teamId, gameRole, seasonId, leagueId, userId }) =>
          JSON.stringify({
            teamId,
            teamRole: gameRole,
            seasonId,
            leagueId,
            userId,
          })
        );

      const teamUsersWithoutLeagueSeasonIds = teamUserParams
          .filter(
            (param) => 
              (param.teamId === homeTeamId || param.teamId === awayTeamId) &&
              (param.seasonId === null) && 
              (param.leagueId === null)
          )
          .map(teamUser => 
            ({
              userId: teamUser.userId,
              teamId: teamUser.teamId,
              teamRole: teamUser.gameRole,
              leagueId: leagueId,
              seasonId: seasonId,
            })
          );

      const teamUserParamsForNewGame = [
        ...Array.from(
          new Set(teamUsersWithNeededTeamIds)
        ).map((stringifiedParam) => JSON.parse(stringifiedParam)),
        ...teamUsersWithoutLeagueSeasonIds
      ];

      if (teamUserParamsForNewGame.length > 0) {
        const createUserTeamResponse = await Promise.all(
          teamUserParamsForNewGame.map(
            async ({ teamId, userId, seasonId, leagueId, teamRole }) =>
              await createTeamUser(
                userId,
                teamId,
                teamRole,
                id,
                leagueId,
                seasonId
              )
          )
        );
      }
    }
  };

  useEffect(() => {
    let updateSubscription;

    if (teamUsersQuery.data) {
      updateSubscription = updateGameSubscription((value) =>
        updateGameSubscriptionCallback(value, teamUsersQuery.data)
      );
    }

    return () => {
      updateSubscription?.unsubscribe();
    };
  }, [teamUsersQuery.data]);

  const onError = (error) => {
    log.error(error);
    setShowToast(true);
    setToastText('An error occurred while retrieving games items.');
    setToastVariant('danger');
  };

  const {
    todaysGamesQuery,
    upcomingGamesQuery,
    pastGamesQuery,
    todaysGames,
    upcomingGames,
    pastGames,
    allGames,
    isLoading,
    isFetching,
    fetchAllGames,
    cancelFetchAll,
  } = useGamesTabsQuery(onError);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isSuccess, // eslint-disable-line no-unused-vars
  } = useMemo(() => {
    switch (activeTab) {
      case GamesTabs.TODAY:
        return todaysGamesQuery;
      case GamesTabs.UPCOMING:
        return upcomingGamesQuery;
      case GamesTabs.PAST:
        return pastGamesQuery;
      case GamesTabs.NONE:
        return {};
      default:
        log.warn(
          'activeTab does not have a valid value (expected type: GamesTabs enum).'
        );
    }
  }, [activeTab, pastGamesQuery, todaysGamesQuery, upcomingGamesQuery]);

  useEffect(() => {
    /* Check if all 3 games queries are successful */
    const isSuccess = aggregateBooleanPropUsingAnd(
      [pastGamesQuery, todaysGamesQuery, upcomingGamesQuery],
      'isSuccess'
    );

    if (isSuccess) {
      switch (activeTab) {
        case GamesTabs.TODAY:
          if (todaysGamesQuery.data?.length > 0) break;
        /* Fallthrough if no LIVE games. We need to display another tab. */
        case GamesTabs.NONE: // eslint-disable-line no-fallthrough
          if (todaysGamesQuery.data?.length > 0) {
            dispatch(updateActiveTab(GamesTabs.TODAY));
          } else if (upcomingGamesQuery.data?.length > 0) {
            dispatch(updateActiveTab(GamesTabs.UPCOMING));
          } else if (pastGamesQuery.data?.length > 0) {
            dispatch(updateActiveTab(GamesTabs.PAST));
          }
          break;
        default:
        /* No action needed */
      }
    }
  }, [
    activeTab,
    dispatch,
    pastGamesQuery,
    todaysGamesQuery,
    upcomingGamesQuery,
  ]);

  const onScrollListHandler = useMemo(
    () =>
      debounce((event) => {
        const { scrollTop } = event.target;
        dispatch(
          updateScrollPosition({
            tab: activeTab,
            scrollPosition: scrollTop,
          })
        );
      }, 200),
    [activeTab, dispatch]
  );

  useEffect(() => {
    if (data?.length > 0 && scrollPositions[activeTab] > 0) {
      const timer = setTimeout(() => {
        scrollContainerRef.current?.scrollTo({
          top: scrollPositions[activeTab],
          behavior: 'smooth',
        });
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [activeTab, data, scrollPositions]);

  const handleSubmit = async (values) => {
    const { gameDateTime, homeTeamId, awayTeamId, seasonId, venueId } = values;

    let homeTeam = teams.find(({ id }) => homeTeamId === id);
    let awayTeam = teams.find(({ id }) => awayTeamId === id);
    let season = seasons.find(({ id }) => seasonId === id);
    let venue = venues.find(({ id }) => venueId === id);
    let league = leagues.find(({ id }) => season.leagueId === id);

    homeTeam = {
      homeTeamId: homeTeam.id,
      homeTeamName: homeTeam.name,
      homeTeamCity: homeTeam.city,
      homeTeamState: homeTeam.state,
      homeTeamActive: homeTeam.active,
    };

    awayTeam = {
      awayTeamId: awayTeam.id,
      awayTeamName: awayTeam.name,
      awayTeamCity: awayTeam.city,
      awayTeamState: awayTeam.state,
      awayTeamActive: awayTeam.active,
    };

    league = {
      sportId: league.sportId,
      sportName: league.sport.name,
      leagueId: league.id,
      leagueName: league.name,
      leagueAbbreviation: league.abbreviation,
      leagueTeamSize: league.teamSize,
      leagueNumPlayersOnCourt: league.numPlayersOnCourt,
      leagueNumPlayersOnBench: league.numPlayersOnBench,
      leagueNumTimeOuts: league.numTimeOuts,
      leagueNumFoulsPerPlayer: league.numFoulsPerPlayer,
      leagueNumPeriods: league.numPeriods,
      leagueTimePerPeriodInMins: league.timePerPeriodInMins,
      leagueOvertimeDurationInMins: league.overtimeDurationInMins,
    };

    season = {
      seasonId: season.id,
      seasonStartDate: season.startDate,
      seasonEndDate: season.endDate,
      seasonType: season.seasonType,
    };

    venue = {
      venueId: venue.id,
      venueName: venue.name,
      venueCity: venue.city,
      venueState: venue.state,
      venueZipcode: venue.zipcode,
      venueActive: venue.active,
    };

    const addNewGame = async (
      gameDateTime,
      homeTeam,
      awayTeam,
      league,
      season,
      venue
    ) => {
      // Create a new game between the home team and away team.
      let createGameData = await createGame(
        gameDateTime,
        homeTeam,
        awayTeam,
        league,
        season,
        venue
      );
      if (createGameData.errors) {
        return createGameData;
      }

      // Retrieve the new game data.
      const newGameData = createGameData.data.createGame;
      const gameId = newGameData.id;

      // Create a home team game lineup.
      const createHomeTeamGameLineup = await createGameLineup(
        gameId,
        homeTeam.homeTeamId
      );
      if (createHomeTeamGameLineup.errors) {
        return createHomeTeamGameLineup;
      }

      // Create an away team game lineup.
      const createAwayTeamGameLineup = await createGameLineup(
        gameId,
        awayTeam.awayTeamId
      );
      if (createAwayTeamGameLineup.errors) {
        return createAwayTeamGameLineup;
      }

      // Retrieve the game lineup ids for the home and away teams.
      newGameData.homeTeamGameLineupId =
        createHomeTeamGameLineup.data.createGameLineup.id;
      newGameData.awayTeamGameLineupId =
        createAwayTeamGameLineup.data.createGameLineup.id;

      // Update the new game data with the home team and away team game lineup ids.
      createGameData = await updateGame(gameId, newGameData);
      if (createGameData.errors) {
        return createGameData;
      }

      return createGameData;
    };

    /**
     * Create or Edit
     */
    let returnData;
    isCreate
      ? (returnData = await addNewGame(
          gameDateTime,
          homeTeam,
          awayTeam,
          league,
          season,
          venue
        ))
      : (returnData = await updateGame(values.id, {
          gameDateTime,
          ...homeTeam,
          ...awayTeam,
          ...league,
          ...season,
          ...venue,
          _version: values._version,
        }));

    /* If League Create/Update failed show an error, else close the modal */
    if (returnData.errors) {
      setCreateGameError(
        `Unable to create team. ${returnData.errors[0].message}.`
      );
    } else {
      /**
       * Success
       * Clear error, update list, close model, show toast, reset form
       */
      const updatedGame = returnData.data.updateGame;
      const tab = getTabByGameDateTime(updatedGame.gameDateTime);

      if (isCreate) {
        setToastText(
          `The "${homeTeam.homeTeamName} vs ${awayTeam.awayTeamName}" was created successfully.`
        );
      } else {
        setToastText(
          `The "${homeTeam.homeTeamName} vs ${awayTeam.awayTeamName}" was updated successfully.`
        );

        /* Directly update React Query cache with updated game */
        queryClient.setQueryData(gamesKeys[tab](), (data) => ({
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            items: page.items.map((game) =>
              game.id === updatedGame.id ? { ...game, ...updatedGame } : game
            ),
          })),
        }));
      }

      setChangedGameId(updatedGame.id);
      dispatch(updateActiveTab(tab));
      setShowToast(true);
      setCreateGameError(null);
      queryClient.invalidateQueries(gamesKeys.all);
    }
  };

  const handleOpen = () => {
    setIsCreate(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCreateGameError(null);
  };

  const handleGameEdit = async (id) => {
    const gameItem = await getGame(id);
    gameItem.gameDateTime = moment(gameItem.gameDateTime).format(
      'YYYY-MM-DDTH:mm'
    );
    setGameItem(gameItem);
    setIsCreate(false);
    setOpen(true);
  };

  return (
    <MainLayout
      title="Games"
      buttonLabel="+ Add Game"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {!isLoading && allGames.length === 0 && (
        <SquareGrid
          height={275}
          width={250}
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          justifyContent={'center'}
        >
          <AddCircleButton
            buttonLabel="Add Game"
            buttonClickHandler={handleOpen}
          />
        </SquareGrid>
      )}

      {!isLoading && allGames.length > 0 && (
        <>
          <GameTabs
            showCounts={false}
            liveGamesCount={todaysGames.length}
            upcomingGamesCount={upcomingGames.length}
            pastGamesCount={pastGames.length}
            liveGamesClickHandler={() =>
              dispatch(updateActiveTab(GamesTabs.TODAY))
            }
            upcomingGamesClickHandler={() =>
              dispatch(updateActiveTab(GamesTabs.UPCOMING))
            }
            pastGamesClickHandler={() =>
              dispatch(updateActiveTab(GamesTabs.PAST))
            }
            activeLiveGamesStatus={activeTab === GamesTabs.TODAY}
            activeUpcomingGamesStatus={activeTab === GamesTabs.UPCOMING}
            activePastGamesStatus={activeTab === GamesTabs.PAST}
            canChangeTabs={canChangeTabs}
          />
          <GameContent
            searchId={'__gameId'}
            currentTabGames={data}
            hasNextPage={hasNextPage}
            /* Creating arrow function to ensure no params (e.g. click event) are passed to to fetchNextPage. See docs for more. */
            fetchNextPage={fetchNextPage}
            searchableGames={allGames}
            fetchAllSearchableGames={fetchAllGames}
            cancelFetchAll={cancelFetchAll}
            isFetchingSearchableGames={isFetching}
            placeholder={'Search Games'}
            handleEditModalOpenHook={handleGameEdit}
            navigateToGameDetailsPage={true}
            displayOption={activeTab}
            pageRef={scrollContainerRef}
            changedGameId={changedGameId}
            onScrollListHandler={onScrollListHandler}
          />
        </>
      )}

      <AddEditGameModal
        isNewGame={isCreate}
        gameData={gameItem}
        modalOpen={open}
        setModalOpen={setOpen}
        teamList={teams}
        seasonList={seasons}
        venueList={venues}
        leaguesList={leagues}
        onSubmit={handleSubmit}
        onClose={handleClose}
        error={createGameError}
        isLoading={isModalDataLoading}
        setError={setCreateGameError}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default GamesPage;
