import React from 'react';
import styled from 'styled-components';
import { SCOREKEEPER, CLOCKMANAGER } from '../../data/roles';

const StyledStatusCircle = styled.div`
	height: 1.5vh;
	width: 1.5vh;
	border-radius: 3vh;
	background-color: ${(props) => (props.status === 'online' ? '#05ea3b' : 'red')};
	border solid 1px #707070;
`;

const StyledUserName = styled.div`
  margin-left: 8px;
  color: white;
  font-size: 14px;
  font-family: Inter;
`;

const StyledUserDiv = styled.div`
  padding: 0 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #707070;
    &:last-child {
      border-right: none;
    }
  }
`;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SingleUser = ({ user }) => {
  let groupAbbreviation = '';
  switch (user.role) {
    case CLOCKMANAGER:
      groupAbbreviation = 'CM';
      break;
    case SCOREKEEPER:
      groupAbbreviation = 'SK';
      break;
    default:
      groupAbbreviation = 'CM';
  }

  return (
    <StyledUserDiv>
      <StyledStatusCircle status={user.status} />
      <StyledUserName>
        {user.fn.charAt(0)}. {user.ln} ({groupAbbreviation})
      </StyledUserName>
    </StyledUserDiv>
  );
};

const UserStatus = ({ users, ...props }) => {
  const loggedInUsers = users?.filter((user) => user[1].status !== 'offline');
  return (
    <StyledFlexRow {...props}>
      {loggedInUsers ? (
        loggedInUsers.map((user, id) => <SingleUser key={id} user={user[1]} />)
      ) : (
        <></>
      )}
    </StyledFlexRow>
  );
};

export default UserStatus;
