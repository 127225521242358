import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logger from '../../logger';
import {
  updateActiveTab,
  updateCanChangeTabs,
} from '../../redux/gamesPageTabsSlice';
import {
  formatDate,
  formatTime,
  getNoGamesMsgByGameTab,
  getTabByGameDateTime,
  searchFilter,
} from '../../utils/gameUtil';
import InfiniteScrollList from '../InfiniteListScroll';
import SearchMenuComponent from '../Inputs/SearchMenuComponent';
import GameContentItem from './GameContentItem';
import PageContent from './PageContent';
const log = logger('GameContent', 'info'); // eslint-disable-line no-unused-vars

const GameContent = ({
  searchId,
  pageRef = null,
  currentTabGames,
  fetchNextPage,
  hasNextPage,
  searchableGames,
  fetchAllSearchableGames = () => {},
  cancelFetchAll = () => {},
  isFetchingSearchableGames = false,
  placeholder,
  handleEditModalOpenHook = () => {},
  onClickedItemHook,
  searchControlStyle,
  selectedIds,
  navigateToGameDetailsPage = false,
  navigateToGameStatsPage = false,
  displayOption = null,
  onScrollListHandler = () => {},
  changedGameId,
  setIsPageLoaded = () => {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [displayedGames, setDisplayedGames] = useState([]);
  const [gameSearch, setGameSearch] = useState('');
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((search) => {
        setDisplayedGames(
          currentTabGames.filter((option) => searchFilter(option, search))
        );
      }, 200),
    [currentTabGames]
  );

  useEffect(() => {
    setIsPageLoaded(true);
  }, [setIsPageLoaded]);

  useEffect(() => {
    /**
     * Update the displayed games based on the search input
     */
    if (gameSearch) {
      /** Check if the input is an object or a search string */
      if (typeof gameSearch === 'object') {
        /** Object Selection */
        /* Can stop fetching add'l games, user found what they wanted. */
        cancelFetchAll();

        setDisplayedGames([gameSearch]);
        setIsSearchResultSelected(true);
        dispatch(updateCanChangeTabs(false));

        const tab = getTabByGameDateTime(gameSearch.gameDateTime);
        if (tab) dispatch(updateActiveTab(tab));
      } else if (gameSearch.length > 0) {
        /** Search String */
        debouncedSearch(gameSearch);
        setIsSearchResultSelected(false);
        dispatch(updateCanChangeTabs(true));
      }
    } else {
      /** Display ALL games */
      setDisplayedGames(currentTabGames);
      setIsSearchResultSelected(false);
      dispatch(updateCanChangeTabs(true));
    }
  }, [
    cancelFetchAll,
    currentTabGames,
    debouncedSearch,
    dispatch,
    gameSearch,
  ]);

  const getDisplayedData = () => {
    return (
      <>
        {displayedGames?.map((game) => {
          return (
            <GameContentItem
              key={game.id}
              game={game}
              selectedIds={selectedIds}
              history={history}
              onClickedItemHook={onClickedItemHook}
              editTeamCtrl={handleEditModalOpenHook}
              navigateToGameDetailsPage={navigateToGameDetailsPage}
              navigateToGameStatsPage={navigateToGameStatsPage}
              changedGameId={changedGameId}
              hasChanged={changedGameId === game.id}
            />
          );
        })}
      </>
    );
  };

  return (
    <PageContent
      searchId={searchId}
      pageRef={pageRef}
      data={searchableGames}
      dataProperty={(option) => `${option.homeTeamVsAwayTeam}`}
      placeholder={placeholder}
      onChange={setGameSearch}
      searchControlStyle={searchControlStyle}
      filterBy={(option, props) => searchFilter(option, props.text)}
      onScrollListHandler={onScrollListHandler}
      onSearch={fetchAllSearchableGames}
      isFetching={isFetchingSearchableGames}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuComponent
            mainCriteriaText={[
              { option: option.homeTeamVsAwayTeam, text: props.text },
            ]}
            subCriteriaText={[
              { option: option.venueName, text: props.text, separator: '-' },
              { option: option.venueCity, text: props.text, separator: ',' },
              { option: option.venueState, text: props.text },
            ]}
            subCriteriaDarkText={[
              {
                option: formatDate(option.gameDateTime),
                text: props.text,
                separator: '|',
              },
              { option: formatTime(option.gameDateTime), text: props.text },
            ]}
          />
        );
      }}
    >
      <InfiniteScrollList
        displayedData={displayedGames}
        fetchNextPage={fetchNextPage}
        hasNextPage={isSearchResultSelected ? false : hasNextPage}
        displayedDataFilter={getDisplayedData}
        unavailableDataStmt={getNoGamesMsgByGameTab(
          displayOption,
          gameSearch.length > 0
        )}
      />
    </PageContent>
  );
};

export default GameContent;
