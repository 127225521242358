import {
  disableUser,
  enableUser,
  removeUserGroup,
  createUserGroup,
  updateUser,
  getListUsers,
  getUser,
} from '../api/userService';
import {
  createTeamUser,
  deleteTeamUser,
} from '../api/teamsService';
import {
  createGameUser,
  updateGameUser,
  deleteGameUser,
} from '../api/gamesService';

import { getGameUsers } from './gameUtil';

export const USER_ROLES = {
  ADMIN: {
    LABEL: 'Admin',
    VALUE: 'Admin',
  },
  CLOCK_MANAGER: {
    LABEL: 'Clock Manager',
    VALUE: 'ClockManagers',
  },
  SCORE_KEEPER: {
    LABEL: 'Score Keeper',
    VALUE: 'ScoreKeepers',
  },
  COACH: {
    LABEL: 'Coach',
    VALUE: 'Coach',
  },
};

export const updateUserStatus = async (active, email, token) => {
  try {
    let userStatusResponse;
    if (active) {
      userStatusResponse = await enableUser(email, token);
    } else {
      userStatusResponse = await disableUser(email, token);
    }

    return userStatusResponse;
  } catch (error) {
    return { errors: error };
  }
};

export const updateUserInfoData = async (
  id,
  firstName,
  lastName,
  role,
  email,
  active,
  _version,
  previousUserRoles,
  token
) => {
  try {
    const updatedUser = await updateUser(
      id,
      firstName,
      lastName,
      email,
      role,
      active,
      _version,
      token
    );

    if (updatedUser.errors) {
      return updatedUser.errors;
    }

    const removedRoles =
      previousUserRoles.includes('ClockManagers') &&
      previousUserRoles.includes('ScoreKeepers')
        ? previousUserRoles
        : previousUserRoles.filter((_role) => !role.includes(_role));
    if (removedRoles.length > 0) {
      const asyncRemoveGroup = removedRoles.map(
        async (_role) => await removeUserGroup(email, _role, token)
      );
      await Promise.all(asyncRemoveGroup);
    }

    const addedRoles =
      previousUserRoles.includes('ClockManagers') &&
      previousUserRoles.includes('ScoreKeepers')
        ? previousUserRoles.filter((_role) => role.includes(_role))
        : role.filter((_role) => !previousUserRoles.includes(_role));
    if (addedRoles.length > 0) {
      const asyncAddGroup = addedRoles.map(
        async (_role) => await createUserGroup(email, _role, token)
      );
      await Promise.all(asyncAddGroup);
    }

    return updatedUser.data.updateUser;
  } catch (error) {
    return { errors: error };
  }
};

export const getUserGameRoleItems = async (variables) => {
  const users = await getListUsers(variables);
  const userItems = users.items.map(({ id, firstName, lastName }) => ({
    id,
    firstName,
    lastName,
  }));
  const userItemsAsync = userItems.map(async (userItem) => {
    const { id, firstName, lastName } = userItem;
    const { games, teams } = await getUser(id);
    const gameUserIds = games.items
      .filter(({ _deleted }) => _deleted === null)
      .map(({ id }) => id);

    const teamUsers = teams.items
      .filter(({ _deleted }) => _deleted === null)
      .map(({ id, userId, teamId, gameId, gameRole }) => ({ id, userId, teamId, gameId, gameRole }));

    let asyncGameItemsResponse = await getGameUsers(gameUserIds);
    asyncGameItemsResponse = asyncGameItemsResponse.filter(
      ({ _deleted }) => _deleted === null
    );
    const userGameRoleItems = asyncGameItemsResponse.map(
      ({ gameRole, game }) => ({ gameRole, id: game.id })
    );

    userItem.gameRoles = (teamUsers.length > 0) ? teamUsers : userGameRoleItems;
    userItem.name = `${firstName} ${lastName}`;

    return userItem;
  });

  const userItemsAsyncResponse = await Promise.all(userItemsAsync);
  return { ...users, items: userItemsAsyncResponse };
};

export const getAssignedUserGameRole = (userItems, gameId) => {
  const gameRolesData = [];

  const userItemsSize = userItems?.length;

  for (let userItemIndex = 0; userItemIndex < userItemsSize; userItemIndex++) {
    const userItem = userItems[userItemIndex];
    const { name, gameRoles } = userItem;

    if (gameRoles.length === 0) {
      continue;
    }

    const gameRole = gameRoles.find(
      (currentGameRole) => currentGameRole.id === gameId
    );
    if (gameRole) {
      gameRolesData.push({ name, gameRole, userId: userItem.id, gameId });
    }
  }

  return gameRolesData.sort((a, b) => a.name.localeCompare(b.name));
};

export const getAssignedTeamCoaches = (userItems, gameId, homeTeamId, awayTeamId) => {
  const teamCoachRoles = [];

  for(let userItemIndex = 0; userItemIndex < userItems.length; userItemIndex++) {
    const userItem = userItems[userItemIndex];
    const { name, gameRoles } = userItem;
    const assignedTeamGame = gameRoles.find(gameRole => gameRole.gameRole === 'Coach' && gameRole.gameId === gameId);
    if(assignedTeamGame) {
      const { teamId } = assignedTeamGame;
      const isHomeTeam = teamId === homeTeamId;
      const isAwayTeam = teamId === awayTeamId;
      teamCoachRoles.push({ ...assignedTeamGame, name, isHomeTeam, isAwayTeam });
    }
  }

  return teamCoachRoles.sort((a, b) => a.name.localeCompare(b.name));
};

export const getCoachesAlreadyAssigned = (userItems, selectedGames, teamId) => {
  const selectedGameIds = selectedGames.map(({ id }) => id);
  const selectedGameHomeTeamIds = selectedGames.map(({ homeTeamId }) => homeTeamId);
  const selectedGameAwayTeamIds = selectedGames.map(({ awayTeamId }) => awayTeamId);
  const coachUsers = userItems.filter(({ gameRoles }) => gameRoles.filter(({ gameRole }) => gameRole === 'Coach').length > 0)
                              .filter(({ gameRoles }) => {
                                const gameIds = gameRoles.map(({ gameId }) => gameId);
                                const teamIds = gameRoles.map(({ teamId }) => teamId);
                                return (
                                        (gameIds.some(gameId => selectedGameIds.includes(gameId)) || teamIds.includes(teamId)) || 
                                        (teamIds.some(teamId => selectedGameHomeTeamIds.includes(teamId)) || teamIds.some(teamId => selectedGameAwayTeamIds.includes(teamId)))
                                       );
                              })
                              .map(({ id }) => {
                                return { userId: id, teamId, gameRole: 'Coach' };
                              });
  return coachUsers.length > 0 ? coachUsers : null;
};

export const getTeamGamesForSelectedUsers = (selectedUserIds, selectedGames, selectedTeamId) => {
  // Get unique season-league Id pairs from the selectedGames.
  const seasonLeagueIds = Array.from( 
                            new Set(
                              selectedGames.map(({seasonId, leagueId }) => JSON.stringify({ seasonId, leagueId }))
                            ) 
                          )
                         .map(jsonStr => JSON.parse(jsonStr));

  // Get the team games for the selected team each with respect to the game's league and season, 
  // however, if unique season-league id pairs is empty each team games should be assigned an empty array.
  const teamGames = seasonLeagueIds.length > 0
                    ? seasonLeagueIds.map(({ seasonId, leagueId }) => {
                                            const games = selectedGames.filter(game => game.seasonId === seasonId && game.leagueId === leagueId);
                                            return {
                                              id: selectedTeamId,
                                              seasonId,
                                              leagueId,
                                              games,
                                            }
                                          })
                    : [
                        {
                          id: selectedTeamId,
                          seasonId: null,
                          leagueId: null,
                          games: [],
                        }
                      ];

  const teamGamesToBeAssignedToSelectedUsers = [];

  for(let i=0; i<selectedUserIds.length; i++) { 
    const id = selectedUserIds[i];
    teamGamesToBeAssignedToSelectedUsers.push({[id]: teamGames});
  }

  return teamGamesToBeAssignedToSelectedUsers;
};

export const addUserToTeam = async (teamUserParams) => {
  teamUserParams = teamUserParams.reduce((updated, current) => {
    const { userId, team, teamRole } = current;
    const { id, seasonId, leagueId, games } = team;
    const gameIdsWithTeamRoles = games.length > 0 
                                  ? games.map(game => ({ teamId: id, gameId: game.id, teamRole, seasonId, leagueId, userId, }))
                                  : [{ teamId: id, gameId: null, seasonId: null, leagueId: null, teamRole, userId, }]
    return [ ...updated, ...gameIdsWithTeamRoles ];
  }, []);

  const createTeamUsersAsync = teamUserParams.map(async ({ userId, teamId, gameId, seasonId, leagueId, teamRole }) => 
                                await createTeamUser(userId, teamId, teamRole, gameId, leagueId, seasonId));
  return await Promise.all(createTeamUsersAsync);
};

export const deleteUserFromTeam = async (teamItems, teamItemsRemoved) => {
  const teamUserIdsForDeletion = teamItems
                                  .filter(({ teamId }) =>  teamItemsRemoved.includes(teamId))
                                  .map(({ id, _version }) => ({ id, _version }));

  const deleteTeamUserAsync = teamUserIdsForDeletion.map(async ({ id, _version }) => await deleteTeamUser(id, _version));
  return await Promise.all( deleteTeamUserAsync );
};

export const addUserToGame = async (gameUserParams) => {
  const createGameUsersAsync = gameUserParams.map(async ({ userId, gameId, gameRole }) => await createGameUser(userId, gameId, gameRole));
  return await Promise.all(createGameUsersAsync);
};

export const deleteUserFromGame = async (deleteGameUserParams) => {
  const deleteGameUsersAsync = deleteGameUserParams.map(async ({ id, _version }) => await deleteGameUser(id, _version));
  return await Promise.all(deleteGameUsersAsync);
};

export const updateUserForGame = async (updateGameUserParams) => {
  const updateGameUsersAsync = updateGameUserParams.map(async ({ id, gameRole, _version }) => await updateGameUser(id, gameRole, _version));
  return await Promise.all(updateGameUsersAsync);
};