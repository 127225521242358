import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { useFetchAll, useItemsFromPages } from '../utils/reactQueryToolkit';
import { getUserGameRoleItems } from '../utils/userUtil';
import { getUserGames, listUsersSortedByUpdatedAt } from './userService';

export const userKeys = {
  all: ['users'],
  filter: (filter) => [...userKeys.all, { filter }],
  roles: () => [...userKeys.all, 'roles'],
  games: (id) => [...userKeys.all, { id }, 'games'],
};

/**
 * Custom React Query hook to fetch all games for a particular user.
 *
 * @param {String} id - user ID
 * @param {Object} [options = {}] - options to pass to useInfiniteQuery
 * @returns
 */
export const useGetUserGamesQuery = (id, options = {}) => {
  const { onSuccessCallback } = useFetchAll(true);
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: userKeys.games(id),
    queryFn: async ({ pageParam }) => {
      const userGames = await getUserGames({
        id,
        gamesLimit: LIST_PAGINATION_LIMIT.LARGE,
        gamesNextToken: pageParam,
      });

      return userGames;
    },
    enabled: !!id,
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    onSuccess: (data) => onSuccessCallback(query)(data),
  });
  const items = useItemsFromPages(query.data?.pages);
  return {
    ...query,
    data: useMemo(() => items.filter((item) => !item._deleted), [items]),
  };
};

export const useUserRoles = (isQueryStarted = true) => {
  const { onSuccessCallback } = useFetchAll(true);
  const query = useInfiniteQuery([userKeys.roles(), { isQueryStarted }],
    async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.LARGE,
        nextToken: pageParam,
      };

      const userItemsAsyncResponse = await getUserGameRoleItems(queryVars);
      return userItemsAsyncResponse;
    },
    {
      cacheTime: 500,
      enabled: isQueryStarted,
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
      onSuccess: (data) => onSuccessCallback(query)(data),
      refetchOnWindowFocus: false,
    }
  );

  const { data, hasNextPage, isSuccess } = query;
  const userItems = useItemsFromPages(data?.pages);

  return { isUserRolesLoaded: isSuccess && !hasNextPage, userItems };
};

export const useUsersQuery = ({ filter, ...options } = {}) => {
  const query = useInfiniteQuery(userKeys.filter(filter),
    async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.LARGE,
        nextToken: pageParam,
      };

      if (filter?.length > 0) {
        const filterLowerCase = filter.toLowerCase();
        queryVars = {
          filter: {
            or: [
              { searchFirstName: { contains: filterLowerCase } },
              { searchLastName: { contains: filterLowerCase } },
            ],
          },
          limit: LIST_PAGINATION_LIMIT.LARGE,
          nextToken: pageParam,
        };
      }

      const users = await listUsersSortedByUpdatedAt(queryVars);

      return users;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    }
  );

  /* Aggregate leagues from all query pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};