import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    lightMode: false,
  },
  reducers: {
    toggleLightMode: (state) => {
      state.lightMode = !state.lightMode;
    },
  },
});

export const { toggleLightMode } = themeSlice.actions;

export const selectLightMode = (state) => state.theme.lightMode;

export default themeSlice.reducer;
