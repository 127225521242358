import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SearchInputComponent from '../Inputs/SearchInputComponent';
import { StyledColumnFlexStart } from '../../styles';
import { isMobile, addScrollListClass, onListScrollHandler } from '../../utils/scrollUtil';

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  backgroundcolor: transparent;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
`;

const PageListItems = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 0px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const PageContent = ({
  searchId,
  data,
  dataProperty,
  placeholder,
  onChange,
  children,
  searchControlStyle,
  filterBy,
  renderMenuItemChildren,
  setPageListRefElement = () => {},
  onScrollListHandler = () => {},
  setRef = () => {},
  pageRef = null,
  isFetching,
  onSearch = () => {},
}) => {
  const listRef = useRef();

  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  
    if(data?.length > 0) {
      setPageListRefElement(pageRef?.current || listRef.current);

      if(!isMobile()) {
        addScrollListClass(pageRef?.current || listRef.current);
      }
    }
  }, [data]);

  return (
    <ContentContainer>
      <StyledColumnFlexStart>
        <SearchInputComponent
          isLoading={isFetching}
          onSearch={onSearch}
          
          id={searchId}
          width={300}
          data={data}
          dataProperty={dataProperty}
          placeholder={placeholder}
          onChange={onChange}
          searchControlStyle={searchControlStyle}

          /* For Custom Filtering (Object Filtering) */
          filterBy={filterBy}

          /* For Obtaining a reference to the Typeahead instance with the intent to invoke any of its instance methods as needed */
          setRef={setRef}
          renderMenuItemChildren={renderMenuItemChildren}
        />
      </StyledColumnFlexStart>
      <PageListItems 
        id="infiniteScrollContainer"
        ref={pageRef || listRef}
        onScroll={() => onListScrollHandler(_isMobile, pageRef?.current || listRef.current)}
        onScrollCapture={onScrollListHandler}
      >
        {children}
      </PageListItems>
    </ContentContainer>
  );
};

export default PageContent;
