import React from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'react-bootstrap';

/*** PLAYER TEAM GAMES STYLES START ***/
const LaunchButtonGroupContainer = styled.div`
  z-index: 1;
  padding-right: 0px;
  width: auto;
`;

const LaunchButtonGroup = styled(ButtonGroup)`
  width: max-content;
  left: 0px;
`;

const LaunchFilterButton = styled(Button)`
  padding: 6px 0;
  margin-left: 6px;
  margin-right: 12px;
  text-align: left !important;
  border: 0;
  background-color: transparent !important;
  font: normal normal normal 14px/20px Inter;
  letter-spacing: 0px;
  color: #111111 !important;
  opacity: 1;
  font-size: 14px;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
  &:hover {
    border: 0;
    border-bottom: 2px solid #26a9e1;
  }
  &:active,
  &.active {
    font-weight: bold;
    border-color: transparent;
    border-bottom: 2px solid #26a9e1;
  }
  &:disabled {
    border: 0;
    border-bottom: 2px solid transparent;
  }
`;
/*** PLAYER TEAM GAMES STYLES END ***/

const GameTabs = ({
  showCounts = true,
  liveGamesCount = 0,
  upcomingGamesCount = 0,
  pastGamesCount = 0,
  liveGamesClickHandler = () => {},
  upcomingGamesClickHandler = () => {},
  pastGamesClickHandler = () => {},
  activeLiveGamesStatus = false,
  activeUpcomingGamesStatus = false,
  activePastGamesStatus = false,
  liveTabTitle = "Today's Games",
  showCustomTabItem = false,
  customTabItemTitle = '',
  customTabItemCount = 0,
  customTabItemStatus = false,
  customTabItemClickHandler = () => {},
  canChangeTabs = true,
}) => {

  return (
    <LaunchButtonGroupContainer>
      <LaunchButtonGroup>
        {/* Live Games */}
        {liveGamesCount > 0 && (
          <LaunchFilterButton
            onClick={liveGamesClickHandler}
            active={activeLiveGamesStatus}
            disabled={!canChangeTabs && !activeLiveGamesStatus}
          >
            {showCounts ? `${liveTabTitle}(${liveGamesCount})` : liveTabTitle}
          </LaunchFilterButton>
        )}
        {/* Upcoming Games */}
        <LaunchFilterButton
          onClick={upcomingGamesClickHandler}
          active={activeUpcomingGamesStatus}
          disabled={!canChangeTabs && !activeUpcomingGamesStatus}
        >
          Upcoming Games{showCounts && `(${upcomingGamesCount})`}
        </LaunchFilterButton>
        {/* Past Games */}
        <LaunchFilterButton
          onClick={pastGamesClickHandler}
          active={activePastGamesStatus}
          disabled={!canChangeTabs && !activePastGamesStatus}
        >
          Past Games{showCounts && `(${pastGamesCount})`}
        </LaunchFilterButton>
        {/* Custom Tab Item */}
        {showCustomTabItem && 
          <LaunchFilterButton
            onClick={customTabItemClickHandler}
            active={customTabItemStatus}
          >
            {customTabItemTitle}{showCounts && `(${customTabItemCount})`}
          </LaunchFilterButton>
        }
      </LaunchButtonGroup>
    </LaunchButtonGroupContainer>
  );
};

export default GameTabs;
