import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Button from '@material-ui/core/Button';
import { colors } from '../../styles/colorsStatsCollector';
import {
  StyledColumnFlexStart,
  MOBILE_MEDIA,
  detectMobileMedia as isMobileMedia,
} from '../../styles';
import BasketballIcon from '../../assets/basket_ball.png';
import { Panel } from './components/Panel';
import { truncateText } from '../../utils/stringUtil';
import {
  calculateGamePeriods,
  formatGameClock,
  isTimerPaused,
  isTimerRunning,
} from '../../utils/gameTimeUtil';
import { PossessionTeam } from './enums';
import { CenteredSpinner } from '../../styles/spinner';

const Container = styled(Panel)`
  min-height: ${({ $isLoading }) => ($isLoading ? '240px' : '')};
  padding: ${({ $isCollapsed }) =>
    $isCollapsed ? '13px 16px' : '19px 26px 30px'};
  flex-direction: ${({ $isCollapsed }) =>
    $isCollapsed ? 'row-reverse' : 'column'};

  @media ${MOBILE_MEDIA} {
    padding: ${({ $isCollapsed }) =>
      $isCollapsed ? '12px 14px 14px' : '16px 22px 26px'};
  }
`;

export const Scoreboard = ({
  gameTimer,
  homeTeamName,
  awayTeamName,
  homeTeamImage,
  awayTeamImage,
  homeTeamScore,
  awayTeamScore,
  homeTeamFouls,
  awayTeamFouls,
  currentPossession,
  possessionIcon = BasketballIcon,
  numPeriods,
  style,
  isLoading = true,
  isTeamInPossessionSelectable,
  isTeamOutOfPossessionSelectable,
  isTeamInPosessionSelected,
  onSelectTeamInPosession,
  onSelectTeamOutOfPosession,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Container $isCollapsed={isCollapsed} $isLoading={isLoading} style={style}>
      {isLoading && (
        <CenteredSpinner animation="border" variant="light"></CenteredSpinner>
      )}
      {!isLoading && (
        <>
          <TimeSection
            isCollapsed={isCollapsed}
            gameTimer={gameTimer}
            numPeriods={numPeriods}
          />
          <ScoreSection $isCollapsed={isCollapsed}>
            <TeamLogo
              possessionIcon={possessionIcon}
              src={homeTeamImage}
              hasPossession={currentPossession === PossessionTeam.HOME}
              isCollapsed={isCollapsed}
              isSelectable={
                currentPossession === PossessionTeam.HOME
                  ? isTeamInPossessionSelectable
                  : isTeamOutOfPossessionSelectable
              }
              onSelect={
                currentPossession === PossessionTeam.HOME
                  ? onSelectTeamInPosession
                  : onSelectTeamOutOfPosession
              }
              shouldDisplayPosesssionGlow={
                currentPossession === PossessionTeam.HOME
                  ? isTeamInPosessionSelected
                  : false
              }
              shouldDisplayBorderGlow={
                currentPossession === PossessionTeam.HOME
                  ? false
                  : isTeamInPosessionSelected
              }
              isHomeTeam={true}
            />
            <Column
              style={{
                alignItems: 'center',
                gridRowStart: `span ${isCollapsed ? '1' : '2'}`,
              }}
            >
              <Score
                homeTeamScore={homeTeamScore}
                awayTeamScore={awayTeamScore}
                $isCollapsed={isCollapsed}
              />
              <Fouls
                homeTeamFouls={homeTeamFouls}
                awayTeamFouls={awayTeamFouls}
                $isCollapsed={isCollapsed}
              />
            </Column>
            <TeamLogo
              possessionIcon={possessionIcon}
              src={awayTeamImage}
              hasPossession={currentPossession === PossessionTeam.AWAY}
              isCollapsed={isCollapsed}
              isSelectable={
                currentPossession === PossessionTeam.AWAY
                  ? isTeamInPossessionSelectable
                  : isTeamOutOfPossessionSelectable
              }
              onSelect={
                currentPossession === PossessionTeam.AWAY
                  ? onSelectTeamInPosession
                  : onSelectTeamOutOfPosession
              }
              shouldDisplayPosesssionGlow={
                currentPossession === PossessionTeam.AWAY
                  ? isTeamInPosessionSelected
                  : false
              }
              shouldDisplayBorderGlow={
                currentPossession === PossessionTeam.AWAY
                  ? false
                  : isTeamInPosessionSelected
              }
              isHomeTeam={false}
            />
            {!isCollapsed && (
              <TeamName>{truncateText(homeTeamName, 26)}</TeamName>
            )}
            {!isCollapsed && (
              <TeamName>{truncateText(awayTeamName, 26)}</TeamName>
            )}
          </ScoreSection>
          <CollapseButton
            onClick={() => setIsCollapsed((old) => !old)}
            isCollapsed={isCollapsed}
          />
        </>
      )}
    </Container>
  );
};

/**
 * Prop Type Definitions
 */
Scoreboard.propTypes = {
  /**
   * Game Periods
   */
  gameTimer: PropTypes.shape({
    quarter: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
    overtimeTracker: PropTypes.number,
  }),
  homeTeamName: PropTypes.string,
  awayTeamName: PropTypes.string,
  homeTeamImage: PropTypes.string,
  awayTeamImage: PropTypes.string,
  homeTeamScore: PropTypes.number,
  awayTeamScore: PropTypes.number,
  homeTeamFouls: PropTypes.number,
  awayTeamFouls: PropTypes.number,
  currentPossession: PropTypes.oneOf([
    PossessionTeam.HOME,
    PossessionTeam.AWAY,
    PossessionTeam.NONE,
  ]),
  possessionIcon: PropTypes.string,
  numPeriods: PropTypes.number,
};

export const TeamLogo = ({
  src,
  possessionIcon = BasketballIcon,
  hasPossession = false,
  isCollapsed = false,
  isSelectable = true,
  onSelect = null,
  shouldDisplayPosesssionGlow = false,
  shouldDisplayBorderGlow = false,
  isHomeTeam,
  style,
}) => {
  const theme = useContext(ThemeContext);
  const lightMode = theme.lightMode;
  const isMobile = isMobileMedia();
  const width = isMobile
    ? isCollapsed
      ? '55px'
      : '76px'
    : isCollapsed
    ? '63px'
    : '86px';
  const boxShadowSpreadRadius = isCollapsed ? '5px' : '8px';
  const boxShadowDefault = `0px 0px 9px ${boxShadowSpreadRadius} ${
    lightMode ? colors.GRAY_TRANSPARENT[600] : colors.BLACK[900]
  }`;
  const boxShadowSelectable = `0px 0px 9px ${boxShadowSpreadRadius} #438EFD`;
  return (
    <div
      onClick={isSelectable ? onSelect : null}
      style={{
        ...style,
        display: 'flex',
        width: width,
        height: 'auto',
        borderRadius: 3,
        backgroundColor: lightMode ? colors.WHITE[100] : colors.BLACK[600],

        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: shouldDisplayBorderGlow
          ? boxShadowSelectable
          : boxShadowDefault,
        position: 'relative',
      }}
    >
      <img
        src={src}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: 3,
        }}
      />
      {hasPossession && (
        <img
          src={possessionIcon}
          alt="Possession Indicator"
          style={{
            filter: isSelectable ? 'none' : 'grayscale(1)',
            position: 'absolute',
            right: isHomeTeam && '-15px',
            left: !isHomeTeam && '-15px',
            top: '-15px',
            width: '40%',
            height: 'auto',
            borderRadius: '50%',
            boxShadow: shouldDisplayPosesssionGlow
              ? '0px 0px 9px 6px #438EFD'
              : 'none',
          }}
        />
      )}
    </div>
  );
};

const Column = styled(StyledColumnFlexStart)`
  alignitems: center;
`;

const TeamName = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[300] : colors.GRAY[400]};
  font-size: 19px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${MOBILE_MEDIA} {
    font-size: 16px;
  }
`;

export const SmallButton = styled(Button)`
  &&& {
    border: ${({ $isActive, theme: { lightMode } }) =>
      $isActive
        ? `2px solid ${colors.BLUE[100]}`
        : lightMode
        ? `1px solid ${colors.GRAY[400]}`
        : `1px solid ${colors.BLACK[500]}`};
    border-radius: 4px;
    padding: 9px 7px;
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[700] : colors.WHITE[100]};
    background: ${({ $isActive, theme: { lightMode } }) =>
      lightMode
        ? $isActive
          ? colors.GRAY[100]
          : colors.WHITE[100]
        : $isActive
        ? colors.BLACK[400]
        : colors.GRAY[1800]};
    height: 30px;
    min-width: 30px;
    font-family: 'Inter';
    font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 600)};
    font-size: 12px;
    cursor: default;

    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      opacity: 0.36;
      background: ${({ theme: { lightMode } }) =>
        lightMode ? colors.GRAY[400] : colors.GRAY[1800]};
    }

    @media ${MOBILE_MEDIA} {
      font-size: 9px;
    }
  }
`;

const CollapseButton = styled(({ isCollapsed, ...props }) => {
  return <Button variant="outlined" {...props} />;
})`
  &&& {
    background: ${({ theme: { lightMode } }) =>
      lightMode ? colors.WHITE[100] : colors.BLUE[100]};
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    left: calc(50% - 15px);
    padding: 0;
    min-width: unset;
    border-radius: 25px;

    .MuiButton-label {
      display: none;
    }

    &::before {
      font-size: 10px;

      border-color: ${({ theme: { lightMode } }) =>
        lightMode ? colors.GRAY[1600] : colors.WHITE[100]};
      transform: ${({ isCollapsed }) =>
        isCollapsed ? 'rotate(135deg)' : 'rotate(-45deg)'};
      position: relative;
      top: ${({ isCollapsed }) => (isCollapsed ? '-2px' : '2px')};

      content: '';
      border-style: solid;
      border-width: 3px 3px 0 0;
      width: 1em;
      height: 1em;
    }
  }
`;

const TimeSection = styled(
  ({ gameTimer, isCollapsed, numPeriods, ...props }) => {
    const [periods, setPeriods] = useState([]);
    const [time, setTime] = useState('00:00');

    const { quarter, minutes, seconds, overtimeTracker } = gameTimer || {};

    useEffect(() => {
      setTime(formatGameClock(minutes, seconds));
    }, [minutes, seconds]);

    useEffect(() => {
      setPeriods(calculateGamePeriods(numPeriods, quarter, overtimeTracker));
    }, [numPeriods, quarter, overtimeTracker]);

    return (
      <div
        {...props}
        style={{
          ...props.style,
          borderBottom: !isCollapsed
            ? `1px solid ${colors.GRAY_TRANSPARENT[700]}`
            : 'none',
          // borderLeft: isCollapsed
          //   ? `1px solid ${colors.GRAY_TRANSPARENT[700]}`
          //   : 'none',
          display: 'flex',
          justifyContent: isCollapsed ? 'center' : 'space-between',
          paddingBottom: isCollapsed ? 0 : 12,
          // paddingLeft: isCollapsed ? 12 : 0,
          marginLeft: isCollapsed ? 15 : 0,
          alignItems: 'center',
          flexDirection: isCollapsed ? 'column-reverse' : 'row',
        }}
      >
        {isCollapsed ? (
          <div className="current-quarter">
            {periods.find((p) => p.active)?.label}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
            }}
          >
            {periods.map((p, idx) => (
              <SmallButton
                key={idx}
                disabled={!!p.disabled}
                $isActive={!!p.active}
              >
                {p.label}
              </SmallButton>
            ))}
          </div>
        )}

        <Clock 
          time={time}
          $isCollapsed={isCollapsed} 
          $isTimerRunning={isTimerRunning(gameTimer)}
          $isTimerPaused={isTimerPaused(gameTimer)}
        />
      </div>
    );
  }
)`
  .current-quarter {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-top: 12px;
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[600] : colors.GRAY[100]};
  }
`;

const Clock = styled(({ time, ...props }) => (
  <div {...props}>
    <span>{time}</span>
  </div>
))`
  background: ${({ $isTimerRunning, $isTimerPaused, theme }) =>
    $isTimerRunning
      ? colors.BLUE[100]
      : $isTimerPaused
      ? colors.YELLOW[100]
      : theme.lightMode
      ? colors.GRAY[1000]
      : colors.GRAY[1800]};
  border-radius: 8px;
  font-family: 'Digital Display', Orbitron;
  font-size: 26px;
  line-height: 1;
  letter-spacing: 3px;
  color: ${({ $isTimerPaused }) =>
    $isTimerPaused ? colors.BLACK[100] : colors.WHITE[100]};

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  height: 30px;
  width: 83px;
  box-sizing: border-box;
  padding: 0 11px 0 14px;
  ${({ $isCollapsed }) => (!$isCollapsed ? 'margin-left: 1em;' : '')}
`;

const ScoreSection = styled.div`
  ${({ $isCollapsed }) => ($isCollapsed ? '' : 'padding-top: 24px; ')}
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: ${({ $isCollapsed }) => ($isCollapsed ? '8px' : '15px')};
  row-gap: 9px;
  align-items: center;
  justify-items: center;
  width: 100%;
  ${({ $isCollapsed }) => ($isCollapsed ? 'margin-right: 12px;' : '')}
`;

const Score = styled(({ homeTeamScore, awayTeamScore, ...props }) => {
  return (
    <div {...props}>
      <div className="points-label">POINTS</div>
      <div className="score">
        <span>{homeTeamScore}</span>
        <span className="separator">|</span>
        <span>{awayTeamScore}</span>
      </div>
    </div>
  );
})`
  font-size: ${({ $isCollapsed }) => ($isCollapsed ? '31px' : '40px')};
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .points-label {
    font-size: ${({ $isCollapsed }) => ($isCollapsed ? '7px' : '12px')};
    font-weight: bold;
    background-color: ${colors.GRAY[700]};
    text-align: center;
    width: auto;
    padding: 2px 4px;
    margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '6px' : '12px')};
    border-radius: 2px;
  }

  .score {
    min-width: ${({ $isCollapsed }) => ($isCollapsed ? '116px' : '146px')};
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[200] : colors.WHITE[100]};
    line-height: 1;
    margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '6px' : '0')};

    span:nth-child(1) {
      justify-self: end;
    }

    span.separator {
      color: ${colors.GRAY[1050]};
      font-size: ${({ $isCollapsed }) => ($isCollapsed ? '24px' : '28px')};
      font-weight: 400;
      margin: 0 5px;
    }
  }

  @media ${MOBILE_MEDIA} {
    font-size: ${({ $isCollapsed }) => ($isCollapsed ? '27px' : '35px')};

    .points-label {
      font-size: ${({ $isCollapsed }) => ($isCollapsed ? '6px' : '10px')};
    }
  }
`;

const Fouls = styled(({ homeTeamFouls, awayTeamFouls, ...props }) => {
  return (
    <div {...props}>
      <h3>Fouls</h3>
      <span>{homeTeamFouls}</span>
      <span>{awayTeamFouls}</span>
    </div>
  );
})`
  h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: ${({ $isCollapsed }) => ($isCollapsed ? '0px' : '16px')};
    margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '2px' : '10px')};
    font-size: ${({ $isCollapsed }) => ($isCollapsed ? '9px' : '16px')};
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[150] : colors.GRAY[1075]};
  }
  span {
    padding: ${({ $isCollapsed }) => ($isCollapsed ? '3px 16px' : '4px 20px')};
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: ${({ $isCollapsed }) => ($isCollapsed ? '9px' : '16px')};
    background-color: ${({ theme }) =>
      theme.lightMode ? colors.WHITE[100] : colors.GRAY[1800]};
    color: ${({ theme }) =>
      theme.lightMode ? colors.BLACK[600] : colors.WHITE[100]};

    border: 1px solid
      ${({ theme }) => (theme.lightMode ? colors.GRAY[400] : colors.BLACK[500])};
    border-radius: 4px;

    &:nth-child(2) {
      margin-right: 4px;
    }
  }

  @media ${MOBILE_MEDIA} {
    h3 {
      font-size: ${({ $isCollapsed }) => ($isCollapsed ? '7px' : '13px')};
    }

    span {
      font-size: ${({ $isCollapsed }) => ($isCollapsed ? '10px' : '14px')};
    }
  }
`;
