import { NUM_PERIODS } from './clockManager.type';

function updateNumOfPeriodsReducer(state = {}, action) {
  switch (action.type) {
    case NUM_PERIODS:
      return action.payload;
    default:
      return null;
  }
}

export default updateNumOfPeriodsReducer;
