import React from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

const controlSize = 'md';
const controlStyle = {
  lineHeight: '2.0',
  textAlign: 'left',
};

const FormControlWithFormik = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <Form.Control
      {...field}
      {...props}
      // size={controlSize}
      // style={controlStyle}
      isInvalid={meta.touched && meta.error}
    />
  );
};

export default FormControlWithFormik;
