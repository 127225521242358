import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { useFetchAll, useItemsFromPages } from '../utils/reactQueryToolkit';
import {
  getLeagueImageProfile,
  listLeaguesSortedByUpdatedAt,
} from './leaguesService';

export const leaguesKeys = {
  all: ['leagues'],
  filter: (filter) => [...leaguesKeys.all, { filter }],
};

/**
 * React Query custom hook that fetches all leagues.
 * 
 * @param {Object} options - spread and passed to useInfiniteQuery.
 *      The following keys are reserved and will be overriden:
 *      queryKey, queryFn, getNextPageParam, onSuccess
 * @returns useInfiniteQuery return object
 */
export const useListLeaguesQuery = (options) => {
  const { onSuccessCallback } = useFetchAll(true);

  const query = useInfiniteQuery({
    ...options,
    queryKey: leaguesKeys.all,
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.XLARGE,
        nextToken: pageParam,
      };

      const leagueItems = await listLeaguesSortedByUpdatedAt(queryVars);

      /* Get the Image for each league */
      const leaguesWithImages = await Promise.all(
        leagueItems.items.map(async (league) => {
          league.image = await getLeagueImageProfile(league.id);
          return league;
        })
      );

      /* Sort based on last updated date */
      return {
        ...leagueItems,
        items: leaguesWithImages,
      };
    },
    getNextPageParam: (lastPage) => lastPage?.nextToken,
    onSuccess: (data) => onSuccessCallback(query)(data),
  });

  return {
    ...query,
    isSuccess: query.isSuccess && !query.hasNextPage,
    /* Aggregate leagues from all query pages into 1 array */
    data: useItemsFromPages(query.data?.pages).sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
  };
};

export const useLeaguesQuery = ({ filter, ...options } = {}) => {
  const query = useInfiniteQuery({
    ...options,
    queryKey: leaguesKeys.filter(filter),
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      if (filter?.length > 0) {
        queryVars = {
          filter: {
            or: [
              { searchName: { contains: filter.toLowerCase() } },
              { searchAbbreviation: { contains: filter.toLowerCase() } },
            ],
          },
          nextToken: pageParam,
        };
      }

      const leagueItems = await listLeaguesSortedByUpdatedAt(queryVars);

      /* Get the Image for each league */
      const leaguesWithImages = await Promise.all(
        leagueItems.items.map(async (league) => {
          league.image = await getLeagueImageProfile(league.id);
          return league;
        })
      );

      /* Sort based on last updated date */
      return {
        ...leagueItems,
        items: leaguesWithImages,
      };
    },
    getNextPageParam: (lastPage) => lastPage?.nextToken,
  });

  /* Aggregate leagues from all query pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};
