import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useField } from 'formik';

const InputSelectDropdown = ({ label, type, setRef, ...props }) => {
  /**
   * Sources: http://ericgio.github.io/react-bootstrap-typeahead/
   * TypeAhead: https://github.com/ericgio/react-bootstrap-typeahead
   * Floating Label Issue: https://github.com/ericgio/react-bootstrap-typeahead/pull/661
   * Input Rendering: https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/Rendering.md
   * Close Button Issue on BS5: https://github.com/ericgio/react-bootstrap-typeahead/issues/622#issuecomment-923246836
   */

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, helper] = useField(props);

  return (
    <Form.Group controlId={`form-${props.name}`}>
      <Typeahead
        clearButton
        {...props}
        ref={setRef}
        id={props.name}
        multiple={false}
        /**
         * Each object is expected to contain a unique id,. which should be their database id
         * {id: "...", name:"League Name", ...}
         */
        options={props.options}
        onChange={(selected) => {
          const value = selected.length > 0 ? selected[0].id : '';
          helper.setValue(value);
        }}
        onInputChange={(text) => {
          const selectedId = props.optionFinderFunction 
                             ? props.optionFinderFunction(text)?.id
                             : props.options.find(({ name }) => name === text)?.id;
          helper.setValue(selectedId);
        }}
        onBlur={() => helper.setTouched(true)}
        allowNew={false} /* AllowNew should always be false */
        /**
         * The 'labelKey' defined the format of the searchable items in the typeahead elements
         * if the input elements are objects: {id: "...", name:"League Name", abbreviation: "ABB"}
         * then a label to show 'League Name (ABB)' would look like this:
         * (option) => `${option.name} (${option.abbreviation})
         */
        labelKey={props.labelKey}
        {...(meta.touched && meta.error
          ? { isInvalid: true, className: 'is-invalid' }
          : { isValid: true })}
        /**
         * Yes this looks complicated, but all it is saying is:
         * "If I passed a default value (id) in field.value, then look for
         * it in the list of options and call the lavel formatter method
         * 'labelKey' on the default object"
         */
        defaultSelected={
          field.value &&
          props.options &&
          props.options.filter((obj) => {
            return obj.id === field.value;
          })
        }
        /**
         * The 'renderInput' prop allows us to customize the rendered component and pass
         * the typeahead properties along to the FloatingLabel control we want to display
         */
        renderInput={({ inputRef, referenceElementRef, defaultSelected, ...inputProps }) => (
          <FloatingLabel
            label={props.required ? `${label}*` : label}
            className={`label-text ${meta.touched && meta.error ? 'pb-0' : 'pb-2'}`}
          >
            <Form.Control
              /**
               * What are we conditionally passing '...inputProps'?
               * If we pass input props the typeahead work perfectly fine
               */
              // {...(!props.disabled ? inputProps : {})}
              {...inputProps}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
              // defaultValue={inputProps.defaultSelected}
              disabled={props.disabled}
              isInvalid={meta.touched && (props.customErrorMsg || meta.error)}
              placeholder="True"
              style={props.inputStyle || {}}
            />
            <Form.Control.Feedback type="invalid">
              {props.customErrorMsg ? props.customErrorMsg : meta.error}
            </Form.Control.Feedback>
          </FloatingLabel>
        )}
      />
    </Form.Group>
  );
};

export default InputSelectDropdown;
