import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SquareGrid from '../Grids/SquareGrid';
import { colors } from '../../styles';

const PageContainerLayout = ({ children }) => {
  return (
    <Row style={{ height: '96%'}}>
      <Col xl={12} md={12} xs={12}>
        <SquareGrid
          bgColor={colors.WHITE[100]}
          border={colors.GRAY[150]}
          height={'100%'}
          width={'98%'}
        >
          {children}
        </SquareGrid>
      </Col>
    </Row>
  );
};

export default PageContainerLayout;