import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';
import NavigationDrawer from '../Navigation/NavigationDrawer';
import { expandedDrawerWidth, collapsedDrawerWidth } from '../../styles/constants';
import { colors } from '../../styles/colors';

const Grid = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  width: auto;
  background-color: ${colors.GRAY[400]};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: ${props => props.drawerWidth} 1fr;
  grid-template-areas:
    'header header'
    'nav main'
    'footer footer';
`;

const GridNav = styled.nav`
  grid-area: nav;
`;

const GridHeader = styled.header`
  grid-area: header;
  z-index: 10;
`;

// commenting out scroll for main content
const GridMain = styled.main`
  width: calc(100% - 40px);
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    // hide the scrollbar but keep the functionality
    display: none;
  }
`;

const GridFooter = styled.footer`
  grid-area: footer;
  z-index: 10;
`;

const Layout = ({ children }) => {
  const [drawerWidth, setDrawerWidth] = useState(collapsedDrawerWidth);

  return (
    <Grid 
      drawerWidth={drawerWidth}
    >
      <GridHeader>
        <Header />
      </GridHeader>
      <GridNav>
        <NavigationDrawer 
          drawerWidth={drawerWidth}
          setIsDrawerCollapsed={(isCollapsed) => setDrawerWidth(isCollapsed ? collapsedDrawerWidth : expandedDrawerWidth)}
        />
      </GridNav>
      <GridMain>{children}</GridMain>
      <GridFooter>
        <Footer />
      </GridFooter>
    </Grid>
  );
};

export default Layout;
