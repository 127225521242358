import moment from 'moment';
import { 
	getTeam, 
	getTeamUserByUserIdTeamId,
} from '../api/teamsService';

export const getTeams = async (teamIds) => {
  const teams = await Promise.all(
  teamIds.map(async teamId => await getTeam(teamId))
  );
  return teams.sort((a, b) => a.name.localeCompare(b.name));
};

export const getTeamUserByTeamIds = async (userId, teamIds) => {
  const teamUsers = await Promise.all(
    teamIds.map(async (teamId) => await getTeamUserByUserIdTeamId(userId, teamId))
  );
  const teamUsersItems = teamUsers?.map(({ items }) => items)
                                  ?.reduce((updated, current) => ([ ...updated, ...current ]), []);
  return teamUsersItems;
};

export const getSelectedTeams = (teams, teamGamesItems, teamIdsAndSeasonIds) => {
  const selectedTeams = teamIdsAndSeasonIds.map(({ teamId, seasonId }) => {
    const team = teams.find(({ id }) => id === teamId);

    const { id, leagueId, league } = team;
    const { abbreviation } = league;

    const gamesAndSeasons = teamGamesItems.filter(userItemData => userItemData.teamId === teamId && userItemData.season.seasonId === seasonId)
                                          .map(({ game, season }) => ({ game, season }));

    const { seasonType, seasonStartDate, seasonEndDate } = gamesAndSeasons[0].season;

    return {
      id,
      teamName: team.name,
      seasonId,
      seasonType,
      seasonStartDate,
      seasonEndDate,
      leagueId,
      leagueName: league.name,
      leagueAbbreviation: abbreviation,
      games: gamesAndSeasons.map(({ game }) => game).sort((a, b) => moment(a.gameDateTime).valueOf() - moment(b.gameDateTime).valueOf())
    };
  });
  return selectedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
};

export const getAssignedTeams = async (teamItems) => {
	const stringifiedAssignedTeamRole = teamItems.map(({ teamId, seasonId, gameRole }) => JSON.stringify({ teamId, seasonId, gameRole }));
	const assignedTeamRoles = Array.from( new Set(stringifiedAssignedTeamRole) ).map(coachRole => JSON.parse(coachRole));
	const asyncAssignedTeams = assignedTeamRoles.map(async ({ teamId, seasonId, gameRole }) => {
		const team = await getTeam(teamId);
		team.role = gameRole;
    team.seasonId = seasonId;
		return team;
	});
	const asyncAssignedTeamsResponse = await Promise.all(asyncAssignedTeams);
	return asyncAssignedTeamsResponse;
};