import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import GameLeaders from '../team/GameLeaders';
import GameTeamStats from './GameTeamStats';
import { GameStatsContext } from './GameStatsContext';

const leftStatsContainerStyles = {
  width: '33%',
  marginRight: 7,
};

const GameLeftStats = ({
}) => {
  const { 
    setStatsTab, 
    awayTeamImage, 
    homeTeamImage, 
    awayTeamPlayersGameStats, 
    homeTeamPlayersGameStats, 
    statsContentStyles, 
  } = useContext(GameStatsContext);

  return (
    <Col xs={3} className='p-0' style={{ ...statsContentStyles, ...leftStatsContainerStyles, }}>
      <GameLeaders
        setStatsTab={setStatsTab}
        awayTeamImage={awayTeamImage}
        homeTeamImage={homeTeamImage}
        awayTeamPlayersGameStats={awayTeamPlayersGameStats}
        homeTeamPlayersGameStats={homeTeamPlayersGameStats}
      />
      <GameTeamStats
        setStatsTab={setStatsTab}
      />
    </Col>
  );
};

export default GameLeftStats;