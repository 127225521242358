export const ClockManagerEvents = {
  GAME_PAUSED: 'GAME_PAUSED',
  GAME_RESUMED: 'GAME_RESUMED',
  GAME_STARTED: 'GAME_STARTED',
  GAME_ENDED: 'GAME_ENDED',
  GAME_CANCELED: 'GAME_CANCELED',
  SUBSTITUTION: 'SUBSTITUTION',
  JUMPBALL: 'JUMPBALL',
  TIMEOUT: 'TIMEOUT',
};

export const ClockEvents = [
  ClockManagerEvents.GAME_PAUSED,
  ClockManagerEvents.GAME_RESUMED,
  ClockManagerEvents.GAME_STARTED,
  ClockManagerEvents.GAME_ENDED,
  ClockManagerEvents.GAME_CANCELED,
];

export default ClockManagerEvents;
