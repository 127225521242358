import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../styles';
import StatsDataContainer from './StatsDataContainer';

const StatsHeaderTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  color: ${colors.WHITE[100]};
  font: normal normal 16px/46px 'Segoe UI Bold';
`;

const PLAYER_STATS_DATA = [
  // {data: ''},
  {data: 'FG'},
  {data: '3PT'},
  {data: 'FT'},
  {data: 'OREB'},
  {data: 'DREB'},
  {data: 'REB'},
  {data: 'AST'},
  {data: 'STL'},
  {data: 'BLK'},
  {data: 'TO'},
  {data: 'PF'},
  // {data: '+/-'},
  {data: 'PTS'},
];

const StatsHeader = ({
  headerTitle, 
}) => {
  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, backgroundColor: colors.BLACK[100], }}>
      <Col xs={12} className="p-0">
        <StatsDataContainer
          keyText={headerTitle.toLowerCase()}
          dataItems={PLAYER_STATS_DATA}
          dataItemStyle={{ font: 'normal normal 14px/46px "Segoe UI Bold"', color: colors.WHITE[100], }}
        >
          <StatsHeaderTitle>
            {headerTitle.toUpperCase()}
          </StatsHeaderTitle>
        </StatsDataContainer>
      </Col>
    </Row>
  );
};

export default StatsHeader;