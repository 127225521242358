import moment from "moment";
import { date } from "yup";

export const dateStringToAwsDate = (dateString) =>
  new Date(dateString).toISOString().substring(0, 10);

const getFormattedDateTimeItem = (dateFormatString, dateTime, queryString) => {
  return moment(dateTime).format(dateFormatString).toLowerCase().includes(queryString.toLowerCase());
};

const getMappedFormattedDateTimeItem = (dateFormatString, dateTime, queryString) => {
  return moment(dateTime).format(dateFormatString).toLowerCase().includes(queryString.toLowerCase()) ? moment(dateTime).format(dateFormatString) : '';
};

export const isMomentDateStringValid = (dateString, dateFormat) => moment(dateString, dateFormat, true).isValid();

export const getMomentInstance = (dateString) => {
  const dateFormats = [
    'MM/DD/YYYY',
    'MM-DD-YYYY',
    'MM/DD/YY',
    'MM-DD-YY',
    'MM Do YYYY',
    'MM Do YY',
    'MMM DD YYYY',
    'MMM Do YY',
    'MMMM Do YYYY',
    'MMMM Do YY',
    'MM DD YYYY',
    'MMMM DD YYYY',
    'MMM Do YYYY',
    'D-MM-YY',
    'D/MM/YY',
    'DD-MM-YY',
    'DD/MM/YY',
    'D/MM/YYYY',
    'DD/MM/YYYY',
  ];

  const dateStringFormat = dateFormats.find(format => isMomentDateStringValid(dateString, format));
  return moment(dateString, dateStringFormat);
};

export const isDateStringValid = dateString => {
  return (
    isMomentDateStringValid(dateString, 'MM/DD/YYYY') ||
    isMomentDateStringValid(dateString, 'MM-DD-YYYY') ||
    isMomentDateStringValid(dateString, 'MM/DD/YY') ||
    isMomentDateStringValid(dateString, 'MM-DD-YY') ||
    isMomentDateStringValid(dateString, 'MM Do YYYY') ||
    isMomentDateStringValid(dateString, 'MM Do YY') ||
    isMomentDateStringValid(dateString, 'MMM DD YYYY') ||
    isMomentDateStringValid(dateString, 'MMM Do YY') ||
    isMomentDateStringValid(dateString, 'MMMM Do YYYY') ||
    isMomentDateStringValid(dateString, 'MMMM Do YY') ||
    isMomentDateStringValid(dateString, 'MM DD YYYY') ||
    isMomentDateStringValid(dateString, 'MMMM DD YYYY') ||
    isMomentDateStringValid(dateString, 'MMM Do YYYY') ||
    isMomentDateStringValid(dateString, 'D-MM-YY') ||
    isMomentDateStringValid(dateString, 'D/MM/YY') ||
    isMomentDateStringValid(dateString, 'DD-MM-YY') ||
    isMomentDateStringValid(dateString, 'DD/MM/YY') ||
    isMomentDateStringValid(dateString, 'D/MM/YYYY') ||
    isMomentDateStringValid(dateString, 'DD/MM/YYYY') 
  );
};

export const isDateTimeStringEqualToQueryString = (dateTime, queryString) => {
  return (
    getFormattedDateTimeItem('MM/DD/YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM-DD-YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM/DD/YY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM-DD-YY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM Do YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM Do YY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMM DD YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMM Do YY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMMM Do YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMMM Do YY', dateTime, queryString) || 
    getFormattedDateTimeItem('MM DD YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMMM DD YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('MMM Do YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('D-MM-YY', dateTime, queryString) || 
    getFormattedDateTimeItem('D/MM/YY', dateTime, queryString) || 
    getFormattedDateTimeItem('DD-MM-YY', dateTime, queryString) || 
    getFormattedDateTimeItem('DD/MM/YY', dateTime, queryString) || 
    getFormattedDateTimeItem('D/MM/YYYY', dateTime, queryString) || 
    getFormattedDateTimeItem('DD/MM/YYYY', dateTime, queryString)
  );
};

export const getMappedFormattedDateTimeString = (dateTime, queryString) => {
  return (
    getMappedFormattedDateTimeItem('MM/DD/YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM-DD-YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM/DD/YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM-DD-YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM Do YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM Do YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMM Do YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMM Do YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMMM Do YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMMM Do YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MM DD YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMM DD YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('MMMM DD YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('D-MM-YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('D/MM/YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('DD-MM-YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('DD/MM/YY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('D/MM/YYYY', dateTime, queryString) || 
    getMappedFormattedDateTimeItem('DD/MM/YYYY', dateTime, queryString)
  );
};

export const filterDateTimeStringByProperty = (dateTime, property) => {
  return (
    (moment(dateTime).format('MM/DD/YYYY') === property) || 
    (moment(dateTime).format('MM-DD-YYYY') === property) || 
    (moment(dateTime).format('MM/DD/YY') === property) || 
    (moment(dateTime).format('MM-DD-YY') === property) || 
    (moment(dateTime).format('MM Do YYYY') === property) || 
    (moment(dateTime).format('MM Do YY') === property) || 
    (moment(dateTime).format('MMM Do YYYY') === property) || 
    (moment(dateTime).format('MMM Do YY') === property) || 
    (moment(dateTime).format('MMMM Do YYYY') === property) || 
    (moment(dateTime).format('MMMM Do YY') === property) || 
    (moment(dateTime).format('MM DD YYYY') === property) || 
    (moment(dateTime).format('MMM DD YYYY') === property) || 
    (moment(dateTime).format('MMMM DD YYYY') === property) || 
    (moment(dateTime).format('D-MM-YY') === property) || 
    (moment(dateTime).format('D/MM/YY') === property) || 
    (moment(dateTime).format('DD-MM-YY') === property) || 
    (moment(dateTime).format('DD/MM/YY') === property) || 
    (moment(dateTime).format('D/MM/YYYY') === property) || 
    (moment(dateTime).format('D/MM/YYYY') === property) || 
    (moment(dateTime).format('DD/MM/YYYY') === property)
  );
};

const getFormattedDateWithSuffixedDaySuperscript = (dateTime, dateFormatText, punctuationTextAfterSup = '', isUpperCase) => {
  const dateTimeFormat = moment(dateTime, 'YYYY-MM-DD');
  const monthDayFormat = dateTimeFormat.format('Do');
  const daySuffix = monthDayFormat.substring(monthDayFormat.length - 2, monthDayFormat.length);
  const dateFormatString = dateTimeFormat.format(dateFormatText).split(' ').map((dateItem, idx, items) => {
    if(dateItem === monthDayFormat) {
      const daySuffixIndex = monthDayFormat.indexOf(daySuffix);
      const dayNum = monthDayFormat.substring(0, daySuffixIndex);
      return <span key={idx}><span>{dayNum}</span><sup>{daySuffix.toLowerCase()}</sup><span>{punctuationTextAfterSup}&nbsp;&nbsp;</span></span>;
    }
    return idx < items.length - 1 ? <span key={idx}>{isUpperCase ? dateItem.toUpperCase() : dateItem}&nbsp;&nbsp;</span> : <span key={idx}>{isUpperCase ? dateItem.toUpperCase() : dateItem}</span>;
  });
  return dateFormatString;
};

export const getFormattedDateInUppercaseWithDaySuffixedSuperscript = (dateTime, dateFormatText, punctuationTextAfterSup = '') => {
  return getFormattedDateWithSuffixedDaySuperscript(dateTime, dateFormatText, punctuationTextAfterSup, true);
};

export const getFormattedDateCasedWithDaySuffixedSuperScript = (dateTime, dateFormatText, punctuationTextAfterSup = '') => {
  return getFormattedDateWithSuffixedDaySuperscript(dateTime, dateFormatText, punctuationTextAfterSup, false);
};

export const expandDateRange = (dateRange1, dateRange2) => {
  const result = dateRangeToMoment(dateRange1);
  let diff;

  if (moment(dateRange2.startDate).isBefore(dateRange1.startDate)) {
    result.startDate = moment(dateRange2.startDate);
    diff = {
      startDate: result.startDate,
      endDate: moment(dateRange1.startDate),
    };
  } else if (moment(dateRange2.endDate).isAfter(dateRange1.endDate)) {
    result.endDate = moment(dateRange2.endDate);
    diff = {
      startDate: moment(dateRange1.endDate),
      endDate: result.endDate,
    };
  }

  return [result, diff];
}

export const dateRangeToMoment = (dateRange) => ({
  startDate: moment(dateRange.startDate),
  endDate: moment(dateRange.endDate),
});

export const dateRangeToISOString = (dateRange) => ({
  startDate: moment(dateRange.startDate).toISOString(),
  endDate: moment(dateRange.endDate).toISOString(),
});