import React from 'react';
import { 
  PlayByPlayHeaderContainer, 
  PeriodColumnHeaderContent, 
  PeriodColumnHeaderContainer, 
} from './PeriodColumnHeader';
import PlayByPlayColumn from './PlayByPlayColumn';

const ColumnImage = ({
  teamImage,
}) => {
  return (
    <img
      src={teamImage}
      width="30px"
      height="30px"
      style={{ objectFit: 'contain', }}
    />
  );
};

const PlayByPlayHeaders = ({
  homeTeamImage,
  awayTeamImage,
}) => {
  return (
    <PlayByPlayHeaderContainer>
      <PlayByPlayColumn 
        width='10%' 
        rightBorderWidth={1}
        bottomBorder={1}
      >
        TIME
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='8%' 
        rightBorderWidth={1}
        bottomBorder={1}
      >
        <PeriodColumnHeaderContainer>
          <PeriodColumnHeaderContent
            width="72%"
          >
            TEAM
          </PeriodColumnHeaderContent>
        </PeriodColumnHeaderContainer>
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='32%' 
        rightBorderWidth={1}
        bottomBorder={1}
      >
        <PeriodColumnHeaderContainer>
          <PeriodColumnHeaderContent
            width='80%'
          >
            PLAYER
          </PeriodColumnHeaderContent>
        </PeriodColumnHeaderContainer>
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='40%' 
        rightBorderWidth={1}
        bottomBorder={1}
      >
        <PeriodColumnHeaderContainer>
          <PeriodColumnHeaderContent
            width='80%'
          >
            PLAY
          </PeriodColumnHeaderContent>
        </PeriodColumnHeaderContainer>
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='5%'
        bottomBorder={1}
        rightBorderWidth={1}
      >
        <ColumnImage
          teamImage={homeTeamImage}
        />
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='5%' 
        rightBorderWidth={1}
        bottomBorder={1}
      >
        <ColumnImage
          teamImage={awayTeamImage}
        />
      </PlayByPlayColumn>
    </PlayByPlayHeaderContainer>
  );
};

export default PlayByPlayHeaders;