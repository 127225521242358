import styled from 'styled-components';
import { colors } from '../../../styles';

export const StatsTitleHeader = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  font: normal normal 600 15px/17px 'Segoe UI Bold';
  color: ${colors.BLACK[100]};
  border-bottom: ${props => props.borderWidth}px solid #D5D5D5;
`;