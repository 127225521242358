import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddCircleButtonStyled = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: ${colors.BLUE[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  &:hover {
    background-color: ${colors.PRIMARY[300]};
    cursor: pointer;
  }
`;

const PlusSignStyled = styled.span`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 5px;
  color: ${colors.WHITE[100]};
`;

const ButtonLabelStyle = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const AddCircleButton = ({
  buttonLabel = 'Add Button',
  buttonClickHandler = () => {},
}) => {
  return (
    <ButtonContainer>
      <AddCircleButtonStyled onClick={buttonClickHandler}>
        <PlusSignStyled>+</PlusSignStyled>
      </AddCircleButtonStyled>
      <ButtonLabelStyle>{buttonLabel}</ButtonLabelStyle>
    </ButtonContainer>
  );
};

export default AddCircleButton;
