import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

// const squareGridCardStyle = {
//   borderWidth: 1,
//   borderStyle: 'solid',
//   borderRadius: 5,
//   display: 'inline-flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   margin: '10px',
//   position: 'relative',
//   boxShadow: '2px 2px 5px #0000001a',
// };

const GridCard = styled(Card)`
  border-style: solid;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin || '10px'};
  position: relative;
  box-shadow: 2px 2px 5px #0000001a;
  &.selected {
    border: 2px solid #25A9E1 !important;
    background-color: #F3FCFF !important;
  }
  &:hover {
    cursor: ${(props) => props.$hasHover && 'pointer'};
  }
`;

const SquareGrid = ({
  children,
  bgColor,
  borderColor,
  borderWidth = 1,
  height,
  width,
  justifyContent = 'flex-start',
  selected,
  hasHover = false,
  transitionProperty, 
  navigateToGameDetailsPage,
  ...props
}) => {


  return (
    <GridCard
      style={{
        height: height || 'auto',
        width: width || 'auto',
        backgroundColor: bgColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        justifyContent: justifyContent,
        position: 'relative',
        transition: transitionProperty && `${transitionProperty} 1s ease`,
      }}
      className={selected ? "selected" : ""}
      $hasHover={hasHover}
      {...props}
    >
      {children}
    </GridCard>
  );
};

export default SquareGrid;
