const PlayerValidation = {
  firstName: {
    isRequired: { value: true, message: 'First Name is required' }
  },
  lastName: {
    isRequired: { value: true, message: 'Last Name is required' }
  },
  position: {
    isRequired: { value: true, message: 'Position is required' },
    options: { value: [ 'Center', 'Power Forward', 'Small Forward', 'Point Guard', 'Shooting Guard' ], message: 'Invalid Position(s)' }
  },
  jerseyNumber: {
    isRequired: { value: true, message: 'Jersey Number is required' },
    min: { value: 0, message: 'Min 0' },
    max: { value: 99, message: 'Max 99' }
  },
  dateOfBirth: {
    isRequired: { value: true, message: 'Date of Birth is required' }
  },
  birthCity: {
    isRequired: { value: true, message: 'Birth City is required' },
    regex: { value: /^([A-Za-z](\s)?[A-Za-z]*)+$/g, message: 'Birth City is invalid' },
  },
  birthState: {
    isRequired: { value: true, message: 'Birth State is required' },
    regex: { value: /^([A-Za-z](\s)?[A-Za-z]*)+$/g, message: 'Birth State is invalid' },
    options: { message: 'Invalid State Or Province' }
  },
  birthCountry: {
    isRequired: { value: true, message: 'Birth Country is required' },
    regex: { value: /^([A-Za-z](\s)?[A-Za-z]*)+$/g, message: 'Birth Country is invalid' },
    options: { message: 'Invalid Country' }
  },
  weight: {
    isRequired: { value: true, message: 'Weight is required' },
    min: { value: 0, message: 'Min 0' }
  },
  heightInFeet: {
    isRequired: { value: true, message: 'Height in Feet is required' }
  },
  heightInInches: {
    isRequired: { value: true, message: 'Height in Inches is required' }
  },
  institute: {
    isRequired: { value: true, message: 'Institute is required' }
  },
  leagueId: {
    isRequired: { value: true, message: 'League is required'}
  },
  image: {
    isRequired: { value: true, message: 'Upload Player Headshot' }
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' }
  }
};

export default PlayerValidation;