export const GameTimerStatus = {
  /** TODO: Add remaining Game Timer Statuses */
  CREATED_NOT_STARTED: 'Game Not Started',

  QUARTER_RUNNING: 'Quarter Running',
  QUARTER_PAUSED: 'Quarter Paused',
  QUARTER_ENDED: 'Quarter Ended',

  OVERTIME_RUNNING: 'Overtime Running',
  OVERTIME_PAUSED: 'Overtime Paused',
  OVERTIME_ENDED: 'Overtime Ended',

  /**
   * @deprecated Not used by Clock service. Switch to `OVERTIME_ENDED`.
   */
  OVERTIME_NOT_STARTED: 'Overtime Not Started',
  /**
   * @deprecated Not used by Clock service. Switch to `QUARTER_ENDED`.
   */
  QUARTER_NOT_STARTED: 'Quarter Not started',
  /**
   * @deprecated Not used by Clock service.
   * Switch to `QUARTER_PAUSED` or `OVERTIME_PAUSED`.
   */
  PAUSED: 'Paused',
};

/**
 * Returns if the GameTimer is created but not started
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in 'created' state
 */
export function isGameNotStarted(timer) {
  return timer?.msg === GameTimerStatus.CREATED_NOT_STARTED;
}

/**
 * Returns if the GameTimer is at the end of a non-overtime Period
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in 'QUARTER_ENDED' state
 */
export function isQuarterEnded(timer) {
  return timer?.timerStatus === GameTimerStatus.QUARTER_ENDED;
}

/**
 * Returns if the GameTimer is at the end of the final non-overtime Period
 * @param {*} timer GameTimer object from time server
 * @param {number} numPeriods Total number of periods for thid game/league
 * @returns {boolean} True if GameTimer is in 'QUARTER_ENDED' state and current period == numPeriods
 */
export function isFinalQuarterEnded(timer, numPeriods) {
  return (
    timer?.timerStatus === GameTimerStatus.QUARTER_ENDED &&
    timer?.quarter === numPeriods
  );
}

/**
 * Returns if the GameTimer is at the end of an overtime Period
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in 'OVERTIME_ENDED' state
 */
export function isOvertimeEnded(timer) {
  return timer?.timerStatus === GameTimerStatus.OVERTIME_ENDED;
}

/**
 * Returns whether the GameTimer is at the end of a period.
 * 
 * @param {Object} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer period has ended (either in 'QUARTER_ENDED' or 'OVERTIME_ENDED' state)
 */
export function isPeriodEnded(timer) {
  return isQuarterEnded(timer) || isOvertimeEnded(timer);
}

/**
 * Returns the string identifier of the next period.
 *  For non-overtime periods, format is `Q<quarter>`
 *  For overtime periods, format is `OT<overtimeTracker>`
 * 
 * @param {*} timer GameTimer object from time server
 * @param {number} numPeriods Total number of periods for thid game/league
 * @returns {string} Text for next period name (Q1, Q2, ... OT1, OT2, ...)
 */
export function getNextPeriodText(timer, numPeriods) {
  return isGameNotStarted(timer)
    ? 'Q1'
    : isFinalQuarterEnded(timer, numPeriods)
    ? 'OT1'
    : isQuarterEnded(timer)
    ? `Q${timer?.quarter + 1}`
    : isOvertimeEnded(timer)
    ? `OT${timer?.overtimeTracker + 1}`
    : 'OT1';
}

/**
 * Returns if the GameTimer is in a state that it can be 'started'
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in the CREATED_NOT_STARTED, QUARTER_ENDED or OVERTIME_ENDED state
 */
export function canGameTimerBeStarted(timer) {
  return (
    isGameNotStarted(timer) || isQuarterEnded(timer) || isOvertimeEnded(timer)
  );
}

/**
 * Returns if the GameTimer is in a state that it can be 'started' by a jumpball 
 * for the first period or an overtime period
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in the CREATED_NOT_STARTED, the 'final' QUARTER_ENDED or OVERTIME_ENDED state
 */
export function canGameTimerBeStartedJumpball(timer, numPeriods) {
  return (
    isGameNotStarted(timer) ||
    isFinalQuarterEnded(timer, numPeriods) ||
    isOvertimeEnded(timer)
  );
}

/**
 * Returns if the GameTimer is in a state that it can be 'resumed'
 * @param {*} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is in the QUARTER_PAUSED, OVERTIME_PAUSED state
 */
export function canGameTimerBeResumed(timer) {
  return (
    timer?.timerStatus === GameTimerStatus.QUARTER_PAUSED ||
    timer?.timerStatus === GameTimerStatus.OVERTIME_PAUSED
  );
}

/** Alias for {@link canGameTimerBeResumed}  */
export { canGameTimerBeResumed as isTimerPaused };

/**
 * Returns if the GameTimer is running.
 * @param {Object} timer GameTimer object from time server
 * @returns {boolean} True if GameTimer is running (in the QUARTER_RUNNING, OVERTIME_RUNNING state)
 */
export function isTimerRunning(timer) {
  return [
    GameTimerStatus.QUARTER_RUNNING,
    GameTimerStatus.OVERTIME_RUNNING,
  ].includes(timer?.timerStatus);
}

export const HALVES = ['1st Half', '2nd Half'];
export const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];

const generateOvertimePeriod = (overtimeTracker) =>
  `OT${overtimeTracker ?? ''}`;
export const formatGameClock = (minutes, seconds) =>
  `${(minutes || 0).toString().padStart(2, '0')}:${(seconds || 0)
    .toString()
    .padStart(2, '0')}`;
export const formatGameTimestamp = (minutes, seconds) =>
  `${(minutes || 0).toString()}:${(seconds || 0).toString().padStart(2, '0')}`;

export const calculateGamePeriods = (numPeriods, quarter, overtimeTracker) => {
  const isOvertime = overtimeTracker > 0;
  const overtimePeriod = {
    label: generateOvertimePeriod(overtimeTracker),
    active: isOvertime,
    disabled: false,
  };

  const quarterIdx = quarter ? quarter - 1 : -1;
  const periods = (numPeriods === 2 ? HALVES : QUARTERS).map((p, idx) => ({
    label: p,
    active: !isOvertime && idx === quarterIdx,
    disabled: isOvertime || idx < quarterIdx,
  }));

  return [...periods, overtimePeriod];
};

/**
 * Returns the string identifier of the current/previous period.
 *  For non-overtime periods, format is `Q<quarter>`
 *  For overtime periods, format is `OT<overtimeTracker>`
 * 
 * @param {Object} timer GameTimer object from time server
 * @param {number} numPeriods Total number of periods for thid game/league
 * @returns {string} Text for current period name (Q1, Q2, ... OT1, OT2, ...)
 */
export const getGamePeriod = (numPeriods, quarter, overtimeTracker) => {
  const isOvertime = overtimeTracker > 0;
  if (isOvertime) return generateOvertimePeriod(overtimeTracker);

  const quarterIdx = quarter - 1;
  const periods = numPeriods === 2 ? HALVES : QUARTERS;
  if (quarterIdx < 0 || quarterIdx >= periods.length) return '';

  return periods[quarterIdx];
};
