import React, { useState } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { useLongPress } from 'use-long-press';
import JerseyLight from '../../../assets/svg/JerseyLight.svg';
import { ItemTypes } from '../ItemTypes';
import CloseIcon from '../../../assets/close @2x.png';
import { colors, gradients } from '../../../styles/colors';
import { capitalizeFirstLetter, truncateText } from '../../../utils/stringUtil';
import { ALERT_VARIANTS } from '../../alerts/Alert';

const StyledImage = styled.img`
  width: auto;
  height: 2.6vh;
  object-fit: contain;
`;

const StyledPlayerDiv = styled.div`
  user-select: none;
	background: ${({ isOnBench, notStarted, isHighlighted }) =>
    isHighlighted
      ? gradients.BLUE
      : isOnBench === ItemTypes.BENCH
      ? 'linear-gradient(180deg, #B6B6B6, #5E5E5E 100%)'
      : isOnBench === ItemTypes.COURT && !notStarted
      ? '#5a5a5a'
      : 'linear-gradient(180deg, #ffffff 0%, #c8c8c8 100%)'};
	border-radius: 4px;
	border: ${(props) =>
    props.isSelected
      ? `2px solid ${colors.BLUE[400]}}`
      : props.isOnBench === ItemTypes.COURT && !props.notStarted
      ? `1px ${colors.BLACK[100]}`
      : props.isDragging
      ? `2px solid ${colors.BLUE[400]}}`
      : `1px solid ${colors.GRAY[850]}`} ;
	width: 18vw;
	height: 3.4vh;
	margin-bottom: 0.6vh;
	margin-top: 0.2vh;
	padding: 2px 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #333333
	position: relative;
  &:hover {
    cursor: ${(props) => (props.isMutating ? 'wait' : 'pointer')};
  }
`;

const StyledPlayerName = styled.div`
  margin-left: 0.5vw;
  font: 600 11px Open Sans;
  color: ${({ isHighlighted }) =>
    isHighlighted ? colors.WHITE[100] : colors.BLACK[250]};
`;

const StyledJerseyDiv = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

const StyledJerseyNumber = styled.div`
  font: normal 8px/8px 'Race Sport';
  position: absolute;
  width: 100%;
  text-align: center;
  top: 55%;
`;

const StyledCloseIconDiv = styled.div`
  width: 10%;
  height: 100%;
  margin-left: auto;
  position: relative;
`;
const StyledCloseIcon = styled.img`
  width: 1.8vw;
  height: auto;
  position: absolute;
  left: 85%;
  top: -45%;
  z-index: 2;
`;

const DraggablePlayer = ({
  player,
  playerId,
  firstName,
  lastName,
  jerseyNumber,
  type,
  onIconClick,
  notStarted,
  version,
  toggleMultiSelection,
  isSelected,
  togglePlayerSelection,
  selectedPlayers,
  resetSelection,
  isMutating = false,
  isHighlighted,
  highlightColor,
}) => {
  const [isClickHandlerEnabled, setIsClickHandlerEnabled] = useState(true);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isMutating,
      type: type,
      item: () => {
        let selection;

        // Check if selection includes dragged player
        if (selectedPlayers.find((item) => item.playerId === playerId)) {
          selection = selectedPlayers;
        } else {
          resetSelection();
          selection = [
            {
              playerId,
              player,
              type,
              version,
            },
          ];
        }
        return selection;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [selectedPlayers, playerId, player, type, version]
  );

  const bindUseLongPress = useLongPress(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      // Prevent immediate deselect upon mouseup
      setIsClickHandlerEnabled(false);
      setTimeout(() => setIsClickHandlerEnabled(true), 1000);

      toggleMultiSelection(type, {
        playerId,
        player,
        type,
        version,
      });
    },
    {
      threshold: 500,
      cancelOnMovement: 10,
      captureEvent: true,
    }
  );

  return (
    <div
      onClick={(e) => {
        if (!isClickHandlerEnabled) return;
        e.preventDefault();
        togglePlayerSelection({
          playerId,
          player,
          type,
          version,
        });
      }}
    >
      <StyledPlayerDiv
        notStarted={notStarted}
        ref={drag}
        isDragging={isDragging}
        style={{ opacity: isDragging ? 1 : 1 }}
        isOnBench={type}
        isSelected={isSelected}
        {...bindUseLongPress()}
        isMutating={isMutating}
        isHighlighted={isHighlighted && highlightColor === ALERT_VARIANTS.BLUE}
      >
        <StyledJerseyDiv>
          <StyledImage src={JerseyLight} />
          <StyledJerseyNumber>{jerseyNumber}</StyledJerseyNumber>
        </StyledJerseyDiv>
        <StyledPlayerName isHighlighted={isHighlighted}>
          {capitalizeFirstLetter(
            `${truncateText(firstName, 10)} ${truncateText(lastName, 10)}`
          )}
        </StyledPlayerName>
        {(type === ItemTypes.BENCH ||
          (type === ItemTypes.COURT && notStarted)) && (
          <StyledCloseIconDiv onClick={onIconClick}>
            <StyledCloseIcon src={CloseIcon} />
          </StyledCloseIconDiv>
        )}
      </StyledPlayerDiv>
    </div>
  );
};

export default DraggablePlayer;
