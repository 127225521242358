import { API, graphqlOperation } from 'aws-amplify';
import {
  listSeasons as listSeasonQueries,
  getSeason as getSeasonQueries,
  getSeasonByUpdatedAt as getSeasonByUpdatedAtQuery,
} from '../graphql/queries';
import { createSeason as createSeasonMutation } from '../graphql/mutations';
import { Storage } from '@aws-amplify/storage';
import { DataStore } from '@aws-amplify/datastore';
import { Season } from '../models';
import SeasonLeagueTrophyImage from '../assets/league_trophy.png';
import { KEY_SORT_ID } from '../utils/constantsUtils';
const log = require('../logger')('api');

Storage.configure({ level: 'public' });

export const getSeasonImageProfile = async (leagueId) => {
  try {
    const doesImageExist = await Storage.list(`league/${leagueId}`);
    if (doesImageExist.length > 0) {
      const imageURL = await Storage.get(`league/${leagueId}`);
      return imageURL;
    } else {
      return SeasonLeagueTrophyImage;
    }
  } catch (error) {
    log.error(`Unable to read image ${leagueId} from storage...`);
  }
};

// get call with parameter
export const listSeasons = async (variables = null) => {
  try {
    const seasonData = await API.graphql(
      graphqlOperation(listSeasonQueries, variables)
    );
    return seasonData.data.listSeasons;
  } catch (err) {
    log.error('error fetching seasons...', err);
    return err;
  }
};

export const listSeasonsSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    const seasonData = await API.graphql(
      graphqlOperation(getSeasonByUpdatedAtQuery, vars)
    );
    return seasonData.data.getSeasonByUpdatedAt;
  } catch (err) {
    log.error('error fetching seasons...', err);
    return err;
  }
};

// get call with parameter
export const getSeason = async (id) => {
  try {
    const seasonData = await API.graphql(
      graphqlOperation(getSeasonQueries, { id: id })
    );
    const seasonItem = seasonData.data.getSeason;
    return seasonItem;
  } catch (err) {
    log.error('Error fetching season...', err);
  }
};

//create call with parameter
export const createSeason = async (
  seasonType,
  leagueId,
  startDate,
  finishDate
) => {
  try {
    const seasonData = await API.graphql(
      graphqlOperation(createSeasonMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          seasonType: seasonType,
          leagueId: leagueId,
          startDate: new Date(startDate).toISOString().substring(0, 10),
          endDate: new Date(finishDate).toISOString().substring(0, 10),
        },
      })
    );
    return seasonData;
  } catch (err) {
    log.error('Error creating season...', err);
    return err;
  }
};

// TODO: If used convert back to graphqlOperation, use id and update .version
export const updateSeason = async (name, city, state, description, league) => {
  try {
    // const seasonData = await API.graphql(
    // 	graphqlOperation(UpdateSeason, {
    // 		input: {
    // 			name: name,
    // 			city: city,
    // 			state: state,
    // 			description: description,
    // 			leagueId: leagueId,
    // 		},
    // 	})
    // );

    const original = await DataStore.query(Season, league);

    const seasonData = await DataStore.save(
      Season.copyOf(original, (updated) => {
        updated.name = name;
        updated.city = city;
        updated.state = state;
        updated.description = description;
        updated.league = league;
      })
    );

    return seasonData;
  } catch (err) {
    log.error('Error updating season...', err);
    return err;
  }
};

// TODO: If used convert back to graphqlOperation
export const deleteSeason = async (id) => {
  try {
    // const seasonData = await API.graphql(
    // 	graphqlOperation(DeleteSeason, { input: { id: id } })
    // );

    const toDelete = await DataStore.query(Season, id);
    const seasonData = await DataStore.delete(toDelete);
    return seasonData;
  } catch (err) {
    log.error('Error deleting season...', err);
  }
};
