import { useInfiniteQuery } from 'react-query';
import { getGamePlayByPlayEvents } from '../api/eventsService';
import { LIST_PAGINATION_LIMIT, SHOT_LOCATION_EVENTS } from './constantsUtils';
import { useFetchAll, useItemsFromPages } from './reactQueryToolkit';

const getGamePeriodPlayByPlayEvents = async (gameId, gamePeriod, periodType, teamAssets, nextToken) => {
  let periodPlayByPlayEvents = await getGamePlayByPlayEvents(
    periodType,
    {
      limit: LIST_PAGINATION_LIMIT.LARGE,
      sortDirection: 'ASC',
      nextToken: nextToken,
      gameId: gameId,
      [periodType]: {
        eq: gamePeriod
      },
    }
  );
  periodPlayByPlayEvents.items = periodPlayByPlayEvents.items.map(periodEvent => {
                                                    const { gamePeriodMinutes, gamePeriodSeconds, gameOvertimeMinutes, gameOvertimeSeconds } = periodEvent;
                                                    const periodSeconds = periodType === "gamePeriodNumber" ? gamePeriodSeconds : gameOvertimeSeconds;
                                                    const periodMinutes = periodType === "gamePeriodNumber" ? gamePeriodMinutes : gameOvertimeMinutes;
                                                    const secondsFormat = periodSeconds.toString().length === 1 ? `0${periodSeconds}` : periodSeconds.toString();
                                                    const gamePeriodTime = `${periodMinutes}:${secondsFormat === '60' ? '00' : secondsFormat}`;
                                                    const gameEventTimeSeconds = (60 * periodMinutes) + periodSeconds;
                                                    periodEvent.eventPlayer = periodEvent.gameLineupPlayer ? `#${periodEvent.gameLineupPlayer.playerJerseyNumber} ${periodEvent.gameLineupPlayer.playerFirstName} ${periodEvent.gameLineupPlayer.playerLastName}` : null;
                                                    periodEvent.eventTeamLogo = teamAssets[periodEvent.teamId];
                                                    periodEvent.gamePeriodTime = gamePeriodTime;
                                                    periodEvent.gameEventTimeSeconds = gameEventTimeSeconds;
                                                    return periodEvent;
                                                  })
                                                  .filter(({ isDeleted }) => isDeleted === false);
  return periodPlayByPlayEvents;
};

const usePeriodPlayByPlayEvent = (queryKey, playByPlayEventParams, teamAssets) => {
  const { onSuccessCallback } = useFetchAll(true);

  const gamePeriodPlayByPlayEventResponse = 
  useInfiniteQuery([queryKey, { playByPlayEventParams }], 
    async ({ pageParam }) => {
      const { gameId, periodNumber, periodType } = playByPlayEventParams;
      const gamePeriodEvents = await getGamePeriodPlayByPlayEvents(gameId, periodNumber, periodType, teamAssets, pageParam);
      return gamePeriodEvents;
    }, 
    {
      enabled: !!playByPlayEventParams?.gameId && !!playByPlayEventParams?.periodNumber && !!playByPlayEventParams?.periodType,
      onSuccess: (data) => onSuccessCallback(gamePeriodPlayByPlayEventResponse)(data),
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken
    }
  );

  const gamePeriodPlayByPlayEvents = useItemsFromPages(gamePeriodPlayByPlayEventResponse.data?.pages)
                                      ?.sort((a, b) => b.gameEventTimeSeconds - a.gameEventTimeSeconds);

  const selectedPeriodEvents = gamePeriodPlayByPlayEvents?.filter(({ relatedEventId }) => relatedEventId === null);
  const periodRelatedEvents = gamePeriodPlayByPlayEvents?.filter(({ relatedEventId }) => relatedEventId !== null);
  const isPeriodEventsLoading = gamePeriodPlayByPlayEventResponse.isLoading;
  return { selectedPeriodEvents, periodRelatedEvents, isPeriodEventsLoading };
};

export default usePeriodPlayByPlayEvent;