import { useState } from 'react'
import Switch from './Switch'

const ObjectSwitch = ({ active, disabled, updateObject }) => {
	const [isChecked, setIsChecked] = useState(active)
	
	const handleChange = () => {
		updateObject()
		setIsChecked(!isChecked)
	}
	
	return (<Switch disabled={disabled} handleChange={handleChange} isChecked={isChecked} />)
}

export default ObjectSwitch;