import styled from 'styled-components';
import { colors } from '../../../styles';

export const SummaryTeamStats = styled.div`
  width: 100%;
  height: ${props => props.height};
  background-color: ${colors.WHITE[100]};
  border-radius: 10px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
  border: 1px solid #D5D5D5;
  padding: 10px;
`;
