import { createSlice } from '@reduxjs/toolkit';
import { GamesTabs } from '../utils/gameUtil';

export const gamesPageSlice = createSlice({
  name: 'gamesPage',
  initialState: {
    scrollPositions: {
      [GamesTabs.TODAY]: 0,
      [GamesTabs.UPCOMING]: 0,
      [GamesTabs.PAST]: 0,
    },
  },
  reducers: {
    updateScrollPosition: (state, action) => {
      state.scrollPositions[action.payload.tab] = action.payload.scrollPosition;
    },
    resetAllScrollPositions: (state) => {
      state.scrollPositions[GamesTabs.TODAY] = 0;
      state.scrollPositions[GamesTabs.UPCOMING] = 0;
      state.scrollPositions[GamesTabs.PAST] = 0;
    },
  },
});

export const {
  updateScrollPosition,
  resetAllScrollPositions,
} = gamesPageSlice.actions;

export const selectScrollPositions = (state) => state.gamesPage.scrollPositions;

export default gamesPageSlice.reducer;
