import React from 'react'
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const StyledHint = styled.div`
	color: ${(props) => props.color};
    text-align: center;
`;

const StyledHintIconDiv = styled.div`
	font-size: 1.5vw;
`;

const StyledHintText = styled.div`
	font-size: 1.1vw;
	line-height: 1.2vw;
	font: normal normal normal Open Sans;
`;

const AddPlayerHint = ({ text, fontColor }) => {
	return (
		<StyledHint color={fontColor}>
			<StyledHintIconDiv>
				<AddCircleOutlineIcon fontSize='inherit' />
			</StyledHintIconDiv>
			<StyledHintText>{text}</StyledHintText>
		</StyledHint>
	);
};

export default AddPlayerHint