import { useCallback, useMemo, useEffect } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import moment from 'moment';
import {
  getGame,
  getGameByDateTime,
  getGameLineup,
  getGamesInDateRange,
  getGameTeamStats,
} from './gamesService';
import { getTeamImageProfile, listTeamUsers } from './teamsService';
import { deriveTeamLineupProperties } from '../utils/gameLineupUtils';
import { GAME_STATUS, LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import {
  aggregateBooleanPropUsingOr,
  fetchAllGraphQL,
  useFetchAll,
  useItemsFromPages,
} from '../utils/reactQueryToolkit';
import { useGetTeamPlayersQuery } from './teamsQueries';
import { isDateStringValid, getMomentInstance } from '../utils/momentDateUtil';
import { getAssignedUserGameRole } from '../utils/userUtil';
import { getGamesForGameTabs } from '../utils/gameUtil';
import { ADMIN, COACH } from '../data/roles';
const log = require('../logger')('gamesQueries', 'debug'); // eslint-disable-line no-unused-vars

export const gamesKeys = {
  all: ['games'],
  stats: () => [...gamesKeys.all, 'stats'],
  statsUserRole: (userRole) => [...gamesKeys.all, { userRole }],
  detail: (gameId) => [...gamesKeys.all, { gameId }],

  /** Games occurring today */
  today: () => [...gamesKeys.all, 'today'],
  /** Games occurring after today */
  upcoming: () => [...gamesKeys.all, 'upcoming'],
  /** Games occurring before today */
  past: () => [...gamesKeys.all, 'past'],
  /**
   * Games occurring after current datetime, optionally filtered by "propertyId".
   * This is either the id for team, league, season, or venue.
   * It can also be a date format supported by {@link isDateStringValid}.
   */
  future: (propertyId) => [...gamesKeys.all, 'future', { propertyId }],
  /** Can use this to query the cache for all cached date range queries */
  allDateRange: () => [...gamesKeys.all, 'dateRange'],
  /** Games occurring with the specified date range */
  dateRange: (startDate, endDate) => [
    ...gamesKeys.allDateRange(),
    { startDate, endDate },
  ],
  previousPage: (date) => [...gamesKeys.all, 'previous', { date }],
  nextPage: (date) => [...gamesKeys.all, 'next', { date }],
};

export const teamUserKeys = {
  all: ['teamUsers'],
  teamUserGames: (userId, userRole) => [...teamUserKeys.all, { userId, userRole }],
};

export const gameLineupKeys = {
  all: ['gameLineup'],
  detail: (gameLineupId) => [...gameLineupKeys.all, { gameLineupId }],

  /* TODO change this query to using 2 'detail' queries, better for caching */
  gameLineupPair: (homeTeamGameLineupId, awayTeamGameLineupId) => [
    ...gameLineupKeys.all,
    { homeTeamGameLineupId, awayTeamGameLineupId },
  ],
};

/**
 * Array.map() callback functions for fetching additional data for each game.
 */
export const gamesMapFn = {
  teamImages: async (game) => {
    const { homeTeamId, awayTeamId, homeTeamName, awayTeamName } = game;
    const [homeTeamImage, awayTeamImage] = await Promise.all([
      getTeamImageProfile(homeTeamId),
      getTeamImageProfile(awayTeamId),
    ]);
    game.homeTeamImage = homeTeamImage;
    game.awayTeamImage = awayTeamImage;
    game.homeTeamVsAwayTeam = `${homeTeamName} VS ${awayTeamName}`;
    return game;
  },
  gameStats: async (game) => {
    const { id, homeTeamId, awayTeamId, homeTeamName, awayTeamName } = game;
    game.gameId = id;
    game.homeTeamVsAwayTeam = `${homeTeamName} Vs ${awayTeamName}`;

    const [homeTeamImage, awayTeamImage, gameStats] = await Promise.all([
      getTeamImageProfile(homeTeamId),
      getTeamImageProfile(awayTeamId),
      getGameTeamStats(id),
    ]);

    game.homeTeamImage = homeTeamImage;
    game.awayTeamImage = awayTeamImage;

    const gameStatsData = gameStats.data.getGameTeamStats;
    game.homeTeamScore =
      (game.gameStatus === GAME_STATUS.CANCELED ||
        game.gameStatus === GAME_STATUS.LIVE ||
        game.gameStatus === null) &&
      !gameStatsData?.home_total_points
        ? 0
        : gameStatsData?.home_total_points;
    game.awayTeamScore =
      (game.gameStatus === GAME_STATUS.CANCELED ||
        game.gameStatus === GAME_STATUS.LIVE ||
        game.gameStatus === null) &&
      !gameStatsData?.away_total_points
        ? 0
        : gameStatsData?.away_total_points;
    game.isStatsPage = true;

    return game;
  },
  gameRoles: (userItems) => (game) => {
    const gameRolesData = getAssignedUserGameRole(userItems, game.id);
    return {
      ...game,
      gameRoles: gameRolesData,
    };
  },
  teamImagesAndGameRoles: (userItems) => 
    async (game) => await gamesMapFn.teamImages(gamesMapFn.gameRoles(userItems)(game)),
};

/**
 * @param {Array} games array of games
 * @returns array of games with images
 */
export const fetchGameImages = async (games) =>
  await Promise.all(games.map(gamesMapFn.teamImages));

/**
 * @param {Array} games array of games
 * @returns array of games with images and game roles
 */
export const fetchGameImagesAndGameRoles = async (games, userItems) =>
  await Promise.all(games.map(gamesMapFn.teamImagesAndGameRoles(userItems)));

/**
 *
 *
 * @param {Object} options Any options to pass to useInfiniteQuery to override defaults, except for
 *                         queryKey, queryFn, getNextPageParam, onSuccess.
 * @returns
 */
export const useGameStatsQuery = (userRole, options) => {
  const { fetchAllCallback, cancelFetchAll, onSuccessCallback } = useFetchAll();

  const query = useInfiniteQuery({
    ...options,
    enabled: userRole === ADMIN,
    queryKey: gamesKeys.statsUserRole(userRole),
    queryFn: async ({ pageParam }) => {
      const games = await getGameByDateTime({
        gameDateTime: { lt: moment().endOf('day').toISOString() },
        sortDirection: 'DESC',
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      });

      return {
        ...games,
        items: await Promise.all(games.items?.map(gamesMapFn.gameStats)),
      };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    onSuccess: ({ pages, pageParams }) => {
      onSuccessCallback(query)({ pages, pageParams });
    },
  });

  /** Flatten items from all pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  const sortedGames = useMemo(() => {
    const liveGames = data.filter(
      ({ gameStatus }) => gameStatus === GAME_STATUS.LIVE
    );

    const nonLiveGames = data.filter(
      ({ gameStatus }) => gameStatus !== GAME_STATUS.LIVE
    );

    return [...liveGames, ...nonLiveGames];
  }, [data]);

  const fetchAll = useCallback(() => fetchAllCallback([query]), [
    fetchAllCallback,
    query,
  ]);

  return {
    ...query,
    data: sortedGames,
    fetchAll,
    cancelFetchAll,
  };
};

export const useCoachGamesByUserId = (userId, userRole, options = {}) => {
  const {  fetchAllCallback, cancelFetchAll, onSuccessCallback } = useFetchAll(true);

  const query = useInfiniteQuery({
    ...options,
    queryKey: teamUserKeys.teamUserGames(userId, userRole),
    enabled: !!userId && userRole === COACH,
    queryFn: async ({ pageParam }) => {
      const queryParams = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      queryParams.filter = {
        and: [
          { userId: { eq: userId } },
          { gameRole: { eq: userRole } },
        ]
      };

      const teamUsers = await listTeamUsers(queryParams);
      const games = teamUsers.items.map(({ game }) => game);

      return { items: await Promise.all(games.map(gamesMapFn.gameStats)), };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
    onSuccess: data => onSuccessCallback(query)(data),
  });

  const data = useItemsFromPages(query.data?.pages)
                .sort((a, b) => moment(b.gameDateTime).valueOf() - moment(a.gameDateTime).valueOf());

  const sortedGames = useMemo(() => {
    const liveGames = data.filter(
      ({ gameStatus }) => gameStatus === GAME_STATUS.LIVE
    );

    const nonLiveGames = data.filter(
      ({ gameStatus }) => gameStatus !== GAME_STATUS.LIVE
    );

    return [...liveGames, ...nonLiveGames];
  }, [data]);

  const fetchAll = useCallback(() => fetchAllCallback([query]), [
    fetchAllCallback,
    query,
  ]);

  return {
    ...query,
    data: sortedGames,
    fetchAll,
    cancelFetchAll,
  };
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const useTodaysGamesQuery = ({
  /**
   * Array.map() callback function applied to each game
   */
  gameMapCallbackFn = gamesMapFn.teamImages,
  /**
   * Any options to pass to useInfiniteQuery to override defaults, except for:
   * queryKey, queryFn, getNextPageParam, onSuccess
   */
  ...options
}) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.today(),
    queryFn: async ({ pageParam }) => {
      const games = await getGameByDateTime({
        gameDateTime: {
          between: [
            moment().startOf('day').toISOString(),
            moment().endOf('day').toISOString(),
          ],
        },
        sortDirection: 'ASC',
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      });

      return {
        ...games,
        items: gameMapCallbackFn
          ? await Promise.all(games.items?.map(gameMapCallbackFn))
          : games.items,
      };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  /** Flatten items from all pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return { ...query, data };
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const useUpcomingGamesQuery = ({
  /**
   * Array.map() callback function applied to each game
   */
  gameMapCallbackFn = gamesMapFn.teamImages,
  /**
   * Any options to pass to useInfiniteQuery to override defaults, except for:
   * queryKey, queryFn, getNextPageParam, onSuccess
   */
  ...options
}) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.upcoming(),
    queryFn: async ({ pageParam }) => {
      const games = await getGameByDateTime({
        gameDateTime: { gt: moment().endOf('day').toISOString() },
        sortDirection: 'ASC',
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      });

      return {
        ...games,
        items: gameMapCallbackFn
          ? await Promise.all(games.items?.map(gameMapCallbackFn))
          : games.items,
      };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  /** Flatten items from all pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return { ...query, data };
};

/**
 *
 * @param {*} param0
 * @returns
 */
 export const useGamesPreviousPageFinderQuery = (
   date,
   /**
    * Any options to pass to useInfiniteQuery to override defaults, except for:
    * queryKey, queryFn, getNextPageParam, onSuccess
    */
   options = {}
 ) => {
   const query = useQuery({
     refetchOnWindowFocus: false,
     ...options,
     queryKey: gamesKeys.previousPage(date),
     queryFn: async ({ pageParam }) => {
       const games = await getGameByDateTime({
         gameDateTime: { lt: moment(date).startOf('day').toISOString() },
         sortDirection: 'DESC',
         limit: LIST_PAGINATION_LIMIT.SMALL,
         nextToken: pageParam,
       });

       return games;
     },
   });

   /** Flatten items from all pages into 1 array */
   const data = query.data?.items ?? [];
   const count = data.length;
   const startOfPage = data[0];
   const endOfPage = data[count - 1] ?? startOfPage;

   return { ...query, count, startOfPage, endOfPage };
 };

/**
 *
 * @param {*} param0
 * @returns
 */
export const useGamesNextPageFinderQuery = (
  date,
  /**
   * Any options to pass to useInfiniteQuery to override defaults, except for:
   * queryKey, queryFn, getNextPageParam, onSuccess
   */
  options = {}
) => {
  const query = useQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.nextPage(date),
    queryFn: async ({ pageParam }) => {
      const games = await getGameByDateTime({
        gameDateTime: { gt: moment(date).endOf('day').toISOString() },
        sortDirection: 'ASC',
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      });

      return games;
    },
  });

  /** Flatten items from all pages into 1 array */
  const data = query.data?.items ?? [];
  const count = data.length;
  const startOfPage = data[0];
  const endOfPage = data[count - 1] ?? startOfPage;

  return { ...query, count, startOfPage, endOfPage };
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const usePastGamesQuery = ({
  /**
   * Array.map() callback function applied to each game
   */
  gameMapCallbackFn = gamesMapFn.teamImages,
  /**
   * Any options to pass to useInfiniteQuery to override defaults, except for:
   * queryKey, queryFn, getNextPageParam, onSuccess
   */
  ...options
}) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.past(),
    queryFn: async ({ pageParam }) => {
      const games = await getGameByDateTime({
        gameDateTime: { lt: moment().startOf('day').toISOString() },
        sortDirection: 'DESC',
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      });

      return {
        ...games,
        items: gameMapCallbackFn
          ? await Promise.all(games.items?.map(gameMapCallbackFn))
          : games.items,
      };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  /** Flatten items from all pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return { ...query, data };
};

export const useGamesDateRangeQuery = (
  startDate,
  endDate,
  {
    /**
     * Array.map() callback function applied to each game
     */
    gameMapCallbackFn = gamesMapFn.teamImages,
    /**
     * Any options to pass to useInfiniteQuery to override defaults, except for:
     * queryKey, queryFn, getNextPageParam, onSuccess
     */
    ...options
  }
) =>
  useQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.dateRange(startDate, endDate),
    queryFn: async () => {
      let games = await fetchAllGraphQL(
        async (nextToken) =>
          await getGamesInDateRange(startDate, endDate, {
            nextToken,
          })
      );

      if (gameMapCallbackFn)
        games = await Promise.all(games.map(gameMapCallbackFn));

      return games;
    },
  });

/**
 *
 * @param {*} param0
 * @returns
 */
export const useFutureGamesQuery = ({
  propertyId,
  /**
   * Array.map() callback function applied to each game
   */
  gameMapCallbackFn,
  /**
   * Any options to pass to useInfiniteQuery to override defaults, except for:
   * queryKey, queryFn, getNextPageParam, onSuccess
   */
  ...options
}) => {
  const query = useInfiniteQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: gamesKeys.future(propertyId),
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        gameDateTime: { gt: moment().toISOString() },
        sortDirection: 'ASC',
        limit: LIST_PAGINATION_LIMIT.LARGE,
        nextToken: pageParam,
      };

      if (propertyId) {
        if (isDateStringValid(propertyId)) {
          queryVars.gameDateTime = {
            between: [
              getMomentInstance(propertyId).startOf('day').toISOString(),
              getMomentInstance(propertyId).endOf('day').toISOString(),
            ],
          };
        } else {
          queryVars.filter = {
            or: [
              { homeTeamId: { eq: propertyId } },
              { awayTeamId: { eq: propertyId } },
              { leagueId: { eq: propertyId } },
              { seasonId: { eq: propertyId } },
              { venueId: { eq: propertyId } },
            ],
          };
        }
      }

      const games = await getGameByDateTime(queryVars);

      return {
        ...games,
        items: gameMapCallbackFn
          ? await Promise.all(games.items?.map(gameMapCallbackFn))
          : games.items,
      };
    },
    getNextPageParam: (lastPage, pages) => lastPage?.nextToken,
  });

  /** Flatten items from all pages into 1 array */
  const data = useItemsFromPages(query.data?.pages);

  return { ...query, data };
};

/**
 *
 * @param {Array} userItems
 * @param {Object} options
 * @returns
 */
export const useHomepageGamesTabsQuery = (userItems, options) => {
  const {
    todaysGamesQuery,
    upcomingGamesQuery,
    pastGamesQuery,
    todaysGames,
    upcomingGames,
    pastGames,
    isLoading,
    isFetching,
    fetchAllGames,
    cancelFetchAll,
  } = useGamesTabsQuery(options);

  const getGameRoles = useMemo(() => gamesMapFn.gameRoles(userItems), [
    userItems,
  ]);

  useEffect(() => {
    log.debug('getGameRoles has changed', userItems);
  }, [getGameRoles]);

  const todaysGamesWithRoles = useMemo(() => todaysGames.map(getGameRoles), [
    getGameRoles,
    todaysGames,
  ]);
  const upcomingGamesWithRoles = useMemo(
    () => upcomingGames.map(getGameRoles),
    [getGameRoles, upcomingGames]
  );
  const pastGamesWithRoles = useMemo(() => pastGames.map(getGameRoles), [
    getGameRoles,
    pastGames,
  ]);

  const allGamesWithRoles = useMemo(
    () => [
      ...todaysGamesWithRoles,
      ...upcomingGamesWithRoles,
      ...pastGamesWithRoles,
    ],
    [pastGamesWithRoles, todaysGamesWithRoles, upcomingGamesWithRoles]
  );

  useEffect(() => {
    log.debug('todaysGamesWithRoles has changed', todaysGamesWithRoles);
  }, [todaysGamesWithRoles]);

  return {
    todaysGamesQuery,
    upcomingGamesQuery,
    pastGamesQuery,
    todaysGames: todaysGamesWithRoles,
    upcomingGames: upcomingGamesWithRoles,
    pastGames: pastGamesWithRoles,
    allGames: allGamesWithRoles,
    isLoading,
    isFetching,
    fetchAllGames,
    cancelFetchAll,
  };
};

/**
 *
 * @param {Array} userItems
 * @param {Object} options
 * @returns
 */
export const useHomepageGamesTabsDateRangeQuery = (
  startDate,
  endDate,
  userItems,
  options = {}
) => {
  const query = useGamesDateRangeQuery(
    moment(startDate).toISOString(),
    moment(endDate).toISOString(),
    {
      keepPreviousData: true,
      ...options,
    }
  );
  
  const allGames = useMemo(
    () => query.data?.map(gamesMapFn.gameRoles(userItems)) || [],
    [query.data, userItems]
  );

  const { todaysGames, upcomingGames, pastGames } = useMemo(
    () => getGamesForGameTabs(allGames),
    [allGames]
  );

  return {
    ...query,
    allGames,
    todaysGames,
    upcomingGames,
    pastGames,
  };
};

export const useGamesTabsQuery = (options = {}) => {
  const { fetchAllCallback, cancelFetchAll, onSuccessCallback } = useFetchAll();

  const todaysGamesQuery = useTodaysGamesQuery({
    ...options,
    onSuccess: (data) => onSuccessCallback(todaysGamesQuery)(data),
  });
  const upcomingGamesQuery = useUpcomingGamesQuery({
    ...options,
    onSuccess: (data) => onSuccessCallback(upcomingGamesQuery)(data),
  });
  const pastGamesQuery = usePastGamesQuery({
    ...options,
    onSuccess: (data) => onSuccessCallback(pastGamesQuery)(data),
  });
  const queries = [todaysGamesQuery, upcomingGamesQuery, pastGamesQuery];

  const todaysGames = todaysGamesQuery.data;
  const upcomingGames = upcomingGamesQuery.data;
  const pastGames = pastGamesQuery.data;
  const allGames = useMemo(
    () => [...todaysGames, ...upcomingGames, ...pastGames],
    [pastGames, todaysGames, upcomingGames]
  );

  /** Are any of the games queries still loading? */
  const isLoading = aggregateBooleanPropUsingOr(queries, 'isLoading');
  /** Are any of the games queries still fetching more items? */
  const isFetching = aggregateBooleanPropUsingOr(queries, 'isFetching');

  const fetchAllGames = () => fetchAllCallback(queries);

  return {
    todaysGamesQuery,
    upcomingGamesQuery,
    pastGamesQuery,
    todaysGames,
    upcomingGames,
    pastGames,
    allGames,
    isLoading,
    isFetching,
    fetchAllGames,
    cancelFetchAll,
  };
};

export const useGetGameQuery = (gameId) =>
  useQuery({
    queryKey: gamesKeys.detail(gameId),
    queryFn: async () => {
      return await getGame(gameId);
    },
    enabled: !!gameId,
  });

/**
 *
 * @param {*} homeTeamGameLineupId
 * @param {*} awayTeamGameLineupId
 * @param {*} select an optional function that allows for additonal data transformation given the data object.
 * @returns
 * 
 * TODO should switch to a hook fetching a single lineup, so they can be cached separately.
 */
export const useGetGameLineupQuery = (
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  select
) =>
  useQuery({
    queryKey: gameLineupKeys.gameLineupPair(homeTeamGameLineupId, awayTeamGameLineupId),
    enabled: !!homeTeamGameLineupId && !!awayTeamGameLineupId,
    select,
    placeholderData: {},
    queryFn: useCallback(async () => {
      const [
        homeTeamGameLineupData,
        awayTeamGameLineupData,
      ] = await Promise.all([
        getGameLineup(homeTeamGameLineupId),
        getGameLineup(awayTeamGameLineupId),
      ]);

      const homeTeamLineup = homeTeamGameLineupData.players.items.filter(
        (player) => !player._deleted
      );
      const awayTeamLineup = awayTeamGameLineupData.players.items.filter(
        (player) => !player._deleted
      );

      return {
        /**
         * home team lineup array, with deleted GameLineupPlayers removed
         */
        homeTeamLineup,
        /**
         * away team lineup array, with deleted GameLineupPlayers removed
         */
        awayTeamLineup,
        /**
         * home team lineup array, with all GameLineupPlayers
         */
        homeTeamLineupData: homeTeamGameLineupData.players.items,
        /**
         * away team lineup array, with all GameLineupPlayers
         */
        awayTeamLineupData: awayTeamGameLineupData.players.items,
      };
    }, [homeTeamGameLineupId, awayTeamGameLineupId]),
  });

export const useDerivedGameLineupDataQuery = (
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  homeTeamId,
  awayTeamId
) => {
  const {
    data: {
      homeTeamLineup,
      awayTeamLineup,
      homeTeamLineupData,
      awayTeamLineupData,
    } = {},
    isSuccess: isSuccessGameLineup,
  } = useGetGameLineupQuery(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    ({
      homeTeamLineup,
      awayTeamLineup,
      homeTeamLineupData,
      awayTeamLineupData,
    }) => ({
      homeTeamLineup: homeTeamLineup?.map((player) => {
        player.teamId = homeTeamId;
        return player;
      }),
      awayTeamLineup: awayTeamLineup?.map((player) => {
        player.teamId = awayTeamId;
        return player;
      }),
      homeTeamLineupData: homeTeamLineupData?.map((player) => {
        player.teamId = homeTeamId;
        return player;
      }),
      awayTeamLineupData: awayTeamLineupData?.map((player) => {
        player.teamId = awayTeamId;
        return player;
      }),
    })
  );
  const {
    data: homeTeamPlayers,
    isSuccess: isSuccessHomeTeamPlayers,
  } = useGetTeamPlayersQuery(homeTeamId);
  const {
    data: awayTeamPlayers,
    isSuccess: isSuccessAwayTeamPlayers,
  } = useGetTeamPlayersQuery(awayTeamId);
  const isSuccess =
    isSuccessGameLineup && isSuccessHomeTeamPlayers && isSuccessAwayTeamPlayers;

  const homeTeamRosterPlayers = useMemo(
    () => deriveRosterPlayers(homeTeamPlayers, homeTeamLineup),
    [homeTeamPlayers, homeTeamLineup]
  );
  const awayTeamRosterPlayers = useMemo(
    () => deriveRosterPlayers(awayTeamPlayers, awayTeamLineup),
    [awayTeamPlayers, awayTeamLineup]
  );

  const homeTeamLineupMap = useMemo(
    () => createTeamLineupMap(homeTeamLineupData),
    [homeTeamLineupData]
  );
  const awayTeamLineupMap = useMemo(
    () => createTeamLineupMap(awayTeamLineupData),
    [awayTeamLineupData]
  );

  const derivedLineupProps = useMemo(
    () => deriveTeamLineupProperties(homeTeamLineup, awayTeamLineup),
    [homeTeamLineup, awayTeamLineup]
  );

  return {
    isSuccess,
    data: useMemo(
      () => ({
        homeTeamLineupData,
        awayTeamLineupData,
        homeTeamPlayers,
        awayTeamPlayers,
        homeTeamLineup,
        awayTeamLineup,
        homeTeamLineupMap,
        awayTeamLineupMap,
        homeTeamRosterPlayers,
        awayTeamRosterPlayers,
        ...derivedLineupProps,
      }),
      [
        awayTeamLineup,
        awayTeamLineupData,
        awayTeamLineupMap,
        awayTeamPlayers,
        awayTeamRosterPlayers,
        derivedLineupProps,
        homeTeamLineup,
        homeTeamLineupData,
        homeTeamLineupMap,
        homeTeamPlayers,
        homeTeamRosterPlayers,
      ]
    ),
  };
};

/**
 *
 * @param {*} teamLineup team lineup array. Note that each player is mutated by ensuring the teamId property is set.
 * @returns a map of the team lineup, with key=id and value=player
 */
const createTeamLineupMap = (teamLineup) => {
  const teamLineupMap = new Map();

  // Add team id to each player
  teamLineup?.forEach((player) => {
    teamLineupMap.set(player.id, player);
  });

  return teamLineupMap;
};

const deriveRosterPlayers = (teamPlayers, teamLineup) =>
  teamPlayers?.filter(
    ({ playerId }) =>
      !teamLineup?.find((playerLineup) => playerLineup.playerId === playerId)
  );
