import { createSlice } from '@reduxjs/toolkit';
import { GameTabsSliceObj } from './gameTabsReduxSliceUtil';

export const gamesPageTabsSlice = createSlice({
	name: 'gamesPageTabs',
	...GameTabsSliceObj,
});

export const { updateActiveTab, updateCanChangeTabs } = gamesPageTabsSlice.actions;

export const selectActiveTab = (state) => state.gamesPageTabs.activeTab

export const selectCanChangeTabs = (state) => state.gamesPageTabs.canChangeTabs;

export default gamesPageTabsSlice.reducer;
