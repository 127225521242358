import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import BasketballCourtShotLocationDisplay from './BasketballCourtShotLocationDisplay';
import TeamPlayerSelectionControl from './TeamPlayerSelectionControl';
import GamePeriodControl from './GamePeriodControl';
import HomeTeamShotsMadeIndicator from '../../../assets/home_team_shots_made_indicator@2x.png';
import HomeTeamShotsMissedIndicator from '../../../assets/home_team_shots_missed_indicator@2x.png';
import AwayTeamShotsMadeIndicator from '../../../assets/away_team_shots_made_indicator@2x.png';
import AwayTeamShotsMissedIndicator from '../../../assets/away_team_shots_missed_indicator@2x.png';
import { colors } from '../../../styles';
import { SHOT_LOCATION_EVENTS } from '../../../utils/constantsUtils';
// import { shotLocationJsonData } from '../../../data/shotLocation';

const playeByPlayCenterControlContentStyles = { 
  width: '100%', 
  height: 'auto', 
  backgroundColor: colors.WHITE[100], 
  marginBottom: 10, 
  borderRadius: 10, 
  border: '2px solid #D5D5D5',
};

const BasketballCourtControl = ({
  game,
  shotLocationJsonData,
  gamePeriodOptions = [],
  homeTeamPlayers = [],
  awayTeamPlayers = [],
}) => {
  const [awayTeamShotMadeLocations, setAwayTeamShotMadeLocations] = useState([]);
  const [homeTeamShotMadeLocations, setHomeTeamShotMadeLocations] = useState([]);
  const [awayTeamShotMissedLocations, setAwayTeamShotMissedLocations] = useState([]);
  const [homeTeamShotMissedLocations, setHomeTeamShotMissedLocations] = useState([]);
  const [showHomeTeamShotsMade, setShowHomeTeamShotsMade] = useState(true);
  const [showHomeTeamShotsMissed, setShowHomeTeamShotsMissed] = useState(true);
  const [showAwayTeamShotsMade, setShowAwayTeamShotsMade] = useState(true);
  const [showAwayTeamShotsMissed, setShowAwayTeamShotsMissed] = useState(true);
  const [gamePeriodValue, setGamePeriodValue] = useState('All Periods');
  const [homeTeamPlayerValue, setHomeTeamPlayerValue] = useState('');
  const [awayTeamPlayerValue, setAwayTeamPlayerValue] = useState('');
  const [resetIconImgStatus, setResetIconImgStatus] = useState(false);

  const homeTeamShotMadeStatusRef = useRef(true);
  const homeTeamShotMissedStatusRef = useRef(true);
  const awayTeamShotMadeStatusRef = useRef(true);
  const awayTeamShotMissedStatusRef = useRef(true);
  const selectedGamePeriodRef = useRef("All Periods");
  const selectedHomeTeamPlayerIdRef = useRef('');
  const selectedAwayTeamPlayerIdRef = useRef('');
  const allPeriodsShotLocationsDataRef = useRef([]);
  const homeTeamPlayersRef = useRef([]);
  const awayTeamPlayersRef = useRef([]);

  useEffect(() => {
    setShotLocationPositions(game, shotLocationJsonData);
    findPlayersWithShots(homeTeamPlayers, awayTeamPlayers);
  }, []);

  useEffect(() => {
    const resetIconImgStatus = (
      gamePeriodValue !== 'All Periods' || 
      homeTeamPlayerValue !== '' || 
      awayTeamPlayerValue !== '' || 
      showHomeTeamShotsMade === false || 
      showHomeTeamShotsMissed === false || 
      showAwayTeamShotsMade === false || 
      showAwayTeamShotsMissed === false
    );
    setResetIconImgStatus(resetIconImgStatus);
  }, [
    gamePeriodValue,
    homeTeamPlayerValue,
    awayTeamPlayerValue,
    showHomeTeamShotsMade,
    showHomeTeamShotsMissed,
    showAwayTeamShotsMade,
    showAwayTeamShotsMissed,
  ]);

  /**
   * This is a select handler method that displays shots made and missed for a selected Game Period.
   * @param {string} selectedPeriod
   * @param {string} awayTeamId
   * @param {string} homeTeamId
   */
  const selectShotLocationsForPeriods = useCallback((selectedPeriod, awayTeamId, homeTeamId) => {
    setGamePeriodValue(selectedPeriod);
    displayGamePeriodsShotLocations(
      shotLocationJsonData, 
      selectedPeriod, 
      selectedHomeTeamPlayerIdRef.current, 
      homeTeamId, 
      selectedAwayTeamPlayerIdRef.current, 
      awayTeamId
    );
    selectedGamePeriodRef.current = selectedPeriod;
  }, [shotLocationJsonData]);

  /**
   * This is a select handler method that displays shots made and missed for a selected Home Team Player.
   * @param {string} playerId
   * @param {string} homeTeamId
   */
  const selectShotLocationsForHomeTeamPlayer = useCallback((playerId, homeTeamId) => {
    setHomeTeamPlayerValue(playerId);
    displayShotLocationsForSpecificHomeTeamPlayer(
      shotLocationJsonData, 
      selectedGamePeriodRef.current, 
      playerId, 
      homeTeamId
    );
    selectedHomeTeamPlayerIdRef.current = playerId;
  }, [shotLocationJsonData]);

  /**
   * This is a select handler method that displays shots made and missed for a selected Away Team Player.
   * @param {string} playerId
   * @param {string} awayTeamId
   */
  const selectShotLocationsForAwayTeamPlayer = useCallback((playerId, awayTeamId) => {
    setAwayTeamPlayerValue(playerId);
    displayShotLocationsForSpecificAwayTeamPlayer(
      shotLocationJsonData, 
      selectedGamePeriodRef.current, 
      playerId, 
      awayTeamId
    );
    selectedAwayTeamPlayerIdRef.current = playerId;
  }, [shotLocationJsonData]);

  /**
   * This is a handler method that determines the display of shots made for the Home Team.
   * @param {boolean} shotsMadeStatus (For Home Team)
   */
  const changeHomeTeamShotsMadeHandler = useCallback((shotsMadeStatus) => {
    homeTeamShotMadeStatusRef.current = !shotsMadeStatus;
    setShowHomeTeamShotsMade(!shotsMadeStatus);
    setShotLocationPositions(game, shotLocationJsonData);
  }, [showHomeTeamShotsMade]);

  /**
   * This is a handler method that determines the display of shots missed for the Home Team.
   * @param {boolean} shotsMissedStatus (For Home Team)
   */
  const changeHomeTeamShotsMissedHandler = useCallback((shotsMissedStatus) => {
    homeTeamShotMissedStatusRef.current = !shotsMissedStatus;
    setShowHomeTeamShotsMissed(!shotsMissedStatus);
    setShotLocationPositions(game, shotLocationJsonData);
  }, [showHomeTeamShotsMissed]);

  /**
   * This is a handler method that determines the display of shots made for the Away Team.
   * @param {boolean} shotsMadeStatus (For Away Team)
   */
  const changeAwayTeamShotsMadeHandler = useCallback((shotsMadeStatus) => {
    awayTeamShotMadeStatusRef.current = !shotsMadeStatus;
    setShowAwayTeamShotsMade(!shotsMadeStatus);
    setShotLocationPositions(game, shotLocationJsonData);
  }, [showAwayTeamShotsMade]);

  /**
   * This is a handler method that determines the display of shots missed for the Away Team.
   * @param {boolean} shotsMissedStatus (For Away Team)
   */
  const changeAwayTeamShotsMissedHandler = useCallback((shotsMissedStatus) => {
    awayTeamShotMissedStatusRef.current = !shotsMissedStatus;
    setShowAwayTeamShotsMissed(!shotsMissedStatus);
    setShotLocationPositions(game, shotLocationJsonData);
  }, [showAwayTeamShotsMissed]);

  /**
   * This is a handler method that restores the original states and reference values of the shot location positions.
   */
  const restoreOriginalShotLocationsStates = useCallback(() => {
    setShowHomeTeamShotsMade(true);
    setShowHomeTeamShotsMissed(true);
    setShowAwayTeamShotsMade(true);
    setShowAwayTeamShotsMissed(true);
    setGamePeriodValue('All Periods');
    setHomeTeamPlayerValue('');
    setAwayTeamPlayerValue('');

    selectedGamePeriodRef.current = 'All Periods';
    selectedHomeTeamPlayerIdRef.current = '';
    selectedAwayTeamPlayerIdRef.current = '';
    homeTeamShotMadeStatusRef.current = true;
    homeTeamShotMissedStatusRef.current = true;
    awayTeamShotMadeStatusRef.current = true;
    awayTeamShotMissedStatusRef.current = true;

    setShotLocationPositions(game, shotLocationJsonData);
  }, []);

  /**
   * This helper method helps initialize or updates shot locations to be displayed.
   * @param {object} game 
   * @param {array} shotLocationJsonData 
   */
  const setShotLocationPositions = (game, shotLocationJsonData) => {
    allPeriodsShotLocationsDataRef.current = Object.values(shotLocationJsonData).reduce((updatedShotLocations, currentGamePeriodShotLocations) => [ ...updatedShotLocations, ...currentGamePeriodShotLocations ], []);

    displayGamePeriodsShotLocations(
      shotLocationJsonData, 
      selectedGamePeriodRef.current, 
      selectedHomeTeamPlayerIdRef.current, 
      game?.homeTeamId, 
      selectedAwayTeamPlayerIdRef.current, 
      game?.awayTeamId
    );
  };

  /**
   * This helper method finds players with shots made and shots missed.
   * @param {array} homeTeamPlayers 
   * @param {array} awayTeamPlayers 
   */
  const findPlayersWithShots = (homeTeamPlayers, awayTeamPlayers) => {
    const getTeamPlayersWithShots = teamPlayers => teamPlayers?.filter(({ playerId }) => (allPeriodsShotLocationsDataRef.current?.filter(shotLocation => shotLocation.playerId === playerId)?.length > 0))
    homeTeamPlayersRef.current = [ { playerName: 'All Players', playerId: '' }, ...getTeamPlayersWithShots(homeTeamPlayers) ];
    awayTeamPlayersRef.current = [ { playerName: 'All Players', playerId: '' }, ...getTeamPlayersWithShots(awayTeamPlayers) ];
  };

  /**
   * This method is a helper method for retrieveing shot locations.
   * @param {array} shotLocationJsonData 
   * @param {string} selectedPeriod 
   * @returns 
   */
  const getShotLocations = (shotLocationJsonData, selectedPeriod) => {
    return selectedPeriod == "All Periods"
            ? allPeriodsShotLocationsDataRef.current
            : shotLocationJsonData[selectedPeriod];
  };

  /**
   * This method retrieves shots made by a player from the Home Team.
   * @param {array} shotLocationData 
   * @param {string} homeTeamPlayerId 
   * @param {string} homeTeamId 
   * @returns 
   */
  const getShotsMadeForHomeTeamPlayer = (shotLocationData, homeTeamPlayerId, homeTeamId) => (
    homeTeamPlayerId?.trim().length > 0 
      ? shotLocationData?.filter(({ teamId, playerId, eventType }) => teamId === homeTeamId && playerId === homeTeamPlayerId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE))
      : getShotMadeForAllHomeTeamPlayers(shotLocationData, homeTeamId)
  );

  /**
   * This method retrieves shots missed by a player from the Home Team.
   * @param {array} shotLocationData 
   * @param {string} homeTeamPlayerId 
   * @param {string} homeTeamId 
   * @returns 
   */
  const getShotsMissedForHomeTeamPlayer = (shotLocationData, homeTeamPlayerId, homeTeamId) => (
    homeTeamPlayerId?.trim().length > 0 
      ? shotLocationData?.filter(({ teamId, playerId, eventType }) => teamId === homeTeamId && playerId === homeTeamPlayerId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED))
      : getShotsMissedForAllHomeTeamPlayers(shotLocationData, homeTeamId)
  );

  /**
   * This method retrieves shots made by a player from the Away Team.
   * @param {array} shotLocationData 
   * @param {string} awayTeamPlayerId 
   * @param {string} awayTeamId 
   * @returns 
   */
  const getShotsMadeForAwayTeamPlayer = (shotLocationData, awayTeamPlayerId, awayTeamId) => (
    awayTeamPlayerId?.trim().length > 0 
      ? shotLocationData?.filter(({ teamId, playerId, eventType }) => teamId === awayTeamId && playerId === awayTeamPlayerId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE))
      : getShotsMadeForAllAwayTeamPlayers(shotLocationData, awayTeamId)
  );

  /**
   * This method retrieves shots missed by a player from the Away Team.
   * @param {array} shotLocationData 
   * @param {string} awayTeamPlayerId 
   * @param {string} awayTeamId 
   * @returns 
   */
  const getShotsMissedForAwayTeamPlayer = (shotLocationData, awayTeamPlayerId, awayTeamId) => (
    awayTeamPlayerId?.trim().length > 0 
      ? shotLocationData?.filter(({ teamId, playerId, eventType }) => teamId === awayTeamId && playerId === awayTeamPlayerId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED))
      : getShotsMissedForAllAwayTeamPlayers(shotLocationData, awayTeamId)
  );

  /**
   * This method retrieves all shots made by the Home Team.
   * @param {array} shotLocationData 
   * @param {string} homeTeamId 
   * @returns 
   */
  const getShotMadeForAllHomeTeamPlayers = (shotLocationData, homeTeamId) => shotLocationData?.filter(({ teamId, eventType }) => teamId === homeTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE));

  /**
   * This method retrieves all shots missed by the Home Team.
   * @param {array} shotLocationData 
   * @param {string} homeTeamId 
   * @returns 
   */
  const getShotsMissedForAllHomeTeamPlayers = (shotLocationData, homeTeamId) => shotLocationData?.filter(({ teamId, eventType }) => teamId === homeTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED));

  /**
   * This method retrieves all shots made by the Away Team.
   * @param {array} shotLocationData 
   * @param {string} awayTeamId 
   * @returns 
   */
  const getShotsMadeForAllAwayTeamPlayers = (shotLocationData, awayTeamId) => shotLocationData?.filter(({ teamId, eventType }) => teamId === awayTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE));

  /**
   * This method retrieves all shots missed by the Home Team.
   * @param {array} shotLocationData 
   * @param {string} awayTeamId 
   * @returns 
   */
  const getShotsMissedForAllAwayTeamPlayers = (shotLocationData, awayTeamId) => shotLocationData?.filter(({ teamId, eventType }) => teamId === awayTeamId && (eventType === SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED || eventType === SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED));

  /**
   * This method displays shot locations, made and missed, for all players from both Home Team and Away Team at each and all game periods.
   * @param {array} shotLocationJsonData 
   * @param {string} selectedPeriod 
   * @param {string} homeTeamPlayerId 
   * @param {string} homeTeamId 
   * @param {string} awayTeamPlayerId 
   * @param {string} awayTeamId 
   */
  const displayGamePeriodsShotLocations = (shotLocationJsonData, selectedPeriod, homeTeamPlayerId, homeTeamId, awayTeamPlayerId, awayTeamId) => {
    const shotLocations = getShotLocations(shotLocationJsonData, selectedPeriod);
    displayShotLocations(shotLocations, homeTeamPlayerId, homeTeamId, awayTeamPlayerId, awayTeamId);
  };

  /**
   * This method gets and displays shot locations, made and missed, for a specific Home Team Player at each and all game periods.
   * @param {array} shotLocationJsonData 
   * @param {string} selectedPeriod 
   * @param {string} selectedPlayerId 
   * @param {string} homeTeamId 
   */
  const displayShotLocationsForSpecificHomeTeamPlayer = (shotLocationJsonData, selectedPeriod, selectedPlayerId, homeTeamId) => {
    const shotLocations = getShotLocations(shotLocationJsonData, selectedPeriod);
    displayShotsForHomeTeamPlayer(shotLocations, selectedPlayerId, homeTeamId);
  };

  /**
   * This method gets and displays shot locations, made and missed, for a specific Away Team Player at each and all game periods.
   * @param {array} shotLocationJsonData 
   * @param {string} selectedPeriod 
   * @param {string} selectedPlayerId 
   * @param {string} awayTeamId 
   */
  const displayShotLocationsForSpecificAwayTeamPlayer = (shotLocationJsonData, selectedPeriod, selectedPlayerId, awayTeamId) => {
    const shotLocations = getShotLocations(shotLocationJsonData, selectedPeriod);
    displayShotsForAwayTeamPlayer(shotLocations, selectedPlayerId, awayTeamId);
  };

  /**
   * This method displays shots made and missed for a Home Team Player.
   * @param {array} shotLocationData 
   * @param {string} homeTeamPlayerId 
   * @param {string} homeTeamId 
   */
  const displayShotsForHomeTeamPlayer = (shotLocationData, homeTeamPlayerId, homeTeamId) => {
    const shotLocationsMade = homeTeamShotMadeStatusRef.current ? getShotsMadeForHomeTeamPlayer(shotLocationData, homeTeamPlayerId, homeTeamId) : [];
    const shotLocationsMissed = homeTeamShotMissedStatusRef.current ? getShotsMissedForHomeTeamPlayer(shotLocationData, homeTeamPlayerId, homeTeamId) : [];
    setHomeTeamShotMadeLocations(shotLocationsMade);
    setHomeTeamShotMissedLocations(shotLocationsMissed);
  };

  /**
   * This method displays shots made and missed for an Away Team Player.
   * @param {array} shotLocationData 
   * @param {string} awayTeamPlayerId 
   * @param {string} awayTeamId 
   */
  const displayShotsForAwayTeamPlayer = (shotLocationData, awayTeamPlayerId, awayTeamId) => {
    const shotLocationsMade = awayTeamShotMadeStatusRef.current ? getShotsMadeForAwayTeamPlayer(shotLocationData, awayTeamPlayerId, awayTeamId) : [];
    const shotLocationsMissed = awayTeamShotMissedStatusRef.current ? getShotsMissedForAwayTeamPlayer(shotLocationData, awayTeamPlayerId, awayTeamId) : [];
    setAwayTeamShotMadeLocations(shotLocationsMade);
    setAwayTeamShotMissedLocations(shotLocationsMissed);
  };

  /**
   * This method displays shots made and missed for all Home Team Players.
   * @param {array} shotLocationData 
   * @param {string} homeTeamId 
   */
  const displayShotLocationsForAllHomeTeamPlayers = (shotLocationData, homeTeamId) => {
    const homeTeamShotMadeLocations = homeTeamShotMadeStatusRef.current ? getShotMadeForAllHomeTeamPlayers(shotLocationData, homeTeamId) : [];
    const homeTeamShotMissedLocations = homeTeamShotMissedStatusRef.current ? getShotsMissedForAllHomeTeamPlayers(shotLocationData, homeTeamId) : [];
    setHomeTeamShotMadeLocations(homeTeamShotMadeLocations);
    setHomeTeamShotMissedLocations(homeTeamShotMissedLocations);
  };

  /**
   * This method displays shots made and missed for all Away Team Players.
   * @param {array} shotLocationData 
   * @param {string} awayTeamId 
   */
  const displayShotLocationsForAllAwayTeamPlayers = (shotLocationData, awayTeamId) => {
    const awayTeamShotMadeLocations = awayTeamShotMadeStatusRef.current ? getShotsMadeForAllAwayTeamPlayers(shotLocationData, awayTeamId) : [];
    const awayTeamShotMissedLocations = awayTeamShotMissedStatusRef.current ? getShotsMissedForAllAwayTeamPlayers(shotLocationData, awayTeamId) : [];
    setAwayTeamShotMadeLocations(awayTeamShotMadeLocations);
    setAwayTeamShotMissedLocations(awayTeamShotMissedLocations);
  };

  /**
   * This method displays a shot locations with respect to a player or team.
   * @param {array} shotLocationData 
   * @param {string} homeTeamPlayerId 
   * @param {string} homeTeamId 
   * @param {string} awayTeamPlayerId 
   * @param {string} awayTeamId 
   */
  const displayShotLocations = (shotLocationData, homeTeamPlayerId, homeTeamId, awayTeamPlayerId, awayTeamId) => {
    if(homeTeamPlayerId?.trim().length > 0) {
      displayShotsForHomeTeamPlayer(shotLocationData, homeTeamPlayerId, homeTeamId);
    }
    else {
      displayShotLocationsForAllHomeTeamPlayers(shotLocationData, homeTeamId);
    }

    if(awayTeamPlayerId?.trim().length > 0) {
      displayShotsForAwayTeamPlayer(shotLocationData, awayTeamPlayerId, awayTeamId);
    }
    else {
      displayShotLocationsForAllAwayTeamPlayers(shotLocationData, awayTeamId);
    }
  };

  return (
    <div style={playeByPlayCenterControlContentStyles}>
      <Row style={{ width: '100%', height: 'auto', margin: 0, }}>
        <Col xs={8} style={{ width: '60%', height: 'auto', }} className="p-0 d-flex justify-content-center align-items-center">
          <BasketballCourtShotLocationDisplay
            basketballCourtHeight={400}
            awayTeamShotMadeLocations={awayTeamShotMadeLocations}
            homeTeamShotMadeLocations={homeTeamShotMadeLocations}
            awayTeamShotMissedLocations={awayTeamShotMissedLocations}
            homeTeamShotMissedLocations={homeTeamShotMissedLocations}
          />
        </Col>
        <Col xs={4} style={{ width: '40%', height: 'auto', borderTopRightRadius: 10, borderBottomRightRadius: 10, borderLeft: '2px solid #D5D5D5', }} className="p-0 d-flex justify-content-center align-items-center">
          <div style={{ width: '100%', height: '100%', }}>
            <GamePeriodControl
              game={game}
              gamePeriodValue={gamePeriodValue}
              gamePeriodOptions={gamePeriodOptions}
              resetIconImgStatus={resetIconImgStatus}
              restoreOriginalShotLocationsStates={restoreOriginalShotLocationsStates}
              selectShotLocationsForPeriods={selectShotLocationsForPeriods}
            />
            {game && 
              <TeamPlayerSelectionControl
                key={game?.homeTeamId}
                teamId={game?.homeTeamId}
                teamName={game?.homeTeamName}
                teamLogo={game?.homeTeamImage}
                teamPlayers={homeTeamPlayersRef.current}
                teamPlayerShotsMadeIndicator={HomeTeamShotsMadeIndicator}
                teamPlayerShotsMissedIndicator={HomeTeamShotsMissedIndicator}
                teamShotsMadeStatus={showHomeTeamShotsMade}
                teamShotsMadeStatusHandler={changeHomeTeamShotsMadeHandler}
                teamShotsMissedStatus={showHomeTeamShotsMissed}
                teamShotsMissedStatusHandler={changeHomeTeamShotsMissedHandler}
                teamShotsByPlayerHandler={selectShotLocationsForHomeTeamPlayer}
                teamPlayerValue={homeTeamPlayerValue}
              />
            }
            {game && 
              <TeamPlayerSelectionControl
                key={game?.awayTeamId}
                teamId={game?.awayTeamId}
                teamName={game?.awayTeamName}
                teamLogo={game?.awayTeamImage}
                teamPlayers={awayTeamPlayersRef.current}
                teamPlayerShotsMadeIndicator={AwayTeamShotsMadeIndicator}
                teamPlayerShotsMissedIndicator={AwayTeamShotsMissedIndicator}
                teamShotsMadeStatus={showAwayTeamShotsMade}
                teamShotsMadeStatusHandler={changeAwayTeamShotsMadeHandler}
                teamShotsMissedStatus={showAwayTeamShotsMissed}
                teamShotsMissedStatusHandler={changeAwayTeamShotsMissedHandler}
                teamShotsByPlayerHandler={selectShotLocationsForAwayTeamPlayer}
                teamPlayerValue={awayTeamPlayerValue}
                bottomRightRadius={10}
              />
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BasketballCourtControl;