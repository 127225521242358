import { configureStore } from '@reduxjs/toolkit';
import userReducer from './redux/userSlice';
import authStateReducer from './redux/authStateSlice';
import pathLocationSlice from './redux/pathLocationSlice';
import themeSlice from './redux/themeSlice';
import homeCalendarSlice from './redux/homeCalendarSlice';
import homeGameListScrollPositionSlice from './redux/homeGameListScrollPositionSlice';
import statsGameListScrollPositionSlice from './redux/statsGameListScrollPositionSlice';
import homeShowGameUsersSlice from './redux/homeShowGameUsersSlice';
import groupReducer from './redux/groupSlice';
import switchViewReducer from './redux/switchViewSlice';
import updateNumOfPeriodsReducer from './pages/ClockManager/updateNumOfPeriods.reducer';
import scoreKeeperEventSlice from './pages/ScoreKeeper-new/controller/scoreKeeperEventSlice';
import gamesPageSlice from './redux/gamesPageSlice';
import homePageTabSlice from './redux/homePageTabsSlice';
import gamesPageTabSlice from './redux/gamesPageTabsSlice';

export default configureStore({
  reducer: {
    authState: authStateReducer,
    gamesPage: gamesPageSlice,
    gamesPageTabs: gamesPageTabSlice,
    group: groupReducer,
    homeCalendar: homeCalendarSlice,
    homeGameListScrollPosition: homeGameListScrollPositionSlice,
    homePageTabs: homePageTabSlice,
    homeShowGameUsers: homeShowGameUsersSlice,
    pathLocation: pathLocationSlice,
    /* Reducer to handle input selection on the ScoreKeeper UI */
    scoreKeeperEvents: scoreKeeperEventSlice,
    statsGameListScrollPosition: statsGameListScrollPositionSlice,
    switchView: switchViewReducer,
    theme: themeSlice,
    updateNumOfPeriodsReducer: updateNumOfPeriodsReducer,
    user: userReducer,
  },
});
