import React, { useState, useLayoutEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import TeamsContainer from './TeamsContainer';
import StatContainer from './StatsContainer';
import { TABBED_ITEM } from './TabbedItemEnum';

const PlayerStats = ({
  awayTeamImage, 
  homeTeamImage, 
  awayTeamName, 
  homeTeamName, 
  awayTeamPlayersGameStats, 
  homeTeamPlayersGameStats, 
  scrollListRef,
}) => {
  useLayoutEffect(() => {
    scrollListRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
    
  const [activeTab, setActiveTab] = useState(TABBED_ITEM.HOME_TEAM_ITEM);

  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, }}>
      <Col xs={12} className='p-0 m-0'>
        <TeamsContainer
          awayTeamImage={awayTeamImage}
          awayTeamName={awayTeamName}
          homeTeamImage={homeTeamImage}
          homeTeamName={homeTeamName}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <StatContainer
          activeTab={activeTab}
          awayTeamPlayersGameStats={awayTeamPlayersGameStats}
          homeTeamPlayersGameStats={homeTeamPlayersGameStats}
        />
      </Col>
    </Row>
  );

};

export default PlayerStats;