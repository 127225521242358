import { useInfiniteQuery } from 'react-query';
import { getListEventsByGameId } from '../api/eventsService';
import { LIST_PAGINATION_LIMIT, SHOT_LOCATION_EVENTS } from './constantsUtils';
import { useFetchAll, useItemsFromPages } from './reactQueryToolkit';

const getGamePeriodPlayByPlayEvents = async (gameId, nextToken) => {
  const periodPlayByPlayEvents = await getListEventsByGameId(
    {
      limit: LIST_PAGINATION_LIMIT.LARGE,
      nextToken: nextToken,
      gameId: gameId
    }
  );
  return periodPlayByPlayEvents;
};

const useGameShotLocation = (queryKey, gameId) => {
  const { onSuccessCallback } = useFetchAll(true);

  const gameShotLocationDataResponse = 
  useInfiniteQuery([queryKey, { gameId }], 
    async ({ pageParam }) => {
      const gamePeriodEvents = await getGamePeriodPlayByPlayEvents(gameId, pageParam);
      return gamePeriodEvents;
    }, 
    {
      enabled: !!gameId,
      onSuccess: (data) => onSuccessCallback(gameShotLocationDataResponse)(data),
      getNextPageParam: (lastPage, pages) => lastPage?.nextToken
    }
  );

  const gameShotLocations = useItemsFromPages(gameShotLocationDataResponse.data?.pages)
                              ?.filter(({ isDeleted }) => isDeleted === false);

  let shotLocationData = gameShotLocations?.map(periodEvent => {
                                                    const { id, gamePeriodNumber, gameOvertimeNumber, shotLocation, teamId, playerId, eventType } = periodEvent;
                                                    const gamePeriod = gamePeriodNumber && !gameOvertimeNumber ? `Q${gamePeriodNumber}` : `OT${gameOvertimeNumber}`;
                                                    return { id, teamId, playerId, eventType, gamePeriod, shotLocation: JSON.parse(shotLocation) };
                                                  });
  shotLocationData = shotLocationData?.filter(({ eventType }) => [ 
    SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MADE, 
    SHOT_LOCATION_EVENTS.TWO_PT_SHOT_MISSED, 
    SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MADE, 
    SHOT_LOCATION_EVENTS.THREE_PT_SHOT_MISSED 
  ].includes(eventType));

  const gamePeriodsList = shotLocationData?.map(({ gamePeriod }) => gamePeriod);
  const uniqueGamePeriodsList = Array.from( new Set(gamePeriodsList) );

  const shotLocationsPerGamePeriod = {};

  uniqueGamePeriodsList?.forEach(gamePeriodItem => {
    let gamePeriodShotLocations = shotLocationData?.filter(({ gamePeriod }) => gamePeriod === gamePeriodItem);
    gamePeriodShotLocations = gamePeriodShotLocations?.map(({ id, teamId, playerId, eventType, shotLocation }) => ({id, teamId, playerId, eventType, shotLocation}));
    shotLocationsPerGamePeriod[gamePeriodItem] = gamePeriodShotLocations;
  });

  return shotLocationsPerGamePeriod;
};

export default useGameShotLocation;