import { createSlice } from '@reduxjs/toolkit';

export const authStateSlice = createSlice({
	name: 'authState',
	initialState: {
		value: '',
		hasSessionExpired: false,
	},
	reducers: {
		updateAuthState: (state, action) => {
			state.value = action.payload;
		},
		setHasSessionExpired: (state, action) => {
			state.hasSessionExpired = action.payload;
		},
	},
});

export const { updateAuthState, setHasSessionExpired } = authStateSlice.actions;

export const selectAuthState = (state) => state.authState.value;
export const selectAuthSessionExpired = (state) => state.authState.hasSessionExpired;


export default authStateSlice.reducer;
