import React from 'react';
import StatsHeader from './StatsHeader';
import TeamStatRecord from './TeamStatRecord';
import { StatsTitleHeader } from './StatsTitleHeader';
import { SummaryTeamStats } from './SummaryTeamStatsContainer';

const TeamPercentageStats = ({
  awayTeamImage, 
  homeTeamImage, 
  percentageStatsData, 
}) => {
  return (
    <SummaryTeamStats
      height={'240px'}
      marginTop={20}
    >
      <StatsTitleHeader
        justifyContent="center"
        alignItems="center"
      >
        Team Stats
      </StatsTitleHeader>
      <StatsHeader
        awayTeamImage={awayTeamImage}
        homeTeamImage={homeTeamImage}
        headerHeight="50px"
        imageHeaderSize="35px"
        colSize1={8}
        colSize2={4}
        mainBorderTop={1}
        mainMorderLeft={1}
        borderColor='#D5D5D5'
        borderWidth={4}
      />
      {percentageStatsData?.map((statData, index) => {
        const [ statField, awayTeamStat, homeTeamStat ] = Object.values(statData);

        return (
          <TeamStatRecord
            key={`stat_field_percentage_${index}`}
            statField={statField}
            awayTeamStatValue={awayTeamStat}
            homeTeamStatValue={homeTeamStat}
            borderBottom={ index === (percentageStatsData?.length - 1) ? '1px solid #D5D5D5' : null }
            justifyStatField='center'
            fontWeight='normal'
            fontSize={12}
            lineHeight={17}
            colSize1={8}
            colSize2={4}
            height={35}
            innerBorderColor='#D5D5D5'
            outerBorderColor='#D5D5D5'
          />
        );
      })}
    </SummaryTeamStats>
  );
};

export default TeamPercentageStats;