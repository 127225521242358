import React from 'react';
import styled from 'styled-components';
import FastStatsLogo from '../../assets/fastStats-logo@2x.png';
import { colors } from '../../styles';
import Dropdown from '../Dropdowns/UserDropDown';
import { useSelector } from 'react-redux';

const StyledHeader = styled.div`
  background-color: ${colors.GRAY[400]};
  height: 50px;
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 20px;
`;

const Header = () => {
  const authState = useSelector((state) => state.authState.value);

  return (
    <StyledHeader>
      <Logo src={FastStatsLogo} alt="logo" />
      {authState === 'signedin' && <Dropdown />}
    </StyledHeader>
  );
};

export default Header;
