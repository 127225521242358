import { DataStore } from '@aws-amplify/datastore';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import {
  createGame as createGameMutation,
  createGameLineup as createGameLineupMutation,
  createGameLineupPlayer as createGameLineupPlayerMutation,
  createGameUser as createGameUserMutation,
  deleteGameLineupPlayer as deleteGameLineupPlayerMutation,
  deleteGameUser as deleteGameUserMutation,
  updateGame as updateGameMutation,
  updateGameLineupPlayer as updateGameLineupPlayerMutation,
  updateGameUser as updateGameUserMutation,
} from '../graphql/mutations';
import {
  getGame as getGameQuery,
  getGameByDatTim as getGameByDateTimeQuery,
  getGameLineup as getGameLineupQuery,
  getGamePlayerStats as GetGamePlayerStats,
  getGameTeamStats as GetGameTeamStats,
  getGameUser as getGameUserQuery,
  getGPStatsByGameIdTeamId as getGPStatsByGameIdTeamIdQuery,
  listGameLineupPlayers as listGameLineupPlayersQuery,
  listGamePlayerStatss as listGamePlayerStatsQuery,
  listGames as listGamesQuery,
} from '../graphql/queries';
import { getGameByDateTimeCustom as getGameByDateTimeCustomQuery } from '../graphql_custom/gamesServiceQueries';
import { onUpdateGame as onUpdateGameSubscription } from '../graphql/subscriptions';
import { Game, GameLineup, GameLineupPlayer, GameTeamStats } from '../models';
import { KEY_SORT_ID, LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
const log = require('../logger')('api');

// get call with parameter
export const listGames = async (variables = null) => {
  try {
    const sportData = await API.graphql(
      graphqlOperation(listGamesQuery, variables)
    );

    //TODO refactor without .items
    return sportData.data.listGames.items;
  } catch (err) {
    return err;
  }
};

// This method call is to get the list of games with the items and nextToken parameters.
// TODO replace uses with listGames once .items has been removed from the return value
export const getListGames = async (variables = null) => {
  try {
    const sportData = await API.graphql(
      graphqlOperation(listGamesQuery, variables)
    );
    return sportData.data.listGames;
  } catch (err) {
    return err;
  }
};

export const getGameByDateTime = async (variables = {}) => {
  const vars = { ...variables, keySortId: KEY_SORT_ID };
  try {
    const sportData = await API.graphql(
      graphqlOperation(getGameByDateTimeQuery, vars)
    );
    return sportData.data.getGameByDatTim;
  } catch (err) {
    return err;
  }
};

export const getGamesInDateRange = async (startDate, endDate, variables = {}) => {
  const vars = {
    sortDirection: 'ASC',
    limit: LIST_PAGINATION_LIMIT.XLARGE,
    ...variables,
    gameDateTime: {
      between: [
        moment(startDate).startOf('day').toISOString(),
        moment(endDate).endOf('day').toISOString(),
      ],
    },
    keySortId: KEY_SORT_ID,
  };
  
  try {
    const sportData = await API.graphql(
      graphqlOperation(getGameByDateTimeCustomQuery, vars)
    );
    return sportData.data.getGameByDatTim;
  } catch (err) {
    return err;
  }
};

export const getGamePlayerStatByTeamId = async (variables = {}) => {
  try {
    const gamePlayerStats = await API.graphql(
      graphqlOperation(getGPStatsByGameIdTeamIdQuery, variables)
    );
    return gamePlayerStats.data.getGPStatsByGameIdTeamId;
  } catch (err) {
    log.error('error fetching list of game player stats...', err);
    return err;
  }
};

export const listGamePlayerStats = async (variables = null) => {
  try {
    const gamePlayerStats = await API.graphql(
      graphqlOperation(listGamePlayerStatsQuery, variables)
    );
    return gamePlayerStats.data.listGamePlayerStatss;
  } catch (err) {
    log.error('error fetching list of game player stats...', err);
    return err;
  }
};

export const listGameLineups = async () => {
  try {
    //const gameLineupsData = await API.graphql(graphqlOperation(ListGameLineups))
    const gameLineupsData = await DataStore.query(GameLineup);
    return gameLineupsData;
  } catch (err) {
    log.error('error fetching games...', err);
  }
};

export const getGameTeamStats = async (id) => {
  try {
    const gameTeamStats = await API.graphql(
      graphqlOperation(GetGameTeamStats, { id: id })
    );
    return gameTeamStats;
  } catch (err) {
    log.error('error fetching game team stats...', err);
  }
};

export const getGameTeamStatsDatastore = async (id) => {
  try {
    return await DataStore.query(GameTeamStats, id);
  } catch (err) {
    log.error('Error fetching game team stats...', err);
  }
};

export const getGamePlayerStats = async () => {
  try {
    const gamePlayerStats = await API.graphql(
      graphqlOperation(GetGamePlayerStats())
    );
    // const gameLineupsData = await DataStore.query(GameLineup);
    return gamePlayerStats;
  } catch (err) {
    log.error('error fetching game team stats...', err);
    return {};
  }
};

export const getGameLineup = async (id) => {
  try {
    const gameLineupPlayersData = await API.graphql(
      graphqlOperation(getGameLineupQuery, { id })
    );
    return gameLineupPlayersData.data.getGameLineup;
  } catch (err) {
    log.error('error fetching gamelineup data...', err);
  }
};

export const getGameLineupPlayers = async (variables = null) => {
  try {
    const listGameLineupPlayersData = await API.graphql(
      graphqlOperation(listGameLineupPlayersQuery, variables)
    );
    return listGameLineupPlayersData.data.listGameLineupPlayers;
  } catch (err) {
    log.error('error fetching gamelineup player data...', err);
  }
};

// get call with parameter
export const getGame = async (id) => {
  try {
    const teamData = await API.graphql(graphqlOperation(getGameQuery, { id }));
    return teamData.data.getGame;
  } catch (err) {
    log.error('error fetching game...', err);
  }
};

export const getGameDataStore = async (id) => {
  try {
    return await DataStore.query(Game, id);
  } catch (err) {
    log.error('Error fetching game from datastore...', err);
    return {};
  }
};

export const getGameUser = async (id) => {
  try {
    const gameUserData = await API.graphql(
      graphqlOperation(getGameUserQuery, { id })
    );
    return gameUserData.data.getGameUser;
  } catch (err) {
    log.error('error fetching game...', err);
  }
};

//create call with parameter
export const createGame = async (
  gameDateTime,
  homeTeam,
  awayTeam,
  league,
  season,
  venue
) => {
  const {
    homeTeamId,
    homeTeamName,
    homeTeamCity,
    homeTeamState,
    homeTeamActive,
  } = homeTeam;

  const {
    awayTeamId,
    awayTeamName,
    awayTeamCity,
    awayTeamState,
    awayTeamActive,
  } = awayTeam;

  const {
    sportId,
    sportName,
    leagueId,
    leagueName,
    leagueAbbreviation,
    leagueTeamSize,
    leagueNumPlayersOnCourt,
    leagueNumPlayersOnBench,
    leagueNumTimeOuts,
    leagueNumFoulsPerPlayer,
    leagueNumPeriods,
    leagueTimePerPeriodInMins,
    leagueOvertimeDurationInMins,
  } = league;

  const { seasonId, seasonStartDate, seasonEndDate, seasonType } = season;

  const {
    venueId,
    venueName,
    venueCity,
    venueState,
    venueZipcode,
    venueActive,
  } = venue;

  try {
    const sportData = await API.graphql(
      graphqlOperation(createGameMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          gameDateTime: new Date(gameDateTime).toISOString(),
          homeTeamId,
          homeTeamName,
          homeTeamCity,
          homeTeamState,
          homeTeamActive,
          awayTeamId,
          awayTeamName,
          awayTeamCity,
          awayTeamState,
          awayTeamActive,
          sportId,
          sportName,
          leagueId,
          leagueName,
          leagueAbbreviation,
          leagueTeamSize,
          leagueNumPlayersOnCourt,
          leagueNumPlayersOnBench,
          leagueNumTimeOuts,
          leagueNumFoulsPerPlayer,
          leagueNumPeriods,
          leagueTimePerPeriodInMins,
          leagueOvertimeDurationInMins,
          seasonId,
          seasonStartDate,
          seasonEndDate,
          seasonType,
          venueId,
          venueName,
          venueCity,
          venueState,
          venueZipcode,
          venueActive,
          gameStatus: null,
        },
      })
    );
    return sportData;
  } catch (error) {
    log.error('error creating game...', error);
    return error;
  }
};

export const createGameLineup = async (gameId, teamId) => {
  try {
    const lineupData = await API.graphql(
      graphqlOperation(createGameLineupMutation, {
        input: {
          teamId,
          gameId,
        },
      })
    );
    // const lineupData = await DataStore.save(
    //   new GameLineup({
    //     id: id,
    //     notes: notes,
    //     teamId: teamId,
    //     gameId: gameId,
    //   })
    // );
    return lineupData;
  } catch (error) {
    log.error(' error creating lineup...', error);
    return error;
  }
};

export const createGameUser = async (userId, gameId, gameRole) => {
  try {
    const gameUserData = await API.graphql(
      graphqlOperation(createGameUserMutation, {
        input: {
          gameId,
          userId,
          gameRole,
        },
      })
    );
    return gameUserData;
  } catch (error) {
    log.error('Error creating game user role...', error);
    return error;
  }
};

export const updateGameUser = async (id, gameRole, _version) => {
  try {
    const gameUserData = await API.graphql(
      graphqlOperation(updateGameUserMutation, {
        input: {
          id,
          gameRole,
          _version,
        },
      })
    );
    return gameUserData;
  } catch (error) {
    log.error('Error updating game user role...', error);
    return error;
  }
};

export const deleteGameUser = async (id, _version) => {
  try {
    const gameUserData = await API.graphql(
      graphqlOperation(deleteGameUserMutation, {
        input: {
          id,
          _version,
        },
      })
    );
    return gameUserData;
  } catch (error) {
    log.error('Error deleting game user role...', error);
    return error;
  }
};

export const updateGame = async (id, values) => {
  const {
    gameDateTime,
    homeTeamId,
    homeTeamName,
    homeTeamCity,
    homeTeamState,
    homeTeamActive,
    awayTeamId,
    awayTeamName,
    awayTeamCity,
    awayTeamState,
    awayTeamActive,
    sportId,
    sportName,
    leagueId,
    leagueName,
    leagueAbbreviation,
    leagueTeamSize,
    leagueNumPlayersOnCourt,
    leagueNumPlayersOnBench,
    leagueNumTimeOuts,
    leagueNumFoulsPerPlayer,
    leagueNumPeriods,
    leagueTimePerPeriodInMins,
    leagueOvertimeDurationInMins,
    seasonId,
    seasonStartDate,
    seasonEndDate,
    seasonType,
    venueId,
    venueName,
    venueCity,
    venueState,
    venueZipcode,
    venueActive,
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    gameStatus,
    _version,
  } = values;
  /**
   * Only the game date is allowed to be updated
   */
  try {
    const gameData = await API.graphql(
      graphqlOperation(updateGameMutation, {
        input: {
          id: id,
          homeTeamId,
          homeTeamName,
          homeTeamCity,
          homeTeamState,
          homeTeamActive,
          awayTeamId,
          awayTeamName,
          awayTeamCity,
          awayTeamState,
          awayTeamActive,
          sportId,
          sportName,
          leagueId,
          leagueName,
          leagueAbbreviation,
          leagueTeamSize,
          leagueNumPlayersOnCourt,
          leagueNumPlayersOnBench,
          leagueNumTimeOuts,
          leagueNumFoulsPerPlayer,
          leagueNumPeriods,
          leagueTimePerPeriodInMins,
          leagueOvertimeDurationInMins,
          seasonId,
          seasonStartDate,
          seasonEndDate,
          seasonType,
          venueId,
          venueName,
          venueCity,
          venueState,
          venueZipcode,
          venueActive,
          homeTeamGameLineupId,
          awayTeamGameLineupId,
          gameStatus,
          _version,
          gameDateTime: new Date(gameDateTime).toISOString(),
        },
      })
    );
    return gameData;
  } catch (err) {
    log.error('error updating game...', err);
    return err;
  }
};

export const updateGameLineupPlayer = async (
  id,
  playerOnCourtBenchStatus,
  _version
) => {
  try {
    const gameLineupData = await API.graphql(
      graphqlOperation(updateGameLineupPlayerMutation, {
        input: {
          id,
          playerOnCourtBenchStatus,
          _version,
        },
      })
    );

    return gameLineupData;
  } catch (err) {
    log.error('error', err);
  }
};

export const updateGameLineupPlayerViaDatastore = async (
  id,
  playerOnCourtBenchStatus
) => {
  try {
    const original = await DataStore.query(GameLineupPlayer, id);

    const gameLineupData = await DataStore.save(
      GameLineupPlayer.copyOf(original, (updated) => {
        updated.playerOnCourtBenchStatus = playerOnCourtBenchStatus;
      })
    );

    return gameLineupData;
  } catch (err) {
    log.error('error', err);
  }
};

export const updateLeague = async (
  name,
  abbreviation,
  League,
  leagueId,
  sport
) => {
  try {
    // const sportData = await API.graphql(
    // 	graphqlOperation(UpdateLeague, {
    // 		input: { name: name, abbreviation: abbreviation, sportId: sportId, id: leagueId },
    // 	})
    // );
    const original = await DataStore.query(League, leagueId);

    log.debug('original: ', original);

    const sportData = await DataStore.save(
      League.copyOf(original, (updated) => {
        updated.name = name;
        updated.abbreviation = abbreviation;
        updated.sport = sport;
      })
    );
    return sportData;
  } catch (err) {
    log.error('error updating league...', err);
  }
};

export const createGameLineupPlayer = async ({
  gameLineupId,
  player,
  playerOnCourtBenchStatus,
}) => {
  const {
    playerId,
    playerFirstName,
    playerLastName,
    playerPosition,
    playerJerseyNumber,
    playerDateOfBirth,
    playerBirthCity,
    playerBirthState,
    playerBirthCountry,
    playerWeight,
    playerHeight,
    playerInstitute,
    playerActive,
  } = player;

  try {
    const gameLineupPlayerData = await API.graphql(
      graphqlOperation(createGameLineupPlayerMutation, {
        input: {
          gameLineupId,
          playerId,
          playerFirstName,
          playerLastName,
          playerPosition,
          playerJerseyNumber,
          playerDateOfBirth,
          playerBirthCity,
          playerBirthState,
          playerBirthCountry,
          playerWeight,
          playerHeight,
          playerInstitute,
          playerActive,
          playerOnCourtBenchStatus,
        },
      })
    );

    return gameLineupPlayerData;
  } catch (err) {
    log.error('error updating gamelineup player');
  }
};

// export const deleteGameLineupPlayer = async (id) => {
// 	try {
// 		// const sportData = await API.graphql(
// 		// 	graphqlOperation(DeleteSport, { input: { id: id } })
// 		// );

// 		const toDelete = await DataStore.query(GameLineupPlayer, id);
// 		const gameLineupPlayerData = await DataStore.delete(toDelete);
// 		return gameLineupPlayerData;
// 	} catch (err) {
// 		log.error('error deleting sport...', err);
// 	}
// };

// export const updateGameLineupPlayer = async (id, gameLineupId, playerId) => {
// 	try {
// 		// const gameLineupPlayerData = await API.graphql(
// 		// 	graphqlOperation(CreateGameLineupPlayer, {
// 		// 		input: {
// 		// 			id: id,
// 		// 			gameLineupId: gameLineupId,
// 		// 			playerId: playerId
// 		// 		}
// 		// 	}))
// 		const gameLineupPlayerData = await DataStore.save(
// 			new GameLineupPlayer({
// 				id: id,
// 				gameLineupId: gameLineupId,
// 				playerId: playerId,
// 			})
// 		);

// 		return gameLineupPlayerData;
// 	} catch (err) {
// 		log.error('error updating gamelineup player');
// 	}
// };

export const deleteGame = async (id) => {
  try {
    // const sportData = await API.graphql(
    // 	graphqlOperation(DeleteGame, { input: { id: id } })
    // );
    const toDelete = await DataStore.query(Game, id);
    const sportData = await DataStore.delete(toDelete);
    return sportData;
  } catch (err) {
    log.error('error deleting game...', err);
  }
};

export const deleteGameLineupPlayer = async (id, version) => {
  try {
    const gameLineupPlayerData = await API.graphql(
      graphqlOperation(deleteGameLineupPlayerMutation, {
        input: {
          id,
          _version: version,
        },
      })
    );
    // const toDelete = await DataStore.query(GameLineupPlayer, id);
    // const gameLineupPlayerData = await DataStore.delete(toDelete);
    return gameLineupPlayerData;
  } catch (err) {
    log.error('error deleting gamelineup player...', err);
  }
};

/**
 * This is a subscription that is notified after a game has been updated.
 * @param {function} updateGameSubscriptionCallback
 */
export const updateGameSubscription = (
  updateGameSubscriptionCallback = () => {}
) => {
  return API.graphql(graphqlOperation(onUpdateGameSubscription)).subscribe({
    next: ({ value }) => {
      updateGameSubscriptionCallback(value);
    },
    error: (error) => {
      log.error('error attempting to subscribe to create or update games....');
    },
  });
};
