import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TEAM_PLAYERS_DEF } from '../PropTypeDefs.type.js';
import Button from '@material-ui/core/Button';
import { StyledColumnFlexStart as Column } from '../../../styles';
import { colors } from '../../../styles/colorsStatsCollector';
import {
  shouldShowPlayerOffenseButtons,
  shouldShowPlayerDefenseButtons,
  isPrimaryPlayerSelectType,
} from '../controller/displayType';
import {
  playerSelectEvent,
  selectPlayer,
  selectDisplayType,
  playerSecondarySelectEvent,
} from '../controller/scoreKeeperEventSlice';
import { useSelector, useDispatch } from 'react-redux';
import { PossessionTeam } from '../enums.js';

const PlayerSelection = ({
  homeTeamLogo,
  awayTeamLogo,
  homeTeamPlayers,
  awayTeamPlayers,
  instructions,
  possessionTeam,
}) => {
  return (
    <Column style={{ flex: 1, justifyContent: 'space-evenly' }}>
      {instructions && <Instructions>{instructions}</Instructions>}
      <Container>
        <CourtPlayers
          logo={homeTeamLogo}
          players={homeTeamPlayers}
          style={{
            paddingRight: '5%',
            marginRight: '5%',
            borderRight: `1px solid ${colors.GRAY[1600]}`,
          }}
          isTeamInPossession={possessionTeam === PossessionTeam.HOME}
        />
        <CourtPlayers
          logo={awayTeamLogo}
          players={awayTeamPlayers}
          isTeamInPossession={possessionTeam === PossessionTeam.AWAY}
        />
      </Container>
    </Column>
  );
};

const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Instructions = styled.div`
  color: ${({ theme }) =>
    theme.lightMode ? colors.GRAY[2300] : colors.WHITE[100]};
  text-align: center;
  font: normal normal 500 16px/22px Open Sans;
`;

const CourtPlayers = ({ logo, players, isTeamInPossession, ...props }) => {
  const displayType = useSelector(selectDisplayType);
  const playerPrimary = useSelector(selectPlayer);
  const dispatch = useDispatch();

  function shouldEnablePlayer(
    isTeamInPossession,
    displayType,
    player,
    playerSelectedPrimary
  ) {
    return isTeamInPossession
      ? shouldShowPlayerOffenseButtons(
          displayType,
          player?.id,
          playerSelectedPrimary?.id
        )
      : shouldShowPlayerDefenseButtons(
          displayType,
          player?.id,
          playerSelectedPrimary?.id
        );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...props.style,
      }}
    >
      <img
        src={logo}
        alt="Team Logo"
        style={{
          height: 60,
          width: 'auto',
          marginBottom: '27px',
          borderRadius: '4px',
        }}
      />

      {players.map((p, idx) => (
        <PlayerButton
          key={p.id}
          player={p}
          $active={p?.id === playerPrimary?.id}
          disabled={
            !shouldEnablePlayer(
              isTeamInPossession,
              displayType,
              p,
              playerPrimary
            )
          }
          onClick={() =>
            dispatch(
              isPrimaryPlayerSelectType(displayType)
                ? playerSelectEvent(p)
                : playerSecondarySelectEvent(p)
            )
          }
        />
      ))}
    </div>
  );
};

const PlayerButton = styled(({ player, ...props }) => {
  return (
    <Button {...props} variant="outlined" style={{}}>
      <div className="jersey">
        <span>{player.playerJerseyNumber}</span>
      </div>
      <div className="name">
        <div className="first-name">{player.playerFirstName}</div>
        <div className="last-name">{player.playerLastName}</div>
      </div>
    </Button>
  );
})`
  &&& {
    width: 160px;
    text-transform: none;
    display: flex;
    justify-content: space-around;
    margin-bottom: 23px;
    padding: 0;
    align-items: stretch;
    border: 1px solid
      ${({ theme }) =>
        theme.lightMode ? colors.GRAY[950] : colors.GRAY_TRANSPARENT[800]};
    border-radius: 5px;
    box-shadow: 5px 5px 5px ${colors.GRAY_TRANSPARENT[600]};

    &:disabled {
      opacity: 0.35;
    }

    .jersey {
      flex: 1;
      background: ${({ $active, theme }) =>
        $active
          ? colors.BLUE[300]
          : theme.lightMode
          ? colors.GRAY[300]
          : colors.GRAY[2400]};
      // opacity: 0.56;
      text-align: center;
      font: normal normal bold 24px/18px Open Sans;
      letter-spacing: 0px;
      color: ${({ $active, theme }) =>
        $active
          ? colors.WHITE[100]
          : theme.lightMode
          ? colors.GRAY[2300]
          : colors.GRAY[100]};
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .name {
      flex: 2;
      padding-left: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 6px 6px 6px 9px;
      color: ${({ $active, theme }) =>
        $active
          ? colors.WHITE[100]
          : theme.lightMode
          ? colors.GRAY[2400]
          : colors.GRAY[100]};
      ${({ $active }) => ($active ? `background: ${colors.BLUE[100]};` : '')};

      .first-name {
        text-align: left;
        font: normal normal bold 16px/18px Open Sans;
        letter-spacing: 0px;
      }

      .last-name {
        text-align: left;
        font: normal normal normal 14px/18px Open Sans;
        letter-spacing: 0px;
      }
    }
  }
`;

CourtPlayers.propTypes = {
  logo: PropTypes.any.isRequired,
  players: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF)).isRequired,
};

PlayerSelection.propTypes = {
  homeTeamLogo: PropTypes.any.isRequired,
  awayTeamLogo: PropTypes.any.isRequired,
  homeTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF))
    .isRequired,
  awayTeamPlayers: PropTypes.arrayOf(PropTypes.shape(TEAM_PLAYERS_DEF))
    .isRequired,
  instructions: PropTypes.string,
};

export default PlayerSelection;
