import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import GameBasketballShotLocationStats from './GameBasketballShotLocationStats';
import GamePlayerStats from './GamePlayerStats.jsx';
import { GameStatsContext } from './GameStatsContext';

const rightStatsContainerStyles = {
  width: '55%',
  marginLeft: 7,
};

const GameRightStats = ({
}) => {
  const { 
    statsContentStyles, 
  } = useContext(GameStatsContext);

  return (
    <Col xs={9} className='p-0' style={{ ...statsContentStyles, ...rightStatsContainerStyles, }}>
      <GameBasketballShotLocationStats
      />
      <GamePlayerStats
      />
    </Col>
  );
};

export default GameRightStats;