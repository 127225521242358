import React from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { useDrop } from 'react-dnd';
import AddPlayerHint from './AddPlayerHint';
import { ItemTypes } from '../ItemTypes';
import { updateGameLineupPlayer } from '../../../api/gamesService';
import Player from './Player';
import { colors } from '../../../styles';
import { useUpdatePlayerMutation } from './queries';
import { gameLineupKeys } from '../../../api/gamesQueries';

const StyledLineUpDiv = styled.div`
	background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#161616'};
	pointer-events: ${(props) => props?.notStarted === undefined && 'none'};
	height: ${(props) => props.height};
	border-style: solid;
	border-width: ${(props) => props.borderWidth};
	border-color: ${(props) => props.borderColor};
	border-radius: 10px;
  align-items: center;
	display: flex;
	padding: 1vh 0vh 0vh 0vh;
    align-items:  ${(props) => props.justifyContent};
    justify-content: ${(props) => props.justifyContent};
	flex-direction: column;
	overflow: scroll;
	overflow-x: hidden;

	//helpful resource: https://css-tricks.com/custom-scrollbars-in-webkit/

	::-webkit-scrollbar {
		width: 13px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		border 4px ${colors.BLACK[100]} solid;
		background: ${colors.GRAY[450]};
	}
}
`;

/**
 * Used for both Court and Bench
 */
const Court = ({
  playerOnCourtBenchStatus,
  notStarted,
  allowedPlayers,
  numPlayers,
  players,
  gameStarted,
  height,
  toggleMultiSelection,
  isMultiSelectionActive,
  isPlayerSelected,
  togglePlayerSelection,
  selectedPlayers,
  resetSelection,
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  markPlayerRecentlyMoved,
  isPlayerHighlighted,
  getPlayerHighlightColor,
}) => {
  const queryClient = useQueryClient();
  const queryKey = gameLineupKeys.gameLineupPair(
    homeTeamGameLineupId,
    awayTeamGameLineupId
  );

  const onClick = async (playerId, version) => {
    // call update lineup player with id
    await updateGameLineupPlayer(playerId, ItemTypes.LINEUP, version);
    queryClient.invalidateQueries(queryKey);
  };

  const canDropPlayers = (count) => {
    return (
      (playerOnCourtBenchStatus === ItemTypes.COURT &&
        numPlayers + count <= allowedPlayers &&
        notStarted) ||
      (playerOnCourtBenchStatus === ItemTypes.BENCH &&
        numPlayers + count <= allowedPlayers)
    );
  };

  const updatePlayerMutation = useUpdatePlayerMutation(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    playerOnCourtBenchStatus,
    markPlayerRecentlyMoved
  );

  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.LINEUP,
      drop: async (items) => {
        await Promise.all(
          items.map((item) =>
            updatePlayerMutation.mutateAsync({
              playerId: item.player.id,
              version: item.version,
            })
          )
        );
        resetSelection();
      },
      canDrop: (items) => canDropPlayers(items.length),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [numPlayers]
  );

  return (
    <StyledLineUpDiv
      height={height}
      notStarted={notStarted}
      justifyContent={players && players.length > 0 ? '' : 'center'}
      ref={drop}
      borderWidth={canDrop ? '2px' : '1px'}
      borderColor={canDrop ? '#448fff' : '#707070'}
    >
      {players && players.length > 0 ? (
        players.map((player, idx) => (
          <Player
            playerId={player.id}
            key={player.id}
            player={player}
            notStarted={notStarted}
            firstName={player.playerFirstName}
            lastName={player.playerLastName}
            jerseyNumber={player.playerJerseyNumber}
            type={playerOnCourtBenchStatus}
            version={player._version}
            onIconClick={() => onClick(player.id, player._version)}
            toggleMultiSelection={toggleMultiSelection}
            isSelected={isMultiSelectionActive && isPlayerSelected(player.id)}
            togglePlayerSelection={(item) => {
              if (isMultiSelectionActive) togglePlayerSelection(item);
            }}
            selectedPlayers={selectedPlayers}
            resetSelection={resetSelection}
            isMutating={player?.isMutating}
            isHighlighted={
              !isMultiSelectionActive && isPlayerHighlighted(player.playerId)
            }
            highlightColor={getPlayerHighlightColor(player.playerId)}
          />
        ))
      ) : (
        <AddPlayerHint
          fontColor="#929292"
          text={
            <div>
              <div>Drag & Drop Players</div>
            </div>
          }
        />
      )}
    </StyledLineUpDiv>
  );
};

export default Court;
