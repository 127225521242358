import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { typeScale } from '../../styles/typography';
import { colors, StyledColumnFlexStart } from '../../styles';

const InputComponent = ({
	...props
}) => {
	const [field, meta] = useField(props);

	useEffect(() => {
		props.setFieldError(meta.touched && meta.error);
	}, [meta.touched, meta.error]);

	const useStyles = makeStyles({
		inputStyle: {
			width: props.width,
			height: 40,
			border: `1px solid ${(meta.touched &&meta.error) ? colors.RED[200] : colors.GRAY[450]}`,
			backgroundColor: (meta.touched && meta.error) ? colors.RED[300] : colors.WHITE[100],
			padding: '0px 4px'
		},
		multilineInputStyle: {
			height: 80,
			border: '1px solid'
		},
		inputLabelShrinkStyle: {
			fontWeight: 600,
			fontSize: typeScale.MEDIUM.fontSize,
			color: colors.GRAY[450]
		},
		errorMsgStyle: {
			color: colors.RED[100],
			fontSize: typeScale.SMEDIUM.fontSize,
		}
	});
	const classes = useStyles();

	return (
		<StyledColumnFlexStart>
			<TextField
				{...field}
				{...props}
				error={meta.touched && meta.error}
				InputLabelProps={{
					className: classes.inputLabelShrinkStyle,
					shrink: true,
				}}
				InputProps={{
					className: classes.inputStyle,
					disableUnderline: true,
				}}
				inputProps={{
					max: props.type === 'date' ? '2099-12-31' : props.max
				}}
			/>	
			{(meta.touched && meta.error) && 
			<span className={classes.errorMsgStyle}>{meta.error}</span>}
		</StyledColumnFlexStart>
	);
};

export default InputComponent;

InputComponent.defaultProps = {
	fullWidth: false,
	multiline: false,
	placeholder: '',
};

InputComponent.propTypes = {
	/**
	 * Does the input field stretch horizontally?
	 */
	fullWidth: PropTypes.bool,
	/*
	Does the input field extend multiple lines - will also set the height of the component
	*/
	multiline: PropTypes.bool,
	/*
	Sets the label of the input field - also sets id+name is none are provied
	*/
	label: PropTypes.string.isRequired,
	/*
	html attributes id and name
	*/
	id: PropTypes.string,
	name: PropTypes.string,
	// Placeholder text
	placeholder: PropTypes.string,
	//Controls value of input
	value: PropTypes.any,
	// onChange function-works with value to control component
	onChange: PropTypes.func,
	// error message - usually passed through Formik
	error: PropTypes.any,
	// Text below field that provides context for input
	helperText: PropTypes.string,
	//Styles the width - set this if not using fullWidth prop
	width: PropTypes.number,

};