import React, { useState, useEffect, useRef } from 'react';
import ModalComponent from './ModalComponent';
import ButtonElement from '../Buttons/ButtonElement';
import { Row, Col } from 'react-bootstrap';
import Papa from 'papaparse'; // PLEASE REFER TO: https://www.papaparse.com/ FOR DOCUMENTATION ON PARSING CSV FILE CONTENT TO JSON.
import { getCsvRowData, downloadFileHandler } from '../../utils/downloadsUtil';

const templateFileUploadContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  padding: 8, 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'space-between', 
  alignItems: 'center', 
  border: '1px solid #9B9B9B', 
};

const templateFileUploadDisplayStyle = {
  width: '60%',
};

const templateFileUploadLabelStyle = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  font: 'normal normal bold 16px/18px Barlow', 
  color: '#616161', 
};

const templateExceptionTextStyle = { 
  width: '86%', 
  height: 'auto', 
  paddingTop: 10, 
  paddingBottom: 10, 
  font: 'normal normal normal 14px/18px Inter', 
  color: '#EB7171', 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'flex-start', 
};

const templateExceptionTextContentStyle = { 
  width: '100%', 
  height: 20, 
  textAlign: 'left', 
};

const CsvTemplateUploadModal = ({
	modalTitle, 
  modalOpen,
  setModalOpen, 
  onClose, 
  inputName='', 
  csvDownloadFile, 
  csvFileHeaders = [], 
  setCsvParsedData = () => {}, 
  backToTeamInfoEntryModal, 
  fileData, 
}) => {
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [isErrorStmtDisplayed, setIsErrorStmtDisplayed] = useState(false);
  const [isParsedCsvDataEmpty, setIsParsedCsvDataEmpty] = useState(false);

  const templateFileUploadRef = useRef();

  useEffect(() => {
    if(isErrorStmtDisplayed || isParsedCsvDataEmpty) {
      setIsNextButtonDisabled(isErrorStmtDisplayed || isParsedCsvDataEmpty);
    }
  }, [isErrorStmtDisplayed, isParsedCsvDataEmpty]);

  useEffect(() => {
    setIsNextButtonDisabled(true);
  }, [modalOpen]);

  const onFileUploadHandler = () => {
    resetStates()
    templateFileUploadRef.current.click();
  };

  const onClickFileUploadHandler = event => {
    resetStates();
    event.currentTarget.value = '';
  };

  const onParseCsv2Json = () => {
    const csvTemplateFile = templateFileUploadRef.current?.files[0];
    Papa.parse(csvTemplateFile, {
      download: true, 
      header: false, 
      skipEmptyLines: 'greedy', 
      complete: results => onCsvParseComplete(results), 
      error: errors => onCsvParseError(errors),
    });
  };

  const onCsvParseComplete = results => {
    const { data } = results;

    const hasValidHeaders = data[0].every(header => csvFileHeaders.includes(header.trim()));

    if(hasValidHeaders && data.length > 1) {
      let csvDataRows = data
                        .filter((_, index) => index > 0)
                        .map(row => {
                          const rowObj = {};
                          row?.forEach((r, i) => {
                            const key = csvFileHeaders[i];
                            return rowObj[key] = r;
                          });
                          return rowObj;
                        });
      closeModal();
      setCsvParsedData(csvDataRows);
    }
    else {
      if(!hasValidHeaders) {
        setIsErrorStmtDisplayed(true);
      }
      else if(data.length <= 1) {
        setIsParsedCsvDataEmpty(data.length <= 1 || data[0].every(header => !csvFileHeaders.includes(header)));
      }
    }
  };

  const onCsvParseError = errors => {
  };

  const closeModal = () => {
    onClose();
    setIsParsedCsvDataEmpty(false);
    setIsErrorStmtDisplayed(false);
  };

  const resetStates = () => {
    setIsNextButtonDisabled(true);
    setIsErrorStmtDisplayed(false);
    setIsParsedCsvDataEmpty(false);
  };

  const addFileData = event => {
    const csvData = getCsvRowData(fileData);
    downloadFileHandler(csvData, csvDownloadFile, 'text/csv');
    resetStates();
    templateFileUploadRef.current.click();
    event.stopPropagation();
  };

	return (
		<ModalComponent
			headerTitle={modalTitle}
			open={modalOpen}
			setOpen={setModalOpen}
      modalWidth={700}
      buttonDisabled={isNextButtonDisabled}
			showNextButton={true}
			showBackButton={true}
      nextHandler={onParseCsv2Json}
      backHandler={() => {
        closeModal();
        backToTeamInfoEntryModal();
      }}
      handleClose={closeModal}
		>
      <Row>
        <Col xs={12} sm={12} md={12} className="mb-2" style={templateFileUploadLabelStyle}>
          Upload CSV File
        </Col>
        <Col xs={12} sm={12} md={12} className="mb-2">
          <div className="csv-upload-file" style={{ ...templateFileUploadContainerStyle, border: (isErrorStmtDisplayed || isParsedCsvDataEmpty) ? '1px solid #DC0101' : '1px solid #9B9B9B'}}>
            <input 
              ref={templateFileUploadRef} 
              style={templateFileUploadDisplayStyle} 
              aria-label="template-upload" 
              type='file' 
              name={inputName} 
              accept='.csv' 
              onChange={() => setIsNextButtonDisabled(false)}
              onClick={onClickFileUploadHandler}
            />
            <ButtonElement 
              styleWithIcon={{ marginRight: 0, minWidth: 80, height: 'auto' }} 
              label={'Browse'} 
              onClick={onFileUploadHandler} 
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} className="mb-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
          {isErrorStmtDisplayed && 
          <div style={templateExceptionTextStyle}>
            <div style={templateExceptionTextContentStyle}>File is not compatible, please make sure to use the downloadable template.</div>
            <div style={templateExceptionTextContentStyle}>If you are using the template provided, please ensure that the fields in the first row</div>
            <div style={templateExceptionTextContentStyle}>remains untouched.&nbsp;&nbsp;<a href="#" onClick={event => addFileData(event)}>Download Template</a></div>
          </div>}
          {isParsedCsvDataEmpty && 
          <div style={templateExceptionTextStyle}>
            <div style={templateExceptionTextContentStyle}>The contents of the uploaded file is empty.</div>
            <div style={templateExceptionTextContentStyle}>Provide the necessary data to the downloaded template.</div>
          </div>
          }
        </Col>
      </Row>
		</ModalComponent>
	);
};

export default CsvTemplateUploadModal;