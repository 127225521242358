import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  getUser as getUserQuery,
  listUsers as listUsersQuery,
  getUserByUpdatedAt as getUserByUpdatedAtQuery,
} from '../graphql/queries';
import {
  createUser as createUserMutation,
  updateUser as updateUserMutation,
} from '../graphql/mutations';
import { KEY_SORT_ID } from '../utils/constantsUtils';
import { getUserGames as getUserGamesQuery } from '../graphql_custom/userServiceQueries';
const log = require('../logger')('api');

const apiName = 'AdminQueries';

const getUserInfo = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.accessToken.jwtToken;
};

//create new user
export const createUser = async (
  firstName,
  lastName,
  email,
  role,
  active,
  token
) => {
  const path = '/createNewUser';
  email = email.toLowerCase(); // Transform to lowercase for consistency / case-insensitive searching

  const myInit = {
    body: {
      username: email,
      email: email,
      name: firstName,
      family_name: lastName,
    },
    headers: {
      Authorization: token,
    },
  };

  return API.post(apiName, path, myInit)
    .then((response) => {
      const sub = response?.User?.Attributes?.find(
        (item) => item.Name === 'sub'
      )?.Value;
      if (!sub) {
        throw new Error("Couldn't get sub ID from Cognito User Pool.");
      }

      return API.graphql(
        graphqlOperation(createUserMutation, {
          input: {
            id: sub,
            keySortId: KEY_SORT_ID,
            firstName,
            lastName,
            email,
            role,
            active,
            searchFirstName: firstName.toLowerCase(),
            searchLastName: lastName.toLowerCase(),
          },
        })
      );
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log.error(error.response);
      return error;
    });
};

// update existing user
export const updateUser = async (
  id,
  firstName,
  lastName,
  email,
  role,
  active,
  _version,
  token
) => {
  const path = '/updateUser';
  const myInit = {
    body: {
      username: email,
      name: firstName,
      family_name: lastName,
    },
    headers: {
      Authorization: token,
    },
  };

  return API.post(apiName, path, myInit)
    .then((response) => {
      return API.graphql(
        graphqlOperation(updateUserMutation, {
          input: {
            id: id,
            firstName: firstName,
            lastName: lastName,
            role: role,
            active: active,
            searchFirstName: firstName.toLowerCase(),
            searchLastName: lastName.toLowerCase(),
            _version: _version,
          },
        })
      );
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log.error(error.response);
      return error;
    });
};

//create group for users
export const createUserGroup = async (email, role, token) => {
  const path = '/addUserToGroup';
  const myInit = {
    body: {
      username: email,
      groupname: role,
    },
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    log.error(`error creating user group, ${role}, for ${email}...`, error);
    return error;
  }
};

//remove user group for user
export const removeUserGroup = async (email, role, token) => {
  const path = '/removeUserFromGroup';
  const myInit = {
    body: {
      username: email,
      groupname: role,
    },
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    log.error(`error removing user group, ${role}, for ${email}...`, error);
    return error;
  }
};

//get user
// get call with parameter
export const getUser = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getUserQuery, { id: id })
    );
    // TODO: Should we return the image here too, or make it optional
    return response.data.getUser;
  } catch (err) {
    log.error('Error fetching user...', err);
  }
};

export const getUserGames = async (variables) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getUserGamesQuery, variables)
    );
    
    /** getUser will be null if the user doesn't exist, e.g. admin in Cognito but not in DB */
    return response.data.getUser?.games;
  } catch (err) {
    log.error('Error fetching user...', err);
  }
};

//list all users
export const listUsers = async (token) => {
  const path = '/listUsers';
  const myInit = {
    headers: {
      Authorization: token,
    },
  };

  return await API.get(apiName, path, myInit);
};

//list of users from graphql
export const getListUsers = async (variables = null) => {
  try {
    const usersList = await API.graphql(
      graphqlOperation(listUsersQuery, variables)
    );
    return usersList.data.listUsers;
  } catch (error) {
    log.error('error fetching users...', error);
  }
};

export const listUsersSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    const usersList = await API.graphql(
      graphqlOperation(getUserByUpdatedAtQuery, vars)
    );
    return usersList.data.getUserByUpdatedAt;
  } catch (error) {
    log.error('error fetching users...', error);
  }
};

//get user in a group
export const getGroupUser = () => {};

// get groups for user
export const getUserGroups = async (username, token) => {
  const path = `/listGroupsForUser?username=${username}`;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  return await API.get(apiName, path, myInit);
};

// disable user
export const disableUser = async (username, token) => {
  const path = '/disableUser';
  const myInit = {
    body: {
      username: username,
    },
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    log.error('Unable to disable user...', error);
    return error;
  }
};

export const enableUser = async (username, token) => {
  const path = '/enableUser';
  const myInit = {
    body: {
      username: username,
    },
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    log.error('Unable to enable user...', error);
    return error;
  }
};

export const addUser = async (username, groupname) => {
  const path = '/addUserToGroup';
  const token = await getUserInfo();
  const myInit = {
    body: {
      username: username,
      groupname: groupname,
    },
    headers: {
      Authorization: token,
    },
  };

  return await API.post(apiName, path, myInit);
};

export const removeUser = async (username, groupname, token) => {
  const path = '/removeUserFromGroup';
  const myInit = {
    body: {
      username: username,
      groupname: groupname,
    },
    headers: {
      Authorization: token,
    }, // replace this with attributes you need
  };

  await API.post(apiName, path, myInit);
};
