import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SquareGrid from '../Grids/SquareGrid';
import { colors, RoleItem, GameRoleSpan } from '../../styles';
import RectangleIcon from '../../assets/RectangleSmall.png';
import Warning from '../../assets/Warning@2x.png';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import { COACH, ROLE_COLOR } from '../../data/roles';

const teamItemRow = {
  width: '100%',
};

const activeStatusContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0px 0px 3px',
  position: 'relative',
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusIndicatorForRole = {
  height: 6,
  width: 6,
  borderRadius: 3,
  marginRight: 3,
};

const activeStatusText = {
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const imageContainerStyle = {
  width: '100%',
  margin: 0,
};

const imageContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 0,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
  position: 'relative',
};

const teamItemContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
};

const teamNameContainer = {
  color: '#111111',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  paddingBottom: 2,
  position: 'relative', 
};

const leagueType = {
  width: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  wordBreak: 'break-word',
  color: '#585858',
};

const leagueRowCol = {
  width: '100%',
  background: '#F5F5F5 0% 0% no-repeat padding-box',
  padding: 10,
  paddingBottom: 7,
  position: 'relative', 
};

const leagueAbbrText = {
  justifyContent: 'center',
  letterSpacing: '0px',
  color: '#585858',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  textAlign: 'center',
};

const cityStyle = {
  color: '#3C4152',
  marginBottom: 2,
};

const stateStyle = {
  color: '#979797',
};

const rectangleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'absolute',
  top: 56,
  margin: 0,
  width: '100%',
};

const pendingUpdateTextStyle = { 
  font: 'normal normal normal 14px/16px Open Sans', 
  color: '#585858', 
};

const leagueNamePendingUpdateTextStyle = { 
  width: '100%',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  wordBreak: 'break-word',
  font: 'italic normal normal 14px/16px Open Sans', 
  color: '#FF9419', 
};

const leagueAbbrPendingUpdateTextStyle = { 
  font: 'italic normal normal 14px/16px Open Sans', 
  color: '#FF9419', 
  textAlign: 'center',
  paddingBottom: 3,
};


const TeamContentItem = ({
  id,
  name,
  state,
  city,
  isPendingUpdate,
  league,
  leagueId,
  leagueAbbr,
  image,
  active,
  editTeamCtrl,
  openAssignUserRoleModal,
  history,
  role = null,
  activeStatusTextFontSize = 0,
  activeIndicatorTopPosition = 0,
  activeIndicatorLeftPosition = 0,
  cardItemWidth = 0,
  cardItemHeight = 0,
  teamImageHeight = 0,
  teamImageTopPosition = 0,
  nameTextFontSize = 0,
  nameTextTopPosition = 0,
  leagueNameFontSize = 0,
  leagueNameLineHeight = 0,
  leagueAbbrFontSize = 0,
  leagueAbbrLineHeight = 0,
  leagueBarTopPosition = 0,
  cityStateTopPosition = 0,
  cityFontSize = 0,
  cityLineHeight = 0,
  stateFontSize = 0,
  stateLineHeight = 0,
}) => {
  const activeStatusIndicatorStyle = role ? activeStatusIndicatorForRole : activeStatusIndicator;
  return (
    <SquareGrid
      key={id}
      bgColor={colors.WHITE[100]}
      borderColor={colors.GRAY[150]}
      height={cardItemHeight || 280}
      width={cardItemWidth || 250}
      onClick={() => {
        if(!role) {
          if(isPendingUpdate) {
            editTeamCtrl(id);
          }
          else {
            history.push(`/admin/team/players/${id}`);
          }
        }
      }}
    >
      {role === COACH && (
        <RoleItem
          $cardItemWidth={cardItemWidth}
          $roleColor={ROLE_COLOR[COACH]}
        >
          <GameRoleSpan>
            {COACH}
          </GameRoleSpan>
        </RoleItem>
      )}
      <Row style={teamItemRow} className="mb-2">
        <Col xl={12} md={12} xs={12}>
          <div style={{ ...activeStatusContainer, top: activeIndicatorTopPosition, left: activeIndicatorLeftPosition, }}>
            {(!isPendingUpdate)
              ?
              <div
                style={{
                  ...activeStatusIndicatorStyle,
                  backgroundColor: active ? '#2DCC38' : '#EE3B3B',
                }}
              ></div>
              :
              <img
                src={Warning}
                style={{ marginRight: 6, }}
                width="12px"
                height="12px"
                alt="Pending Update"
              />
            }
            <span style={(!isPendingUpdate) ? { ...activeStatusText, fontSize: `${activeStatusTextFontSize || 12}px`, } : pendingUpdateTextStyle}>
              {(!isPendingUpdate)
                ? (active ? 'Active' : 'Inactive')
                : 'Pending Update'
              }
            </span>
          </div>
        </Col>
      </Row>

      <Row style={{ ...imageContainerStyle, height: teamImageHeight || 90, }} className="mb-1">
        <Col style={imageContentStyle} xl={12} md={12} xs={12}>
          <img
            src={image}
            style={{ ...imageStyle, top: teamImageTopPosition, }}
            width={`${teamImageHeight || 90}px`}
            height={`${teamImageHeight || 90}px`}
            alt=""
          />
        </Col>
      </Row>

      <Row style={{ ...teamItemRow, marginTop: isPendingUpdate ? 5 : 0 }} className="mb-2">
        <Col xl={12} md={12} xs={12}>
          <div style={{ ...teamItemContentStyle, ...teamNameContainer, top: nameTextTopPosition, font: `normal normal 600 ${nameTextFontSize || 25}px/25px Barlow Condensed`, }}>
            {name}
          </div>
        </Col>
      </Row>

      <Row style={{ ...teamItemRow, position: 'relative' }} className="mb-3">
        <Col xl={12} md={12} xs={12} className="p-0">
          <div style={{ ...leagueRowCol,  top: leagueBarTopPosition, }}>
            <div style={!isPendingUpdate ? { ...leagueType, font: `normal normal normal ${leagueNameFontSize || 14}px/${leagueNameLineHeight || 16}px Open Sans`, } : leagueNamePendingUpdateTextStyle } className="mb-1">
              {(!isPendingUpdate) ? league : "League Pending"}
            </div>
            <div style={!isPendingUpdate ? { ...teamItemContentStyle, ...leagueAbbrText, fontSize: `${leagueAbbrFontSize || 13}px`, lineHeight: `${leagueAbbrLineHeight || 20}px`, } : leagueAbbrPendingUpdateTextStyle}>
              {(!isPendingUpdate) ? leagueAbbr : "Abbreviation Pending"}
            </div>
          </div>
        </Col>
        <div style={rectangleStyle}>
          <img src={RectangleIcon} alt="League" />
        </div>
      </Row>

      <Row style={{ ...teamItemRow }} className="mb-2">
        <Col xl={12} md={12} xs={12}>
          <div style={{ width: '100%', height: 'auto', position: 'relative', top: cityStateTopPosition, }}>
            <div style={{ ...teamItemContentStyle, ...cityStyle, font: `normal normal normal ${cityFontSize || 14}px/${cityLineHeight || 16}px Inter`, }}>{city}</div>
            <div style={{ ...teamItemContentStyle, ...stateStyle, font: `normal normal normal ${stateFontSize || 12}px/${stateLineHeight || 16}px Inter`, }}>{state}</div>
          </div>
        </Col>
      </Row>

      {!role && 
        <OptionsOverflowMenu
          menuItems={[
            { label: 'View', onClick: () => {} },
            { label: 'Edit', onClick: (e) => { editTeamCtrl(id); e.stopPropagation(); } },
            { label: 'Assign Coach', onClick: (e) => { openAssignUserRoleModal(name, id, leagueId); e.stopPropagation(); } }
          ]}
        />
      }
    </SquareGrid>
  );
};

export default TeamContentItem;