import React from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

const StyledHeader = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: row;
`;

const StyledRightDiv = styled.div`
  flex-grow: 10;
  border-bottom: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;
  border-top-right-radius: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ModalHeader = ({ HomeTeamTab, AwayTeamTab, handleClose }) => {
  return (
    <StyledHeader>
      {HomeTeamTab}
      {AwayTeamTab}
      <StyledRightDiv>
        <button
          onClick={handleClose}
          style={{ paddingRight: '2vw', background: 'none', border: 'none' }}
        >
          {' '}
          <CloseIcon style={{ color: 'white' }} />
        </button>
      </StyledRightDiv>
    </StyledHeader>
  );
};

export default ModalHeader;
