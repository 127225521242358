import React, { useState, useEffect } from 'react';
import { GlobalStyle } from './styles';
import {
  Route,
  Switch,
  // BrowserRouter as Router,
  MemoryRouter,
  useHistory,
} from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools'; // eslint-disable-line no-unused-vars
import AdminRoute from './components/Navigation/AdminRoute';
import GameChoicePage from './pages/GameChoicePage';
import StatsPage from './pages/StatsPage';
import PlayByPlayStatsPage from './pages/PlayByPlayStatsPage';
import BasketballClockManager from './pages/ClockManager/BasketballClockManager';
import LoginLayout from './components/layout/LoginLayout';
import Layout from './components/layout/Layout';
import jwt_decode from 'jwt-decode';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, updateUserId } from './redux/userSlice';
import { updateGroup } from './redux/groupSlice';
import {
  selectAuthState,
  selectAuthSessionExpired,
  setHasSessionExpired,
  updateAuthState,
} from './redux/authStateSlice';
import { updateSwitchView } from './redux/switchViewSlice';
import { ADMIN, SCOREKEEPER, CLOCKMANAGER, COACH, SUPERUSER } from './data/roles';

import OfflineAlert from './components/alerts/OfflineAlert';
import ScoreKeeperPage from './pages/ScoreKeeper-new/ScoreKeeperPage';
import HomePage from './pages/HomePage';
import { amplifyAuthStateListener } from './utils/authStateListener';
import { Button, Modal } from 'react-bootstrap';
import LoginPage from './pages/LoginPage';
import { NEW_GROUP_USER } from './utils/constantsUtils';
const log = require('./logger')('debug');

const App = () => {
  const history = useHistory();
  const [userGroup, setUserGroup] = useState();
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();

  /**
   * Callback for the amplify auth state event listener
   * On the user token refresh failute event, update the redux state to notify the user on the login page
   */
  function onTokenRefreshFailure() {
    log.debug('onTokenRefreshFailure');
    dispatch(setHasSessionExpired(true));
  }

  /**
   * Callback for the amplify auth state event listener
   * On the user signed out event, navigate the user to the login page
   */
  function onUserSignedOut() {
    log.debug('onUserSignedOut');
    dispatch(updateAuthState(AuthState.SignedOut));
    history.push('/');
  }

  /** Listen to Amplify Auth State changes */
  amplifyAuthStateListener(onTokenRefreshFailure, onUserSignedOut);

  useEffect(() => {
    /**
     * Hook from the login page
     * Returns a nextAuthState of 'signin' and undefined authData when the user is not logged in
     * Returns a nextAuthState of 'signedin' and authData object when the user is logged in
     */
    return onAuthUIStateChange((nextAuthState, authData) => {
      log.debug('onAuthUIStateChange', nextAuthState, authData);

      /* Update redux state for auth state and user data */
      dispatch(updateAuthState(nextAuthState));
      dispatch(updateUser(JSON.stringify(authData)));
      if (authData?.attributes?.sub)
        dispatch(updateUserId(authData.attributes.sub));
      /* TODO: Is this switch view slice used anywhere? */
      dispatch(updateSwitchView(true));

      /* If signed in determine and store user group (admin, clockmanaged, scorekeeper, superuser?) in redux*/
      if (nextAuthState === 'signedin') {
        const decoded = jwt_decode(
          authData.signInUserSession?.accessToken.jwtToken
        );
        log.debug('decoded', decoded);
        let isAdmin = false;
        let isCoach = false;
        let isScoreKeeper = false;
        let isTimeKeeper = false;
        for (let group of decoded['cognito:groups']) {
          if (group === ADMIN) {
            isAdmin = true;
            break;
          } 
          else if (group === COACH) {
            isCoach = true;
            break;
          } 
          else {
            group === SCOREKEEPER
              ? (isScoreKeeper = true)
              : (isTimeKeeper = true);
          }
        }
        const group = isAdmin
          ? ADMIN
          : isCoach
          ? COACH
          : isScoreKeeper && isTimeKeeper
          ? SUPERUSER
          : isTimeKeeper
          ? CLOCKMANAGER
          : SCOREKEEPER;
        log.info('User signed in as Role:', group);
        setUserGroup(group);
        dispatch(updateGroup(group));
      }
      else if (nextAuthState === 'signin') {
        dispatch(updateGroup(NEW_GROUP_USER));
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MemoryRouter>
        {authState === AuthState?.SignedIn ? (
          userGroup === ADMIN ? (
            <Switch>
              <Route exact path="/scorekeeper">
                <ScoreKeeperPage />
              </Route>
              <Route exact path="/clockmanager">
                <BasketballClockManager />
              </Route>
              <Route exact path="/gamechoice">
                <LoginLayout>
                  <GameChoicePage />
                </LoginLayout>
              </Route>
              <Route exact path="/stats">
                <LoginLayout>
                  <StatsPage />
                </LoginLayout>
              </Route>
              <Route exact path="/gameStats/play-by-play/:gameId">
                <LoginLayout>
                  <PlayByPlayStatsPage />
                </LoginLayout>
              </Route>
              <Route path="/admin">
                {/* Only want OfflineAlert for the Admin Panel */}
                <OfflineAlert />
                <AdminRoute />
              </Route>
              <Route exact path="/">
                <OfflineAlert />
                <Layout>
                  <HomePage />
                </Layout>
              </Route>
            </Switch>
          ) : 
          userGroup === COACH ? (
            <Switch>
              <Route exact path="/gameStats/play-by-play/:gameId">
                <LoginLayout>
                  <PlayByPlayStatsPage />
                </LoginLayout>
              </Route>
              <Route path="/">
                <LoginLayout>
                  <StatsPage />
                </LoginLayout>
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/scorekeeper">
                <ScoreKeeperPage />
              </Route>
              <Route exact path="/clockmanager">
                <BasketballClockManager />
              </Route>
              <LoginLayout>
                <Route path="/">
                  <GameChoicePage />
                </Route>
              </LoginLayout>
            </Switch>
          )
        ) : (
          <LoginPage />
        )}
        <GlobalStyle />
      </MemoryRouter>
      {/* <ReactQueryDevtools /> */}
    </>
  );
};

export default App;
