import React, { useState } from 'react';
import styled from 'styled-components';
import PlayByPlayColumn from './PlayByPlayColumn';
import HomeTeamShotsMadeIndicator from '../../../assets/home_team_shots_made_indicator@2x.png';
import AwayTeamShotsMadeIndicator from '../../../assets/away_team_shots_made_indicator@2x.png';
import { colors } from '../../../styles';

const PlayByPlayEventPeriodTeamLogoContainer = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  justify-content: ${props => props.justifyContent};
  align-items: center;
  background-color: ${props => props.itemIndex % 2 == 0 ? '#F5F5F5' : colors.WHITE[100]}; 
`;

const EventPeriodTeamLogoContainer = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-right: ${props => props.borderRight}px solid #DCDCDC; 
`;

const PlayByPlayEventTimePeriod = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  position: relative;
  border-right: 1px solid #DCDCDC;
`;

const EventGamePeriodContainer = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 14px/45px 'Segoe UI Bold';
  color: #0A0A0A;
`;

const GamePeriodBorder = styled.div`
  width: 22px;
  height: 2px;
  border-bottom: 1px solid #DCDCDC;
  position: absolute;
  bottom: 50%;
  right: 0;
`;

const ClockManagerEvent = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font: normal normal normal 14px/45px 'Segoe UI';
  color: #0A0A0A;
`;

const EventPeriodImageDisplay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: flex-end;
  align-items: ${props => props.alignItems};
`;

const EventTeamLogoContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #DCDCDC;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;

const EventTypeColumnContainer = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  background-color: ${props => props.itemIndex % 2 == 0 ? '#F5F5F5' : colors.WHITE[100]};
  border-right: ${props => props.borderRight}px solid #DCDCDC;
`;

const EventTypeColumnContent = styled.div`
  width: ${props => props.width || '80%'};
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: center;
  font: normal normal ${props => props.fontWeight} 14px/45px 'Segoe UI';
  color: #0A0A0A;
  border-right: ${props => props.borderRight}px solid #DCDCDC;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;

const TeamScoreColumnContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/45px 'Segoe UI';
  color: #0A0A0A;
  background-color: ${props => props.itemIndex % 2 == 0 ? '#F5F5F5' : colors.WHITE[100]};
  border-right: ${props => props.borderRight}px solid #DCDCDC;
`;

const RelatedEventTeamImageSpace = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  margin-right: ${props => props.marginRight}px;
`;

const MainEventBorders = styled.div`
  width: 85%;
  height: 50%;
  border-top: 1px solid #97E0FF;
`;

const ChildRelatedEventBorders = styled.div`
  width: 15px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 46%;
  border-bottom: 1px solid #97E0FF;
  border-left: 1px solid #97E0FF;
`;

const PERIOD_EVENTS = {
  ASSIST: 'Assist',
  bench: 'Bench',
  BLOCK: 'Block',
  CHANGE_POSSESSION: 'Change Possession', 
  court: 'Court',
  DEFLECT: 'Deflect',
  FREE_THROW_ATTEMPT: 'Free Throws',
  FREE_THROW_MADE: 'Free Throw Made',
  FREE_THROW_MISS: 'Free Throw Missed',
  FT_FINAL: 'Free Throw Final',
  GAME_CANCELED: 'Game Canceled',
  GAME_ENDED: 'Game Ended',
  GAME_PAUSED: 'Game Paused',
  GAME_RESUMED: 'Game Resumed',
  GAME_STARTED: 'Game Started',
  JUMPBALL: 'Jumpball',
  PERSONAL_FOUL: 'Personal Foul',
  'Q1 STARTED': '1ST Quarter Started',
  'Q2 STARTED': '2ND Quarter Started',
  'Q3 STARTED': '3RD Quarter Started',
  'Q4 STARTED': '4TH Quarter Started',
  POSSESSION: 'Possession',
  REBOUND: 'Rebound',
  defensive: 'Defensive',
  offensive: 'Offensive',
  SUBSTITUTION: 'Substitution',
  STEAL: 'Steal',
  TECHNICAL_FOUL: 'Technical Foul',
  TIMEOUT: 'Timeout',
  THREE_POINT_MADE: '3PT Made',
  THREE_POINT_MISS: '3PT Missed',
  TURNOVER: 'Turnover',
  TWO_POINT_MISS: '2PT Missed',
  TWO_POINT_MADE: '2PT Made',
  VIOLATION: 'Violation',
  VIOLATION_BACKCOURT: 'Backcourt',
  VIOLATION_CARRYING: 'Carrying',
  VIOLATION_DOUBLE_DRIBBLE: 'Double Dribble',
  VIOLATION_DEF_GOALTENDING: 'Def Goal Tending',
  VIOLATION_FREE_THROW: 'Free Throw',
  VIOLATION_OFF_GOALTENDING: 'Off Goal Tending',
  VIOLATION_TRAVELING: 'Traveling',
  VIOLATION_3_SECONDS: '3 Seconds',
  VIOLATION_5_SECONDS: '5 Seconds',
  VIOLATION_10_SECONDS: '10 Seconds',
};

const displayEventCumulativeScore = (eventType, cumulativeScore) => {
  return [ 
    PERIOD_EVENTS.GAME_STARTED,
    PERIOD_EVENTS.GAME_ENDED,
    PERIOD_EVENTS.GAME_CANCELED,
    PERIOD_EVENTS.GAME_PAUSED,
    PERIOD_EVENTS.GAME_RESUMED,
    PERIOD_EVENTS.SUBSTITUTION 
  ].includes(eventType) 
          ? '' 
          : cumulativeScore;
};

const PlayByPlayEventTeam = ({
  itemIndex, 
  justifyContent, 
  borderRight = 0, 
  children, 
}) => {
  return (
    <PlayByPlayEventPeriodTeamLogoContainer
      itemIndex={itemIndex}
      justifyContent={justifyContent}
    >
      <EventPeriodTeamLogoContainer
        borderRight={borderRight}
      >
        {children}
      </EventPeriodTeamLogoContainer>
    </PlayByPlayEventPeriodTeamLogoContainer>
  );
};

const PlayByPlayGamePeriod = ({
  teamId,
  homeTeamId,
  gamePeriodTime,
  gamePeriodIndicatorPosition,
}) => {
  const teamEventIndicator = (teamId, homeTeamId) => {
    return teamId === homeTeamId ? HomeTeamShotsMadeIndicator : AwayTeamShotsMadeIndicator;
  };

  return (
    <PlayByPlayEventTimePeriod>
      <EventGamePeriodContainer>
        <div>{gamePeriodTime}</div>
      </EventGamePeriodContainer>
      <GamePeriodBorder />
      <img
        src={teamEventIndicator(teamId, homeTeamId)}
        width="10px"
        height="10px"
        style={{ position: 'absolute', bottom: gamePeriodIndicatorPosition, right: -5, }}
      />
    </PlayByPlayEventTimePeriod>
  );
};

const ShowPlayByPlayEvent = ({
  homeTeamId,
  awayTeamScore,
  homeTeamScore,
  playByPlayItem, 
  itemIndex, 
}) => {
  const {
    eventPlayer,
    eventTeamLogo,
    eventType,
    playType,
    eventCreatorRole,
    gamePeriodTime,
    teamId,
    gameOvertimeNumber,
  } = playByPlayItem;
  
  return (
    <div style={{ width: '100%', height: 50, paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', }}>
      <PlayByPlayColumn
        width='10%'
      >
        <PlayByPlayGamePeriod
          teamId={teamId}
          homeTeamId={homeTeamId}
          gamePeriodTime={gamePeriodTime}
          gamePeriodIndicatorPosition='40.5%'
        />
      </PlayByPlayColumn>
      {eventCreatorRole === 'ClockManager'
        ?
          <ClockManagerEvent>
            {(eventType === 'GAME_PAUSED' || 
              eventType === 'GAME_CANCELED' || 
              eventType === 'GAME_ENDED' || 
              eventType === 'GAME_STARTED' || 
              eventType === 'GAME_RESUMED' || 
              eventType === 'Q1 STARTED' || 
              eventType === 'Q2 STARTED' || 
              eventType === 'Q3 STARTED' || 
              eventType === 'Q4 STARTED' ||
              gameOvertimeNumber != 0 && gameOvertimeNumber != null && gameOvertimeNumber != undefined
              )
              ?
                <>
                  <EventTypeColumnContainer
                    itemIndex={itemIndex}
                    borderRight={1}
                    width="97.5%"
                    height='100%'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <EventTypeColumnContent
                      width='100%'
                      justifyContent='center'
                      fontWeight={'normal'}
                      borderRight={0}
                    >
                      {gameOvertimeNumber ? `OT${gameOvertimeNumber} STARTED` : PERIOD_EVENTS[eventType]}
                    </EventTypeColumnContent>
                  </EventTypeColumnContainer>
                </>
              :
                <>
                  <PlayByPlayColumn 
                    width='10%' 
                  >
                    <EventPeriodImageDisplay
                      flexDirection='row'
                      alignItems='center'
                    >
                      <PlayByPlayEventTeam
                        itemIndex={itemIndex}
                        justifyContent='center'
                      >
                        <EventTeamLogoContainer>
                          <img
                            src={eventTeamLogo}
                            width="15px"
                            height="15px"
                          />
                        </EventTeamLogoContainer>
                      </PlayByPlayEventTeam>
                    </EventPeriodImageDisplay>
                  </PlayByPlayColumn>
                  {eventPlayer
                  ?
                    <>
                      <PlayByPlayColumn 
                        width='40%' 
                      >
                        <EventTypeColumnContainer
                          itemIndex={itemIndex}
                          height='100%'
                          justifyContent='center'
                          alignItems='flex-end'
                        >
                          <EventTypeColumnContent
                            fontWeight={600}
                            borderRight={1}
                          >
                            {eventPlayer}
                          </EventTypeColumnContent>
                        </EventTypeColumnContainer>
                      </PlayByPlayColumn>
                      <PlayByPlayColumn 
                        width='50%' 
                      >
                        <EventTypeColumnContainer
                          itemIndex={itemIndex}
                          borderRight={1}
                          height='100%'
                          justifyContent='center'
                          alignItems='flex-end'
                        >
                          <EventTypeColumnContent
                            fontWeight={'normal'}
                          >
                            {playType == null ? PERIOD_EVENTS[eventType] : `${PERIOD_EVENTS[eventType] + ' - ' + PERIOD_EVENTS[playType]}`}
                          </EventTypeColumnContent>
                        </EventTypeColumnContainer>
                      </PlayByPlayColumn>
                    </>
                    :
                    <EventTypeColumnContainer
                      itemIndex={itemIndex}
                      borderRight={1}
                      width="90%"
                      height='100%'
                      justifyContent='center'
                      alignItems='flex-start'
                    >
                      <EventTypeColumnContent
                        width='92%'
                        justifyContent='center'
                        fontWeight={'normal'}
                      >
                        {playType ? `${PERIOD_EVENTS[eventType]} (${playType})` : (PERIOD_EVENTS[eventType] || eventType)}
                      </EventTypeColumnContent>
                    </EventTypeColumnContainer>
                  }
                </>
            }
          </ClockManagerEvent>
        :
          <>
            <PlayByPlayColumn 
              width='8%' 
            >
              <EventPeriodImageDisplay
                flexDirection='row'
                alignItems='center'
              >
                <PlayByPlayEventTeam
                  itemIndex={itemIndex}
                  justifyContent='center'
                >
                  <EventTeamLogoContainer>
                    <img
                      src={eventTeamLogo}
                      width="15px"
                      height="15px"
                    />
                  </EventTeamLogoContainer>
                </PlayByPlayEventTeam>
              </EventPeriodImageDisplay>
            </PlayByPlayColumn>
            {eventType === 'POSSESSION'
              ?
              <PlayByPlayColumn
                width='72%'
              >
                <EventTypeColumnContainer
                  itemIndex={itemIndex}
                  borderRight={1}
                  height='100%'
                  justifyContent='center'
                  alignItems='flex-start'
                >
                  <EventTypeColumnContent
                    width='92%'
                    justifyContent='center'
                    fontWeight={'normal'}
                  >
                    {playType == null ? PERIOD_EVENTS[eventType] : `${PERIOD_EVENTS[eventType] + ' - ' + PERIOD_EVENTS[playType]}`}
                  </EventTypeColumnContent>
                </EventTypeColumnContainer>
              </PlayByPlayColumn>
              :
              <>
                <PlayByPlayColumn 
                  width='32%' 
                >
                  <EventTypeColumnContainer
                    itemIndex={itemIndex}
                    height='100%'
                    justifyContent='center'
                    alignItems='flex-end'
                  >
                    <EventTypeColumnContent
                      fontWeight={600}
                      borderRight={1}
                    >
                      {eventPlayer}
                    </EventTypeColumnContent>
                  </EventTypeColumnContainer>
                </PlayByPlayColumn>
                <PlayByPlayColumn 
                  width='40%' 
                >
                  <EventTypeColumnContainer
                    itemIndex={itemIndex}
                    borderRight={1}
                    height='100%'
                    justifyContent='center'
                    alignItems='flex-end'
                  >
                    <EventTypeColumnContent
                      fontWeight={'normal'}
                    >
                      {playType == null ? PERIOD_EVENTS[eventType] : `${PERIOD_EVENTS[eventType] + ' - ' + PERIOD_EVENTS[playType]}`}
                    </EventTypeColumnContent>
                  </EventTypeColumnContainer>
                </PlayByPlayColumn>
              </>
            }
          </>
          }
      <PlayByPlayColumn 
        width='5%'
      >
        <TeamScoreColumnContent
          itemIndex={itemIndex}
          borderRight={1}
        >
          {displayEventCumulativeScore(PERIOD_EVENTS[eventType], homeTeamScore)}
        </TeamScoreColumnContent>
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='5%' 
      >
        <TeamScoreColumnContent
          itemIndex={itemIndex}
          borderRight={1}
        >
          {displayEventCumulativeScore(PERIOD_EVENTS[eventType], awayTeamScore)}
        </TeamScoreColumnContent>
      </PlayByPlayColumn>
    </div>
  );
};

const ShowPlayByPlayEventWithRelatedEvent = ({
  homeTeamId,
  awayTeamScore,
  homeTeamScore,
  playByPlayItem, 
  relatedEvents, 
  itemIndex, 
}) => {
  const [eventItemsHeight, setEventItemsHeight] = useState(0);

  const {
    id,
    eventPlayer,
    eventTeamLogo,
    eventType,
    gamePeriodTime,
    playType,
    teamId,
  } = playByPlayItem;

  const retrieveChainedRelatedEvents = (relatedEvents, eventId) => {
    const relatedEvent = relatedEvents.find(({ relatedEventId }) => relatedEventId === eventId);
    return relatedEvent
            ? [ relatedEvent, ...retrieveChainedRelatedEvents(relatedEvents, relatedEvent.id) ]
            : [];
  };

  const chainedRelatedEvents = retrieveChainedRelatedEvents(relatedEvents, id);

  const EventRowItem = ({
    eventItem,
    eventJustifyAlignment,
    isMainEvent = false,
    isChildEvent = false,
    itemIndex,
  }) => {
    return (
      <div style={{ width: '100%', height: 50, display: 'flex', flexDirection: 'row' }}>
          <PlayByPlayColumn 
            width='10%' 
          >
            {isChildEvent && 
              <ChildRelatedEventBorders
              />
            }
            <EventPeriodImageDisplay
              flexDirection='column'
              alignItems='flex-end'
            >
              <PlayByPlayEventTeam
                itemIndex={itemIndex}
                flexDirection="row"
                borderRight={1}
                justifyContent={eventJustifyAlignment}
              >
                <RelatedEventTeamImageSpace
                  alignItems='flex-end'
                  justifyContent='flex-end'
                  marginRight={2}
                >
                  {isMainEvent && 
                    <MainEventBorders
                    />
                  }
                </RelatedEventTeamImageSpace>
                <RelatedEventTeamImageSpace
                  alignItems='flex-start'
                  justifyContent='center'
                >
                  <img
                    src={eventItem.eventTeamLogo}
                    width="15px"
                    height="15px"
                  />
                </RelatedEventTeamImageSpace>
              </PlayByPlayEventTeam>
            </EventPeriodImageDisplay>
          </PlayByPlayColumn>
          <PlayByPlayColumn 
            width='40%' 
          >
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
              <EventTypeColumnContainer
                itemIndex={itemIndex}
                height='100%'
                justifyContent={eventJustifyAlignment}
                alignItems='flex-end'
              >
                <EventTypeColumnContent
                  fontWeight={600}
                  borderRight={1}
                  marginBottom={5}
                >
                  {eventItem.eventPlayer}
                </EventTypeColumnContent>
              </EventTypeColumnContainer>
            </div>
          </PlayByPlayColumn>
          <PlayByPlayColumn 
            width='50%' 
          >
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', borderRight: '1px solid #DCDCDC', }}>
              <EventTypeColumnContainer
                itemIndex={itemIndex}
                height='100%'
                justifyContent={eventJustifyAlignment}
                alignItems='flex-end'
              >
                <EventTypeColumnContent
                  fontWeight='normal'
                  marginBottom={5}
                >
                  {eventItem.playType == null ? PERIOD_EVENTS[eventItem.eventType] : `${PERIOD_EVENTS[eventItem.eventType] + ' - ' + PERIOD_EVENTS[eventItem.playType]}`}
                </EventTypeColumnContent>
              </EventTypeColumnContainer>
            </div>
          </PlayByPlayColumn>
      </div>
    );
  };

  const onLoadHandler = (event) => setEventItemsHeight(event.currentTarget.clientHeight);

  return (
    <div onLoad={onLoadHandler} style={{ width: '100%', height: 'auto', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', }}>
      <PlayByPlayColumn
        width='10%'
        height={eventItemsHeight}
      >
        <PlayByPlayGamePeriod
          teamId={teamId}
          homeTeamId={homeTeamId}
          gamePeriodTime={gamePeriodTime}
          gamePeriodIndicatorPosition={(eventItemsHeight / 2) - 5}
        />
      </PlayByPlayColumn>
      <div style={{ width: '80%', height: 'auto', display: 'flex', flexDirection: 'column' }}>
        <EventRowItem
          eventItem={{ eventPlayer, eventTeamLogo, eventType, playType, }}
          eventJustifyAlignment='center'
          itemIndex={itemIndex}
          isMainEvent={true}
        />
        {chainedRelatedEvents.map((relatedEvent) => (
            <EventRowItem
              eventItem={relatedEvent}
              eventJustifyAlignment='center'
              itemIndex={itemIndex}
              isChildEvent={true}
            />
          ))
        }
      </div>
      <PlayByPlayColumn 
        width='5%'
        height={eventItemsHeight}
      >
        <TeamScoreColumnContent
          itemIndex={itemIndex}
          borderRight={1}
        >
          {displayEventCumulativeScore(PERIOD_EVENTS[eventType], homeTeamScore)}
        </TeamScoreColumnContent>
      </PlayByPlayColumn>
      <PlayByPlayColumn 
        width='5%' 
        height={eventItemsHeight}
      >
        <TeamScoreColumnContent
          itemIndex={itemIndex}
          borderRight={1}
        >
          {displayEventCumulativeScore(PERIOD_EVENTS[eventType], awayTeamScore)}
        </TeamScoreColumnContent> 
      </PlayByPlayColumn>
    </div>
  );
};

const PlayByPlayEventDisplay = ({
  homeTeamId,
  playByPlayItem,
  relatedEvents,
  itemIndex,
  homeTeamScore,
  awayTeamScore,
}) => {
  const relatedEventIds = relatedEvents.map(({ relatedEventId }) => relatedEventId);

  return (
    relatedEventIds.includes(playByPlayItem.id)
      ?
      <ShowPlayByPlayEventWithRelatedEvent
        homeTeamId={homeTeamId}
        awayTeamScore={awayTeamScore}
        homeTeamScore={homeTeamScore}
        playByPlayItem={playByPlayItem}
        relatedEvents={relatedEvents}
        itemIndex={itemIndex}
      />
      :
      <ShowPlayByPlayEvent
        homeTeamId={homeTeamId}
        playByPlayItem={playByPlayItem}
        itemIndex={itemIndex}
        awayTeamScore={awayTeamScore}
        homeTeamScore={homeTeamScore}
      />
  );
};

export default PlayByPlayEventDisplay;