
export const imageUploadChangeHandler = (value, imageErrorHook, formik) => {
  const imageFileObj = value.target.files[0];

  if(imageFileObj) {
    const { type, name } = imageFileObj;
    if (type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png' && type !== 'image/gif') {
      imageErrorHook(true);
      return;
    }

    formik.setFieldValue('image', imageFileObj);
  }
};
