import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../styles/colors';
import Exchange from '../../../assets/exchange.png';
import ModalHeader from './ModalHeader';
import Lineup from './Lineup';
import Court from './Court'; // used for both court+bench
import Roster from './Roster';
import Tab from './Tab';
import { ItemTypes } from '../ItemTypes';
import { useMultiSelectionReducer } from './MultiSelection';
const log = require('../../../logger')('AddPlayersModal'); // eslint-disable-line no-unused-vars

const GridLayout = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas:
    'header header header header header header header'
    'space1 title1 space2 title2 space3 title3 space4'
    'space5 roster exchange lineup space6 court space7'
    'bottom bottom bottom bottom bottom bottom bottom'
    'footer footer footer footer footer footer footer';
  grid-template-rows: 1fr 4fr 30fr 2fr 5fr;
  grid-template-columns: 2.5vw 11fr 2.5vw 11fr 2.5vw 12fr 2.5vw;
`;

const StyledGridDiv = styled.div`
  grid-area: ${(props) => props.gridArea};
`;

const StyledMT = styled.div`
  margin-top: 16px;
`;

const StyledTitleText = styled.h2`
  color: white;
  font: normal normal 500 13px/16px Inter;
`;

const StyledSecondaryTitle = styled.h2`
  color: ${colors.GRAY[120]};
  font: normal normal 500 13px/16px Inter;
`;

const StyledFooter = styled.div`
  grid-area: footer;
  display: flex;
  border-top: 1px solid ${colors.GRAY[350]};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledDoneButton = styled.button`
  height: ${isMobile ? '3.6vh' : '4.5vh'};
  padding: 10px;
  width: 7.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    !props.disabled &&
    `linear-gradient(180deg, ${colors.PRIMARY[500]}, ${colors.PRIMARY[600]})`};
  font: normal normal Open Sans;
  color: white;
  font-size: 1.2vw;
  border-radius: 4px;
  margin-right: 2.7vw;
`;

const StyledTitleRow = styled.div`
  padding-top: 1vh;
  grid-area: ${(props) => props.gridArea};
  align-self: end;
`;

const StyledExchangeIcon = styled.img`
  width: 50%;
  height: auto;
  grid-area: exchange;
  place-self: center;
  text-align: center;
`;

const ModalFooter = ({ handleClose, canSubmit }) => {
  return (
    <StyledDoneButton disabled={!canSubmit} onClick={handleClose}>
      Done
    </StyledDoneButton>
  );
};

const AddPlayersModal = ({
  gameId,
  open,
  handleClose,
  gameData,
  teamSize,
  homeRosterPlayers,
  homeLineupPlayers,
  homeCourtPlayers,
  homeBenchPlayers,
  homeReadImage,
  awayRosterPlayers,
  awayLineupPlayers,
  awayCourtPlayers,
  awayBenchPlayers,
  homeTeamLineupOnCourtOnBenchPlayers,
  awayTeamLineupOnCourtOnBenchPlayers,
  awayReadImage,
  isHomeModalSelected,
  notStarted,
  homeTeamName,
  awayTeamName,
  homeTeamLineup,
  awayTeamLineup,
  setHomeModalSelected,
  numPlayersOnCourt,
  numPlayersOnBench,
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  markPlayerRecentlyMoved = () => {},
  isPlayerHighlighted = () => {},
  getPlayerHighlightColor = () => {},
}) => {
  const classes = useStyles();
  const gradients = {
    GREEN: `linear-gradient(180deg, ${colors.GREEN[100]}, ${colors.GREEN[200]})`,
    GREY: `linear-gradient(180deg, ${colors.GRAY[700]}, ${colors.GRAY[800]})`,
  };

  const [canSubmit, setCanSubmit] = useState(false);

  const [
    multiSelectionState,
    multiSelectionDispatch, // eslint-disable-line no-unused-vars
    {
      isMultiSelectionActive,
      toggleMultiSelection,
      isPlayerSelected,
      togglePlayerSelection,
      resetMultiSelection,
    },
  ] = useMultiSelectionReducer();

  useEffect(() => {
    if (homeCourtPlayers || awayCourtPlayers) {
      setCanSubmit(
        isHomeModalSelected
          ? homeCourtPlayers.length === numPlayersOnCourt
          : awayCourtPlayers.length === numPlayersOnCourt
      );
    }
  }, [
    homeCourtPlayers,
    homeBenchPlayers,
    isHomeModalSelected,
    numPlayersOnCourt,
    awayCourtPlayers,
  ]);

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none !important',
      }}
      className="egClass"
      open={open}
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <GridLayout>
          <ModalHeader
            handleClose={handleClose}
            HomeTeamTab={
              <Tab
                selected={isHomeModalSelected}
                onClick={() => setHomeModalSelected(true)}
                teamName={homeTeamName}
                teamLogoSrc={homeReadImage}
              />
            }
            AwayTeamTab={
              <Tab
                selected={!isHomeModalSelected}
                onClick={() => setHomeModalSelected(false)}
                teamName={awayTeamName}
                teamLogoSrc={awayReadImage}
              />
            }
          />

          <StyledGridDiv gridArea="space1" />

          <StyledTitleRow gridArea="title1">
            {/* <MinimumPlayersNote>Please select 5 players for court and 7 players for bench</MinimumPlayersNote> */}
            <StyledTitleText>
              {`Team Players (${
                isHomeModalSelected
                  ? homeRosterPlayers?.length
                  : awayRosterPlayers?.length
              })`}
            </StyledTitleText>
          </StyledTitleRow>
          <StyledGridDiv gridArea="space2" />
          <StyledTitleRow gridArea="title2">
            <StyledTitleText>
              Game Line Up/Active Players (
              {isHomeModalSelected
                ? homeLineupPlayers.length
                : awayLineupPlayers.length}
              )
            </StyledTitleText>
          </StyledTitleRow>
          <StyledGridDiv gridArea="space3" />
          <StyledTitleRow gridArea="title3">
            <StyledSecondaryTitle>
              Court Players (
              {isHomeModalSelected
                ? homeCourtPlayers.length
                : awayCourtPlayers.length}
              /{numPlayersOnCourt})
            </StyledSecondaryTitle>
          </StyledTitleRow>
          <StyledGridDiv gridArea="space4" />
          <StyledGridDiv gridArea="space5" />
          <Roster
            teamPlayers={
              isHomeModalSelected ? homeRosterPlayers : awayRosterPlayers
            }
            teamLineupOnCourtOnBench={
              isHomeModalSelected
                ? homeTeamLineupOnCourtOnBenchPlayers
                : awayTeamLineupOnCourtOnBenchPlayers
            }
            isMultiSelectionActive={isMultiSelectionActive(ItemTypes.ROSTER)}
            toggleMultiSelection={toggleMultiSelection}
            isPlayerSelected={isPlayerSelected}
            togglePlayerSelection={togglePlayerSelection}
            selectedPlayers={multiSelectionState.selectedPlayers}
            resetSelection={resetMultiSelection}
            homeTeamGameLineupId={homeTeamGameLineupId}
            awayTeamGameLineupId={awayTeamGameLineupId}
            homeTeamId={gameData?.homeTeamId}
            awayTeamId={gameData?.awayTeamId}
            markPlayerRecentlyMoved={markPlayerRecentlyMoved}
            isPlayerHighlighted={isPlayerHighlighted}
            getPlayerHighlightColor={getPlayerHighlightColor}
          />
          <StyledExchangeIcon src={Exchange} />
          <Lineup
            teamLineupOnCourtOnBench={
              isHomeModalSelected
                ? homeTeamLineupOnCourtOnBenchPlayers
                : awayTeamLineupOnCourtOnBenchPlayers
            }
            numPlayers={
              isHomeModalSelected
                ? homeLineupPlayers.length
                : awayLineupPlayers.length
            }
            lineup={[homeTeamLineup, awayTeamLineup]}
            lineupPlayers={
              isHomeModalSelected ? homeLineupPlayers : awayLineupPlayers
            }
            isMultiSelectionActive={isMultiSelectionActive(ItemTypes.LINEUP)}
            toggleMultiSelection={toggleMultiSelection}
            isPlayerSelected={isPlayerSelected}
            togglePlayerSelection={togglePlayerSelection}
            selectedPlayers={multiSelectionState.selectedPlayers}
            resetSelection={resetMultiSelection}
            homeTeamGameLineupId={homeTeamGameLineupId}
            awayTeamGameLineupId={awayTeamGameLineupId}
            homeTeamId={gameData?.homeTeamId}
            awayTeamId={gameData?.awayTeamId}
            markPlayerRecentlyMoved={markPlayerRecentlyMoved}
            isPlayerHighlighted={isPlayerHighlighted}
            getPlayerHighlightColor={getPlayerHighlightColor}
            notStarted={notStarted}
          />
          <StyledGridDiv gridArea="space6" />
          <StyledGridDiv gridArea="court">
            <Court
              notStarted={notStarted}
              playerOnCourtBenchStatus={ItemTypes.COURT}
              height="22vh"
              linearGradient={gradients.GREEN}
              players={
                isHomeModalSelected ? homeCourtPlayers : awayCourtPlayers
              }
              numPlayers={
                isHomeModalSelected
                  ? homeCourtPlayers.length
                  : awayCourtPlayers.length
              }
              allowedPlayers={numPlayersOnCourt}
              isMultiSelectionActive={isMultiSelectionActive(ItemTypes.COURT)}
              toggleMultiSelection={toggleMultiSelection}
              isPlayerSelected={isPlayerSelected}
              togglePlayerSelection={togglePlayerSelection}
              selectedPlayers={multiSelectionState.selectedPlayers}
              resetSelection={resetMultiSelection}
              homeTeamGameLineupId={homeTeamGameLineupId}
              awayTeamGameLineupId={awayTeamGameLineupId}
              homeTeamId={gameData?.homeTeamId}
              awayTeamId={gameData?.awayTeamId}
              markPlayerRecentlyMoved={markPlayerRecentlyMoved}
              isPlayerHighlighted={isPlayerHighlighted}
              getPlayerHighlightColor={getPlayerHighlightColor}
            />
            <StyledMT />
            <StyledSecondaryTitle>
              Bench Players (
              {isHomeModalSelected
                ? homeBenchPlayers.length
                : awayBenchPlayers.length}
              /{numPlayersOnBench})
            </StyledSecondaryTitle>
            <Court
              notStarted={false}
              height="31vh"
              playerOnCourtBenchStatus={ItemTypes.BENCH}
              linearGradient={gradients.GREY}
              players={
                isHomeModalSelected ? homeBenchPlayers : awayBenchPlayers
              }
              numPlayers={
                isHomeModalSelected
                  ? homeBenchPlayers.length
                  : awayBenchPlayers.length
              }
              allowedPlayers={numPlayersOnBench}
              isMultiSelectionActive={isMultiSelectionActive(ItemTypes.BENCH)}
              toggleMultiSelection={toggleMultiSelection}
              isPlayerSelected={isPlayerSelected}
              togglePlayerSelection={togglePlayerSelection}
              selectedPlayers={multiSelectionState.selectedPlayers}
              resetSelection={resetMultiSelection}
              homeTeamGameLineupId={homeTeamGameLineupId}
              awayTeamGameLineupId={awayTeamGameLineupId}
              homeTeamId={gameData?.homeTeamId}
              awayTeamId={gameData?.awayTeamId}
              markPlayerRecentlyMoved={markPlayerRecentlyMoved}
              isPlayerHighlighted={isPlayerHighlighted}
              getPlayerHighlightColor={getPlayerHighlightColor}
            />
          </StyledGridDiv>
          <StyledGridDiv gridArea="space7" />
          <StyledGridDiv gridArea="bottom" />
          <StyledFooter>
            <ModalFooter handleClose={handleClose} canSubmit={canSubmit} />
          </StyledFooter>
        </GridLayout>
      </div>
    </Modal>
  );
};

export default AddPlayersModal;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#1C1C1C',
    border: '1px solid #C2C2C2',
    borderTop: '0px none',
    borderRadius: 20,
    borderTopLeftRadius: 0,
    width: '75%',
    height: '83%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0),
  },
}));
