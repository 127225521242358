import { useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { updateEvent } from '../../../../api/eventsService';
import ClockInput from './ClockInput';
import { EventTypeSelect, TeamSelect } from './EventSelectControls';

export const EditEventTypeForm = {
  FieldsetComponent: EditEventTypeFieldset,
  getValidationSchema: ({ periodDuration, homeTeamId, awayTeamId, allowedEventTypes }) =>
    yup.object({
      minutes: yup.number().required().min(0).max(periodDuration),
      seconds: yup
        .number()
        .required()
        .min(0)
        .when('minutes', {
          is: periodDuration,
          then: (schema) => schema.max(0),
          otherwise: (schema) => schema.max(59),
        }),
      eventType: yup
        .string()
        .oneOf(allowedEventTypes),
      teamId: yup.string().required().oneOf([homeTeamId, awayTeamId]),
    }),
  getInitialValues: ({ event }) => ({
    minutes: event.gamePeriodMinutes ?? '',
    seconds: event.gamePeriodSeconds ?? '',
    eventType: event.eventType,
    teamId: event.teamId,
  }),
  handleSubmit: ({ play, values }) => {
    updateEvent({ ...play.event, ...values });
  },
};

export function EditEventTypeFieldset({
  allowedEventTypes,
  periodDuration,
  homeTeamId,
  homeTeamName,
  awayTeamId,
  awayTeamName,
}) {
  const { values } = useFormikContext();
  const { minutes, seconds } = values;

  return (
    <>
      <Row className="mb-3">
        <Col sm={4} className="pe-0">
          <ClockInput
            minutes={minutes}
            seconds={seconds}
            periodDuration={periodDuration}
          />
        </Col>

        <Col sm={8}>
          <EventTypeSelect allowedEventTypes={allowedEventTypes} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <TeamSelect
            homeTeamId={homeTeamId}
            homeTeamName={homeTeamName}
            awayTeamId={awayTeamId}
            awayTeamName={awayTeamName}
          />
        </Col>
      </Row>
    </>
  );
}
