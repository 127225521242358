import React from 'react'
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { Offline } from "react-detect-offline";
import { colors } from '../../styles';

const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
  background: ${colors.BLACK[100]};
  opacity: 0.9;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AlertText = styled.div`
  color: ${colors.WHITE[100]};
  background-color: ${colors.RED[400]};
  position: fixed;
  padding: 6px 72px 9px;
  opacity: 1;
  border-radius: 3px;
  font: normal normal 600 16px/21px 'Segoe UI';
`

function OfflineAlert() {
  return (
    <Offline>
      <StyledModal 
        open={true}
      >
        <AlertText>
          No internet connection detected. Please connect to the internet to use the app.
        </AlertText>
      </StyledModal>
    </Offline>
  )
}

export default OfflineAlert