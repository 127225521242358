import PropTypes from "prop-types"
import React from 'react';
import styled from 'styled-components';
import { getGameEventText } from '../../controller/gameEvents';
import { truncateText } from '../../../../utils/stringUtil';
import SelectDropdown from '../../../../components/Dropdowns/SelectDropdown';
import { colors } from '../../../../styles/colorsStatsCollector';
import { InputGroup } from 'react-bootstrap';
import { shortenFullName } from '../../../../utils/gameLineupUtils';
import { ReactComponent as CourtIconSvg } from '../../../../assets/svg/Court.svg';
import { ReactComponent as BenchIconSvg } from '../../../../assets/svg/Bench.svg';
import { ItemTypes } from '../../../../components/Modals/ItemTypes';

const CourtIcon = () => (
  <InputGroup.Text
    style={{ background: 'green', padding: '0 5px', border: 'none' }}
  >
    <CourtIconSvg />
  </InputGroup.Text>
);

const BenchIcon = () => (
  <InputGroup.Text
    style={{ background: 'red', padding: '0 5px', border: 'none' }}
  >
    <BenchIconSvg />
  </InputGroup.Text>
);

export const EventTypeSelect = ({ allowedEventTypes }) => (
  <StyledSelectDropdown name="eventType">
    {allowedEventTypes.map((eventType) => (
      <option value={eventType} key={eventType}>{getGameEventText({ eventType })}</option>
    ))}
  </StyledSelectDropdown>
);

export const TeamSelect = ({
  homeTeamId,
  homeTeamName,
  awayTeamId,
  awayTeamName,
}) => (
  <StyledSelectDropdown name="teamId">
    <option value={homeTeamId}>{truncateText(homeTeamName, 50)}</option>
    <option value={awayTeamId}>{truncateText(awayTeamName, 50)}</option>
  </StyledSelectDropdown>
);

export const TeamLineupSelect = ({
  players,
  /**
   * (Optional) Used to render the correct logo and color. 
   */
  lineupType,
  name,
}) => (
  <InputGroup hasValidation>
    {lineupType === ItemTypes.COURT ? (
      <CourtIcon />
    ) : lineupType === ItemTypes.BENCH ? (
      <BenchIcon />
    ) : null}

    <StyledSelectDropdown
      name={name}
      wrapWithGroup={false}
      style={
        [ItemTypes.COURT, ItemTypes.BENCH].includes(lineupType)
          ? { borderLeft: 'none' }
          : {}
      }
    >
      {players?.map((p) => (
        <option value={p.id} key={p.id}>
          {p.playerJerseyNumber} -{' '}
          {shortenFullName(p.playerFirstName, p.playerLastName, 15)}
        </option>
      ))}
    </StyledSelectDropdown>
  </InputGroup>
);

TeamLineupSelect.propTypes = {
  lineupType: PropTypes.oneOf([ItemTypes.COURT, ItemTypes.BENCH]),
  name: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const StyledSelectDropdown = styled(SelectDropdown)`
  &&& {
    background-color: ${colors.BLACK[400]};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%239D9D9D' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C%2Fsvg%3E");
    background-position: right 0.5rem center;
    background-size: 12px 9px;

    color: ${colors.GRAY[1050]};
    font: normal normal normal 14px/19px Open Sans;
    border: 1px solid ${colors.GRAY[1600]};
    border-radius: 5px;
    height: 39px;
    padding: 0.375rem 1.5rem 0.375rem 0.5rem;
  }
`;
