import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WideToast = styled(Toast)`
width: auto;
max-width: 800px;
`

const ToastAlert = ({
  text /* Text to be displayed */,
  showToast /* useEffect state */,
  setShowToast /* useEffect setter */,
  variant /* Bootstrap variant: [success, danger, warning, ...], don't use 'light'*/,
  delay /* Time to display toast in milliseconds */,
}) => {
  const toastBodySytle = {
    font: 'normal normal normal 14px/16px Inter',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  };

  return (
      <ToastContainer
        className="p-3"
        position={'top-center'}
        style={{ zIndex: 99 }}
        >
        <WideToast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={delay}
          className={`text-white bg-${variant} border-${variant} border-0 rounded`}
          autohide
        >
          <Toast.Body style={toastBodySytle}>{text}</Toast.Body>
        </WideToast>
      </ToastContainer>
  );
};

ToastAlert.defaultProps = {
  text: 'This is a default message',
  variant: 'success',
  delay: 5000,
};

ToastAlert.propTypes = {
  delay: PropTypes.number,
  showToast: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'info',
    'dark',
  ]),
};

export default ToastAlert;
