import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Alert } from 'react-bootstrap';
import { Country, State, City } from 'country-state-city';
import ModalComponent from './ModalComponent';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import ImageUploadComponent from '../Inputs/ImageUploadComponent';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import InputSwitchComponent from '../Checkbox/InputSwitchComponent';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import InputSelectDropdown from '../Dropdowns/InputSelectDropdown';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import { imageUploadChangeHandler } from '../../utils/imageUploadUtil';
import PlayerImage from '../../assets/Player@2x.png';
import validation from '../../validation-config/player';
import { PLAYERS_POSTIONS } from '../../utils/playersUtil';
import { getTeam } from '../../api/teamsService';

const POSITIONS_LIST = [
  {
    id: 'position_1',
    label: PLAYERS_POSTIONS.CENTER,
    checked: false,
  },
  {
    id: 'position_2',
    label: PLAYERS_POSTIONS.POWER_FORWARD,
    checked: false,
  },
  {
    id: 'position_3',
    label: PLAYERS_POSTIONS.SMALL_FORWARD,
    checked: false,
  },
  {
    id: 'position_4',
    label: PLAYERS_POSTIONS.POINT_GUARD,
    checked: false,
  },
  {
    id: 'position_5',
    label: PLAYERS_POSTIONS.SHOOTING_GUARD,
    checked: false,
  },
];

const HEIGHT_IN_FEET = [1, 2, 3, 4, 5, 6, 7];

const HEIGHT_IN_INCHES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const AllCountriesList = ({
  isNewPlayer,
  countriesList,
  countryStateListFunc,
  formik,
}) => {
  useEffect(() => {
    const { birthCountry } = formik.values;
    const countryItem = countriesList.find(({ id }) => id === birthCountry);
    countryStateListFunc(countryItem?.isoCode);
  }, [formik.values.birthCountry]);

  return (
    <InputSelectDropdown
      label="Birth Country"
      name="birthCountry"
      required={true}
      options={countriesList}
      disabled={!isNewPlayer}
      labelKey={(option) => option && `${option.name}`}
    />
  );
};

const AllCountryStatesOrProvinces = ({
  isNewPlayer,
  countriesList,
  statesOrProvinces,
  stateOrProvinceCitiesFunc,
  isStateOrProvinceDisabled,
  formik,
}) => {
  let typeahead = useRef();

  useEffect(() => {
    const { birthCountry, birthState } = formik.values;
    const countryItem = countriesList.find(({ id }) => id === birthCountry);
    const stateOrProvinceItem = statesOrProvinces.find(
      ({ id }) => id === birthState
    );
    stateOrProvinceCitiesFunc(
      countryItem?.isoCode,
      stateOrProvinceItem?.isoCode
    );
  }, [formik.values.birthState]);

  /** Clear the State field on Country change */
  useEffect(() => {
    if (isNewPlayer) {
      formik.setFieldValue('birthState', '');
      typeahead.clear();
    }
  }, [formik.values.birthCountry]);

  return (
    <InputSelectDropdown
      label="Birth State/Province"
      name="birthState"
      required={true}
      options={statesOrProvinces}
      disabled={!isNewPlayer || isStateOrProvinceDisabled}
      labelKey={(option) => option && `${option.name}`}
      setRef={(ref) => (typeahead = ref)}
    />
  );
};

const AllStateOrProvinceCities = ({
  isNewPlayer,
  stateOrProvinceCities,
  isStateOrProvinceCitiesDisabled,
  formik,
}) => {
  let typeahead = useRef();

  const { birthState, birthCountry } = formik.values;

  /** Clear the City field on State or Country change */
  useEffect(() => {
    if (isNewPlayer) {
      formik.setFieldValue('birthCity', '');
      typeahead.clear();
    }
  }, [birthState, birthCountry]);

  return (
    <InputSelectDropdown
      label="Birth City"
      name="birthCity"
      required={true}
      options={stateOrProvinceCities}
      disabled={!isNewPlayer || isStateOrProvinceCitiesDisabled}
      labelKey={(option) => option && `${option.name}`}
      setRef={(ref) => (typeahead = ref)}
    />
  );
};

const AllLeaguesList = ({ leagueList, setLeagueTeamData, formik }) => {
  const { leagueId } = formik.values;

  useEffect(() => {
    setLeagueTeamData(leagueId);
  }, [leagueId]);

  return (
    <InputSelectDropdown
      label="League"
      name="leagueId"
      required={true}
      options={leagueList}
      labelKey={(option) =>
        option && `${option.name} - (${option.abbreviation})`
      }
    />
  );
};

const SelectedLeagueTeamsList = ({
  playerData,
  leagueTeams,
  setTeamPlayerCountHook,
  setTeamPlayerJerseyNumbers,
  formik,
}) => {
  const { teamId } = formik.values;

  useEffect(() => {
    if (teamId) {
      getSelectedLeagueTeam(teamId);
    }
  }, [teamId]);

  const getSelectedLeagueTeam = async (teamId) => {
    const selectedTeam = await getTeam(teamId);
    let usedPlayerNumbers = selectedTeam?.players.items.map(
      ({ jerseyNumber }) => jerseyNumber
    );
    usedPlayerNumbers = usedPlayerNumbers.filter(function (item) {
      return item !== playerData.jerseyNumber;
    });
    setTeamPlayerJerseyNumbers(usedPlayerNumbers);
    setTeamPlayerCountHook(selectedTeam?.players.items.length);
  };

  return (
    <InputSelectDropdown
      label="Team"
      name="teamId"
      disabled={leagueTeams.length === 0}
      options={leagueTeams}
      labelKey={(option) => option && `${option.name}`}
    />
  );
};

const AddEditPlayerModal = ({
  isNewPlayer,
  playerData,
  leagueList,
  teamList,
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  error,
  backToPlayerInfoEntryModal,
}) => {
  const [countries, setCountries] = useState([]);
  const [statesOrProvinces, setStatesOrProvinces] = useState([]);
  const [stateOrProvinceCities, setStateOrProvinceCities] = useState([]);
  const [isStateOrProvinceDisabled, setIsStateOrProvinceDisabled] = useState(
    true
  );
  const [
    isStateOrProvinceCitiesDisabled,
    setIsStateOrProvinceCitiesDisabled,
  ] = useState(true);
  const [selectedLeagueTeamSize, setSelectedLeagueTeamSize] = useState();
  const [teamPlayerCount, setTeamPlayerCount] = useState();
  const [teamPlayerJerseyNumbers, setTeamPlayerJerseyNumbers] = useState([]);
  const [leagueTeams, setLeagueTeams] = useState([]);
  const [showPositionList, setShowPositionList] = useState(false);
  const [updatedPositions, setUpdatedPositions] = useState(POSITIONS_LIST);

  const stateOrProvinceRef = useRef();

  useEffect(() => {
    if (isStateOrProvinceDisabled) {
      setIsStateOrProvinceCitiesDisabled(isStateOrProvinceDisabled);
    }
  }, [isStateOrProvinceDisabled]);

  useEffect(() => {
    const countries = getCountries();
    const country = countries.find(({ id }) => id === playerData.birthCountry);
    const stateOrProvince = getStatesOrProvinces(country?.isoCode).find(
      ({ id }) => id === playerData.birthState
    );
    getStateOrProvinceCities(country?.isoCode, stateOrProvince?.isoCode);
    getCountryStates(country?.isoCode);
    setCountries(countries);
  }, [playerData.birthState]);

  useEffect(() => {
    if (
      typeof playerData.position == 'string' &&
      playerData.position.length > 0
    ) {
      const updatedPositionsList = updatedPositions.map((position) => {
        return playerData.position
          .split(',')
          .map((pos) => pos.trim())
          .includes(position.label)
          ? { ...position, checked: !position.checked }
          : { ...position };
      });
      setUpdatedPositions([...updatedPositionsList]);
    }

    if (playerData.leagueId && playerData.leagueId.length > 0) {
      setLeagueTeamData(playerData.leagueId);
    }
  }, [playerData]);

  const getCountries = () => {
    return Country.getAllCountries().map(({ isoCode, name }) => ({
      isoCode,
      name,
      id: name,
    }));
  };

  const getStatesOrProvinces = (countryCode) => {
    return State.getStatesOfCountry(countryCode).map(({ isoCode, name }) => ({
      isoCode,
      name,
      id: name,
    }));
  };

  const getCities = (countryCode, stateOrProvince) => {
    return City.getCitiesOfState(
      countryCode,
      stateOrProvince
    ).map(({ name }) => ({ id: name, name }));
  };

  const getCountryStates = (countryCode) => {
    if (countryCode?.length > 0) {
      const statesOrProvinces = getStatesOrProvinces(countryCode);
      setIsStateOrProvinceDisabled(false);
      setStatesOrProvinces(
        statesOrProvinces.length > 0
          ? statesOrProvinces
          : [{ id: 'N/A', name: 'N/A', isoCode: 'N/A', }]
      );
    } else {
      setIsStateOrProvinceDisabled(true);
      setStatesOrProvinces([]);
    }
  };

  const getStateOrProvinceCities = (countryCode, stateOrProvince) => {
    if (
      stateOrProvince?.length > 0 &&
      stateOrProvinceRef.current != stateOrProvince
    ) {
      const stateOrProvinceCities = getCities(countryCode, stateOrProvince);
      setIsStateOrProvinceCitiesDisabled(false);
      setStateOrProvinceCities(
        stateOrProvinceCities.length > 0
          ? stateOrProvinceCities
          : [{ id: 'N/A', name: 'N/A' }]
      );
    } else {
      setIsStateOrProvinceCitiesDisabled(true);
      setStateOrProvinceCities([]);
    }

    stateOrProvinceRef.current = stateOrProvince;
  };

  const setLeagueTeamData = (leagueId) => {
    const league = leagueList.find(({ id }) => id === leagueId);
    const leagueTeamSize = league?.teamSize;
    setSelectedLeagueTeamSize(leagueTeamSize);
    const leagueTeams = teamList.filter((team) => team.leagueId === leagueId);
    setLeagueTeams(leagueTeams);
  };

  const showPositionListHandler = () => setShowPositionList(true);

  const hidePositionListHandler = () => setShowPositionList(false);

  const onSelectedPositionsChangeHandler = (event, formik) => {
    const updatedPositionsList = updatedPositions.map((position) => {
      return position.id === event.target.name
        ? { ...position, checked: !position.checked }
        : { ...position };
    });
    setUpdatedPositions([...updatedPositionsList]);

    const positionLabels = updatedPositionsList
      .filter(({ checked }) => checked == true)
      .map(({ label }) => label);
    formik.setFieldValue('position', positionLabels.join(', '));
  };

  const playerValidationSchema = Yup.object({
    firstName: Yup.string().when('null', {
      is: () => validation.firstName.isRequired.value,
      then: Yup.string().required(validation.firstName.isRequired.message),
    }),
    lastName: Yup.string().when('null', {
      is: () => validation.lastName.isRequired.value,
      then: Yup.string().required(validation.lastName.isRequired.message),
    }),
    position: Yup.string().when('null', {
      is: () => validation.position.isRequired.value,
      then: Yup.string().required(validation.position.isRequired.message),
    }),
    jerseyNumber: Yup.number()
      .min(
        validation.jerseyNumber.min.value,
        validation.jerseyNumber.min.message
      )
      .max(
        validation.jerseyNumber.max.value,
        validation.jerseyNumber.max.message
      )
      .notOneOf(teamPlayerJerseyNumbers, 'This jersey number is already taken.')
      .when('null', {
        is: () => validation.jerseyNumber.isRequired.value,
        then: Yup.number().required(validation.jerseyNumber.isRequired.message),
      }),
    dateOfBirth: Yup.string().when('null', {
      is: () => validation.dateOfBirth.isRequired.value,
      then: Yup.string().required(validation.dateOfBirth.isRequired.message),
    }),
    birthCity: Yup.string().when('null', {
      is: () => validation.birthCity.isRequired.value,
      then: Yup.string().required(validation.birthCity.isRequired.message),
    }),
    birthState: Yup.string()
      .oneOf(
        statesOrProvinces.map((stateOrProvince) => stateOrProvince.id),
        validation.birthState.options.message
      )
      .when('null', {
        is: () => validation.birthState.isRequired.value,
        then: Yup.string().required(validation.birthState.isRequired.message),
      }),
    birthCountry: Yup.string()
      .matches(
        validation.birthCountry.regex.value,
        validation.birthCountry.regex.message
      )
      .oneOf(
        countries.map((country) => country.id),
        validation.birthCountry.options.message
      )
      .when('null', {
        is: () => validation.birthCountry.isRequired.value,
        then: Yup.string().required(validation.birthCountry.isRequired.message),
      }),
    weight: Yup.number()
      .min(validation.weight.min.value, validation.weight.min.message)
      .when('null', {
        is: () => validation.weight.isRequired.value,
        then: Yup.number().required(validation.weight.isRequired.message),
      }),
    heightInFeet: Yup.string().when('null', {
      is: () => validation.heightInFeet.isRequired.value,
      then: Yup.string().required(validation.heightInFeet.isRequired.message),
    }),
    heightInInches: Yup.string().when('null', {
      is: () => validation.heightInInches.isRequired.value,
      then: Yup.string().required(validation.heightInInches.isRequired.message),
    }),
    institute: Yup.string().when('null', {
      is: () => validation.institute.isRequired.value,
      then: Yup.string().required(validation.institute.isRequired.message),
    }),
    leagueId: Yup.string().when('null', {
      is: () => validation.leagueId.isRequired.value,
      then: Yup.string().required(validation.leagueId.isRequired.message),
    }),
    teamId: Yup.string().when('null', {
      is: () => selectedLeagueTeamSize == teamPlayerCount,
      then: Yup.string().required(
        'The max number of players on this team has reached its limit'
      ),
    }),
    image: Yup.mixed().when('null', {
      is: () => validation.image.isRequired.value,
      then: Yup.string().required(validation.image.isRequired.message),
    }),
    acceptedTerms: Yup.boolean()
      .oneOf([true], validation.acceptedTerms.isRequired.message)
      .when('null', {
        is: () => validation.acceptedTerms.isRequired.value,
        then: Yup.boolean().required(
          validation.acceptedTerms.isRequired.message
        ),
      }),
  });

  const getInitialValues = () => {
    const emptyValues = {
      firstName: '',
      lastName: '',
      position: '',
      jerseyNumber: '',
      dateOfBirth: '',
      birthCity: '',
      birthState: '',
      birthCountry: '',
      weight: '',
      heightInFeet: '',
      heightInInches: '',
      institute: '',
      leagueId: '',
      teamId: '',
      image: '',
      active: true,
      acceptedTerms: false,
    };

    if (!isNewPlayer) {
      playerData.acceptedTerms = true;
    }

    return isNewPlayer ? emptyValues : playerData;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={playerValidationSchema}
      isInitialValid={playerValidationSchema.isValidSync(getInitialValues())}
      enableReinitialize
    >
      {(formik) => (
        <ModalComponent
          headerTitle={isNewPlayer ? 'Add Player' : 'Edit Player'}
          buttonTitle={isNewPlayer ? 'Add Player' : 'Save Changes'}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(formik);
            if (formik.isValid) {
              onSubmit({ ...formik.values }, formik.resetForm);
              setUpdatedPositions(POSITIONS_LIST);
              setShowPositionList(false);
              setTeamPlayerJerseyNumbers([]);
            }
          }}
          modalWidth={1000}
          handleClose={() => {
            formik.resetForm();
            setUpdatedPositions(POSITIONS_LIST);
            onClose();
            setShowPositionList(false);
            setTeamPlayerJerseyNumbers([]);
          }}
          isFooterVisible={true}
          buttonDimmed={!formik.isValid}
          buttonDisabled={!isNewPlayer && !formik.dirty}
          showBackButton={isNewPlayer}
          backHandler={() => {
            backToPlayerInfoEntryModal();
            setShowPositionList(false);
          }}
        >
          <div
            style={{ width: '100%', height: '100%' }}
            onClick={() => hidePositionListHandler()}
          >
            {/* Modal Component Children are wrapped in a Form */}
            {/* Alert Column */}
            <Col sm={12} className="px-1">
              {/* Edit Warning */}
              {!isNewPlayer && (
                <Alert variant={'warning'}>
                  <i>
                    If you need to edit any of the disabled fields please
                    contact your Fast Stats administrator.
                  </i>
                </Alert>
              )}
              {/* Error Message */}
              {error && <Alert variant={'danger'}>{error}</Alert>}
            </Col>

            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <ImageUploadComponent
                      id="file-upload-component"
                      label="Upload Player Image"
                      name="image"
                      imageHeight={'170px'}
                      imageMaxHeight={'200px'}
                      formik={formik}
                      onChange={imageUploadChangeHandler}
                      value={formik.values.image}
                      error={formik.touched.image && formik.errors.image}
                      errorMsg={formik.errors.image}
                      disabled={!isNewPlayer}
                      placeHolder={PlayerImage}
                    />
                  </Col>

                  <Col
                    xs={12}
                    className="mt-1"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <InputSwitchComponent
                      name="active"
                      switchLabel="Player Active?"
                      labelPositive={'Yes'}
                      labelNegative={'No'}
                      marginLeft={15}
                      isHorizontal={true}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={8}>
                <Row>
                  <Col xs={6}>
                    <InputFieldComponent
                      name="firstName"
                      label="First Name"
                      type="text"
                      required={true}
                      disabled={!isNewPlayer}
                    />
                  </Col>

                  <Col xs={6}>
                    <InputFieldComponent
                      name="lastName"
                      label="Last Name"
                      type="text"
                      required={true}
                      disabled={!isNewPlayer}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <AllLeaguesList
                      leagueList={leagueList}
                      setLeagueTeamData={setLeagueTeamData}
                      formik={formik}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <SelectedLeagueTeamsList
                      playerData={playerData}
                      leagueTeams={leagueTeams}
                      setTeamPlayerCountHook={setTeamPlayerCount}
                      setTeamPlayerJerseyNumbers={setTeamPlayerJerseyNumbers}
                      formik={formik}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} style={{ position: 'relative' }}>
                    <InputFieldComponent
                      name="position"
                      label="Position"
                      type="text"
                      required={true}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={showPositionListHandler}
                      readOnly
                    />
                    {showPositionList && (
                      <div
                        style={{
                          width: '91%',
                          zIndex: 20,
                          position: 'absolute',
                          top: 60,
                          backgroundColor: 'white',
                          border: '1px solid black',
                          paddingLeft: 20,
                        }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {updatedPositions?.map(({ id, label, checked }) => {
                          return (
                            <CheckboxWithLabel
                              label={label}
                              checked={checked}
                              name={id}
                              key={id}
                              onChange={(event) =>
                                onSelectedPositionsChangeHandler(event, formik)
                              }
                            />
                          );
                        })}
                      </div>
                    )}
                  </Col>

                  <Col xs={6}>
                    <InputFieldComponent
                      id="jersey"
                      name="jerseyNumber"
                      label="Jersey (Number)"
                      type="number"
                      required={true}
                      min="0"
                      max="99"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <InputFieldComponent
                  id="dateOfBirth"
                  name="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  required={true}
                  disabled={!isNewPlayer}
                />
              </Col>

              <Col xs={4}>
                <AllCountriesList
                  isNewPlayer={isNewPlayer}
                  countriesList={countries}
                  countryStateListFunc={getCountryStates}
                  formik={formik}
                />
              </Col>

              <Col xs={4}>
                <AllCountryStatesOrProvinces
                  isNewPlayer={isNewPlayer}
                  countriesList={countries}
                  statesOrProvinces={statesOrProvinces}
                  stateOrProvinceCitiesFunc={getStateOrProvinceCities}
                  isStateOrProvinceDisabled={isStateOrProvinceDisabled}
                  formik={formik}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <AllStateOrProvinceCities
                  isNewPlayer={isNewPlayer}
                  stateOrProvinceCities={stateOrProvinceCities}
                  isStateOrProvinceCitiesDisabled={
                    isStateOrProvinceCitiesDisabled
                  }
                  formik={formik}
                />
              </Col>

              <Col xs={4}>
                <InputFieldComponent
                  name="weight"
                  label="Weight (lb)"
                  type="number"
                  required={true}
                  min="0"
                />
              </Col>

              <Col xs={4}>
                <Row>
                  <Col xs={6}>
                    <SelectDropdown
                      name="heightInFeet"
                      label="Feet"
                      required={true}
                      options={HEIGHT_IN_FEET}
                      helperText={'Select Height - Feet'}
                    >
                      <option value=""></option>
                      {HEIGHT_IN_FEET?.map((feet) => {
                        return (
                          <option key={`${feet}_feet`} value={feet}>
                            {feet}
                          </option>
                        );
                      })}
                    </SelectDropdown>
                  </Col>

                  <Col xs={6}>
                    <SelectDropdown
                      name="heightInInches"
                      label="Inches"
                      required={true}
                      options={HEIGHT_IN_INCHES}
                      helperText={'Select Height - Inches'}
                    >
                      <option value=""></option>
                      {HEIGHT_IN_INCHES?.map((inches) => {
                        return (
                          <option key={`${inches}_inches`} value={inches}>
                            {inches}
                          </option>
                        );
                      })}
                    </SelectDropdown>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <InputFieldComponent
                  name="institute"
                  label="Institute"
                  type="text"
                  required={true}
                  disabled={!isNewPlayer}
                />
              </Col>
            </Row>

            {isNewPlayer && (
              <Row className="mb-2">
                <Col xs={12}>
                  <InputCheckboxLabel
                    label="Please check the information you have entered as all or some of the fields will not be editable once the Player has been added."
                    name="acceptedTerms"
                  />
                </Col>
              </Row>
            )}
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditPlayerModal;
