import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Button as BootstrapButton, Modal } from 'react-bootstrap';
import { colors, gradients } from '../../../styles/colorsStatsCollector';
import { ActionTypes } from '../ActionTypes.type';
import HeadingWithLine from './HeadingWithLine';
import { shouldShowActionButtons } from '../controller/displayType';
import {
  buttonEvent,
  selectDisplayType,
  setActionType,
  selectActiveEventButton,
  selectBreadcrumbs,
  selectGameTimerStatus,
  selectIsTeamInPosessionSelected,
} from '../controller/scoreKeeperEventSlice';
import InputEvents from '../controller/inputEvents';
import { MOBILE_MEDIA } from '../../../styles';
import logger from '../../../logger';
import { GameTimerStatus } from '../../../utils/gameTimeUtil';
const log = logger('App', 'info'); // eslint-disable-line no-unused-vars

const EventActionButtons = (props) => {
  const [modalProps, setModalProps] = React.useState(true);
  const displayType = useSelector(selectDisplayType);
  const activeEventButton = useSelector(selectActiveEventButton);
  const gameTimerStatus = useSelector(selectGameTimerStatus);
  const isTeamInPossessionSelected = useSelector(
    selectIsTeamInPosessionSelected
  );
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(selectBreadcrumbs);

  const isActive = useCallback(
    (inputEvent) => activeEventButton === inputEvent,
    [activeEventButton]
  );

  const isDeselectable = useCallback(
    (inputEvent) => breadcrumbs.length === 1 && isActive(inputEvent),
    [breadcrumbs, isActive]
  );

  const isEnabled = useCallback(
    (inputEvent) =>
      [
        GameTimerStatus.QUARTER_RUNNING,
        GameTimerStatus.OVERTIME_RUNNING,
        GameTimerStatus.QUARTER_PAUSED,
        GameTimerStatus.OVERTIME_PAUSED,
        GameTimerStatus.QUARTER_ENDED,
      ].includes(gameTimerStatus?.timerStatus) &&
      !isTeamInPossessionSelected &&
      (shouldShowActionButtons(displayType) || isDeselectable(inputEvent)),
    [
      gameTimerStatus,
      displayType,
      isDeselectable,
      isActive,
      isTeamInPossessionSelected,
    ]
  );

  const cancel = useCallback(() => dispatch(buttonEvent(InputEvents.CANCEL)), [
    dispatch,
  ]);

  const isEndOfQuarter = [GameTimerStatus.QUARTER_ENDED].includes(
    gameTimerStatus?.timerStatus
  );

  const createActionButton = useCallback(
    (points, text, actionType, inputEvent, isWide = false) => (
      <ActionButton
        points={points}
        text={text}
        actionType={actionType}
        disabled={!isEnabled(inputEvent)}
        onClick={() => {
          if (isDeselectable(inputEvent)) {
            cancel();
          } else if (isEndOfQuarter) {
            setModalProps({
              show: true,
              inputEvent: inputEvent,
              actionType: actionType,
            });
          } else {
            dispatch(buttonEvent(inputEvent));
            dispatch(setActionType(actionType));
          }
        }}
        $isActive={isActive(inputEvent)}
        isWide={isWide}
      />
    ),
    [
      cancel,
      dispatch,
      displayType,
      isActive,
      isEnabled,
      isDeselectable,
      gameTimerStatus,
    ]
  );

  return (
    <>
      {/* Button Grid */}
      <Grid {...props}>
        <ActionButtonHeader actionType={ActionTypes.Made} />
        <ActionButtonHeader actionType={ActionTypes.Missed} />
        <ActionButtonHeader actionType={ActionTypes.Fouls} />

        {createActionButton(
          2,
          'points',
          ActionTypes.Made,
          InputEvents.SHOT_2PT_MADE
        )}
        {createActionButton(
          2,
          'points',
          ActionTypes.Missed,
          InputEvents.SHOT_2PT_MISS
        )}
        {createActionButton(
          null,
          'personal foul',
          ActionTypes.Fouls,
          InputEvents.PERSONAL_FOUL
        )}

        {createActionButton(
          3,
          'points',
          ActionTypes.Made,
          InputEvents.SHOT_3PT_MADE
        )}
        {createActionButton(
          3,
          'points',
          ActionTypes.Missed,
          InputEvents.SHOT_3PT_MISS
        )}
        {createActionButton(
          null,
          'technical foul',
          ActionTypes.Fouls,
          InputEvents.TECHNICAL_FOUL
        )}

        <ActionButtonHeader actionType={ActionTypes.Actions} isWide={true} />

        {createActionButton(
          null,
          'free throws',
          ActionTypes.Actions,
          InputEvents.FT_ROUTINE
        )}

        {createActionButton(
          null,
          'violation',
          ActionTypes.Actions,
          InputEvents.VIOLATION
        )}
        {createActionButton(
          null,
          'turnover',
          ActionTypes.Actions,
          InputEvents.TURNOVER
        )}
        {createActionButton(
          null,
          'block',
          ActionTypes.Actions,
          InputEvents.BLOCK
        )}
        {createActionButton(
          null,
          'deflect',
          ActionTypes.Actions,
          InputEvents.DEFLECT
        )}
        {createActionButton(
          null,
          'steal',
          ActionTypes.Actions,
          InputEvents.STEAL
        )}
      </Grid>

      {/* Quarter Ended Modal */}
      <QuarterEndedModal show={modalProps.show} size="md" centered>
        <QuarterEndedModalBody>
          <span>
            The current quarter has ended. <br></br> Are you sure you want to
            add another play?
          </span>
        </QuarterEndedModalBody>
        <QuarterEndedModalFooter>
          <QuarterEndedModalButtonYes
            variant="primary"
            onClick={() => {
              dispatch(buttonEvent(modalProps.inputEvent));
              dispatch(setActionType(modalProps.actionType));
              setModalProps({ show: false });
            }}
          >
            Yes
          </QuarterEndedModalButtonYes>
          <QuarterEndedModalButtonCancel
            variant="secondary"
            onClick={() => setModalProps({ show: false })}
          >
            Cancel
          </QuarterEndedModalButtonCancel>
        </QuarterEndedModalFooter>
      </QuarterEndedModal>
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  height: 100%;
  gap: 27px 18px;
  justify-items: center;
  align-items: center;

  border-left: 1px solid ${colors.GRAY_TRANSPARENT[700]};
  padding-left: 25px;
  margin-left: 25px;

  @media ${MOBILE_MEDIA} {
    padding-left: 20px;
    margin-left: 20px;
  }
`;

const QuarterEndedModal = styled(Modal)`
  .modal-content {
    background: #dfe0ea;
    padding: 25px;
  }
`;

const QuarterEndedModalBody = styled(Modal.Body)`
  color: #000000;
  text-align: center;
  font: normal normal normal 17px/24px Open Sans;
`;

const QuarterEndedModalFooter = styled(Modal.Footer)`
  color: #000000;
  text-align: center;
  justify-content: center;
`;

const QuarterEndedModalButtonYes = styled(BootstrapButton)`
  background: linear-gradient(#448fff, #224880);
  border-radius: 4px;
  border-color: none;
  font: normal normal normal 16px/24px Open Sans;
  width: 132px;
  height: 40px;
`;

const QuarterEndedModalButtonCancel = styled(BootstrapButton)`
  background: transparent;
  border-radius: 4px;
  color: #000000;
  border: 1px solid #7b7b7b;
  font: normal normal normal 16px/24px Open Sans;
  width: 132px;
  height: 40px;
`;

export const ActionButtonHeader = ({ actionType, isWide, ...props }) => {
  return (
    <HeadingWithLine
      label={actionType.label}
      color={actionType.color}
      style={{ gridColumn: isWide ? 'span 3' : 'unset' }}
    />
  );
};

export const ActionButton = styled(
  ({ points, text, actionType, isWide = false, ...props }) => {
    return (
      <Button {...props}>
        <div className="points">{points}</div>
        <div className="text">{text}</div>
      </Button>
    );
  }
)`
  &&& {
    width: ${({ isWide }) => (isWide ? '301px' : '90px')};
    height: ${({ isWide }) => (isWide ? '39px' : '90px')};
    ${({ isWide }) => (isWide ? `grid-column: span 3;` : '')}

    border: 1px solid ${({ actionType }) => actionType.color};
    border-radius: 45px;
    box-shadow: 4px 7px 16px #00000029;

    &,
    &:hover {
      background: ${({ $isActive, actionType, theme }) =>
        $isActive
          ? `${actionType.color}`
          : theme.lightMode
          ? colors.GRAY[100]
          : gradients.BLACK};
    }

    .MuiButton-label {
      flex-direction: column;
    }

    .points {
      font: normal normal bold 22px/30px Open Sans;
      display: block;
    }

    .text {
      font: normal normal bold 10px/14px Open Sans;
      text-transform: uppercase;
    }

    .points,
    .text {
      color: ${({ $isActive, theme }) =>
        $isActive
          ? colors.WHITE[100]
          : theme.lightMode
          ? colors.BLACK[100]
          : colors.GRAY[100]};
    }

    &:disabled {
      opacity: 0.4;
    }

    @media ${MOBILE_MEDIA} {
      width: ${({ isWide }) => (isWide ? '263px' : '78px')};
      height: ${({ isWide }) => (isWide ? '34px' : '78px')};
    }
  }
`;

ActionButton.propTypes = {
  points: PropTypes.number,
  text: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf(Object.values(ActionTypes)).isRequired,
  /**
   * Whether this is a wide "full-width" button
   */
  isWide: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * If button should be styled as "active"
   */
  $isActive: PropTypes.bool,
};

export default EventActionButtons;
