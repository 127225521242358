import React from 'react';
import styled from 'styled-components';

const PeriodColumn = styled.div`
  width: ${props => props.width}px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal ${props => props.isSelected ? 'bold' : 'normal'} 16px/21px ${props => props.isSelected ? 'Segoe UI Bold' : 'Segoe UI'};
  color: ${props => props.isSelected ? '#0A0A0A' : '#707070'};
  border-bottom: 2px solid ${props => props.isSelected ? '#BFECFF' : '#DCDCDC'}; 
  border-left: ${props => props.columnIndex > 0 ? 1 : 0}px solid #DCDCDC;
  border-right: ${props => props.columnIndex < (props.periodParams.length - 1) ? 1 : 0}px solid #DCDCDC;
`;

const GamePeriodColumn = ({
    gameId,
    columnIndex,
    columnWidth,
    periodEventParamter,
    periodParams,
    periodColumnHandler
}) => {
  const { 
    period, 
    periodNumber, 
    periodType,
    isSelected
  } = periodEventParamter;

  return (
    <PeriodColumn
      width={columnWidth}
      columnIndex={columnIndex}
      periodParams={periodParams}
      isSelected={isSelected}
      onClick={event => {
        periodColumnHandler(period, periodParams, gameId, periodNumber, periodType);
        event.stopPropagation();
      }}
    >
      {period}
    </PeriodColumn>
  );
};
  
export default GamePeriodColumn;