export const MOBILE_MEDIA = `only screen and (max-device-width: 1366px)`;
export const DESKTOP_MEDIA = `only screen and (min-device-width: 1367px)`;
export const TABLET_ONLY = `only screen and (min-device-width: 1024px) and (max-device-width: 1366px)`;

export const detectMobileMedia = () => {
  return matchMedia(MOBILE_MEDIA).matches;
};

export const detectDesktopMedia = () => {
  return matchMedia(DESKTOP_MEDIA).matches;
};
