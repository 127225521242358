import { API, graphqlOperation } from 'aws-amplify';
import {
  listPlayers as listPlayersQuery,
  getPlayer as getPlayerQuery,
  getPlayerByUpdatedAt as getPlayerByUpdatedAtQuery,
} from '../graphql/queries';
import {
  createPlayer as createPlayerMutation,
  updatePlayer as updatePlayerMutation,
} from '../graphql/mutations';
import { Storage } from '@aws-amplify/storage';
import PlayerImage from '../assets/Player@2x.png';
import { DataStore } from '@aws-amplify/datastore';
import { Player } from '../models';
import { KEY_SORT_ID } from '../utils/constantsUtils';
const log = require('../logger')('api');

Storage.configure({ level: 'public' });

const savePlayerImageProfile = async (playerId, imageFile) => {
  try {
    const response = await Storage.put(`player/${playerId}`, imageFile, {
      level: 'public',
    });
    return response;
  } catch (error) {
    log.error(`Unable to save image ${playerId} to storage...`);
  }
};

export const getPlayerImageProfile = async (playerId) => {
  try {
    const doesImageExist = await Storage.list(`player/${playerId}`);
    if (doesImageExist.length > 0) {
      const imageURL = await Storage.get(`player/${playerId}`);
      return imageURL;
    } else {
      return PlayerImage;
    }
  } catch (error) {
    log.error(`Unable to read image ${playerId} from storage...`);
  }
};

// get call with parameter
export const listPlayers = async (variables = null) => {
  try {
    const playerData = await API.graphql(
      graphqlOperation(listPlayersQuery, variables)
    );
    // const playerData = await DataStore.query(Player);
    return playerData.data.listPlayers;
  } catch (err) {
    log.error('error fetching players...', err);
  }
};

export const listPlayersSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    const playerData = await API.graphql(
      graphqlOperation(getPlayerByUpdatedAtQuery, vars)
    );
    return playerData.data.getPlayerByUpdatedAt;
  } catch (err) {
    log.error('error fetching players...', err);
  }
};

// get call with parameter
export const getPlayer = async (id) => {
  try {
    const playerData = await API.graphql(
      graphqlOperation(getPlayerQuery, { id })
    );
    // const playerData = await DataStore.query(Player, id);
    const playerItem = playerData.data.getPlayer;
    playerItem.image = await getPlayerImageProfile(id);
    return playerItem;
  } catch (err) {
    log.error('error fetching player...', err);
  }
};

export const getPlayersOfTeam = async (teamId) => {
  try {
    const playerData = (await DataStore.query(Player)).filter(
      (data) => data?.teamId === teamId
    );

    return playerData;
  } catch (err) {
    log.error('error fetching player...', err);
  }
};

//create call with parameter

export const createPlayer = async (playerObject) => {
  const {
    firstName,
    lastName,
    jerseyNumber,
    position,
    dateOfBirth,
    birthCity,
    birthState,
    birthCountry,
    weight,
    height,
    active,
    teamId,
    leagueId,
    institute,
    image,
  } = playerObject;

  try {
    const playerData = await API.graphql(
      graphqlOperation(createPlayerMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          firstName,
          lastName,
          jerseyNumber,
          position,
          dateOfBirth: new Date(dateOfBirth).toISOString().substring(0, 10),
          birthCity,
          birthState,
          birthCountry,
          weight,
          height,
          active,
          institute,
          teamId,
          leagueId,
        },
      })
    );

    const { id } = playerData.data.createPlayer;
    await savePlayerImageProfile(id, image);

    // const playerData = await DataStore.save(
    //   new Player({
    //     firstName: playerObject.firstName,
    //     lastName: playerObject.lastName,
    //     jerseyName: playerObject.jerseyName,
    //     jerseyNumber: playerObject.jerseyNumber,
    //     dateOfBirth: playerObject.dateOfBirth,
    //     birthCity: playerObject.birthCity,
    //     birthState: playerObject.birthState,
    //     weight: playerObject.weight,
    //     height: playerObject.height,
    //     active: playerObject.active,
    //     college: playerObject.college,
    //     leagueId: playerObject.leagueId,
    //     league: playerObject.league,
    //   })
    // );

    return playerData;
  } catch (err) {
    log.error('error creating player...', err);
    return err;
  }
};

export const updatePlayer = async (playerObject) => {
  try {
    const playerData = await API.graphql(
      graphqlOperation(updatePlayerMutation, {
        input: {
          id: playerObject.id,
          active: playerObject.active,
          jerseyNumber: playerObject.jerseyNumber,
          teamId: playerObject.teamId,
          leagueId: playerObject.leagueId,
          position: playerObject.position,
          weight: playerObject.weight,
          height: playerObject.height,
          _version: playerObject._version,
        },
      })
    );
    // const original = await DataStore.query(Player, playerObject.id);

    // const playerData = await DataStore.save(
    //   Player.copyOf(original, (updated) => {
    //     updated.firstName = playerObject.firstName;
    //     updated.lastName = playerObject.lastName;
    //     updated.jerseyName = playerObject.jerseyName;
    //     updated.jerseyNumber = playerObject.jerseyNumber;
    //     updated.dateOfBirth = playerObject.dateOfBirth;
    //     updated.birthCity = playerObject.birthCity;
    //     updated.birthState = playerObject.birthState;
    //     updated.weight = playerObject.weight;
    //     updated.height = playerObject.height;
    //     updated.active = playerObject.active;
    //     updated.college = playerObject.college;
    //     updated.leagueId = playerObject.leagueId;
    //   })
    // );

    return playerData;
  } catch (err) {
    log.error('error updating player...', err);
    return err;
  }
};

export const deletePlayer = async (id) => {
  try {
    // const playerData = await API.graphql(
    // 	graphqlOperation(DeletePlayer, { input: { id: id } })
    // );
    const toDelete = await DataStore.query(Player, id);
    const playerData = await DataStore.delete(toDelete);
    return playerData;
  } catch (err) {
    log.error('error deleting player...', err);
  }
};
