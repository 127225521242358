import React from 'react';
import { useDrop } from 'react-dnd';
import AddPlayerHint from './AddPlayerHint';
import Player from './Player';
import { ItemTypes } from '../ItemTypes';
import RosterOutline from './RosterOutline';
import {
  useUpdatePlayerFromRosterMutation,
  useUpdatePlayerMutation,
} from './queries';

const Lineup = ({
  numPlayers,
  lineupPlayers,
  lineup,
  toggleMultiSelection,
  isMultiSelectionActive,
  isPlayerSelected,
  togglePlayerSelection,
  selectedPlayers,
  resetSelection,
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  homeTeamId,
  awayTeamId,
  markPlayerRecentlyMoved,
  isPlayerHighlighted,
  getPlayerHighlightColor,
  notStarted,
}) => {
  const updatePlayerFromRosterMutation = useUpdatePlayerFromRosterMutation(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    homeTeamId,
    awayTeamId,
    markPlayerRecentlyMoved
  );

  const updatePlayerFromCourtOrBenchMutation = useUpdatePlayerMutation(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    ItemTypes.LINEUP,
    markPlayerRecentlyMoved
  );

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: [ItemTypes.ROSTER, ItemTypes.BENCH, ItemTypes.COURT],
    drop: async (items) => {
      await Promise.all(
        items.map((item) => {
          if (item.type === ItemTypes.ROSTER) {
            return updatePlayerFromRosterMutation.mutateAsync(item);
          } else {
            return updatePlayerFromCourtOrBenchMutation.mutateAsync(item);
          }
        })
      );
      resetSelection();
    },
    canDrop: (items, monitor) => {
      return notStarted || items.every((item) => item.type !== ItemTypes.COURT);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <RosterOutline
      drop={drop}
      borderStyle={canDrop ? 'solid' : 'none'}
      justifyContent={lineupPlayers?.length > 0 ? '' : 'center'}
    >
      {numPlayers > 0 ? (
        lineupPlayers.map((player, index) => (
          <Player
            playerId={player.id}
            key={player.playerId}
            player={player}
            firstName={player.playerFirstName}
            lastName={player.playerLastName}
            jerseyNumber={player.playerJerseyNumber}
            inCategory={player.onCourt || player.onBench}
            version={player._version}
            type={ItemTypes.LINEUP}
            toggleMultiSelection={toggleMultiSelection}
            isSelected={isMultiSelectionActive && isPlayerSelected(player.id)}
            togglePlayerSelection={(item) => {
              if (isMultiSelectionActive) togglePlayerSelection(item);
            }}
            selectedPlayers={selectedPlayers}
            resetSelection={resetSelection}
            isMutating={player?.isMutating}
            isHighlighted={!isMultiSelectionActive && isPlayerHighlighted(player.playerId)}
            highlightColor={getPlayerHighlightColor(player.playerId)}
          />
        ))
      ) : (
        <AddPlayerHint
          text={
            <div>
              <div>Drag & Drop Players</div>
            </div>
          }
        />
      )}
    </RosterOutline>
  );
};

export default Lineup;
