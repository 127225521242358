import React, { useState, useEffect, useCallback } from "react";
import MainLayout from "../components/layout/MainLayout";
import { useParams } from "react-router-dom";
import { getPlayer, updatePlayer, deletePlayer } from "../api/playersService";
import { listLeagues } from "../api/leaguesService";
import styled from "styled-components";
import Switch from "../components/Switches/ObjectSwitch";
import { colors } from "../styles/colors";
import { typeScale } from "../styles/typography";
import { buttonThemes } from "../styles/themes";
import Button from "../components/Buttons/ButtonElement";
import InputComponent from "../components/Inputs/InputComponent";
import ModalComponent from "../components/Modals/ModalComponent";
import DeleteModal from "../components/Modals/DeleteModal";
import useDropdown from "../components/Dropdowns/Dropdown";
import { StyledFlexRow, StyledFlexEnd } from "../styles/flex";
import { useFormik } from "formik";
import * as yup from "yup";
import { statesList } from "../data/statesList";
import { useHistory } from "react-router-dom";

const StyledLabel = styled.span`
  color: ${colors.GRAY[400]};
  font-size: ${typeScale.MEDIUM.fontSize};
  font-weight: 600;
  min-width: 130px;
`;

const StyledValue = styled.span``;

const StyledPair = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const FlexDiv = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 3rem;
`;

const StyledDiv = styled.div`
  margin-top: 2rem;
`;

const RightButtonSpan = styled.span`
  margin-left: 1rem;
`;

const PlayerDetailPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [playerData, setPlayerData] = useState([]);
  const [leagues, leaguesHook] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [state, StateDropdown, setState] = useDropdown(
    "Birth State",
    "Select",
    statesList
  );
  const [league, LeaguesDropdown, setLeague] = useDropdown(
    "League",
    "select",
    leagues
  );

  const callGetPlayer = useCallback(async () => {
    const playerData = await getPlayer(id);
    const filteredData = playerData;
    setPlayerData(filteredData);
    setIsChecked(filteredData.active);
    setLeague(filteredData.league && filteredData.league.name);
    setState(filteredData.birthState);
  });

  const callUpdatePlayer = async () => {
    await updatePlayer({ ...playerData, id: id, active: isChecked });
  };

  useEffect(() => {
    callGetLeagues();
    callGetPlayer();
  }, [callGetPlayer, id]);

  useEffect(() => {
    callUpdatePlayer();
  }, [callUpdatePlayer, isChecked]);

  const callGetLeagues = async () => {
    const leaguesData = await listLeagues();
    leaguesHook(leaguesData.data.listLeagues.items);
  };

  const processLeague = () => {
    const selectedLeague = leagues.filter((item) => item.name === league);
    return selectedLeague[0]?.id;
  };

  const validationSchema = yup.object({
    firstName: yup.string("Enter player name").required("Enter player name "),
    lastName: yup.string("Enter player name").required("Enter player name "),
    dateOfBirth: yup.date("Enter date of birth"),
    birthCity: yup.string("Enter player name"),
    jerseyNumber: yup.string().required("Enter a number"),
    weight: yup.number().min(0).integer("invalid decimal"),
    height: yup.string("Enter player height").required("Enter player height"),
    college: yup.string("Enter a college"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: playerData.firstName,
      lastName: playerData.lastName,
      dateOfBirth: playerData.dateOfBirth,
      birthCity: playerData.birthCity,
      jerseyNumber: playerData.jerseyNumber,
      weight: playerData.weight,
      height: playerData.height,
      college: playerData.college,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values) => {
    const league = processLeague();
    await updatePlayer({
      ...values,
      wieght: parseInt(values.weight),
      jerseyName: `${values.lastName}, ${values.firstName}`,
      leagueId: league,
      active: isChecked,
      id: id,
      birthState: state,
    });
    await callGetPlayer();
    setOpen(false);
  };

  const handleDelete = () => {
    deletePlayer(id);
    history.goBack();
  };

  const KeyValue = ({ label, value }) => {
    return (
      <StyledPair>
        <StyledLabel>{label}</StyledLabel>
        <StyledValue>{value}</StyledValue>
      </StyledPair>
    );
  };

  const TitleComponent = () => {
    return (
      <div>
        <span>{playerData.jerseyName}</span>
        <StyledFlexEnd>
          <Button
            onClick={() => setOpen(true)}
            label='edit player'
            size='small'
          />
          <RightButtonSpan>
            <Button
              onClick={() => setDeleteOpen(true)}
              label='Delete player '
              theme={buttonThemes.WHITE}
              size='large'
            />
          </RightButtonSpan>
        </StyledFlexEnd>
      </div>
    );
  };

  return (
    <MainLayout title={<TitleComponent />} bodyContent={""}>
      <FlexDiv>
        <KeyValue label='First name' value={playerData.firstName} />{" "}
        <KeyValue label='Last name' value={playerData.lastName} />{" "}
        <KeyValue label='Date of birth' value={playerData.dateOfBirth} />{" "}
      </FlexDiv>

      <FlexDiv>
        <KeyValue label='Birth city' value={playerData.birthCity} />{" "}
        <KeyValue label='Birth state' value={playerData.birthState} />{" "}
        <KeyValue label='Jersey number' value={playerData.jerseyNumber} />{" "}
      </FlexDiv>

      <FlexDiv>
        <KeyValue label='Weight' value={playerData.weight} />{" "}
        <KeyValue label='Height' value={playerData.height} />{" "}
        <KeyValue
          label='Active'
          value={
            <Switch
              label='Yes'
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          }
        />
      </FlexDiv>

      <div>
        {" "}
        <KeyValue label='College' value={playerData.college} />{" "}
      </div>
      <StyledDiv>
        {" "}
        <KeyValue label='League' value={playerData.league?.name} />{" "}
      </StyledDiv>

      <ModalComponent
        headerTitle='Are you sure'
        open={deleteOpen}
        setOpen={setDeleteOpen}
        footerContent
      >
        <DeleteModal
          message={`Are you sure you want to delete ${playerData.firstName} ${playerData.lastName}?`}
          handleDelete={handleDelete}
          handleCancel={() => setDeleteOpen(false)}
        />
      </ModalComponent>
      <ModalComponent
        headerTitle={"Edit player"}
        buttonTitle={"Save changes"}
        open={open}
        setOpen={setOpen}
        handleSubmit={formik.handleSubmit}
        handleClose={() => setOpen(false)}
        modalWidth={730}
      >
        <StyledFlexRow>
          <LeaguesDropdown />
          <span style={{ marginRight: 40 }}>
            <InputComponent
              label='College'
              id='college'
              name='college'
              width={340}
              value={formik.values.college}
              onChange={formik.handleChange}
              error={formik.touched.college && Boolean(formik.errors.college)}
              helperText={formik.touched.college && formik.errors.college}
            />
          </span>
        </StyledFlexRow>
        <StyledFlexRow>
          <InputComponent
            label='First name'
            id='firstName'
            name='firstName'
            width={240}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <InputComponent
            label='Last name'
            id='lastName'
            name='lastName'
            width={240}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <InputComponent
            label='Date of Birth'
            id='dateOfBirth'
            name='dateOfBirth'
            placeholder='yyyy-mm-dd'
            width={160}
            value={formik.values.dateOfBirth}
            onChange={formik.handleChange}
            error={
              formik.touched.firstName && Boolean(formik.errors.dateOfBirth)
            }
            helperText={formik.touched.firstName && formik.errors.dateOfBirth}
          />
        </StyledFlexRow>
        <StyledFlexRow>
          <InputComponent
            label='Birth city'
            id='birthCity'
            name='birthCity'
            width={240}
            value={formik.values.birthCity}
            onChange={formik.handleChange}
            error={formik.touched.birthCity && Boolean(formik.errors.birthCity)}
            helperText={formik.touched.birthCity && formik.errors.birthCity}
          />
          <StateDropdown />
          <InputComponent
            label='Jersey (number)'
            id='jerseyNumber'
            name='jerseyNumber'
            required={true}
            width={160}
            value={formik.values.jerseyNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.jerseyNumber && Boolean(formik.errors.jerseyNumber)
            }
            helperText={
              formik.touched.jerseyNumber && formik.errors.jerseyNumber
            }
          />
        </StyledFlexRow>
        <StyledFlexRow>
          <InputComponent
            label='Weight (lb)'
            id='weight'
            name='weight'
            width={128}
            value={formik.values.weight}
            onChange={formik.handleChange}
            error={formik.touched.weight && Boolean(formik.errors.weight)}
            helperText={formik.touched.weight && formik.errors.weight}
          />

          <InputComponent
            label='Height (ft)'
            id='height'
            name='height'
            required={true}
            width={128}
            value={formik.values.height}
            onChange={formik.handleChange}
            error={formik.touched.height && Boolean(formik.errors.height)}
            helperText={formik.touched.height && formik.errors.height}
          />
          <span style={{ marginRight: 40 }}>
            <Switch
              label='Active'
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </span>
        </StyledFlexRow>
      </ModalComponent>
    </MainLayout>
  );
};

export default PlayerDetailPage;
