import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableToolbar from './TableToolbar';
import {
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import TextButton from '../Buttons/TextButton';

/*
 * Pass in columns and data props to render the table
 * https://react-table.tanstack.com/docs/quick-start
 */

// Height is hard coded to fit the window, need to revisit this and optimize for Chromebook
// Height needs to be set in order for the stickyHeader scrolling to work
const TableContainerStyled = styled(TableContainer)`
	&&& {
		max-height: 500px;
	}
`;

const TableStyled = styled(Table)`
	&&& {
		table-layout: auto;
	}
`;

const TableCellStyled = styled(TableCell)`
	&&& {
		font-weight: bold;
	}
`;

// creating a cell
const Cell = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
}) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = useState(initialValue);

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return <div>{value}</div>;
};

Cell.propTypes = {
	cell: PropTypes.shape({
		value: PropTypes.any, // removed isRequired due to warning
	}),
	row: PropTypes.shape({
		index: PropTypes.number.isRequired,
	}),
	column: PropTypes.shape({
		id: PropTypes.string.isRequired,
	}),
	updateMyData: PropTypes.func.isRequired,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
	Cell: Cell,
};

const TableComponent = ({
	columns,
	data,
	setData,
	updateMyData,
	skipPageReset,
	handleEdit,
	handleDelete,
	handleView,
	viewLabel = 'view',
	editLabel = 'edit',
	deleteLabel = 'delete',
}) => {
	const {
		getTableProps,
		headerGroups,
		prepareRow,
		page,
		gotoPage,
		setPageSize,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize, selectedRowIds, globalFilter },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			autoResetPage: !skipPageReset,
			// updateMyData isn't part of the API, but
			// anything we put into these options will
			// automatically be available on the instance.
			// That way we can call this function from our
			// cell renderer!
			updateMyData,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.allColumns.push((columns) => [
				...columns,
				{
					id: 'actions',
					Header: () => 'Actions',
					Cell: ({ row }) => (
						<span>
							{handleView && (
								<span>
									<TextButton
										label={viewLabel}
										handleClick={() => handleView(row.original)}
									/>
									<span> | </span>
								</span>
							)}
							{handleEdit && (
								<span>
									<TextButton
										label={editLabel}
										handleClick={() => handleEdit(row.original)}
									/>
									{handleDelete &&
										<span> | </span>}
								</span>
							)}
							{handleDelete && (
								<TextButton
									label={deleteLabel}
									handleClick={() => handleDelete(row.original)}
								/>
							)}
						</span>
					),
				},
			]);
		}
	);

	const handleChangePage = (event, newPage) => {
		gotoPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageSize(Number(event.target.value));
	};

	const removeByIndexs = (array, indexs) =>
		array.filter((_, i) => !indexs.includes(i));

	const deleteUserHandler = (event) => {
		const newData = removeByIndexs(
			data,
			Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
		);
		setData(newData);
	};

	const addUserHandler = (user) => {
		const newData = data.concat([user]);
		setData(newData);
	};

	return (
		<Paper elevation={0}>
			<TableToolbar
				numSelected={Object.keys(selectedRowIds).length}
				deleteUserHandler={deleteUserHandler}
				addUserHandler={addUserHandler}
				preGlobalFilteredRows={preGlobalFilteredRows}
				setGlobalFilter={setGlobalFilter}
				globalFilter={globalFilter}
			/>
			<TableContainerStyled>
				<TableStyled stickyHeader {...getTableProps()}>
					<TableHead>
						{headerGroups.map((headerGroup) => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<TableCellStyled
										{...(column.id === 'selection'
											? column.getHeaderProps()
											: column.getHeaderProps(column.getSortByToggleProps()))}
									>
										{column.render('Header')}
										{column.id !== 'selection' ? (
											<TableSortLabel
												active={column.isSorted}
												// react-table has a unsorted state which is not treated here
												direction={column.isSortedDesc ? 'desc' : 'asc'}
											/>
										) : null}
									</TableCellStyled>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<TableRow
									{...row.getRowProps()}
									// handleEdit={handleEdit}
									// handleDelete={handleDelete}
								>
									{row.cells.map((cell) => {
										return (
											<TableCell {...cell.getCellProps()}>
												{cell.render('Cell')}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</TableStyled>
			</TableContainerStyled>
			<Paper elevation={0} variant='outlined' square>
				{data?.length > 0 && (
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[
									5,
									10,
									25,
									{ label: 'All', value: data.length },
								]}
								colSpan={3}
								count={data.length}
								rowsPerPage={pageSize}
								page={pageIndex}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				)}
			</Paper>
		</Paper>
	);
};

TableComponent.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	updateMyData: PropTypes.func.isRequired,
	setData: PropTypes.func.isRequired,
	skipPageReset: PropTypes.bool.isRequired,
};

export default TableComponent;
