import { useCallback, useEffect, useMemo, useRef } from 'react';
import logger from '../logger';
const log = logger('reactQueryToolkit', 'info'); // eslint-disable-line no-unused-vars

/**
 * Fetches all items from the provided graphQL/amplify api "service" function.
 * 
 * @param {Function} fn (nextToken) => Object; 
 *    async callback function that does the fetching and takes nextToken as a param 
 *    and returns it as part of the response object, if available.
 *    e.g.
 *    async (nextToken) =>
 *        await getGamesInDateRange(startDate, endDate, {
 *          nextToken,
 *        })
 * @returns all items fetched from the `fn` callback function
 */
export const fetchAllGraphQL = async (fn) => {
  let response = await fn();
  let { items } = response;

  while (response.nextToken) {
    response = await fn(response.nextToken);
    items = [...items, response.items];
  }

  return items;
};

/**
 * Useful with useInfiniteQuery to convert data.pages into a flat array of items.
 *
 * @param {Array} pages array of pages, each containing items to be flattened into 1 array
 * @returns a flattened array of items from each of the provided pages
 */
export const getItemsFromPages = (pages) =>
  pages?.reduce((items, page) => [...items, ...page.items], []) ?? [];

/**
 * Useful with useInfiniteQuery to convert data.pages into a flat array of items.
 * Memoized hook version of {@link getItemsFromPages}.
 * 
 * @param {Array} pages array of pages, each containing items to be flattened into 1 array
 * @returns a flattened array of items from each of the provided pages
 */
export const useItemsFromPages = (pages) =>
  useMemo(
    () => pages?.reduce((items, page) => [...items, ...page.items], []) ?? [],
    [pages]
  );

/**
 * Custom hook to add "fetch all" behavior to infinite queries.
 * This is useful when doing FE filtering and we need to query the BE
 * for more records until the user finds what they need.
 *
 * NOTE: Stops fetching when the calling component dismounts.
 *
 * @param {boolean} [initialValue=false] - whether the query should fetch all right away, on its first fetch
 * @returns {Object} obj
 * @returns {function} obj.fetchAllCallback - triggers "fetch all" for a given array of queries
 * @returns {function} obj.cancelFetchAll - sets the "fetch all" flag to false, stopping it after the current page is fetched
 * @returns {function} obj.onSuccessCallback - Usage: onSuccessCallback(query)({ pages, pageParams })
 */
export const useFetchAll = (initialValue = false) => {
  const _isFetchAllActive = useRef(initialValue);

  /* Stop fetching on dismount */
  useEffect(
    () => () => {
      _isFetchAllActive.current = false;
    },
    []
  );

  const fetchAllCallback = useCallback((queries) => {
    _isFetchAllActive.current = true;

    queries.forEach((query) => {
      if (query.hasNextPage) query.refetch();
    });
  }, []);

  const setFetchAll = useCallback((fetchAllFlag) => {
    _isFetchAllActive.current = fetchAllFlag;
  }, []);

  const cancelFetchAll = useCallback(() => {
    _isFetchAllActive.current = false;
  }, []);

  const onSuccessCallback = useCallback(
    (query) => ({ pages, pageParams }) => {
      if (_isFetchAllActive.current) {
        /**
         * If FE search is active, check if there are more results to fetch.
         * We need to fetch all pages so that FE filtering can work correctly.
         */
        if (pages[pages.length - 1].nextToken) {
          query.fetchNextPage();
        }
      }
    },
    []
  );

  return {
    fetchAllCallback,
    setFetchAll,
    cancelFetchAll,
    onSuccessCallback,
  };
};

/**
 * Custom hook wrapper around useFetchAll.
 * Automatically triggers "fetch all" when queryString is present and has length > 0.
 *
 * @param {string} queryString
 * @returns
 * 
 * @deprecated This doesn't quite work. There's a circular dependency between this hook and needing
 * the object from useInfiniteQuery, since we want to run fetchAllCallback on the query object when 
 * queryString changes.
 */
export const useAutoFetchAll = (queryString) => {
  const {
    fetchAllCallback,
    setFetchAll,
    cancelFetchAll,
    onSuccessCallback,
  } = useFetchAll();

  useEffect(() => {
    if (queryString?.length > 0) {
      setFetchAll(true);
    } else {
      setFetchAll(false);
    }

    return () => {
      cancelFetchAll();
    };
  }, [cancelFetchAll, queryString, setFetchAll]);

  return {
    fetchAllCallback,
    cancelFetchAll,
    onSuccessCallback,
  };
};

/**
 * Fetch when query is present, stop fetching when there's no query string.
 * 
 * @param {String} queryString 
 * @param {function} fetchAll 
 * @param {function} cancelFetchAll 
 */
export const useFetchOnQuery = (queryString, fetchAll, cancelFetchAll) => {
  useEffect(() => {
    if (queryString?.length > 0) {
      fetchAll();
    } else {
      cancelFetchAll();
    }
  }, [queryString]);
};

export const orReducer = (prop) => [(prev, curr) => prev || curr[prop], false];
export const andReducer = (prop) => [(prev, curr) => prev && curr[prop], true];

/**
 * @param {Array} queries an array of React Queries
 * @param {string} prop the boolean property to aggregate using OR (isLoading, isFetching, etc.)
 * @returns {boolean}
 */
export const aggregateBooleanPropUsingOr = (queries, prop) =>
  queries.reduce(...orReducer(prop));

/**
 * @param {Array} queries an array of React Queries
 * @param {string} prop the boolean property to aggregate using AND (isSuccess, etc.)
 * @returns {boolean}
 */
export const aggregateBooleanPropUsingAnd = (queries, prop) =>
  queries.reduce(...andReducer(prop));
