import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import StatsDataContainer from './StatsDataContainer';
import { textEllipsisStyle } from '../../../styles/typography';

const PlayerRecordItem = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
`;

const PlayerHeadshot = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #434343;
  border-radius: 15px;
  overflow: hidden;
`;

const PlayerJersey = styled.div`
  width: 70px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  font: normal normal 14px/46px 'Segoe UI Bold';
  color: #2E2E2E;
`;

const PlayerName = styled.div`
  width: calc(100% - 110px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
  font: normal normal 600 16px/30px 'Segoe UI';
  color: #707070;
`;

const SeparatorDivider = styled.div`
  width: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  width: 2px;
  height: 50%;
  border-left: 2px solid #707070;
`;

const dataItems = {
  minutes_played: "minutes_played",
  fg_att: "fg_att",
	fg_made: "fg_made",
	three_point_att: "three_point_att",
	three_point_made: "three_point_made",
	free_throw: "free_throw",
	free_throw_atts: "free_throw_atts",
  off_rebound: "off_rebound",
  def_rebound: "def_rebound",
  total_rebounds: "total_rebounds",
  assist: "assist",
  steal: "steal",
  blocks: "blocks",
  turnover: "turnover",
  personal_foul: "personal_foul",
  point_diff: "point_diff",
	total_points: "total_points",
};

const PlayerInfo = ({
  firstName,
  lastName,
  jerseyNumber,
  playerImage,
}) => {
  return (
    <PlayerRecordItem>
      <PlayerHeadshot>
        <img
          src={playerImage}
          width="100%"
          height="100%"
          style={{ objectFit: 'cover' }}
        />
      </PlayerHeadshot>
      <PlayerJersey>
        {`#${jerseyNumber}`}
      </PlayerJersey>
      <SeparatorDivider>
        <Divider />
      </SeparatorDivider>
      <PlayerName
        style={textEllipsisStyle}
      >
        {`${firstName.charAt(0).toUpperCase()}. ${lastName}`}
      </PlayerName>
    </PlayerRecordItem>
  );
};

const StatsRecordItem = ({
  keyText = '',
  gamePlayerStats, 
  recordItemIndex, 
}) => {
  const { firstName, lastName, jerseyNumber, playerImage } = gamePlayerStats.player;

  const getGamePlayerStatsData = (playerGameStats) => {
    return playerGameStats.coachDecision
    ? [{ data: playerGameStats.coachDecision }]
    : [
        // {data: playerGameStats[dataItems.minutes_played] || ''},
        {data: `${playerGameStats[dataItems.fg_made]}-${playerGameStats[dataItems.fg_made] + playerGameStats[dataItems.fg_att]}`},
        {data: `${playerGameStats[dataItems.three_point_made]}-${playerGameStats[dataItems.three_point_made] + playerGameStats[dataItems.three_point_att]}`},
        {data: `${playerGameStats[dataItems.free_throw]}-${playerGameStats[dataItems.free_throw] + playerGameStats[dataItems.free_throw_atts]}`},
        {data: playerGameStats[dataItems.off_rebound]},
        {data: playerGameStats[dataItems.def_rebound]},
        {data: playerGameStats[dataItems.total_rebounds]},
        {data: playerGameStats[dataItems.assist]},
        {data: playerGameStats[dataItems.steal]},
        {data: playerGameStats[dataItems.blocks]},
        {data: playerGameStats[dataItems.turnover]},
        {data: playerGameStats[dataItems.personal_foul]},
        // {data: playerGameStats[dataItems.point_diff] || 0},
        {data: playerGameStats[dataItems.total_points]},
      ]
  };
  
  return (
    <Row style={{ width: '100%', height: 'auto', margin: 0, backgroundColor: recordItemIndex % 2 == 0 ? '#F3F3F3' : '#FFFFFF' }}>
      <Col xs={12} className="p-0">
        <StatsDataContainer
          keyText={keyText}
          dataItems={getGamePlayerStatsData(gamePlayerStats)}
          dataItemStyle={{ font: 'normal normal 600 13px/14px "Segoe UI"', color: '#000000', }}
        >
          <PlayerInfo
            firstName={firstName}
            lastName={lastName}
            jerseyNumber={jerseyNumber}
            playerImage={playerImage}
          />
        </StatsDataContainer>
      </Col>
    </Row>
  );
};

export default StatsRecordItem;