import { Storage } from '@aws-amplify/storage';
import { Backdrop, Divider, makeStyles } from '@material-ui/core';
import { DataStore } from 'aws-amplify';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import socketClient from 'socket.io-client';
import styled, { css, ThemeProvider } from 'styled-components';
import {
  clockTimerStatus,
  deleteGameTimer,
  pauseClock,
  resumeClock,
  startClock,
} from '../../api/clockService';
import { createEvent } from '../../api/eventsService';
import {
  gameLineupKeys,
  useDerivedGameLineupDataQuery,
} from '../../api/gamesQueries';
import {
  getGameDataStore,
  getGameTeamStatsDatastore,
  updateGame,
  updateGameLineupPlayer,
} from '../../api/gamesService';
import { ALERT_VARIANTS } from '../../components/alerts/Alert';
import AddPlayersModal from '../../components/Modals/AddPlayers/AddPlayersModal';
import { ItemTypes } from '../../components/Modals/ItemTypes';
import { CLOCKMANAGER } from '../../data/roles';
import logger from '../../logger';
import { Game, GameTeamStats } from '../../models';
import { selectLightMode } from '../../redux/themeSlice';
import { detectMobileMedia } from '../../styles';
import { colors } from '../../styles/colorsStatsCollector';
import { CenteredSpinner } from '../../styles/spinner';
import { GAME_STATUS } from '../../utils/constantsUtils';
import { shortenFullName } from '../../utils/gameLineupUtils';
import {
  canGameTimerBeStartedJumpball,
  GameTimerStatus,
  getNextPeriodText,
  isGameNotStarted,
  isTimerRunning,
} from '../../utils/gameTimeUtil';
import { gameAdapter } from '../../utils/gameUtil';
import { setGameTimerStatus } from '../ScoreKeeper-new/controller/scoreKeeperEventSlice';
import PlayFeed from '../ScoreKeeper-new/PlayFeed';
import usePlayByPlay from '../ScoreKeeper-new/usePlayByPlay';
import ClockDiv from './ClockDiv';
import Header from './ClockHeader';
import ClockManagerEvents from './ClockManagerEvents';
import GamePeriods from './GamePeriods';
import JumpballTimeoutControl from './JumpballTimeoutControl';
import Scores from './Scores';
import Team from './Team';
import updateNumOfPeriods from './updateNumOfPeriods.action';

const log = logger('ClockManager', 'info');
const logGameTimer = logger('GameTimer', 'debug');

/**
 * Timeout duration (in ms)
 * Used for banner alerts and highlighted players (changing lineup and substitutions)
 */
const NOTIFICATION_TIMEOUT = 3000;

/**
 * Properties for calculating the header and container size
 * Scrolling should occur under a min h/w
 */
const HEADER_HEIGHT = '50px';
const MIN_HEIGHT_CONTAINER = '675px';
const MIN_HEIGHT_CONTENT = `calc(${MIN_HEIGHT_CONTAINER} - ${HEADER_HEIGHT})`;
const MIN_WIDTH_CONTAINER = '1195px';

const Container = styled.div`
  opacity: ${(props) => (props.modalOpen ? '0.1' : '1.0')};
  background-color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.GRAY[600] : colors.BLACK[900]};
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  width: 100vw;
  min-height: ${MIN_HEIGHT_CONTAINER};
  min-width: ${MIN_WIDTH_CONTAINER};
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 8fr 15fr;
  grid-template-rows: 100%;
  flex: 1;
  padding: 18px;
  column-gap: 18px;
  overflow: auto;
  height: calc(var(--app-height) - ${HEADER_HEIGHT});
  min-height: ${MIN_HEIGHT_CONTENT};
  min-width: ${MIN_WIDTH_CONTAINER};
`;

const StyledLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  background-color: ${({ theme }) =>
    theme.lightMode ? colors.GRAY[100] : colors.BLACK[400]};
  box-shadow: 0px 0px 20px ${colors.GRAY_TRANSPARENT[500]};
  border-radius: 10px;
  padding: 20px;
`;

const ClockPanel = styled(Panel)`
  /* When loading set the position to relative in order to 
     have centered spinner position relative */
  ${(props) =>
    !props.isLoaded &&
    css`
      position: relative;
    `}
  display: grid;
  row-gap: 2vh;
  min-height: 140px;
`;

const JumpballTimeoutPanel = styled(Panel)`
  /* When loading set the position to relative in order to 
     have centered spinner position relative */
  ${(props) =>
    !props.isLoaded &&
    css`
      position: relative;
    `}
  padding: 16px 25px;
  margin-top: 19px;
  min-height: 140px;
`;

const TeamsPanel = styled(Panel)`
  /* When loading set the position to relative in order to 
     have centered spinner position relative */
  ${(props) =>
    !props.isLoaded &&
    css`
      position: relative;
    `}
  padding: 17px;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-template-rows: auto 3fr 2fr;
  column-gap: 16px;
`;

const StyledDivider = styled(Divider)`
  &&& {
    grid-row-start: span 3;
    height: calc(100% + 34px);
    margin-top: -17px;
    margin-bottom: -17px;
    width: 2px;
    background-color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.GRAY[700] : colors.BLACK[900]};
  }
`;

const GameEndedText = styled.div`
  font: normal normal 600 20px/24px Open Sans;
  color: ${({ color }) => color};
  background: ${colors.BLACK[100]};
  padding: 30px 60px;
  border-radius: 5px;
  border: 1px solid ${colors.GRAY[1700]};
`;

/**
 * Used by custom hook usePlayByPlay.
 * Is a filter function on events that determines which events should be shown.
 *
 * @param {*} event an event object
 * @returns true if the event should be shown, false otherwise
 */
function shouldDisplayEvent(event) {
  if (event.eventCreatorRole === 'ClockManager') {
    /* Display ALL ClockManager events */
    return true;
  }

  /* Don't display other events */
  return false;
}

const toggleCourtBenchStatus = (status) =>
  status === ItemTypes.BENCH ? ItemTypes.COURT : ItemTypes.BENCH;

const RESULT = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  FINISHED: 'finished',
  WAITING: 'waiting',
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    cursor: 'not-allowed',
  },
  aboveBackdrop: {
    zIndex: theme.zIndex.drawer + 2,
  },
}));

const BasketballClockManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const localStorage = window.localStorage;

  /** Model for displaying and modifying team lineups */
  const [isTeamLineupModalOpen, setTeamLineupModalOpen] = useState(false);
  /** Bool for choosing which team to be displayed in the lineup modal  */
  const [isHomeTeamLineupSelected, setHomeTeamLineupSelected] = useState(false);
  /** Modal for finish and end game options */
  const [endGameModalOpen, setEndGameModalOpen] = useState(false);

  /** State of the jumpball/timeout routine, used to disable other components during use */
  const [
    isRecordingJumpballOrTimeout,
    setIsRecordingJumpballOrTimeout,
  ] = useState(false);

  /** Boolean set when closk timer is running/paused */
  const [isClockRunning, setIsClockRunning] = useState(false);

  /** Gametimer from the faststats time server */
  const [gameTimer, setGameTimer] = useState();

  const gameTimerMinute = gameTimer?.minutes;
  const gameTimerSecond = gameTimer?.seconds;
  const gameTimerQuarter = gameTimer?.quarter;
  const gameTimerStatus = gameTimer?.timerStatus;

  /** overtime of the game if available */
  const [overtime, setOvertime] = useState(false);

  /** game states for possession, points and fouls etc */
  const [gameTeamStats, setGameTeamStats] = useState();

  /** game data includes team names, ids etc */
  const [gameData, setGameData] = useState();

  /** TeamId-to-logo map to be used by components */
  const [teamLogoMap, setTeamLogoMap] = useState({});

  /* Team Images */
  const [homeTeamImage, sethomeTeamImage] = useState();
  const [awayTeamImage, setawayTeamImage] = useState();

  /** player one for substitute event  */
  const [selectedPlayerOne, setSelectedPlayerOne] = useState(null);

  /** player two for substitute event */
  const [selectedPlayerTwo, setSelectedPlayerTwo] = useState(null);

  /** substitution result for successful and not successful substitution */
  const [substitutionResult, setSubstitutionResult] = useState(false);

  /** enable overtime plus button */
  const [enableOverTimeStart, setEnableOverTimeStart] = useState(false);

  /** overtime number for local overtimer */
  const [localOvertimeNumber, setLocalOvertimeNumber] = useState(null);

  /** notification text for substitution */
  const [notificationText, setNotificationText] = useState(null);

  /** offline and online stats collector and clock manager */
  const [users, setUsers] = useState([]);

  /* Game Stats */
  const currentPossessionTeamId = gameTeamStats?.curr_possession || null;
  const homeTeamPoints = gameTeamStats?.home_total_points || 0;
  const awayTeamPoints = gameTeamStats?.away_total_points || 0;
  const homeTechFoul = gameTeamStats?.home_tech_foul || 0;
  const homePersonalFoul = gameTeamStats?.home_personal_foul || 0;
  const awayTechFoul = gameTeamStats?.away_tech_foul || 0;
  const awayPersonalFoul = gameTeamStats?.away_personal_foul || 0;
  const homeTeamTimeouts = gameTeamStats?.home_timeouts;
  const awayTeamTimeouts = gameTeamStats?.away_timeouts;

  /* Game Data */
  const homeTeamName = gameData?.homeTeam?.name;
  const awayTeamName = gameData?.awayTeam?.name;
  const homeTeamId = gameData?.homeTeam?.id;
  const awayTeamId = gameData?.awayTeam?.id;

  /* League Data */
  const leagueNumPeriods = gameData?.league?.numPeriods;
  const leagueTimePerPeriodInMins = gameData?.league?.timePerPeriodInMins;
  const leagueOvertimeDurationInMins = gameData?.league?.overtimeDurationInMins;
  const leagueNumPlayersOnBench = gameData?.league?.numPlayersOnBench;
  const leagueNumPlayersOnCourt = gameData?.league?.numPlayersOnCourt;
  const leagueNumTimeouts = gameData?.league?.numTimeOuts;
  const leagueTeamSize = gameData?.league?.teamSize;

  /* User attributes */
  const parsedUser = useSelector(
    (state) => state?.user?.value && JSON.parse(state?.user?.value)
  );
  const firstName = parsedUser?.attributes?.name;
  const lastName = parsedUser?.attributes?.family_name;
  const userEmail = parsedUser?.attributes?.email;
  const userSub = parsedUser?.attributes?.sub;

  const hasGameEnded = useMemo(
    () =>
      [GAME_STATUS.ENDED, GAME_STATUS.CANCELED].includes(gameData?.gameStatus),
    [gameData]
  );

  const gamePossiblyEnded = useMemo(
    () =>
      hasGameEnded ||
      (gameTimerStatus === GameTimerStatus.QUARTER_ENDED &&
        gameTimerQuarter === leagueNumPeriods &&
        !localOvertimeNumber),
    [
      hasGameEnded,
      localOvertimeNumber,
      leagueNumPeriods,
      gameTimerQuarter,
      gameTimerStatus,
    ]
  );

  /** Keep track of players that have been recently moved within the lineup, for visual cues */
  const [playerHighlights, setPlayerHighlights] = useState({});
  const highlightPlayer = (playerId, color) =>
    setPlayerHighlights((old) => ({
      ...old,
      [playerId]: { isHighlighted: true, highlightColor: color },
    }));
  const markPlayerRecentlyMoved = (playerId) =>
    highlightPlayer(playerId, ALERT_VARIANTS.BLUE);

  // eslint-disable-next-line no-unused-vars
  const unhighlightPlayer = (playerId) =>
    setPlayerHighlights((old) => ({
      ...old,
      [playerId]: { isHighlighted: false },
    }));
  const isPlayerHighlighted = (playerId) =>
    !!playerHighlights[playerId]?.isHighlighted;
  const getPlayerHighlightColor = (playerId) =>
    playerHighlights[playerId]?.highlightColor;
  const resetPlayerHighlights = () => setPlayerHighlights({});
  const highlightTimeoutId = useRef();

  const resetHighlightsAfterDelay = useCallback(() => {
    highlightTimeoutId.current = setTimeout(
      () => resetPlayerHighlights(),
      NOTIFICATION_TIMEOUT
    );
  }, []);

  useEffect(() => {
    return (
      highlightTimeoutId.current && clearTimeout(highlightTimeoutId.current)
    );
  }, []);

  const currentGame = localStorage.getItem('currentGame');
  const eventCreatorRole = 'ClockManager';
  const gameId = useMemo(() => JSON.parse(currentGame)?.id, [currentGame]);
  Storage.configure({ level: 'public' });

  // DEBUG CODE: This use Effect is only for testing purpose, set the game state to live on GameDataUpdate
  // useEffect(() => {
  //   updateGameStatus(GAME_STATUS.LIVE);
  // }, [gameData]);

  /**
   * Subscribe to Game and GameTeamStats updates and get initial data
   */
  useEffect(() => {
    log.debug('Subscribing to Game Data and Stats changes');
    log.info('gameId', gameId);

    (async function () {
      setGameData(gameAdapter(await getGameDataStore(gameId)));
      setGameTeamStats(await getGameTeamStatsDatastore(gameId));
    })();

    const subscriptionGame = DataStore.observe(Game, gameId).subscribe(
      (msg) => {
        log.debug('Game Data Updated', msg);
        setGameData(gameAdapter(msg.element));
      }
    );

    const subscriptionStats = DataStore.observe(
      GameTeamStats,
      gameId
    ).subscribe((msg) => {
      log.debug('Game Team Stats Updated', msg);
      setGameTeamStats(msg.element);
    });

    return () => {
      subscriptionGame.unsubscribe();
      subscriptionStats.unsubscribe();
    };
  }, [gameId]);

  /** Event List for Play-by-Play (Unique events exclude children events with a relatedEventId prop)*/
  const { playMap, playsLoaded } = usePlayByPlay(gameId, shouldDisplayEvent);
  const playMapAsArray = useMemo(
    () => Array.from(playMap?.values()).reverse(),
    [playMap]
  );

  /** to check the event is overtime or not */
  const isOvertimeEvent = useMemo(
    () =>
      gameTimerStatus?.includes('Overtime') ||
      (gameTimerStatus?.includes(GameTimerStatus.QUARTER_ENDED) &&
        gameTimerStatus?.quarter === leagueNumPeriods),
    [gameTimerStatus, leagueNumPeriods]
  );

  function canStartOvertime(currentTimer) {
    const finalPeriodEnded =
      currentTimer?.timerStatus === GameTimerStatus.QUARTER_ENDED &&
      currentTimer?.quarter === leagueNumPeriods;
    const overtimePeriodEnded =
      currentTimer?.timerStatus === GameTimerStatus.OVERTIME_ENDED;
    return finalPeriodEnded || overtimePeriodEnded;
  }

  useEffect(() => {
    if (!gameData) return;
    log.debug('Game Data', gameData);

    (async () => {
      queryClient.invalidateQueries(gameLineupKeys.all);
    })();

    return () => {
      /** clean local storage when unmounting */
      localStorage.removeItem('gameOvertimeNumber');
      localStorage.removeItem('minutes');
      localStorage.removeItem('seconds');
      localStorage.removeItem('quarter');
    };
  }, [gameData, localStorage, queryClient]);

  const { data: lineupData } = useDerivedGameLineupDataQuery(
    gameData?.homeTeamGameLineupId,
    gameData?.awayTeamGameLineupId,
    gameData?.homeTeamId,
    gameData?.awayTeamId
  );

  const {
    homeTeamLineup,
    awayTeamLineup,
    homeTeamRosterPlayers,
    awayTeamRosterPlayers,
    homeTeamLineupPlayers = [],
    homeTeamOnCourtPlayers = [],
    homeTeamOnBenchPlayers = [],
    awayTeamLineupPlayers = [],
    awayTeamOnCourtPlayers = [],
    awayTeamOnBenchPlayers = [],
  } = lineupData;

  useEffect(() => {
    log.debug('lineupData', lineupData);
  }, [lineupData]);

  const substitutionCalls = useCallback(
    async (player, isPlayerTwo) => {
      // selectedPlayerOne && (await new Promise((r) => setTimeout(r, 1000)));
      let madeRequest = false;
      if (
        isPlayerTwo &&
        selectedPlayerOne &&
        player &&
        selectedPlayerOne.playerOnCourtBenchStatus !==
          player.playerOnCourtBenchStatus
      ) {
        await Promise.all([
          updateGameLineupPlayer(
            selectedPlayerOne.id,
            toggleCourtBenchStatus(selectedPlayerOne.playerOnCourtBenchStatus),
            selectedPlayerOne._version
          ),
          updateGameLineupPlayer(
            player.id,
            toggleCourtBenchStatus(player.playerOnCourtBenchStatus),
            player._version
          ),
        ]);
        madeRequest = true;
        queryClient.invalidateQueries(gameLineupKeys.all);
      }
      return madeRequest;
    },
    [queryClient, selectedPlayerOne]
  );

  /** substitution event */
  const substitutionHandler = useCallback(
    async (player) => {
      if (!player) return;

      const isPlayerTwo =
        selectedPlayerOne &&
        selectedPlayerOne.id !== player.id &&
        selectedPlayerOne.playerOnCourtBenchStatus !==
          player.playerOnCourtBenchStatus;
      const team =
        selectedPlayerOne?.gameLineupId === gameData?.homeTeamGameLineupId
          ? gameData?.homeTeam
          : gameData?.awayTeam;

      if (!selectedPlayerOne) {
        setSelectedPlayerOne(player);
        setSelectedPlayerTwo(null);
      } else if (selectedPlayerOne.id === player.id) {
        /* Player one has been deselected */
        setSelectedPlayerOne(null);
        setSelectedPlayerTwo(null);
      } else if (isPlayerTwo) {
        setSelectedPlayerTwo(player);
      }

      if (isPlayerTwo) {
        const currentOverTime = isOvertimeEvent
          ? gameTimer?.overtimeTracker ?? 1
          : undefined;

        let result = (await substitutionCalls(player, isPlayerTwo))
          ? RESULT.SUCCESS
          : RESULT.FAILURE;

        /* First event needs to be bench -> court. Swap players if necessary. */
        let player1, player2;
        if (selectedPlayerOne.playerOnCourtBenchStatus === ItemTypes.BENCH) {
          player1 = selectedPlayerOne;
          player2 = player;
        } else {
          player1 = player;
          player2 = selectedPlayerOne;
        }

        if (result === RESULT.SUCCESS) {
          log.debug(`Creating Substitution Events for game ${gameData?.id}`);

          const firstEvent = await createEvent({
            eventType: 'SUBSTITUTION',
            minutes: gameTimerMinute,
            seconds: gameTimerSecond,
            quarter: gameTimerQuarter,
            team: team,
            game: gameData,
            playerId: player1?.playerId,
            gameLineupPlayer: player1,
            gameLineupPlayerId: player1?.id,
            playType: toggleCourtBenchStatus(player1?.playerOnCourtBenchStatus),
            gameOvertimeNumber: currentOverTime,
            statcollFirstName: firstName || 'First Name',
            statcollLastName: lastName || 'Last Name',
            statcollEmail: userEmail || null,
            statcollSub: userSub || null,
            eventCreatorRole: eventCreatorRole,
            posessionId: currentPossessionTeamId,
          });

          log.info('Substitution Event #1', firstEvent);

          const substitionEvent = await createEvent({
            eventType: 'SUBSTITUTION',
            minutes: gameTimerMinute,
            seconds: gameTimerSecond,
            quarter: gameTimerQuarter,
            team,
            game: gameData,
            playerId: player2?.playerId,
            gameLineupPlayer: player2,
            gameLineupPlayerId: player2?.id,
            relatedEventId: firstEvent?.id,
            playType: toggleCourtBenchStatus(player2?.playerOnCourtBenchStatus),
            gameOvertimeNumber: currentOverTime,
            statcollFirstName: firstName || 'First Name',
            statcollLastName: lastName || 'Last Name',
            statcollSub: userSub || null,
            statcollEmail: userEmail || null,
            eventCreatorRole: eventCreatorRole,
            posessionId: currentPossessionTeamId,
          });

          log.info('Substitution Event #2', substitionEvent);

          if (!firstEvent || !substitionEvent) result = RESULT.FAILURE;
        }

        setSubstitutionResult(result);

        if (result === RESULT.SUCCESS) {
          setNotificationText({
            text: `${shortenFullName(
              player1?.player.firstName,
              player1?.player.lastName
            )} successfully substituted for ${shortenFullName(
              player2?.player?.firstName,
              player2?.player?.lastName
            )}.`,
            variant: ALERT_VARIANTS.GREEN,
          });
          highlightPlayer(selectedPlayerOne?.playerId, ALERT_VARIANTS.GREEN);
          highlightPlayer(player?.playerId, ALERT_VARIANTS.GREEN);
        } else {
          setNotificationText({
            text: `Substitution has failed.`,
            variant: ALERT_VARIANTS.RED,
          });
          highlightPlayer(selectedPlayerOne?.playerId, ALERT_VARIANTS.RED);
          highlightPlayer(player?.playerId, ALERT_VARIANTS.RED);
        }

        resetHighlightsAfterDelay();
      }

      setSubstitutionResult(
        isPlayerTwo
          ? RESULT.FINISHED
          : selectedPlayerOne && selectedPlayerOne.id === player.id
          ? RESULT.FINISHED
          : RESULT.WAITING
      );
    },
    [
      firstName,
      gameData,
      isOvertimeEvent,
      lastName,
      gameTimerMinute,
      currentPossessionTeamId,
      gameTimerQuarter,
      resetHighlightsAfterDelay,
      gameTimerSecond,
      selectedPlayerOne,
      substitutionCalls,
      gameTimer,
      userEmail,
      userSub,
    ]
  );

  useEffect(() => {
    if (substitutionResult === RESULT.FINISHED) {
      setSelectedPlayerOne(null);
      setSelectedPlayerTwo(null);
    }
  }, [substitutionResult]);

  async function createGameStartedEvent(team) {
    const event = await createEvent({
      gameOvertimeNumber: overtime || undefined,
      minutes: gameTimerMinute || leagueTimePerPeriodInMins,
      seconds: gameTimerSecond || 0,
      quarter: gameTimerQuarter && gameTimerQuarter > 0 ? gameTimerQuarter : 1,
      eventType: ClockManagerEvents.GAME_STARTED,
      team: team,
      game: gameData,
      statcollFirstName: firstName,
      statcollLastName: lastName,
      statcollEmail: userEmail || null,
      statcollSub: userSub || null,
      eventCreatorRole: eventCreatorRole,
    });
    log.debug('Created Game Started Event', event);
  }

  async function createOvertimeStartedEvent(team) {
    const event = await createEvent({
      gameOvertimeNumber: overtime || undefined,
      minutes: gameTimerMinute,
      seconds: gameTimerSecond,
      quarter: gameTimerQuarter && gameTimerQuarter > 0 ? gameTimerQuarter : 1,
      eventType: `${getNextPeriodText(gameTimer, leagueNumPeriods)} STARTED`,
      team: team,
      game: gameData,
      statcollFirstName: firstName,
      statcollLastName: lastName,
      statcollEmail: userEmail || null,
      statcollSub: userSub || null,
      eventCreatorRole: eventCreatorRole,
    });
    log.debug('Created Overtime Started Event', event);
  }

  async function createJumpballEvent(team) {
    const event = await createEvent({
      gameOvertimeNumber: overtime || undefined,
      eventType: ClockManagerEvents.JUMPBALL,
      undefined,
      minutes: gameTimerMinute || 0,
      seconds: gameTimerSecond || 0,
      quarter: gameTimerQuarter && gameTimerQuarter > 0 ? gameTimerQuarter : 1,
      team: team,
      game: gameData,
      statcollFirstName: firstName,
      statcollLastName: lastName,
      statcollEmail: userEmail || null,
      statcollSub: userSub || null,
      eventCreatorRole: eventCreatorRole,
    });
    log.debug('Created Jumpball Event', event);
  }

  /**
   * Handle a jumpball event, which can start the gmae,
   * start an overtime period, or just change posession
   * @param {*} teamId if the the team who won the jumpball
   */
  const onJumpball = async (teamId) => {
    /** Get the full team object for the given id*/
    var selectedTeam;
    if (teamId === gameData?.homeTeam?.id) {
      selectedTeam = gameData?.homeTeam;
    } else {
      selectedTeam = gameData?.awayTeam;
    }

    /**
     * Depending on the game/clock state, the Jumpball could trigger the
     * start of the game or a new overtime period.
     */
    if (isGameNotStarted(gameTimer)) {
      /* If game hasn't started then jumpball triggers the start of the game.
      create a GameStarted and Jumpball event, then call the api to start the clock */
      createGameStartedEvent(selectedTeam);
      createJumpballEvent(selectedTeam);
      log.debug('Starting Game upon Jumpball');
      await startClock(gameId);
    } else if (canGameTimerBeStartedJumpball(gameTimer, leagueNumPeriods)) {
      /* If the game is started but the game timer is in a state where an overtime
      period needs to be started, create overtime started and jumpball events, 
      then call the api to start the clock */
      createOvertimeStartedEvent(selectedTeam);
      createJumpballEvent(selectedTeam);
      log.debug('Starting Overtime upon Jumpball');
      await startClock(gameId, true);
    } else {
      /* If the game is started and we are not starting an overtime period, 
      then just create a jumpball event and resume the clock */
      createJumpballEvent(selectedTeam);
      log.debug('Resuming Clock upon Jumpball');
      await resumeClock(gameId, isOvertimeEvent);
    }

    updateGameStatus(GAME_STATUS.LIVE);
  };

  /**
   * Handle a timeout selection for a team
   * @param {*} teamId if the the team who won the jumpball
   */
  const onTimeout = async (teamId) => {
    const eventType = ClockManagerEvents.TIMEOUT;
    var selectedTeam, timeoutNumber;
    if (teamId === gameData?.homeTeam?.id) {
      selectedTeam = gameData?.homeTeam;
      timeoutNumber = leagueNumTimeouts - homeTeamTimeouts + 1;
    } else {
      selectedTeam = gameData?.awayTeam;
      timeoutNumber = leagueNumTimeouts - awayTeamTimeouts + 1;
    }

    const playType = `${timeoutNumber}/${leagueNumTimeouts}`;
    await pauseClock(gameId, isOvertimeEvent);

    /** If game has started, then create either Jumpball or Timeout event */
    const event = await createEvent({
      gameOvertimeNumber: overtime || undefined,
      eventType: ClockManagerEvents.TIMEOUT,
      playType,
      minutes: gameTimerMinute || 0,
      seconds: gameTimerSecond || 0,
      quarter: gameTimerQuarter && gameTimerQuarter > 0 ? gameTimerQuarter : 1,
      team: selectedTeam,
      game: gameData,
      statcollFirstName: firstName,
      statcollLastName: lastName,
      statcollEmail: userEmail,
      statcollSub: userSub,
      eventCreatorRole: eventCreatorRole,
    });

    log.debug(`Creating ${eventType} event`, event);
  };

  const handleOvertimeClick = () => {
    setLocalOvertimeNumber(
      gameTimer?.overtimeTracker ? gameTimer?.overtimeTracker + 1 : 1
    );
    setEnableOverTimeStart(true);
  };

  const handleModalClick = (isHomeTeam) => {
    setHomeTeamLineupSelected(isHomeTeam);
    setTeamLineupModalOpen(true);
  };

  const handleCloseModal = () => {
    setTeamLineupModalOpen(false);
    resetHighlightsAfterDelay();
  };

  const handleCloseEndGameModal = () => {
    setEndGameModalOpen(false);
  };

  const handleOpenEndGameModal = () => {
    pauseClockIfRunning();
    setEndGameModalOpen(true);
  };

  useEffect(() => {
    setIsClockRunning(isTimerRunning(gameTimer));
    setOvertime(
      gameTimer?.quarter === leagueNumPeriods ||
        gameTimer?.timerStatus === GameTimerStatus.OVERTIME_RUNNING
    );
  }, [gameTimer, leagueNumPeriods]);

  const totalHomeFoul = homeTechFoul + homePersonalFoul;
  const totalAwayFoul = awayTechFoul + awayPersonalFoul;

  localStorage.setItem('firstName', firstName);
  localStorage.setItem('lastName', lastName);

  const parameters = `?sub=${userSub}&fn=${firstName}&ln=${lastName}&role=${CLOCKMANAGER}`;

  /** use effect clock timer and socket */
  useEffect(() => {
    dispatch(
      updateNumOfPeriods({
        numPeriods: leagueNumPeriods || '4',
        currentPeriod: gameTimer,
      })
    );
  }, [dispatch, leagueNumPeriods, gameTimer]);

  /** When the game/clock timer changes update the local storage values and the game timer states */
  function onGameTimerUpdate(data) {
    logGameTimer.debug('GameTimer Socket - Timer Update', data);

    localStorage.setItem('minutes', data?.minutes);
    localStorage.setItem('seconds', data?.seconds);
    localStorage.setItem('quarter', data?.quarter);
    localStorage.setItem(
      'gameOvertimeNumber',
      data?.overtimeTracker ? data?.overtimeTracker : null
    );
    setGameTimer(data);
    dispatch(setGameTimerStatus(data));

    /** If the state change indicates overtime can be started, disable the
     * overtime start button until it is enabled by the (+) new overtime period button */
    if (canStartOvertime(data)) setEnableOverTimeStart(false);
  }

  useEffect(() => {
    /* Setup timeserver socket callbacks */
    const SERVER = `https://dev-clocktimer.faststats.live${parameters}`;
    const socket = socketClient(SERVER);
    logGameTimer.info('GameTimer Socket - Setup', SERVER);

    /* Client side connect callback */
    socket.on('connect', () => {
      logGameTimer.info(`GameTimer Socket - Connected ${socket.id}`);

      /* Set the Game ID for the time server */
      socket.emit('room', gameId);
      logGameTimer.info(`GameTimer Socket - Emitted Room ${gameId}`);

      /* Asynchronously get the timer status for the current game */
      (async function () {
        onGameTimerUpdate(await clockTimerStatus(gameId));
      })();
    });

    /* Client side disconnect callback */
    socket.on('disconnect', () => {
      logGameTimer.info(`GameTimer Socket - Disconnect`);
    });

    /* Subscribe a timer update callback */
    socket.on('timer', (data) => {
      onGameTimerUpdate(data);
    });

    /* Subscribe an online user callback */
    socket.on('users', (userList) => {
      logGameTimer.debug(`GameTimer Socket - users`, userList);
      const filterUserList = userList.filter(
        (user) => user?.[1]?.room === gameId
      );
      setUsers(filterUserList);
    });

    /* Subscribe an offline user callback */
    socket.on('offline', (userList) => {
      logGameTimer.debug(`GameTimer Socket - offline`, userList);
      const filterUserList = userList.filter(
        (user) => user?.[1]?.room === gameId
      );
      setUsers(filterUserList);
    });

    return function offline() {
      logGameTimer.info(`GameTimer Socket - Exiting`);
      socket.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log.debug('Users', users);
  }, [users]);

  useEffect(() => {
    homeTeamId && getProfilePicture(homeTeamId, sethomeTeamImage);
    awayTeamId && getProfilePicture(awayTeamId, setawayTeamImage);
  }, [homeTeamId, awayTeamId]);

  useEffect(() => {
    setTeamLogoMap({
      [homeTeamId]: homeTeamImage,
      [awayTeamId]: awayTeamImage,
    });
  }, [homeTeamImage, awayTeamImage, homeTeamId, awayTeamId]);

  const getProfilePicture = (teamId, setImage) => {
    Storage.get(`team/${teamId}`)
      .then((url) => {
        var myRequest = new Request(url);
        fetch(myRequest).then(function (response) {
          if (response.status === 200) {
            setImage(url);
          }
        });
      })
      .catch((err) => log.error(err));
  };

  const isSubstitutionAllowed =
    [
      GameTimerStatus.QUARTER_ENDED,
      GameTimerStatus.QUARTER_PAUSED,
      GameTimerStatus.OVERTIME_PAUSED,
      GameTimerStatus.OVERTIME_ENDED,
    ].includes(gameTimer?.timerStatus) ||
    gameTimer?.msg === GameTimerStatus.CREATED_NOT_STARTED;

  const isSubstitutionAllowedHomeTeamOnCourt =
    !gamePossiblyEnded &&
    isSubstitutionAllowed &&
    homeTeamOnBenchPlayers.length > 0 &&
    ((selectedPlayerOne &&
      selectedPlayerOne.teamId === homeTeamId &&
      selectedPlayerOne.playerOnCourtBenchStatus === ItemTypes.BENCH) ||
      !selectedPlayerOne);

  const isSubstitutionAllowedHomeTeamOnBench =
    !gamePossiblyEnded &&
    isSubstitutionAllowed &&
    homeTeamOnBenchPlayers.length > 0 &&
    ((selectedPlayerOne &&
      selectedPlayerOne.teamId === homeTeamId &&
      selectedPlayerOne.playerOnCourtBenchStatus === ItemTypes.COURT) ||
      !selectedPlayerOne);

  const isSubstitutionAllowedAwayTeamOnCourt =
    !gamePossiblyEnded &&
    isSubstitutionAllowed &&
    awayTeamOnBenchPlayers.length > 0 &&
    ((selectedPlayerOne &&
      selectedPlayerOne?.teamId === awayTeamId &&
      selectedPlayerOne.playerOnCourtBenchStatus === ItemTypes.BENCH) ||
      !selectedPlayerOne);

  const isSubstitutionAllowedAwayTeamOnBench =
    !gamePossiblyEnded &&
    isSubstitutionAllowed &&
    awayTeamOnBenchPlayers.length > 0 &&
    ((selectedPlayerOne &&
      selectedPlayerOne.teamId === awayTeamId &&
      selectedPlayerOne.playerOnCourtBenchStatus === ItemTypes.COURT) ||
      !selectedPlayerOne);

  /** create event method which is passed to child componenets */
  const callCreateEvent = useCallback(
    async ({
      eventType,
      team,
      player,
      quarter,
      overTime,
      eventMinutes,
      eventSeconds,
    }) => {
      const minutes = eventMinutes ?? gameTimerMinute ?? 0;
      const seconds = eventSeconds ?? gameTimerSecond ?? 0;
      const currentQuarter = quarter ?? gameTimerQuarter ?? 1;
      const currentOverTime = gameTimer?.overtimeTracker ?? 1;
      const isStartedEvent = eventType?.includes('STARTED');
      await createEvent({
        gameOvertimeNumber: overTime ? currentOverTime : undefined,
        eventType: eventType,
        team: team ? team : null,
        minutes: isStartedEvent ? leagueTimePerPeriodInMins : minutes,
        seconds: isStartedEvent ? '00' : seconds,
        quarter: currentQuarter,
        game: gameData,
        statcollFirstName: firstName ? firstName : 'First Name',
        statcollLastName: lastName ? lastName : 'Last Name',
        statcollEmail: userEmail ? userEmail : null,
        statcollSub: userSub ? userSub : null,
        eventCreatorRole: eventCreatorRole,
      });
    },
    [
      gameTimerMinute,
      gameTimerSecond,
      gameTimerQuarter,
      gameTimer,
      leagueTimePerPeriodInMins,
      gameData,
      firstName,
      lastName,
      userEmail,
      userSub,
    ]
  );

  const updateGameStatus = useCallback(
    async (gameStatus) => updateGame(gameData.id, { ...gameData, gameStatus }),
    [gameData]
  );

  const endGame = async (endGameEventType) => {
    let gameStatus;
    switch (endGameEventType) {
      case ClockManagerEvents.GAME_ENDED:
        gameStatus = GAME_STATUS.ENDED;
        break;
      case ClockManagerEvents.GAME_CANCELED:
        gameStatus = GAME_STATUS.CANCELED;
        break;
      default:
        log.warn(
          'Call to endGame with invalid reason provided',
          endGameEventType
        );
        return;
    }

    /** Create Play Feed event for Game Ended/Canceled */
    await createEvent({
      eventType: endGameEventType,
      minutes: gameTimerMinute,
      seconds: gameTimerSecond,
      quarter: gameTimerQuarter,
      game: gameData,
      gameOvertimeNumber: isOvertimeEvent
        ? gameTimer?.overtimeTracker ?? 1
        : undefined,
      statcollFirstName: firstName || 'First Name',
      statcollLastName: lastName || 'Last Name',
      statcollEmail: userEmail || null,
      statcollSub: userSub || null,
      eventCreatorRole: eventCreatorRole,
      posessionId: currentPossessionTeamId,
    });

    /** Updated Game object status to Ended/Canceled */
    const updateGameStatusResponse = await updateGameStatus(gameStatus);
    log.debug('Updating GameStatus', updateGameStatusResponse);

    /** Delete the GameTimer for the cancelled/ended game */
    const deleteGameTimerResponse = await deleteGameTimer(gameId);
    log.debug('Deleting GameTimer', deleteGameTimerResponse);
  };

  const { overtimeTracker } = gameTimer || {};
  const pauseClockIfRunning = useCallback(async () => {
    if (isClockRunning) {
      await pauseClock(gameId, isOvertimeEvent);
      await callCreateEvent({
        eventType: ClockManagerEvents.GAME_PAUSED,
        overTime: isOvertimeEvent,
        quarter: isOvertimeEvent ? overtimeTracker : null,
        eventMinutes: gameTimer?.minutes,
        eventSeconds: gameTimer?.seconds,
      });
    }
  }, [
    isClockRunning,
    gameId,
    isOvertimeEvent,
    callCreateEvent,
    overtimeTracker,
    gameTimer,
  ]);

  /** is game started based on clock timer  */
  const notStarted = gameTimer?.msg === GameTimerStatus.CREATED_NOT_STARTED;

  const lightMode = useSelector(selectLightMode);

  /** Team data panel is loading until the game, stats and team images are available */
  const isTeamDataLoaded = !(
    gameData === undefined ||
    lineupData === undefined ||
    homeTeamImage === undefined ||
    awayTeamImage === undefined
  );

  /** Clock panel is loading until the game and timer info is available */
  const isClockInfoLoaded = !(gameData === undefined || gameTimer === null);

  /** Jumpball/Timeout panel is loading until the game and stats data are available */
  const isJumpballTimeoutLoaded = !(gameData === undefined);

  /** Substitution is being made as indicated by a player selected */
  const isSubstitutionBeingMade = selectedPlayerOne !== null;

  /** The Clock control should be disabled when a jumpball/timeout or substitution is being recorded */
  const isClockDisabled =
    isSubstitutionBeingMade || isRecordingJumpballOrTimeout;

  /**
   * Only enabled the Game Finished button when period or overtime period is ended,
   * and scores are not tied
   */
  const isGameFinishedButtonEnabled =
    /* Quarter or Overtime Ended */
    [GameTimerStatus.QUARTER_ENDED, GameTimerStatus.OVERTIME_ENDED].includes(
      gameTimer?.timerStatus
    ) &&
    /* Overtime or final period/quarter */
    (gameTimer?.overtimeTracker ||
      (!gameTimer?.overtimeTracker &&
        gameTimer?.quarter >= leagueNumPeriods)) &&
    /* Game not tied */
    gameTeamStats?.home_total_points !== gameTeamStats?.away_total_points;

  return (
    <ThemeProvider theme={{ lightMode: lightMode }}>
      {/* When game has ended, disable interaction except for the header */}
      <Backdrop open={hasGameEnded} className={classes.backdrop}>
        {gameData?.gameStatus === GAME_STATUS.ENDED ? (
          <GameEndedText color={colors.BLUE[400]}>Game Ended</GameEndedText>
        ) : gameData?.gameStatus === GAME_STATUS.CANCELED ? (
          <GameEndedText color={colors.RED[400]}>Game Canceled</GameEndedText>
        ) : (
          <></>
        )}
      </Backdrop>
      <Container modalOpen={isTeamLineupModalOpen}>
        {isTeamLineupModalOpen && (
          <AddPlayersModal
            open={isTeamLineupModalOpen}
            handleClose={handleCloseModal}
            notStarted={notStarted}
            setModalOpen={setTeamLineupModalOpen}
            numPlayersOnBench={leagueNumPlayersOnBench}
            numPlayersOnCourt={leagueNumPlayersOnCourt}
            teamSize={leagueTeamSize}
            gameId={gameId}
            gameData={gameData}
            homeRosterPlayers={homeTeamRosterPlayers}
            homeLineupPlayers={homeTeamLineupPlayers}
            homeCourtPlayers={homeTeamOnCourtPlayers}
            homeBenchPlayers={homeTeamOnBenchPlayers}
            homeTeamLineupOnCourtOnBenchPlayers={[
              ...homeTeamLineupPlayers,
              ...homeTeamOnCourtPlayers,
              ...homeTeamOnBenchPlayers,
            ]}
            homeReadImage={homeTeamImage && homeTeamImage}
            homeTeamName={homeTeamName}
            awayRosterPlayers={awayTeamRosterPlayers}
            awayLineupPlayers={awayTeamLineupPlayers}
            awayCourtPlayers={awayTeamOnCourtPlayers}
            awayBenchPlayers={awayTeamOnBenchPlayers}
            awayTeamLineupOnCourtOnBenchPlayers={[
              ...awayTeamLineupPlayers,
              ...awayTeamOnCourtPlayers,
              ...awayTeamOnBenchPlayers,
            ]}
            awayReadImage={awayTeamImage && awayTeamImage}
            awayTeamName={awayTeamName}
            isHomeModalSelected={isHomeTeamLineupSelected}
            setHomeModalSelected={setHomeTeamLineupSelected}
            homeTeamLineup={homeTeamLineup}
            awayTeamLineup={awayTeamLineup}
            homeTeamGameLineupId={gameData?.homeTeamGameLineupId}
            awayTeamGameLineupId={gameData?.awayTeamGameLineupId}
            markPlayerRecentlyMoved={markPlayerRecentlyMoved}
            isPlayerHighlighted={isPlayerHighlighted}
            getPlayerHighlightColor={getPlayerHighlightColor}
          />
        )}
        <Header
          users={users}
          handleOpenEndGameModal={handleOpenEndGameModal}
          handleCloseEndGameModal={handleCloseEndGameModal}
          endGameModalOpen={endGameModalOpen}
          endGame={endGame}
          hasGameEnded={hasGameEnded}
          notificationText={notificationText}
          notificationTimeout={NOTIFICATION_TIMEOUT}
          disableGameFinishedButton={!isGameFinishedButtonEnabled}
          className={`mx-n3 ${classes.aboveBackdrop}`}
          style={{
            height: HEADER_HEIGHT,
            minHeight: HEADER_HEIGHT,
            borderBottom: 'none',
            padding: '0 17px',
            backgroundColor: colors.BLACK[100],
          }}
        />
        <MainContent isMobile={detectMobileMedia()}>
          {/* Clock Control, Jumpball/Timeout and Play-by-play container */}
          <StyledLeftDiv className="item">
            {/* Clock Control */}
            <ClockPanel $isLoaded={isClockInfoLoaded}>
              {/* Loading Spinner */}
              {!isClockInfoLoaded && (
                <CenteredSpinner
                  animation="border"
                  variant="light"
                ></CenteredSpinner>
              )}
              {/* Clock Control, Timer and Quarteds/Periods */}
              {isClockInfoLoaded && (
                <>
                  <ClockDiv
                    numPeriods={leagueNumPeriods}
                    gameId={gameId}
                    callCreateEvent={callCreateEvent}
                    gameTimer={gameTimer}
                    disabled={isClockDisabled}
                    isClockRunning={isClockRunning}
                    updateGameStatus={updateGameStatus}
                  />
                  <GamePeriods
                    gameTimer={gameTimer}
                    numPeriods={leagueNumPeriods}
                    localOvertimeNumber={localOvertimeNumber}
                    handleOvertimeClick={handleOvertimeClick}
                    isTied={homeTeamPoints === awayTeamPoints}
                    overTimeStartEnabled={enableOverTimeStart}
                  />
                </>
              )}
            </ClockPanel>
            {/* Jumpball and Timeout Controls Panel */}
            <JumpballTimeoutPanel $isLoaded={isJumpballTimeoutLoaded}>
              {/* Loading Spinner */}
              {!isJumpballTimeoutLoaded && (
                <CenteredSpinner
                  animation="border"
                  variant="light"
                ></CenteredSpinner>
              )}
              {/* Jumpball and Timeout Controls */}
              {isJumpballTimeoutLoaded && (
                <JumpballTimeoutControl
                  benchCourtPlayersSelected={
                    leagueNumPlayersOnCourt ===
                      homeTeamOnCourtPlayers?.length &&
                    leagueNumPlayersOnCourt === awayTeamOnCourtPlayers?.length
                  }
                  enableOverTimeStart={enableOverTimeStart}
                  gameTeamStats={gameTeamStats}
                  numTimeouts={leagueNumTimeouts}
                  gameData={gameData}
                  gameTimer={gameTimer}
                  isClockRunning={isClockRunning}
                  homeTeamId={homeTeamId}
                  awayTeamId={awayTeamId}
                  homeTeam={homeTeamName}
                  awayTeam={awayTeamName}
                  setIsRecordingJumpballOrTimeout={
                    setIsRecordingJumpballOrTimeout
                  }
                  numPeriods={leagueNumPeriods}
                  onJumpball={onJumpball}
                  onTimeout={onTimeout}
                  disabled={isSubstitutionBeingMade}
                />
              )}
            </JumpballTimeoutPanel>

            {/* Play Event Feed */}
            <PlayFeed
              plays={playMapAsArray}
              gameData={gameData}
              lineupData={lineupData}
              teamLogoMap={teamLogoMap}
              numPeriods={leagueNumPeriods}
              showTabs={true}
              isLoading={!playsLoaded}
              style={{
                marginTop: 19,
                flex: '1',
              }}
            />
          </StyledLeftDiv>

          {/* Team Lineup, Scores and Possesion Panel */}
          <TeamsPanel $isLoaded={isTeamDataLoaded}>
            {/* Loading Spinner */}
            {!isTeamDataLoaded && (
              <CenteredSpinner
                animation="border"
                variant="light"
              ></CenteredSpinner>
            )}
            {isTeamDataLoaded && (
              <>
                {/* Home Team Scores */}
                <Scores
                  score={homeTeamPoints}
                  numFouls={totalHomeFoul}
                  teamImage={homeTeamImage}
                  hasPossession={
                    currentPossessionTeamId &&
                    currentPossessionTeamId === homeTeamId
                  }
                  isHomeTeam={true}
                />

                {/* Vertical section devider for teams */}
                <StyledDivider orientation="vertical" />

                {/* Away Team Scores */}
                <Scores
                  score={awayTeamPoints}
                  numFouls={totalAwayFoul}
                  teamImage={awayTeamImage}
                  hasPossession={
                    currentPossessionTeamId &&
                    currentPossessionTeamId === awayTeamId
                  }
                  reverseColumns={true}
                  isHomeTeam={false}
                />

                {/* Home Team Court */}
                <div>
                  <Team
                    gameTimer={gameTimer}
                    gameData={gameData}
                    notStarted={notStarted}
                    players={homeTeamOnCourtPlayers}
                    hasPlayers={
                      homeTeamOnCourtPlayers &&
                      homeTeamOnCourtPlayers.length === 0
                    }
                    onCourt={true}
                    isHomeTeam={true}
                    hasPossession={
                      currentPossessionTeamId &&
                      currentPossessionTeamId === homeTeamId
                    }
                    isSubstitutionAllowed={isSubstitutionAllowedHomeTeamOnCourt}
                    substitutionHandler={substitutionHandler}
                    selectedPlayerOne={selectedPlayerOne}
                    selectedPlayerTwo={selectedPlayerTwo}
                    handleModalClick={handleModalClick}
                    isPlayerHighlighted={isPlayerHighlighted}
                    getPlayerHighlightColor={getPlayerHighlightColor}
                    disable={isRecordingJumpballOrTimeout}
                  />
                </div>

                {/* Away Team Court */}
                <div>
                  <Team
                    gameTimer={gameTimer}
                    gameData={gameData}
                    notStarted={notStarted}
                    players={awayTeamOnCourtPlayers}
                    hasPlayers={awayTeamOnBenchPlayers}
                    onCourt={true}
                    hasPossession={
                      currentPossessionTeamId &&
                      currentPossessionTeamId === awayTeamId
                    }
                    isSubstitutionAllowed={isSubstitutionAllowedAwayTeamOnCourt}
                    substitutionHandler={substitutionHandler}
                    selectedPlayerOne={selectedPlayerOne}
                    selectedPlayerTwo={selectedPlayerTwo}
                    handleModalClick={handleModalClick}
                    isPlayerHighlighted={isPlayerHighlighted}
                    getPlayerHighlightColor={getPlayerHighlightColor}
                    disable={isRecordingJumpballOrTimeout}
                  />
                </div>

                {/* Home Team Bench */}
                <Team
                  players={homeTeamOnBenchPlayers}
                  notStarted={notStarted}
                  isHomeTeam={true}
                  hasPlayers={homeTeamOnCourtPlayers}
                  isSubstitutionAllowed={isSubstitutionAllowedHomeTeamOnBench}
                  substitutionHandler={substitutionHandler}
                  selectedPlayerOne={selectedPlayerOne}
                  selectedPlayerTwo={selectedPlayerTwo}
                  handleModalClick={handleModalClick}
                  isPlayerHighlighted={isPlayerHighlighted}
                  getPlayerHighlightColor={getPlayerHighlightColor}
                  disable={isRecordingJumpballOrTimeout}
                />

                {/* Away Team Bench */}
                <Team
                  players={awayTeamOnBenchPlayers}
                  notStarted={notStarted}
                  hasPlayers={awayTeamOnBenchPlayers}
                  isSubstitutionAllowed={isSubstitutionAllowedAwayTeamOnBench}
                  substitutionHandler={substitutionHandler}
                  selectedPlayerOne={selectedPlayerOne}
                  selectedPlayerTwo={selectedPlayerTwo}
                  handleModalClick={handleModalClick}
                  isPlayerHighlighted={isPlayerHighlighted}
                  getPlayerHighlightColor={getPlayerHighlightColor}
                  disable={isRecordingJumpballOrTimeout}
                />
              </>
            )}
          </TeamsPanel>
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default BasketballClockManager;
