import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FlagFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import useDoubleClick from 'use-double-click';
import { updateEventFlag } from '../../api/eventsService';
import { ReactComponent as BenchIcon } from '../../assets/svg/Bench.svg';
import { ReactComponent as CourtIcon } from '../../assets/svg/Court.svg';
import { ItemTypes } from '../../components/Modals/ItemTypes';
import logger from '../../logger';
import { MOBILE_MEDIA, StyledFlexRow as Row } from '../../styles';
import { colors } from '../../styles/colorsStatsCollector';
import { CenteredSpinner } from '../../styles/spinner';
import { getGamePeriod } from '../../utils/gameTimeUtil';
import useListScroll from '../../utils/useListScroll';
import Tabs from '../ClockManager/Tabs';
import { ActionTypes } from './ActionTypes.type.js';
import { EditTab } from './components/forms/EditTab';
import { Panel } from './components/Panel';
import { isGameEventEditable } from './controller/gameEvents';
import {
  selectPlayBeingEdited,
  setPlayBeingEdited,
} from './controller/scoreKeeperEventSlice';

// import TeamPlaceholder from '../../assets/Team.png';
const log = logger('PlayFeed', 'debug');

/**
 * Left and right margin around vertical Play-by-Play "timeline"
 */
const TIMELINE_MARGIN = 16;

const StyledBenchIcon = styled(BenchIcon)`
  margin-left: 10px;
  path {
    fill: ${({ theme }) =>
      theme.lightMode ? colors.BLACK[100] : colors.GRAY[900]};
  }
`;
const StyledCourtIcon = styled(CourtIcon)`
  margin-left: 10px;
  path {
    fill: ${({ theme }) =>
      theme.lightMode ? colors.BLACK[100] : colors.GRAY[900]};
  }
`;

/**
 * This component just adds color to the sub-play items based off of their action types.
 * This logic needed to be extracted out of the main component styling because the implementation
 * did not account for multiple children/sub-plays for a parent play. This still relies on the
 * .subplay and .action classes in the in the main bosy styling
 */
const SubPlayAction = styled.div`
  color: ${({ subPlay }) => subPlay?.textColor} !important;
  background-color: ${({ subPlay }) => subPlay?.color} !important;
  &::after {
    border-color: ${({ subPlay }) =>
      subPlay?.color ? subPlay?.color : ''} !important;
  }
`;

export const PlayFeed = ({
  plays,
  gameData,
  lineupData,
  teamLogoMap,
  numPeriods,
  showTabs = false,
  isLoading,
  style,
}) => {
  const dispatch = useDispatch();
  const playBeingEdited = useSelector(selectPlayBeingEdited);
  const scrollProps = useListScroll(plays); //TODO desktop bug: this gets messed up when switching light mode

  const editPlay = (play) => {
    log.debug('isGameEventEditable', isGameEventEditable(play.event));
    if (isGameEventEditable(play.event)) {
      dispatch(setPlayBeingEdited(JSON.stringify(play)));
    }
  };

  const exitEditMode = () => {
    dispatch(setPlayBeingEdited());
  };

  return (
    <Container style={style}>
      {isLoading && (
        <CenteredSpinner animation="border" variant="light"></CenteredSpinner>
      )}
      {!isLoading && showTabs && (
        <Tabs
          tabs={[
            {
              label: 'Play by Play',
              clickHandler: () => {},
              active: !playBeingEdited,
            },
            {
              label: 'Edits/Actions',
              clickHandler: () => {},
              active: !!playBeingEdited,
            },
          ]}
        />
      )}
      {!isLoading &&
        (showTabs && playBeingEdited ? (
          <EditTab
            play={playBeingEdited}
            exitEditMode={exitEditMode}
            gameData={gameData}
            lineupData={lineupData}
          />
        ) : (
          <PlayFeedItemsContainer
            className="list-items-scroll"
            {...scrollProps}
          >
            {plays?.map((p, idx) => (
              <PlayFeedItem
                play={p}
                teamLogoMap={teamLogoMap}
                numPeriods={numPeriods}
                key={idx}
                padding={15}
                editPlay={() => editPlay(p)}
                active={playBeingEdited?.id === p.id}
              />
            ))}
          </PlayFeedItemsContainer>
        ))}
    </Container>
  );
};

const IndicatorDot = styled.div`
  background: ${colors.BLUE[100]};
  width: 5px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;
  left: -9px;
`;

const PlayFeedItem = styled(
  ({ play, editPlay, teamLogoMap, numPeriods, padding = 15, ...props }) => {
    const {
      timestamp,
      gamePeriodNumber,
      gameOvertimeNumber,
      action,
      userInitials,
      teamId,
      courtLocation,
      isSubstitution,
      isEdited,
      event,
    } = play;

    const { playerName, playerNumber, playerOnCourtBenchStatus } = play;
    const { isFlaged: isFlagged } = event;

    const gamePeriod = getGamePeriod(
      numPeriods,
      gamePeriodNumber,
      gameOvertimeNumber
    );

    let logo;
    /** Set the Team Logo from the id of the team object */
    if (teamId && teamLogoMap.hasOwnProperty(teamId)) {
      logo = teamLogoMap[teamId];
    }

    const handlers = useSwipeable({
      onSwipedRight: (eventData) => {
        log.info('User Swiped right!', eventData);
        editPlay();
      },
      trackMouse: true,
    });

    const ref = useRef();
    const setRef = (el) => {
      handlers.ref(el);
      ref.current = el;
    };
    useDoubleClick({
      ref: ref,
      onDoubleClick: (e) => {
        log.info('double click', play);
        updateEventFlag({ ...event, isFlaged: !event.isFlaged });
      },
    });

    function areSubstitutionEventsOutOfOrder(isSubstitution, play) {
      return (
        isSubstitution &&
        play.playerOnCourtBenchStatus === ItemTypes.BENCH &&
        play.subPlay?.playerOnCourtBenchStatus === ItemTypes.COURT
      );
    }

    function shouldDisplayCourtIcon(play) {
      return (
        play.isSubstitution && play.playerOnCourtBenchStatus === ItemTypes.COURT
      );
    }

    function shouldDisplayBenchIcon(play) {
      return (
        play.isSubstitution && play.playerOnCourtBenchStatus === ItemTypes.BENCH
      );
    }

    const subPlays = [];

    if (play.subPlay) {
      var parentPlay = play;

      /* For Substitution, need to ensure that Court player is listed first, followed by Bench player */
      if (areSubstitutionEventsOutOfOrder(isSubstitution, play)) {
        /* Swap parent and child play */
        parentPlay = play.subPlay;
        parentPlay.subPlay = play;
        parentPlay.subPlay.subPlay = undefined;
      }

      /** Iterate down through subPlays as long as a parent has remaining children */
      while (parentPlay.subPlay) {
        const subPlay = parentPlay.subPlay;
        var subPlayLogo;

        /* Set the Team Logo from the id of the team object */
        if (subPlay?.teamId && teamLogoMap.hasOwnProperty(subPlay.teamId)) {
          subPlayLogo = teamLogoMap[subPlay.teamId];
        }

        /** Create a list of any sub-play components */
        subPlays.push(
          /** Sub-Play */
          <Row
            key={subPlay.id}
            className={`subPlay${isSubstitution ? ' substitution' : ''}`}
          >
            {/* Action pill and branch connection */}
            <SubPlayAction className="action" subPlay={subPlay}>
              {subPlay.action}
            </SubPlayAction>
            {/* Bench/Court Icon for substitution play/sub-play */}
            {shouldDisplayCourtIcon(subPlay) ? (
              <StyledCourtIcon />
            ) : shouldDisplayBenchIcon(subPlay) ? (
              <StyledBenchIcon />
            ) : (
              <></>
            )}
            {/* Player name and number */}
            <div className="player" style={{ flex: 1 }}>
              {subPlay.playerNumber && <strong>#{subPlay.playerNumber}</strong>}
              {subPlay.playerName && ` - ${subPlay.playerName}`}
            </div>
            {/* Initial and Logo column on the right */}
            <div className="right-column">
              {!isSubstitution && (
                <>
                  {/* User initials of entry */}
                  {subPlay.userInitials && (
                    <div className="user-initials">{subPlay.userInitials}</div>
                  )}
                  {/* Team Logo */}
                  <div className="logo">
                    {subPlayLogo && <img src={subPlayLogo} alt="Logo" />}
                  </div>
                </>
              )}
            </div>
          </Row>
        );
        /* Move to the next  */
        parentPlay = parentPlay.subPlay;
      }
    }

    return (
      <Row {...props} {...handlers} ref={setRef}>
        <div className={`timestamp-column ${isFlagged && 'is-flagged'}`}>
          {isEdited && !isFlagged && <IndicatorDot />}
          <div className={`timestamp ${isFlagged && 'is-flagged'}`}>
            {isFlagged && (
              <div className="icon-container">
                <FlagFill
                  className="icon"
                  color={colors.BLACK[100]}
                  size={10}
                />
              </div>
            )}
            {timestamp}
          </div>
          <div className="game-period">{gamePeriod}</div>
        </div>
        <div className="divider"></div>
        <div className="center-column">
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div className="action">
              {action}
              {courtLocation && ` (${courtLocation})`}
            </div>
            {isSubstitution && playerOnCourtBenchStatus === ItemTypes.COURT ? (
              <StyledCourtIcon />
            ) : isSubstitution &&
              playerOnCourtBenchStatus === ItemTypes.BENCH ? (
              <StyledBenchIcon />
            ) : (
              <></>
            )}
            <div className="player">
              {playerNumber && <strong>#{playerNumber}</strong>}
              {playerName && ` - ${playerName}`}
            </div>
            {!isSubstitution && (
              <div className="right-column">
                {userInitials && (
                  <div className="user-initials">{userInitials}</div>
                )}
                <div className="logo">
                  {logo && <img src={logo} alt="Logo" />}
                </div>
              </div>
            )}
          </Row>
          {subPlays}
        </div>
        {/* Substitution pairs share these fields */}
        {isSubstitution && (
          <div className="right-column bottom-border">
            {userInitials && (
              <div className="user-initials">{userInitials}</div>
            )}
            {logo && (
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            )}
          </div>
        )}
      </Row>
    );
  }
)`
  padding: 0 20px 0 0;
  position: relative;
  justify-content: flex-start;
  color: ${({ theme }) =>
    theme.lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  background: ${({ active, theme: { lightMode } }) =>
    active ? (lightMode ? colors.BLUE[400] : colors.BLUE[500]) : 'none'};

  .center-column {
    padding: ${({ padding = 15 }) => `${padding}px 0`};
    flex: 1;
  }

  .right-column {
    display: flex;
    align-items: center;

    &.bottom-border {
      border-bottom: 1px solid ${colors.GRAY_TRANSPARENT[700]};
    }
  }

  .center-column {
    border-bottom: 1px solid ${colors.GRAY_TRANSPARENT[700]};
  }

  &:last-child .center-column {
    border-bottom: none;
  }

  .user-initials {
    font: normal normal 6px/8px 'Segoe UI Bold';
    color: ${colors.GRAY[1500]};
    background: ${({ theme }) =>
      theme.lightMode ? colors.WHITE[100] : colors.BLACK[800]};
    ${({ theme }) =>
      theme.lightMode ? `border: 0.5px solid ${colors.GRAY[950]};` : ''};
    border-radius: 1px;
    display: inline-block;
    padding: 2px;
    margin-right: 10px;
    text-transform: uppercase;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;

    img {
      max-height: 17px;
      width: auto;
      border-radius: 2px;
    }
  }

  .player {
    flex: 1;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    color: ${({ theme }) =>
      theme.lightMode ? colors.BLACK[100] : colors.GRAY[900]};

    margin-left: 5px;
    padding-left: 5px;
    margin-right: 5px;
    // border-left: 1px solid ${colors.GRAY_TRANSPARENT[700]};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .timestamp-column {
    padding-top: ${({ padding = 15 }) => `${padding}px`};
    width: ${46 + TIMELINE_MARGIN}px;
    text-align: right;

    .timestamp {
      font-size: 12px;
      font-family: Open Sans, sans-serif;
      font-weight: bold;
      margin: 0;
      color: ${({ theme }) =>
        theme.lightMode ? colors.BLACK[100] : colors.GRAY[1000]};
      padding-right: ${TIMELINE_MARGIN}px;

      &.is-flagged {
        padding: 3px 5px 3px 0;
        margin-bottom: 2px;
        margin-left: 11px;
        top: -2px;

        background: ${colors.YELLOW[200]};
        color: ${colors.BLACK[100]};
        position: relative;
        display: flex;
        justify-content: flex-end;
        border-radius: 10px 0 0 10px;

        .icon-container {
          background: ${colors.YELLOW[200]};
          color: ${colors.BLACK[100]};
          padding: 0 2px 0 0;
          height: 100%;
        }
      }
    }
  }

  .game-period {
    font: normal normal normal 10px/15px Oswald;
    letter-spacing: 0px;
    color: ${colors.GRAY[1200]};
    line-height: 1;
    margin-top: 1px;
    padding-right: ${TIMELINE_MARGIN}px;
  }

  .action {
    color: ${({ play }) => play.textColor};
    background-color: ${({ play }) => play.color};
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 10px;
    padding: 2px 4px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
    text-transform: uppercase;

    // Timeline marker (circle)
    &::before {
      content: '';
      height: 5px;
      width: 5px;
      background-color: ${colors.GRAY[500]};
      border: 1px solid ${colors.GRAY[1600]};
      border-radius: 2px;
      display: inline-block;
      position: absolute;
      left: calc(-${TIMELINE_MARGIN}px - 3px);
      top: 6px;
      z-index: 3;
    }

    // Horizontal line connecting to timeline circle
    &::after {
      content: '';
      // Setting the height to 0.9px instead of 1px is a somewhat hacky fix to the sub-pixel rendering issue seen on desktop
      height: 0.9px;
      background-color: ${({ play }) => (play.color ? play.color : '')};
      position: absolute;
      left: -${TIMELINE_MARGIN}px;
      width: ${TIMELINE_MARGIN}px;
      top: 8px;
      z-index: 2;
    }
  }

  .subPlay {
    align-items: center;
    justify-content: flex-start;
    margin-left: ${TIMELINE_MARGIN / 2}px;
    margin-top: 9px;

    .action {
      &::before {
        display: none;
      }

      // Line connecting to parent play
      &::after {
        background: none;
        border-width: 0 0 0.5px 0.5px;
        border-style: solid;

        content: '';
        position: absolute;
        left: -${TIMELINE_MARGIN}px;
        width: ${TIMELINE_MARGIN}px;
        height: calc(100% + 16px);
        top: calc(-100% - 8px);
        z-index: 1;
      }
    }

    @media ${MOBILE_MEDIA} {
      .action::after {
        height: calc(100% + 14px);
        top: calc(-100% - 5px);
      }
    }

    &.substitution {
      margin-left: 0;
      .action::after {
        left: -${TIMELINE_MARGIN / 2}px;
        width: ${TIMELINE_MARGIN / 2}px;
      }
    }
  }

  .divider {
    margin: 0 ${TIMELINE_MARGIN}px 0 0;
    width: 1px;
    background-color: ${colors.GRAY_TRANSPARENT[700]};
    position: relative;
  }

  @media ${MOBILE_MEDIA} {
    .timestamp-column .timestamp {
      font-size: 10px;
    }

    .player {
      font-size: 12px;
    }
  }
`;

const Container = styled(Panel)`
  min-width: 410px;
  // height: 465px;
  overflow: auto;
  flex-direction: column;
  padding: 0;
`;

const PlayFeedItemsContainer = styled.div`
  overflow: auto;
`;

/**
 * Plays and sub-plays share these fields
 */
const subPlayDef = {
  action: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf(Object.values(ActionTypes)).isRequired,
  playerName: PropTypes.string,
  playerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logo: PropTypes.string.isRequired,
  userInitials: PropTypes.string,
  courtLocation: PropTypes.string,
  isSubstitution: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

const playDefinition = {
  timestamp: PropTypes.string.isRequired,
  gamePeriodNumber: PropTypes.number,
  gameOvertimeNumber: PropTypes.number,
  ...subPlayDef,
  subPlay: PropTypes.shape(subPlayDef),
};

PlayFeed.propTypes = {
  plays: PropTypes.arrayOf(PropTypes.shape(playDefinition)).isRequired,
  numPeriods: PropTypes.number,
  /**
   * teamLogoMap should have key=teamId and value=imageUrl
   * e.g.
   * {
   *   [gameData?.homeTeamId]: homeTeamImage,
   *   [gameData?.awayTeamId]: awayTeamImage,
   * }
   */
  teamLogoMap: PropTypes.object,
  showTabs: PropTypes.bool,
};

PlayFeedItem.propTypes = {
  play: PropTypes.shape(playDefinition).isRequired,
  editPlay: PropTypes.func.isRequired,
  teamLogoMap: PropTypes.object.isRequired,
  numPeriods: PropTypes.number,
  padding: PropTypes.number,
  /** If this play feed item is active, e.g. it's being edited. */
  active: PropTypes.bool,
};

export default PlayFeed;
