import React, { useState, useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { State } from 'country-state-city';
import MainLayout from '../components/layout/MainLayout';
import SquareGrid from '../components/Grids/SquareGrid';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import ToastAlert from '../components/alerts/ToastAlert';
import InfoEntryModal from '../components/Modals/InfoEntryModal';
import AddEditTeamModal from '../components/Modals/AddEditTeamModal';
import AssignUserGamesModal from '../components/Modals/AssignUserGamesModal';
import AssignGameUserRolesModal from '../components/Modals/AssignGameUserRolesModal';
import CsvTemplateUploadModal from '../components/Modals/CsvTemplateUploadModal';
import CsvAddDataModal from '../components/Modals/CsvAddDataModal';
import {
  createTeam,
  listTeams,
  getTeam,
  updateTeam,
} from '../api/teamsService';
import TeamContent from '../components/PageContent/TeamContent';
import { colors } from '../styles';
import {
  ITEM_TYPE,
  PENDING_CHANGE_LEAGUE_ID,
} from '../utils/constantsUtils';
import Spinner from '../components/Spinner';
import { useUserRoles } from '../api/userQueries';
import { teamsKeys, useListTeamsQuery, useTeamsQuery } from '../api/teamsQueries';
import { useListLeaguesQuery } from '../api/leaguesQueries';
import { useFutureGamesQuery } from '../api/gamesQueries';
import { getCoachesAlreadyAssigned, getTeamGamesForSelectedUsers, addUserToTeam } from '../utils/userUtil';
import { truncateText } from '../utils/stringUtil';
import { useFetchAll } from '../utils/reactQueryToolkit';
const log = require('../logger')('adminPanel');

const TeamsPage = () => {
  const queryClient = useQueryClient();
  const [queryFilter, setQueryFilter] = useState('');
  const onSearch = (query) => setQueryFilter(query);

  const [open, setOpen] = useState(false);
  const [csvOpen, setCsvOpen] = useState(false);
  const [teamEntryModalOpen, setTeamEntryModalOpen] = useState(false);
  const [teamEntryValue, setTeamEntryValue] = useState();
  const [teamId, setTeamId] = useState('');
  const [isCreate, setIsCreate] = useState();
  const [createTeamError, setCreateTeamError] = useState();
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [openAddCsvTeamsModal, setOpenAddCsvTeamsModal] = useState(false);
  const [teamItem, setTeamItem] = useState({
    name: '',
    leagueId: '',
    state: '',
    city: '',
    image: '',
    active: true,
    acceptedTerms: false,
  });

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const [openUsersModal, setOpenUsersModal] = useState(false);
  const [openUserRolesModal, setOpenUserRolesModal] = useState(false);
  const [pickedItems, setPickedItems] = useState([]);
  const [removePickedItems, setRemovePickedItems] = useState([]);
  const [isCancelledBtnClicked, setIsCancelledBtnClicked] = useState(false);
  const [itemType, setItemType] = useState();

  const teamIDRef = useRef();
  const teamNameRef = useRef();
  const teamFutureGamesRef = useRef([]);
  const assignedUsersRef = useRef();

  const { onSuccessCallback } = useFetchAll(true);

  const futureGamesQuery = useFutureGamesQuery({
    onSuccess: (data) => onSuccessCallback(futureGamesQuery)(data),
  });

  const { userItems } = useUserRoles();

  useEffect(() => {
    setOpenUsersModal(itemType === ITEM_TYPE.COACH);
  }, [itemType]);

  useEffect(() => {
    setOpenUserRolesModal(pickedItems.length > 0);
  }, [pickedItems]);

  useEffect(() => {
    if(isCancelledBtnClicked) {
      setItemType(null);
      setRemovePickedItems([]);
    }
  }, [isCancelledBtnClicked]);

  const TEAM_INFO_ENTRY = {
    MANUAL: 'manual',
    CSV: 'csv',
  };

  const CSV_TEAM_FILE_HEADERS = ['Name', 'City', 'State'];

  useEffect(() => {
    switch (teamEntryValue) {
      case TEAM_INFO_ENTRY.MANUAL:
        handleAddTeamManualModalOpen(teamId);
        break;
      case TEAM_INFO_ENTRY.CSV:
        setCsvOpen(true);
        break;
      default:
        break;
    }
  }, [teamEntryValue, teamId, TEAM_INFO_ENTRY.CSV, TEAM_INFO_ENTRY.MANUAL]);

  useEffect(() => {
    if (parsedCsvData.length > 0) {
      setOpenAddCsvTeamsModal(true);
    }
  }, [parsedCsvData]);

  useEffect(() => {
    if (!openAddCsvTeamsModal) {
      setParsedCsvData([]);
    }
  }, [openAddCsvTeamsModal]);

  /**
   * This useQuery retrieves all the leagues from leaguesService API.
   */
  const { data: leagues } = useListLeaguesQuery();

  /**
   * This useQuery retrieves all the teams from teamsService API.
   */
  const { data: allTeams } = useListTeamsQuery();

  /**
   * This useInfiniteQuery retrieves a set of teams page by page using the listTeams teamsService API method.
   */
  const {
    data: teams,
    fetchNextPage,
    hasNextPage,
    isSuccess, // eslint-disable-line no-unused-vars
    isLoading,
    isFetching,
  } = useTeamsQuery({
    filter: queryFilter,
    onError: (error) => {
      log.error(error);
      setShowToast(true);
      setToastText('An error occurred while retrieving teams.');
      setToastVariant('danger');
    },
  });

  const handleSubmit = async (
    values,
    resetFormFunct,
    isPendingUpdate = false
  ) => {
    /**
     * Check for duplicate
     */
    const { name, city, state, leagueId, active } = values;

    const duplicateData = await listTeams({
      filter: {
        name: { eq: name },
        city: { eq: city },
        state: { eq: state },
        leagueId: { eq: leagueId },
        active: { eq: active },
      },
    });

    if (duplicateData.errors) {
      /** Error checking for duplicate */
      setCreateTeamError(
        `Unable to create team. ${duplicateData.errors[0].message}.`
      );
    } else if (duplicateData.length >= 1) {
      const league = leagues.find(({ id }) => id === leagueId);
      /** Duplicate Exists */
      setCreateTeamError(
        `The team "${name} in ${city} ${state}, of ${league.abbreviation} league" already exists.`
      );
    } else {
      /**
       * Create or Edit
       */
      let returnData;
      isCreate
        ? (returnData = await createTeam(
            values.name,
            values.city,
            values.state,
            values.leagueId,
            values.image,
            values.active
          ))
        : (returnData = await updateTeam(values.id, values, isPendingUpdate));

      /* If League Create/Update failed show an error, else close the modal */
      if (returnData.errors) {
        setCreateTeamError(
          `Unable to create team. ${returnData.errors[0].message}.`
        );
      } else {
        /**
         * Success
         * Clear error, update list, close model, show toast, reset form
         */
        setCreateTeamError(null);
        queryClient.invalidateQueries(teamsKeys.all);
        setOpen(false);
        resetFormFunct();
        setTeamEntryValue(null);
        setTeamId(null);

        if (isCreate) {
          setToastText(`The Team "${values.name}" was created successfully.`);
        } else {
          setToastText(`The Team "${values.name}" was updated successfully.`);
        }

        setShowToast(true);
        setToastVariant('success');
      }
    }
  };

  const handleOpen = () => {
    setTeamEntryModalOpen(true);
  };

  const handleAddTeamManualModalOpen = async (teamId) => {
    if (!teamId) {
      setIsCreate(true);
      setOpen(true);
    } else {
      const team = await getTeam(teamId);
      setTeamItem(team);
      setIsCreate(false);
      setOpen(true);
    }
  };

  const handleEditTeamManualModalOpen = async (teamId) => {
    setTeamId(teamId);
    setTeamEntryValue(TEAM_INFO_ENTRY.MANUAL);
  };

  const onTeamManualModalClose = () => {
    setCreateTeamError(null);
    setOpen(false);
    setTeamEntryValue(null);
    setTeamId(null);
  };

  const onCvsTemplateModalClose = () => {
    setCsvOpen();
    setTeamEntryValue(null);
  };

  const returnToTeamEntryModal = () => {
    onTeamManualModalClose();
    setCsvOpen(false);
    handleOpen();
  };

  const closeUsersModalHandler = () => {
    setOpenUsersModal(false);
  };

  const closeUserRoleAssignmentModalHandler = () => {
    setOpenUserRolesModal(false);
  };

  const reopenAssignUserItemsModalBackHandler = (pickedItemsRemoved) => {
    setRemovePickedItems(pickedItemsRemoved);
    closeUserRoleAssignmentModalHandler();
    setTimeout(() => setOpenUsersModal(true), 250);
  };

  const getModalTitle = (itemType) => {
    switch(itemType) {
      case ITEM_TYPE.COACH:
        return `Assign Coaches to ${truncateText(teamNameRef.current, 70)}`;
      default:
        return null;
    };
  };

  const selectedCoaches = (coaches) => {
    const userIds = coaches.map(({ id }) => id);
    const coachesToBeAssigned = getTeamGamesForSelectedUsers(userIds, teamFutureGamesRef.current, teamIDRef.current);
    coaches = coaches.map(({ id, firstName, lastName, role }) => {
      const team = coachesToBeAssigned.find(item => Object.keys(item)[0] === id)[id];
      return { id, name: `${firstName} ${lastName}`, role, team };
    });
    setPickedItems(coaches);
  };

  const handleOpenAssignUserRoleModal = (teamName, teamId, leagueId) => {
    const futureGamesData = futureGamesQuery.data;
    teamIDRef.current = teamId;
    teamNameRef.current = teamName;
    teamFutureGamesRef.current = futureGamesData.filter(upcomingGame => (upcomingGame.leagueId === leagueId) && ((upcomingGame.homeTeamId === teamId) || (upcomingGame.awayTeamId === teamId)));
    assignedUsersRef.current = getCoachesAlreadyAssigned(userItems, teamFutureGamesRef.current, teamId);
    setItemType(ITEM_TYPE.COACH);
  };

  const submitCoachTeamAssignment = async (itemParams) => {
    const teamUserParams = itemParams.reduce((updated, current) => {
      const { userId, teamRole, team } = current;
      const reducedCurrentParams = team.reduce((updatedTeam, currentTeam) => [ ...updatedTeam, { userId, teamRole, team: { ...currentTeam } } ], []);
      return [ ...updated, ...reducedCurrentParams ];
    }, []);

    const createTeamUsersResponse = await addUserToTeam(teamUserParams);

    if(!createTeamUsersResponse.error) {
      setToastVariant('success');
      setToastText(`Coaches were assigned successfully to ${teamNameRef.current}`);
      setShowToast(true);
    }
    else {
      setToastVariant('danger');
      setToastText(`Coaches failed to be assigned to ${teamNameRef.current}`);
      setShowToast(true);
    }
  };

  const saveNewCsvTeamData = async (csvTeamData, setCsvTeamState) => {
    const allLeagueTeamNames = allTeams.items.map(({ name }) => name);

    csvTeamData = csvTeamData.map((team) => {
      team.isAlreadyExistingDataValue = allLeagueTeamNames.find(
        (teamName) => teamName === team.Name
      )
        ? true
        : false;
      return team;
    });

    const isAnyCsvTeamNameRepeated = csvTeamData.some(
      ({ isAlreadyExistingDataValue }) => isAlreadyExistingDataValue
    );

    if (isAnyCsvTeamNameRepeated) {
      setCsvTeamState([...csvTeamData]);
    } else {
      csvTeamData = csvTeamData.map((team) => {
        team.State = State.getStatesOfCountry('US').find(
          ({ name }) => team.State === name
        )?.isoCode;
        return team;
      });

      const newTeamsCreatedAsync = csvTeamData.map(
        async ({ Name, City, State }) =>
          await createTeam(
            Name,
            City,
            State,
            PENDING_CHANGE_LEAGUE_ID,
            null,
            true
          )
      );
      const newTeamsCreatedAsynResponse = await Promise.all(
        newTeamsCreatedAsync
      );

      const hasExceptions = newTeamsCreatedAsynResponse.some(
        (response) => response.errors
      );

      if (hasExceptions) {
        setCreateTeamError(
          'Data set from CSV Teams file was not added successfully.'
        );
      } else {
        setCreateTeamError(null);
        setOpenAddCsvTeamsModal(false);
        queryClient.invalidateQueries(teamsKeys.all);
        setToastText('New Teams from Teams CSV file was added successfully.');
        setShowToast(true);
        setToastVariant('success');
      }
    }
  };

  return (
    <MainLayout
      title="Teams"
      buttonLabel="+ Add Team"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {!isLoading && teams && teams.length === 0 && (
        <SquareGrid
          height={275}
          width={250}
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          justifyContent={'center'}
        >
          <AddCircleButton
            buttonLabel="Add Team"
            buttonClickHandler={handleOpen}
          />
        </SquareGrid>
      )}

      <TeamContent
        searchId={'__teamId'}
        data={teams}
        placeholder={'Search Teams'}
        handleEditModalOpenHook={handleEditTeamManualModalOpen}
        openAssignUserRoleModal={handleOpenAssignUserRoleModal}
        fetchNextPage={() => fetchNextPage()}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        onSearch={onSearch}
      />

      <InfoEntryModal
        isOpen={teamEntryModalOpen}
        setOpen={setTeamEntryModalOpen}
        setInfoEntry={setTeamEntryValue}
        initialValue={{ teamEntry: 'manual' }}
        modalHeaderTitle="Add Team(s)"
        groupName="teamEntry"
        templateFileType="Teams"
        csvRadioTitle="Upload one or more teams via a csv file"
        infoTitle="How would you like to enter team information?"
        filename="team-data-upload-template.csv"
        fileData={{
          Name: '',
          City: '',
          State: '',
        }}
      />

      <AssignUserGamesModal
        modalOpen={openUsersModal}
        setModalOpen={setOpenUsersModal}
        doneHandler={pickedItems => selectedCoaches(pickedItems)}
        onClose={closeUsersModalHandler}
        itemType={itemType}
        isCancelledBtnClicked={isCancelledBtnClicked}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        pickedGamesRemoved={removePickedItems}
        itemName={truncateText(teamNameRef.current, 70)}
        assignedUserIds={assignedUsersRef.current}
        teamItemId={teamIDRef.current}
      />

      <AssignGameUserRolesModal
        isNewGameRoleAssignment={true}
        isGameRolesEditable={false}
        games={pickedItems}
        itemType={itemType}
        modalOpen={openUserRolesModal}
        setModalOpen={setOpenUserRolesModal}
        submitHandler={submitCoachTeamAssignment}
        backHandler={reopenAssignUserItemsModalBackHandler}
        onClose={closeUserRoleAssignmentModalHandler}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        modalTitle={getModalTitle(itemType)}
      />

      <AddEditTeamModal
        isNewTeam={isCreate}
        modalOpen={open}
        setModalOpen={setOpen}
        onSubmit={handleSubmit}
        onClose={onTeamManualModalClose}
        teamData={teamItem}
        leagueList={leagues}
        error={createTeamError}
        backToTeamInfoEntryModal={returnToTeamEntryModal}
      />

      <CsvAddDataModal
        csvParsedData={parsedCsvData}
        dataType={'teams'}
        modalOpen={openAddCsvTeamsModal}
        setModalOpen={setOpenAddCsvTeamsModal}
        onSubmit={saveNewCsvTeamData}
        onClose={() => setOpenAddCsvTeamsModal(false)}
        modalTitle={'Add Teams'}
        csvFileHeaders={CSV_TEAM_FILE_HEADERS}
        repeatedDataValueProperty={'Name'}
        error={createTeamError}
        setError={setCreateTeamError}
      />

      <CsvTemplateUploadModal
        modalTitle={'Add Team(s)'}
        modalOpen={csvOpen}
        setModalOpen={setCsvOpen}
        onClose={onCvsTemplateModalClose}
        inputName={'add_teams'}
        csvDownloadFile={'team-data-upload-template.csv'}
        csvFileHeaders={CSV_TEAM_FILE_HEADERS}
        setCsvParsedData={setParsedCsvData}
        backToTeamInfoEntryModal={returnToTeamEntryModal}
        fileData={{
          Name: '',
          City: '',
          State: '',
        }}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default TeamsPage;
