const LeagueValidation = {
  image: {
    isRequired: { value: true, message: 'Upload League Image' },
  },name: {
    min: { value: 5, message: 'Must be 5 characters or more' },
    max: { value: 100, message: 'Must be no more than 100 characters' },
    isRequired: { value: true, message: 'League Name required' },
  },
  abbreviation: {
    min: { value: 2, message: 'Must be 2 characters or more' },
    max: { value: 6, message: 'Must be no more than 6 characters' },
    isRequired: { value: true, message: 'Abbreviation required' },
  },
  sport: {
    isRequired: { value: true, message: 'Sport selection required' },
  },
  teamSize: {
    min: { value: 1, message: 'Min 1' },
    max: { value: 40, message: 'Max 40' },
    isRequired: { value: true, message: 'Team Size is required' },
  },
  numPlayersOnCourt: {
    min: { value: 1, message: 'Min 1' },
    max: { value: 5, message: 'Max 5' },
    isRequired: { value: true, message: 'Players on Court required' },
  },
  numTimeOuts: {
    min: { value: 0, message: 'Min 0' },
    max: { value: 10, message: 'Max 10' },
    isRequired: { value: true, message: 'Timeouts required' },
  },
  numFoulsPerPlayer: {
    min: { value: 0, message: 'Min 0' },
    max: { value: 10, message: 'Max 10' },
    isRequired: { value: true, message: 'Fouls/Player required' },
  },
  numPeriods: {
    options: { value: [2, 4], message: 'Invalid Number of Periods'},
    isRequired: { value: true, message: 'Period Type required' },
  },
  timePerPeriodInMins: {
    min: { value: 0, message: 'Min 0' },
    max: { value: 60, message: 'Max 60' },
    isRequired: { value: true, message: 'Period Time required' },
  },
  overtimeDurationInMins: {
    min: { value: 0, message: 'Min 0' },
    max: { value: 30, message: 'Max 30' },
    isRequired: { value: true, message: 'Overtime duration required' },
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' },
  },
};

export default LeagueValidation