import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Row, Col, Table } from 'react-bootstrap';
import SearchInputComponent from '../Inputs/SearchInputComponent';
import { colors, textEllipsisStyle, StyledColumnFlexStart } from '../../styles';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import UploadFileImage from '../../assets/LeaguePlaceholder.png';
import {
  SearchMenuDiv,
  SearchMenuMainCriteria,
  SearchMenuSubCriteria,
  getHighlightedText,
} from '../../utils/searchUtils';
import InfiniteScrollList from '../InfiniteListScroll';
import useListScroll from '../../utils/useListScroll';

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  backgroundcolor: transparent;
  display: flex;
  flex-direction: column;

  display: flex;
  justify-content: center; // 1
  flex-flow: column wrap; // 2
`;

const LeagueItems = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 0px;
  display: flex;
  justify-content: center; // 3
  flex-flow: row wrap; // 4
`;

const cardStyle = {
  height: '260px',
  width: '440px',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  margin: '10px',
  padding: '0px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '2px 2px 5px #0000001a',
  border: '1px solid #BEBEBE',
  borderRadius: '6px',
  opacity: '1',
};

const tableStyle = {
  border: '0px solid #EBEBEB',
  opacity: '0.75',
  font: "14px 'Open Sans', Regular",
  lineHeight: '12px',
  color: '#434343FF',
};

const imageStyle = {
  opacity: '1',
  height: '100%',
  width: '100%',
  objectFit: 'contain',
  padding: '5px',
  borderRadius: '12%',
  marginLeft: '0.5px',
};

const imageContainerStyle = {
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #E5E5E5',
  borderRadius: '10px',
  opacity: '1',
  height: '100px',
  width: '100px',
  margin: '0px 10px 10px 0px',
  paddingRight: '0px',
};

const leagueNameStyle = {
  ...textEllipsisStyle,
  WebkitLineClamp: 2,
  wordBreak: 'break-word',
  font: "26px 'Barlow Condensed', SemiBold",
  fontWeight: 600,
  color: '#434343',
  // minHeight: '60px',
  lineHeight: '28px',
  marginRight: '32px',
  letterSpacing: '0.45px',
  textTransform: 'capitalize',
  // display: 'block' /* Fallback for non-webkit */,
};

const dividerStyle = {
  marginTop: '10px',
  marginBottom: '10px',
  borderBottom: '1px solid rgb(112 112 112 / 20%)',
};

const leagueAbbreviationStyle = {
  font: "16px 'Open Sans', Regular",
  color: '#434343',
  lineHeight: '20px',
  letterSpacing: '0.32px',
};

const noLeftGutter = {
  paddingLeft: '0px',
};

const noRightGutter = {
  paddingRight: '0px',
};

const HorizontalGrid = ({ children, onClick }) => {
  return (
    <Card body style={cardStyle} onClick={onClick}>
      {children}
    </Card>
  );
};

const LeagueItem = ({
  id,
  name,
  image,
  abbreviation,
  teamSize,
  numPlayersOnCourt,
  numPlayersOnBench,
  numTimeOuts,
  numFoulsPerPlayer,
  numPeriods,
  timePerPeriodInMins,
  overtimeDurationInMins,
  sport,
  editLeagueCtrl,
  viewLeagueCtrl,
}) => {
  return (
    <HorizontalGrid
      bgColor={colors.WHITE[100]}
      borderColor={'transparent'}
      onClick={(e) => {
        if (!e.overflowMenuHandled) {
          viewLeagueCtrl(id);
        }
      }}
    >
      <Row>
        <Col className="col-md-auto" style={noRightGutter}>
          <div style={imageContainerStyle}>
            <img
              style={imageStyle}
              src={image}
              onError={(event) => (event.target.src = UploadFileImage)}
              alt="League Logo"
            />
          </div>
        </Col>
        <Col style={noLeftGutter}>
          <div style={leagueNameStyle}>{name.toUpperCase()}</div>
          <div style={dividerStyle}></div>
          {/* <div style={leagueAbbreviationStyle}>{sport}</div> */}
          <div style={leagueAbbreviationStyle}>{abbreviation}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <tbody style={tableStyle}>
              <tr>
                <td>Roster Size</td>
                <td style={{ fontWeight: 'bold' }}>{teamSize}</td>
                <td>Fouls Per Player</td>
                <td style={{ fontWeight: 'bold' }}>{numFoulsPerPlayer}</td>
              </tr>
              <tr>
                <td>Players on Court</td>
                <td style={{ fontWeight: 'bold' }}>{numPlayersOnCourt}</td>
                <td>Periods</td>
                <td style={{ fontWeight: 'bold' }}>{numPeriods}</td>
              </tr>
              <tr>
                <td>Players on Bench</td>
                <td style={{ fontWeight: 'bold' }}>{numPlayersOnBench}</td>
                <td>Time Per Period</td>
                <td style={{ fontWeight: 'bold' }}>{timePerPeriodInMins}</td>
              </tr>
              <tr>
                <td>No. of Time Outs</td>
                <td style={{ fontWeight: 'bold' }}>{numTimeOuts}</td>
                <td>Overtime Duration</td>
                <td style={{ fontWeight: 'bold' }}>{overtimeDurationInMins}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <OptionsOverflowMenu
        menuItems={[
          {
            label: 'View',
            onClick: (e) => {
              viewLeagueCtrl(id, name);
            },
          },
          // {
          //   label: "Edit",
          //   onClick: (e) => {
          //     e.stopPropagation();
          //     editLeagueCtrl(id, name);
          //   },
          // },
        ]}
      />
    </HorizontalGrid>
  );
};

const LeagueContent = ({
  searchId,
  placeholder,
  data,
  openEditModalHook,
  openViewModalHook,
  fetchNextPage,
  hasNextPage,
  isFetching,
  onSearch,
}) => {
  const [displayedLeagues, setDisplayedLeagues] = useState([]);
  const [leagueSearch, setLeagueSearch] = useState('');

  const scrollProps = useListScroll(data);
  // const [listRef, onListScrollHandler] = useListScroll(data);

  const searchDisplay = (option) => `${option.name} (${option.abbreviation})`;

  const searchFilter = (option, search) =>
    searchDisplay(option).toLowerCase().includes(search.toLowerCase()) ||
    option.name.toLowerCase().includes(search.toLowerCase()) ||
    option.abbreviation.toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    setDisplayedLeagues(data);
  }, [data]);

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (leagueSearch) {
      /** Check if the input is an object or a search string */
      if (typeof leagueSearch === 'object') {
        /** Object Selection */
        setDisplayedLeagues([leagueSearch]);
      } else if (leagueSearch.length > 0) {
        /** Search String */
        setDisplayedLeagues(
          data?.filter((option) => searchFilter(option, leagueSearch))
        );
      }
    } else {
      /** Display ALL Leagues by clearing the search filter */
      onSearch('')
    }
  }, [leagueSearch]);

  const getDisplayedData = displayedLeagues => {
    return (
      <>
        {displayedLeagues?.map(
          ({
            id,
            image,
            name,
            abbreviation,
            teamSize,
            numPlayersOnCourt,
            numPlayersOnBench,
            numTimeOuts,
            numFoulsPerPlayer,
            numPeriods,
            timePerPeriodInMins,
            overtimeDurationInMins,
            sport,
          }) => (
            <LeagueItem
              key={id}
              id={id}
              name={name}
              image={image}
              abbreviation={abbreviation}
              teamSize={teamSize}
              numPlayersOnCourt={numPlayersOnCourt}
              numPlayersOnBench={numPlayersOnBench}
              numTimeOuts={numTimeOuts}
              numFoulsPerPlayer={numFoulsPerPlayer}
              numPeriods={numPeriods}
              timePerPeriodInMins={timePerPeriodInMins}
              overtimeDurationInMins={overtimeDurationInMins}
              sport={sport.name}
              editLeagueCtrl={openEditModalHook}
              viewLeagueCtrl={openViewModalHook}
            />
          )
        )}
      </>
    );
  }

  return (
    <ContentContainer>
      <StyledColumnFlexStart>
        <SearchInputComponent
          id={searchId}
          width={300}
          data={data}
          dataProperty={(option) => `${option.name} (${option.abbreviation})`}
          placeholder={placeholder}
          filterBy={(option, props) => searchFilter(option, props.text)}
          onChange={setLeagueSearch}
          isLoading={isFetching}
          onSearch={onSearch}
          renderMenuItemChildren={(option, props) => {
            return (
              <SearchMenuDiv>
                <SearchMenuMainCriteria>
                  {getHighlightedText(option.name, props.text)}
                </SearchMenuMainCriteria>
                <SearchMenuSubCriteria>
                  {getHighlightedText(option.abbreviation, props.text)}
                </SearchMenuSubCriteria>
              </SearchMenuDiv>
            );
          }}
        />
      </StyledColumnFlexStart>
      <LeagueItems 
        id="infiniteScrollContainer"
        {...scrollProps}
        // ref={listRef}
        // onScroll={() => onListScrollHandler()}
      >
        <InfiniteScrollList
          displayedData={displayedLeagues}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          displayedDataFilter={() => getDisplayedData(displayedLeagues)}
        />
      </LeagueItems>
    </ContentContainer>
  );
};

export default LeagueContent;
