import React from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'react-bootstrap';

const LaunchButtonGroupContainer = styled.div`
  z-index: 1;
  padding-right: 0px;
  width: 100%;
  border-bottom: 1px solid #D5D5D5;
`;

const LaunchButtonGroup = styled(ButtonGroup)`
  width: 100%;
  left: 0px;
`;

const LaunchFilterButton = styled(Button)`
  padding: 6px 0;
  margin-left: 0px;
  margin-right: 0px;
  text-align: center !important;
  border: 0;
  background-color: transparent !important;
  font: normal normal normal 8px/12px 'Segoe UI';
  letter-spacing: 0px;
  color: #111111 !important;
  opacity: 1;
  font-size: 14px;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
  &:hover {
    border: 0;
    border-bottom: 2px solid #26a9e1;
  }
  &:active,
  &.active {
    font-weight: bold;
    border-color: transparent;
    border-bottom: 2px solid #26a9e1;
  }
  &:disabled {
    border: 0;
    border-bottom: 2px solid transparent;
  }
`;

const GameLeadersTabs = ({
  pointsClickHandler = () => {},
  reboundsClickHandler = () => {},
  assistsClickHandler = () => {},
  activePointsStatus = false,
  activeReboundsStatus = false,
  activeAssistsStatus = false,
}) => {
  return (
    <LaunchButtonGroupContainer>
      <LaunchButtonGroup>
        <LaunchFilterButton
          onClick={pointsClickHandler}
          active={activePointsStatus}
        >
          Points
        </LaunchFilterButton>
        <LaunchFilterButton
          onClick={reboundsClickHandler}
          active={activeReboundsStatus}
        >
          Rebounds
        </LaunchFilterButton>
        <LaunchFilterButton
          onClick={assistsClickHandler}
          active={activeAssistsStatus}
        >
          Assists
        </LaunchFilterButton>
      </LaunchButtonGroup>
    </LaunchButtonGroupContainer>
  );
};

export default GameLeadersTabs;