import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../styles';
import { textEllipsisStyle } from '../../../styles/typography';
import { TABBED_ITEM } from './TabbedItemEnum';

const TeamTabbedContainer = styled.div`
  width: 100%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${colors.WHITE[100]};
  border-top: 1px solid #D5D5D5;
  border-left: 1px solid #D5D5D5;
  border-right: 1px solid #D5D5D5;
`;

const TeamTabItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: ${props => props.leftBorderWidth}px solid #D5D5D5;
  border-right: ${props => props.rightBorderWidth}px solid #D5D5D5;
  border-bottom: 2px solid ${props => props.tabbedStateStatus ? '#26A9E1' : '#D5D5D5'};
`;

const TeamTabTitle = styled.div`
  width: auto;
  max-width: 200px;
  height: 40px;
  text-align: left;
  padding-left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font: normal normal 600 20px/35px 'Segoe UI';
  color: ${props => props.tabbedStateStatus ? colors.BLACK[100] : '#696969'}
`;

const TeamTabItem = ({
  teamImage, 
  teamName,
  leftBorderWidth = 0,
  rightBorderWidth= 0,
  tabbedStateStatus,
  onTabItemClick = () => {}, 
}) => {
  return (
    <TeamTabItemContainer
      leftBorderWidth={leftBorderWidth}
      rightBorderWidth={rightBorderWidth}
      tabbedStateStatus={tabbedStateStatus}
      onClick={() => onTabItemClick()}
    >
      <img
        src={teamImage}
        width="40px"
        height="40px"
        style={{ 
          objectFit: 'contain', 
          opacity: tabbedStateStatus ? 1 : 0.7, 
          mixBlendMode: tabbedStateStatus ? 'normal' : 'luminosity', 
        }}
      />
      <TeamTabTitle
        tabbedStateStatus={tabbedStateStatus}
        style={textEllipsisStyle}
      >
        {teamName}
      </TeamTabTitle>
    </TeamTabItemContainer>
  );
}

const HomeTeamTabItem = ({
  homeTeamImage, 
  homeTeamName, 
  activeTab,
  setActiveTab,
}) => {
  return (
    <TeamTabItem
      teamImage={homeTeamImage}
      teamName={homeTeamName}
      rightBorderWidth={1}
      tabbedStateStatus={activeTab == TABBED_ITEM.HOME_TEAM_ITEM}
      onTabItemClick={() => setActiveTab(TABBED_ITEM.HOME_TEAM_ITEM)}
    />
  );
};

const AwayTeamTabItem = ({
  awayTeamImage, 
  awayTeamName, 
  activeTab,
  setActiveTab,
}) => {
  return (
    <TeamTabItem
      teamImage={awayTeamImage}
      teamName={awayTeamName}
      leftBorderWidth={1}      
      tabbedStateStatus={activeTab == TABBED_ITEM.AWAY_TEAM_ITEM}
      onTabItemClick={() => setActiveTab(TABBED_ITEM.AWAY_TEAM_ITEM)}
    />
  );
};

const TeamsContainer = ({
  awayTeamImage, 
  homeTeamImage, 
  awayTeamName, 
  homeTeamName, 
  activeTab,
  setActiveTab,
}) => {
  return (
    <TeamTabbedContainer>
      <Row style={{ width: '100%', height: '100%', margin: 0, }}>
        <Col xs={6} className="p-0">
          <HomeTeamTabItem
            homeTeamImage={homeTeamImage}
            homeTeamName={homeTeamName}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Col>
        <Col xs={6} className="p-0">
          <AwayTeamTabItem
            awayTeamImage={awayTeamImage}
            awayTeamName={awayTeamName}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Col>
      </Row>
    </TeamTabbedContainer>
  );
};

export default TeamsContainer;