import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItemText from '@material-ui/core/ListItemText';
import { ListGroup } from 'react-bootstrap';
import Routes from './Routes';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updatePathLocation } from '../../redux/pathLocationSlice';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { colors } from '../../styles/colors';
import ExpandAdminIcon from '../../assets/expand_admin.png';
import CollapseAdminIcon from '../../assets/collapse_admin.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {},
  drawerPaper: props => ({
    width: props.width,
    backgroundColor: colors.GRAY[400],
    paddingLeft: 0,
    paddingTop: 90,
    borderRight: 0,
    zIndex: 0,
    transition: 'width 0.05s linear',
  }),
  drawerText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#D9D9D9',
    letterSpacing: '0px',
  },
  selectedDrawerText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    color: colors.GRAY[400],
    fontWeight: '600',
  },
  routeList: {
    backgroundColor: colors.GRAY[250],
    padding: 0,
  },
}));

const Svg = styled.svg`
  background-color: ${(props) => (props.selected ? '#2E2E2E' : '#D9D9D9')};
  height: 24px;
  width: 24px;
  margin-right: ${props => props.isDrawerCollapsed ? '0px' : '18px'};
  margin-left: ${props => props.isDrawerCollapsed ? '0px' : '12px'};
  mask: ${({ src }) => `url(${src.toString()}) no-repeat `};
  mask-size: 22px;
`;
const StyledListItem = styled(ListGroup.Item)`
  &&& {
    padding: 10px 15px;
    display: flex;
    height: ${props => props.index === 'start' ? '0px' : '52px'};
    width: 100%;
    ${(props) => props.selected && 'pointer-events: none;'}
    align-items: center;
    background-color: ${(props) =>
      props.selected ? 'transparent' : props.index % 2 ? '#2C2C2C' : '#2E2E2E'};
    border: none;
    border-radius: 0 0 0 0;
    padding-left: ${(props) => (props.selected ? '10px' : '15px')};
    border-bottom: ${(props) =>
      props.selected || props.index === 'end' ? 'none' : '1px solid #333333'};
    border-left: ${(props) => (props.selected ? '5px solid #26A9E1' : 'none')};
    // Sub-pixel problem
    ${(props) => props.index === 'start' && 'margin-top: -1px;'}
    ${(props) => props.index === 'end' && 'margin-bottom: -1px;'}
    &.selectednext {
      border-radius: 0 0 10px 0;
    }
    &.selectedprev {
      border-radius: 0 10px 0 0;
    }
  }
`;
const ExpandCollapseCtrlContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

function NavigationItem({
  selected,
  selectedNext,
  selectedPrev,
  index,
  children,
}) {
  return (
    <StyledListItem
      selected={selected}
      index={index}
      className={`${
        selectedPrev ? 'selectedprev' : selectedNext ? 'selectednext' : ''
      }`}
    >
      {children}
    </StyledListItem>
  );
}

const NavigationDrawer = (props) => {
  const classes = useStyles({ width: props.drawerWidth });
  const location = useLocation();
  const dispatch = useDispatch();
  const pathLocationState = useSelector(state => state.pathLocation.value);

  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(true);
  const [currentLocationPath, setCurrentLocationPath] = useState('');

  useEffect(() => {
    props.setIsDrawerCollapsed(isDrawerCollapsed);
  }, [isDrawerCollapsed]);

  useEffect(() => {
    if(location.pathname.includes('/admin/player/profile/') || 
       location.pathname.includes('/admin/team/players/') || 
       location.pathname.includes('/admin/game/details/') || 
       location.pathname.includes('/admin/user/profile/')
    ) {
      setCurrentLocationPath(location.pathname);

      if(pathLocationState === '/admin/home') {
        if(location.pathname.includes('/admin/game/details/') || location.pathname.includes('/admin/user/profile/')) {
          dispatch(updatePathLocation({ pathLocationValue: location.pathname }));
        }
      }
    }
    else {
      setCurrentLocationPath('');

      if(location.pathname === '/admin/home') {
        if(!((pathLocationState?.includes('/admin/game/details/') || pathLocationState?.includes('/admin/user/profile/')))) {
          dispatch(updatePathLocation({ pathLocationValue: '/admin/home' }));
        }
      }
      else {
        dispatch(updatePathLocation({ pathLocationValue: '' }));
      }
    }
  }, [location]);

  const activeRoute = (routename) => {
    const matchesPath = props.location.pathname === routename ? true : false;

    if((routename === '/admin/players' && currentLocationPath.includes('/admin/player/profile/')) || 
       (routename === '/admin/teams' && currentLocationPath.includes('/admin/team/players/')) || 
       (routename === '/admin/games' && currentLocationPath.includes('/admin/game/details/')) || 
       (routename === '/admin/users' && currentLocationPath.includes('/admin/user/profile/'))
    ) {
      return true;
    }

    return matchesPath;
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <ListGroup className={classes.routeList}>
          {/* Empty Item just used to show the curved border when the first item is selected */}
          <NavigationItem
            selected={false}
            index={'start'}
            selectedNext={Routes[0] ? activeRoute(Routes[0].pathName) : false}
          />
          {/* List of Navigation Routes */}
          {Routes.map((item, index, array) => (
            <Link
              to={item.pathName}
              style={{ textDecoration: 'none', height: 52, width: '100%', }}
              key={item.label}
            >
              <NavigationItem
                selected={activeRoute(item.pathName)}
                selectedPrev={
                  array[index - 1]
                    ? activeRoute(array[index - 1].pathName)
                    : false
                }
                selectedNext={
                  array[index + 1]
                    ? activeRoute(array[index + 1].pathName)
                    : false
                }
                index={index}
              >
                <Svg
                  selected={activeRoute(item.pathName)}
                  src={item.iconImgSrc}
                  alt={item.label.replace(/s/, '').toLowerCase()}
                  isDrawerCollapsed={isDrawerCollapsed}
                />
                {!isDrawerCollapsed && 
                <ListItemText
                  classes={{
                    primary: activeRoute(item.pathName)
                      ? classes.selectedDrawerText
                      : classes.drawerText,
                  }}
                  primary={item.label}
                ></ListItemText>}
              </NavigationItem>
            </Link>
          ))}
          {/* Empty Item just used so to show the curved border when the last item is selected */}
          <NavigationItem
            selected={false}
            index={'end'}
            selectedPrev={activeRoute(Routes[Routes.length - 1].pathName)}
          />
        </ListGroup>
        <ExpandCollapseCtrlContainer>
          <img
            width="20px"
            height="20px"
            src={isDrawerCollapsed ? CollapseAdminIcon : ExpandAdminIcon}
            onClick={() => setIsDrawerCollapsed(!isDrawerCollapsed)}
            alt=""
            style={{ cursor: 'pointer' }}
          />
        </ExpandCollapseCtrlContainer>
      </Drawer>
    </div>
  );
};

export default withRouter(NavigationDrawer);
