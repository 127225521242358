import React from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner';

const Spinner = (props) => (
  <BootstrapSpinner
    animation="border"
    role="status"
    style={{
      position: props.position || 'fixed',
      top: '50%',
      left: '50%',
      zIndex: 1,
    }}
    {...props}
  >
    <span className="visually-hidden">Loading...</span>
  </BootstrapSpinner>
);

export default Spinner;