import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import { colors } from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.GRAY[500],
    overflowY: 'scroll',
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(2, 0, 2, 2),
  },
}));

export default function CheckboxBox({
  checkableArray,
  setCheckableArray,
  label,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    let updatedState = checkableArray.map((item) =>
      item.id === event.target.name
        ? { ...item, checked: !item.checked }
        : { ...item }
    );
    setCheckableArray(updatedState);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {checkableArray.map((item) => (
            <CheckboxWithLabel
              checked={item.checked}
              onChange={handleChange}
              name={item.id}
              key={item.id}
              label={label ? item[label] : item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
