import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useField } from 'formik';
import { isMobile } from 'react-device-detect';

const controlSize = 'md';
const controlStyle = {
  lineHeight: '2.0',
  textAlign: 'left',
};

const InputFieldComponent = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  const getPlaceHolder = (type, value) => {
    if (type === 'date' && isMobile) {
      return type === 'date' && value !== '' ? '' : 'mm/dd/yyyy';
    } else if (type === 'datetime-local' && isMobile) {
      return type === 'datetime-local' && value !== ''
        ? ''
        : 'mm/dd/yyyy --:-- --';
    } else if (type === 'datetime-local' || type === 'date') {
      return '';
    } else {
      return 'true';
    }
  };

  return (
    <Form.Group>
      <FloatingLabel
        label={props.required ? `${label}*` : label}
        className={`label-text ${meta.touched && meta.error ? 'pb-0' : 'pb-2'}`}
      >
        <Form.Control
          {...field}
          {...props}
          size={controlSize}
          style={controlStyle}
          isInvalid={meta.touched && meta.error}
          placeholder={getPlaceHolder(props.type, field.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {meta.error}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};

export default InputFieldComponent;
