import React, { useEffect, useRef, } from 'react';
import { isMobile, addScrollListClass } from '../utils/scrollUtil';
const log = require('../logger')();

const scrollListContainerStyle = { 
  width: '100%', 
  height: 'auto', 
  overflowY: 'auto', 
  flexGrow: 1, 
  display: 'flex', 
};

const ScrollListItemsContainer = ({
  children,
  data = [], 
  listStyle = {},
  scrollRef = null,
  onScroll = () => {},
  isCollapsedState = false,
  setIsCollapsedState = () => {},
}) => {
  const listRef = useRef();

  useEffect(() => {  
    // if(data?.length > 0) { //TO BE LOOKED AGAIN.
    if(!isMobile()) {
      addScrollListClass(scrollRef ? scrollRef?.current : listRef.current);
    }
    // }
  }, [data]);

  const onScrollCaptureHandler = event => {
    const { scrollTop } = event.currentTarget;
    log.debug('Scroll Top', scrollTop)

    if((scrollTop > 0) && !isCollapsedState) {
      setIsCollapsedState(true);
    }

    if((scrollTop == 0) && isCollapsedState) {
      setIsCollapsedState(false);
    }
  };

  return (
    <div ref={scrollRef || listRef} style={{ ...scrollListContainerStyle, ...listStyle, }} onScroll={onScroll} onScrollCapture={onScrollCaptureHandler}>
      {children}
    </div>
  );
};

export default ScrollListItemsContainer;
