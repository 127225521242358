import { ItemTypes } from '../components/Modals/ItemTypes';
import { truncateText } from './stringUtil';

/** convert each word of string to title case */
export const convertToTitleCase = (playerName) => {
  return playerName.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const shortenFullName = (firstName, lastName, limit = 50) =>
  truncateText(`${firstName.charAt(0)}. ${lastName}`, limit);

/** filter and sorting of players for home team and away team */
const sortFilterLowercasePlayers = (players, itemType) => {
  const filteredPlayers = players?.filter(
    (data) => data.playerOnCourtBenchStatus === itemType
  );
  const sortedPlayers = filteredPlayers.sort((a, b) =>
    parseInt(a.playerJerseyNumber) > parseInt(b.playerJerseyNumber)
      ? 1
      : parseInt(b.playerJerseyNumber) > parseInt(a.playerJerseyNumber)
      ? -1
      : 0
  );
  const titleCasePlayers = sortedPlayers.map((player) => {
    const updatedPlayer = {
      ...player.player,
      firstName: convertToTitleCase(player.playerFirstName),
      lastName: convertToTitleCase(player.playerLastName),
    };
    return { ...player, player: updatedPlayer };
  });
  return titleCasePlayers;
};

export const filterGameLineupDataByItemType = (teamLineup, itemType) => {
  const result = teamLineup
    ? sortFilterLowercasePlayers(teamLineup, itemType)
    : [];
  return result;
};

export const getTeamLineupPlayers = (homeTeamLineup) =>
  filterGameLineupDataByItemType(homeTeamLineup, ItemTypes.LINEUP);

export const getTeamOnCourtPlayers = (homeTeamLineup) =>
  filterGameLineupDataByItemType(homeTeamLineup, ItemTypes.COURT);

export const getTeamOnBenchPlayers = (homeTeamLineup) =>
  filterGameLineupDataByItemType(homeTeamLineup, ItemTypes.BENCH);

export const deriveTeamLineupProperties = (homeTeamLineup, awayTeamLineup) => {
  return {
    homeTeamLineupPlayers: getTeamLineupPlayers(homeTeamLineup),
    homeTeamOnCourtPlayers: getTeamOnCourtPlayers(homeTeamLineup),
    homeTeamOnBenchPlayers: getTeamOnBenchPlayers(homeTeamLineup),
    awayTeamLineupPlayers: getTeamLineupPlayers(awayTeamLineup),
    awayTeamOnCourtPlayers: getTeamOnCourtPlayers(awayTeamLineup),
    awayTeamOnBenchPlayers: getTeamOnBenchPlayers(awayTeamLineup),
  };
};

export const transformPlayerLineupData = (player) => {
  const {
    id,
    active,
    birthCity,
    birthState,
    birthCountry,
    dateOfBirth,
    firstName,
    lastName,
    height,
    weight,
    institute,
    jerseyNumber,
    position,
    teamId,
  } = player;

  return {
    teamId,
    playerId: id,
    playerFirstName: firstName,
    playerLastName: lastName,
    playerPosition: position,
    playerJerseyNumber: jerseyNumber,
    playerDateOfBirth: dateOfBirth,
    playerBirthCity: birthCity,
    playerBirthState: birthState,
    playerBirthCountry: birthCountry,
    playerWeight: weight,
    playerHeight: height,
    playerInstitute: institute,
    playerActive: active,
  };
};
