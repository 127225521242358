import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { listLeaguesSortedByUpdatedAt } from '../../api/leaguesService';
import UploadFileImage from '../../assets/LeaguePlaceholder.png';
import logger from '../../logger';
import { generateValidateCallback } from '../../utils/formikToolkit';
import { imageUploadChangeHandler } from '../../utils/imageUploadUtil';
import validation from '../../validation-config/league';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import ImageUploadComponent from '../Inputs/ImageUploadComponent';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import ModalComponent from './ModalComponent';
const log = logger('AddEditLeagueModal', 'info');

const EditMode = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

const AddEditLeagueModal = ({
  editMode,
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  sports,
  initialValues,
  error,
  setError,
}) => {

  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  const validationSchemaCreate = Yup.object({
    image: Yup.mixed().when('null', {
      is: () => validation.image.isRequired.value,
      then: Yup.mixed().required(validation.image.isRequired.message),
    }),
    name: Yup.string()
      .min(validation.name.min.value, validation.name.min.message)
      .max(validation.name.max.value, validation.name.max.message)
      .when('null', {
        is: () => validation.name.isRequired.value,
        then: Yup.string().required(validation.name.isRequired.message),
      }),
    abbreviation: Yup.string()
      .min(
        validation.abbreviation.min.value,
        validation.abbreviation.min.message
      )
      .max(
        validation.abbreviation.max.value,
        validation.abbreviation.max.message
      )
      .when('null', {
        is: () => validation.abbreviation.isRequired.value,
        then: Yup.string().required(validation.abbreviation.isRequired.message),
      }),
    sport: Yup.object({
      id: Yup.string().when('null', {
        is: () => validation.sport.isRequired.value,
        then: Yup.string().required(validation.sport.isRequired.message),
      }),
    }),
    teamSize: Yup.number()
      .min(validation.teamSize.min.value, validation.teamSize.min.message)
      .max(validation.teamSize.max.value, validation.teamSize.max.message)
      .when('null', {
        is: () => validation.teamSize.isRequired.value,
        then: Yup.number().required(validation.teamSize.isRequired.message),
      }),
    numPlayersOnCourt: Yup.number()
      .min(
        validation.numPlayersOnCourt.min.value,
        validation.numPlayersOnCourt.min.message
      )
      .max(
        validation.numPlayersOnCourt.max.value,
        validation.numPlayersOnCourt.max.message
      )
      .when('null', {
        is: () => validation.numPlayersOnCourt.isRequired.value,
        then: Yup.number().required(
          validation.numPlayersOnCourt.isRequired.message
        ),
      })
      .when(['teamSize'], (teamSize, schema) => {
        return teamSize
          ? schema.max(
              Math.min(teamSize, validation.numPlayersOnCourt.max.value),
              `Max ${validation.numPlayersOnCourt.max.value}, less than team size`
            )
          : schema.max(
              validation.numPlayersOnCourt.max.value,
              `Max ${validation.numPlayersOnCourt.max.value}`
            );
      }),
    numTimeOuts: Yup.number()
      .min(validation.numTimeOuts.min.value, validation.numTimeOuts.min.message)
      .max(validation.numTimeOuts.max.value, validation.numTimeOuts.max.message)
      .when('null', {
        is: () => validation.numTimeOuts.isRequired.value,
        then: Yup.number().required(validation.numTimeOuts.isRequired.message),
      }),
    numFoulsPerPlayer: Yup.number()
      .min(
        validation.numFoulsPerPlayer.min.value,
        validation.numFoulsPerPlayer.min.message
      )
      .max(
        validation.numFoulsPerPlayer.max.value,
        validation.numFoulsPerPlayer.max.message
      )
      .when('null', {
        is: () => validation.numFoulsPerPlayer.isRequired.value,
        then: Yup.number().required(
          validation.numFoulsPerPlayer.isRequired.message
        ),
      }),
    numPeriods: Yup.number()
      .oneOf(
        validation.numPeriods.options.value,
        validation.numPeriods.options.message
      )
      .when('null', {
        is: () => validation.numPeriods.isRequired.value,
        then: Yup.number().required(validation.numPeriods.isRequired.message),
      }),
    timePerPeriodInMins: Yup.number()
      .min(
        validation.timePerPeriodInMins.min.value,
        validation.timePerPeriodInMins.min.message
      )
      .max(
        validation.timePerPeriodInMins.max.value,
        validation.timePerPeriodInMins.max.message
      )
      .when('null', {
        is: () => validation.timePerPeriodInMins.isRequired.value,
        then: Yup.number().required(
          validation.timePerPeriodInMins.isRequired.message
        ),
      }),
    overtimeDurationInMins: Yup.number()
      .min(
        validation.overtimeDurationInMins.min.value,
        validation.overtimeDurationInMins.min.message
      )
      .max(
        validation.overtimeDurationInMins.max.value,
        validation.overtimeDurationInMins.max.message
      )
      .when('null', {
        is: () => validation.overtimeDurationInMins.isRequired.value,
        then: Yup.number().required(
          validation.overtimeDurationInMins.isRequired.message
        ),
      }),
    acceptedTerms: Yup.boolean()
      .oneOf([true], validation.acceptedTerms.isRequired.message)
      .when('null', {
        is: () => validation.acceptedTerms.isRequired.value,
        then: Yup.boolean().required(
          validation.acceptedTerms.isRequired.message
        ),
      }),
  });

  const validationSchemaEdit = Yup.object({
    image: Yup.mixed().required('A file is required'),
    name: Yup.string()
      .min(5, 'Must be 5 characters or more')
      .required('League name required'),
    abbreviation: Yup.string()
      .max(5, 'Must be 5 characters or less')
      .required('Abbreviation required'),
  });

  const leagueId = initialValues?.id;

  const duplicateCheck = useCallback(
    async (values, context) => {
      const { name, abbreviation } = values;

      /* If any of these fields are missing, there can't be a duplicate in the DB. */
      if (!name || !abbreviation) return true;

      const queryVars = {
        filter: {
          name: { eq: name },
          abbreviation: { eq: abbreviation },
        },
      };
      /* If this is an existing league, ignore the current league id when checking for duplicates */
      if (editMode === EditMode.EDIT && leagueId) {
        queryVars.filter.id = { ne: leagueId };
      }

      let duplicateDataResponse = await listLeaguesSortedByUpdatedAt(queryVars);

      if (duplicateDataResponse.errors) {
        /** Error checking for duplicate */
        return context.createError({
          message: `${duplicateDataResponse.errors[0].message}.`,
        });
      }

      let items = duplicateDataResponse.items;
      while (items.length === 0 && duplicateDataResponse.nextToken) {
        duplicateDataResponse = await listLeaguesSortedByUpdatedAt({
          ...queryVars,
          nextToken: duplicateDataResponse.nextToken,
        });
        items = [...items, ...duplicateDataResponse.items];
      }

      if (items.length >= 1) {
        /** Duplicate Exists */
        return context.createError({
          message: `The League "${values.name} (${values.abbreviation})" already exists.`,
        });
      }
      
      return true;
    },
    [editMode, leagueId]
  );

  const validationSchemaCreateAsync = validationSchemaCreate.test(
    'duplicate-check',
    'A duplicate league was found.',
    duplicateCheck
  );

  const validationSchemaEditAsync = validationSchemaEdit.test(
    'duplicate-check',
    'A duplicate league was found.',
    duplicateCheck
  );

  function getHeaderTitle(editMode) {
    switch (editMode) {
      case EditMode.CREATE:
        return 'Add League';
      case EditMode.EDIT:
        return 'Edit League';
      case EditMode.VIEW:
        return 'View League';
      default:
        return 'Unknown State';
    }
  }

  function getButtonTitle(editMode) {
    switch (editMode) {
      case EditMode.CREATE:
        return 'Add League';
      case EditMode.EDIT:
        return 'Save Changes';
      case EditMode.VIEW:
        return 'View League';
      default:
        return 'Unknown State';
    }
  }

  function getValidationSchema(editMode, sync) {
    switch (editMode) {
      case EditMode.CREATE:
        return sync ? validationSchemaCreate : validationSchemaCreateAsync;
      case EditMode.EDIT:
        return sync ? validationSchemaEdit : validationSchemaEditAsync;
      case EditMode.VIEW:
      default:
        return validationSchemaCreate;
    }
  }

  function getInitialValues() {
    var values = { ...initialValues };
    if (editMode === EditMode.EDIT) values.acceptedTerms = true;
    return values;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        isInitialValid={getValidationSchema(editMode, true).isValidSync(
          getInitialValues()
        )}
        validate={generateValidateCallback(
          getValidationSchema(editMode, false),
          (msg) => {
            setError(
              `Unable to ${
                editMode === EditMode.CREATE ? 'create' : 'edit'
              } game. ${msg}`
            );
          },
          () => setError()
        )}
        onSubmit={async (values, formik) => {
          try {
            await onSubmit(values);
            formik.resetForm();
            onClose();
          } catch (err) {
            log.error(err);
          }
        }}
        validateOnMount
      >
        {(formik) => (
          <ModalComponent
            headerTitle={getHeaderTitle(editMode)}
            buttonTitle={getButtonTitle(editMode)}
            open={modalOpen}
            setOpen={setModalOpen}
            handleSubmit={formik.handleSubmit}
            modalWidth={850}
            handleClose={() => {
              formik.resetForm();
              onClose();
            }}
            buttonDimmed={!formik.isValid}
            buttonDisabled={editMode === EditMode.EDIT ? !formik.dirty : false}
          >
            {/* Modal Component Children are wrapped in a Form */}
            {/* Root of Grid */}
            <Row className="px-2 pb-2">
              {/* Alert Column */}
              <Col sm={12} className="px-1">
                {error && <Alert variant={'danger'}>{error}</Alert>}
                {!error && editMode === EditMode.EDIT && (
                  <Alert variant={'warning'}>
                    <i>
                      If you need to edit any of the disabled fields please
                      contact your Fast Stats administrator.
                    </i>
                  </Alert>
                )}
              </Col>
              {/* Image Row containing 2 columns of inputs */}
              <Col sm={12} md={3} className="p-1">
                <ImageUploadComponent
                  id="file-upload-component"
                  label="Upload League Image"
                  name="image"
                  imageMaxHeight={'115px'}
                  imageHeight={'115px'}
                  formik={formik}
                  onChange={imageUploadChangeHandler}
                  value={formik.values.image}
                  error={formik.touched.image && formik.errors.image}
                  errorMsg={formik.errors.image}
                  placeHolder={UploadFileImage}
                  // disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              {/* Column with 2 Rows of Inputs */}
              <Col
                sm={12}
                md={9}
                className="p-0"
                style={{ alignSelf: 'flex-end' }}
              >
                {/* Row of League Name and Abbreviation */}
                <Row className="p-0 m-0">
                  <Col sm={12} md={8} className="p-1">
                    <InputFieldComponent
                      required={validation.name.isRequired.value}
                      label="League Name"
                      name="name"
                      type="text"
                      placeholder="National Basketball Association"
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-1">
                    <InputFieldComponent
                      required={validation.abbreviation.isRequired.value}
                      label="Abbreviation"
                      name="abbreviation"
                      type="text"
                      placeholder="NBA"
                    />
                  </Col>
                </Row>
                {/* Row of Sport and Team Size */}
                <Row className="p-0 m-0">
                  <Col sm={12} md={8} className="p-1">
                    <SelectDropdown
                      required={validation.sport.isRequired.value}
                      label="Sport"
                      name="sport.id"
                      disabled={true}
                    >
                      <option value="">Select a Sport</option>
                      {sports?.map((sport) => (
                        <option key={sport.id} value={sport.id}>
                          {sport.name}
                        </option>
                      ))}
                    </SelectDropdown>
                  </Col>
                  <Col sm={12} md={4} className="p-1">
                    <InputFieldComponent
                      required={validation.teamSize.isRequired.value}
                      label="Team Size"
                      name="teamSize"
                      type="number"
                      placeholder="12"
                      disabled={editMode !== EditMode.CREATE}
                    />
                  </Col>
                </Row>
              </Col>
              {/* Row of # Players on Court and Bench, Timeouts and Fouls */}
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  required={validation.numPlayersOnCourt.isRequired.value}
                  label="Players on Court"
                  name="numPlayersOnCourt"
                  type="number"
                  placeholder="5"
                  disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  label="Players on Bench"
                  name="numPlayersOnBench"
                  type="number"
                  value={
                    formik.values.teamSize && formik.values.numPlayersOnCourt
                      ? Math.max(
                          formik.values.teamSize -
                            formik.values.numPlayersOnCourt,
                          0
                        )
                      : ''
                  }
                  placeholder="7"
                  disabled={true}
                />
              </Col>
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  required={validation.numTimeOuts.isRequired.value}
                  label="Timeouts"
                  name="numTimeOuts"
                  type="number"
                  placeholder="3"
                  disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  required={validation.numFoulsPerPlayer.isRequired.value}
                  label="Fouls / Player"
                  name="numFoulsPerPlayer"
                  type="number"
                  placeholder="5"
                  disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              <Col sm={12} md={6} className="p-1">
                <SelectDropdown
                  required={validation.numPeriods.isRequired.value}
                  label="Periods"
                  name="numPeriods"
                  type="number"
                  disabled={editMode !== EditMode.CREATE}
                >
                  <option value="">Select Periods</option>
                  <option value="2">Halves</option>
                  <option value="4">Quarters</option>
                </SelectDropdown>
              </Col>
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  required={validation.timePerPeriodInMins.isRequired.value}
                  label="Period Time (mins)"
                  name="timePerPeriodInMins"
                  type="number"
                  placeholder="12"
                  disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              <Col sm={12} md={3} className="p-1">
                <InputFieldComponent
                  required={validation.overtimeDurationInMins.isRequired.value}
                  label="Overtime (mins)"
                  name="overtimeDurationInMins"
                  type="number"
                  placeholder="5"
                  disabled={editMode !== EditMode.CREATE}
                />
              </Col>
              <Col className="p-1">
                {editMode === EditMode.CREATE && (
                  <InputCheckboxLabel
                    name="acceptedTerms"
                    label="Please check the information you have entered as all or some of the fields will not be editable once the League has been added."
                    disabled={editMode !== EditMode.CREATE}
                  />
                )}
              </Col>
            </Row>
          </ModalComponent>
        )}
      </Formik>
    </>
  );
};

AddEditLeagueModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { AddEditLeagueModal, EditMode };
