import React from 'react';
import { useDrop } from 'react-dnd';
import RosterOutline from './RosterOutline';
import Player from './Player';
import { ItemTypes } from '../ItemTypes';
import { useDeletePlayerMutation } from './queries';
const log = require('../../../logger')('AddPlayersModal');

const Roster = ({
  teamPlayers,
  teamLineupOnCourtOnBench,
  isMultiSelectionActive,
  toggleMultiSelection,
  isPlayerSelected,
  togglePlayerSelection,
  selectedPlayers,
  resetSelection,
  homeTeamGameLineupId,
  awayTeamGameLineupId,
  markPlayerRecentlyMoved,
  isPlayerHighlighted,
  getPlayerHighlightColor,
}) => {
  const deletePlayerMutation = useDeletePlayerMutation(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    markPlayerRecentlyMoved
  );

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.LINEUP,
    drop: async (items) => {
      log.info('Dropping items', items);
      await Promise.all(
        items.map(({ playerId, version }) =>
          deletePlayerMutation.mutateAsync({
            playerId,
            version,
          })
        )
      );
      resetSelection();
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <RosterOutline drop={drop} borderStyle={canDrop ? 'solid' : 'none'}>
      {teamPlayers.length > 0 &&
        teamPlayers?.map((player, idx) => {
          const isPlayerInLineup = teamLineupOnCourtOnBench.find(
            (lineupPLayer) =>
              (lineupPLayer &&
                lineupPLayer.player &&
                lineupPLayer.player.playerId) === (player && player.playerId)
          );
          return (
            !isPlayerInLineup && (
              <Player
                playerId={player.playerId}
                key={player.playerId}
                player={player}
                firstName={player.playerFirstName}
                lastName={player.playerLastName}
                jerseyNumber={player.playerJerseyNumber}
                type={ItemTypes.ROSTER}
                toggleMultiSelection={toggleMultiSelection}
                isSelected={
                  isMultiSelectionActive && isPlayerSelected(player.playerId)
                }
                togglePlayerSelection={(item) => {
                  if (isMultiSelectionActive) togglePlayerSelection(item);
                }}
                selectedPlayers={selectedPlayers}
                resetSelection={resetSelection}
                isMutating={player?.isMutating}
                isHighlighted={!isMultiSelectionActive && isPlayerHighlighted(player.playerId)}
                highlightColor={getPlayerHighlightColor(player.playerId)}
              />
            )
          );
        })}
    </RosterOutline>
  );
};

export default Roster;
