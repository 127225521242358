import React from 'react'; // eslint-disable-line no-unused-vars
import styled from 'styled-components';
import { colors } from '../../../styles/colorsStatsCollector';

export const Panel = styled.div`
  background-color: ${({ theme }) =>
    theme.lightMode ? colors.GRAY[100] : colors.BLACK[400]};
  box-shadow: 0px 0px 20px ${colors.GRAY_TRANSPARENT[500]};
  border-radius: 10px;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  display: flex;
  position: relative;
`;

export default Panel;
