const GamesValidation = {
  gameDateTime: {
    isRequired: { value: true, message: 'Date & Time is required' }
  },
  homeTeamId: {
    isRequired: { value: true, message: 'Home Team is required' }
  },
  awayTeamId: {
    isRequired: { value: true, message: 'Away Team is required' },
    awayTeamDiff: { message: 'Away Team cannot be the same as Home Team' }
  },
  leagueId: {
    isRequired: { value: true, message: 'League is required' },
  },
  seasonId: {
    isRequired: { value: true, message: 'Season is required' }
  },
  venueId: {
    isRequired: { value: true, message: 'Venue is required' }
  },
  acceptedTerms: {
    isRequired: { value: true, message: 'Select the checkbox after reading the statement.' }
  }
};

export default GamesValidation;