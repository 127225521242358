import React from 'react';
import StatsHeader from './StatsHeader';
import StatsRecordItem from './StatsRecordItem';

const PLAYER_TYPE = {
  COURT: 'Court',
  BENCH: 'Bench'
};

const CourtPlayersGameStatsRecord = ({
  courtPlayerGameStats,
}) => {
  return (
    <>
      <StatsHeader
        headerTitle={PLAYER_TYPE.COURT}
      />
      {courtPlayerGameStats.map((gameplayerStats, index) => (
        <StatsRecordItem
          keyText='court_player'
          gamePlayerStats={gameplayerStats}
          recordItemIndex={index}
        />
      ))}
    </>
  );
};

const BenchPlayersGameStatsRecord = ({
  benchPlayerGameStats,
}) => {
  return (
    <>
      <StatsHeader
        headerTitle={PLAYER_TYPE.BENCH}
      />
      {benchPlayerGameStats.map((gameplayerStats, index) => (
        <StatsRecordItem
          keyText='bench_player'
          gamePlayerStats={gameplayerStats}
          recordItemIndex={index}
        />
      ))}
    </>
  );
};

const PlayerStatsRecords = ({
  teamPlayerGameStats = [],
}) => {
  const courtPlayersGameStats = teamPlayerGameStats.filter(({ player }) => player?.lineupPlayer === PLAYER_TYPE.COURT.toLowerCase());
  const benchPlayersGameStats = teamPlayerGameStats.filter(({ player }) => player?.lineupPlayer === PLAYER_TYPE.BENCH.toLowerCase());

  return (
    <>
      <CourtPlayersGameStatsRecord
        courtPlayerGameStats={courtPlayersGameStats}
      />
      <BenchPlayersGameStatsRecord
        benchPlayerGameStats={benchPlayersGameStats}
      />
    </>
  );
};

export default PlayerStatsRecords;