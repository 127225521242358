import styled from 'styled-components';
import { CLOCKMANAGER } from '../data/roles';

export const UserGameRole = styled.div`
  width: ${props => props.width || '47%'};
  height: 30px;
  text-align: center;
  padding: 3px;
  font: normal normal normal 10px/24px Inter;
  color: #FFFFFF;
  margin-bottom: 5px;
  background-color: ${props => props.roleColor};
  border-radius: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
