import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { colors, typeScale, StyledFlexRow } from '../../styles';

const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 14px; /* applied */
  line-height: 16px;
  color: ${colors.GRAY[450]};
`;

const Dropdown = ({
  id,
  label,
  value,
  onBlur,
  onChange,
  required,
  options,
  dropDownField,
  width,
  marginLeft = 0,
  marginRight = 0,
  errorMsg,
  error,
}) => {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: 'black',
    }),
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: error ? colors.RED[300] : colors.WHITE[100],
      marginLeft: marginLeft,
      marginRight: marginRight,
      border: `1px solid ${error ? colors.RED[200] : colors.GRAY[450]}`,
      '&:hover': {
        border: '1px solid black',
      },
    }),
    // singleValue: provided => ({
    //   ...provided,
    //   color: 'white'
    // })
  };

  const useStyles = makeStyles({
    errorMsgStyle: {
      color: colors.RED[100],
      fontSize: typeScale.SMEDIUM.fontSize,
    },
  });

  const classes = useStyles();

  return (
    <div style={width ? width : null}>
      <StyledLabel htmlFor="venue">
        {required ? `${label}*` : label}
      </StyledLabel>
      <Select
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles}
        id={id}
        options={options}
        getOptionLabel={(option) =>
          dropDownField ? option[dropDownField] : option['name']
        }
        getOptionValue={(option) =>
          dropDownField ? option[dropDownField] : option['name']
        }
        onBlur={onBlur}
        onChange={(value) => onChange(value)}
        value={options && options[value]}
        error={error}
        disabled={false}
      />

      {error && (
        <StyledFlexRow>
          <span className={classes.errorMsgStyle}>{errorMsg}</span>
        </StyledFlexRow>
      )}
    </div>
  );
};

export default Dropdown;
