/**
 * Color palette for new ScoreKeeper
 */
export const colors = {
  WHITE: {
    100: '#FFFFFF',
    200: '#F5F5F5',
  },
  BLACK: {
    // Black is numbered in increasing brightness
    100: '#000000',
    150: '#030303',
    200: '#11263C',
    300: '#11273D',
    400: '#1C1C1C',
    500: '#1D1D1D',
    600: '#222222',
    700: '#242424',
    800: '#2A2A2A',
    900: '#2E2E2E',
  },
  RED: {
    100: '#FF0000',
    200: '#DC0000',
    300: '#6E0000',
    400: '#FF5C5C',
  },
  GRAY: {
    100: '#F2F3F7',
    300: '#ECECEC',
    400: '#E3E3E3',
    500: '#E4E4E4',
    600: '#E1E2EC',
    700: '#D5D5D5',
    800: '#D4D4D4',
    900: '#CFCFCF',
    950: '#C4C4C4',
    975: '#B7B7B7',
    1000: '#A5A5A5',
    1050: '#9E9E9E',
    1075: '#97989E',
    1100: '#8B8B8B',
    1200: '#858585',
    1300: '#848484',
    1400: '#828282',
    1500: '#818181',
    1600: '#707070',
    1650: '#70707026',
    1700: '#5A5A5A',
    1800: '#464646',
    2300: '#444444',
    2400: '#393939',
  },
  GRAY_TRANSPARENT: {
    100: '#F2F3F74A',
    200: '#000000C4',
    300: '#0000004D',
    400: '#00000029',
    500: '#00000026',
    600: '#0000000D',
    700: 'rgba(112, 112, 112, 0.25)',
    750: 'rgba(112, 112, 112, 0.3)',
    800: 'rgba(255, 255, 255, 0.35)',
    900: 'rgba(236, 236, 236, 0.16)',
  },
  GREEN: {
    100: '#DDF2E9',
    200: '#05EA3B',
    300: '#0B9E22',
    400: '#008248',
    500: '#59CC1F',
  },
  BLUE: {
    100: '#438EFD',
    200: '#26A9E1',
    300: '#3179E4',
    400: '#7BAFFF',
    500: '#233249',
  },
  PURPLE: {
    100: '#552583',
    200: '#BB006B',
  },
  ORANGE: {
    100: '#FD7643',
  },
  YELLOW: {
    100: '#FDB927',
    200: '#FFDD00',
  },
};

export const gradients = {
  BLACK: `linear-gradient(180deg, ${colors.GRAY_TRANSPARENT[100]} 0%, #00000000 100%)`,
  GRAY: `linear-gradient(180deg, #F2F3F7A3 0%, #00000091 100%)`,
  LIGHT_GRAY: `linear-gradient(180deg, #F2F3F74A 0%, #00000000 100%)`,
  BLUE: 'linear-gradient(180deg, #579BFF 0%, #154895 100%)',
  DARKER_BLUE: 'linear-gradient(180deg, #448FFF, #224880)',
  RED: `linear-gradient(180deg, #FF4444 0%, #80223CC4 100%)`,
  GREEN: `linear-gradient(180deg, #0DB827 0%, #064F11 100%)`,
}