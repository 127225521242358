import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-color: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.borderColor};
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  position: relative;
`;

const HorizontalGrid = ({ children, bgColor, borderColor, height, width }) => {
  return (
    <Grid
      bgColor={bgColor}
      borderColor={borderColor}
      height={height}
      width={width}
    >
      {children}
    </Grid>
  );
};

/* Default values from the Venue Implementation */
HorizontalGrid.defaultProps = {
  // height: '178px',
  // width: '46%',
};

export default HorizontalGrid;
