import HomePage from '../../pages/HomePage';
// import SportsPage from '../../pages/SportsPage';
import LeaguesPage from '../../pages/LeaguesPage';
import PlayersPage from '../../pages/PlayersPage';
import TeamsPage from '../../pages/TeamsPage';
import SeasonsPage from '../../pages/SeasonsPage';
import VenuesPage from '../../pages/VenuesPage';
import GamesPage from '../../pages/GamesPage';
import UsersPage from '../../pages/UsersPage';

import HomeIcon from '../../assets/svg/Home.svg';
import GamesIcon from '../../assets/svg/Games.svg';
import LeagueIcon from '../../assets/svg/League.svg';
import PlayersIcon from '../../assets/svg/Players.svg';
import SeasonsIcon from '../../assets/svg/Season.svg';
import TeamIcon from '../../assets/svg/Teams.svg';
import VenuesIcon from '../../assets/svg/Venue.svg';
import UserIcon from '../../assets/svg/Users.svg';

// Routes used for sidebar nav
const Routes = [
  {
    label: 'Home',
    iconImgSrc: HomeIcon,
    pathName: '/admin/home',
    component: HomePage,
  },
  {
    label: 'Leagues',
    iconImgSrc: LeagueIcon,
    pathName: '/admin/leagues',
    component: LeaguesPage,
  },
  {
    label: 'Seasons',
    iconImgSrc: SeasonsIcon,
    pathName: '/admin/seasons',
    component: SeasonsPage,
  },
  {
    label: 'Teams',
    iconImgSrc: TeamIcon,
    pathName: '/admin/teams',
    component: TeamsPage,
  },
  {
    label: 'Players',
    iconImgSrc: PlayersIcon,
    pathName: '/admin/players',
    component: PlayersPage,
  },
  {
    label: 'Venues',
    iconImgSrc: VenuesIcon,
    pathName: '/admin/venues',
    component: VenuesPage,
  },
  {
    label: 'Games',
    iconImgSrc: GamesIcon,
    pathName: '/admin/games',
    component: GamesPage,
  },
  {
    label: 'Users',
    iconImgSrc: UserIcon,
    pathName: '/admin/users',
    component: UsersPage,
  },
  // { label: 'Storybook', pathName: '/admin/storybook', component: StorybookPage },
];

export default Routes;
