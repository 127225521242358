import React from 'react';
import {
    SearchMenuDiv,
    SearchMenuMainCriteria,
    SearchMenuSubCriteria,
    SearchMenuSubCriteriaDark,
    getHighlightedText
} from '../../utils/searchUtils';

const displaySearchMenuCriteria = criteriaText => {
  return criteriaText.map(({option, text, separator}) => getHighlightedText(`${option} ${separator || ''}`, text));
};

const SearchMenuComponent = ({
  mainCriteriaText,
  subCriteriaText,
  subCriteriaDarkText,
}) => {
  return (
    <SearchMenuDiv>
      {mainCriteriaText && 
      <SearchMenuMainCriteria>
        {displaySearchMenuCriteria(mainCriteriaText)}
      </SearchMenuMainCriteria>
      }
      {subCriteriaDarkText && 
        <SearchMenuSubCriteriaDark>
          {displaySearchMenuCriteria(subCriteriaDarkText)}
        </SearchMenuSubCriteriaDark>
      }
      {subCriteriaText && 
        <SearchMenuSubCriteria>
          {displaySearchMenuCriteria(subCriteriaText)}
        </SearchMenuSubCriteria>
      }
    </SearchMenuDiv>
  );
};

export default SearchMenuComponent;