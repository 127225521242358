import DoublyLinkedList from './doubleLinkedList';
import {
  addBreadcrumb,
  removeBreadcrumb,
  clearBreadcrumb,
  changeDisplayType,
  setEventStartTime,
  setActiveEventButton,
  playerSelectEvent,
  playerSecondarySelectEvent,
  setUserInstructions,
  locationSelection,
  setFreeThrowMadeCount,
  setFreeThrowMissCount,
  setFreeThrowViolationCount,
} from './scoreKeeperEventSlice';
import GameEvents, { createGameEvent } from './gameEvents';
import InputEvents, { getBreadcrumbForEvent } from './inputEvents';
import store from '../../../store.js';
const localStorage = window.localStorage;
const log = require('../../../logger')('StateManager', 'debug');

class StateManager {
  constructor(schema, dispatch) {
    this.dispatch = dispatch;
    this.routines = schema.routines;
    this.subroutines = schema.subroutines;
    /** TODO: "Compile" the full state machine by replacing subroutine
     * references with the full subroutine object */
    this.linkedList = new DoublyLinkedList(this.routines);
    this.onNewStateEntered();
  }

  /** Returns the current state object, with 'type' and 'on' callbacks */
  getCurrentState() {
    return this.linkedList.tail.value;
  }

  /** Returns the values of the 'events' this state has callbacks for */
  getSubscribedEvents() {
    return Object.keys(this.getCurrentState()?.on);
  }

  /** Returns true if the input 'event' is included in the subscribed events */
  doesHandleEvent(event) {
    return this.getSubscribedEvents().includes(event);
  }

  /** Handle an event change if the current state is subscribed to the event */
  onEvent(state) {
    /** Handle Back button push, navigating up the list */
    if (state.value === InputEvents.BACK) {
      this.onBack();
    }
    /** Handle Cancel button push, navigating up the list */
    if (state.value === InputEvents.CANCEL) {
      this.onCancel();
    }
    if (this.doesHandleEvent(state.value)) {
      this.handleEvent(state);
    }
  }

  /** Transition to the next state for the subscribed event */
  handleEvent(state) {
    log.debug('Handling Event', state);

    /** If this event starts a new game flow store current game time*/
    if (this.linkedList.length === 1) {
      this.storeEventStartTime();
      this.dispatch(setActiveEventButton(state.value));
    }

    /** Add the next node to the state linked list */
    const nextNode = this.getCurrentState().on[state.value];
    this.linkedList.append(nextNode);

    /** Update breadcrumb list */
    if (getBreadcrumbForEvent(state))
      this.dispatch(addBreadcrumb(getBreadcrumbForEvent(state)));

    /** Enter new state */
    this.onNewStateEntered();
  }

  storeEventStartTime() {
    const eventStartTime = {
      minutes: parseInt(localStorage.getItem('minutes')),
      seconds: parseInt(localStorage.getItem('seconds')),
      quarter: parseInt(localStorage.getItem('quarter')),
      gameOvertimeNumber: parseInt(localStorage.getItem('gameOvertimeNumber')),
    };
    log.debug('Storing Event StartTime', eventStartTime);
    this.dispatch(setEventStartTime(eventStartTime));
  }

  populateEvents() {
    /** Create an array to store the event objects */
    this.linkedList.tail.value.eventObjects = [];
    /** If the previous state has any eventObject, aggregate them all */
    if (this.linkedList.tail?.previous?.value?.eventObjects) {
      this.linkedList.tail.value.eventObjects.push(
        ...this.linkedList.tail.previous.value.eventObjects
      );
    }

    /** Check is the state has an events that need to be populated */
    if (this.getCurrentState().events) {
      for (
        let index = 0;
        index < this.getCurrentState().events.length;
        index++
      ) {
        const event = this.getCurrentState().events[index];
        /** Create an event object based off of each event enum in the list */
        this.linkedList.tail.value.eventObjects.push({ name: event });
      }
    }
  }

  async sendAllGameEvents() {
    var relatedEventId = null;
    const events = this.linkedList.tail.value.eventObjects;
    const state = store.getState().scoreKeeperEvents;
    for (let index = 0; index < events.length; index++) {
      const eventType = events[index].name;
      relatedEventId = await createGameEvent(
        eventType,
        state.gameId,
        state.possessionId,
        state.player,
        state.playerSecondary,
        relatedEventId,
        state.eventStartTime,
        state.location,
        state.user.statcollFirstName,
        state.user.statcollLastName,
        state.user.statcollSub,
        state.user.statcollEmail,
        state.user.eventCreatorRole,
        index === events.length - 1
      );
    }
  }

  /** Enter a new state and update the displayed input */
  onNewStateEntered() {
    /** Keep track of ScoreKeeper events */
    this.populateEvents();

    /** Check if we reached the final node in the game event flow, else change the display type for the curren node */
    if (!this.getCurrentState()?.on) {
      /** End of Conversation Flow */
      log.debug('Events to send', this.linkedList.tail.value.eventObjects);
      this.onEndOfConversationFlow();
    } else {
      /** New State, update the display type */
      this.dispatch(changeDisplayType(this.getCurrentState().type));

      /** Set user instructions */
      this.dispatch(setUserInstructions(this.getCurrentState().text));

      /** Update current free throw progress (if applicable) */
      this.updateFreeThrowTracking();

      log.debug('New State', this.getCurrentState());
    }
  }

  /**
   * This logic iterates through all of the events stored up until
   * this state and determines the current free throw tracking state
   * (i.e. number of made/missed shots) and updates the redux state
   */
  updateFreeThrowTracking() {
    var freeThrowsMade = 0;
    var freeThrowsMissed = 0;
    var freeThrowViolations = 0;
    var events = this.linkedList.tail.value.eventObjects;
    for (let index = 0; index < events.length; index++) {
      const event = events[index];
      if (event.name === GameEvents.FREE_THROW_MADE) {
        freeThrowsMade++;
      } else if (event.name === GameEvents.FREE_THROW_MISS) {
        freeThrowsMissed++;
      } else if (event.name === GameEvents.VIOLATION_FREE_THROW){
        freeThrowViolations++;
      }
    }
    this.dispatch(setFreeThrowMadeCount(freeThrowsMade));
    this.dispatch(setFreeThrowMissCount(freeThrowsMissed));
    this.dispatch(setFreeThrowViolationCount(freeThrowViolations));
  }

  /**
   * Conversation Flow is finished
   * 1. Send any events from the flow
   * 2. Reset to the root action node
   */
  onEndOfConversationFlow() {
    log.debug('End of GameEvent Flow');

    /** Send Events */
    this.sendAllGameEvents();

    /** Return to default state */
    this.linkedList = new DoublyLinkedList(this.routines);
    this.onNewStateEntered();
    this.resetActiveStateVariables();
  }

  /** Traverse back  */
  onBack() {
    log.debug('Navigating Back');
    if (this.linkedList.length > 1) {
      this.linkedList.remove(this.linkedList.length - 1);
      this.dispatch(removeBreadcrumb());
    }
    if (this.linkedList.length === 1) {
      this.resetActiveStateVariables();
    }
    this.onNewStateEntered();
  }

  /** Traverse back  */
  onCancel() {
    log.debug('Cancelling GameEvent Flow');
    while (this.linkedList.length > 1) {
      this.linkedList.remove(this.linkedList.length - 1);
    }
    this.resetActiveStateVariables();
    this.onNewStateEntered();
  }

  /**
   * Reset "active" state elements that we're keeping track of while within a flow.
   * This should be used when ending a flow, including when cancelling.
   */
  resetActiveStateVariables() {
    this.dispatch(clearBreadcrumb());
    this.dispatch(setActiveEventButton());
    this.dispatch(playerSelectEvent());
    this.dispatch(playerSecondarySelectEvent());
    this.dispatch(locationSelection());
    this.dispatch(setFreeThrowMadeCount(0));
    this.dispatch(setFreeThrowMissCount(0));
  }
}

export default StateManager;
