import { useInfiniteQuery } from 'react-query';
import { LIST_PAGINATION_LIMIT } from '../utils/constantsUtils';
import { useItemsFromPages } from '../utils/reactQueryToolkit';
import {
  getVenueImageProfile,
  listVenuesSortedByUpdatedAt,
} from './venuesService';

export const venuesKeys = {
  all: ['venues'],
  filter: (filter) => [...venuesKeys.all, { filter }],
};

export const useVenuesQuery = ({ filter, ...options } = {}) => {
  const query = useInfiniteQuery({
    ...options,
    queryKey: venuesKeys.filter(filter),
    queryFn: async ({ pageParam }) => {
      let queryVars = {
        limit: LIST_PAGINATION_LIMIT.SMALL,
        nextToken: pageParam,
      };

      if (filter?.length > 0) {
        const filterLowerCase = filter.toLowerCase();
        queryVars = {
          filter: {
            or: [
              { searchName: { contains: filterLowerCase } },
              { searchCity: { contains: filterLowerCase } },
              { searchState: { contains: filterLowerCase } },
              { zipcode: { contains: filter } },
            ],
          },
          nextToken: pageParam,
        };
      }

      const venueItems = await listVenuesSortedByUpdatedAt(queryVars);

      /* Get the Image for each league */
      const venuesWithImages = await Promise.all(
        venueItems.items?.map(async (venue) => {
          venue.image = await getVenueImageProfile(venue.id);
          return venue;
        })
      );

      return {
        ...venueItems,
        items: venuesWithImages,
      };
    },
    getNextPageParam: (lastPage) => lastPage?.nextToken,
  });

  /* Aggregate venues from all query pages */
  const data = useItemsFromPages(query.data?.pages);

  return {
    ...query,
    data,
  };
};
