import React, { useState, useEffect, } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ModalComponent from './ModalComponent';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import InputCheckboxLabel from '../Checkbox/InputCheckboxLabel';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import InputSwitchComponent from '../Checkbox/InputSwitchComponent';
import ButtonElement from '../Buttons/ButtonElement';
import { Row, Col, Alert } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';
import { USER_ROLES } from '../../utils/userUtil';
import DownChevron from '../../assets/downArrow.png';
import UpChevron from '../../assets/upArrow.png';
import { colors } from '../../styles';
import { buttonThemes } from '../../styles/themes';
import validation from '../../validation-config/user';

import 'react-tagsinput/react-tagsinput.css';

const USER_ROLE_IDS = {
  [USER_ROLES.ADMIN.LABEL]: 'user_role_1',
  [USER_ROLES.CLOCK_MANAGER.LABEL]: 'user_role_2',
  [USER_ROLES.SCORE_KEEPER.LABEL]: 'user_role_3',
  [USER_ROLES.COACH.LABEL]: 'user_role_4',
};

const USER_ROLE_LIST = [
  {
    id: 'user_role_1',
    label: USER_ROLES.ADMIN.LABEL,
    value: USER_ROLES.ADMIN.VALUE,
    checked: false,
    disabled: false,
  },
  {
    id: 'user_role_2',
    label: USER_ROLES.CLOCK_MANAGER.LABEL,
    value: USER_ROLES.CLOCK_MANAGER.VALUE,
    checked: false,
    disabled: false,
  },
  {
    id: 'user_role_3',
    label: USER_ROLES.SCORE_KEEPER.LABEL,
    value: USER_ROLES.SCORE_KEEPER.VALUE,
    checked: false,
    disabled: false,
  },
  {
    id: 'user_role_4',
    label: USER_ROLES.COACH.LABEL,
    value: USER_ROLES.COACH.VALUE,
    checked: false,
    disabled: false,
  },
];

const UserRolesInput = ({
  tags, 
  rolesList, 
  isRolesDisplayed, 
  showOrHideRolesListSelection, 
  changeSelectedTags, 
  checkUserRoleItem, 
  setSelectedTags, 
  isNewUser, 
  formik, 
  isEditingUserProfile, 
  isCoachRoleSelected,
}) => {
  useEffect(() => {
    if(formik.values.roles?.length > 0) {
      formik.setFieldError('role', '');
    }
  }, [formik.values.roles]);

  useEffect(() => {
    if(!isNewUser && !formik.values.active && isRolesDisplayed) {
      showOrHideRolesListSelection(null, isRolesDisplayed, formik)
    }
  }, [formik.values.active]);

  const isUserRolesSelectionDisabled = () => (
    (!isNewUser && !formik.values.active) || 
    isEditingUserProfile || 
    isCoachRoleSelected
  );

  return (
    <Row className="mb-3">
      <Col xl={12} md={12} xs={12}>
        <label style={{ font: 'normal normal normal 16px/24px Inter', color: (formik.touched.role && formik.errors.role) ? '#DC3545' : '#616161', }}>
          Assign Role(s)*
        </label>
      </Col>
      <Col xl={12} md={12} xs={12} className="p-2">
        <div 
          style={{ width: '100%', height: 'auto', position: 'relative', display: 'flex', flexDirection: 'row', marginBottom: 12, backgroundColor: (isUserRolesSelectionDisabled()) ? '#E9ECEF' : 'transparent' }}
          onClick={event => event.stopPropagation()}
        >
          <div 
            style={{ width: '100%', height: 48, paddingLeft: 5, paddingTop: 5, border: `1px solid ${(formik.touched.role && formik.errors.role) ? '#DC3545' : '#cccccc'}`, }}
            onClick={event => ((isNewUser || formik.values.active) && !isEditingUserProfile) && showOrHideRolesListSelection(event, isRolesDisplayed, formik)}
          >
            {tags.length === 0 
              ? <span style={{ font: 'normal normal normal 16px/24px Inter', color: '#959595', position:'absolute', top: 10, }}>Select one or more roles</span>
              : <TagsInput
                  name="role"
                  value={tags}
                  onChange={(tags, changed, index) => changeSelectedTags(formik, rolesList, tags, changed, index)}
                  maxTags={3} 
                  disabled={isUserRolesSelectionDisabled()}
                  renderInput={() => {}}
                  className={(isUserRolesSelectionDisabled()) ? 'user-active-tag-input' : 'react-tagsinput'}
                  tagProps={{ 
                    className: `user-role-react-tagsinput-tag ${(isUserRolesSelectionDisabled()) ? 'user-role-inactive-background-color' : 'user-role-acitve-background-color'}`,
                    classNameRemove: 'user-role-react-tagsinput-remove'
                  }}
                  onlyUnique
                />
            }
            {formik.touched.role && 
            <div style={{ width: '100%', height: 'auto', color: '#DC3545', position: 'absolute', top: 50, fontSize: 14, }}>
              {formik.errors.role}
            </div>
            }
          </div>
          {((isNewUser || formik.values.active) && !isEditingUserProfile) && 
            <img 
              style={{ position: 'absolute', top: 10, right: 15, zIndex: 20, padding: 10, }} 
              src={isRolesDisplayed ? UpChevron : DownChevron} 
              alt=""
              onClick={event => showOrHideRolesListSelection(event, isRolesDisplayed, formik)}
            />
          }
          {isRolesDisplayed && 
          <div style={{ 
            width: '100%', 
            position: 'absolute', 
            top: 48, 
            zIndex: 20, 
            backgroundColor: 'white', 
            border: '1px solid #cccccc', 
            paddingTop: 5,
            paddingLeft: 10, 
            paddingRight: 5,
          }}>
            <>
              <div style={{ width: '100%', height: 'auto', }}>
                {rolesList?.map(({ id, label, checked, disabled }) => {
                  return (
                    <div 
                      key={id}
                      style={{ width: '95%', height: 30, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginBottom: 5, backgroundColor: checked ? '#D8E6FF' : colors.WHITE[100], }}
                      onClick={event => event.stopPropagation()}
                    >
                      <CheckboxWithLabel
                        label={label}
                        checked={checked}
                        disabled={(!isNewUser && label === USER_ROLES.COACH.LABEL) ? true : disabled}
                        name={id}
                        onChange={(event) => checkUserRoleItem(event, rolesList, formik)}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 7 }}>
                <ButtonElement
                  size="large"
                  label="Done"
                  minWidth="100px"
                  height="35px"
                  theme={{ ...buttonThemes.WHITE, border: `1px solid ${colors.BLACK[300]}`, }}
                  onClick={event => {
                    setSelectedTags(rolesList, formik);
                    event.stopPropagation();
                  }}
                />
              </div>
            </>
          </div>}
        </div>
      </Col>
    </Row>
  );
};

const AddEditUserModal = ({
  isNewUser,
  userData,
  originalRoles,
  modalOpen,
  setModalOpen,
  onSubmit,
  onClose,
  onNext,
  error,
  isEditingUserProfile = false,
  isEditingUserRoles = false,
}) => {
  const [tags, setTags] = useState([]);
  const [isRolesDisplayed, setIsRolesDisplayed] = useState(false);
  const [rolesList, setRolesList] = useState(USER_ROLE_LIST);
  const [isNextButton, setIsNextButton] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);

  useEffect(() => {
    if(!modalOpen) {
      setTags([]);
      setRolesList(USER_ROLE_LIST.map(role => {role.disabled = false; role.checked = false; return role;}));
      setIsRolesDisplayed(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    if(userData?.role?.length > 0) {
      if((tags.length > 0) && (tags.join(',') !== getRoleLabels(originalRoles).join(','))) {
        if(userData?.games.items.length > 0) {
          setIsNextButton(true);
        }
        setIsSwitchDisabled(true);
      }
      else {
        setIsNextButton(false);
        setIsSwitchDisabled(false);
      }
    }
  }, [tags]);

  useEffect(() => {
    if(userData.role.length > 0) {
      const selectedTagLabels = getRoleLabels(userData.role);
      const updatedRoleList = USER_ROLE_LIST.map(role => {
        if((role.label === USER_ROLES.ADMIN.LABEL) && (selectedTagLabels.includes(USER_ROLES.ADMIN.LABEL))) {
          role.disabled = false;
        }
        else if((role.label === USER_ROLES.CLOCK_MANAGER.LABEL || role.label === USER_ROLES.SCORE_KEEPER.LABEL) && 
                (selectedTagLabels.includes(USER_ROLES.CLOCK_MANAGER.LABEL) || selectedTagLabels.includes(USER_ROLES.SCORE_KEEPER.LABEL))) {
          role.disabled = false;
        }
        else {
          role.disabled = true;
        }
        role.checked = selectedTagLabels.includes(role.label);
        return role;
      });
      setTags([...selectedTagLabels]);
      setRolesList([...updatedRoleList]);
    }
  }, [userData.role, modalOpen]);

  const getRoleLabels = roles => roles.split(',').map(tagValue => USER_ROLE_LIST.find(({ value }) => value === tagValue)?.label);

  const showOrHideRolesListSelection = (event, isRolesDisplayed, formik) => {
    if(isRolesDisplayed) {
      formik.setFieldTouched('role', isRolesDisplayed && tags.length === 0);
    }
    setIsRolesDisplayed(!isRolesDisplayed);
    event?.stopPropagation();
  };

  const checkUserRoleItem = (event, rolesList, formik) => {
    const selectedRoleId = event.target.name;
    let updatedRolesList = rolesList.map((role) => {
      return role.id === event.target.name
        ? { ...role, checked: !role.checked }
        : { ...role };
    });

    updatedRolesList = updatedRolesList.map((role, index, rolesArray) => {
      if(selectedRoleId === USER_ROLE_IDS[USER_ROLES.ADMIN.LABEL]) {
        if(role.label !== USER_ROLES.ADMIN.LABEL) {
          role.disabled = rolesArray[0].checked;
        }
      }
      else if(selectedRoleId === USER_ROLE_IDS[USER_ROLES.COACH.LABEL]) {
        if(role.label !== USER_ROLES.COACH.LABEL) {
          role.disabled = rolesArray[3].checked;
        }
      }
      else if((selectedRoleId === USER_ROLE_IDS[USER_ROLES.CLOCK_MANAGER.LABEL]) || (selectedRoleId === USER_ROLE_IDS[USER_ROLES.SCORE_KEEPER.LABEL])) {
        if(role.label === USER_ROLES.ADMIN.LABEL) {
          rolesArray[0].disabled = (rolesArray[1].checked || rolesArray[2].checked);
          rolesArray[3].disabled = (rolesArray[1].checked || rolesArray[2].checked);
        }
      }
      return role;
    });

    setTags(updatedRolesList.filter(role => role.checked).map(role => role.label));
    const isAnyRolesSelected = updatedRolesList.some(({checked}) => checked);
    formik.setFieldTouched('role', !isAnyRolesSelected);
    formik.setFieldValue('role', updatedRolesList.filter(role => role.checked).map(role => role.value).join(','));
    setRolesList([...updatedRolesList]);
  };

  const setSelectedTags = (rolesList, formik) => {
    const selectedRoleLabels = rolesList.filter(role => role.checked)
                                        .map(role => role.label);
    const selectedRoleValues = rolesList.filter(role => role.checked)
                                        .map(role => role.value)
                                        .join(',');
    setTags(selectedRoleLabels);
    setIsRolesDisplayed(false);
    formik.setFieldValue('role', selectedRoleValues);
  };

  const changeSelectedTags = (formik, rolesList, tags, changed, index) => {
    const selectedRoleTag = changed[0];
    let updatedRolesList = rolesList.map(role => {
      if(selectedRoleTag === role.label) {
        role.checked = false;
      }
      return role;
    });

    updatedRolesList = updatedRolesList.map((role, index, rolesArray) => {
      if((selectedRoleTag === USER_ROLES.ADMIN.LABEL) || (selectedRoleTag === USER_ROLES.COACH.LABEL)) {
        role.disabled = (rolesArray[0].checked || rolesArray[3].checked);
      }
      else if((selectedRoleTag === USER_ROLES.CLOCK_MANAGER.LABEL) || (selectedRoleTag === USER_ROLES.SCORE_KEEPER.LABEL)) {
        if(role.label === USER_ROLES.ADMIN.LABEL) {
          rolesArray[0].disabled = (rolesArray[1].checked || rolesArray[2].checked);
          rolesArray[3].disabled = (rolesArray[1].checked || rolesArray[2].checked);
        }
      }
      return role;
    });

    setRolesList([...updatedRolesList]);
    formik.setFieldTouched('role', tags.length === 0);
    setSelectedTags(updatedRolesList, formik);
  };

  const userSchemaValidation = Yup.object({
    firstName: Yup.string().when('null', {
      is: () => validation.firstName.isRequired.value,
      then: Yup.string().required(validation.firstName.isRequired.message),
    }),
    lastName: Yup.string().when('null', {
      is: () => validation.lastName.isRequired.value,
      then: Yup.string().required(validation.lastName.isRequired.message),
    }),
    email: Yup.string()
      .matches(validation.email.regex.value, validation.email.regex.message)
      .when('null', {
        is: () => validation.email.isRequired.value,
        then: Yup.string().required(validation.email.isRequired.message),
    }),
    role: Yup.string().when('null', {
      is: () => validation.role.isRequired.value,
      then: Yup.string().required(validation.role.isRequired.message),
    }),
    acceptedTerms: Yup.boolean()
      .oneOf([true], validation.acceptedTerms.isRequired.message)
      .when('null', {
        is: () => validation.acceptedTerms.isRequired.value,
        then: Yup.boolean().required(validation.acceptedTerms.isRequired.message),
    }),
  });

  const showButtonTitle = (formik) => {
    if(isEditingUserRoles === true) {
      return (
        (userData?.games?.items.length === 0) || 
        (userData?.games?.items.every(({ _deleted }) => _deleted === true) || !formik.values.active) 
          ? 'Save Changes' 
          : null
      );
    }
    else if(isEditingUserProfile === true) {
      return 'Save Changes';
    }
  };

  const getInitialValues = () => {
    const emptyValues = {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      active: true,
      acceptedTerms: false,
    };

    if (!isNewUser) {
      userData.acceptedTerms = true;
    }

    return isNewUser ? emptyValues : userData;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={userSchemaValidation}
      isInitialValid={userSchemaValidation.isValidSync(getInitialValues())}
      enableReinitialize
    >
      {formik => (
        <ModalComponent
          headerTitle={isNewUser ? 'Add User' : 'Edit User'}
          buttonTitle={isNewUser ? 'Add User' : showButtonTitle(formik)}
          open={modalOpen}
          setOpen={setModalOpen}
          handleSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(formik);
            if (formik.isValid) {
              formik.setFieldTouched('role', false);
              onSubmit(formik.values, formik.resetForm);
            }
          }}
          modalWidth={560}
          handleClose={() => {
            formik.resetForm();
            onClose();
          }}
          buttonDimmed={!formik.isValid}
          buttonDisabled={!isNewUser && !formik.dirty && !isNextButton}
          showNextButton={isNextButton}
          nextHandler={() => onNext(formik.values, formik.resetForm)}
        >
          {/* Modal Component Children are wrapped in a Form */}
          {/* Alert Column */}
          <Col sm={12} className="px-1">
            {/* Edit Warning */}
            {!isNewUser && (
              <Alert variant={'warning'}>
                <i>
                  If you need to edit any of the disabled fields please
                  contact your Fast Stats administrator.
                </i>
              </Alert>
            )}
            {/* Error Message */}
            {error && <Alert variant={'danger'}>{error}</Alert>}
          </Col>

          <Row className="mb-3">
            <Col xl={6} md={6} xs={6}>
              <InputFieldComponent
                name="firstName"
                label="First Name"
                type="text"
                required={true}
                disabled={(!isNewUser && !formik.values.active) || isEditingUserRoles}
              />
            </Col>
            <Col xl={6} md={6} xs={6}>
              <InputFieldComponent
                name="lastName"
                label="Last Name"
                type="text"
                required={true}
                disabled={(!isNewUser && !formik.values.active) || isEditingUserRoles}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={6} md={6} xs={6}>
              <InputFieldComponent
                name="email"
                label="Email"
                type="text"
                required={true}
                disabled={!isNewUser}
              />
            </Col>
            <Col xl={6} md={6} xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <InputSwitchComponent
                name="active"
                switchLabel="User Active?"
                labelPositive={'Yes'}
                labelNegative={'No'}
                marginLeft={15}
                disabled={isSwitchDisabled || isEditingUserRoles}
              />
            </Col>
          </Row>

          <UserRolesInput
            tags={tags} 
            rolesList={rolesList} 
            isRolesDisplayed={isRolesDisplayed} 
            showOrHideRolesListSelection={showOrHideRolesListSelection} 
            changeSelectedTags={changeSelectedTags} 
            checkUserRoleItem={checkUserRoleItem} 
            setSelectedTags={setSelectedTags} 
            isNewUser={isNewUser}
            formik={formik} 
            isEditingUserProfile={isEditingUserProfile}
            isCoachRoleSelected={userData.role?.includes(USER_ROLES.COACH.VALUE)}
          />

          {isNewUser && 
          <Row className="mb-3" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Col xl={12} md={12} xs={12}>
              <InputCheckboxLabel
                label="Please check the information you have entered as all or some of the fields will not be editable once the User has been added."
                name="acceptedTerms"
              />
            </Col>
          </Row>}
        </ModalComponent>
      )}
    </Formik>
  );
};

export default AddEditUserModal;