
export const modalContainerStyle = { 
  width: '100%', 
  padding: 0, 
  position: 'relative', 
};
  
export const modalWarningStyle = { 
  width: '100%', 
  height: 50, 
  position: 'absolute', 
  top: 0, 
  backgroundColor: '#FFC476', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

export const modalBodyContainerStyle = { 
  width: '100%', 
  paddingLeft: 20, 
  paddingRight: 20, 
  margin: 0, 
};
