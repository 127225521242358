
export const getGamePercentageStatsData = statsData => {
  const { 
    away_fg_att, 
    away_fg_made, 
    away_three_point_att, 
    away_three_point_made, 
    away_total_rebounds, 
    away_turnover, 
    home_fg_att, 
    home_fg_made, 
    home_three_point_att, 
    home_three_point_made, 
    home_total_rebounds, 
    home_turnover, 
  } = statsData;

  let awayFieldGoalsPercentage = away_fg_made == 0 && away_fg_att == 0 
                                    ? 0 
                                    : Math.ceil((away_fg_made / (away_fg_made + away_fg_att)) * 100);
  awayFieldGoalsPercentage = awayFieldGoalsPercentage || 0;

  let homeFieldGoalsPercentage = home_fg_made == 0 && home_fg_att == 0 
                                    ? 0 
                                    : Math.ceil((home_fg_made / (home_fg_made + home_fg_att)) * 100);
  homeFieldGoalsPercentage = homeFieldGoalsPercentage || 0;

  let awayThreePointPercentage = away_three_point_made == 0 && away_three_point_att == 0 
                                    ? 0 
                                    : Math.ceil((away_three_point_made / (away_three_point_made + away_three_point_att)) * 100);
  awayThreePointPercentage = awayThreePointPercentage || 0;
 
  let homeThreePointPercentage = home_three_point_made == 0 && home_three_point_att == 0 
                                    ? 0 
                                    : Math.ceil((home_three_point_made / (home_three_point_made + home_three_point_att)) * 100);
  homeThreePointPercentage = homeThreePointPercentage || 0;

  const percentageData = [
    { 'percentageStatField': 'Field Goal %', 'awayPercentageStatField': `${awayFieldGoalsPercentage}%`, 'homePercentageStatField': `${homeFieldGoalsPercentage}%`  },
    { 'percentageStatField': 'Three Point %', 'awayPercentageStatField': `${awayThreePointPercentage}%`, 'homePercentageStatField': `${homeThreePointPercentage}%` },
    { 'percentageStatField': 'Total Turnovers', 'awayPercentageStatField': away_turnover, 'homePercentageStatField': home_turnover, 'maxStatData': away_turnover >= home_turnover ? away_turnover : home_turnover },
    { 'percentageStatField': 'Total Rebounds', 'awayPercentageStatField': away_total_rebounds, 'homePercentageStatField': home_total_rebounds, 'maxStatData': away_total_rebounds >= home_total_rebounds ? away_total_rebounds : home_total_rebounds },
  ];

  return percentageData;
};
