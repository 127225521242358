import React, { useContext } from 'react';
import { Container } from '../team/TeamContainer';
import { StatsTitleHeader } from '../team/StatsTitleHeader';
import { SummaryTeamStats } from '../team/SummaryTeamStatsContainer';
import { ViewStats, ViewStatsItem } from '../team/TeamViewStats';
import BasketballCourtShotLocationDisplay from '../playByPlay/BasketballCourtShotLocationDisplay';
import { GameStatsContext } from './GameStatsContext';

const GameBasketballShotLocationStats = ({
}) => {
  const { 
    setStatsTab, 
    awayTeamShotMadeLocations,
    awayTeamShotMissedLocations,
    homeTeamShotMadeLocations,
    homeTeamShotMissedLocations,
  } = useContext(GameStatsContext);

  return (
    <SummaryTeamStats
      height={'auto'}
      marginBottom={10}
    >
      <StatsTitleHeader
        justifyContent="center"
        alignItems="flex-start"
      >
        Shot Chart
      </StatsTitleHeader>
      <Container
        height={'auto'}
      >
        <BasketballCourtShotLocationDisplay
          basketballCourtHeight={290}
          awayTeamShotMadeLocations={awayTeamShotMadeLocations}
          awayTeamShotMissedLocations={awayTeamShotMissedLocations}
          homeTeamShotMadeLocations={homeTeamShotMadeLocations}
          homeTeamShotMissedLocations={homeTeamShotMissedLocations}
        />
      </Container>
      <ViewStats>
        <ViewStatsItem
          onClick={() => setStatsTab('playByPlayStats')}
        >
          View Play By Play
        </ViewStatsItem>
      </ViewStats>
    </SummaryTeamStats>
  )
};

export default GameBasketballShotLocationStats;