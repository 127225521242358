import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import ModalComponent from './ModalComponent';
import { getCsvRowData, downloadFileHandler } from '../../utils/downloadsUtil';

const titleStyle = {
  textAlign: 'center',
  font: 'normal bold 16px/24px Inter',
  color: '#616161'
};

const radioLabelStyle = { 
  width: '90%', 
  padding: 0,
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center',
};

const RadioField = ({
  name, 
  value, 
  children, 
}) => {
  return (
    <Form.Label style={radioLabelStyle}>
      <Field 
        type="radio"
        name={name}
        value={value}
        style={{ marginRight: 10 }}
      />
      {children}
    </Form.Label>
  );
};

const InfoEntryModal = ({
  isOpen,
  setOpen,
  setInfoEntry,
  modalHeaderTitle,
  groupName,
  templateFileType = '',
  csvRadioTitle,
  infoTitle,
  initialValue,
  filename = '', 
  fileData = {}, 
}) => {
  const setInfoEntryValue = (infoEntryValue, formik) => {
    setInfoEntry(infoEntryValue);
    closeModal(formik);
  };

  const closeModal = formik => {
    setOpen(false);
    formik.resetForm();
  };

  const addFileData = event => {
    const csvData = getCsvRowData(fileData);
    downloadFileHandler(csvData, filename, 'text/csv');
    event.stopPropagation();
  };

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
    >
      {formik => (
        <ModalComponent
          headerTitle={modalHeaderTitle}
          showNextButton={true}
          modalWidth={700}
          open={isOpen}
          setOpen={setOpen}
          nextHandler={() => setInfoEntryValue(formik.values[groupName], formik)}
          handleClose={() => closeModal(formik)}
        >
          <Form>
            <Row>
              <Col className="mb-3" xs={12}>
                <span style={titleStyle}>{infoTitle}</span>
              </Col>

              <Col className="mb-4" xs={12}>
                <Form.Group>
                  <Form.Group>
                    <RadioField
                      name={groupName}
                      value={"manual"}
                    >
                      <span>Fill out form fields manually</span>
                    </RadioField>
                  </Form.Group>

                  <Form.Group>
                    <RadioField
                      name={groupName}
                      value={"csv"}
                    >
                      <div>
                        <span>{csvRadioTitle} </span>
                        <span>(</span>
                        <a href="#" onClick={event => addFileData(event)}>{`Download ${templateFileType} Template`}</a>
                        <span>)</span>
                      </div>
                    </RadioField>
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default InfoEntryModal;