import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGamesNextPageFinderQuery,
  useGamesPreviousPageFinderQuery,
  useHomepageGamesTabsDateRangeQuery,
} from '../api/gamesQueries';
import { useUserRoles } from '../api/userQueries';
import MainLayout from '../components/layout/MainLayout';
import HomeContent from '../components/PageContent/HomeContent';
import Spinner from '../components/Spinner';
import {
  selectDateRange,
  updateEndDate,
  updateStartDate,
} from '../redux/homeCalendarSlice';
import { selectActiveTab } from '../redux/homePageTabsSlice';
import { GamesTabs } from '../utils/gameUtil';
const log = require('../logger')('adminPanel', 'debug');

const HomePage = () => {
  const dispatch = useDispatch();
  const { isUserRolesLoaded, userItems } = useUserRoles();
  const currentTab = useSelector(selectActiveTab);
  const { startDate, endDate } = useSelector(selectDateRange);
  // const queryClient = useQueryClient();
  // const [isSearching, setIsSearching] = useState(false)

  const {
    isSuccess,
    isLoading,
    isFetching,
    isPreviousData,
    todaysGames,
    upcomingGames,
    pastGames,
    allGames,
  } = useHomepageGamesTabsDateRangeQuery(startDate, endDate, userItems, {
    onError: (error) => log.error(error),
    staleTime: 25000, // 25 sec
    cacheTime: 15 * 60 * 1000, // 15 mins
  });

  const previousPage = useGamesPreviousPageFinderQuery(startDate);
  const nextPage = useGamesNextPageFinderQuery(endDate);

  // const {
  //   todaysGamesQuery,
  //   upcomingGamesQuery,
  //   pastGamesQuery,
  //   fetchAllGames,
  //   cancelFetchAll,
  // } = useHomepageGamesTabsQuery(userItems, {
  //   onError: (error) => log.error(error),
  // });

  /* TODO replace this functionality */
  const fetchAllGames = useCallback(() => {
    // setIsSearching(true);
  }, []);
  const cancelFetchAll = useCallback(() => {
    // setIsSearching(false);
  }, []);

  const fetchMore = useCallback(() => {
    if (previousPage.count > 0)
      dispatch(updateStartDate(previousPage.endOfPage?.gameDateTime));
    if (nextPage.count > 0)
      dispatch(updateEndDate(nextPage.endOfPage?.gameDateTime));
  }, [dispatch, nextPage, previousPage]);

  // useEffect(() => {
  //   if (isSearching && nextPage.isSuccess && previousPage.isSuccess)
  //     fetchMore();
  // }, [fetchMore, isSearching, nextPage.isSuccess, previousPage.isSuccess]);

  const fetchNextPage = useCallback(() => {
    if (currentTab === GamesTabs.PAST && previousPage.count > 0)
      dispatch(updateStartDate(previousPage.endOfPage?.gameDateTime));
    else if (currentTab === GamesTabs.UPCOMING && nextPage.count > 0)
      dispatch(updateEndDate(nextPage.endOfPage?.gameDateTime));
  }, [
    currentTab,
    dispatch,
    nextPage.count,
    nextPage.endOfPage,
    previousPage.count,
    previousPage.endOfPage,
  ]);

  const hasNextPage = useMemo(() => {
    switch (currentTab) {
      case GamesTabs.UPCOMING:
        return nextPage.isLoading || nextPage.count > 0;
      case GamesTabs.PAST:
        return previousPage.isLoading || previousPage.count > 0;
      case GamesTabs.TODAY:
      case GamesTabs.NONE:
      default:
        return false;
    }
  }, [
    currentTab,
    nextPage.count,
    nextPage.isLoading,
    previousPage.count,
    previousPage.isLoading,
  ]);

  return (
    <MainLayout title="Home">
      {isLoading && <Spinner />}

      {!isLoading && isUserRolesLoaded && (
        <HomeContent
          todaysGamesData={todaysGames}
          upcomingGamesData={upcomingGames}
          pastGamesData={pastGames}
          allGamesData={allGames}
          fetchNextPage={() => fetchNextPage()}
          hasNextPage={hasNextPage}
          isLoading={isLoading || (isFetching && isPreviousData)}
          fetchAll={fetchAllGames}
          cancelFetchAll={cancelFetchAll}
          userItems={userItems}
        />
      )}
    </MainLayout>
  );
};

export default HomePage;
