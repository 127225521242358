import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { colors, textEllipsisStyle } from '../../styles';
import GameTabs from '../tabs/GameTabs';
import UserIcon from '../../assets/user@2x.png';
import GameContentItem from './GameContentItem';
import TeamContentItem from './TeamContentItem';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import { isMobile, addScrollListClass, onListScrollHandler } from '../../utils/scrollUtil';
import { COACH } from '../../data/roles';
import { ITEM_TYPE } from '../../utils/constantsUtils';


/************** BEGIN STYLES ************/
const activeIndicatorPositionStyle = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const gameUserInfoContainerStyles = { 
  width: '100%', 
  height: '78px', 
  margin: 0, 
};

const gamesForUserInfoContainerStyles = { 
  width: '100%', 
  height: '85%', 
  margin: 0, 
};

const gameUserInfoColumnLayoutStyles = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'flex-end', 
  height: '100%',
};

const gamesForUserInfoColumnLayoutStyles = { 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'flex-start', 
};

const tabbarControlContainerStyles = { 
  width: '100%', 
  height: '10%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-end',
};

const gamesForUserInfoContentStyles = { 
  width: '100%', 
  height: '90%', 
  backgroundColor: '#EDEEF2', 
  borderRadius: 3, 
  border: `1px solid ${colors.GRAY[200]}`, 
};

const userInfoStyles = { 
  width: '100%',  
  height: '100%', 
  position: 'relative', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start',
  borderBottom: `1px solid ${colors.GRAY[200]}`,
};

const imageColumnStyle = { 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'flex-start', 
  maxWidth: 140,
};

const imageContainerStyle = { 
  width: 'auto', 
  height: '90%', 
  display: 'flex', 
  alignItems: 'flex-end', 
  justifyContent: 'center', 
  paddingBottom: 3,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const userInfoContainer = { 
  width: '100%', 
  height: '90%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-end', 
  position: 'relative', 
  paddingLeft: 10, 
};

const userInfoRowStyle = { 
  width: '100%', 
  margin: 0, 
};

const userInfoDataContainer = { 
  ...userInfoContainer, 
  borderLeft: `1px solid ${colors.GRAY[200]}`,
};

const userInfolLabelStyle = { 
  width: 'auto', 
  height: 'auto', 
  position: 'absolute', 
  top: 4, 
  left: 10, 
  font: 'normal normal normal 12px/20px Open Sans', 
  color: '#585858', 
};

const userNameTextStyle = { 
  width: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'flex-end', 
  justifyContent: 'flex-start', 
  textAlign: 'left', 
  font: 'normal normal 600 24px/35px Barlow Condensed', 
  color: '#111111', 
  ...textEllipsisStyle,
};

const userEmailTextStyle = { 
  width: '100%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'flex-start', 
  justifyContent: 'flex-start', 
  textAlign: 'left', 
  font: 'normal normal normal 16px/16px Inter', 
  color: '#3C4152', 
  paddingTop: 4, 
  ...textEllipsisStyle,
};
/*************** END STYLES *************/

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  backgroundcolor: transparent;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
`;

const PageListItems = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 0px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const transformRoleValue = role => {
  role = role.includes(',') ? role.replace(',', ' & ') : role;
  role = role.includes('ClockManagers') ? role.replace('ClockManagers', 'Clock Manager') : role;
  role = role.includes('ScoreKeepers') ? role.replace('ScoreKeepers', 'Score Keeper') : role;
  return role;
};

const PlayerProfileItemActiveStatus = ({ 
  active, 
  top = 0, 
  left = 0, 
  position = 'absolute'
}) => {
 return (
   <div style={{ ...activeIndicatorPositionStyle, position: position, top:top, left:left, }}>
     <div
       style={{
         ...activeStatusIndicator,
         backgroundColor: active ? '#2DCC38' : '#EE3B3B',
       }}
     ></div>
     <span style={activeStatusText}>{active ? 'Active' : 'Inactive'}</span>
   </div>
 );
};

const UserInfoItem = ({
  userInfoValue, 
  userStyle, 
  rowHeight, 
}) => {
  return (
    <Row style={{ ...userInfoRowStyle, height: rowHeight, }}>
      <Col xl={12} xs={12} md={12} style={{ display: 'flex', alignItems: 'flex-end', }} className="p-0">
        <div style={userStyle}>
          {userInfoValue}
        </div>
      </Col>
    </Row>
  );
};

const UserInfoData = ({
  userLabel,
  userValue,
}) => {
  return (
    <div style={{ width: '100%', height: '100%', paddingTop: 10, }}>
      <div style={userInfoDataContainer}>
        <div style={userInfolLabelStyle}>
          {userLabel}
        </div>
        <UserInfoItem
          userInfoValue={userValue}
          userStyle={{ ...userNameTextStyle, fontSize: 18 }}
          rowHeight="100%"
        />
      </div>
    </div>
  );
};

const GameUserInfoContainer = ({ children }) => {
  return (
    <div style={{ width: '97%', height: '96%', }}>
      {children}
    </div>
  );
};

const GameUserInfo = ({
  userInfo, 
  openAddEditUserModal, 
  openAssignGameUserRolesModal, 
  userItemsSize
}) => {
  const {
    id, 
    active, 
    email, 
    firstName, 
    lastName, 
    role, 
  } = userInfo;

  const name = `${firstName} ${lastName}`;

  return (
    <Row style={gameUserInfoContainerStyles}>
      <Col xl={12} xs={12} md={12} className="p-0" style={gameUserInfoColumnLayoutStyles}>
        <GameUserInfoContainer>
          <div style={userInfoStyles}>
            <Row style={{ width: '85%', height: '100%', margin:0, }}>
              <Col xl={1} xs={1} md={1} className="p-0">
                <div style={imageContainerStyle}>
                  <img
                    src={UserIcon}
                    style={imageStyle}
                    width="50px"
                    height="50px"
                    alt=""
                  />
                </div>
              </Col>
              <Col xl={3} xs={3} md={3} className="p-0">
                <div style={userInfoContainer}>
                  <PlayerProfileItemActiveStatus
                    active={active}
                    top={14}
                    left={10}
                  />
                  <UserInfoItem
                    userInfoValue={name}
                    userStyle={userNameTextStyle}
                    rowHeight="100%"
                  />
                </div>
              </Col>
              <Col xl={4} xs={4} md={4} className="p-0">
                <UserInfoData
                  userLabel='Role(s)'
                  userValue={transformRoleValue(role?.join(','))}
                />
              </Col>
              <Col xl={4} xs={4} md={4} className="p-0">
                <UserInfoData
                  userLabel='Email'
                  userValue={email}
                />
              </Col>
            </Row>

            <OptionsOverflowMenu
              menuItems={[
                { label: 'Edit User Profile', onClick: openAddEditUserModal },
                { label: role[0] === COACH ? 'Unassign Teams' : 'Edit Game Role', onClick: () => openAssignGameUserRolesModal(role), disabled: userItemsSize === 0 },
              ]}
            />
          </div>
        </GameUserInfoContainer>
      </Col>
    </Row>
  );
};

const TabbarControlContainer = ({
  userGames,
  userTeams,
  setSelectedItems,
  setEmptyGamesMsg,
  setIsTeamAssignedSelected,
  itemType,
}) => {
  const GAMES_FILTERING_OPTIONS = {
    TODAYS_GAMES: 'todaysGames',
    PAST_GAMES: 'pastGames',
    UPCOMING_GAMES: 'upcomingGames',
    TEAMS_ASSIGNED: 'teamsAssigned',
  };

  const EMPTY_GAME_ITEMS_STATEMENTS = {
    TODAYS_GAMES: 'This user currently has no games assigned for today.',
    PAST_GAMES: 'This user currently has no past games assigned.',
    UPCOMING_GAMES: 'This user currently has no upcoming games assigned.',
    TEAMS_ASSIGNED: 'There are currently no teams assigned to this user.',
  };

  const [activeGames, setActiveGames] = useState(GAMES_FILTERING_OPTIONS.TODAYS_GAMES);

  useEffect(() => {
    todaysGamesRef.current = userGames.filter(({ gameDateTime }) => (moment().startOf('d').valueOf() < moment(gameDateTime).valueOf()) && (moment(gameDateTime).valueOf() < moment().endOf('d').valueOf()));
    upcomingGamesRef.current = userGames.filter(({ gameDateTime }) => moment().endOf('d').valueOf() < moment(gameDateTime).valueOf());
    pastGamesRef.current = userGames.filter(({ gameDateTime }) => moment().startOf('d').valueOf() > moment(gameDateTime).valueOf());

    if(todaysGamesRef.current.length > 0) {
      setActiveGames(GAMES_FILTERING_OPTIONS.TODAYS_GAMES);
    }
    else {
      setActiveGames(GAMES_FILTERING_OPTIONS.UPCOMING_GAMES);
    }
  }, []);

  useEffect(() => {
    if(activeGames === GAMES_FILTERING_OPTIONS.TODAYS_GAMES || 
       activeGames === GAMES_FILTERING_OPTIONS.UPCOMING_GAMES || 
       activeGames === GAMES_FILTERING_OPTIONS.PAST_GAMES) 
    {
      if(activeGames === GAMES_FILTERING_OPTIONS.TODAYS_GAMES) {
        setSelectedItems(todaysGamesRef.current);

        if(todaysGamesRef.current.length === 0) {
          setEmptyGamesMsg(EMPTY_GAME_ITEMS_STATEMENTS.TODAYS_GAMES);
        }
      }      
      else if(activeGames === GAMES_FILTERING_OPTIONS.UPCOMING_GAMES) {
        setSelectedItems(upcomingGamesRef.current);

        if(upcomingGamesRef.current.length === 0) {
          setEmptyGamesMsg(EMPTY_GAME_ITEMS_STATEMENTS.UPCOMING_GAMES);
        }
      }
      else if(activeGames === GAMES_FILTERING_OPTIONS.PAST_GAMES) {
        setSelectedItems(pastGamesRef.current);

        if(pastGamesRef.current.length === 0) {
          setEmptyGamesMsg(EMPTY_GAME_ITEMS_STATEMENTS.PAST_GAMES);
        }
      }
      setIsTeamAssignedSelected(false);
    }
    else if(activeGames === GAMES_FILTERING_OPTIONS.TEAMS_ASSIGNED) {
      setSelectedItems(userTeams);
      setIsTeamAssignedSelected(true);

      if(userTeams?.length === 0) {
        setEmptyGamesMsg(EMPTY_GAME_ITEMS_STATEMENTS.TEAMS_ASSIGNED);
      }
    }
  }, [activeGames]);

  const todaysGamesRef = useRef();
  const upcomingGamesRef = useRef();
  const pastGamesRef = useRef();

  return (
    <div style={tabbarControlContainerStyles}>
        <GameTabs
          liveGamesCount={todaysGamesRef.current?.length}
          upcomingGamesCount={upcomingGamesRef.current?.length}
          pastGamesCount={pastGamesRef.current?.length}
          liveGamesClickHandler={() => activeGames !== GAMES_FILTERING_OPTIONS.TODAYS_GAMES && setActiveGames(GAMES_FILTERING_OPTIONS.TODAYS_GAMES)}
          upcomingGamesClickHandler={() => activeGames !== GAMES_FILTERING_OPTIONS.UPCOMING_GAMES && setActiveGames(GAMES_FILTERING_OPTIONS.UPCOMING_GAMES)}
          pastGamesClickHandler={() => activeGames !== GAMES_FILTERING_OPTIONS.PAST_GAMES && setActiveGames(GAMES_FILTERING_OPTIONS.PAST_GAMES)}
          activeLiveGamesStatus={activeGames === GAMES_FILTERING_OPTIONS.TODAYS_GAMES}
          activeUpcomingGamesStatus={activeGames === GAMES_FILTERING_OPTIONS.UPCOMING_GAMES}
          activePastGamesStatus={activeGames === GAMES_FILTERING_OPTIONS.PAST_GAMES}
          showCustomTabItem={itemType === ITEM_TYPE.TEAM}
          customTabItemTitle={'Teams Assigned'}
          customTabItemCount={userTeams?.length}
          customTabItemStatus={activeGames === GAMES_FILTERING_OPTIONS.TEAMS_ASSIGNED}
          customTabItemClickHandler={() => activeGames !== GAMES_FILTERING_OPTIONS.TEAMS_ASSIGNED && setActiveGames(GAMES_FILTERING_OPTIONS.TEAMS_ASSIGNED)}
        />
    </div>
  );
};

const GamesForUserInfoContent = ({
  children, 
}) => {
  return (
    <div style={gamesForUserInfoContentStyles}>
      {children}
    </div>
  );
};

const EmptyGamesForUserContent = ({
  emptyGamesMsg,
}) => {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', font: 'normal normal normal 14px/30px Inter', }}>
      {emptyGamesMsg}
    </div>
  );
};

const GameItemsContent = ({
  userGames,
  userTeams,
  itemType,
  isTeamAssignedSelected,
}) => {
  const listRef = useRef();

  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  }, []);

  useEffect(() => {
    if(userGames?.length > 0) {
      if(!isMobile()) {
        addScrollListClass(listRef.current);
      }
    }
  }, [userGames]);

  return (
    <ContentContainer>
      <PageListItems
        ref={listRef}
        onScroll={() => onListScrollHandler(_isMobile, listRef.current)}
      >
        {!isTeamAssignedSelected && 
          userGames.map(game => (
            <GameContentItem
              key={game.id}
              game={game}
              cardItemWidth={225}
              cardItemHeight={236}
              cardOuterMargin={'5px'}
              teamImageHeight={35}
              teamTextFontSize={12}
              versusCircleSize={30}
              versusTextFontSize={15}
              marginTopStyle={10}
              dateFontSize={12}
              datePaddingBottom={'1px'}
              venueFontSize={13}
              homeAwayTeamHeight={80}
              homeAwayTeamNameMinHeight={14}
              homeAwayTeamMarginTop={5}
              homeAwayTeamMarginBottom={5}
              leagueMarginTop={8}
              leagueHeight={45}
              leagueNameFontSize={14}
              leagueAbbrvFontSize={11}
            />
        ))}
        {(itemType === ITEM_TYPE.TEAM && isTeamAssignedSelected) &&
          userTeams.map(({
            id, 
            name, 
            state, 
            city, 
            league, 
            image, 
            active,
            role,
          }) => (
            <TeamContentItem
              key={id}
              id={id}
              name={name}
              state={state}
              city={city}
              league={league?.name}
              leagueAbbr={league?.abbreviation}
              image={image}
              active={active}
              role={role}
              activeStatusTextFontSize={10}
              activeIndicatorTopPosition={16}
              activeIndicatorLeftPosition={-4}
              cardItemWidth={225}
              cardItemHeight={236}
              teamImageHeight={45}
              teamImageTopPosition={8}
              nameTextFontSize={22}
              nameTextTopPosition={8}
              leagueNameFontSize={12}
              leagueAbbrLineHeight={13}
              leagueAbbrFontSize={12}
              leagueNameLineHeight={14}
              leagueBarTopPosition={8}
              cityStateTopPosition={12}
              cityFontSize={12}
              cityLineHeight={14}
              stateFontSize={10}
              stateLineHeight={13}
            />
          ))
        }
      </PageListItems>
    </ContentContainer>
  );
};

const GamesForUserInfo = ({
  userGames, 
  userTeams, 
  itemType, 
}) => {
  const [selectedItems, setSelectedItems] = useState(userGames);
  const [emptyGamesMsg, setEmptyGamesMsg] = useState('');
  const [isTeamAssignedSelected, setIsTeamAssignedSelected] = useState(false);

  return (
    <Row style={gamesForUserInfoContainerStyles}>
      <Col xl={12} xs={12} md={12} className="p-0" style={gamesForUserInfoColumnLayoutStyles}>
        <GameUserInfoContainer>
          <TabbarControlContainer
            userGames={userGames}
            userTeams={userTeams}
            setSelectedItems={setSelectedItems}
            setEmptyGamesMsg={setEmptyGamesMsg}
            itemType={itemType}
            setIsTeamAssignedSelected={setIsTeamAssignedSelected}
          />
          <GamesForUserInfoContent>
            {selectedItems.length === 0
              ?
              <EmptyGamesForUserContent 
                emptyGamesMsg={emptyGamesMsg}
              />
              : 
              <GameItemsContent
                userGames={!isTeamAssignedSelected && selectedItems}
                userTeams={isTeamAssignedSelected && selectedItems}
                itemType={itemType}
                isTeamAssignedSelected={isTeamAssignedSelected}
              />
            }
          </GamesForUserInfoContent>
        </GameUserInfoContainer>
      </Col>
    </Row>
  );
};

const UserProfileContent = ({
  userInfo, 
  userGames, 
  userTeams, 
  itemType, 
  openAddEditUserModal, 
  openAssignGameUserRolesModal, 
}) => {
  const userGameSize = userGames.filter(({ gameDateTime }) => moment().valueOf() < moment(gameDateTime).valueOf()).length;
  const userTeamSize = userTeams.length;

  return (
    <Row style={{ width: '100%', height: '100%', padding:10, }}>
      <Col xl={12} xs={12} md={12} className="p-0">
        <GameUserInfo
          userInfo={userInfo}
          openAddEditUserModal={openAddEditUserModal}
          openAssignGameUserRolesModal={openAssignGameUserRolesModal}
          userItemsSize={itemType === ITEM_TYPE.TEAM ? userTeamSize : userGameSize}
        />
        <GamesForUserInfo
          userGames={userGames}
          userTeams={userTeams}
          itemType={itemType}
        />
      </Col>
    </Row>
  );
};

export default UserProfileContent;