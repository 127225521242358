export const PLAYERS_POSTIONS = {
  CENTER: 'Center',
  POWER_FORWARD: 'Power Forward',
  SMALL_FORWARD: 'Small Forward',
  POINT_GUARD: 'Point Guard',
  SHOOTING_GUARD: 'Shooting Guard',
};

export const PLAYERS_POSITIONS_ABBREVIATION = {
  [PLAYERS_POSTIONS.CENTER]: 'CT',
  [PLAYERS_POSTIONS.POWER_FORWARD]: 'PF',
  [PLAYERS_POSTIONS.SMALL_FORWARD]: 'SF',
  [PLAYERS_POSTIONS.POINT_GUARD]: 'PG',
  [PLAYERS_POSTIONS.SHOOTING_GUARD]: 'SG',
};

export const searchFilter = (option, search) =>
  option.name.toLowerCase().includes(search.toLowerCase()) ||
  option.institute?.toLowerCase().includes(search.toLowerCase()) ||
  option.position.join(', ').toLowerCase().includes(search.toLowerCase()) ||
  String(option.jerseyNumber).toLowerCase().includes(search.toLowerCase()) ||
  option.team.name.toLowerCase().includes(search.toLowerCase()) ||
  option.league.name.toLowerCase().includes(search.toLowerCase()) ||
  option.league.abbreviation.toLowerCase().includes(search.toLowerCase());
