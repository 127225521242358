import { Box, Button } from '@material-ui/core';
import React from 'react';
import { StyledFlexEnd } from '../../styles';

/*
Delete modal accepts following arguments
message: it will display in body of modal
handleDelete: delete mehtods
handleCancel: it will close the model
*/

const DeleteModal = ({ message, handleDelete, handleCancel }) => {
  return (
    <div>
      <p>{message}</p>
      <StyledFlexEnd>
        <Button
          variant="outlined"
          color="default"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Box ml={2}>
          <Button
            className="ml-5"
            variant="contained"
            color="secondary"
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        </Box>
      </StyledFlexEnd>
    </div>
  );
};
export default DeleteModal;
