import { ActionTypes } from '../ActionTypes.type.js';
import { createEvent } from '../../../api/eventsService.js';
import ClockManagerEvents from '../../ClockManager/ClockManagerEvents.js';
import { colors } from '../../../styles/colorsStatsCollector.js';
import { EditEventTypeForm } from '../components/forms/EventTypeFieldset.jsx';
import { EditSubstitutionForm } from '../components/forms/SubstitutionFieldset.jsx';
import { EditPointsForm } from '../components/forms/EditPointsForm.jsx';
const log = require('../../../logger')('GameEvents');

/**
 * These game "events" correspond to the logged events that the statistics
 * and possession systems operate around
 */
const GameEvents = {
  /** 2pt Shots */
  TWO_POINT_MADE: 'TWO_POINT_MADE',
  TWO_POINT_MISS: 'TWO_POINT_MISS',
  /** 3pt Shots */
  THREE_POINT_MADE: 'THREE_POINT_MADE',
  THREE_POINT_MISS: 'THREE_POINT_MISS',
  /** Free Throws */
  FREE_THROW_ATTEMPT: 'FREE_THROW_ATTEMPT',
  FREE_THROW_MADE: 'FREE_THROW_MADE',
  FREE_THROW_MISS: 'FREE_THROW_MISS',
  /** Offensive Actions */
  ASSIST: 'ASSIST',
  TURNOVER: 'TURNOVER',
  REBOUND_OFFENSIVE: 'OFFENSIVE_REBOUND',
  /** Defensive Actions */
  BLOCK: 'BLOCK',
  STEAL: 'STEAL',
  REBOUND_DEFENSIVE: 'DEFENSIVE_REBOUND',
  DEFLECT: 'DEFLECT',
  /** Fouls */
  PERSONAL_FOUL: 'PERSONAL_FOUL',
  TECHNICAL_FOUL: 'TECHNICAL_FOUL',
  VIOLATION_TRAVELING: 'VIOLATION_TRAVELING',
  VIOLATION_CARRYING: 'VIOLATION_CARRYING',
  VIOLATION_DOUBLE_DRIBBLE: 'VIOLATION_DOUBLE_DRIBBLE',
  VIOLATION_BACKCOURT: 'VIOLATION_BACKCOURT',
  VIOLATION_OFF_GOALTENDING: 'VIOLATION_OFF_GOALTENDING',
  VIOLATION_DEF_GOALTENDING: 'VIOLATION_DEF_GOALTENDING',
  VIOLATION_3_SECONDS: 'VIOLATION_3_SECONDS',
  VIOLATION_5_SECONDS: 'VIOLATION_5_SECONDS',
  VIOLATION_10_SECONDS: 'VIOLATION_10_SECONDS',
  VIOLATION_FREE_THROW: 'VIOLATION_FREE_THROW',
  /** Change of possesion? */
  CHANGE_POSSESSION: 'CHANGE_POSSESION',
};

const gameEventToPropMap = {
  [GameEvents.TWO_POINT_MADE]: {
    text: '2PT MADE',
    actionType: ActionTypes.Made,
    isEditable: true,
    editForm: EditPointsForm,
  },
  [GameEvents.FREE_THROW_ATTEMPT]: {
    text: 'FREE THROWS',
    actionType: ActionTypes.Actions,
  },
  [GameEvents.THREE_POINT_MADE]: {
    text: '3PT MADE',
    actionType: ActionTypes.Made,
  },
  [GameEvents.FREE_THROW_MADE]: {
    text: 'FT MADE',
    actionType: ActionTypes.Made,
  },
  [GameEvents.TWO_POINT_MISS]: {
    text: '2PT MISS',
    actionType: ActionTypes.Missed,
  },
  [GameEvents.THREE_POINT_MISS]: {
    text: '3PT MISS',
    actionType: ActionTypes.Missed,
  },
  [GameEvents.FREE_THROW_MISS]: {
    text: 'FT MISS',
    actionType: ActionTypes.Missed,
  },
  [GameEvents.ASSIST]: {
    text: 'ASSIST',
    actionType: ActionTypes.Actions,
    isRelatedEvent: true,
  },
  [GameEvents.TURNOVER]: {
    text: 'TURNOVER',
    actionType: ActionTypes.Actions,
    isRelatedEvent: true,
  },
  [GameEvents.REBOUND_OFFENSIVE]: {
    text: 'OFF REBOUND',
    actionType: ActionTypes.Actions,
    isRelatedEvent: true,
    eventType: 'REBOUND',
    playType: 'offensive',
  },
  [GameEvents.REBOUND_DEFENSIVE]: {
    text: 'DEF REBOUND',
    actionType: ActionTypes.Actions,
    isRelatedEvent: true,
    eventType: 'REBOUND',
    playType: 'defensive',
  },
  [GameEvents.BLOCK]: { text: 'BLOCK', actionType: ActionTypes.Actions },
  [GameEvents.STEAL]: { text: 'STEAL', actionType: ActionTypes.Actions },
  [GameEvents.PERSONAL_FOUL]: {
    text: 'PERSONAL FOUL',
    actionType: ActionTypes.Fouls,
  },
  [GameEvents.TECHNICAL_FOUL]: {
    text: 'TECHNICAL FOUL',
    actionType: ActionTypes.Fouls,
  },
  [GameEvents.CHANGE_POSSESSION]: {
    text: 'CHANGE POSSESSION',
    actionType: ActionTypes.Actions,
  },
  [GameEvents.VIOLATION_TRAVELING]: {
    text: 'TRAVELING',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_TRAVELING,
  },
  [GameEvents.VIOLATION_CARRYING]: {
    text: 'CARRYING',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_CARRYING,
  },
  [GameEvents.VIOLATION_DOUBLE_DRIBBLE]: {
    text: 'DOUBLE DRIBBLE',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_DOUBLE_DRIBBLE,
  },
  [GameEvents.VIOLATION_BACKCOURT]: {
    text: 'BACKCOURT',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_BACKCOURT,
  },
  [GameEvents.VIOLATION_OFF_GOALTENDING]: {
    text: 'OFF GOALTENDING',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_OFF_GOALTENDING,
  },
  [GameEvents.VIOLATION_DEF_GOALTENDING]: {
    text: 'DEF GOALTENDING',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_DEF_GOALTENDING,
  },
  [GameEvents.VIOLATION_3_SECONDS]: {
    text: 'VIOLATION (3 Sec)',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_3_SECONDS,
  },
  [GameEvents.VIOLATION_5_SECONDS]: {
    text: 'VIOLATION (5 Sec)',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_5_SECONDS,
  },
  [GameEvents.VIOLATION_10_SECONDS]: {
    text: 'VIOLATION (10 Sec)',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_10_SECONDS,
  },
  [GameEvents.VIOLATION_FREE_THROW]: {
    text: 'VIOLATION (FT)',
    actionType: ActionTypes.Fouls,
    eventType: 'VIOLATION',
    playType: GameEvents.VIOLATION_FREE_THROW,
  },
  /** Clock Manager Events */
  [ClockManagerEvents.GAME_PAUSED]: {
    text: 'Game Paused',
    actionType: ActionTypes.Actions,
    color: '#D8B62E',
    textColor: colors.BLACK[100],
  },
  [ClockManagerEvents.GAME_RESUMED]: {
    text: 'Game Resumed',
    actionType: ActionTypes.Actions,
    color: colors.GREEN[500],
    textColor: colors.BLACK[100],
  },
  [ClockManagerEvents.GAME_STARTED]: {
    text: 'Game Started',
    actionType: ActionTypes.Actions,
    color: colors.WHITE[100],
    textColor: colors.BLACK[100],
  },
  [ClockManagerEvents.GAME_ENDED]: {
    text: 'Game Ended',
    actionType: ActionTypes.Actions,
    color: '#FF6D6D',
    textColor: colors.BLACK[100],
  },
  [ClockManagerEvents.GAME_CANCELED]: {
    text: 'Game Canceled',
    actionType: ActionTypes.Actions,
    color: '#FF6D6D',
    textColor: colors.BLACK[100],
  },
  [ClockManagerEvents.SUBSTITUTION]: {
    text: 'Substitution',
    actionType: ActionTypes.Actions,
    color: colors.PURPLE[200],
    isEditable: true,
    editForm: EditSubstitutionForm,
  },
  [ClockManagerEvents.JUMPBALL]: {
    text: 'Jumpball',
    actionType: ActionTypes.Actions,
    color: colors.PURPLE[200],
    isEditable: true,
    editForm: EditEventTypeForm,
  },
  [ClockManagerEvents.TIMEOUT]: {
    text: 'Timeout',
    actionType: ActionTypes.Actions,
    isEditable: true,
    editForm: EditEventTypeForm,
  },
};

export function isGameEventEditable(event) {
  const gameEvent = getGameEventFromEvent(event);
  return gameEventToPropMap[gameEvent]?.isEditable ?? false;
}

export function getEditForm(event) {
  const gameEvent = getGameEventFromEvent(event);
  return gameEventToPropMap[gameEvent]?.editForm;
}

function getGameEventFromEvent(event) {
  if (event.eventType === 'VIOLATION') {
    /** Violations use the PlayType Field */
    return event.playType;
  } else if (event.eventType === 'REBOUND') {
    /** Rebounds determing OFF/DEF based off of the playType Field */
    return event.playType.toUpperCase() === 'OFFENSIVE'
      ? GameEvents.REBOUND_OFFENSIVE
      : GameEvents.REBOUND_DEFENSIVE;
  } else {
    /** Everything else uses the EventType field */
    return event.eventType;
  }
}

export function getGameEventText(event) {
  const gameEvent = getGameEventFromEvent(event);
  if (gameEventToPropMap.hasOwnProperty(gameEvent)) {
    if (
      [GameEvents.TWO_POINT_MADE, GameEvents.TWO_POINT_MISS].includes(
        event.eventType
      )
    ) {
      const shotLocation = event.shotLocation && JSON.parse(event.shotLocation);
      const shotLocationArea = shotLocation?.area ? ` (${shotLocation.area})` : '';
      return `${gameEventToPropMap[gameEvent].text}${shotLocationArea}`;
    } else {
      return gameEventToPropMap[gameEvent].text;
    }
  } else {
    return gameEvent;
  }
}

export function getGameEventActionType(event) {
  const gameEvent = getGameEventFromEvent(event);
  if (gameEventToPropMap.hasOwnProperty(gameEvent)) {
    return gameEventToPropMap[gameEvent].actionType;
  } else {
    return ActionTypes.Actions;
  }
}

export function getColorFromEvent(event) {
  const gameEvent = getGameEventFromEvent(event);
  if (gameEventToPropMap.hasOwnProperty(gameEvent)) {
    return (
      gameEventToPropMap[gameEvent].color ||
      gameEventToPropMap[gameEvent].actionType?.color ||
      ActionTypes.Actions.color
    );
  } else if (
    /* Colors for Q# Started or Q# Ended */
    event.eventType.includes('STARTED') ||
    event.eventType.includes('ENDED')
  ) {
    return colors.GRAY[975];
  } else {
    return ActionTypes.Actions.color;
  }
}

export function getTextColorFromEvent(event) {
  const gameEvent = getGameEventFromEvent(event);
  if (gameEventToPropMap.hasOwnProperty(gameEvent)) {
    return gameEventToPropMap[gameEvent].textColor || colors.WHITE[100];
  } else if (
    /* Colors for Q# Started or Q# Ended */
    event.eventType.includes('STARTED') ||
    event.eventType.includes('ENDED')
  ) {
    return colors.BLACK[100];
  } else {
    return colors.WHITE[100];
  }
}

function isRelatedEvent(gameEvent) {
  if (
    gameEventToPropMap.hasOwnProperty(gameEvent) &&
    gameEventToPropMap[gameEvent].hasOwnProperty('isRelatedEvent')
  ) {
    return gameEventToPropMap[gameEvent].isRelatedEvent;
  } else {
    return false;
  }
}

function getEventTypeFromGameEvent(gameEvent) {
  if (
    gameEventToPropMap.hasOwnProperty(gameEvent) &&
    gameEventToPropMap[gameEvent].hasOwnProperty('eventType')
  ) {
    return gameEventToPropMap[gameEvent].eventType;
  } else {
    return gameEvent;
  }
}

function getPlayTypeFromGameEvent(gameEvent) {
  if (
    gameEventToPropMap.hasOwnProperty(gameEvent) &&
    gameEventToPropMap[gameEvent].hasOwnProperty('playType')
  ) {
    return gameEventToPropMap[gameEvent].playType;
  } else {
    return null;
  }
}

export async function createGameEvent(
  gameEvent,
  gameId,
  possessionId,
  player,
  playerSecondary,
  relatedEventId,
  eventStartTime,
  location,
  statcollFirstName,
  statcollLastName,
  statcollSub,
  statcollEmail,
  eventCreatorRole,
  isFinalEvent = false
) {
  
  /** Check if the event type is a primary or secondary event */
  const isSecondaryEvent = isRelatedEvent(gameEvent) && relatedEventId;
  
  /** Confirm secondary events have a secondary player selected*/
  if (isSecondaryEvent && !playerSecondary) {
    log.warn(
      'Trying to be create a related event without secondary player selected'
    );
  }
  
  /* Get the play type for this event from the event type*/
  var playType = getPlayTypeFromGameEvent(gameEvent)
  
  /** 
   * SPECIAL CASE - Final Free Throw: In order to change possession on the final
   * attempt of a series of free throws where there is no rebound on a missed shot
   * we are going to set the play type of this event to FT_FINAL
   * 
   * TODO: This is probably not the cleanest way to handle this but the behaviour 
   * to treat certain event differently based on their related events does not fit 
   * into the current state machine/template based architecture 
   */
  if(isFinalEvent && [GameEvents.FREE_THROW_MADE, GameEvents.FREE_THROW_MISS].includes(gameEvent)){
    playType = 'FT_FINAL'
  }

  const eventData = await createEvent({
    eventType: getEventTypeFromGameEvent(gameEvent),
    minutes: eventStartTime.minutes,
    seconds: eventStartTime.seconds,
    quarter: eventStartTime.quarter,
    gameOvertimeNumber: eventStartTime.gameOvertimeNumber
      ? eventStartTime.gameOvertimeNumber
      : 0,
    team: { id: isSecondaryEvent ? playerSecondary.teamId : player.teamId },
    game: { id: gameId },
    playerId: isSecondaryEvent ? playerSecondary.playerId : player.playerId,
    gameLineupPlayerId: isSecondaryEvent ? playerSecondary.id : player.id,
    gameLineupPlayer: isSecondaryEvent ? playerSecondary : player,
    relatedEventId: relatedEventId,
    posessionId: possessionId,
    playType: playType,
    shotLocation: JSON.stringify(location),
    statcollFirstName: statcollFirstName,
    statcollLastName: statcollLastName,
    statcollSub: statcollSub,
    statcollEmail: statcollEmail,
    eventCreatorRole: eventCreatorRole,
  });

  if (eventData) {
    log.info('Event Created', eventData);
    return eventData.id;
  } else {
    return null;
  }
}

export default GameEvents;
