import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const statRecordContainerStyles = { 
  width: '100%', 
  margin: 0, 
  borderRight: '1px solid', 
  borderLeft: '1px solid', 
  borderTop: '1px solid', 
};

const StatField = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  font: ${props => `normal normal ${props.fontWeight} ${props.fontSize}px/${props.lineHeight}px 'Segoe UI'`};
  color: #0A0A0A;
  padding-left: ${props => props.paddingLeft}px;
`;

const StatValue = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: ${props => `1px solid ${props.borderColor}`};
  font: ${props => `normal normal ${props.fontWeight} ${props.fontSize}px/${props.lineHeight}px 'Segoe UI'`};
  color: #0A0A0A;
`;

const TeamStatRecord = ({
  statField,
  homeTeamStatValue, 
  awayTeamStatValue, 
  backgroundColor = 'transparent', 
  borderBottom, 
  justifyStatField = 'flex-start', 
  fontWeight = 600,
  colSize1 = 8,
  colSize2 = 4,
  paddingLeft = 0,
  fontSize = 14,
  lineHeight = 38, 
  height = 40,
  outerBorderColor = '#EBEBEB',
  innerBorderColor = '#D4D4D4',
}) => {
  return (
    <Row style={{ ...statRecordContainerStyles, height, backgroundColor, borderBottom, borderColor: outerBorderColor, }}>
      <Col xs={colSize1} className='p-0'>
        <StatField
          justifyContent={justifyStatField}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          paddingLeft={paddingLeft}
        >
          {statField}
        </StatField>
      </Col>
      <Col xs={colSize2} className='p-0' style={{ display: 'flex', flexDirection: 'row', }}>
        <StatValue
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          borderColor={innerBorderColor}
        >
          {homeTeamStatValue}
        </StatValue>
        <StatValue
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          borderColor={innerBorderColor}
        >
          {awayTeamStatValue}
        </StatValue>
      </Col>
    </Row>
  )
};

export default TeamStatRecord;