import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useListLeaguesQuery } from '../api/leaguesQueries';
import { playersKeys, usePlayersQuery } from '../api/playersQueries';
import {
  createPlayer,
  getPlayer,
  getPlayerImageProfile,
  listPlayers,
  updatePlayer,
} from '../api/playersService';
import { listTeams } from '../api/teamsService';
import ToastAlert from '../components/alerts/ToastAlert';
import AddCircleButton from '../components/Buttons/AddCircleButton';
import SquareGrid from '../components/Grids/SquareGrid';
import MainLayout from '../components/layout/MainLayout';
import AddEditPlayerModal from '../components/Modals/AddEditPlayerModal';
import InfoEntryModal from '../components/Modals/InfoEntryModal';
import PlayerContent from '../components/PageContent/PlayerContent';
import Spinner from '../components/Spinner';
import { colors } from '../styles';
import { useFetchAll, useFetchOnQuery } from '../utils/reactQueryToolkit';
const log = require('../logger')('adminPanel');

const PlayersPage = () => {
  const initialPlayerItem = {
    firstName: '',
    lastName: '',
    position: '',
    jerseyNumber: '',
    dateOfBirth: '',
    birthCity: '',
    birthState: '',
    birthCountry: '',
    weight: '',
    height: '',
    institute: '',
    leagueId: '',
    teamId: '',
    image: '',
    active: true,
    acceptedTerms: false,
  };

  const queryClient = useQueryClient();
  const [queryFilter, setQueryFilter] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [createPlayerError, setCreatePlayerError] = useState();
  const [playerEntryModalOpen, setPlayerEntryModalOpen] = useState(false);
  const [playerEntryValue, setPlayerEntryValue] = useState();
  const [playerId, setPlayerId] = useState('');
  const [playerItem, setPlayerItem] = useState(initialPlayerItem);
  const [changedPlayerId, setChangedPlayerId] = useState();

  /** Reset changedPlayerId after 2 seconds */
  useEffect(() => {
    if (changedPlayerId) {
      const timer = setTimeout(() => setChangedPlayerId(), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [changedPlayerId]);

  /** ToastAlert */
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(
    'This is a successful test message!'
  );
  const [toastVariant, setToastVariant] = useState('success');

  const PLAYER_INFO_ENTRY = {
    MANUAL: 'manual',
    CSV: 'csv',
  };
  useEffect(() => {
    switch (playerEntryValue) {
      case PLAYER_INFO_ENTRY.MANUAL:
        handleAddPlayerManualModalOpen(playerId);
        break;
      case PLAYER_INFO_ENTRY.CSV:
        //TODO: OPEN GAME ENTRY MANUAL CSV FORM
        break;
      default:
        break;
    }
  }, [
    playerEntryValue,
    playerId,
    PLAYER_INFO_ENTRY.CSV,
    PLAYER_INFO_ENTRY.MANUAL,
  ]);

  const { data: leagues, isSuccess: leaguesSuccess } = useListLeaguesQuery();

  const { data: teams, isSuccess: teamsSuccess } = useQuery(
    'teams',
    async () => {
      const teamList = await listTeams();
      return teamList.items?.sort((a, b) => a.name.localeCompare(b.name));
    }
  );

  const { fetchAllCallback, cancelFetchAll, onSuccessCallback } = useFetchAll();

  const playersQuery = usePlayersQuery(
    {
      enabled:
        teamsSuccess &&
        teams?.length > 0 &&
        leaguesSuccess &&
        leagues?.length > 0,
      onSuccess: (data) => onSuccessCallback(playersQuery)(data),
      onError: (error) => {
        log.error(error);
        setShowToast(true);
        setToastText('An error occurred while retrieving players.');
        setToastVariant('danger');
      },
    },
    useCallback(
      async (player) => {
        player.name = `${player.firstName} ${player.lastName}`;
        player.team = teams.find(({ id }) => id === player.teamId) || {
          name: 'Free Agent',
        };
        player.league = leagues.find(({ id }) => id === player.leagueId);
        player.image = await getPlayerImageProfile(player.id);
        return player;
      },
      [leagues, teams]
    )
  );

  useFetchOnQuery(
    queryFilter,
    () => fetchAllCallback([playersQuery]),
    cancelFetchAll
  );

  const {
    data: loadedPlayers,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isLoading,
    isFetching,
  } = playersQuery;

  const handleSubmit = async (values, resetFormFunct) => {
    values.position =
      values.position.indexOf(',') !== -1
        ? values.position.split(',').map((pos) => pos.trim())
        : [values.position.trim()];
    values.height =
      parseFloat(values.heightInFeet) * 12 + parseFloat(values.heightInInches);
    values.teamId = values.teamId.length === 0 ? 'null' : values.teamId;
    values.firstName =
      values.firstName.toString()[0].toUpperCase() +
      values.firstName.toString().substring(1);
    values.lastName =
      values.lastName.toString()[0].toUpperCase() +
      values.lastName.toString().substring(1);

    delete values.heightInFeet;
    delete values.heightInInches;

    /**
     * Check for duplicate
     *
     * TODO Need a better query to check for a duplicate. Probably a new key.
     * Right now, this won't necessarily find a duplicate b/c of the limit of records
     * that actually get filtered.
     */
    const duplicateData = await listPlayers({
      filter: {
        firstName: { eq: values.firstName.toString() },
        lastName: { eq: values.lastName.toString() },
        jerseyNumber: { eq: values.jerseyNumber },
        position: { eq: values.position },
        dateOfBirth: { eq: values.dateOfBirth },
        birthCity: { eq: values.birthCity },
        birthState: { eq: values.birthState },
        birthCountry: { eq: values.birthCountry },
        weight: { eq: values.weight },
        height: { eq: values.height },
        active: { eq: values.active },
        institute: { eq: values.institute },
        teamId: { eq: values.teamId },
        leagueId: { eq: values.leagueId },
      },
    });

    if (duplicateData.items.errors) {
      /** Error checking for duplicate */
      setCreatePlayerError(
        `Unable to create player. ${duplicateData.items.errors[0].message}.`
      );
    } else if (duplicateData.items.length >= 1) {
      const { firstName, lastName, teamId } = values;
      const teamName = teams.find(({ id }) => id === teamId).name;

      /** Duplicate Exists */
      setCreatePlayerError(
        `The player "${firstName} ${lastName}" of "${teamName}" team already exists.`
      );
    } else {
      /**
       * Create or Edit
       */
      let returnData;
      isCreate
        ? (returnData = await createPlayer(values))
        : (returnData = await updatePlayer(values));

      /* If League Create/Update failed show an error, else close the modal */
      if (returnData.errors) {
        setCreatePlayerError(
          `Unable to create player. ${returnData.errors[0].message}.`
        );
      } else {
        /**
         * Success
         * Clear error, update list, close model, show toast, reset form
         */
        queryClient.invalidateQueries(playersKeys.all);
        setChangedPlayerId(
          returnData.data.createPlayer?.id ?? returnData.data.updatePlayer?.id
        );
        setCreatePlayerError(null);
        setModalOpen(false);
        resetFormFunct();
        setPlayerEntryValue(null);
        setPlayerId(null);
        setPlayerItem(initialPlayerItem);

        if (isCreate) {
          setToastText(
            `The Player "${values.firstName} ${values.lastName}" was created successfully.`
          );
        } else {
          setToastText(
            `The Player "${values.firstName} ${values.lastName}" was updated successfully.`
          );
        }

        setShowToast(true);
        setToastVariant('success');
      }
    }
  };

  const handleOpen = () => {
    setPlayerEntryModalOpen(true);
  };

  const handleAddPlayerManualModalOpen = async (playerId) => {
    if (!playerId) {
      setIsCreate(true);
      setModalOpen(true);
    } else {
      const player = await getPlayer(playerId);
      let {
        id,
        firstName,
        lastName,
        jerseyNumber,
        position,
        dateOfBirth,
        birthCity,
        birthState,
        birthCountry,
        weight,
        height,
        active,
        leagueId,
        teamId,
        institute,
        image,
        _version,
      } = player;

      position = position.length > 1 ? position.join(',') : position[0];
      teamId = teamId === 'null' ? '' : teamId;
      const heightInFeet = Math.floor(height / 12).toString();
      const heightInInches = (height % 12).toString();

      setPlayerItem({
        id,
        firstName,
        lastName,
        jerseyNumber,
        position,
        dateOfBirth,
        birthCity,
        birthState,
        birthCountry,
        weight,
        heightInFeet,
        heightInInches,
        leagueId,
        teamId,
        institute,
        image,
        active,
        _version,
      });
      setIsCreate(false);
      setModalOpen(true);
    }
  };

  const handleEditPlayerManualModalOpen = async (playerId) => {
    setPlayerId(playerId);
    setPlayerEntryValue(PLAYER_INFO_ENTRY.MANUAL);
  };

  const onPlayerManualModalClose = () => {
    setCreatePlayerError(null);
    setModalOpen(false);
    setPlayerEntryValue(null);
    setPlayerId(null);
    setPlayerItem(initialPlayerItem);
  };

  const returnToPlayerEntryModal = () => {
    onPlayerManualModalClose();
    handleOpen();
  };

  return (
    <MainLayout
      title="Players"
      buttonLabel="+ Add Player"
      buttonHandler={handleOpen}
    >
      {isLoading && <Spinner />}

      {isSuccess && loadedPlayers && loadedPlayers.length === 0 && (
        <SquareGrid
          height={275}
          width={250}
          bgColor={'transparent'}
          borderColor={colors.GRAY[150]}
          justifyContent={'center'}
        >
          <AddCircleButton
            buttonLabel="Add Player"
            buttonClickHandler={handleOpen}
          />
        </SquareGrid>
      )}

      <PlayerContent
        searchId={'__playerId'}
        data={loadedPlayers}
        placeholder={'Search Players'}
        handleEditModalOpenHook={handleEditPlayerManualModalOpen}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        onSearch={setQueryFilter}
        cancelFetchAll={cancelFetchAll}
        changedPlayerId={changedPlayerId}
      />

      <InfoEntryModal
        isOpen={playerEntryModalOpen}
        setOpen={setPlayerEntryModalOpen}
        setInfoEntry={setPlayerEntryValue}
        initialValue={{ playerEntry: 'manual' }}
        modalHeaderTitle="Add Player(s)"
        groupName="playerEntry"
        csvRadioTitle="Upload one or more players via a csv file"
        infoTitle="How would you like to enter player information?"
      />

      <AddEditPlayerModal
        isNewPlayer={isCreate}
        playerData={playerItem}
        leagueList={leagues}
        teamList={teams}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onSubmit={handleSubmit}
        onClose={onPlayerManualModalClose}
        error={createPlayerError}
        backToPlayerInfoEntryModal={returnToPlayerEntryModal}
      />

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default PlayersPage;
