import ModalComponent from './ModalComponent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import InputFieldComponent from '../Inputs/InputFieldComponent';
import { Row, Col } from 'react-bootstrap';

// And now we can use these
const AddEditCustomStatsModal = ({
  modalOpen,
  setModalOpen,
  onClose,
  onSubmit,
  initialValues,
}) => {
  const blankValues = {
    customStat1: '',
    customStat2: '',
    customStat3: '',
  };

  const validationSchema = Yup.object({
    customStat1: Yup.string().required('Custom Stat 1 is Required'),
    customStat2: Yup.string().required('Custom Stat 2 is Required'),
    customStat3: Yup.string().required('Custom Stat  is Required'),
  });

  function getHeaderTitle() {
    return 'Edit Custom Stats';
  }

  function getButtonTitle() {
    return 'Save Changes';
  }

  function getInitialValues() {
    var values = initialValues ? { ...initialValues } : blankValues;
    return values;
  }

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        isInitialValid={validationSchema.isValidSync(getInitialValues())}
        validateOnMount
        enableReinitialize
      >
        {(formik) => (
          <ModalComponent
            headerTitle={getHeaderTitle()}
            buttonTitle={getButtonTitle()}
            open={modalOpen}
            setOpen={setModalOpen}
            handleSubmit={(e) => {
              e.preventDefault();
              onSubmit(formik.values);
            }}
            modalWidth={400}
            handleClose={() => {
              formik.resetForm();
              onClose();
            }}
            buttonDisabled={!formik.isValid}
          >
            {/* Root of Grid */}
            <Row>
              {/* Image Row containing 2 columns of inputs */}
              <Col sm={12} className="p-1">
                <InputFieldComponent
                  required
                  label="Custom Stats"
                  name="customStat1"
                  type="text"
                  placeholder="Throw Ball"
                />
              </Col>
              <Col sm={12} className="p-1">
                <InputFieldComponent
                  required
                  label="Custom Stats"
                  name="customStat2"
                  type="text"
                  placeholder="ABC Stat"
                />
              </Col>
              <Col sm={12} className="p-1">
                <InputFieldComponent
                  required
                  label="Custom Stats"
                  name="customStat3"
                  type="text"
                  placeholder="DEF Stat"
                />
              </Col>
            </Row>
          </ModalComponent>
        )}
      </Formik>
    </>
  );
};

AddEditCustomStatsModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { AddEditCustomStatsModal };
