import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { 
  textEllipsisStyle, 
} from '../styles';
import {
  seasonFormatDisplay,
} from '../utils/seasonsUtil';

const gameInformationRowStyles = { 
  width: '100%', 
  height: 70, 
  margin: 0, 
  marginBottom: 30, 
  backgroundColor: '#ECECEC', 
  borderBottom: '1px solid #000000', 
};

const gameInfoItemContentStyle = {
  width: 'auto',
  height: '100%',
  textAlign: 'center',
  font: 'normal normal 600 13px/25px Open Sans',
  ...textEllipsisStyle,
};

const gameLeagueNameContentStyle = {
  width: 'auto',
  height: '100%',
  maxWidth: '80%',
  textAlign: 'left',
  font: 'normal normal normal 13px/25px Open Sans',
  ...textEllipsisStyle,
};

const gameLeagueAbbrvContentStyle = {
  width: 'auto',
  height: '100%',
  font: 'normal normal normal 13px/25px Open Sans',
};

const gameSeasonContentStyle = {
  width: 'auto',
  height: '100%',
  textAlign: 'right',
  font: 'normal normal normal 13px/25px Open Sans',
};

const teamNameRowContainerStyle = { 
  width: 'auto', 
  height: '100%',
  maxWidth: '45%', 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'flex-end', 
};

const leagueRowContainerStyle = { 
  width: 'auto', 
  height: '100%',
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'flex-end', 
};

const GameItem = styled.div`
  width: 75%;
  height: 70%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

const GameInfoItem = styled.div`
  width: ${props => props.width || '33.33%'};
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  border-left: ${props => props.borderLeft}px solid #D5D5D5;
  border-right: ${props => props.borderRight}px solid #D5D5D5;
  padding-left: ${props => props.paddingLeft}px;
  padding-right: ${props => props.paddingRight}px;
`;

const GameInformation = ({
  gameItem,
}) => {
  const {
    awayTeamName,
    homeTeamName,
    gameDateTime,
    venue,
    season,
    league,
  } = gameItem;

  return (
    <GameItem>
      <GameInfoItem
        paddingRight={10}
        justifyContent="flex-end"
      >
        <div style={teamNameRowContainerStyle}>
          <div style={gameInfoItemContentStyle}>
            {homeTeamName}
          </div>
        </div>
        <div style={{ ...gameInfoItemContentStyle, width: 25, paddingLeft: 3, paddingRight: 3, }}>
          Vs
        </div>
        <div style={teamNameRowContainerStyle}>
          <div style={gameInfoItemContentStyle}>
            {awayTeamName}
          </div>
        </div>
      </GameInfoItem>
      <GameInfoItem
        width='auto'
        justifyContent='center'
        borderLeft={2}
        borderRight={2}
      >
        <div style={{ ...gameInfoItemContentStyle, paddingLeft: 10, paddingRight: 10, }}>
          {moment(gameDateTime).format('MM/DD/YYYY, h:mmA')}
        </div>
      </GameInfoItem>
      <GameInfoItem
        justifyContent='flex-start'
        paddingLeft={10}
      >
        <div style={{ ...gameInfoItemContentStyle, maxWidth: '100%', }}>
          {venue.name}
        </div>
      </GameInfoItem>
      <GameInfoItem
        justifyContent='flex-end'
        paddingRight={10}
      >
        <div style={gameSeasonContentStyle}>
          {seasonFormatDisplay(season)}
        </div>
      </GameInfoItem>
      <GameInfoItem
        width='40%'
        justifyContent='flex-start'
        borderLeft={2}
        paddingLeft={10}
      >
        <div style={leagueRowContainerStyle}>
          <div style={gameLeagueNameContentStyle}>
            {league.name}
          </div>
          <div style={gameLeagueAbbrvContentStyle}>
            {`(${league.abbreviation})`}
          </div>
        </div>
      </GameInfoItem>
    </GameItem>
  );
};

const GameItemInformation = ({
  gameItem,
}) => {
  return (
    <Row style={gameInformationRowStyles}>
      <Col xl={12} xs={12} md={12} className="p-0 d-flex justify-content-center align-items-center">
        <GameInformation
          gameItem={gameItem}
        />
      </Col>
    </Row>
  );
};

export default GameItemInformation;