import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import RectangleIcon from '../../assets/RectangleSmall.png';
import PlayerJersey from '../../assets/regular-jersey@2x.png';
import OptionsOverflowMenu from '../../components/Menus/OptionOverflowMenu';
import { colors, textEllipsisStyle } from '../../styles';
import { PLAYERS_POSITIONS_ABBREVIATION, searchFilter } from '../../utils/playersUtil';
import {
  getHighlightedText, SearchMenuDiv,
  SearchMenuMainCriteria,
  SearchMenuSubCriteria,
  SearchMenuSubCriteriaDark
} from '../../utils/searchUtils';
import SquareGrid from '../Grids/SquareGrid';
import InfiniteScrollList from '../InfiniteListScroll';
import PageContent from './PageContent';

const activeStatusContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px 0px 0px 12px',
  position: 'absolute',
  top: 10,
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const imageContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 0,
};

const headShotDisplay = {
  width: 90,
  height: 90,
  borderRadius: 45,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.GRAY[150],
  overflow: 'hidden',
};

const imageStyle = {
  opacity: 1,
  objectFit: 'contain',
  width: 'inherit',
};

const playerName = {
  width: '100%',
  textAlign: 'center',
  font: 'normal normal 600 24px/25px Barlow Condensed',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
};

const jerseyNumberStyle = {
  position: 'absolute',
  top: 14,
  width: '100%',
  textAlign: 'center',
  font: 'normal normal 600 20px/25px Barlow Condensed',
};

const teamNameStyle = {
  width: '100%',
  height: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const teamNameTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal bold 14px/16px Open Sans',
  ...textEllipsisStyle,
};

const teamLeagueNameTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal normal 12px/16px Open Sans',
  color: '#585858',
};

const teamLeagueAbbreviationTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal normal 10px/16px Open Sans',
  color: '#585858',
};

const rectangleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'absolute',
  top: 58,
  margin: 0,
  width: '100%',
};

const positionStyle = {
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
  textAlign: 'center',
  font: 'normal normal normal 12px/16px Inter',
};

const positionItemStyle = {
  width: 'auto',
  display: 'inline-block',
  marginLeft: 2,
  marginRight: 2,
};

const TextLabel = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const PlayerItem = ({
  id,
  name,
  team,
  league,
  image,
  active,
  position,
  jerseyNumber,
  editTeamCtrl,
  history,
  hasChanged,
}) => {
  const itemRef = useRef();
  const [cardItemBorderColor, setCardItemBorderColor] = useState(
    colors.GRAY[150]
  );
  const [cardItemBorderWidth, setCardItemBorderWidth] = useState(1);

  useEffect(() => {
    if (hasChanged) {
      itemRef.current.scrollIntoView({ behavior: 'smooth' });
      setCardItemBorderWidth(2);
      setCardItemBorderColor(colors.BLUE[300]);
    } else {
      setCardItemBorderWidth(1);
      setCardItemBorderColor(colors.GRAY[150]);
    }
  }, [hasChanged]);

  return (
    <SquareGrid
      key={id}
      bgColor={colors.WHITE[100]}
      borderColor={cardItemBorderColor}
      borderWidth={cardItemBorderWidth}
      height={330}
      width={250}
      hasHover={true}
      onClick={() => history.push(`/admin/player/profile/${id}`)}
    >
      <div
        ref={itemRef}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          scrollMargin: 10,
        }}
      >
        <Row style={{ width: '100%', height: 40 }}>
          <Col xs={12} style={activeStatusContainer}>
            <div
              style={{
                ...activeStatusIndicator,
                backgroundColor: active ? '#2DCC38' : '#EE3B3B',
              }}
            ></div>
            <span style={activeStatusText}>
              {active ? 'Active' : 'Inactive'}
            </span>
          </Col>
        </Row>

        <Row style={{ width: '100%', height: 90, marginTop: 5 }}>
          <Col xs={12} style={imageContentStyle}>
            <div style={headShotDisplay}>
              <img
                src={image}
                style={{ ...imageStyle, objectFit: 'cover' }}
                width="90px"
                height="90px"
                alt=""
              />
            </div>
          </Col>
        </Row>

        <Row style={{ width: '100%', marginTop: 5 }}>
          <Col xs={12}>
            <div style={playerName}>{name}</div>
          </Col>
        </Row>

        <Row style={{ width: '100%', height: 50, marginTop: 10 }}>
          <Col xs={12} style={{ ...imageContentStyle, position: 'relative' }}>
            <img
              src={PlayerJersey}
              style={imageStyle}
              width="50px"
              height="50px"
              alt=""
            />
            <div style={jerseyNumberStyle}>{jerseyNumber}</div>
          </Col>
        </Row>

        <Row
          style={{
            width: '100%',
            height: 'auto',
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 15,
            backgroundColor: colors.GRAY[100],
            position: 'relative',
          }}
        >
          <Col xs={12}>
            <div style={teamNameStyle}>
              <div style={teamNameTextStyle}>{team.name}</div>
              <div style={teamLeagueNameTextStyle}>
                <TextLabel>{league.name}</TextLabel>
              </div>
              <div style={teamLeagueAbbreviationTextStyle}>
                {`- ${league.abbreviation} -`}
              </div>
            </div>
          </Col>
          <div style={rectangleStyle}>
            <img src={RectangleIcon} alt="Team Name" />
          </div>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col
            xs={12}
            style={{
              width: '100%',
              height: 60,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={positionStyle}>
              {position.map((pos, index) => {
                return index < position.length - 1 ? (
                  <React.Fragment key={PLAYERS_POSITIONS_ABBREVIATION[pos]}>
                    <div style={positionItemStyle}>
                      {PLAYERS_POSITIONS_ABBREVIATION[pos]}
                    </div>
                    <span style={{ color: '#BCBCBC', fontFamily: 'auto' }}>
                      &nbsp;|&nbsp;
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={PLAYERS_POSITIONS_ABBREVIATION[pos]}>
                    <div style={positionItemStyle}>
                      {PLAYERS_POSITIONS_ABBREVIATION[pos]}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </Col>
        </Row>

        <OptionsOverflowMenu
          menuItems={[
            { label: 'View', onClick: () => {} },
            {
              label: 'Edit',
              onClick: (e) => {
                editTeamCtrl(id);
                e.stopPropagation();
              },
            },
          ]}
        />
      </div>
    </SquareGrid>
  );
};

const PlayerContent = ({
  searchId,
  data,
  placeholder,
  handleEditModalOpenHook,
  fetchNextPage,
  hasNextPage,
  isFetching,
  onSearch,
  cancelFetchAll,
  changedPlayerId,
}) => {
  const history = useHistory();
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [playerSearch, setPlayerSearch] = useState('');
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((search) => {
        setDisplayedPlayers(
          data.filter((option) => searchFilter(option, search))
        );
      }, 200),
    [data]
  );

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (playerSearch) {
      /** Check if the input is an object or a search string */
      if (typeof playerSearch === 'object') {
        /** Object Selection */
        setDisplayedPlayers([playerSearch]);
        setIsSearchResultSelected(true);
        cancelFetchAll();
      } else if (playerSearch.length > 0) {
        debouncedSearch(playerSearch);
        setIsSearchResultSelected(false);
      }
    } else {
      /** Display ALL Players */
      setDisplayedPlayers(data);
      setIsSearchResultSelected(false);
    }
  }, [playerSearch, data, debouncedSearch, cancelFetchAll]);

  // Clear search when input clears
  useEffect(() => {
    if (!playerSearch) onSearch('');
  }, [playerSearch, onSearch]);

  const getDisplayedData = () => {
    return (
      <>
        {displayedPlayers?.map((player) => {
          const {
            id,
            name,
            jerseyNumber,
            position,
            team,
            league,
            image,
            active,
          } = player;

          return (
            <PlayerItem
              id={id}
              key={id}
              name={name}
              team={team}
              league={league}
              image={image}
              active={active}
              position={position}
              jerseyNumber={jerseyNumber}
              editTeamCtrl={handleEditModalOpenHook}
              history={history}
              hasChanged={changedPlayerId === id}
            />
          );
        })}
      </>
    );
  };

  return (
    <PageContent
      searchId={searchId}
      data={data}
      dataProperty={(option) => option.name}
      placeholder={placeholder}
      filterBy={(option, props) => searchFilter(option, props.text)}
      onChange={setPlayerSearch}
      isFetching={isFetching}
      onSearch={onSearch}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuDiv>
            <Row className="d-flex">
              <Col
                sm="2"
                style={{
                  alignSelf: 'center',
                }}
              >
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    backgroundColor: '#F6F6F6',
                    border: '1px solid #CFCFCF',
                    borderRadius: '25px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <SearchMenuMainCriteria
                    style={{
                      fontSize: '20px',
                      lineHeight: '25px',
                      margin: 'auto auto',
                    }}
                  >
                    {getHighlightedText(
                      String(option.jerseyNumber),
                      props.text
                    )}
                  </SearchMenuMainCriteria>
                </div>
              </Col>
              <Col sm="auto"></Col>
              <Col sm="9" className="d-grid p-0">
                <SearchMenuMainCriteria
                  style={{ fontSize: '20px', lineHeight: '25px' }}
                >
                  {/* {getHighlightedText(String(option.jerseyNumber), props.text)} */}
                  {getHighlightedText(option.name, props.text)}
                </SearchMenuMainCriteria>
                <SearchMenuSubCriteriaDark>
                  {getHighlightedText(option.team.name, props.text)}
                </SearchMenuSubCriteriaDark>
                <SearchMenuSubCriteria>
                  {getHighlightedText(option.league.name, props.text)} (
                  {getHighlightedText(option.league.abbreviation, props.text)})
                </SearchMenuSubCriteria>
                {playerSearch.length > 0 &&
                  option.position
                    .join(', ')
                    .toLowerCase()
                    .includes(playerSearch.toLowerCase()) && (
                    <SearchMenuSubCriteria>
                      {getHighlightedText(
                        option.position.join(', '),
                        props.text
                      )}
                    </SearchMenuSubCriteria>
                  )}
                {playerSearch.length > 0 &&
                  option.institute
                    .toLowerCase()
                    .includes(playerSearch.toLowerCase()) && (
                    <SearchMenuSubCriteria>
                      {getHighlightedText(option.institute, props.text)}
                    </SearchMenuSubCriteria>
                  )}
              </Col>
            </Row>
          </SearchMenuDiv>
        );
      }}
    >
      <InfiniteScrollList
        displayedData={displayedPlayers}
        fetchNextPage={fetchNextPage}
        hasNextPage={isSearchResultSelected ? false : hasNextPage}
        displayedDataFilter={getDisplayedData}
      />
    </PageContent>
  );
};

export default PlayerContent;
