import React, { useEffect, useRef, } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { textEllipsisStyle, colors, } from '../styles';

const dimensionsStyle = {
  width: 720, 
  height: 40, 
  display: 'flex', 
  flexDirection: 'row', 
  borderRadius: 7, 
  opacity: 0, 
  position: 'fixed', 
};

const textContainerStyle = { 
  width: '95%', 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignItems: 'center', 
  color: colors.WHITE[100], 
  font: 'normal normal 600 16px/21px "Segoe UI"', 
};

const iconContainerStyle = { 
  width: '5%', 
  height: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
};

const FloatingTextNotification = ({
  bgColor = '#25A9E1', 
  subjectTitle = '', 
  textDisplay = '', 
  topPosition = 50,
  isNotified = false, 
  setIsNotified = () => {},
}) => {
  const notificationRef = useRef();

  useEffect(() => {
    if(isNotified) {
      notificationRef.current?.classList.add('fade-In-fade-out');

      setTimeout(() => removeNotification(), 5000);
    }
  }, [isNotified]);

  const removeNotification = () => {
    notificationRef.current?.classList.remove('fade-In-fade-out');
    setIsNotified(false);    
  };

  return (
    <div style={{ width: '100%', height: 'auto', paddingLeft: 60 }}>
      <div ref={notificationRef} style={{ ...dimensionsStyle, backgroundColor: bgColor, top: topPosition, }}>
        <div style={textContainerStyle}>
          {subjectTitle.length > 0
            ?
            <>
              <div style={{ ...textEllipsisStyle, textAlign: 'right', width: (subjectTitle.length <= 40) ? '30%' : '50%', }}>
                {subjectTitle}
              </div>
              <div style={{ paddingLeft: 5, textAlign: 'left', width: 'auto', }}>
                {textDisplay}
              </div>
            </>
            :
            <>
              {textDisplay}
            </>
          }
        </div>
        <div style={iconContainerStyle} onClick={removeNotification}>
          <CloseIcon width={20} height={20} style={{ color: colors.WHITE[100], }}/>
        </div>
      </div>
    </div>
  );
};

export default FloatingTextNotification;