import React, { useMemo } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import {
  calculateGamePeriods,
  isFinalQuarterEnded,
  isOvertimeEnded,
} from '../../utils/gameTimeUtil';
import { SmallButton } from '../ScoreKeeper-new/Scoreboard';
import { colors, gradients } from '../../styles/colorsStatsCollector';
import { StyledFlexRow } from '../../styles';

const GamePeriods = ({
  gameTimer,
  numPeriods,
  localOvertimeNumber,
  handleOvertimeClick,
  isTied,
  overTimeStartEnabled,
}) => {
  const quarter = gameTimer?.quarter;
  const overtimeTracker = gameTimer?.overtimeTracker;
  const localStorage = window.localStorage;

  const isStartOvertimeDisabled = () => {
    return (
      !(
        isFinalQuarterEnded(gameTimer, numPeriods) || isOvertimeEnded(gameTimer)
      ) ||
      !isTied ||
      overTimeStartEnabled
    );
  };

  const isOvertime = gameTimer?.overtimeTracker || localOvertimeNumber;
  const isAdditionalOvertimeSelected =
    localOvertimeNumber > (gameTimer?.overtimeTracker || 0);

  /** Displays the overtime value determined by the larger of the gametimer overtime
   * and the "local" overtime value (which increments when the "+" enable additional
   * overtime button is selected)
   */
  const overtimeText = () => {
    const overtimeValue = isOvertime
      ? isAdditionalOvertimeSelected
        ? localOvertimeNumber
        : gameTimer?.overtimeTracker
      : null;
    return overtimeValue !== null ? `Overtime ${overtimeValue}` : 'Overtime';
  };

  const periods = useMemo(
    () =>
      calculateGamePeriods(
        numPeriods,
        quarter,
        overtimeTracker ? overtimeTracker : localOvertimeNumber
      )
        .slice(0, -1)
        .map((p, idx) => (
          <GamePeriodButton
            key={idx}
            disabled={!!p.disabled}
            $isActive={!!p.active}
          >
            {p.label}
          </GamePeriodButton>
        )),
    [numPeriods, quarter, overtimeTracker, localOvertimeNumber]
  );

  return (
    <Grid container>
      <Grid item xs={6} style={{ paddingRight: '6px' }}>
        <Row>{periods}</Row>
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: '6px' }}>
        <Row>
          <StyledOvertimeDiv isOvertime={isOvertime}>
            {overtimeText()}
          </StyledOvertimeDiv>
          <div style={{ maxWidth: '21%' }}>
            <AddDiv
              gameTimer={gameTimer}
              onClick={handleOvertimeClick}
              isTied={isTied}
              disabled={isStartOvertimeDisabled()}
            />
          </div>
        </Row>
      </Grid>
      {/* <Grid item xs={1}> */}
      {/* </Grid> */}
    </Grid>
  );
};

const AddDiv = ({ gameTimer, onClick, isTied, disabled }) => {
  return (
    <StyledAddButton onClick={onClick} disabled={disabled}>
      <AddIcon fontSize="inherit" />
    </StyledAddButton>
  );
};

const GamePeriodButton = styled(SmallButton)`
  &&& {
    flex: 1;
    height: 34px;
    width: 34px;
    background: none;
    cursor: default;
    border: ${({ $isActive, theme: { lightMode } }) =>
      $isActive
        ? `2px solid ${colors.BLUE[100]}`
        : `1px solid ${colors.GRAY[1700]}`};

    &:disabled {
      background: none;
    }
  }
`;

const StyledOvertimeDiv = styled.div`
  color: ${({ theme: { lightMode } }) =>
    lightMode ? colors.BLACK[100] : colors.WHITE[100]};
  border: ${({ isOvertime }) =>
    isOvertime ? '2px solid #438EFD' : '1px solid rgba(112, 112, 112, 1)'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  cursor: default;
  font: normal normal 500 11px/14px Inter;
  letter-spacing: 0px;
  flex: 1;
  margin-right: 6px;
  opacity: ${({ isOvertime }) => (isOvertime ? '1' : '0.4')};
`;

const StyledAddButton = styled.button`
  background: ${({ disabled }) =>
    disabled
      ? 'linear-gradient(180deg, #545454 0%, #171717 100%)'
      : gradients.BLUE};

  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: arial;
  color: ${colors.WHITE[100]};
  height: 34px;
  width: 34px;
  max-width: 100%;

  &:disabled {
    opacity: 0.4;
  }
`;

const Row = styled(StyledFlexRow)`
  &&& {
    align-items: center;
    // justify-content: space-around;
    // margin-right: 10px;
  }
`;

export default GamePeriods;
