import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles';
import { textEllipsisStyle } from '../../../styles/typography';

const ItemContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #D5D5D5;
`;

const ImageContainer = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PlayerContainer = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const PlayerRecordContainer = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D5D5D5;
  border-left: 1px solid #D5D5D5;
`;

const PlayerStatsRecordContainer = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PlayerStatsInfoContainer = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: ${props => props.width === '0px' ? '0px' : '1px'} solid #D5D5D5;
`;

const HeadshotContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #D5D5D5;
  padding-left: 4px;
`;

const Headshot = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.GRAY[150]};
  overflow: hidden;
`;

const PlayerInformationContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PlayerInformationData = styled.div`
  width: 99%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems};
  font: ${props => `normal normal ${props.fontWeight} ${props.fontSize}px/${props.lineHeight}px 'Segoe UI'`};
  text-align: left;
  padding-right: 3px;
  padding-left: 5px;
`;

const PlayerStatsTitle = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font: normal normal normal 12px/14px 'Segoe UI';
`;

const PlayerStatsValue = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font: normal normal 14px/16px 'Segoe UI Bold';
`;

const GamePlayerImage = ({
  teamImage, 
}) => {
  return (
    <ImageContainer>
      <img
        src={teamImage}
        width="40px"
        height="40px"
        style={{ objectFit: 'cover', border: '4px solid #D5D5D5', borderRadius: 5, }}
        alt=""
      />
    </ImageContainer>
  );
};

const GamePlayerInformation = ({
  width,
  teamPlayer, 
}) => {
  return (
    <PlayerContainer
      width={width}
    >
      <HeadshotContainer>
        {teamPlayer?.playerImage && 
          <Headshot>
            <img
              src={teamPlayer?.playerImage}
              style={{ opacity: 1, objectFit: 'cover', }}
              width="100%"
              height="100%"
              alt=""
            />
          </Headshot>
        }
      </HeadshotContainer>
      <PlayerInformationContainer>
        <PlayerInformationData
          alignItems='flex-end'
          fontSize={12}
          fontWeight={800}
          lineHeight={32}
          style={textEllipsisStyle}
        >
          {teamPlayer?.playerName}
        </PlayerInformationData>
        <PlayerInformationData
          alignItems='flex-start'
          fontSize={10}
          fontWeight='normal'
          lineHeight={18}
          style={textEllipsisStyle}
        >
          {teamPlayer?.playerPosition}
        </PlayerInformationData>
      </PlayerInformationContainer>
    </PlayerContainer>
  );
};

const PlayerStats = ({
  playerStat,
}) => {
  return (
    <>
      <PlayerStatsValue>
        {playerStat?.stat}
      </PlayerStatsValue>
      <PlayerStatsTitle>
        {playerStat?.title}
      </PlayerStatsTitle>
    </>
  );
};

const GameLeaderItem = ({
  teamImage,
  teamPlayer,
  playerRecord,
  playerStatRecord,
  playerStatInfo,
}) => {
  const computeContainerWidth = (statColumnValue, teamPlayer) => {
    if(teamPlayer && statColumnValue != null) {
      return '15%';
    }
    else if(teamPlayer && statColumnValue == null) {
      return '22.5%';
    }
    else if(!teamPlayer && statColumnValue != null) {
      return '28%';
    }
    else if(!teamPlayer && !statColumnValue) {
      return '45%';
    }
  };

  return (
    <ItemContainer>
      <GamePlayerImage
        teamImage={teamImage}
      />
      {teamPlayer && 
        <GamePlayerInformation
          width={playerStatInfo?.stat != null ? '40%' : '44.75%'}
          teamPlayer={teamPlayer}
        />
      }
      <PlayerRecordContainer
        width={computeContainerWidth(playerStatInfo?.stat, teamPlayer)}
      >
        <PlayerStats
          playerStat={playerRecord}
        />
      </PlayerRecordContainer>
      <PlayerStatsRecordContainer
        width={computeContainerWidth(playerStatInfo?.stat, teamPlayer)}
      >
        <PlayerStats
          playerStat={playerStatRecord}
        />
      </PlayerStatsRecordContainer>
      <PlayerStatsInfoContainer
        width={playerStatInfo?.stat != null ? computeContainerWidth(playerStatInfo?.stat, teamPlayer) : '0px'}
      >
        <PlayerStats
          playerStat={playerStatInfo}
        />
      </PlayerStatsInfoContainer>
    </ItemContainer>
  );
};

export default GameLeaderItem;