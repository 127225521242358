import { createSlice } from '@reduxjs/toolkit';
import { GameTabsSliceObj } from './gameTabsReduxSliceUtil';

export const homePageTabsSlice = createSlice({
	name: 'homePageTabs',
	...GameTabsSliceObj,
});

export const { updateActiveTab, updateCanChangeTabs } = homePageTabsSlice.actions;

export const selectActiveTab = (state) => state.homePageTabs.activeTab

export const selectCanChangeTabs = (state) => state.homePageTabs.canChangeTabs;

export default homePageTabsSlice.reducer;
