import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { gamesKeys, useDerivedGameLineupDataQuery } from '../api/gamesQueries';
import { createGameLineup, getGame, updateGame } from '../api/gamesService';
import { useLeaguesQuery } from '../api/leaguesQueries';
import { getLeagueImageProfile } from '../api/leaguesService';
import { useSeasonsQuery } from '../api/seasonsQueries';
import { useTeamsQuery } from '../api/teamsQueries';
import { getTeam } from '../api/teamsService';
import { useVenuesQuery } from '../api/venuesQueries';
import { getVenueImageProfile } from '../api/venuesService';
import ToastAlert from '../components/alerts/ToastAlert';
import MainLayout from '../components/layout/MainLayout';
import PageContainerLayout from '../components/layout/PageContainerLayout';
import AddEditGameModal from '../components/Modals/AddEditGameModal';
import AddPlayersModal from '../components/Modals/AddPlayers/AddPlayersModal';
import AssignGameUserRolesModal from '../components/Modals/AssignGameUserRolesModal';
import AssignUserGamesModal from '../components/Modals/AssignUserGamesModal';
import GameDetailsContent from '../components/PageContent/GameDetailsContent';
import { ITEM_TYPE } from '../utils/constantsUtils';
import { transformPlayerLineupData } from '../utils/gameLineupUtils';
import {
  aggregateBooleanPropUsingOr,
  useFetchAll,
} from '../utils/reactQueryToolkit';
import { addUserToTeam } from '../utils/userUtil';
const log = require('../logger')('adminPanel/GameDetailsPage'); // eslint-disable-line no-unused-vars

const GameDetailsPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [gameItem, setGameItem] = useState();
  const [open, setOpen] = useState(false);
  const [createGameError, setCreateGameError] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isHomeTabSelected, setIsHomeTabSelected] = useState(false);
  const [gameIsStarted, setIsGameStarted] = useState(false); // eslint-disable-line no-unused-vars
  const [showSpinner, setShowSpinner] = useState(false);

  const [homeTeamGameLineupId, setHomeTeamGameLineupId] = useState();
  const [awayTeamGameLineupId, setAwayTeamGameLineupId] = useState();

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastVariant, setToastVariant] = useState();

  const [pickedItems, setPickedItems] = useState([]);
  const [removePickedItems, setRemovePickedItems] = useState([]);

  const [openUsersModal, setOpenUsersModal] = useState(false);
  const [openUserRolesModal, setOpenUserRolesModal] = useState(false);
  const [isCancelledBtnClicked, setIsCancelledBtnClicked] = useState(false);
  const [itemType, setItemType] = useState();
  const [assignedUserIds, setAssignedUserIds] = useState([]);
  const [isUserRolesQueried, setIsUserRolesQueried] = useState(false);

  const homeTeamPlayersRef = useRef([]);
  const awayTeamPlayersRef = useRef([]);

  useEffect(() => {
    getGameFromId(id);
  }, []);

  useEffect(() => {
    setOpenUsersModal(itemType === ITEM_TYPE.COACH);
  }, [itemType]);

  useEffect(() => {
    setOpenUserRolesModal(pickedItems.length > 0);
  }, [pickedItems]);

  useEffect(() => {
    if (isCancelledBtnClicked) {
      setItemType(null);
      setRemovePickedItems([]);
    }
  }, [isCancelledBtnClicked]);

  useEffect(() => {
    if (!isUserRolesQueried) {
      setShowToast(false);
      setToastVariant('danger');
      setToastText('');
    }
  }, [isUserRolesQueried]);

  const {
    data: {
      homeTeamRosterPlayers,
      homeTeamLineupPlayers = [],
      homeTeamOnCourtPlayers = [],
      homeTeamOnBenchPlayers = [],
      awayTeamRosterPlayers,
      awayTeamLineupPlayers = [],
      awayTeamOnCourtPlayers = [],
      awayTeamOnBenchPlayers = [],
    },
    isLoading,
    isSuccess,
    isIdle,
  } = useDerivedGameLineupDataQuery(
    homeTeamGameLineupId,
    awayTeamGameLineupId,
    gameItem?.homeTeamId,
    gameItem?.awayTeamId
  );

  const { onSuccessCallback } = useFetchAll(true);

  const teamsQuery = useTeamsQuery({
    onSuccess: (data) => onSuccessCallback(teamsQuery)(data),
  });
  const teams = useMemo(
    () => teamsQuery.data.sort((a, b) => a.name.localeCompare(b.name)),
    [teamsQuery.data]
  );

  const seasonsQuery = useSeasonsQuery({
    onSuccess: (data) => onSuccessCallback(seasonsQuery)(data),
  });
  const seasons = useMemo(
    () =>
      seasonsQuery.data.sort(
        (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
      ),
    [seasonsQuery.data]
  );

  const venuesQuery = useVenuesQuery({
    onSuccess: (data) => onSuccessCallback(venuesQuery)(data),
  });
  const venues = useMemo(
    () => venuesQuery.data.sort((a, b) => a.name.localeCompare(b.name)),
    [venuesQuery.data]
  );

  const leaguesQuery = useLeaguesQuery({
    onSuccess: (data) => onSuccessCallback(leaguesQuery)(data),
  });
  const leagues = leaguesQuery.data;

  const isModalDataLoading = aggregateBooleanPropUsingOr(
    [teamsQuery, seasonsQuery, venuesQuery, leaguesQuery],
    'isLoading'
  );

  const getGameFromId = async (gameId) => {
    const game = await getGame(gameId);
    const {
      leagueId,
      venueId,
      homeTeamId,
      awayTeamId,
      homeTeamGameLineupId,
      awayTeamGameLineupId,
    } = game;

    const leagueImage = await getLeagueImageProfile(leagueId);
    const venueImage = await getVenueImageProfile(venueId);
    const homeTeam = await getTeam(homeTeamId);
    const awayTeam = await getTeam(awayTeamId);

    homeTeamPlayersRef.current = homeTeam.players.items.map(
      transformPlayerLineupData
    );
    awayTeamPlayersRef.current = awayTeam.players.items.map(
      transformPlayerLineupData
    );

    const league = {
      id: game.leagueId,
      image: leagueImage,
      name: game.leagueName,
      abbreviation: game.leagueAbbreviation,
    };

    const venue = {
      name: game.venueName,
      city: game.venueCity,
      state: game.venueState,
      zipcode: game.venueZipcode,
      image: venueImage,
    };

    const season = {
      startDate: game.seasonStartDate,
      endDate: game.seasonEndDate,
      seasonType: game.seasonType,
    };

    game.league = league;
    game.venue = venue;
    game.season = season;
    game.homeTeamImage = homeTeam.image;
    game.awayTeamImage = awayTeam.image;

    setGameItem(game);

    let _homeTeamGameLineupId;
    let _awayTeamGameLineupId;

    if (!homeTeamGameLineupId || !awayTeamGameLineupId) {
      if (!homeTeamGameLineupId) {
        const homeTeamGameLineupData = await createGameLineup(
          gameId,
          homeTeamId
        );
        _homeTeamGameLineupId = homeTeamGameLineupData.data.createGameLineup.id;
      }

      if (!awayTeamGameLineupId) {
        const awayTeamGameLineupData = await createGameLineup(
          gameId,
          awayTeamId
        );
        _awayTeamGameLineupId = awayTeamGameLineupData.data.createGameLineup.id;
      }

      // eslint-disable-next-line no-unused-vars
      const updatedGameItem = await updateGame(gameId, {
        ...game,
        homeTeamGameLineupId: homeTeamGameLineupId || _homeTeamGameLineupId,
        awayTeamGameLineupId: awayTeamGameLineupId || _awayTeamGameLineupId,
      });
    }

    setHomeTeamGameLineupId(homeTeamGameLineupId || _homeTeamGameLineupId);
    setAwayTeamGameLineupId(awayTeamGameLineupId || _awayTeamGameLineupId);

    setShowSpinner(false);
  };

  const handleSubmit = async (values) => {
    let venue = venues.find(({ id }) => values.venueId === id);

    venue = {
      venueId: venue.id,
      venueName: venue.name,
      venueCity: venue.city,
      venueState: venue.state,
      venueZipcode: venue.zipcode,
      venueActive: venue.active,
    };

    values = {
      ...values,
      ...venue,
    };

    const returnData = await updateGame(values.id, values);

    const { homeTeamId, awayTeamId } = values;

    const homeTeam = teams.find(({ id }) => homeTeamId === id);
    const awayTeam = teams.find(({ id }) => awayTeamId === id);

    if (returnData.errors) {
      setCreateGameError(
        `Unable to update game. ${returnData.errors[0].message}.`
      );
    } else {
      setOpen(false);
      setCreateGameError(null);
      setToastVariant('success');
      setToastText(
        `The "${homeTeam.name} vs ${awayTeam.name}" was updated successfully.`
      );
      setShowToast(true);
      queryClient.invalidateQueries(gamesKeys.all);
      await getGameFromId(values.id);
    }
  };

  const pickedCoaches = (coaches) => {
    coaches = coaches.map(({ id, firstName, lastName, role }) => ({
      id,
      name: `${firstName} ${lastName}`,
      role,
    }));
    setPickedItems(coaches);
  };

  const getSelectedPickedItems = (itemType, pickedItems) => {
    switch (itemType) {
      case ITEM_TYPE.COACH:
        return pickedCoaches(pickedItems);
      default:
        log.error('Unable to select picked items for said item type....');
    }
  };

  const getModalTitle = (itemType) => {
    switch (itemType) {
      case ITEM_TYPE.COACH:
        return 'Assign Coaches to Teams';
      default:
        return null;
    }
  };

  const getAssignedColumnTitle = (itemType) => {
    switch (itemType) {
      case ITEM_TYPE.COACH:
        return 'Assign Team';
      default:
        return null;
    }
  };

  const getMenuOptionItems = (itemType) => {
    if (itemType === ITEM_TYPE.COACH) {
      const {
        homeTeamName,
        homeTeamId,
        awayTeamName,
        awayTeamId,
        seasonId,
        leagueId,
      } = gameItem;
      return [
        {
          team: {
            id: homeTeamId,
            seasonId,
            leagueId,
            teamName: homeTeamName,
            games: [gameItem],
          },
          name: homeTeamName,
          value: homeTeamName,
        },
        {
          team: {
            id: awayTeamId,
            seasonId,
            leagueId,
            teamName: awayTeamName,
            games: [gameItem],
          },
          name: awayTeamName,
          value: awayTeamName,
        },
      ];
    }

    return null;
  };

  const reopenAssignUserItemsModalBackHandler = (pickedItemsRemoved) => {
    setRemovePickedItems(pickedItemsRemoved);
    closeUserRoleAssignmentModalHandler();
    setTimeout(() => setOpenUsersModal(true), 250);
  };

  const submitTeamUserParams = async (teamUserParams) => {
    const createTeamUsersResponse = await addUserToTeam(teamUserParams);

    if (!createTeamUsersResponse.error) {
      setToastVariant('success');
      setToastText('Team user coaches was assigned successfully.');
      setShowToast(true);
      setIsUserRolesQueried(true);
    } else {
      setToastVariant('danger');
      setToastText('Team user coaches assignment failed.');
      setShowToast(true);
    }
  };

  const submitGameUserParams = async (gameUserParams) => {
    //TODO: PROVIDE IMPLEMENTATION DETAILS FOR ASSIGNING GAME USER ROLE.
  };

  const submitParamsHandler = async (itemParams) => {
    switch (itemType) {
      case ITEM_TYPE.COACH:
        return await submitTeamUserParams(itemParams);
      case ITEM_TYPE.GAME:
        return await submitGameUserParams(itemParams);
      default:
        return log.error(
          'Unable to handle role assignment for the given game.'
        );
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCreateGameError(null);
  };

  const closeUsersModalHandler = () => {
    setOpenUsersModal(false);
  };

  const closeUserRoleAssignmentModalHandler = () => {
    setOpenUserRolesModal(false);
  };

  return (
    <MainLayout title="Game Details" navigationLinkBack={'/admin/games'}>
      {(isIdle || isLoading || showSpinner) && (
        <Spinner
          animation="border"
          role="status"
          style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 100 }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      {gameItem && (
        <AddEditGameModal
          isNewGame={false}
          gameData={gameItem}
          modalOpen={open}
          setModalOpen={setOpen}
          teamList={teams}
          seasonList={seasons}
          venueList={venues}
          leaguesList={leagues}
          onSubmit={handleSubmit}
          onClose={handleClose}
          error={createGameError}
          isLoading={isModalDataLoading}
          setError={setCreateGameError}
        />
      )}

      <AssignUserGamesModal
        modalOpen={openUsersModal}
        setModalOpen={setOpenUsersModal}
        doneHandler={(pickedItems) =>
          getSelectedPickedItems(itemType, pickedItems)
        }
        onClose={closeUsersModalHandler}
        assignedUserIds={assignedUserIds}
        itemType={itemType}
        gameItem={gameItem}
        isCancelledBtnClicked={isCancelledBtnClicked}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        pickedGamesRemoved={removePickedItems}
      />

      <AssignGameUserRolesModal
        isNewGameRoleAssignment={true}
        isGameRolesEditable={false}
        games={pickedItems}
        itemType={itemType}
        modalOpen={openUserRolesModal}
        setModalOpen={setOpenUserRolesModal}
        submitHandler={submitParamsHandler}
        backHandler={reopenAssignUserItemsModalBackHandler}
        onClose={closeUserRoleAssignmentModalHandler}
        setIsCancelledBtnClicked={setIsCancelledBtnClicked}
        modalTitle={getModalTitle(itemType)}
        assignedOptionList={getMenuOptionItems(itemType)}
        assignedColumnTitle={getAssignedColumnTitle(itemType)}
        gameItem={gameItem}
      />

      {isSuccess && (
        <>
          <AddPlayersModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            gameStarted={gameIsStarted}
            notStarted={!gameIsStarted}
            setModalOpen={setOpenModal}
            numPlayersOnBench={gameItem?.leagueNumPlayersOnBench}
            numPlayersOnCourt={gameItem?.leagueNumPlayersOnCourt}
            teamSize={gameItem?.leagueTeamSize}
            gameId={id}
            gameData={gameItem}
            homeRosterPlayers={homeTeamRosterPlayers}
            homeLineupPlayers={homeTeamLineupPlayers}
            homeCourtPlayers={homeTeamOnCourtPlayers}
            homeBenchPlayers={homeTeamOnBenchPlayers}
            homeTeamLineupOnCourtOnBenchPlayers={[
              ...homeTeamLineupPlayers,
              ...homeTeamOnCourtPlayers,
              ...homeTeamOnBenchPlayers,
            ]}
            homeReadImage={gameItem?.homeTeamImage}
            homeTeamName={gameItem?.homeTeamName}
            awayRosterPlayers={awayTeamRosterPlayers}
            awayLineupPlayers={awayTeamLineupPlayers}
            awayCourtPlayers={awayTeamOnCourtPlayers}
            awayBenchPlayers={awayTeamOnBenchPlayers}
            awayTeamLineupOnCourtOnBenchPlayers={[
              ...awayTeamLineupPlayers,
              ...awayTeamOnCourtPlayers,
              ...awayTeamOnBenchPlayers,
            ]}
            awayReadImage={gameItem?.awayTeamImage}
            awayTeamName={gameItem?.awayTeamName}
            isHomeModalSelected={isHomeTabSelected}
            setHomeModalSelected={setIsHomeTabSelected}
            homeTeamLineup={gameItem?.homeTeamGameLineup}
            awayTeamLineup={gameItem?.awayTeamGameLineup}
            homeTeamGameLineupId={homeTeamGameLineupId}
            awayTeamGameLineupId={awayTeamGameLineupId}
          />

          <PageContainerLayout>
            {gameItem && isSuccess && (
              <GameDetailsContent
                game={gameItem}
                setAddEditGameModalOpen={setOpen}
                setAddPlayersModalOpen={setOpenModal}
                setIsHomeTabSelected={setIsHomeTabSelected}
                homeCourtPlayers={homeTeamOnCourtPlayers}
                homeBenchPlayers={homeTeamOnBenchPlayers}
                awayCourtPlayers={awayTeamOnCourtPlayers}
                awayBenchPlayers={awayTeamOnBenchPlayers}
                setItemType={setItemType}
                setAssignedUserIds={setAssignedUserIds}
                isUserRolesQueried={isUserRolesQueried}
                setIsUserRolesQueried={setIsUserRolesQueried}
              />
            )}
          </PageContainerLayout>
        </>
      )}

      <ToastAlert
        text={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
        variant={toastVariant}
      />
    </MainLayout>
  );
};

export default GameDetailsPage;
