import { API, graphqlOperation } from 'aws-amplify';
import {
  listVenues as listVenuesQuery,
  getVenue as getVenueQuery,
  getVenueByUpdatedAt as getVenueByUpdatedAtQuery,
} from '../graphql/queries';
import {
  createVenue as createVenueMutation,
  updateVenue as updateVenueMutation,
} from '../graphql/mutations';
import { Storage } from '@aws-amplify/storage';
import UploadFileImage from '../assets/FileUpload.png';
import { KEY_SORT_ID } from '../utils/constantsUtils';
const log = require('../logger')('api');

Storage.configure({ level: 'public' });

const saveVenueImageProfile = async (venueId, imageFile) => {
  try {
    const response = await Storage.put(`venue/${venueId}`, imageFile, {
      level: 'public',
    });
    return response;
  } catch (error) {
    log.error(`Unable to save image ${venueId} to storage...`);
  }
};

export const getVenueImageProfile = async (venueId) => {
  try {
    const doesImageExist = await Storage.list(`venue/${venueId}`);
    if (doesImageExist.length > 0) {
      const imageURL = await Storage.get(`venue/${venueId}`);
      return imageURL;
    } else {
      return UploadFileImage;
    }
  } catch (error) {
    log.error(`Unable to read image ${venueId} from storage...`);
  }
};

// const deleteVenueImageProfile = async (venueId) => {
//   try {
//     const deletedImage = await Storage.remove(`venue/${venueId}`);
//     return deletedImage;
//   } catch (error) {
//     log.error(`Unable to delete image ${venueId} from storage...`);
//   }
// };

// get call with parameter
export const listVenues = async (variables = null) => {
  try {
    const venueData = await API.graphql(
      graphqlOperation(listVenuesQuery, variables)
    );
    // Return extracted the League Items
    return venueData.data.listVenues;
  } catch (err) {
    log.error('error fetching venues...', err);
    return err;
  }
};

export const listVenuesSortedByUpdatedAt = async (variables = {}) => {
  const vars = { sortDirection: 'DESC', ...variables, keySortId: KEY_SORT_ID };
  try {
    const venueData = await API.graphql(
      graphqlOperation(getVenueByUpdatedAtQuery, vars)
    );
    return venueData.data.getVenueByUpdatedAt;
  } catch (err) {
    log.error('error fetching venues...', err);
    return err;
  }
};

// get call with parameter
export const getVenue = async (id) => {
  try {
    const venueData = await API.graphql(
      graphqlOperation(getVenueQuery, { id: id })
    );
    const venueItem = venueData.data.getVenue;
    // TODO: Should we return the image here too, or make it optional
    venueItem.image = await getVenueImageProfile(id);
    return venueItem;
  } catch (err) {
    log.error('error fetching venue...', err);
    return err;
  }
};

//create call with parameter

export const createVenue = async (
  name,
  city,
  zipcode,
  state,
  image,
  active
) => {
  try {
    const venueData = await API.graphql(
      graphqlOperation(createVenueMutation, {
        input: {
          keySortId: KEY_SORT_ID,
          name: name,
          city: city,
          zipcode: zipcode,
          state: state,
          active: active,
          searchName: name.toLowerCase(),
          searchCity: city.toLowerCase(),
          searchState: state.toLowerCase(),
        },
      })
    );
    const { id } = venueData.data.createVenue;
    await saveVenueImageProfile(id, image);
    return venueData;
  } catch (err) {
    log.error('error creating venue...', err);
  }
};

export const updateVenue = async (id, values) => {
  /**
   * Only the active atatus is allowed to be updated
   */
  try {
    const venueData = await API.graphql(
      graphqlOperation(updateVenueMutation, {
        input: {
          id: id,
          active: values.active,
          _version: values._version,
        },
      })
    );
    return venueData;
  } catch (err) {
    log.error('error updating venue...', err);
    return err;
  }
};

// export const deleteVenue = async (id) => {
//   try {
//     const venueData = await API.graphql(
//       graphqlOperation(deleteVenueMutation, {
//         input: { id: id, _version: 1 },
//       })
//     );
//     // const venueImage = await deleteVenueImageProfile(id);
//     return venueData;
//   } catch (err) {
//     log.error('error deleting venue...', err);
//   }
// };
