import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, Box } from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import ButtonElement from '../Buttons/ButtonElement';
import { typeScale } from '../../styles/typography';
import { colors } from '../../styles/colors';
import { buttonThemes } from '../../styles/themes';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styled from "styled-components";

const ModalHeaderText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
  text-align: left;
  word-break: break-all;
`;

const ModalComponent = ({
  headerTitle,
  buttonTitle,
  open,
  setOpen,
  handleSubmit,
  children,
  modalWidth,
  footerContent,
  buttonDisabled = false,
  buttonDimmed = false,
  isDelete = false,
  isFooterVisible = true,
  showNextButton = false,
  showBackButton = false,
  handleClose = () => setOpen(false),
  nextHandler = () => {},
  backHandler = () => {},
  customModalButtons = [],
  footerText, 
  modalBackgroundColor = colors.WHITE[100], 
  modalHeaderStyles = {}, 
  modalBodyStyles = {},
  buttonContainerStyles = {},
  footerTextStyles = {},
  modalLoadCompleted = () => {},
}) => {
  const classes = useStyles();

  const ModalHeader = ({
    styles, 
  }) => {
    return (
      <div style={{ marginBottom: 24, ...styles, }} className={classes.modalHeader}>
        <h2 className={classes.modalHeaderText} id="simple-modal-title">
          <ModalHeaderText>{headerTitle}</ModalHeaderText>
        </h2>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIconButton} />
        </IconButton>
      </div>
    );
  };

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={handleClose}
      onLoad={modalLoadCompleted}
      disableBackdropClick={true}  /* This prop prevents the closing of the modal when the user clicks outside of the modal */
    >
      <div style={{ width: modalWidth, backgroundColor: modalBackgroundColor, }} className={classes.paper}>
        <ModalHeader styles={{ ...modalHeaderStyles }} />
        {!footerContent ? (
          <Form
            className={classes.modalBody}
            style={{ paddingLeft: 24, paddingRight: 24, ...modalBodyStyles, }}
            onSubmit={handleSubmit}
            noValidate
          >
            {children}
            {isFooterVisible && (
              /**
               * TODO: For some reason putting this code into a Component breaks
               * the default submit functionality
               */
              // <ModalFooter />
              <div style={{ width: '100%', height: 'auto', ...buttonContainerStyles, }}>
                {showBackButton && (
                  <ButtonElement
                    theme={buttonThemes.WHITE}
                    onClick={backHandler}
                    label="< Back"
                    minWidth="80px"
                    style={{ border: '1px solid #616161' }}
                  />
                )}
                {isDelete ? (
                  <ButtonElement
                    size="large"
                    label={buttonTitle}
                    onClick={handleSubmit}
                  />
                ) : showNextButton ? (
                  <ButtonElement
                    size="large"
                    label="Next"
                    onClick={nextHandler}
                    disabled={buttonDisabled}
                    dimmed={buttonDimmed}
                  />
                ) : customModalButtons.length === 0 && buttonTitle && (
                  <ButtonElement
                    type="submit"
                    size="large"
                    label={buttonTitle}
                    disabled={buttonDisabled}
                    dimmed={buttonDimmed}
                  />
                )}
                {customModalButtons.length === 0 && !showBackButton && (
                  <ButtonElement
                    theme={buttonThemes.WHITE}
                    onClick={handleClose}
                    label="Cancel"
                    minWidth="80px"
                  />
                )}
                {customModalButtons.map(({ buttonLabel, buttonTheme, buttonHandler, styles = {}, }) => {
                  return (
                    <ButtonElement
                      size="large"
                      label={buttonLabel}
                      theme={buttonTheme}
                      styleWithIcon={styles}
                      onClick={buttonHandler}
                    />
                  );
                })}
                {footerText && 
                  <div style={{ width: 'auto', ...footerTextStyles, }}>{footerText}</div>
                }
              </div>
            )}
          </Form>
        ) : (
          <Box m={3}>{children}</Box>
        )}
      </div>
    </Modal>
  );
};

export default ModalComponent;

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // border: `1px solid ${colors.GRAY[450]}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 3),
    borderRadius: 15,
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: 24,
    borderWidth: 0,
    borderBottomWidth: 2,
    borderStyle: 'solid',
    // borderColor: colors.GRAY[200],
    paddingRight: 18,
    paddingLeft: 24,
    color: colors.WHITE[100],
    backgroundColor: colors.GRAY[450],
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 64,
  },
  modalBody: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
  modalHeaderText: {
    fontSize: typeScale.LMEDIUM.fontSize,
    lineHeight: typeScale.LMEDIUM.lineHeight,
    marginBottom: 0,
  },
  formRoot: {
    width: 120,
  },
  closeIconButton: {
    color: colors.WHITE[100],
  },
}));

ModalComponent.defaultProps = {
  modalWidth: 400,
};

ModalComponent.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalWidth: PropTypes.number,
};
