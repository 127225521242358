import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { colors, textEllipsisStyle } from '../../styles';
import moment from 'moment';
import styled from 'styled-components';
import { Country } from 'country-state-city';
import PageContent from './PageContent';
import GameContentItem from './GameContentItem';
import SearchMenuComponent from '../Inputs/SearchMenuComponent';
import { PLAYERS_POSITIONS_ABBREVIATION } from '../../utils/playersUtil';
import { 
  formatDate, 
  formatTime, 
  GamesTabs, 
  getTabByGameDateTime, 
  searchFilter,  
} from '../../utils/gameUtil';
import {
  isMobile,
  addScrollListClass,
  onListScrollHandler,
} from '../../utils/scrollUtil';
import GameTabs from '../tabs/GameTabs';
const log = require('../../logger')();

const EXPANDED_CONTAINER_HEIGHT = 190;
const COLLAPSED_CONTAINER_HEIGHT = 90;
const TAB_BAR_HEIGHT = 62;

const GAMES_FILTERING_OPTIONS = {
  PAST_GAMES: 'pastGames',
  LIVE_GAMES: 'liveGames',
  UPCOMING_GAMES: 'upcomingGames',
};

/*** PLAYER PROFILE INFORMATION STYLES START ***/
const rowContainerStyle = {
  width: '100%',
  position: 'relative',
};

const activeIndicatorPositionStyle = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const activeStatusIndicator = {
  height: 12,
  width: 12,
  borderRadius: 6,
  marginRight: 6,
};

const activeStatusText = {
  fontSize: '12px',
  color: '#3C3C3C',
  fontFamily: 'Open Sans',
};

const imageContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 'auto',
  paddingTop: 0,
};

const headShotDisplay = {
  width: 140,
  height: 140,
  borderRadius: 70,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.GRAY[150],
  overflow: 'hidden',
  marginTop: 10,
};

const imageStyle = {
  opacity: 1,
  objectFit: 'cover',
  width: 'inherit',
};

const playerProfileIdStyle = {
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  font: 'normal normal 600 30px/30px Barlow Condensed', 
};

const jerseyNumberStyle = {
  ...playerProfileIdStyle,
  paddingRight: 10, 
  borderRight: `2px solid ${colors.GRAY[350]}`,
};

const playerNameStyle = {
  font: 'normal normal 600 30px/30px Barlow Condensed', 
  paddingLeft: 10, 
  paddingRight: 20,
  paddingBottom: 5,
  ...textEllipsisStyle,
};

const playerTeamNameStyle = {
  backgroundColor: colors.GRAY[100], 
  font: 'normal normal normal 14px/30px Inter', 
  paddingLeft: 10, 
  color: '#3C4152',
  width: '95%',
  ...textEllipsisStyle,
};

const playerProfileInfoItemTitleStyle = {
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-start', 
  paddingLeft: 10, 
  textAlign: 'left', 
  height: '100%', 
  color: '#585858', 
  font: 'normal normal normal 11px/14px Open Sans', 
};

const playerProfileInfoItemValueStyle = {
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-start', 
  textAlign: 'left', 
  height: '100%', 
  color: '#343434', 
  font: 'normal normal bold 11px/14px Open Sans', 
};

const playerProfileInfoItemValueTextStyle = {
  textAlign: 'left', 
  height: '100%', 
  color: '#343434', 
  font: 'normal normal bold 11px/14px Open Sans',   
};

const positionItemStyle = {
  width: 'auto',
  display: 'inline-block',
  marginLeft: 1,
  marginRight: 1,
};

const playerGamesControlBackgroundStyle = { 
  width: '100%',
  backgroundColor: 'white', 
  zIndex: 2, 
  paddingTop: 3,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const playerProfileGameSearchControlStyle = { 
  position: 'fixed', 
  width: 350, 
  height: 50, 
  right: '13.4%', 
  zIndex: 2, 
  marginTop: 16, 
  marginRight: 24,
  transition: 'top 0.5s ease 0.1s',
};

const playerGameItemsContainerStyle = {
  width: '100%', 
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const playerGameItemsStyle = {
  width: '94%', 
  height: 'auto',
  marginTop: 2,
  marginBottom: 5,
  marginLeft: 35,
  marginRight: '4%',
  background: `${colors.GRAY[250]} 0% 0% no-repeat padding-box`,
  borderRadius: 5, 
  display: 'flex', 
  justifyContent: 'center', 
  flexFlow: 'row wrap', 
};

const playerGameItemsEmptyStyle = { 
  width: '100%', 
  height: 215, 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  font: 'normal normal normal 16px/12px Open Sans',
};


const headShotCondensedStyle = {
  ...headShotDisplay, 
  width: 60, 
  height: 60, 
  borderRadius: 30, 
  margin: '10px 0px'
};

const playerProfileIdCondensedStyle = {
  display: 'flex', 
  flexDirection: 'row', 
  height: '100%', 
  alignItems: 'flex-end', 
  font: 'normal normal 600 24px/19px Barlow Condensed'
};

const jerseyNumberCondensedStyle = {
  ...playerProfileIdCondensedStyle,
  justifyContent: 'flex-end', 
  fontSize: 21,
};

const playerNameStyleCondensedStyle = {
  ...playerProfileIdCondensedStyle,
  paddingLeft: 5, 
  paddingBottom: 26, 
  justifyContent: 'flex-start',
  fontSize: 20,
};

const teamCondensedStyle = {
  width: '100%', 
  height: '50%', 
  display: 'flex', 
  flexDirection: 'row', 
};

const teamTitleCondensedStyle = { 
  ...teamCondensedStyle, 
  alignItems: 'flex-end', 
  justifyContent: 'flex-start', 
  color: '#585858', 
  font: 'normal normal normal 12px/20px Open Sans', 
};

const teamNameCondensedStyle = { 
  color: '#111111', 
  font: 'normal normal 600 12px/20px Open Sans', 
  ...textEllipsisStyle,
};

const teamBordersCondensedStyle = { 
  width: '100%', 
  height: '100%', 
  borderLeft: '1px solid rgba(112, 112, 112, 0.15)', 
  borderRight: '1px solid rgba(112, 112, 112, 0.15)' 
};

const PlayerName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  line-height: 35px;
`;

const PlayerJersey = styled.div`
  height: auto;
  line-height: 28px;
  padding-right: 5px;
  border-right: 2px solid ${colors.GRAY[350]};
`;
/*** PLAYER PROFILE INFORMATION STYLES END ***/

/*** PLAYER PROFILE CONTAINER COMPONENTS START ***/
const PlayerProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  backgroundcolor: transparent;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  padding-bottom: 15px;
`;

const PlayerProfileScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-flow: row wrap;
  position: relative;
`;
/*** PLAYER PROFILE CONTAINER COMPONENTS END ***/

/**
 *  This component displays the player active status.
 */
const PlayerProfileItemActiveStatus = ({ 
  active, 
  top = 20, 
  left = 0, 
  position = 'absolute'
}) => {
 return (
   <div style={{ ...activeIndicatorPositionStyle, position: position, top:top, left:left, }}>
     <div
       style={{
         ...activeStatusIndicator,
         backgroundColor: active ? '#2DCC38' : '#EE3B3B',
       }}
     ></div>
     <span style={activeStatusText}>{active ? 'Active' : 'Inactive'}</span>
   </div>
 );
};

/**
* This component displays images with respect to the player item.
*/
const PlayerProfileImage = ({ 
 imgSrc, 
 extraStyles, 
 imageWidth, 
 imageHeight 
}) => {
 return (
   <img
     src={imgSrc}
     style={{ ...imageStyle, ...extraStyles }}
     width={imageWidth || "140px"}
     height={imageHeight || "140px"}
     alt=""
   />
 );
};

/**
* This is a helper method that displays the list of player positions
*/
const displayPlayerPositions = (position, abbreviate = false) => {
 return (
   <>
     {position.map((pos, index) => {
       return index < position.length - 1 ? (
         <>
           <div style={positionItemStyle}>{abbreviate ? PLAYERS_POSITIONS_ABBREVIATION[pos] : pos}</div>
           <span style={{ color: '#BCBCBC' }}>
             &nbsp;|&nbsp;
           </span>
         </>
       ) : (
         <div style={positionItemStyle}>{abbreviate ? PLAYERS_POSITIONS_ABBREVIATION[pos] : pos}</div>
       );
     })}
   </>
 );
}

/**
 * Get the list of countries.
 */
const getCountries = () => {
  return Country
          .getAllCountries()
          .map(({ isoCode, name }) => ({ isoCode, name }));
};

/**
 * This Expanded PlayerProfile Info content provides all the player profile information.
 */
const ExpandedPlayerProfileInfo = ({
  name, 
  team, 
  image, 
  position, 
  weight, 
  birthCity, 
  birthState, 
  birthCountry, 
  dateOfBirth, 
  heightInFeet, 
  heightInInches, 
  institute,
  jerseyNumber, 
  active, 
}) => {
  return (
    <Row style={{ width: '100%', height: 212, margin: 0, }}>
      <Col xl={12} xs={12} md={12} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: 0, }}>
        <Row style={{ width: '75%', height: '100%', }}>
          <Col xs={2} className="p-0" style={{ display: 'flex', justifyContent: 'center', position: 'relative', minWidth: '140px' }}>
            <div style={imageContentStyle}>
              <div style={headShotDisplay}>
                <PlayerProfileImage
                  imgSrc={image}
                />
              </div>
            </div>
            <PlayerProfileItemActiveStatus
              active={active}
            />
          </Col>

          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%', height: 'auto' }}>
              <Row style={{ width: '100%', height: 'auto', marginBottom: 10, marginTop: 10 }}>
                <Col xl={12} xs={12} md={12}>
                  <div style={{ height: '100%', width: 450, display: 'flex', flexDirection: 'row' }}>
                    <div style={jerseyNumberStyle}>
                      {jerseyNumber}
                    </div>
                    <div style={playerNameStyle}>
                      {name}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row style={{ width: '100%', height: 'auto' }}>
                <Col xl={12} xs={12} md={12} style={{ position: 'relative' }}>
                  <div style={playerTeamNameStyle}>
                    {team.name}
                  </div>
                  {(team.name != 'Free Agent') && 
                  <div style={{ width: 'auto', height: 'auto', position: 'absolute', top: -30, right: -70, }}>
                    <PlayerProfileImage
                      imgSrc={team.image}
                      extraStyles={{ objectFit: 'cover', width: 'auto', maxHeight: 100, maxWidth: 100 }}
                      imageWidth={'auto'}
                      imageHeight={'auto'}
                    />
                  </div>}
                </Col>
              </Row>

              <Row style={{ width: '100%', height: 'auto', marginTop: 8 }}>
                <Col xl={12} xs={12} md={12}>
                  <Row className="mb-2" style={{ width: '100%' }}>
                    <Col xl={12} xs={12} md={12}>
                      <Row style={{ height: '100%' }}>
                        <Col  style={{maxWidth: 100 }}>
                          <div style={playerProfileInfoItemTitleStyle}>
                            Position(s)
                          </div>
                        </Col>
                        <Col className="p-0">
                          <div style={playerProfileInfoItemValueTextStyle}>
                            {displayPlayerPositions(position)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-2" style={{ width: '100%'}}>
                    <Col xl={6} xs={6} md={6}>
                      <Row style={{ height: '100%' }}>
                        <Col style={{ maxWidth: 100 }}>
                          <div style={playerProfileInfoItemTitleStyle}>
                            Birth Place
                          </div>
                        </Col>
                        <Col className="p-0">
                          <div style={{ ...playerProfileInfoItemValueStyle, alignItems: 'flex-start'}}>
                            {
                              birthCity == 'N/A' 
                              ? `${birthState}, ${birthCountry}` 
                              : `${birthCity}, ${birthState.replace('Province', '').replace('State', '')}, ${birthCountry}`
                            }
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={6} xs={6} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} className="p-0">
                      <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <Row style={{ width: '50%' }}>
                          <Col xl={6} xs={6} md={6} style={{ width: 55 }}>
                            <div style={{ ...playerProfileInfoItemTitleStyle, paddingLeft: 0, alignItems: 'flex-start', }}>
                              Height
                            </div>
                          </Col>
                          <Col xl={6} xs={6} md={6} style={{ width: 'auto' }}>
                            <div style={{ ...playerProfileInfoItemValueStyle, alignItems: 'flex-start', }}>
                              {`${heightInFeet}' ${heightInInches}"`}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ width: '50%' }}>
                          <Col xl={6} xs={6} md={6} style={{ width: 55 }}>
                            <div style={{ ...playerProfileInfoItemTitleStyle, paddingLeft: 0, alignItems: 'flex-start', }}>
                              Weight
                            </div>
                          </Col>
                          <Col xl={6} xs={6} md={6} style={{ width: 'auto' }}>
                            <div style={{ ...playerProfileInfoItemValueStyle, alignItems: 'flex-start', }}>
                            {`${weight} lbs`}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-2" style={{ width: '100%' }}>
                    <Col xl={6} xs={6} md={6}>
                      <Row style={{ height: '100%' }}>
                        <Col style={{ maxWidth: 100 }}>
                          <div style={playerProfileInfoItemTitleStyle}>
                            Date of Birth
                          </div>
                        </Col>
                        <Col className="p-0">
                          <div style={playerProfileInfoItemValueStyle}>
                            {moment(dateOfBirth).format('MM/DD/YYYY')}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={6} xs={6} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                      <div style={{ width: '90%', height: 'auto' }}>
                        <Row style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
                          <Col xl={3} xs={3} md={3} className="p-0" style={{ width: 55 }}>
                            <div style={{ ...playerProfileInfoItemTitleStyle, paddingLeft: 0 }}>
                              Institute
                            </div>
                          </Col>
                          <Col xl={9} xs={9} md={9} className="p-0">
                            <div style={{ ...playerProfileInfoItemValueStyle, ...textEllipsisStyle, height: 'auto', justifyContent: 'flex-end' }}>
                              {institute}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

/**
 * This Collapsed Player Profile Content Provides Partial Player Profile Information.
 */
const CollapsedPlayerProfileInfo = ({
  name, 
  team, 
  image, 
  position, 
  jerseyNumber, 
  active, 
}) => {
  return (
    <Row style={{ width: '100%', height: 90, display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0, }}>
      <Col xl={12} xs={12} md={12} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Row style={{ width: '90%', height: '100%', }}>
          <Col xl={2} xs={2} md={2} className="p-0" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={imageContentStyle}>
              <div style={headShotCondensedStyle}>
                <PlayerProfileImage
                  imageWidth={60} 
                  imageHeight={60} 
                  imgSrc={image}
                />
              </div>
            </div>
          </Col>

          <Col xl={3} xs={3} md={3}>
            <Row style={{ width: '100%', height: '40%' }}>
              <Col xl={6} xs={6} md={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
                <PlayerProfileItemActiveStatus
                  top={null}
                  left={null}
                  position={null}
                  active={active}
                />
              </Col>
            </Row>
            <Row style={{ width: '100%', height: '60%', marginTop: 5, }}>
              <Col className="p-0" xl={3} xs={3} md={3}>
                <div style={{ width: '100%', height: '100%', paddingBottom: 30, }}>
                  <div style={jerseyNumberCondensedStyle}>
                    <PlayerJersey>{jerseyNumber}</PlayerJersey>
                  </div>
                </div>
              </Col>
              <Col className="p-0" xl={9} xs={9} md={9}>
                <div style={playerNameStyleCondensedStyle}>
                  <PlayerName>{name}</PlayerName>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xl={5} xs={5} md={5} style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ width: '100%', height: '75%', }}>
              <Row style={teamBordersCondensedStyle}>
                <Col xl={4} xs={4} md={4} className="p-0" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  {(team.name != 'Free Agent') && 
                  <PlayerProfileImage
                    imgSrc={team.image}
                    extraStyles={{ objectFit: 'cover', width: 'auto', height: 60 }}
                    imageWidth={'auto'}
                    imageHeight={'auto'}
                  />}
                </Col>
                <Col xl={8} xs={8} md={8}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                    <div style={teamTitleCondensedStyle}>Team</div>
                    <div style={teamNameCondensedStyle}>{team.name}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xl={2} xs={2} md={2} className="p-0">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
              <div style={teamTitleCondensedStyle}>Positions(s)</div>
              <div style={{ ...playerProfileInfoItemValueTextStyle, width: '100%', height: '50%' }}>
                {displayPlayerPositions(position, true)}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

/**
 * This content provides information with respect to the Player Profile.
 */
 const PlayerProfileItemContent = ({
  name, 
  team, 
  image, 
  position, 
  weight, 
  birthCity, 
  birthState, 
  birthCountry, 
  dateOfBirth, 
  heightInFeet, 
  heightInInches, 
  institute,
  jerseyNumber, 
  active, 
  containerHeight, 
  elRef,
  liveGamesCount,
  upcomingGamesCount,
  pastGamesCount,
  selectedGames,
  isCollapsed,
  setActiveSelectedGamesStatus,
}) => {

  return (
    <div
      ref={elRef}
      style={{
        width: 'calc(90% - 76px)',
        position: 'fixed',
        zIndex: 2,
        backgroundColor: 'white',
        height: containerHeight + 55,
        transition: 'height 0.5s ease 0.1s',
        overflowY: 'hidden',
      }}
    >
      {containerHeight === EXPANDED_CONTAINER_HEIGHT ? (
        <ExpandedPlayerProfileInfo
          name={name}
          team={team}
          image={image}
          position={position}
          weight={weight}
          birthCity={birthCity}
          birthState={birthState}
          birthCountry={birthCountry}
          dateOfBirth={dateOfBirth}
          heightInFeet={heightInFeet}
          heightInInches={heightInInches}
          institute={institute}
          jerseyNumber={jerseyNumber}
          active={active}
        />
      ) : (
        <CollapsedPlayerProfileInfo
          name={name}
          team={team}
          image={image}
          position={position}
          jerseyNumber={jerseyNumber}
          active={active}
        />
      )}
      <div
        style={{
          ...playerGamesControlBackgroundStyle,
          top: isCollapsed
            ? COLLAPSED_CONTAINER_HEIGHT
            : EXPANDED_CONTAINER_HEIGHT,
          transition: 'top 0.5s ease 0.1s',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '80%',
            height: 'auto',
            borderTop: `1px solid ${colors.GRAY[200]}`,
            borderBottom: `1px solid ${colors.GRAY[200]}`,
            paddingTop: 12,
          }}
        >
          <GameTabs
            liveGamesCount={liveGamesCount}
            upcomingGamesCount={upcomingGamesCount}
            pastGamesCount={pastGamesCount}
            liveGamesClickHandler={() =>
              setActiveSelectedGamesStatus(GamesTabs.TODAY)
            }
            upcomingGamesClickHandler={() =>
              setActiveSelectedGamesStatus(GamesTabs.UPCOMING)
            }
            pastGamesClickHandler={() =>
              setActiveSelectedGamesStatus(GamesTabs.PAST)
            }
            activeLiveGamesStatus={selectedGames === GamesTabs.TODAY}
            activeUpcomingGamesStatus={selectedGames === GamesTabs.UPCOMING}
            activePastGamesStatus={selectedGames === GamesTabs.PAST}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * This is a container for all the game items in the team of each player.
 */
 const PlayerProfileGameItemsContent = ({ 
  gameItems,
  emptyGameItemsStatement, 
  topContainerHeight, 
}) => {
  return (
    <Row style={{ 
      height: 'auto', 
      width: '100%',
      position: 'absolute', 
      top: topContainerHeight + TAB_BAR_HEIGHT - 10, 
      left: 12,
      transition: 'top 0.5s ease 0.1s',
      bottom: 0
    }}>
      <Col xl={12} xs={12} md={12} style={playerGameItemsContainerStyle}>
        {gameItems.length > 0 
          ?
        <div style={{ ...playerGameItemsStyle, border: `1px solid ${colors.GRAY[200]}` }}>
          {gameItems.map((game, index) => {
            return (
              <GameContentItem
                key={`${game.homeTeamVsAwayTeam}_${index}`}
                game={game}
                editTeamCtrl={null}
                cardItemWidth={225}
                cardItemHeight={220}
                cardOuterMargin={'5px'}
                teamImageHeight={35}
                teamTextFontSize={12}
                versusCircleSize={30}
                versusTextFontSize={15}
                marginTopStyle={10}
                dateFontSize={12}
                datePaddingBottom={'1px'}
                venueFontSize={13}
                homeAwayTeamHeight={80}
                homeAwayTeamNameMinHeight={14}
                homeAwayTeamMarginTop={5}
                homeAwayTeamMarginBottom={5}
                leagueMarginTop={8}
                leagueHeight={45}
                leagueNameFontSize={14}
                leagueAbbrvFontSize={11}
              />
            );
          })}
        </div>
          :
        <div style={playerGameItemsEmptyStyle}>{emptyGameItemsStatement}</div>
        }
      </Col>
    </Row>
  );
};

/**
 * This content provides information with respect to the Player 
 * games.
 */
 const PlayerProfileGameItems = ({
  gameItems, 
  liveGames,
  upcomingGames,
  pastGames,
  pastGameItems,
  liveGamesItems,
  upcomingGameItems,
  topContainerHeight, 
  setGameItems, 
  selectedGames,
  setLiveGamesCount,
  setUpcomingGamesCount,
  setPastGamesCount,
  setLiveGameItems,
  setUpcomingGameItems,
  setPastGameItems,
  isCollapsed,
  setActiveTab,
 }) => {
  const EMPTY_GAME_ITEMS_STATEMENTS = {
    PAST_GAMES: 'There are currently no past games.',
    UPCOMING_GAMES: 'There are currently no upcoming games.',
  };

  const [ gameItemsSearch, setGameItemsSearch ] = useState('');
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);
  const [ searchPositionTop, setSearchPositionTop ] = useState(EXPANDED_CONTAINER_HEIGHT + 143);
  const [ emptyGameItemsStatement, setEmptyGameItemsStatement ] = useState('');

  useEffect(() => {
    setSearchPositionTop(isCollapsed ? (COLLAPSED_CONTAINER_HEIGHT + 143) : (EXPANDED_CONTAINER_HEIGHT + 143));
  }, [isCollapsed]);

  useEffect(() => {
    if(selectedGames === GamesTabs.UPCOMING) {
      setEmptyGameItemsStatement(EMPTY_GAME_ITEMS_STATEMENTS.UPCOMING_GAMES);
    }
    else if(selectedGames === GamesTabs.PAST) {
      setEmptyGameItemsStatement(EMPTY_GAME_ITEMS_STATEMENTS.PAST_GAMES);
    }
  }, [gameItems]);

  useEffect(() => {
    setLiveGamesCount(liveGamesItems.length);
    if(!isSearchResultSelected && selectedGames === GamesTabs.TODAY) {
      setGameItems( liveGamesItems );
    }
  }, [liveGamesItems]);

  useEffect(() => {
    setUpcomingGamesCount(upcomingGameItems.length);
    if(!isSearchResultSelected && selectedGames === GamesTabs.UPCOMING) {
      setGameItems( upcomingGameItems );
    }
  }, [upcomingGameItems]);

  useEffect(() => {
    setPastGamesCount(pastGameItems.length);
    if(!isSearchResultSelected && selectedGames === GamesTabs.PAST) {
      setGameItems( pastGameItems );
    }
  }, [pastGameItems]);

  useEffect(() => {
    if (gameItemsSearch) {
      if (typeof gameItemsSearch === 'object') {
        setGameItems([gameItemsSearch]);
        setIsSearchResultSelected(true);

        const tab = getTabByGameDateTime(gameItemsSearch.gameDateTime);
        setActiveTab(tab);

        /* Override tab count as appropriate */
        switch (tab) {
          case GamesTabs.TODAY:
            setLiveGamesCount(1);
            break;
          case GamesTabs.UPCOMING:
            setUpcomingGamesCount(1);

            break;
          case GamesTabs.PAST:
            setPastGamesCount(1);
            break;
          default:
            log.warn(`Invalid game tab value selected: ${tab}`);
        }
      } else if (gameItemsSearch.length > 0) {
        setLiveGameItems(
          liveGames.filter((option) => searchFilter(option, gameItemsSearch))
        );
        setUpcomingGameItems(
          upcomingGames.filter((option) =>
            searchFilter(option, gameItemsSearch)
          )
        );
        setPastGameItems(
          pastGames.filter((option) => searchFilter(option, gameItemsSearch))
        );

        setIsSearchResultSelected(false);
      }
    } else {
      setLiveGameItems(liveGames);
      setUpcomingGameItems(upcomingGames);
      setPastGameItems(pastGames);
      setIsSearchResultSelected(false);
    }
  }, [gameItemsSearch]);

   return (
     <div style={{ width: '100%', height: 'auto', marginLeft: 50, marginRight: 50, paddingBottom: 5, display: 'flex', justifyContent: 'center' }}>
      <PageContent
        searchId={'__playerGameId'}
        data={[ ...liveGames, ...upcomingGames, ...pastGames ]}
        dataProperty={option => option.homeTeamVsAwayTeam}
        placeholder={'Search Player Games'}
        onChange={setGameItemsSearch}
        filterBy={(option, props) =>  searchFilter(option, props.text)}
        searchControlStyle={{ ...playerProfileGameSearchControlStyle, top: searchPositionTop, }}
        renderMenuItemChildren={(option, props) => {
          return (
            <SearchMenuComponent
              mainCriteriaText={[
                {option:option.homeTeamVsAwayTeam, text:props.text}
              ]}
              subCriteriaText={[
                {option:option.venueName, text:props.text, separator:'-'}, 
                {option:option.venueCity, text:props.text, separator:','}, 
                {option:option.venueState, text:props.text}
              ]}
              subCriteriaDarkText={[
                {option:formatDate(option.gameDateTime), text:props.text, separator:'|'}, 
                {option:formatTime(option.gameDateTime), text:props.text}
              ]}
            />
          );
        }}
      >
        <PlayerProfileGameItemsContent
          gameItems={gameItems}
          emptyGameItemsStatement={emptyGameItemsStatement}
          topContainerHeight={topContainerHeight}
        />
      </PageContent>
     </div>
   );
 };

const PlayerProfileContent = ({ 
  playerItem 
}) => {
  const {
    id,
    name,
    jerseyNumber,
    position,
    dateOfBirth,
    birthCity,
    birthState,
    birthCountry,
    weight,
    heightInFeet,
    heightInInches,
    active,
    institute,
    image,
    team,
    teamTodaysGames, 
    teamPastGames, 
    teamUpcomingGames,
  } = playerItem;

  const [ currentTopContainerHeight, setCurrentTopContainerHeight ] = useState(EXPANDED_CONTAINER_HEIGHT);
  const [ currentGameItemsContainerHeight, setCurrentGameItemsContainerHeight ] = useState(0);
  const [ isCollapsed, setIsCollapsed ] = useState(false);

  const [ gameItems, setGameItems ] = useState([]);
  const [ liveGamesItems, setLiveGameItems ] = useState(teamTodaysGames);
  const [ upcomingGameItems, setUpcomingGameItems ] = useState(teamUpcomingGames);
  const [ pastGameItems, setPastGameItems ] = useState(teamPastGames);
  const [ liveGamesCount, setLiveGamesCount ] = useState(0);
  const [ upcomingGamesCount, setUpcomingGamesCount ] = useState(0);
  const [ pastGamesCount, setPastGamesCount ] = useState(0);
  const [ activeTab, setActiveTab ] = useState();
  
  const playerProfileElRef = useRef();
  const playerProfileScrollEfRef = useRef();
  const cardContainerHeightRef = useRef();

  useEffect(() => {
    setActiveTab(GamesTabs.TODAY);
    setGameItems(teamTodaysGames);
  }, [teamTodaysGames]);

  useEffect(() => {
    if (activeTab !== GamesTabs.TODAY) {
      setActiveTab(GamesTabs.UPCOMING);
      setGameItems(teamUpcomingGames);
    }
    setUpcomingGameItems(teamUpcomingGames);
  }, [teamUpcomingGames]);

  useEffect(() => {
    setPastGamesCount(teamPastGames.length);
    setPastGameItems(teamPastGames);
  }, [teamPastGames]);

  useEffect(() => {
    if (teamTodaysGames.length > 0) {
      setActiveTab(GamesTabs.TODAY);
      setGameItems(teamTodaysGames);
    }
    else {
      setActiveTab(GamesTabs.UPCOMING);
      setGameItems(teamUpcomingGames);
    }
  }, []);

  useEffect(() => {
    cardContainerHeightRef.current = playerProfileScrollEfRef.current.clientHeight;
    const initialGameItemsContainerHeight = cardContainerHeightRef.current - currentTopContainerHeight;
    setCurrentGameItemsContainerHeight(initialGameItemsContainerHeight);
  }, []);

  useEffect(() => {
    if(isCollapsed) {
      setCurrentTopContainerHeight(COLLAPSED_CONTAINER_HEIGHT);
    }
    else {
      setCurrentTopContainerHeight(EXPANDED_CONTAINER_HEIGHT);
    }
  }, [isCollapsed]);

  useEffect(() => {
    setCurrentGameItemsContainerHeight(cardContainerHeightRef.current - currentTopContainerHeight);
  }, [currentTopContainerHeight]);

  let _isMobile;

  useEffect(() => {
    _isMobile = isMobile();
  
    if([...teamTodaysGames, ...teamUpcomingGames, ...teamPastGames]?.length > 0) {
      if(!isMobile()) {
        addScrollListClass(playerProfileScrollEfRef.current);
      }
    }
  }, [...teamTodaysGames, ...teamUpcomingGames, ...teamPastGames]);

  const onScrollCaptureHandler = event => {
    const { scrollTop } = event.currentTarget;
    log.debug('Scroll Top', scrollTop)

    if((scrollTop > 0) && !isCollapsed) {
      setIsCollapsed(true);
    }

    if((scrollTop == 0) && isCollapsed) {
      setIsCollapsed(false);
    }
  };

  const setActiveSelectedGamesStatus = (selectedGamesStatus) => {
    if (selectedGamesStatus !== activeTab) {
      setActiveTab(selectedGamesStatus);

      switch (selectedGamesStatus) {
        case GamesTabs.TODAY:
          setGameItems(liveGamesItems);
          break;
        case GamesTabs.UPCOMING:
          setGameItems(upcomingGameItems);
          break;
        case GamesTabs.PAST:
          setGameItems(pastGameItems);
          break;
        default:
          log.warn(`Invalid game tab value selected: ${selectedGamesStatus}`);
      }
    }
  };

  return (
    <PlayerProfileContainer>
      <PlayerProfileScrollContainer
        ref={playerProfileScrollEfRef}
        onScroll={() => onListScrollHandler(_isMobile, playerProfileScrollEfRef.current)}
        onScrollCapture={onScrollCaptureHandler}
      >
        <PlayerProfileItemContent
          name={name}
          team={team}
          image={image}
          position={position}
          weight={weight}
          birthCity={birthCity}
          birthState={birthState}
          birthCountry={getCountries().find(({ name }) => name === birthCountry).isoCode}
          dateOfBirth={dateOfBirth}
          heightInFeet={heightInFeet}
          heightInInches={heightInInches}
          institute={institute}
          jerseyNumber={jerseyNumber}
          active={active}
          containerHeight={currentTopContainerHeight}
          elRef={playerProfileElRef}
          liveGamesCount={liveGamesCount}
          upcomingGamesCount={upcomingGamesCount}
          pastGamesCount={pastGamesCount}
          selectedGames={activeTab}
          isCollapsed={isCollapsed}
          setActiveSelectedGamesStatus={setActiveSelectedGamesStatus}
        />
        <PlayerProfileGameItems
          gameItems={gameItems}
          liveGames={teamTodaysGames}
          upcomingGames={teamUpcomingGames}
          pastGames={teamPastGames}
          pastGameItems={pastGameItems}
          liveGamesItems={liveGamesItems}
          upcomingGameItems={upcomingGameItems}
          topContainerHeight={currentTopContainerHeight}
          selectedGames={activeTab}
          setGameItems={setGameItems}
          setLiveGamesCount={setLiveGamesCount}
          setUpcomingGamesCount={setUpcomingGamesCount}
          setPastGamesCount={setPastGamesCount}
          setLiveGameItems={setLiveGameItems}
          setUpcomingGameItems={setUpcomingGameItems}
          setPastGameItems={setPastGameItems}
          isCollapsed={isCollapsed}
          setActiveTab={setActiveTab}
        />
      </PlayerProfileScrollContainer>
    </PlayerProfileContainer>
  );
};

export default PlayerProfileContent;