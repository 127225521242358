import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useQueryClient } from 'react-query';
import onresize from 'resize-event';
import { bindResizeEvents, unbindResizeEvents } from 'resize-start-stop';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchGameImagesAndGameRoles, gamesKeys, gamesMapFn } from '../../api/gamesQueries';
import { getGamesInDateRange } from '../../api/gamesService';
import CalendarIcon from '../../assets/calendar@2x.png';
import Information from '../../assets/information@2x.png';
import LeftBlueChevron from '../../assets/leftBlueChevron@2x.png';
import LeftChevron from '../../assets/leftChevron@2x.png';
import RightBlueChevron from '../../assets/rightBlueChevron@2x.png';
import RightChevron from '../../assets/rightChevron@2x.png';
import logger from '../../logger';
import {
  selectDateRange,
  selectHomeCalendarCollapsed,
  updateDateRange,
  updateHomeCalendarCollapsed,
} from '../../redux/homeCalendarSlice';
import {
  resetAllScrollPositions,
  selectHomeGameListScrollPositions,
  updatePastGameScrollPosition,
  updateTodaysGameScrollPosition,
  updateUpcomingGameScrollPosition,
} from '../../redux/homeGameListScrollPositionSlice';
import {
  selectActiveTab,
  selectCanChangeTabs,
  updateActiveTab,
  updateCanChangeTabs,
} from '../../redux/homePageTabsSlice';
import {
  selectHomeShowGameUsers,
  toggleAllGameUserDisplayedStatus,
  updateAllGameUserDisplayedStatus,
} from '../../redux/homeShowGameUsersSlice';
import { updatePathLocation } from '../../redux/pathLocationSlice';
import { colors } from '../../styles';
import { CenteredSpinner } from '../../styles/spinner';
import { textEllipsisStyle } from '../../styles/typography';
import {
  formatDate,
  formatTime,
  GamesTabs,
  GAMES_SORTING_FN,
  getTabByGameDateTime,
  searchFilter,
} from '../../utils/gameUtil';
import {
  dateRangeToISOString,
  expandDateRange,
  getFormattedDateCasedWithDaySuffixedSuperScript,
} from '../../utils/momentDateUtil';
import { fetchAllGraphQL } from '../../utils/reactQueryToolkit';
import { seasonFormatDisplay } from '../../utils/seasonsUtil';
import InfiniteScrollList from '../InfiniteListScroll';
import SearchMenuComponent from '../Inputs/SearchMenuComponent';
import ScrollListItemsContainer from '../ScrollListItemsContainer';
import GameTabs from '../tabs/GameTabs';
import Tooltip from '../Tooltip';
import GameContentItem from './GameContentItem';
import PageContent from './PageContent';
const log = logger('AdminPanel/HomePage', 'debug');

const EMPTY_GAME_ITEMS_STATEMENTS = {
  TODAYS_GAMES_EMPTY_MESSAGE: 'There are currently no games today.',
  UPCOMING_GAMES_EMPTY_MESSAGE: 'There are currently no upcoming games.',
};

const CALENDAR_VIEW = {
  MONTH: 'month',
  YEAR: 'year',
  DECADE: 'decade',
  CENTURY: 'century',
};

/************ BEGINNING STYLES ***********/
const homeContainerStyles = {
  width: '100%',
  height: '98%',
  margin: 0,
};

const homeContentContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
};

const homeContentItemStyles = {
  height: '100%',
  borderRadius: 10,
  transition: 'width 1s ease',
};

const gameContainerStyles = {
  ...homeContentItemStyles,
  border: '1px solid #D2D2D2',
  position: 'relative',
};

const calendarContainerStyles = {
  ...homeContentItemStyles,
  border: '1px solid #D2D2D2',
  backgroundColor: colors.WHITE[100],
};

const topRowGameContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
};

const topRowGameContentStyle = {
  width: '97%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 10,
};

// const topRowGameHeaderStyle = {
//   width: 'auto',
//   height: 'auto',
//   color: '#111111',
//   font: 'normal normal bold 22px/18px Inter',
// };

const userRoleSwitchLabelStyle = {
  margin: 0,
  marginRight: 10,
  font: 'normal normal bold 14px/24px Inter',
};

const tabbarControlContainerStyles = {
  width: '37%',
  height: 'auto',
  position: 'absolute',
  top: -40,
  left: 5,
};

const searchControlStyle = {
  position: 'fixed',
  top: '65px',
  right: '56px',
  zIndex: '2',
  width: '350px',
  height: '50px',
};

const gameItemContentStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexFlow: 'row wrap',
};

// const emptyGameMessageStyle = {
//   width: '100%',
//   height: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// };

const tooltipLegendContentStyle = {
  width: '100%',
  height: 20,
  paddingLeft: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  font: 'normal normal bold 12px/14px Inter',
  color: '#808080',
};

const tipRowItemContainerStyle = {
  width: '100%',
  height: 60,
  paddingLeft: 10,
};

const tipRowItemStyle = {
  width: '100%',
  height: 30,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const tipRowItemBgColorStyle = {
  width: '25%',
  height: 25,
  borderRadius: 5,
};

const tipRowItemTitleStyle = {
  width: '75%',
  height: 25,
  paddingLeft: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  font: 'normal normal normal 12px/14px Inter',
  color: '#585858',
};

const collapsedExpandedCalendarContainerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const topCalendarContentItemStyle = {
  width: '98%',
  height: 130,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingLeft: 10,
  paddingRight: 10,
};

const bottomCalendarContentItemStyle = {
  width: '98%',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const topExpandedCalendarContentItemStyle = {
  width: '98%',
  height: 'auto',
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 10,
};

const bottomExpandedCalendarContentItemStyle = {
  width: '98%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 10,
};

const calendarContainer = {
  width: '100%',
  height: '100%',
  paddingBottom: 8,
  borderBottom: '1px solid #D2D2D2',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
};

const expandedSelectedCalendarGameItemsContainerStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const expandedSelectedCalendarGameItemContainerStyle = {
  width: '95%',
  height: 80,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  borderRadius: 7,
  marginBottom: 8,
  border: '2px solid #D9D9D9',
};

const expandedSelectedCalendarGameItemDateTimeStyle = {
  width: '34%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  font: 'normal normal bold 0.85vw/15px Barlow',
  color: colors.WHITE[100],
  borderTopLeftRadius: 7,
  borderBottomLeftRadius: 7,
};

const expandedSelectedCalendarGameItemDateStyle = {
  width: '100%',
  height: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: 1,
};

const expandedSelectedCalendarGameItemTimeStyle = {
  width: '100%',
  height: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: 1,
};

const expandedSelectedCalendarGameItemInfoContainerStyle = {
  width: '66%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 8,
  paddingRight: 8,
};

const expandedSelectedGameItemHomeTeamVsAwayTeamNamesContainerStyle = {
  width: '100%',
  height: 25,
  display: 'flex',
  flexDirection: 'column',
};

const expandedSelectedGameItemTeamNameStyles = {
  width: '100%',
  height: 20,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  font: 'normal normal 600 0.8vw/14px Barlow',
  color: '#1C1C1C',
  marginBottom: 2,
};

const expandedSelectedGameItemTeamNameTextStyle = {
  height: 'auto',
  textAlign: 'left',
  ...textEllipsisStyle,
};

const expandedSelectedGameItemVersusTextStyle = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 5,
  paddingRight: 5,
};

const expandedSelectedGameItemUnderlineTeamNameStyle = {
  width: '100%',
  height: 2,
  borderBottom: '2px solid black',
};

const expandedSelectedGameItemSeasonAndVenueInfoStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  font: 'normal normal normal 0.8vw/15px Barlow',
  color: '#1C1C1C',
};

const expandedSelectedGameItemSeasonVenueDividerStyle = {
  width: 2,
  height: '70%',
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  marginLeft: 4,
  marginRight: 4,
};

const expandedSelectedGameItemDividerStyle = {
  width: 2,
  height: '100%',
  borderLeft: '2px solid #D2D2D2',
};

const expandedSelectedGameItemHyphenSeparatorStyle = {
  width: 'auto',
  height: 'auto',
  marginLeft: 4,
  marginRight: 4,
};

const expandedSelectedGameItemTextStyle = {
  height: 'auto',
  textAlign: 'left',
  ...textEllipsisStyle,
};

const expandedSelectedGameItemSeasonTextStyle = {
  width: 'auto',
  maxWidth: 130,
  height: 'auto',
  ...textEllipsisStyle,
};

const expandedSelectedGameItemVenueTextStyle = {
  width: 'auto',
  maxWidth: 117,
  ...expandedSelectedGameItemTextStyle,
};

const expandedSelectedGameItemLeagueNameTextStyle = {
  width: 'auto',
  maxWidth: 'max-content',
  ...expandedSelectedGameItemTextStyle,
};

const calendarDateGameIndicatorContainerStyle = {
  width: '100%',
  height: '50%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const calendarDateGameIndicatorStyle = {
  width: '0.3vw',
  height: '0.3vw',
  borderRadius: '50%',
  backgroundColor: '#009FEE',
};

const calendarImageContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '35%',
  paddingTop: 8,
};

const dateFormatTextContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '30%',
};

const selectedGameItemsCountSizeStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20%',
};

const selectedGameItemsCountSizeTextStyle = {
  width: '45%',
  height: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #C5C5C5',
  borderRadius: 8,
  font: 'normal normal 600 12px/15px Barlow Condensed',
  color: '#3D3D3D',
};

const calenderChevronImageContainerStyle = {
  width: '10%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
};

const dateFormatTextStyle = {
  width: '75%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const dayTextDisplayStyle = {
  width: '100%',
  height: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'normal normal bold 13px/14px Inter',
  color: '#111111',
};

const dateTextDisplayStyle = {
  width: '100%',
  height: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'normal normal normal 11px/14px Inter',
  color: '#111111',
};

const homeVsAwayTeamNameTextStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const teamNameTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal 600 13px/16px Barlow, Condensed SemiBold',
  color: '#1C1C1C',
  textAlign: 'center',
  ...textEllipsisStyle,
};

const versusTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal normal 12px/14px Barlow, Medium',
  color: '#1C1C1C',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const selectedCalendarDateGameItemStyle = {
  width: '90%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  borderRadius: 5,
  border: '2px solid #E6E6E6',
  marginBottom: 8,
};

const gameItemCalendarDateTimeStyle = {
  width: '100%',
  height: 25,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const gameItemCalendarDateTimeContentStyle = {
  width: 'auto',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

const gameItemDateAndTimeTextStyle = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const gameDateTimeVerticalDividerContainerStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: 8,
  paddingRight: 8,
};

const gameDateTimeVerticalDividerStyle = {
  height: '45%',
  width: 2,
  borderLeft: '2px solid #BCBCBC',
};

const gameDateTextStyle = {
  font: 'normal normal 600 10px/15px Barlow, Medium',
  color: '#44444F',
};

const gameTimeTextStyle = {
  font: 'normal normal 600 10px/15px Barlow, Medium',
  color: '#44444F',
};

const gameItemInformationContainerStyle = {
  width: '100%',
  height: 120,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

const gameItemPropertyTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal normal 11px/12px Barlow, Medium',
  color: '#5D5D5D',
  paddingTop: 2,
  textAlign: 'center',
  ...textEllipsisStyle,
};

const gameItemSeasonTextStyle = {
  width: '100%',
  height: 'auto',
  font: 'normal normal normal 11px/12px Barlow, Medium',
  color: '#5D5D5D',
  paddingTop: 2,
  textAlign: 'center',
};

const emptyContainerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const emptySelectedGamesMessageTextContainerStyle = {
  width: '80%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'normal normal normal 11px/12px Inter',
  color: '#3D3D3D',
  textAlign: 'center',
};

const emptySelectedGamesMessageTextStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

/*************** END STYLES *************/

/***************************************************** BEGINNING GAMES SECTION ****************************************************************/
const TopRowGameContent = ({ infoRef, dispatch }) => {
  // const switchControlRef = useRef();
  const userRoleSwitchValue = useSelector(selectHomeShowGameUsers);

  const onSwitchValueChange = () => {
    if (!userRoleSwitchValue) {
      infoRef.current?.click();
    }
    dispatch(toggleAllGameUserDisplayedStatus());
  };

  return (
    <div style={{ ...topRowGameContainerStyle, height: 45 }}>
      <div style={topRowGameContentStyle}>
        <Form.Group className={'d-flex flex-row align-items-end'}>
          <Form.Label style={userRoleSwitchLabelStyle}>User Roles</Form.Label>
          <Form.Check
            // ref={switchControlRef}
            type="switch"
            value={userRoleSwitchValue}
            onChange={() => onSwitchValueChange()}
          />
        </Form.Group>
      </div>
      <img
        ref={infoRef}
        src={Information}
        width="20px"
        height="20px"
        style={{ position: 'absolute', top: 9, right: 5 }}
        data-for="show-information-tip"
        data-event="click"
        data-tip=""
        alt=""
      />
    </div>
  );
};

const TabRowContainer = ({
  todayGameCount,
  upcomingGamesCount,
  passGamesCount,
  pageListItemsRef,
  scrollPositions,
  isScrolling = false,
  todaysGames,
  upcomingGames,
  pastGames,
  setCalendarGameItems,
  dispatch,
}) => {
  const selectedStatus = useSelector(selectActiveTab);
  const canChangeTabs = useSelector(selectCanChangeTabs);

  /* Should always be able to change tabs after initial render */
  useEffect(() => {
    dispatch(updateCanChangeTabs(true));
  }, [dispatch]);

  useEffect(() => {
    if (selectedStatus === GamesTabs.TODAY) {
      setCalendarGameItems([...todaysGames]);
    } else if (selectedStatus === GamesTabs.UPCOMING) {
      setCalendarGameItems([...upcomingGames]);
    } else if (selectedStatus === GamesTabs.PAST) {
      setCalendarGameItems([...pastGames]);
    }
  }, [
    pastGames,
    selectedStatus,
    setCalendarGameItems,
    todaysGames,
    upcomingGames,
  ]);

  const onLiveGamesTabHandler = (gameTabValue) => {
    setCalendarGameItems([...todaysGames]);
    dispatch(updateActiveTab(gameTabValue));
  };

  const onUpcomingGamesTabHandler = (gameTabValue) => {
    setCalendarGameItems([...upcomingGames]);
    dispatch(updateActiveTab(gameTabValue));
  };

  const onPastGamesTabHandler = (gameTabValue) => {
    setCalendarGameItems([...pastGames]);
    dispatch(updateActiveTab(gameTabValue));
  };

  return (
    <div
      style={{
        ...topRowGameContainerStyle,
        justifyContent: 'flex-start',
        height: 'auto',
      }}
    >
      <div
        style={{
          ...tabbarControlContainerStyles,
          borderBottom: isScrolling && '1px solid #D2D2D2',
        }}
      >
        <GameTabs
          showCounts={false}
          liveGamesCount={todayGameCount}
          upcomingGamesCount={upcomingGamesCount}
          pastGamesCount={passGamesCount}
          activeLiveGamesStatus={selectedStatus === GamesTabs.TODAY}
          activeUpcomingGamesStatus={selectedStatus === GamesTabs.UPCOMING}
          activePastGamesStatus={selectedStatus === GamesTabs.PAST}
          liveGamesClickHandler={() => onLiveGamesTabHandler(GamesTabs.TODAY)}
          upcomingGamesClickHandler={() =>
            onUpcomingGamesTabHandler(GamesTabs.UPCOMING)
          }
          pastGamesClickHandler={() => onPastGamesTabHandler(GamesTabs.PAST)}
          canChangeTabs={canChangeTabs}
        />
      </div>
    </div>
  );
};

const GameItems = ({ gameItems, selectedGameId }) => {
  const history = useHistory();

  return (
    <>
      {gameItems.map((game, index) => {
        return (
          <GameContentItem
            key={`${game.homeTeamVsAwayTeam}_${index}`}
            game={game}
            editTeamCtrl={null}
            cardItemWidth={225}
            cardItemHeight={265}
            cardOuterMargin={'5px'}
            teamImageHeight={35}
            teamTextFontSize={12}
            teamTextLineHeight={'13px'}
            versusCircleSize={30}
            versusTextFontSize={15}
            marginTopStyle={15}
            dateFontSize={12}
            datePaddingBottom={'1px'}
            venueFontSize={13}
            homeAwayTeamHeight={80}
            homeAwayTeamNameMinHeight={14}
            homeAwayTeamMarginTop={5}
            homeAwayTeamMarginBottom={5}
            leagueMarginTop={8}
            leagueHeight={45}
            leagueNameFontSize={14}
            leagueAbbrvFontSize={11}
            showGameUsers={true}
            cardStateHeight={100}
            transitionProperty={'height'}
            navigateToGameDetailsPage={true}
            history={history}
            hasChanged={selectedGameId === game.id}
          />
        );
      })}
    </>
  );
};

const GameItemsContainer = ({
  gameItems,
  emptyGameMessage,
  selectedGameId,
  fetchNextPage,
  hasNextPage,
}) => {
  /**
   * Other tabs are disabled when we're displaying a search result.
   * Infinite scrolling should also be disabled then.
   */
  const canChangeTabs = useSelector(selectCanChangeTabs);

  return (
    <Row
      style={{ width: '100%', height: gameItems.length > 0 ? 'auto' : '100%' }}
    >
      <Col xl={12} xs={12} md={12} className="p-0">
        <div style={gameItemContentStyle}>
          <InfiniteScrollList
            displayedData={gameItems}
            fetchNextPage={fetchNextPage}
            hasNextPage={!canChangeTabs ? false : hasNextPage}
            displayedDataFilter={useCallback(
              () => (
                <GameItems
                  gameItems={gameItems}
                  selectedGameId={selectedGameId}
                />
              ),
              [gameItems, selectedGameId]
            )}
            unavailableDataStmt={emptyGameMessage}
          />
        </div>
      </Col>
    </Row>
  );
};

const GamesContainer = ({
  gameItems = [],
  allTodaysGames,
  allUpcomingGames,
  allPastGames,
  setGameItems,
  todaysGames = [],
  setTodaysGames,
  upcomingGames = [],
  setUpcomingGames,
  pastGames = [],
  setPastGames,
  dispatch,
  selectedGameId,
  scrollPositions,
  pageListItemsRef,
  homeShowGameUsersStatus,
  // homeGameSearchState,
  // gameItemsStore,
  isScrolling,
  setIsScrolling,
  fetchNextPage,
  hasNextPage,
  isFetching,
  fetchAll,
  cancelFetchAll,
}) => {
  const [emptyGameMsg, setEmptyGameMsg] = useState();
  const selectedStatus = useSelector(selectActiveTab);
  const [gameItemsSearch, setGameItemsSearch] = useState('');

  // let typeahead = useRef();

  /**
   * Not sure why this scroll behavior was in here.
   * Commenting it out because it's causing issues.
   */
  // const updateScrollPosition = useCallback(() => {
  //   log.info(`updateScrollPosition: ${selectedStatus}`);

  //   if (
  //     selectedStatus === GamesTabs.TODAY &&
  //     gameItems.length > 0 &&
  //     scrollPositions.todaysGamesScrollValue > 0
  //   ) {
  //     pageListItemsRef.current?.scrollTo({
  //       top: scrollPositions.todaysGamesScrollValue,
  //       behavior: 'smooth',
  //     });
  //   } else if (
  //     selectedStatus === GamesTabs.UPCOMING &&
  //     gameItems.length > 0 &&
  //     scrollPositions.upcomingGamesScrollValue > 0
  //   ) {
  //     pageListItemsRef.current?.scrollTo({
  //       top: scrollPositions.upcomingGamesScrollValue,
  //       behavior: 'smooth',
  //     });
  //   } else if (
  //     selectedStatus === GamesTabs.PAST &&
  //     gameItems.length > 0 &&
  //     scrollPositions.pastGamesScrollvalue > 0
  //   ) {
  //     pageListItemsRef.current?.scrollTo({
  //       top: scrollPositions.pastGamesScrollvalue,
  //       behavior: 'smooth',
  //     });
  //   }
  // }, [
  //   gameItems.length,
  //   pageListItemsRef,
  //   scrollPositions.pastGamesScrollvalue,
  //   scrollPositions.todaysGamesScrollValue,
  //   scrollPositions.upcomingGamesScrollValue,
  //   selectedStatus,
  // ]);

  /**
   * Not sure why this scroll behavior was in here.
   * Commenting it out because it's causing issues.
   */
  // useEffect(() => {
  //   if (homeShowGameUsersStatus) {
  //     log.info('useEffect scroll w/timeout');
  //     setTimeout(() => updateScrollPosition(), 1000);
  //   } else {
  //     log.info('useEffect scroll');
  //     updateScrollPosition();
  //   }
  // }, [homeShowGameUsersStatus]);

  useEffect(() => {
    if (selectedStatus === GamesTabs.TODAY) {
      if (todaysGames.length === 0) {
        dispatch(updateActiveTab(GamesTabs.UPCOMING));
      } else {
        setGameItems(todaysGames);
      }
    }
  }, [todaysGames, selectedStatus, setGameItems, dispatch]);

  useEffect(() => {
    if (selectedStatus === GamesTabs.UPCOMING) {
      if (upcomingGames.length === 0) {
        setEmptyGameMsg(
          EMPTY_GAME_ITEMS_STATEMENTS.UPCOMING_GAMES_EMPTY_MESSAGE
        );
      }
      setGameItems(upcomingGames);
    }
  }, [upcomingGames, selectedStatus, setGameItems]);

  useEffect(() => {
    if (selectedStatus === GamesTabs.PAST) {
      if (pastGames.length === 0) {
        setEmptyGameMsg('');
      }
      setGameItems(pastGames);
    }
  }, [pastGames, selectedStatus, setGameItems]);

  useEffect(() => {
    log.debug('search', gameItemsSearch);
    if (gameItemsSearch) {
      if (typeof gameItemsSearch === 'object') {
        /** Object Selection */
        /* Can stop fetching add'l games, user found what they wanted. */
        cancelFetchAll();
        setGameItems([gameItemsSearch]);
        dispatch(updateCanChangeTabs(false));

        const tab = getTabByGameDateTime(gameItemsSearch.gameDateTime);
        if (tab) dispatch(updateActiveTab(tab));
      } else if (gameItemsSearch.length > 0) {
        setTodaysGames(
          allTodaysGames.filter((option) =>
            searchFilter(option, gameItemsSearch)
          )
        );
        setUpcomingGames(
          allUpcomingGames.filter((option) =>
            searchFilter(option, gameItemsSearch)
          )
        );
        setPastGames(
          allPastGames.filter((option) => searchFilter(option, gameItemsSearch))
        );

        dispatch(updateCanChangeTabs(true));
      }
    } else {
      setTodaysGames(allTodaysGames);
      setUpcomingGames(allUpcomingGames);
      setPastGames(allPastGames);
      dispatch(updateCanChangeTabs(true));
    }
  }, [
    allPastGames,
    allTodaysGames,
    allUpcomingGames,
    cancelFetchAll,
    dispatch,
    gameItemsSearch,
    setGameItems,
    setPastGames,
    setTodaysGames,
    setUpcomingGames,
  ]);

  /**
   * After a delay of 500ms, set isScrolling to false.
   */
  const stillScrolling = useMemo(
    () => debounce(() => setIsScrolling(false), 500),
    [setIsScrolling]
  );

  /**
   * Signal that we are scrolling. Updates state as necessary.
   */
  const scrollingSignal = useMemo(
    () =>
      debounce(
        () => {
          setIsScrolling(true);
          stillScrolling();
        },
        500,
        {
          leading: true,
          trailing: true,
        }
      ),
    [stillScrolling, setIsScrolling]
  );

  /**
   * Save scroll position in redux state
   */
  const saveScrollPosition = useMemo(
    () =>
      debounce((position) => {
        if (selectedStatus === GamesTabs.TODAY) {
          dispatch(updateTodaysGameScrollPosition(position));
        } else if (selectedStatus === GamesTabs.UPCOMING) {
          dispatch(updateUpcomingGameScrollPosition(position));
        } else if (selectedStatus === GamesTabs.PAST) {
          dispatch(updatePastGameScrollPosition(position));
        }
      }, 500),
    [dispatch, selectedStatus]
  );

  /**
   * Throttled onScroll handler
   */
  const onScrollListHandler = useMemo(() => {
    return throttle(
      (event) => {
        scrollingSignal();
        const { scrollTop } = event.currentTarget || event.target;
        saveScrollPosition(scrollTop);
      },
      200,
      {
        leading: true,
        trailing: true,
      }
    );
  }, [saveScrollPosition, scrollingSignal]);

  return (
    <div
      style={{
        ...topRowGameContainerStyle,
        alignItems: 'center',
        height: '90%',
      }}
    >
      <div style={{ width: '95%', height: '100%' }}>
        <PageContent
          isFetching={isFetching}
          onSearch={fetchAll}
          searchId={'__homeGameId'}
          data={[...todaysGames, ...upcomingGames, ...pastGames]}
          dataProperty={(option) => option.homeTeamVsAwayTeam}
          placeholder={'Search Games'}
          onChange={setGameItemsSearch}
          filterBy={(option, props) => searchFilter(option, props.text)}
          searchControlStyle={searchControlStyle}
          setPageListRefElement={(refElement) =>
            (pageListItemsRef.current = refElement)
          }
          // setRef={(ref) => (typeahead = ref)}
          onScrollListHandler={onScrollListHandler}
          renderMenuItemChildren={(option, props) => {
            return (
              <SearchMenuComponent
                mainCriteriaText={[
                  { option: option.homeTeamVsAwayTeam, text: props.text },
                ]}
                subCriteriaText={[
                  {
                    option: option.venueName,
                    text: props.text,
                    separator: '-',
                  },
                  {
                    option: option.venueCity,
                    text: props.text,
                    separator: ',',
                  },
                  { option: option.venueState, text: props.text },
                ]}
                subCriteriaDarkText={[
                  {
                    option: formatDate(option.gameDateTime),
                    text: props.text,
                    separator: '|',
                  },
                  { option: formatTime(option.gameDateTime), text: props.text },
                ]}
              />
            );
          }}
        >
          <GameItemsContainer
            gameItems={gameItems}
            emptyGameMessage={emptyGameMsg}
            selectedGameId={selectedGameId}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </PageContent>
      </div>
    </div>
  );
};

const TooltipContent = () => {
  const TipRowItem = ({ itemBgColor, itemTitle }) => {
    return (
      <div style={tipRowItemStyle}>
        <div
          style={{ ...tipRowItemBgColorStyle, backgroundColor: itemBgColor }}
        ></div>
        <div style={tipRowItemTitleStyle}>{itemTitle}</div>
      </div>
    );
  };

  return (
    <div style={{ width: 130, height: 'auto' }}>
      <Row>
        <Col xs={12} md={12} xl={12} className="p-0">
          <div style={tooltipLegendContentStyle}>Legend</div>
          <div style={tipRowItemContainerStyle}>
            <TipRowItem itemTitle={'Clock Manager'} itemBgColor={'#008d39'} />
            <TipRowItem itemTitle={'Score Keeper'} itemBgColor={'#c78c00'} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
/*********************************************************************************************************************************************/

/************************************************************ CALENDAR SECTION ***************************************************************/
const getGameItemDateTimeFormat = (dateTime, dateTimeformat) => {
  return moment(dateTime).format(dateTimeformat);
};

const getCalendarGameDateItemColor = (date) => {
  const today = moment().startOf('d');
  const todaysDate = today.format('MM/DD/YYYY');

  const todaysDateTime = today.valueOf();
  const currentDateTime = moment(date).valueOf();

  let dateColor;
  if (moment(date).format('MM/DD/YYYY') === todaysDate) {
    dateColor = '#008d18';
  } else if (currentDateTime < todaysDateTime) {
    dateColor = '#535353';
  } else if (currentDateTime > todaysDateTime) {
    dateColor = '#009FEE';
  }

  return dateColor;
};

const setActiveCalendarChildElement = (calendarElement, date, allGameItems) => {
  const previousActiveChildElement = calendarElement.querySelector(
    '.react-calendar__tile--today, .react-calendar--tile--upcoming, .react-calendar__tile--past'
  );
  previousActiveChildElement?.classList.remove(
    'react-calendar__tile--today',
    'react-calendar--tile--upcoming',
    'react-calendar__tile--past'
  );
  const activeChildElement = calendarElement.querySelector(
    '.react-calendar__tile--active'
  );

  if (
    activeChildElement?.classList.contains('react-calendar__tile--selectedDate')
  ) {
    activeChildElement?.classList.remove('react-calendar__tile--selectedDate');
  }

  const today = moment().startOf('d');
  const todaysDate = today.format('MM/DD/YYYY');
  const currentDate = moment(date).format('MM/DD/YYYY');

  const todaysDateTime = today.valueOf();
  const currentDateTime = moment(date).valueOf();

  const gameItems = allGameItems.filter(
    ({ gameDateTime }) =>
      currentDate === moment(gameDateTime).format('MM/DD/YYYY')
  );

  if (gameItems.length > 0) {
    if (currentDate === todaysDate) {
      activeChildElement?.classList.add('react-calendar__tile--today');
    } else if (currentDateTime < todaysDateTime) {
      activeChildElement?.classList.add('react-calendar__tile--past');
    } else if (currentDateTime > todaysDateTime) {
      activeChildElement?.classList.add('react-calendar--tile--upcoming');
    }
  } else {
    activeChildElement?.classList.add('react-calendar__tile--nogames');
  }
};

const CalendarDateSelectedEmptyGameItems = ({
  dateTime,
  gameItems,
  selectedGameType,
  getSelectGameTypeText,
  calendarValueSelected = '',
  isCollapsed,
}) => {
  return (
    <div
      style={{
        ...emptyContainerStyle,
        justifyContent: isCollapsed ? 'flex-start' : 'center',
      }}
    >
      <div style={emptySelectedGamesMessageTextContainerStyle}>
        <div style={emptySelectedGamesMessageTextStyle}>
          {gameItems.length === 0 && <>{'There are no'}</>}
          {gameItems.length > 0 &&
            calendarValueSelected !== 'Select a Year' &&
            calendarValueSelected !== 'Select a Decade' && (
              <>{'There are no games for'}</>
            )}
        </div>
        <div style={{ width: '100%', height: 5 }}></div>
        <div style={emptySelectedGamesMessageTextStyle}>
          {calendarValueSelected.length > 0 ? (
            <>{calendarValueSelected}</>
          ) : gameItems.length > 0 ? (
            <>
              {getFormattedDateCasedWithDaySuffixedSuperScript(
                moment(dateTime).startOf('day'),
                'MMMM Do YYYY',
                ','
              )}
            </>
          ) : (
            <>{getSelectGameTypeText(selectedGameType)}</>
          )}
        </div>
      </div>
    </div>
  );
};

const CollapsedCalendarGameItems = ({
  gameItems,
  gameItemIdentifierHandler,
}) => {
  const HometeamVsAwayteam = ({ homeTeam, awayTeam }) => {
    return (
      <>
        <div style={teamNameTextStyle}>{homeTeam}</div>
        <div style={versusTextStyle}>Vs</div>
        <div style={teamNameTextStyle}>{awayTeam}</div>
      </>
    );
  };

  return (
    <>
      {gameItems.map(
        ({
          id,
          gameDateTime,
          leagueAbbreviation,
          seasonType,
          seasonStartDate,
          seasonEndDate,
          venueName,
          homeTeamName,
          awayTeamName,
        }) => (
          <div
            key={id}
            style={selectedCalendarDateGameItemStyle}
            onClick={() => gameItemIdentifierHandler(id)}
          >
            <div style={gameItemCalendarDateTimeStyle}>
              <div style={gameItemCalendarDateTimeContentStyle}>
                <div style={gameItemDateAndTimeTextStyle}>
                  <span style={gameDateTextStyle}>
                    {getFormattedDateCasedWithDaySuffixedSuperScript(
                      moment(gameDateTime).startOf('d'),
                      'MMM Do YYYY',
                      ','
                    )}
                  </span>
                </div>
                <div style={gameDateTimeVerticalDividerContainerStyle}>
                  <div style={gameDateTimeVerticalDividerStyle}></div>
                </div>
                <div style={gameItemDateAndTimeTextStyle}>
                  <span style={gameTimeTextStyle}>
                    {getGameItemDateTimeFormat(gameDateTime, 'h:mma')}
                  </span>
                </div>
              </div>
            </div>
            <div style={gameItemInformationContainerStyle}>
              <div
                style={{
                  width: '100%',
                  borderBottom: '2px solid black',
                }}
              ></div>
              <div style={homeVsAwayTeamNameTextStyle}>
                <HometeamVsAwayteam
                  homeTeam={homeTeamName}
                  awayTeam={awayTeamName}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  borderBottom: '2px solid black',
                }}
              ></div>
              <div style={gameItemPropertyTextStyle}>{venueName}</div>
              <div style={gameItemSeasonTextStyle}>
                {seasonFormatDisplay({
                  seasonType,
                  startDate: seasonStartDate,
                  endDate: seasonEndDate,
                })}
              </div>
              <div style={gameItemPropertyTextStyle}>{leagueAbbreviation}</div>
            </div>
          </div>
        )
      )}
    </>
  );
};

const CollapsedCalendarContainer = ({
  selectedGameType,
  gameItems,
  allGameItems,
  dateDayText,
  dateFormatText,
  dateTime,
  selectedCalendarGameItems,
  calenderContainerHeight,
  gameItemIdentifierHandler,
  setGameInformationForCalendar,
  getSelectGameTypeText,
  setSelectedGameItemsGameType,
  isCollapsed,
  isResizeStarted,
}) => {
  const [
    collapsedGameItemsContainerHeight,
    setCollapsedGameItemsContainerHeight,
  ] = useState(0);

  const collapsedCalendarRef = useRef();

  useEffect(() => {
    if (calenderContainerHeight > 0) {
      const collapsedCalendarHeight = collapsedCalendarRef.current?.clientHeight;
      const gameItemsContainerHeight = calenderContainerHeight - collapsedCalendarHeight;
      setCollapsedGameItemsContainerHeight(gameItemsContainerHeight);
    }
  }, [calenderContainerHeight]);

  const nextDateHandler = (event, currentDateTime, allGameItems) => {
    const nextDateTime = moment(currentDateTime)
      .add(1, 'day')
      .startOf('day')
      .valueOf();
    setGameInformationForCalendar(nextDateTime, allGameItems);
    setSelectedGameItemsGameType(nextDateTime);
    event.stopPropagation();
  };

  const previousDateHandler = (event, currentDateTime, allGameItems) => {
    const previousDateTime = moment(currentDateTime)
      .subtract(1, 'day')
      .startOf('day')
      .valueOf();
    setGameInformationForCalendar(previousDateTime, allGameItems);
    setSelectedGameItemsGameType(previousDateTime);
    event.stopPropagation();
  };

  return (
    <div style={collapsedExpandedCalendarContainerStyles}>
      <div ref={collapsedCalendarRef} style={topCalendarContentItemStyle}>
        <div style={calendarImageContainerStyle}>
          <div
            style={{
              ...calenderChevronImageContainerStyle,
              justifyContent: 'flex-start',
            }}
            onClick={(event) =>
              previousDateHandler(event, dateTime, allGameItems)
            }
          >
            <img src={RightChevron} width="10px" height="20px" alt="Previous" />
          </div>
          <img
            src={CalendarIcon}
            width="45px"
            height="40px"
            alt="Calendar Icon"
          />
          <div
            style={{
              ...calenderChevronImageContainerStyle,
              justifyContent: 'flex-end',
            }}
            onClick={(event) => nextDateHandler(event, dateTime, allGameItems)}
          >
            <img src={LeftChevron} width="10px" height="20px" alt="Next" />
          </div>
        </div>
        <div style={dateFormatTextContainerStyle}>
          <div style={dateFormatTextStyle}>
            <div style={dayTextDisplayStyle}>{dateDayText}</div>
            <div style={dateTextDisplayStyle}>
              <div
                style={{
                  width: 'auto',
                  height: 'auto',
                  paddingBottom: 2,
                  borderBottom: '2px solid black',
                }}
              >
                {dateFormatText}
              </div>
            </div>
          </div>
        </div>
        <div style={selectedGameItemsCountSizeStyle}>
          {selectedCalendarGameItems?.length > 0 && (
            <div style={selectedGameItemsCountSizeTextStyle}>
              {`${selectedCalendarGameItems?.length} games`}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          ...bottomCalendarContentItemStyle,
          height: collapsedGameItemsContainerHeight,
        }}
      >
        <ScrollListItemsContainer
          data={selectedCalendarGameItems}
          listStyle={{ flexDirection: 'column', alignItems: 'center' }}
        >
          {selectedCalendarGameItems?.length > 0 ? (
            <CollapsedCalendarGameItems
              gameItems={isResizeStarted ? [] : selectedCalendarGameItems}
              gameItemIdentifierHandler={gameItemIdentifierHandler}
            />
          ) : (
            <CalendarDateSelectedEmptyGameItems
              dateTime={dateTime}
              gameItems={gameItems}
              selectedGameType={selectedGameType}
              getSelectGameTypeText={getSelectGameTypeText}
              isCollapsed={isCollapsed}
            />
          )}
        </ScrollListItemsContainer>
      </div>
    </div>
  );
};

const ExpandedCalendarGameItems = ({
  gameItems,
  gameItemIdentifierHandler,
  setSelectedGameItemsGameType,
}) => {
  const getDefinedWidth = (teamName) => {
    return teamName.length > 30 ? '46%' : 'auto';
  };

  const scrollToGameItem = (id, gameDateTime) => {
    log.info('scrollToGameItem', id, gameDateTime);
    setSelectedGameItemsGameType(moment(gameDateTime).valueOf());
    gameItemIdentifierHandler(id);
  };

  return (
    <div style={expandedSelectedCalendarGameItemsContainerStyle}>
      {gameItems.map(
        ({
          id,
          gameDateTime,
          leagueName,
          leagueAbbreviation,
          seasonType,
          seasonStartDate,
          seasonEndDate,
          venueName,
          homeTeamName,
          awayTeamName,
        }) => (
          <div
            key={id}
            style={expandedSelectedCalendarGameItemContainerStyle}
            onClick={() => scrollToGameItem(id, gameDateTime)}
          >
            <div
              style={{
                ...expandedSelectedCalendarGameItemDateTimeStyle,
                backgroundColor: getCalendarGameDateItemColor(gameDateTime),
              }}
            >
              <div style={expandedSelectedCalendarGameItemDateStyle}>
                <div
                  style={{
                    width: 'auto',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {getFormattedDateCasedWithDaySuffixedSuperScript(
                    moment(gameDateTime).startOf('day'),
                    'MMMM Do YYYY',
                    ','
                  )}
                </div>
              </div>
              <div style={expandedSelectedCalendarGameItemTimeStyle}>
                {getGameItemDateTimeFormat(gameDateTime, 'h:mma')}
              </div>
            </div>
            <div style={expandedSelectedCalendarGameItemInfoContainerStyle}>
              <div
                style={
                  expandedSelectedGameItemHomeTeamVsAwayTeamNamesContainerStyle
                }
              >
                <div style={expandedSelectedGameItemTeamNameStyles}>
                  <div
                    style={{
                      width: getDefinedWidth(homeTeamName),
                      ...expandedSelectedGameItemTeamNameTextStyle,
                    }}
                  >
                    {homeTeamName}
                  </div>
                  <div style={expandedSelectedGameItemVersusTextStyle}>Vs</div>
                  <div
                    style={{
                      width: getDefinedWidth(awayTeamName),
                      ...expandedSelectedGameItemTeamNameTextStyle,
                    }}
                  >
                    {awayTeamName}
                  </div>
                </div>
                <div
                  style={expandedSelectedGameItemUnderlineTeamNameStyle}
                ></div>
              </div>
              <div style={expandedSelectedGameItemSeasonAndVenueInfoStyle}>
                <div style={expandedSelectedGameItemSeasonTextStyle}>
                  {seasonFormatDisplay({
                    seasonType,
                    startDate: seasonStartDate,
                    endDate: seasonEndDate,
                  })}
                </div>
                <div style={expandedSelectedGameItemSeasonVenueDividerStyle}>
                  <div style={expandedSelectedGameItemDividerStyle}></div>
                </div>
                <div style={expandedSelectedGameItemVenueTextStyle}>
                  {venueName}
                </div>
              </div>
              <div style={expandedSelectedGameItemSeasonAndVenueInfoStyle}>
                <div style={{ width: 'auto', height: 'auto' }}>
                  {leagueAbbreviation}
                </div>
                <div style={expandedSelectedGameItemHyphenSeparatorStyle}>
                  -
                </div>
                <div style={expandedSelectedGameItemLeagueNameTextStyle}>
                  {leagueName}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const ExpandedCalendarContainer = ({
  dateTime,
  gameItems,
  allGameItems,
  selectedGameType,
  getSelectGameTypeText,
  selectedCalendarGameItems,
  gameItemIdentifierHandler,
  setSelectedGameItemsGameType,
  setGameInformationForCalendar,
  getFormattedGameDateTimeText,
  emptyCalendarGameItems,
  setIsExpandedCalendarDateNotSelected,
  calenderContainerHeight: calendarContainerHeight,
  isMobile,
  isCollapsed,
  userItems,
  isLoading,
  isResizeStarted,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [calendarView, setCalendarView] = useState(CALENDAR_VIEW.MONTH);
  const [calendarDate, setCalendarDate] = useState();
  const [activeCalendarStartDate, setActiveCalendarStartDate] = useState(
    new Date()
  );
  const [calendarValueSelected, setCalendarValueSelected] = useState('');
  const [
    calendarSelectedGamesHeight,
    setCalendarSelectedGamesHeight,
  ] = useState(0);
  const [isPrevCalendarBtnShown, setIsPrevCalendarBtnShown] = useState(false);
  const [isNextCalendarBtnShown, setIsNextCalendarBtnShown] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const queryDateRange = useSelector(selectDateRange);

  const calendarRef = useRef();

  useEffect(() => {
    calculateCalendarSelectedGamesHeight(calendarContainerHeight);
  }, [calendarContainerHeight]);

  useEffect(() => {
    setCalendarView(CALENDAR_VIEW.MONTH);
    setCalendarDate(dateTime);
    setActiveCalendarStartDate(moment(dateTime).startOf('month').toDate());
    calculateCalendarSelectedGamesHeight(calendarContainerHeight);
    setIsPrevCalendarBtnShown(true);
    setIsNextCalendarBtnShown(true);
    addCalendarTypeCustomClass('month');

    const timeoutId = setTimeout(() => {
      setActiveCalendarChildElement(
        calendarRef.current,
        dateTime,
        selectedCalendarGameItems
      );
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [dateTime]);

  const onChangeCalendarDate = (dateValue) => {
    setCalendarDate(dateValue);
    setCalendarValueSelected('');
    setIsExpandedCalendarDateNotSelected(false);
    setSelectedGameItemsGameType(moment(dateValue).valueOf());
    setGameInformationForCalendar(moment(dateValue), allGameItems);
    setTimeout(() => {
      setActiveCalendarChildElement(
        calendarRef.current,
        dateValue,
        allGameItems
      );
    }, 100);
  };

  const calculateCalendarSelectedGamesHeight = (calenderContainerHeight) => {
      const calendarHeight = calendarRef.current.clientHeight;
      const gameListItemsHeight = calenderContainerHeight - calendarHeight - 19;
      setCalendarSelectedGamesHeight(gameListItemsHeight);
  };

  const setGameItemsForSelectedMonth = (activeDate) => {
    setActiveCalendarStartDate(activeDate);
    setIsExpandedCalendarDateNotSelected(true);
    setGameInformationForCalendar(
      null,
      allGameItems,
      'MMM YYYY',
      moment(activeDate).format('MMM YYYY')
    );
    setCalendarValueSelected(moment(activeDate).format('MMMM YYYY'));
  };

  const setGameItemsForSelectedYear = (activeDate) => {
    setActiveCalendarStartDate(activeDate);
    setIsExpandedCalendarDateNotSelected(true);
    setGameInformationForCalendar(
      null,
      allGameItems,
      'YYYY',
      moment(activeDate).format('YYYY')
    );
    setCalendarValueSelected(moment(activeDate).format('YYYY'));
  };

  const addSelectedDateClassToActiveDateElement = () => {
    setTimeout(() => {
      const activeCalendarDateSelected = calendarRef.current.querySelector(
        '.react-calendar__tile--active'
      );
      activeCalendarDateSelected?.classList.add(
        'react-calendar__tile--selectedDate'
      );
    }, 20);
  };

  const addCalendarTypeCustomClass = (calendarType) => {
    switch (calendarType) {
      case 'month':
        const calendarDaysElement = calendarRef.current.querySelector(
          '.react-calendar__month-view__days'
        );
        const calendarWeekElement = calendarRef.current.querySelector(
          '.react-calendar__month-view__weekdays'
        );
        calendarDaysElement?.classList.add(
          isMobile
            ? 'react-calendar__calendar-month-view-ipad'
            : 'react-calendar__calendar-month-view-desktop'
        );
        calendarWeekElement?.classList.add(
          isMobile
            ? 'react-calendar__calendar-month-view-ipad'
            : 'react-calendar__calendar-month-view-desktop'
        );
        break;
      case 'year':
        const calendarYearElement = calendarRef.current.querySelector(
          '.react-calendar__year-view__months'
        );
        calendarYearElement?.classList.add(
          isMobile
            ? 'react-calendar__calendar-year-view-ipad'
            : 'react-calendar__calendar-year-view-desktop'
        );
        break;
      case 'decade':
        const calendarDecadeElement = calendarRef.current.querySelector(
          '.react-calendar__decade-view__years'
        );
        calendarDecadeElement?.classList.add(
          isMobile
            ? 'react-calendar__calendar-decade-view-ipad'
            : 'react-calendar__calendar-decade-view-desktop'
        );
        break;
      default:
        log.warn(`Unrecognized calendarType: ${calendarType}`);
    }
  };

  const onDrillUpHandler = ({ action, activeStartDate, value, view }) => {
    if (action === 'drillUp') {
      if (view === 'year') {
        setCalendarView(CALENDAR_VIEW.YEAR);
        addCalendarTypeCustomClass('year');
        setGameItemsForSelectedYear(activeStartDate);
      } else if (view === 'decade') {
        setCalendarView(CALENDAR_VIEW.DECADE);
        emptyCalendarGameItems();
        addCalendarTypeCustomClass('decade');
        setCalendarValueSelected('Select a Year');
      }
    }

    setTimeout(
      () => calculateCalendarSelectedGamesHeight(calendarContainerHeight),
      50
    );
  };

  const onDrillDownHandler = ({ action, activeStartDate, value, view }) => {
    if (action === 'drillDown') {
      if (view === 'month') {
        setCalendarView(CALENDAR_VIEW.MONTH);
        setIsPrevCalendarBtnShown(true);
        setIsNextCalendarBtnShown(true);
        addCalendarTypeCustomClass('month');
        addSelectedDateClassToActiveDateElement();
        setGameItemsForSelectedMonth(activeStartDate);
      } else if (view === 'year') {
        setCalendarView(CALENDAR_VIEW.YEAR);
        setIsPrevCalendarBtnShown(true);
        setIsNextCalendarBtnShown(true);
        addCalendarTypeCustomClass('year');
        setGameItemsForSelectedYear(activeStartDate);
      } else if (view === 'decade') {
        setCalendarView(CALENDAR_VIEW.DECADE);
        emptyCalendarGameItems();
        addCalendarTypeCustomClass('decade');
        setCalendarValueSelected('Select a Year');
      }
    }

    setTimeout(
      () => calculateCalendarSelectedGamesHeight(calendarContainerHeight),
      50
    );
  };

  const getMonthValue = (monthNum) => {
    switch (monthNum) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        log.warn(`Invalid monthNum: ${monthNum}`);
    }
  };

  const getPrevIcon = () => {
    return <img src={RightChevron} width="10px" height="20px" alt="Previous" />;
  };

  const getNextIcon = () => {
    return <img src={LeftChevron} width="10px" height="20px" alt="Next" />;
  };

  const onActiveStartDateChangeHandler = ({
    action,
    activeStartDate,
    value,
    view,
  }) => {
    fetchOnViewChange({
      action,
      activeStartDate,
      value,
      view,
    });

    if (view === 'month' || view === 'year') {
      if (action === 'prev' || action === 'next') {
        setIsPrevCalendarBtnShown(true);
        setIsNextCalendarBtnShown(true);

        if (view === 'month') {
          addCalendarTypeCustomClass('month');
          addSelectedDateClassToActiveDateElement();
          setGameItemsForSelectedMonth(activeStartDate);
        } else if (view === 'year') {
          addCalendarTypeCustomClass('year');
          setGameItemsForSelectedYear(activeStartDate);
        }
      }
    } else if (view === 'decade' || view === 'century') {
      setIsPrevCalendarBtnShown(false);
      setIsNextCalendarBtnShown(false);
      emptyCalendarGameItems();

      if (view === 'decade') {
        setCalendarValueSelected('Select a Year');
      } else if (view === 'century') {
        setCalendarValueSelected('Select a Decade');
      }
    }

    setTimeout(
      () => calculateCalendarSelectedGamesHeight(calendarContainerHeight),
      50
    );
  };

  const tileContentHandler = ({ activeStartDate, date, view }) => {
    if (view === 'month') {
      const formattedGameDateTimeText = getFormattedGameDateTimeText(date);
      const gameItemsCount = allGameItems.filter(
        ({ gameDateTime }) =>
          formattedGameDateTimeText ===
          getFormattedGameDateTimeText(gameDateTime)
      ).length;
      const dateColor = getCalendarGameDateItemColor(date);

      return (
        <div style={calendarDateGameIndicatorContainerStyle}>
          {gameItemsCount > 0 && (
            <div
              style={{
                ...calendarDateGameIndicatorStyle,
                backgroundColor: dateColor,
              }}
            ></div>
          )}
        </div>
      );
    } else if (view === 'year') {
      const month = date.getMonth();
      const year = date.getFullYear();
      const activeMonth = moment().month();
      const activeYear = moment().year();

      return (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            {getMonthValue(month)}
          </div>
          <div style={calendarDateGameIndicatorContainerStyle}>
            {month === activeMonth && year === activeYear && (
              <div
                style={{
                  ...calendarDateGameIndicatorStyle,
                  backgroundColor: '#535353',
                }}
              ></div>
            )}
          </div>
        </>
      );
    } else if (view === 'decade') {
      const year = date.getFullYear();
      const activeYear = moment().year();

      return (
        <div
          style={{ ...calendarDateGameIndicatorContainerStyle, marginTop: 3 }}
        >
          {year === activeYear && (
            <div
              style={{
                ...calendarDateGameIndicatorStyle,
                backgroundColor: '#535353',
              }}
            ></div>
          )}
        </div>
      );
    }
  };

  const tileContentClassNameHandler = ({ activeStartDate, date, view }) => {
    if (view === 'month') {
      return isMobile
        ? 'home-page-react-calendar-ipad'
        : 'home-page-react-calendar-desktop';
    } else if (view === 'year') {
      return isMobile
        ? 'home-page-react-calendar-month-ipad'
        : 'home-page-react-calendar-month-desktop';
    } else if (view === 'decade') {
      return isMobile
        ? 'home-page-react-calendar-year-ipad'
        : 'home-page-react-calendar-year-desktop';
    }
  };

  /**
   * Function called when the user navigates from one view to another using
   * previous/next button.
   *
   * `action` signifies the reason for active start date change and
   * can be one of the following values:
   * "prev", "prev2", "next", "next2", "drillUp", "drillDown", "onChange".
   */
  const fetchOnViewChange = useCallback(
    async ({ action, activeStartDate, value, view }) => {
      const requestedDateRange = {
        startDate: moment(activeStartDate),
        endDate: moment(activeStartDate).endOf(view),
      };

      if (view === CALENDAR_VIEW.MONTH) {
        const allGamesQueries = queryClient.getQueriesData(
          gamesKeys.allDateRange()
        );

        log.debug('[fetchOnViewChange] params', {
          action,
          activeStartDate,
          value,
          view,
        });
        log.debug(
          '[fetchOnViewChange] allGamesQueries (straight from cache)',
          allGamesQueries
        );

        /* If we have cached data, start from there */
        if (allGamesQueries.length > 0) {
          const paramsIndex = gamesKeys.allDateRange().length;
          const allGamesQueriesSimplified = allGamesQueries.map((query) => ({
            startDate: query[0][paramsIndex].startDate,
            endDate: query[0][paramsIndex].endDate,
            diff: moment(query[0][paramsIndex].endDate).diff(
              query[0][paramsIndex].startDate
            ),
            data: query[1],
          }));
          const queryWithMaxRange = allGamesQueriesSimplified.reduce(
            (max, curr) => (curr.diff > max.diff ? curr : max),
            { diff: -1 }
          );
          const cacheDateRange = {
            startDate: queryWithMaxRange.startDate,
            endDate: queryWithMaxRange.endDate,
          };

          const cachedGames = queryWithMaxRange.data;

          log.debug(
            '[fetchOnViewChange] allGamesQueriesSimplified',
            allGamesQueriesSimplified
          );
          log.debug('[fetchOnViewChange] queryWithMaxRange', queryWithMaxRange);

          log.info(
            `[fetchOnViewChange] Requested Date Range (${view})`,
            dateRangeToISOString(requestedDateRange)
          );

          const [newDateRange, diffDateRange] = expandDateRange(
            cacheDateRange,
            requestedDateRange
          );

          log.debug(
            '[fetchOnViewChange] newDateRange',
            dateRangeToISOString(newDateRange)
          );
          log.debug(
            '[fetchOnViewChange] diffDateRange',
            diffDateRange ? dateRangeToISOString(diffDateRange) : diffDateRange
          );

          if (diffDateRange) {
            setShowSpinner(true);
            let newGames = await fetchAllGraphQL(
              async (nextToken) =>
                await getGamesInDateRange(
                  diffDateRange.startDate,
                  diffDateRange.endDate,
                  {
                    nextToken,
                  }
                )
            );

            newGames = await fetchGameImagesAndGameRoles(newGames, userItems);

            log.debug('[fetchOnViewChange] newGames', newGames);

            let combinedGames;
            if (diffDateRange.startDate.isBefore(cacheDateRange.startDate)) {
              combinedGames = [...newGames, ...cachedGames];
            } else {
              combinedGames = [...cachedGames, ...newGames];
            }

            log.debug('[fetchOnViewChange] combinedGames', combinedGames);
            queryClient.setQueryData(
              gamesKeys.dateRange(newDateRange.startDate, newDateRange.endDate),
              combinedGames
            );
          }

          dispatch(updateDateRange(dateRangeToISOString(newDateRange)));
          setShowSpinner(false);
        } else {
          /* No cached data to consider. */
          const [newDateRange] = expandDateRange(
            queryDateRange,
            requestedDateRange
          );
          dispatch(updateDateRange(dateRangeToISOString(newDateRange)));
        }
      } else if (view === CALENDAR_VIEW.YEAR) {
        dispatch(updateDateRange(dateRangeToISOString(requestedDateRange)));
      }
    },
    [dispatch, queryClient, queryDateRange, userItems]
  );

  return (
    <div style={collapsedExpandedCalendarContainerStyles}>
      <div style={topExpandedCalendarContentItemStyle}>
        <div style={calendarContainer}>
          {(isLoading || showSpinner) && calendarView === CALENDAR_VIEW.MONTH && (
            <CenteredSpinner animation="border" variant="dark" />
          )}

          <Calendar
            className={[
              'home-page-calendar',
              isMobile
                ? 'home-page-calendar-ipad'
                : 'home-page-calendar-desktop',
            ]}
            inputRef={calendarRef}
            value={calendarDate}
            // onViewChange={fetchOnViewChange}
            onChange={onChangeCalendarDate}
            onDrillUp={onDrillUpHandler}
            onDrillDown={onDrillDownHandler}
            tileContent={tileContentHandler}
            tileClassName={tileContentClassNameHandler}
            onActiveStartDateChange={onActiveStartDateChangeHandler}
            activeStartDate={activeCalendarStartDate}
            view={calendarView}
            prevLabel={isPrevCalendarBtnShown ? getPrevIcon() : null}
            nextLabel={isNextCalendarBtnShown ? getNextIcon() : null}
            prev2Label={null}
            prev2AriaLabel={null}
            next2Label={null}
            next2AriaLabel={null}
          />
        </div>
      </div>
      <div
        style={{
          ...bottomExpandedCalendarContentItemStyle,
          height: calendarSelectedGamesHeight,
        }}
      >
        <ScrollListItemsContainer
          data={selectedCalendarGameItems}
          listStyle={{ flexDirection: 'column', alignItems: 'center' }}
        >
          {selectedCalendarGameItems?.length > 0 ? (
            <ExpandedCalendarGameItems
              gameItems={isResizeStarted ? [] : selectedCalendarGameItems}
              gameItemIdentifierHandler={gameItemIdentifierHandler}
              setSelectedGameItemsGameType={setSelectedGameItemsGameType}
            />
          ) : (
            <CalendarDateSelectedEmptyGameItems
              dateTime={dateTime}
              gameItems={gameItems}
              selectedGameType={selectedGameType}
              getSelectGameTypeText={getSelectGameTypeText}
              calendarValueSelected={calendarValueSelected}
              isCollapsed={isCollapsed}
            />
          )}
        </ScrollListItemsContainer>
      </div>
    </div>
  );
};

const CalendarContainer = ({
  isCalendarCollapsed = false,
  selectedGameType,
  allGameItems,
  gameItems,
  gameItemIdentifierHandler,
  calenderHeight,
  isMobile,
  userItems,
  isLoading,
  isResizeStarted,
}) => {
  const dispatch = useDispatch();
  const isCollapsed = isCalendarCollapsed;
  const [dateDayText, setDateDayText] = useState('');
  const [dateFormatText, setDateFormatText] = useState('');
  const [dateTime, setDateTime] = useState(0);
  const [selectedCalendarGameItems, setSelectedCalendarGameItems] = useState(
    []
  );
  const [
    isExpandedCalendarDateNotSelected,
    setIsExpandedCalendarDateNotSelected,
  ] = useState(false);

  const isNextPreviousCtrlPressedRef = useRef(false);

  useEffect(() => {
    if (isExpandedCalendarDateNotSelected) {
      restoreCollapsedCalendarGameItems(allGameItems, selectedGameType);
      setIsExpandedCalendarDateNotSelected(false);
    }
  }, [isCollapsed]);

  // useEffect(() => {
  //   if (
  //     allGameItems?.length > 0
  //     // && (!startTodaysDateTime.current || !endTodaysDateTime.current)
  //   ) {
  //     const { gameDateTime } = allGameItems[0];

  //     const firstGameStartDateTime = moment(gameDateTime)
  //       .startOf('day')
  //       .valueOf();
  //     const firstGameEndDateTime = moment(gameDateTime).endOf('day').valueOf();
  //     const todaysStartDateTime = moment().startOf('day').valueOf();
  //     const todaysEndDateTime = moment().endOf('day').valueOf();

  //     if (firstGameStartDateTime >= todaysStartDateTime) {
  //       startTodaysDateTime.current = firstGameStartDateTime;
  //       endTodaysDateTime.current = firstGameEndDateTime;
  //     } else {
  //       startTodaysDateTime.current = todaysStartDateTime;
  //       endTodaysDateTime.current = todaysEndDateTime;
  //     }
  //   }
  // }, [allGameItems]);

  useEffect(() => {
    if (!isNextPreviousCtrlPressedRef.current) {
      if (gameItems.length > 0) {
        const firstTabGame = gameItems[0];
        const { gameDateTime } = firstTabGame;
        setGameInformationForCalendar(gameDateTime, allGameItems);
      } else {
        const today = moment();
        const firstGameItemDateTime = moment(allGameItems[0]?.gameDateTime);
        const currentGameDateTime =
          selectedGameType === GamesTabs.UPCOMING &&
          today.format('MM/DD/YYYY') ===
            moment(firstGameItemDateTime)?.format('MM/DD/YYYY')
            ? today.startOf('d').add(1, 'd').valueOf()
            : today.valueOf();
        formatDateText(currentGameDateTime);
        setSelectedCalendarGameItems([]);
      }
    }

    gameItemIdentifierHandler(null);
    isNextPreviousCtrlPressedRef.current = false;
  }, [gameItems]);

  const getFormattedGameDateTimeText = (gameDateTime) =>
    moment(gameDateTime).format('MM/DD/YYYY');

  const emptyCalendarGameItems = () => setSelectedCalendarGameItems([]);

  /**
   * Change to appropriate tab based on provided dateTime value.
   * @param {number} dateTime Unix timestamp in ms
   */
  const setSelectedGameItemsGameType = (dateTime) => {
    if (dateTime > 0) {
      const tab = getTabByGameDateTime(dateTime);

      if (tab !== GamesTabs.NONE) {
        isNextPreviousCtrlPressedRef.current = tab !== selectedGameType;
        dispatch(updateActiveTab(tab));
      }
    }
  };

  const restoreCollapsedCalendarGameItems = (
    allGameItems,
    selectedGameType
  ) => {
    const todaysStart = moment().startOf('d').valueOf();
    const todaysEnd = moment().endOf('d').valueOf();
    let gameItem;

    switch (selectedGameType) {
      case GamesTabs.TODAY:
        gameItem = allGameItems.find(
          ({ gameDateTime }) =>
            moment(gameDateTime).valueOf() > todaysStart &&
            moment(gameDateTime).valueOf() < todaysEnd
        );
        break;
      case GamesTabs.UPCOMING:
        gameItem = allGameItems.find(
          ({ gameDateTime }) => moment(gameDateTime).valueOf() > todaysEnd
        );
        break;
      case GamesTabs.PAST:
        gameItem = allGameItems.find(
          ({ gameDateTime }) => moment(gameDateTime).valueOf() < todaysStart
        );
        break;
      default:
    }

    setGameInformationForCalendar(gameItem.gameDateTime, allGameItems);
  };

  const getSelectGameTypeText = (selectedGameType) => {
    if (selectedGameType === GamesTabs.UPCOMING) {
      return 'upcoming games';
    } else if (selectedGameType === GamesTabs.PAST) {
      return 'past games';
    }
  };

  const formatDateText = (dateTime) => {
    const momentDateTimeInstance = moment(dateTime).startOf('day');
    const dateDay = momentDateTimeInstance.format('dddd');
    setDateDayText(dateDay);
    setDateFormatText(
      getFormattedDateCasedWithDaySuffixedSuperScript(
        momentDateTimeInstance,
        'MMMM Do YYYY',
        ','
      )
    );

    setDateTime((old) => old || new Date(dateTime));
  };

  const setGameInformationForCalendar = (
    dateTime,
    allGameItems,
    customFormatType,
    customFormatValue
  ) => {
    let gameDateTimeSelectedGameItems = [];

    if (!customFormatType || !customFormatValue) {
      const formattedGameDateTimeText = getFormattedGameDateTimeText(dateTime);
      gameDateTimeSelectedGameItems = allGameItems.filter(
        ({ gameDateTime }) =>
          formattedGameDateTimeText ===
          getFormattedGameDateTimeText(gameDateTime)
      );
      formatDateText(dateTime);
    } else {
      gameDateTimeSelectedGameItems = allGameItems
        .filter(
          ({ gameDateTime }) =>
            customFormatValue === moment(gameDateTime).format(customFormatType)
        )
        .sort(GAMES_SORTING_FN.byGameDateTimeDesc);
    }

    setSelectedCalendarGameItems(gameDateTimeSelectedGameItems);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isCollapsed ? (
        <CollapsedCalendarContainer
          selectedGameType={selectedGameType}
          gameItems={gameItems}
          allGameItems={allGameItems}
          gameItemIdentifierHandler={gameItemIdentifierHandler}
          dateDayText={dateDayText}
          dateFormatText={dateFormatText}
          dateTime={dateTime}
          getSelectGameTypeText={getSelectGameTypeText}
          selectedCalendarGameItems={selectedCalendarGameItems}
          setGameInformationForCalendar={setGameInformationForCalendar}
          setSelectedGameItemsGameType={setSelectedGameItemsGameType}
          calenderContainerHeight={calenderHeight}
          isCollapsed={isCollapsed}
          isResizeStarted={isResizeStarted}
        />
      ) : (
        <ExpandedCalendarContainer
          dateTime={dateTime}
          gameItems={gameItems}
          allGameItems={allGameItems}
          selectedGameType={selectedGameType}
          getSelectGameTypeText={getSelectGameTypeText}
          gameItemIdentifierHandler={gameItemIdentifierHandler}
          selectedCalendarGameItems={selectedCalendarGameItems}
          setSelectedGameItemsGameType={setSelectedGameItemsGameType}
          setGameInformationForCalendar={setGameInformationForCalendar}
          getFormattedGameDateTimeText={getFormattedGameDateTimeText}
          emptyCalendarGameItems={emptyCalendarGameItems}
          setIsExpandedCalendarDateNotSelected={
            setIsExpandedCalendarDateNotSelected
          }
          calenderContainerHeight={calenderHeight}
          isMobile={isMobile}
          isCollapsed={isCollapsed}
          userItems={userItems}
          isLoading={isLoading}
          isResizeStarted={isResizeStarted}
        />
      )}
    </div>
  );
};
/*********************************************************************************************************************************************/

const HomeContent = ({
  todaysGamesData,
  upcomingGamesData,
  pastGamesData,
  allGamesData,
  fetchNextPage,
  hasNextPage,
  isLoading,
  fetchAll,
  cancelFetchAll,
  userItems,
}) => {
  const dispatch = useDispatch();
  const pathLocationState = useSelector((state) => state.pathLocation.value);
  const selectedStatus = useSelector(selectActiveTab);

  const isCollapsed = useSelector(selectHomeCalendarCollapsed);
  const homeGamePageListScrollPos = useSelector(
    selectHomeGameListScrollPositions
  );
  const homeShowGameUsersStatus = useSelector(selectHomeShowGameUsers);
  const [chevron, setChevron] = useState();
  const [gameContainerWidth, setGameContainerWidth] = useState();
  const [calendarContainerWidth, setCalendarContainerWidth] = useState();

  const [gameItems, setGameItems] = useState([]);
  const [todaysGames, setTodaysGames] = useState(todaysGamesData);
  const [upcomingGames, setUpcomingGames] = useState(upcomingGamesData);
  const [pastGames, setPastGames] = useState(pastGamesData);

  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState();
  const [selectedGameItems, setSelectedGameItems] = useState([]);
  const [isResizeStarted, setIsResizeStarted] = useState(false);
  const [isDeviceMobile, setIsMobile] = useState(false);
  const [
    calendarContainerResizeHeight,
    setCalendarContainerResizeHeight,
  ] = useState(0);

  const infoRef = useRef();
  const pageListItemsRef = useRef();
  const calenderSectionRef = useRef();

  /** Reset selectedGameId after 2 seconds */
  useEffect(() => {
    if (selectedGameId) {
      const timeoutId = setTimeout(() => setSelectedGameId(), 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedGameId]);

  useEffect(() => {
    if (
      pathLocationState.includes('/admin/game/details/') ||
      pathLocationState.includes('/admin/user/profile/')
    ) {
      dispatch(updatePathLocation({ pathLocationValue: '/admin/home' }));
    } else {
      dispatch(updateHomeCalendarCollapsed(false));

      dispatch(resetAllScrollPositions());
      dispatch(updateAllGameUserDisplayedStatus(false));
      dispatch(updateCanChangeTabs(true));

      if (selectedStatus === GamesTabs.NONE) {
        dispatch(
          updateActiveTab(
            todaysGames?.length > 0 ? GamesTabs.TODAY : GamesTabs.UPCOMING
          )
        );
      }
    }

    const resizeObserverCallback = (resizeEntry) => {
      const calendarContainerHeight = resizeEntry[0].target.clientHeight;
      setCalendarContainerResizeHeight(calendarContainerHeight);
    };
    const resizeObserver = new ResizeObserver(resizeObserverCallback);
    resizeObserver.observe(calenderSectionRef.current);

    bindResizeEvents();
    window.addEventListener('resizestart', () => setIsResizeStarted(true));

    const calendarContainerResizeEventObserver = onresize(calenderSectionRef.current, debounce(() => setIsResizeStarted(false), 100));

    return () => {
      unbindResizeEvents();
      resizeObserver.disconnect();
      calendarContainerResizeEventObserver.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollPositions = useSelector(selectHomeGameListScrollPositions);
  useEffect(() => {
    log.debug(
      `scroll useEffect: ${selectedStatus}`,
      scrollPositions,
      pageListItemsRef.current
    );
    const timeoutId = setTimeout(() => {
      if (selectedStatus === GamesTabs.TODAY) {
        pageListItemsRef.current.scrollTo({
          top: scrollPositions.todaysGamesScrollValue,
          behavior: 'smooth',
        });
      } else if (selectedStatus === GamesTabs.UPCOMING) {
        pageListItemsRef.current.scrollTo({
          top: scrollPositions.upcomingGamesScrollValue,
          behavior: 'smooth',
        });
      } else if (selectedStatus === GamesTabs.PAST) {
        pageListItemsRef.current.scrollTo({
          top: scrollPositions.pastGamesScrollvalue,
          behavior: 'smooth',
        });
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [selectedStatus]);

  useEffect(() => {
    const MOBILE_MEDIA = `only screen and (min-device-width: 1024px) and (max-device-width: 1366px)`;
    const isMobile = matchMedia(MOBILE_MEDIA).matches;

    const EXPANDED_GAME_CONTAINER_WIDTH = '80%';
    const COLLAPSED_CALENDAR_CONTAINER_WIDTH = '18.7%';

    const COLLAPSED_GAME_CONTAINER_WIDTH = isMobile ? '55%' : '65%';
    const EXPANDED_CALENDAR_CONTAINER_WIDTH = isMobile ? '43.7%' : '33.8%';

    setIsMobile(isMobile);
    setChevron(isCollapsed ? LeftBlueChevron : RightBlueChevron);
    setGameContainerWidth(
      isCollapsed
        ? COLLAPSED_GAME_CONTAINER_WIDTH
        : EXPANDED_GAME_CONTAINER_WIDTH
    );
    setCalendarContainerWidth(
      isCollapsed
        ? EXPANDED_CALENDAR_CONTAINER_WIDTH
        : COLLAPSED_CALENDAR_CONTAINER_WIDTH
    );
  }, [isCollapsed]);

  const onChangeChevron = (isCollapsed) => {
    log.debug(
      `[onChangeChevron] Changing from ${isCollapsed} to ${!isCollapsed}`
    );
    dispatch(updateHomeCalendarCollapsed(!isCollapsed));
  };

  return (
    <Row style={homeContainerStyles}>
      <Col
        xl={12}
        xs={12}
        md={12}
        className="p-0"
        style={homeContentContainerStyles}
      >
        <div style={{ ...gameContainerStyles, width: gameContainerWidth }}>
          <img
            src={chevron}
            width="25px"
            height="25px"
            style={{ position: 'absolute', top: '45%', right: -10, zIndex: 2 }}
            onClick={() => onChangeChevron(isCollapsed)}
            alt=""
          />
          <TopRowGameContent
            infoRef={infoRef}
            dispatch={dispatch}
            // setIsUserGameRolesActivated={setIsUserGameRolesActivated}
            // allGameItems={allGameItems}
            // homeShowGameUsersStatus={homeShowGameUsersStatus}
          />
          <TabRowContainer
            todayGameCount={todaysGames?.length}
            upcomingGamesCount={upcomingGames?.length}
            passGamesCount={pastGames?.length}
            scrollPositions={homeGamePageListScrollPos}
            pageListItemsRef={pageListItemsRef}
            isScrolling={isScrolling}
            todaysGames={todaysGames}
            upcomingGames={upcomingGames}
            pastGames={pastGames}
            setCalendarGameItems={setSelectedGameItems}
            dispatch={dispatch}
          />
          <GamesContainer
            gameItems={gameItems}
            allTodaysGames={todaysGamesData}
            allUpcomingGames={upcomingGamesData}
            allPastGames={pastGamesData}
            setGameItems={setGameItems}
            todaysGames={todaysGames}
            setTodaysGames={setTodaysGames}
            upcomingGames={upcomingGames}
            setUpcomingGames={setUpcomingGames}
            pastGames={pastGames}
            setPastGames={setPastGames}
            dispatch={dispatch}
            selectedGameId={selectedGameId}
            scrollPositions={homeGamePageListScrollPos}
            pageListItemsRef={pageListItemsRef}
            homeShowGameUsersStatus={homeShowGameUsersStatus}
            // homeGameSearchState={homeGameSearchState}
            // gameItemsStore={homeGameItemsState}
            isScrolling={isScrolling}
            setIsScrolling={setIsScrolling}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            fetchAll={fetchAll}
            cancelFetchAll={cancelFetchAll}
          />
        </div>
        <div
          style={{ ...calendarContainerStyles, width: calendarContainerWidth }}
          ref={calenderSectionRef}
        >
          <CalendarContainer
            isCalendarCollapsed={!isCollapsed}
            selectedGameType={selectedStatus}
            allGameItems={allGamesData}
            gameItems={selectedGameItems}
            gameItemIdentifierHandler={(gameId) => setSelectedGameId(gameId)}
            calenderHeight={calendarContainerResizeHeight}
            isMobile={isDeviceMobile}
            isLoading={isLoading}
            isResizeStarted={isResizeStarted}
          />
        </div>
      </Col>
      <Tooltip
        tooltipId="show-information-tip"
        tooltipClass="home-tooltip"
        tooltipTextColor="#808080"
        tooltipBorderColor="#979797"
        tooltipBackgroundColor={colors.WHITE[100]}
        tooltipContent={<TooltipContent />}
        tooltipDelayShow={500}
        tooltipDelayHide={2000}
        hasCapture={true}
        afterShowHandler={() =>
          setTimeout(() => infoRef.current?.click(), 1000)
        }
      />
    </Row>
  );
};

export default HomeContent;
