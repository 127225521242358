import { createSlice } from '@reduxjs/toolkit';

export const homeShowGameUsersSlice = createSlice({
  name: 'homeShowGameUsers',
  initialState: {
    isAllGameUsersShownValue: false,
  },
  reducers: {
    updateAllGameUserDisplayedStatus: (state, action) => {
      state.isAllGameUsersShownValue = action.payload;
    },
    toggleAllGameUserDisplayedStatus: (state, action) => {
      state.isAllGameUsersShownValue = !state.isAllGameUsersShownValue;
    },
  },
});

export const {
  updateAllGameUserDisplayedStatus,
  toggleAllGameUserDisplayedStatus,
} = homeShowGameUsersSlice.actions;

export const selectHomeShowGameUsers = (state) =>
  state.homeShowGameUsers.isAllGameUsersShownValue;

export default homeShowGameUsersSlice.reducer;
