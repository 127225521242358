import { DisplayType } from './displayType';
import GameEvents from './gameEvents';
import InputEvents from './inputEvents';

/**
 * Schema for the Assist Sub-Routine:
 * Assist -> Which Player?
 */
const SUB_ROUTINE_ASSIST = {
  /** Assist or End Play? */
  type: DisplayType.ASSIST,
  on: {
    [InputEvents.ASSIST]: {
      /** Which Player? */
      type: DisplayType.PLAYER_OFFENSE_SECONDARY,
      on: {
        [InputEvents.PLAYER]: {
          events: [GameEvents.ASSIST],
        },
      },
    },
    /** Play Ended */
    [InputEvents.END_PLAY]: {},
  },
};

/**
 * Schema for the Rebound Sub-Routine:
 * Off / Def -> Which Player?
 */
const SUB_ROUTINE_REBOUND = {
  /** Offensive, Defensive, or End Play? */
  type: DisplayType.REBOUND,
  on: {
    [InputEvents.REBOUND_OFFENSIVE]: {
      /** Which Player? */
      type: DisplayType.PLAYER_OFFENSE_SECONDARY,
      on: {
        [InputEvents.PLAYER]: {
          events: [GameEvents.REBOUND_OFFENSIVE],
        },
      },
    },
    [InputEvents.REBOUND_DEFENSIVE]: {
      /** Which Player? */
      type: DisplayType.PLAYER_DEFENSE_SECONDARY,
      on: {
        [InputEvents.PLAYER]: {
          events: [GameEvents.REBOUND_DEFENSIVE],
        },
      },
    },
    /** Play Ended */
    [InputEvents.END_PLAY]: {},
  },
};

const ROUTINE_SHOT_2PT_MADE = {
  /** What Location? */
  type: DisplayType.LOCATION_2PT,
  on: {
    [InputEvents.LOCATION]: {
      /** Which Player? */
      type: DisplayType.PLAYER_OFFENSE,
      on: {
        /** Assist*/
        [InputEvents.PLAYER]: {
          events: [GameEvents.TWO_POINT_MADE],
          ...SUB_ROUTINE_ASSIST,
        },
      },
    },
  },
};

const ROUTINE_SHOT_3PT_MADE = {
  /** What Location? */
  type: DisplayType.LOCATION_3PT,
  on: {
    [InputEvents.LOCATION]: {
      /** Which Player? */
      type: DisplayType.PLAYER_OFFENSE,
      on: {
        /** Assist*/
        [InputEvents.PLAYER]: {
          events: [GameEvents.THREE_POINT_MADE],
          ...SUB_ROUTINE_ASSIST,
        },
      },
    },
  },
};

const ROUTINE_SHOT_2PT_MISS = {
  /** What Location? */
  type: DisplayType.LOCATION_2PT,
  on: {
    [InputEvents.LOCATION]: {
      /** Which Player */
      type: DisplayType.PLAYER_OFFENSE,
      on: {
        /** Off/Def Rebound */
        [InputEvents.PLAYER]: {
          events: [GameEvents.TWO_POINT_MISS],
          ...SUB_ROUTINE_REBOUND,
        },
      },
    },
  },
};

const ROUTINE_SHOT_3PT_MISS = {
  /** What Location? */
  type: DisplayType.LOCATION_3PT,
  on: {
    [InputEvents.LOCATION]: {
      /** Which Player */
      type: DisplayType.PLAYER_OFFENSE,
      on: {
        /** Off/Def Rebound */
        [InputEvents.PLAYER]: {
          events: [GameEvents.THREE_POINT_MISS],
          ...SUB_ROUTINE_REBOUND,
        },
      },
    },
  },
};

const maxFreeThrowAttempts = 3;
function SUB_ROUTINE_FT_FOLLOW_UP(attempt) {
  return {
    type: DisplayType.FREE_THROW_FOLLOW_UP,
    on: {
      /** End Play */
      [InputEvents.END_PLAY]: {},
      /** FT Made */
      [InputEvents.SHOT_FT_MADE]: {
        events: [GameEvents.FREE_THROW_MADE],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_FOLLOW_UP(attempt + 1)),
      },
      /** FT Missed */
      [InputEvents.SHOT_FT_MISS]: {
        events: [GameEvents.FREE_THROW_MISS],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_MISS_FOLLOW_UP(attempt + 1)),
        ...(attempt >= maxFreeThrowAttempts && SUB_ROUTINE_REBOUND),
      },
      /** FT Violation */
      [InputEvents.VIOLATION_FREE_THROW]: {
        events: [GameEvents.VIOLATION_FREE_THROW],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_FOLLOW_UP(attempt + 1)),
      },
    },
  };
}

function SUB_ROUTINE_FT_MISS_FOLLOW_UP(attempt) {
  return {
    type: DisplayType.FREE_THROW_MISS_FOLLOW_UP,
    on: {
      /** End Play */
      [InputEvents.END_PLAY]: {},
      /** FT Made */
      [InputEvents.SHOT_FT_MADE]: {
        events: [GameEvents.FREE_THROW_MADE],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_FOLLOW_UP(attempt + 1)),
      },
      /** FT Missed */
      [InputEvents.SHOT_FT_MISS]: {
        events: [GameEvents.FREE_THROW_MISS],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_MISS_FOLLOW_UP(attempt + 1)),
        ...(attempt >= maxFreeThrowAttempts && SUB_ROUTINE_REBOUND),
      },
      /** FT Violation */
      [InputEvents.VIOLATION_FREE_THROW]: {
        events: [GameEvents.VIOLATION_FREE_THROW],
        /* Additional Freee Throw Dialogue */
        ...(attempt < maxFreeThrowAttempts &&
          SUB_ROUTINE_FT_FOLLOW_UP(attempt + 1)),
      },
      /** OFF Rebound */
      [InputEvents.REBOUND_OFFENSIVE]: {
        /** Which Player? */
        type: DisplayType.PLAYER_OFFENSE_SECONDARY,
        on: {
          [InputEvents.PLAYER]: {
            events: [GameEvents.REBOUND_OFFENSIVE],
          },
        },
      },
      /** DEF Rebound */
      [InputEvents.REBOUND_DEFENSIVE]: {
        /** Which Player? */
        type: DisplayType.PLAYER_DEFENSE_SECONDARY,
        on: {
          [InputEvents.PLAYER]: {
            events: [GameEvents.REBOUND_DEFENSIVE],
          },
        },
      },
    },
  };
}

const ROUTINE_SHOT_FT = {
  /** Which Player */
  type: DisplayType.PLAYER_OFFENSE,
  on: {
    [InputEvents.PLAYER]: {
      events: [GameEvents.FREE_THROW_ATTEMPT],
      /* Additional Free Throw Dialogue */
      ...SUB_ROUTINE_FT_FOLLOW_UP(0),
    },
  },
};


const ROUTINE_PERSONAL_FOUL = {
  /** Which Player */
  type: DisplayType.PLAYER,
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.PERSONAL_FOUL],
    },
  },
};

const ROUTINE_TECHNICAL_FOUL = {
  /** Which Player */
  type: DisplayType.PLAYER,
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.TECHNICAL_FOUL],
    },
  },
};

const ROUTINE_VIOLATION = {
  /** Which Player */
  type: DisplayType.PLAYER_OFFENSE,
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      type: DisplayType.VIOLATION,
      on: {
        [InputEvents.VIOLATION_TRAVELING]: {
          events: [GameEvents.VIOLATION_TRAVELING],
        },
        [InputEvents.VIOLATION_CARRYING]: {
          events: [GameEvents.VIOLATION_CARRYING],
        },
        [InputEvents.VIOLATION_DOUBLE_DRIBBLE]: {
          events: [GameEvents.VIOLATION_DOUBLE_DRIBBLE],
        },
        [InputEvents.VIOLATION_BACKCOURT]: {
          events: [GameEvents.VIOLATION_BACKCOURT],
        },
        [InputEvents.VIOLATION_OFF_GOALTENDING]: {
          events: [GameEvents.VIOLATION_OFF_GOALTENDING],
        },
        [InputEvents.VIOLATION_3_SECONDS]: {
          events: [GameEvents.VIOLATION_3_SECONDS],
        },
        [InputEvents.VIOLATION_5_SECONDS]: {
          events: [GameEvents.VIOLATION_5_SECONDS],
        },
        [InputEvents.VIOLATION_10_SECONDS]: {
          events: [GameEvents.VIOLATION_10_SECONDS],
        },
      },
    },
  },
};

const ROUTINE_BLOCK = {
  /** Which Player */
  type: DisplayType.PLAYER_DEFENSE,
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.BLOCK],
      ...SUB_ROUTINE_REBOUND,
    },
  },
};

const ROUTINE_DEFLECT = {
  /** Which Player */
  type: DisplayType.PLAYER_DEFENSE,
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.DEFLECT],
    },
  },
};

const ROUTINE_STEAL = {
  /** Which Player */
  type: DisplayType.PLAYER_DEFENSE,
  text: 'Who stole the ball?',
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.STEAL],
      type: DisplayType.TURNOVER,
      on: {
        [InputEvents.TURNOVER]: {
          /** Which Player? */
          type: DisplayType.PLAYER_OFFENSE_SECONDARY,
          text: 'Who turned the ball over?',
          on: {
            [InputEvents.PLAYER]: {
              events: [GameEvents.TURNOVER],
            },
          },
        },
        /** Play Ended */
        [InputEvents.END_PLAY]: {},
      },
    },
  },
};

const ROUTINE_TURNOVER = {
  /** Which Player */
  type: DisplayType.PLAYER_OFFENSE,
  text: 'Who turned the ball over?',
  on: {
    /** Off/Def Rebound */
    [InputEvents.PLAYER]: {
      events: [GameEvents.TURNOVER],
    },
  },
};

export const schema = {
  routines: {
    /** ROOT NODE */
    type: DisplayType.ACTION,
    on: {
      /** Shots Made */
      [InputEvents.SHOT_2PT_MADE]: ROUTINE_SHOT_2PT_MADE,
      [InputEvents.SHOT_3PT_MADE]: ROUTINE_SHOT_3PT_MADE,
      /** Shots Miss */
      [InputEvents.SHOT_2PT_MISS]: ROUTINE_SHOT_2PT_MISS,
      [InputEvents.SHOT_3PT_MISS]: ROUTINE_SHOT_3PT_MISS,
      /** Fouls */
      [InputEvents.PERSONAL_FOUL]: ROUTINE_PERSONAL_FOUL,
      [InputEvents.TECHNICAL_FOUL]: ROUTINE_TECHNICAL_FOUL,
      /** Actions */
      [InputEvents.VIOLATION]: ROUTINE_VIOLATION,
      [InputEvents.FT_ROUTINE]: ROUTINE_SHOT_FT,
      [InputEvents.BLOCK]: ROUTINE_BLOCK,
      [InputEvents.STEAL]: ROUTINE_STEAL,
      [InputEvents.DEFLECT]: ROUTINE_DEFLECT,
      [InputEvents.TURNOVER]: ROUTINE_TURNOVER,

      // [InputEvents.EDIT]: {
      //   type: DisplayType.EDIT,
      //   on: {
      //     /** Shots Made */
      //     [InputEvents.SHOT_2PT_MADE]: ROUTINE_EDIT_SHOT_2PT_MADE,
      //     // [InputEvents.SHOT_3PT_MADE]: ROUTINE_SHOT_3PT_MADE,
      //     /** Shots Miss */
      //     // [InputEvents.SHOT_2PT_MISS]: ROUTINE_SHOT_2PT_MISS,
      //     // [InputEvents.SHOT_3PT_MISS]: ROUTINE_SHOT_3PT_MISS,
          
      //   },
      // }
    },
  },
};
