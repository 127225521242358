import React from 'react';
import styled from 'styled-components';
import LaunchHeaderRow from '../Navigation/LaunchHeaderRow';
import { colors } from '../../styles/colors';

const Grid = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: ${colors.GRAY[250]};
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'body';
`;

const GridHeader = styled.div`
  grid-area: header;
`;

// Use overflow-x to enable horizontal scrolling on the tables
const GridBody = styled.div`
  grid-area: body;
  overflow-x: scroll;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    // hide the scrollbar but keep the functionality
    display: none;
  }
`;

const LaunchLayout = ({
  title,
  subTitle,
  rightButtonLabel,
  rightButtonHandler,
  rightButtonEnabled,
  rightButtonHidden,
  leftButtonLabel,
  leftButtonHandler,
  leftButtonEnabled,
  leftButtonHidden,
  children,
}) => {
  return (
    <Grid>
      <GridHeader>
        <LaunchHeaderRow
          title={title}
          subTitle={subTitle}
          rightButtonLabel={rightButtonLabel}
          rightButtonHandler={rightButtonHandler}
          rightButtonEnabled={rightButtonEnabled}
          rightButtonHidden={rightButtonHidden}
          leftButtonLabel={leftButtonLabel}
          leftButtonHandler={leftButtonHandler}
          leftButtonEnabled={leftButtonEnabled}
          leftButtonHidden={leftButtonHidden}
        />
      </GridHeader>
      <GridBody>{children}</GridBody>
    </Grid>
  );
};

export default LaunchLayout;
