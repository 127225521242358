import { colors } from './colors';
import {
  colors as statsCollectorColors,
  gradients as statsCollectorGradients,
} from './colorsStatsCollector';

export const buttonThemes = {
  BLUE: {
    backgroundColor: colors.BLUE[100],
    hoverColor: colors.PRIMARY[300],
    textColor: colors.WHITE[100],
    border: `1px solid ${colors.BLUE[100]}`,
    borderHover: `1px solid ${colors.PRIMARY[300]}`,
  },
  WHITE: {
    backgroundColor: colors.WHITE[100],
    hoverColor: colors.GRAY[100],
    textColor: colors.GRAY[300],
    border: `1px solid ${colors.GRAY[200]}`,
  },
  GRAY: {
    backgroundColor: colors.GRAY[400],
    hoverColor: colors.GRAY[300],
    textColor: colors.WHITE[100],
    border: `1px solid ${colors.GRAY[100]}`,
    disabledColor: colors.GRAY[600],
  },
  BLACK: {
    backgroundColor: statsCollectorColors.BLACK[400],
    hoverColor: colors.GRAY[300],
    textColor: colors.WHITE[100],
    border: `1px solid ${colors.GRAY[100]}`,
    disabledColor: colors.GRAY[600],
  },
  GREEN: {
    backgroundColor: statsCollectorGradients.GREEN,
    border: 'none',
  },
};

/**
 * Placeholder for light and dark themes
 *
 * Would like to have defined themes with semantic variables,
 * but there are too many color variations in the design mockups to pull this off.
 */
