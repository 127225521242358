import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { colors, gradients } from '../../../styles/colorsStatsCollector';
import logger from '../../../logger';
import InputEvents from '../controller/inputEvents';
import {
  buttonEvent,
  selectFreeThrowMadeCount,
  selectFreeThrowMissCount,
  selectFreeThrowViolationCount,
} from '../controller/scoreKeeperEventSlice';
import { ActionButton, ActionButtonHeader } from './EventActionButtons';
import { ActionTypes } from '../ActionTypes.type';
import { useDispatch, useSelector } from 'react-redux';
const log = logger('FreeThrowFollowUpScreen', 'info');

const FreeThrowFollowUpScreen = ({
  endPlayClickHandler,
  shouldShowReboundOptions,
  shouldShowEndPlayOptions,
  shouldShowFreeThrowOptions,
}) => {
  const dispatch = useDispatch();
  const freeThrowMade = useSelector(selectFreeThrowMadeCount);
  const freeThrowMiss = useSelector(selectFreeThrowMissCount);
  const freeThrowViolation = useSelector(selectFreeThrowViolationCount);
  log.debug('shouldShowReboundOptions', shouldShowReboundOptions);

  const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 0px 15px;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: 350px;
    background: ${({ theme: { lightMode } }) =>
      lightMode ? colors.GRAY_TRANSPARENT[100] : colors.BLACK[600]};
    border: 1px solid
      ${({ theme }) =>
        theme.lightMode
          ? colors.GRAY_TRANSPARENT[700]
          : colors.GRAY_TRANSPARENT[900]};
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 20px 10px 5px 15px;
  `;

  const Score = styled.div`
    align-items: center;
    color: ${({ theme: { lightMode } }) =>
      lightMode ? colors.BLACK[200] : colors.WHITE[100]};
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    padding: 5px 0px;
  `;

  return (
    <Container>
      <Grid>
        <ActionButtonHeader actionType={ActionTypes.Made} />
        <ActionButtonHeader actionType={ActionTypes.Missed} />
        <ActionButtonHeader
          actionType={{ label: 'Violation', color: ActionTypes.Fouls.color }}
        />
        <Score>{freeThrowMade}</Score>
        <Score>{freeThrowMiss}</Score>
        <Score>{freeThrowViolation}</Score>
      </Grid>
      {/* Free Throw Made/Miss/Violation Options */}
      <div class="d-flex align-items-center h-100 pt-2 pb-4">
        <div class="d-block">
          <div class="m-auto" style={{ width: 'fit-content' }}>
            {/* End Play */}
            {shouldShowEndPlayOptions && (
              <StyledButton
                variant="outlined"
                style={{
                  font: 'normal normal bold 15px/20px Open Sans',
                  letterSpacing: '0px',
                  textTransform: 'uppercase',
                }}
                onClick={endPlayClickHandler}
              >
                End Play
              </StyledButton>
            )}
            {/* Rebound Options */}
            {shouldShowReboundOptions && (
              <ButtonContainer buttonCount={2} style={{ marginTop: '30px' }}>
                <RoundButton
                  onClick={() =>
                    dispatch(buttonEvent(InputEvents.REBOUND_OFFENSIVE))
                  }
                  key={`off-rebound`}
                >
                  <div
                    style={{
                      font: 'normal normal bold 15px/20px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {'OFF'}
                  </div>
                  <div
                    style={{
                      font: 'normal normal bold 10px/14px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {'REBOUND'}
                  </div>
                </RoundButton>
                <RoundButton
                  onClick={() =>
                    dispatch(buttonEvent(InputEvents.REBOUND_DEFENSIVE))
                  }
                  key={`$def-rebound`}
                >
                  <div
                    style={{
                      font: 'normal normal bold 15px/20px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {'DEF'}
                  </div>
                  <div
                    style={{
                      font: 'normal normal bold 10px/14px Open Sans',
                      letterSpacing: '0px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {'REBOUND'}
                  </div>
                </RoundButton>
              </ButtonContainer>
            )}
          </div>
          {(shouldShowEndPlayOptions || shouldShowReboundOptions) &&
            shouldShowFreeThrowOptions && <OrSeparator />}
          {shouldShowFreeThrowOptions && (
            <ButtonContainer buttonCount={3}>
              <ActionButton
                text={'FT MADE'}
                actionType={ActionTypes.Made}
                onClick={() => dispatch(buttonEvent(InputEvents.SHOT_FT_MADE))}
              />
              <ActionButton
                text={'FT MISS'}
                actionType={ActionTypes.Missed}
                onClick={() => dispatch(buttonEvent(InputEvents.SHOT_FT_MISS))}
              />
              <ActionButton
                text={'VIOLATION'}
                actionType={ActionTypes.Fouls}
                onClick={() =>
                  dispatch(buttonEvent(InputEvents.VIOLATION_FREE_THROW))
                }
              />
            </ButtonContainer>
          )}
        </div>
      </div>
    </Container>
  );
};

FreeThrowFollowUpScreen.propTypes = {
  endPlayClickHandler: PropTypes.func.isRequired,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      primaryLabel: PropTypes.string,
      secondaryLabel: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: 25px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.buttonCount}, minmax(0px, auto));
  justify-items: center;
  row-gap: 1em;

  > button {
    margin: 0 0.5em;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    border-radius: 24px;
    text-transform: none;
    padding: 12px 50px;
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[100] : gradients.BLACK};
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.GRAY[900]};
    border: 1px solid ${colors.BLUE[100]};

    font-family: Open Sans, sans-serif;
    font-weight: 500;
    font-size: 19px;

    &:hover,
    &:focus,
    &:active {
      background: ${colors.BLUE[100]};
      color: ${colors.WHITE[100]};
    }
  }
`;

const OrSeparator = styled((props) => (
  <div {...props}>
    <div class="d-flex align-items-center justify-content-between w-100">
      <span className="divider"></span>
      <span>OR</span>
      <span className="divider"></span>
    </div>
  </div>
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  width: 100%;

  span {
    text-align: center;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2400] : colors.GRAY[950]};
    margin: 4px 0;
  }
  .divider {
    height: 0.5px;
    width: 100%;
    background: ${colors.GRAY[900]};
    margin: 0px 10px;
  }
`;

const RoundButton = styled(Button)`
  &&& {
    width: 78px;
    height: 78px;
    border-radius: 45px;
    background: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[100] : gradients.BLACK};
    color: ${({ theme }) =>
      theme.lightMode ? colors.GRAY[2300] : colors.GRAY[900]};
    box-shadow: 4px 7px 16px ${colors.GRAY_TRANSPARENT[400]};
    border: 1px solid ${colors.BLUE[100]};

    .MuiButton-label {
      flex-direction: column;
    }

    &:hover,
    &:focus,
    &:active {
      background: ${colors.BLUE[100]};
      color: ${colors.WHITE[100]};
    }
  }
`;

export default FreeThrowFollowUpScreen;
