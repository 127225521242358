import moment from 'moment';

/**
 * Formatter for condensed season label (YYYY SEASON, YY-YY SEASON)
 * @param {*} season 
 * @returns label (Ex. '2022 REG SEASON, 22-23 POST SEASON')
 */
export const seasonFormatDisplayCondensed = ({
  seasonType,
  startDate,
  endDate,
}) => {
  const simplifiedSeasonType = seasonType
    .toUpperCase()
    .replace('regular'.toUpperCase(), 'reg'.toUpperCase());
  const seasonFormat =
    moment(startDate).format('YY') === moment(endDate).format('YY')
      ? `${moment(startDate).format('YYYY')} ${simplifiedSeasonType}`
      : `${moment(startDate).format('YY')}-${moment(endDate).format(
          'YY'
        )} ${simplifiedSeasonType}`;

  return seasonFormat;
};

/**
 * Formatter for normal/long season Label (YYYY SeasonType, YYYY-YY SeasonType)
 * @param {*} season 
 * @returns label (Ex. '2022 Regular Season, 2022-23 Post Season')
 */
export const seasonFormatDisplay = ({ seasonType, startDate, endDate }) => {
  const seasonFormat =
    moment(startDate).format('YY') === moment(endDate).format('YY')
      ? `${moment(startDate).format('YYYY')} ${seasonType}`
      : `${moment(startDate).format('YYYY')}-${moment(endDate).format(
          'YY'
        )} ${seasonType}`;
  return seasonFormat;
};

/**
 * Formatter for season-league label (YYYY-YY SeasonType - League (Abbr))
 * @param {*} season 
 * @returns label (Ex. '2022-23 Post Season - Upcoming League (UPL)')
 */
export const getLeagueSeasonLabelFormat = ({
  seasonType,
  startDate,
  endDate,
  league,
}) => {
  const seasonFormat = seasonFormatDisplay({
    seasonType,
    startDate,
    endDate,
  });
  const leagueFormat = `${league.name} (${league.abbreviation})`;
  return `${seasonFormat} - ${leagueFormat}`;
};
