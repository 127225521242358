import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageContent from './PageContent';
import TeamContentItem from './TeamContentItem';
import {
  SearchMenuDiv,
  SearchMenuMainCriteria,
  SearchMenuSubCriteriaDark,
  getHighlightedText,
} from '../../utils/searchUtils';
import InfiniteScrollList from '../InfiniteListScroll';
import { PENDING_CHANGE_LEAGUE_ID } from "../../utils/constantsUtils";

const TeamContent = ({
  searchId,
  data,
  placeholder,
  handleEditModalOpenHook,
  openAssignUserRoleModal,
  fetchNextPage,
  hasNextPage,
  isFetching,
  onSearch,
}) => {
  const history = useHistory();
  const [displayedTeams, setDisplayedTeams] = useState([]);
  const [teamSearch, setTeamSearch] = useState('');
  const searchFilter = (option, search) =>
    option.name.toLowerCase().includes(search.toLowerCase()) ||
    option.league?.name.toLowerCase().includes(search.toLowerCase()) ||
    option.league?.abbreviation.toLowerCase().includes(search.toLowerCase()) ||
    option.city.toLowerCase().includes(search.toLowerCase()) ||
    option.state.toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    /**
     * Update the displayed seasons based on the search input
     */
    if (teamSearch) {
      /** Check if the input is an object or a search string */
      if (typeof teamSearch === 'object') {
        /** Object Selection */
        setDisplayedTeams([teamSearch]);
      } else if (teamSearch.length > 0) {
        /** Search String */
        setDisplayedTeams(
          data?.filter((option) => searchFilter(option, teamSearch))
        );
      }
    } else {
      /** Display ALL Seasons */
      setDisplayedTeams(data);
    }
  }, [teamSearch, data]);

  useEffect(() => {
    if (!teamSearch)
      onSearch('')
  }, [teamSearch, onSearch])

  const getDisplayedData = displayedTeams => {
    return (
      <>
        {displayedTeams?.map(
          ({ 
            id, 
            name, 
            state, 
            city, 
            league, 
            leagueId, 
            image, 
            active 
          }) => {
            return (
              <TeamContentItem
                key={id}
                id={id}
                name={name}
                state={state}
                city={city}
                isPendingUpdate={leagueId === PENDING_CHANGE_LEAGUE_ID}
                league={league?.name}
                leagueId={leagueId}
                leagueAbbr={league?.abbreviation}
                image={image}
                active={active}
                editTeamCtrl={handleEditModalOpenHook}
                openAssignUserRoleModal={openAssignUserRoleModal}
                history={history}
              />
            );
          }
        )}
      </>
    );
  };
  
  return (
    <PageContent
      searchId={searchId}
      data={data}
      dataProperty={(option) =>
        `${option.name}${
          option.league
            ? ` - ${option.league.name} (${option.league.abbreviation})`
            : ''
        }`
      }
      placeholder={placeholder}
      filterBy={(option, props) => searchFilter(option, props.text)}
      onChange={setTeamSearch}
      isFetching={isFetching}
      onSearch={onSearch}
      renderMenuItemChildren={(option, props) => {
        return (
          <SearchMenuDiv>
            <SearchMenuMainCriteria>
              {getHighlightedText(option.name, props.text)}
            </SearchMenuMainCriteria>
            {option.league && (
              <SearchMenuSubCriteriaDark>
                {option.league.name &&
                  getHighlightedText(option.league.name, props.text)}{' '}
                (
                {option.league.abbreviation &&
                  getHighlightedText(option.league.abbreviation, props.text)}
                )
              </SearchMenuSubCriteriaDark>
            )}
            {/* <SearchMenuSubCriteria>
              {getHighlightedText(option.city, props.text)},
              {getHighlightedText(option.state, props.text)}
            </SearchMenuSubCriteria> */}
          </SearchMenuDiv>
        );
      }}
    >
      <InfiniteScrollList
        displayedData={displayedTeams}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        displayedDataFilter={() => getDisplayedData(displayedTeams)}
      />
    </PageContent>
  );
};

export default TeamContent;
