import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { colors, textEllipsisStyle, StyledFlexRow } from '../../styles';
import ButtonElement from '../Buttons/ButtonElement';
import { useHistory } from 'react-router-dom';

const ContainerStyled = styled.div`
  &&& {
    padding-bottom: 0px;
  }
`;

const HeaderStyle = styled.div`
  &&& {
    font-family: Inter;
    font-size: 26px;
    height: 40px;
    color: #434343;
    font-weight: bold;
    padding-top: 0px;
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
  }
`;

const NavigationStyle = styled.div`
  &&& {
    text-align: left;
    font: normal normal bold 16px/24px Inter;
    color: #26a9e1;
    cursor: pointer;
  }
`;

const LineStyled = styled.hr`
  &&& {
    background-color: ${colors.GRAY[300]};
    border-color: ${colors.GRAY[300]};
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const HeaderRow = ({
  title = 'Header Title',
  buttonSize = 'large',
  buttonLabel,
  rowButtonLabel,
  rowButtonWidth = '130px',
  buttonHandler = () => {},
  buttonDisabled = false, 
  navigationLinkBack,
}) => {
  const history = useHistory();
  // const onBack = (navigationLinkBack) => {
  //   history.push(navigationLinkBack);
  // };

  return (
    <ContainerStyled>
      <StyledFlexRow style={{ alignItems: 'center' }}>
        {navigationLinkBack && (
          <Col style={{ flex: '1 1' }}>
            <NavigationStyle
              onClick={() => {
                // onBack(navigationLinkBack);
                history.goBack();
              }}
            >
              {'< Back'}
            </NavigationStyle>
          </Col>
        )}
        <Col style={{ flex: '4 0' }}>
          <HeaderStyle style={textEllipsisStyle} centered={navigationLinkBack}>
            {title}
          </HeaderStyle>
        </Col>
        <Col style={{ flex: '1 1', textAlign: 'right' }}>
          {rowButtonLabel && (
            <ButtonElement
              style={{
                height: '40px',
                width: rowButtonWidth,
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: 0,
              }}
              size={buttonSize}
              label={rowButtonLabel}
              onClick={buttonHandler}
            />
          )}
        </Col>

        {buttonLabel && (
          <ButtonElement
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '60px',
              zIndex: 99,
              borderRadius: '50%',
              height: '60px',
              width: '60px',
              fontSize: '28px',
            }}
            size={buttonSize}
            label={'+'}
            onClick={buttonHandler}
            disabled={buttonDisabled}
          />
        )}
      </StyledFlexRow>
      <LineStyled />
    </ContainerStyled>
  );
};

export default HeaderRow;
