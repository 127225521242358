import { DataStore } from '@aws-amplify/datastore';
import { API, graphqlOperation } from 'aws-amplify';
import { Event } from '../models';
import {
  listEvents as listEventsQuery,
  getEvent as getEventQuery,
  getEventByGameIdUpdatedAt,
  getEventByGameIdGamePeriod as getEventByGameIdGamePeriodQuery,
  getEventByGameIdGameOvertime as getEventByGameIdGameOvertimeQuery,
  getEventByGameIdCreatedAt as getEventByGameIdCreatedAtQuery,
} from '../graphql/queries';
import {
  customListGamePeriodEvents as customListGamePeriodEventsQuery,
  customListOvertimePeriodEvents as customListOvertimePeriodEventsQuery,
  customListEventTypesForGamePeriods as customListEventTypesForGamePeriodsQuery,
  customListEventTypesForOvertimePeriods as customListEventTypesForOvertimePeriodsQuery,
} from '../graphql_custom/eventServiceQueries';
const log = require('../logger')('api', 'debug');

export const getEvent = async (id) => {
  try {
    const eventData = await API.graphql(
      graphqlOperation(getEventQuery, { id })
    );
    return eventData.data.getEvent;
  } catch (err) {
    log.error('error fetching event...', err);
  }
};

export const listEvents = async (variables = null) => {
  try {
    const eventsList = await API.graphql(
      graphqlOperation(listEventsQuery, variables)
    );
    return eventsList.data.listEvents;
  } catch (err) {
    log.error('error fetching events list', err);
  }
};

/**
 * @param {*} variables
 *   gameId (required)
 *   sortDirection (optional): 'ASC' or 'DESC'
 *   limit (optional)
 * @returns
 */
export const listEventsByGame = async (variables = null) => {
  try {
    const eventsList = await API.graphql(
      graphqlOperation(getEventByGameIdUpdatedAt, variables)
    );
    return eventsList.data.getEventByGameIdUpdatedAt;
  } catch (err) {
    log.error('error fetching events list', err);
  }
};

export const getListEventsByGameId = async (variables = null) => {
  try {
    const eventsListByGameId = await API.graphql(
      graphqlOperation(getEventByGameIdCreatedAtQuery, variables)
    );
    return eventsListByGameId.data.getEventByGameIdCreatedAt;
  } catch (err) {
    log.error('error fetching events list by game Id', err);
  }  
};

export const getGamePeriodPlayEvents = async (variables = null) => {
  try {
    const playByPlayGamePeriodEventsList = await API.graphql(
      graphqlOperation(getEventByGameIdGamePeriodQuery, variables)
    );
    return playByPlayGamePeriodEventsList.data.getEventByGameIdGamePeriod
  }
  catch(error) {
    log.error('error fetching game period play-by-play events....');
  }
};

export const getGameOvertimePeriodPlayEvents = async (variables = null) => {
  try {
    const playByPlayOvertimePeriodEventsList = await API.graphql(
      graphqlOperation(getEventByGameIdGameOvertimeQuery, variables)
    );
    return playByPlayOvertimePeriodEventsList.data.getEventByGameIdGameOvertime;
  }
  catch(error) {
    log.error('error fetching game overtime period play-by-play events....');
  }
};

export const getGamePlayByPlayEvents = async (periodType, variables = null) => {
  try {
    if(periodType == 'gamePeriodNumber') {
      return await getGamePeriodPlayEvents(variables);
    }
    else if(periodType == 'gameOvertimeNumber') {
      return await getGameOvertimePeriodPlayEvents(variables);
    }
  } catch(err) {
    log.error('error fetching play-by-play events with respect to game period number or overtime number', err);
  }
};

export const customListGamePeriodsEvents = async (variables = null) => {
  try {
    const gamePeriodsEventList = await API.graphql(
      graphqlOperation(customListGamePeriodEventsQuery, variables)
    );
    return gamePeriodsEventList.data.getEventByGameIdGamePeriod;
  } catch (err) {
    log.error('error fetching game periods events list', err);
  }
};

export const customListOvertimePeriodEvents = async (variables = null) => {
  try {
    const gameOvertimePeriodsEventList = await API.graphql(
      graphqlOperation(customListOvertimePeriodEventsQuery, variables)
    );
    return gameOvertimePeriodsEventList.data.getEventByGameIdGameOvertime;
  } catch (err) {
    log.error('error fetching overtime periods events list', err);
  }
};

export const customEventTypesForGamePeriods = async (variables = null) => {
  try {
    const eventTypesForGamePeriods = await API.graphql(
      graphqlOperation(customListEventTypesForGamePeriodsQuery, variables)
    );
    return eventTypesForGamePeriods.data.getEventByGameIdGamePeriod;
  } catch (err) {
    log.error('error fetching event types for game periods events list', err);
  }
};

export const customEventTypesForOvertimePeriods = async (variables = null) => {
  try {
    const eventTypesForOvertimePeriods = await API.graphql(
      graphqlOperation(customListEventTypesForOvertimePeriodsQuery, variables)
    );
    return eventTypesForOvertimePeriods.data.listEvents.items;
  } catch (err) {
    log.error(
      'error fetching event types for overtime periods events list',
      err
    );
  }
};

export const createEvent = async ({
  eventType,
  minutes,
  seconds,
  quarter,
  team,
  game,
  playerId,
  gameLineupPlayer,
  gameLineupPlayerId,
  relatedEventId,
  posessionId,
  playType,
  gameOvertimeNumber,
  shotLocation,
  statcollFirstName,
  statcollLastName,
  statcollEmail,
  statcollSub,
  eventCreatorRole,
}) => {
  try {
    const eventData = await DataStore.save(
      new Event({
        eventType: eventType,
        eventAbsoluteTime: new Date().toISOString(),
        eventCreatorRole: eventCreatorRole,
        gameId: game?.id,
        teamId: team?.id,
        playerId: playerId,
        gameLineupPlayer: gameLineupPlayer,
        gameLineupPlayerId: gameLineupPlayerId,
        gamePeriodNumber:
          !gameOvertimeNumber && quarter ? parseInt(quarter) : undefined,
        gamePeriodMinutes: !gameOvertimeNumber ? parseInt(minutes) : undefined,
        gamePeriodSeconds: !gameOvertimeNumber ? parseInt(seconds) : undefined,
        gameOvertimeNumber: gameOvertimeNumber,
        gameOvertimeMinutes: gameOvertimeNumber && parseInt(minutes),
        gameOvertimeSeconds: gameOvertimeNumber && parseInt(seconds),
        relatedEventId: relatedEventId,
        isEdited: false,
        isDeleted: false,
        isFlaged: false,
        possessionTeam: posessionId,
        playType,
        shotLocation: shotLocation && shotLocation,
        statcollSub: statcollSub,
        statcollEmail: statcollEmail,
        statcollFirstName,
        statcollLastName,
      })
    );
    log.debug('CreateEvent', eventData)
    return eventData;
  } catch (err) {
    log.error('Error creating Event', err);
  }
};

export const updateEvent = async ({
  id,
  eventType,
  minutes,
  seconds,
  quarter,
  teamId,
  gameId,
  playerId,
  gameLineupPlayer,
  gameLineupPlayerId,
  relatedEventId,
  possessionTeam,
  playType,
  gameOvertimeNumber,
  statcollFirstName,
  statcollLastName,
  statcollSub,
  statcollEmail,
  eventCreatorRole,
}) => {
  try {
    const original = await DataStore.query(Event, id);
    const eventData = await DataStore.save(
      Event.copyOf(original, (updated) => {
        updated.eventType = eventType;
        updated.eventAbsoluteTime = new Date().toISOString();
        updated.gameId = gameId;
        updated.teamId = teamId;
        updated.playerId = playerId;
        updated.gameLineupPlayer = gameLineupPlayer;
        updated.gameLineupPlayerId = gameLineupPlayerId;
        updated.relatedEventId = relatedEventId || null;
        updated.gamePeriodNumber =
          !gameOvertimeNumber && quarter != null
            ? parseInt(quarter)
            : undefined;
        updated.gamePeriodMinutes =
          !gameOvertimeNumber && minutes != null
            ? parseInt(minutes)
            : undefined;
        updated.gamePeriodSeconds =
          !gameOvertimeNumber && seconds != null
            ? parseInt(seconds)
            : undefined;
        updated.gameOvertimeNumber = gameOvertimeNumber;
        updated.gameOvertimeMinutes =
          gameOvertimeNumber && minutes != null && parseInt(minutes);
        updated.gameOvertimeSeconds =
          gameOvertimeNumber && seconds != null && parseInt(seconds);
        updated.isEdited = true;
        updated.isDeleted = false;
        updated.possessionTeam = possessionTeam;
        updated.playType = playType;
        updated.statcollSub = statcollSub;
        updated.statcollEmail = statcollEmail;
        updated.statcollFirstName = statcollFirstName;
        updated.statcollLastName = statcollLastName;
        updated.eventCreatorRole = eventCreatorRole;
      })
    );

    return eventData;
  } catch (err) {
    log.error('error updating event...', err);
  }
};

export const updateEventFlag = async ({
  id,
  statcollSub,
  statcollEmail,
  statcollFirstName,
  statcollLastName,
  isFlaged,
}) => {
  try {
    const original = await DataStore.query(Event, id);
    const eventData = await DataStore.save(
      Event.copyOf(original, (updated) => {
        updated.isFlaged = isFlaged;
        updated.statcollSub = statcollSub;
        updated.statcollEmail = statcollEmail;
        updated.statcollFirstName = statcollFirstName;
        updated.statcollLastName = statcollLastName;
      })
    );

    return eventData;
  } catch (err) {
    log.error('error updating event...', err);
  }
};

// export const listEvents = async () => {
//   try {
//     const eventsData = await DataStore.query(Event);
//     return eventsData;
//   } catch (err) {
//     return err;
//   }
// };
